/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getPaymentDetails = (state) => state.checkout.payment.paymentForm?.paymentMethod;

export const getPayPalVO = (state) => state.checkout.cart.orderDetails && state.checkout.cart.orderDetails.payPalVO;
export const getChasePayVO = (state) => state.checkout.cart.orderDetails && state.checkout.cart.orderDetails.chasePayVO;
export const getVisaCheckoutVO = (state) => state.checkout.cart.orderDetails && state.checkout.cart.orderDetails.VISACheckoutVO;

// TODO: Restore memoization (possibly)
export const getEPaymentDetails = (state) => {
    let paymentDetails = {};
    const paymentInfo = getPaymentDetails(state);
    const payPalVO = getPayPalVO(state);
    const chasePayVO = getChasePayVO(state);
    const visaCheckoutVO = getVisaCheckoutVO(state);
    const paymentMethodId = paymentInfo.id ? paymentInfo.id.toLowerCase() : '';

    try {
        if (payPalVO || paymentMethodId === 'paypal') {
            paymentDetails.paymentMethod = {
                id: 'PayPal',
                name: 'PayPal',
            };

            // Append VO
            paymentDetails = { ...paymentDetails, ...payPalVO };
        } else if (chasePayVO || paymentMethodId === 'chasepay') {
            paymentDetails.paymentMethod = {
                id: 'ChasePay',
                name: 'Chase Pay',
            };

            // Append VO
            paymentDetails = { ...paymentDetails, ...chasePayVO };
        } else if (visaCheckoutVO || paymentMethodId === 'visacheckout') {
            paymentDetails.paymentMethod = {
                id: 'VisaCheckout',
                name: 'Click to Pay',
            };

            // Append VO
            paymentDetails = { ...paymentDetails, ...visaCheckoutVO };
        } else if (paymentMethodId === 'applepaycheckout') {
            paymentDetails.paymentMethod = {
                id: 'ApplePay',
                name: 'Apple Pay',
            };
        } else {
            paymentDetails.paymentMethod = paymentInfo;
        }
    } catch (ex) {
        console.error('ERROR: getOrderDetails selector FAILED', ex);
    }

    return paymentDetails;
};

export const getOrderStatus = (state) => state.checkout.cart?.orderDetails?.fdOrderVO?.status || '';
export const getFirstItemRecipientZipCode = (state) => state.checkout.cart?.orderDetails?.fdOrderVO?.recipients?.[0]?.recipientAddress?.zipCode || '';
export const getOrderConfirmationId = (state) => state.checkout.cart?.orderDetails?.fdOrderVO?.orderIdWithSourceId || '';
export const getFirstItemBillingZipCode = (state) => state.cart?.orderDetails?.fdOrderVO?.billingAddress?.zipCode || '';
