/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
import * as actions from './PaymentReadiness-Action';

const paymentReadinessReducer = (state = initialState.payment.paymentReadiness, action) => {
    switch (action.type) {
        case actions.pageInitializationCompleted().type:
            return {
                ...state,
                pageReady: true,
            };
        case actions.pageInitializationStart().type:
            return {
                ...state,
                pageReady: false,
            };
        default:
            return state;
    }
};

export default paymentReadinessReducer;
