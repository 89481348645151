/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const initialState = {
    modal: {
        open: false,
        title: '',
        content: {},
        customClass: '',
        layout: '',
        config: '',
    },
    giftList: {
        pagination: { count: 0, pageSize: 5, currentPage: 1 },
    },
    common: {
        addressVerification: {},
    },
    modalHistory: [],
    pageData: {},
    isFetchingModal: true,
    isReadyState: true,
    toggleState: false,
    recipients: [],
    singleAddressGet: {},
    addNewConfig: '',
    addForm: '',
    openDialog: false,
    addFormLable: '',
};

export default initialState;
