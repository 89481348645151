/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import CardType from '../Plugin/CardType/CardType';

export const GetCardType = (num) => {
    const cardNumber = num.toString().split('-').join('');
    const cardDetails = {
        cardType: '',
        cvvLen: {
            min: 3,
            max: 4,
        },
        gaps: [4, 8, 12],
        len: 16,
        cardNumber,
    };
    const typeOfCard = CardType(cardNumber);
    if (typeOfCard.length === 1) {
        const [card] = typeOfCard;
        const [cardLen] = card.lengths;
        cardDetails.cardType = card.type;
        cardDetails.cvvLen.min = card.code.size;
        cardDetails.gaps = card.gaps;
        cardDetails.len = cardLen;
        return cardDetails;
    }
    return cardDetails;
};

/*
export const GetCardType = (num) => {
    const number = num.split('-').join('');
    const cardDetails = {
        cardType: '',
        cvvLen: {
            min: 3,
            max: 4,
        },
        gaps: [4, 8, 12],
        len: 16,
        cardNumber: number,
    };
    let re = new RegExp('^4[0-9]{15}$');
    if (number.match(re) != null) {
        cardDetails.cardType = 'visa';
        cardDetails.cvvLen.min = 3;
        cardDetails.cvvLen.max = 4;
        cardDetails.len = 16;
    }
    re = (number.length < 15) ? new RegExp('^3[47]') : new RegExp('^3[47][0-9]{13}$');
    if (number.match(re) != null) {
        cardDetails.cardType = 'amex';
        cardDetails.cvvLen.min = 4;
        cardDetails.cvvLen.max = 4;
        cardDetails.gaps = [4, 10];
        cardDetails.len = 15;
    }
    re = new RegExp('^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$');
    if (number.match(re) != null) {
        cardDetails.cardType = 'master';
        cardDetails.cvvLen.min = 3;
        cardDetails.cvvLen.max = 4;
        cardDetails.len = 16;
    }
    re = new RegExp('^6(?:011|5[0-9]{2})[0-9]{12}$');
    if (number.match(re) != null) {
        cardDetails.cardType = 'discover';
        cardDetails.cvvLen.min = 3;
        cardDetails.cvvLen.max = 4;
        cardDetails.len = 16;
    }
    return cardDetails;
};
*/

export const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};
