/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const GET_GOOGLEPAY_CLIENTID = 'mbp-pwa-ui/GET/GOOGLEPAY/CLIENTID';
export const CLEAR_GOOGLEPAY_CLIENTID = 'mbp-pwa-ui/CLEAR/GOOGLEPAY/CLIENTID';
export const LOAD_GOOGLEPAY_CLIENTID = 'mbp-pwa-ui/LOAD/GOOGLEPAY/CLIENTID';
export const SUBMIT_GOOGLE_PAYMENT = 'mbp-pwa-ui/SUBMIT/GOOGLE/PAYMENT';

export default {};
