/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_ACTION = '/deliveryCalendar';
const RESOURCE_ROOT_FACADE_AVAIL = '/availability';

const getDeliveryCalendarByProduct = (wcEnv, jwtToken, skuProductCode, brandCode, zipCode, locationType, startDate, customerType, brandIdentifier) => {
    // Used this translation list to convert French and Spanish to English for using in the API call
    const locationTranslations = {
        // fr
        Résidence: 'Residence',
        Entreprise: 'Business',
        'Maison funéraire': 'Funeral home',
        Hôpital: 'Hospital',
        Appartement: 'Apartment',
        École: 'School',
        Église: 'Church',
        // es
        Residencia: 'Residence',
        Negocio: 'Business',
        'Casa funeraria': 'Funeral home',
        Hospital: 'Hospital',
        Apartamento: 'Apartment',
        Colegio: 'School',
        Iglesia: 'Church',
    };
    // eslint-disable-next-line no-param-reassign
    wcEnv.rootUri = RESOURCE_ROOT_FACADE_AVAIL;
    const locale_CA = 'en_CA';
    let resourcePage = `${RESOURCE_PAGE_ACTION}?skuProductCode=${skuProductCode}&brandCode=${brandCode}&zipCode=${zipCode}&locationType=${locationTranslations[locationType] || locationType}&startDate=${startDate}&customerType=${customerType}`;
    if (brandIdentifier && brandIdentifier === 'CAN') {
        resourcePage = `${RESOURCE_PAGE_ACTION}?skuProductCode=${skuProductCode}&brandCode=${brandCode}&zipCode=${zipCode}&locationType=${locationTranslations[locationType] || locationType}&startDate=${startDate}&customerType=${customerType}&locale=${locale_CA}`;
    }

    return restClient.getFacade(wcEnv, resourcePage, jwtToken)
        .then((response) => response);
};

export default getDeliveryCalendarByProduct;
