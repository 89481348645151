/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// import {
//   ApolloLink,
//   HttpLink,
// } from '@apollo/client/core';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import mbpUtil from 'mbp-api-util';

import getCookie from './common/storage/cookieStorage';

class MultiAPILink extends ApolloLink {
    constructor(config, request) {
        super(request);
        this.config = config;
        this.httpLink = new HttpLink({ ...config });
    }

    request(operation, forward) {
        const api = operation.getContext()?.api;
        const jwt = operation.getContext()?.jwt;

        // Personalized Content Service
        if (api === 'personalizedContent') {
            operation.setContext({
                uri: `https://${mbpUtil.getEnv('APP_WC_HOST')}/r/api/customer-personalize/graphql?${operation.operationName}`,
                fetchOptions: {
                    method: 'POST',
                },
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
            });
            // Qubit
        } else if (api === 'qubit') {
            operation.setContext({
                uri: `https://api.qubit.com/placements/query?${operation.operationName}`,
                fetchOptions: {
                    method: 'POST',
                },
                headers: {
                    'content-type': 'text/plain',
                },
            });
            // Aggregator
        } else {
            operation.setContext({
                fetchOptions: {
                    method: 'GET',
                },
                headers: {
                    'baggage-sessionId': getCookie('ShopperManagerEnterprise'),
                    sv: getCookie('sv'),
                },
            });
        }
        return this.httpLink.request(operation, forward);
    }
}

export default MultiAPILink;
