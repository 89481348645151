/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Excludes Promo From the list
export const getOrderItemsExcludePromo = (state) => {
    if (state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO) {
        const { recipients } = state.checkout.cart.orderDetails.fdOrderVO;
        return recipients.filter((items) => (
            items.orderItems.filter((orderItems) => orderItems.catalogEntryVO.primeFreeShipFlag !== 'M').length !== 0
        ));
    }
    return [];
};

export const getCurrentViewOrderItem = (state) => {
    const recipients = getOrderItemsExcludePromo(state);
    if (recipients.length) {
        return recipients[0];
    }
    return {};
};
