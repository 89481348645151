/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    useState, useRef, useCallback, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { getIsBot } from '../../../state/ducks/App/App-Selectors';
import { getBrowser } from '../../../state/ducks/App/ducks/Config/Config-Helpers';

// 1 to many ratio, many hooks use this
const useIntersectionOberserver = () => {
    const [elems, setElems] = useState([]);

    const changeHandler = useCallback((elem) => {
        if (elem.length) {
            setElems(elem[0]);
        }
    });

    const { current: intOb } = useRef(new IntersectionObserver(changeHandler));

    return [intOb, elems];
};

// each instance of visibility will have a 1 to 1 ratio, 1 component uses this
const useVisibility = () => {
    const isBot = useSelector(getIsBot);
    const browser = getBrowser();
    if (isBot || browser === 'Microsoft Internet Explorer' || typeof window === 'undefined' || !window.IntersectionObserver) {
        return [true, { current: null }];
    }

    const [visible, setVisibility] = useState(false);
    const [intOb, node] = useIntersectionOberserver();
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && ref.current !== node.target) {
            intOb.observe(ref.current);
            return;
        }

        if (ref.current === node.target && node.intersectionRatio > 0) {
            intOb.unobserve(ref.current);
            setVisibility(true);
        }
    }, [ref.current, node.intersectionRatio]);

    return [visible, ref];
};

export default useVisibility;
