/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';

const brandIdsByHostName = {
    '800flowers': '18F',
    harryanddavid: 'HD',
    '800baskets': '18B',
    simplychocolate: 'SCH',
    fruitbouquets: 'FBQ',
    cheryls: 'CCO',
    personalizationuniverse: 'PZU',
    thepopcornfactory: 'TPF',
    wolfermans: 'WLF',
    stockyards: 'SY',
    berries: 'BRY',
    '0800flowers': '08F',
    canada: 'CAN',
    plants: 'PLA',
};

export const getBrandIdByHostName = (hostName) => {
    let brandId = mbpUtil.getEnv('APP_BRAND_ID') || '18F';

    Object.keys(brandIdsByHostName).forEach((key) => {
        if (hostName.indexOf(key) !== -1) {
            brandId = brandIdsByHostName[key];
        }
    });

    return brandId;
};

export const isWineAvailable = (data) => {
    let availability = false;
    if (!data?.wineAvailability) {
        return false;
    }
    const keys = Object.keys(data.wineAvailability);
    keys.forEach((key) => {
        if (data.wineAvailability[key]?.isProductAvailable) {
            availability = true;
        }
    });
    return availability;
};

export default {};
