/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';

import { getDataLayerActionEntry } from './pageDataLayerCommonHelpers';

const getAppliedPromotions = (appliedPromos) => {
    let promoCodes = '';

    try {
        if (appliedPromos.length && appliedPromos[0] !== '') {
            appliedPromos.forEach((promo) => {
                // Handle multiple codes (comma delimited)
                if (promoCodes !== '') {
                    promoCodes += ',';
                }

                promoCodes += promo.code;
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            appliedPromos,
            function: 'getAppliedPromotions',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate applied promos data.',
        });
    }

    return promoCodes;
};

const loadAddOnData = (addOns, brandCode, primeFreeShipFlag) => {
    let addOnsData = null;

    try {
        if (addOns) {
            addOnsData = Object.keys(addOns).map((key) => {
                const {
                    skuCode,
                    parentSkuName,
                    parentProductType,
                    // productURL,
                    skuOfferPrice,
                    skuListPrice,
                    quantity,
                    promisedAvailableTime,
                    discountAdjustmentTotal,
                    shippingAdjustmentTotal,
                    surchargeAdjustmentTotal,
                } = addOns[key];

                const unitPrice = skuOfferPrice || skuListPrice;

                const addOnProduct = {
                    sku: skuCode || '',
                    productName: parentSkuName || '',
                    productCategory: parentProductType || '',
                    productURL: 'productURL',
                    price: unitPrice || '0.00',
                    quantity,
                    adjustments: {
                        discount: discountAdjustmentTotal || '0.00',
                        shipping: shippingAdjustmentTotal || '0.00',
                        surcharge: surchargeAdjustmentTotal || '0.00',
                    },
                    total: unitPrice || '0.00',
                    deliveryDate: promisedAvailableTime,
                    baseCode: skuCode,
                    brandCode,
                    primeFreeShipFlag,
                    orderItemId: 'orderItemId',
                };

                return addOnProduct;
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            addOns,
            brandCode,
            primeFreeShipFlag,
            function: 'loadAddOnData',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build add-on item data.',
        });
    }

    return addOnsData;
};

const loadGreetingCardData = (item, gcOrderItemId) => {
    let gcItem = null;

    try {
        if (item) {
            const {
                partNumber,
                catalogEntry: { description },
                price,
                quantity,
                discountAdjustmentTotal,
                shippingAdjustmentTotal,
                surchargeAdjustmentTotal,
            } = gcItem;

            gcItem = {
                sku: partNumber || '',
                productName: description.name || '',
                price: price || '0.00',
                quantity,
                adjustments: {
                    discount: discountAdjustmentTotal || '0.00',
                    shipping: shippingAdjustmentTotal || '0.00',
                    surcharge: surchargeAdjustmentTotal || '0.00',
                },
                orderItemId: gcOrderItemId || '',
            };
        }
    } catch (ex) {
        mbpLogger.logError({
            item,
            gcOrderItemId,
            function: 'loadGreetingCardData',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build Greeting Card item data.',
        });
    }

    return gcItem;
};

const loadRecipientData = (address) => {
    let recipient = null;

    try {
        if (address) {
            const {
                firstName,
                lastName,
                businessTitle,
                addressLine1,
                addressLine2,
                email,
                city,
                state,
                zipCode,
                phoneNo,
                country,
            } = address;

            const recipCountry = (country === 'US') ? 'US' : country;

            recipient = {
                firstName,
                lastName,
                location: businessTitle,
                address1: addressLine1,
                address2: addressLine2,
                id: email,
                city,
                state,
                postal: zipCode,
                phone1: phoneNo,
                country: recipCountry,
            };
        }
    } catch (ex) {
        mbpLogger.logError({
            address,
            function: 'loadRecipientData',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build item Recipient data',
        });
    }

    return [recipient];
};

const loadComponentData = (components) => {
    let compsData = null;

    try {
        if (components) {
            compsData = components.map((component) => {
                const {
                    sku,
                    name,
                    quantity,
                } = component;

                const componentProduct = {
                    sku: sku || '',
                    productName: name || '',
                    quantity,
                };

                return componentProduct;
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            components,
            function: 'loadComponentData',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build component item data.',
        });
    }

    return compsData;
};

const generateCheckoutDataLayerItemArrays = (recipients, products) => {
    try {
        const items = [];
        const productAddons = [];
        const productAdjustments = [];
        const productAttributes = [];
        const productCategoryIdentifiers = [];
        const productCategoryIds = [];
        const productCategoryPaths = [];
        const productComponents = [];
        const productCoupons = [];
        const productDeliveryDates = [];
        const productDeliveryMethods = [];
        const productGreetingCards = [];
        const productImageUrls = [];
        const productOrderItemIds = [];
        const productQuantities = [];
        const productRecipients = [];
        const productTotals = [];
        const productTypes = [];
        const productUrls = [];

        recipients.forEach((recip) => {
            const { orderItems, recipientAddress } = recip;
            orderItems.forEach((orderItem, idx) => {
                const productCount = idx + 1;
                const {
                    orderItemsId,
                    catalogEntryVO,
                    discountAdjustmentTotal,
                    shippingAdjustmentTotal,
                    surchargeAdjustmentTotal,
                    promisedAvailableTime,
                    xOrderAttrValues,
                    totalProduct,
                    quantity,
                    price,
                } = orderItem;
                const brandCode = catalogEntryVO.brandId || '';
                const primeFreeShipFlag = catalogEntryVO.primeFreeShipFlag || '';

                const item = {
                    partNumber: catalogEntryVO.skuCode || products[catalogEntryVO.parentCatentryId].parentSkuCode || '',
                    name: catalogEntryVO.parentSkuName || '',
                    price: price || catalogEntryVO.skuListPrice || '',
                    sKUs: [{ partNumber: catalogEntryVO.skuCode || '' }],
                    brandId: brandCode,
                    productCount,

                    adjustments: {
                        discount: 0,
                        shipping: 0,
                        surcharge: 0,
                        total: 0,
                    },
                    attributes: [''], // CS
                    category: catalogEntryVO.parentProductType || '',
                    categoryId: (xOrderAttrValues && xOrderAttrValues.categoryId) ? xOrderAttrValues.categoryId : '',
                    categoryName: (xOrderAttrValues && xOrderAttrValues.categoryIdent) ? xOrderAttrValues.categoryIdent : '',
                    categoryPath: (xOrderAttrValues && xOrderAttrValues.categoryPath) ? xOrderAttrValues.categoryPath : '',
                    components: [''],
                    coupons: '',
                    deliveryDates: orderItem.promisedAvailableTime || '',
                };

                // Product Url
                productUrls.push('productUrl');
                // Product Image Url
                productImageUrls.push('productImageURL');
                // Total Product
                productTotals.push(totalProduct || '0.00');
                // Delivery Date
                productDeliveryDates.push(promisedAvailableTime || '');
                // Delivery Method
                productDeliveryMethods.push((xOrderAttrValues && xOrderAttrValues.deliverySLA) ? xOrderAttrValues.deliverySLA : '');
                // OrderItem ID
                productOrderItemIds.push(orderItem.orderItemsId || '');
                // Quantity
                productQuantities.push(quantity || '');
                item.quantity = quantity || ''; // As per current tealium data layer the quantity is counting from product array

                // Product Attributes Data
                // TODO - Nothing applied to PWA CCO
                productAttributes.push(['']);

                // Adjustments
                productAdjustments.push({
                    discount: discountAdjustmentTotal || '0.00',
                    shipping: shippingAdjustmentTotal || '0.00',
                    surcharge: surchargeAdjustmentTotal || '0.00',
                });

                productTypes.push('');

                // Add-Ons Data
                if (orderItem && orderItem.adonList && Object.keys(orderItem.adonList).length) {
                    item.addOns = loadAddOnData(orderItem.adonList, brandCode, primeFreeShipFlag);
                }
                productAddons.push(item.addOns || ['']);

                // Product Category ID/Identifier
                productCategoryIdentifiers.push(item.categoryIdent || '');
                productCategoryIds.push(item.categoryId || '');
                productCategoryPaths.push('');

                // Product Components (CYO/PYO)
                if (orderItem.assortmentOrderItemVO && Object.keys(orderItem.assortmentOrderItemVO).length) {
                    item.components = loadComponentData(orderItem.assortmentOrderItemVO);
                }
                productComponents.push(item.components || ['']);

                // Coupons // TODO: Add coupon code
                if (orderItem.itemLevelPromotions) {
                    item.coupons = Object.keys(orderItem.itemLevelPromotions).map((key) => orderItem.itemLevelPromotions[key].promotionCode || '');
                }
                productCoupons.push(item.coupons || '');

                // Greeting Card Data
                if (orderItem.gcOrderItemId) {
                    item.greetingCards = loadGreetingCardData(orderItem.gcOrderItemId);
                }
                productGreetingCards.push(item.greetingCards || ['']);

                if (recipientAddress) {
                    item.recipient = loadRecipientData(recipientAddress);
                }
                productRecipients.push(item.recipient || ['']);

                items[orderItemsId] = item;
            });
        });

        if (items.length) {
            const checkoutItemsArrays = {
                productAdjustments,
                productAttributes,
                productAddons,
                productComponents,
                productCoupons,
                productDeliveryDates,
                productDeliveryMethods,
                productGreetingCards,
                productImageUrls,
                productOrderItemIds,
                productQuantities,
                productRecipients,
                productTotals,
                productTypes,
                productUrls,
            };

            return {
                products: items,
                ...checkoutItemsArrays,
            };
        }
    } catch (ex) {
        mbpLogger.logError({
            recipients,
            function: 'generateCheckoutDataLayerItemArrays',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build Checkout data layer arrays.',
        });
    }

    return {};
};

const getCheckoutProducts = (recipients) => {
    const products = {};

    recipients
        .forEach((recip) => recip.orderItems
            .forEach((orderItem) => {
                const { parentCatentryId } = orderItem.catalogEntryVO;

                products[parentCatentryId] = orderItem.catalogEntryVO;
            }));

    return products;
};

const generateCheckoutPageDataLayer = (page, pageDataLayer) => {
    if (!page || !pageDataLayer) {
        mbpLogger.logError({
            page,
            pageDataLayer,
            function: 'generateCheckoutPageDataLayer',
            module: 'mbp-page',
            message: 'Missing required params',
        });

        return {};
    }

    const {
        pageName, pageType, checkoutStep, paymentDetails,
    } = page;
    const dataLayer = { ...pageDataLayer };
    const { order } = getDataLayerActionEntry('track_order_data', page.dataLayer);

    try {
        const checkoutPageDetails = {
            'interstitial-login': {
                pageName: 'CheckoutAccountSignIn',
                pageType: 'checkoutAccountSignIn',
            },
            cart: {
                pageName: 'ShoppingCart',
                pageType: 'shoppingCart',
                step: '0',
            },
            shipping: {
                pageName: 'Recipient',
                pageType: 'recipient',
                step: '1',
            },
            // delivery: {
            //     pageName: 'DeliveryAndGreeting',
            //     pageType: 'deliveryandgreeting',
            //     step: '1',
            // },
            payment: {
                pageName: 'Payment',
                pageType: 'payment',
                step: '2',
            },
            review_order: {
                pageName: 'PlaceOrder',
                pageType: 'placeorder',
                step: '3',
            },
            order_confirmation: {
                pageName: 'Thankyouconfirm',
                pageType: 'thankyouconfirm',
            },
        };

        // Base Data Layer Props
        if (page && page.name) {
            dataLayer.pageName = checkoutPageDetails[page.name].pageName || '';
            dataLayer.pageType = checkoutPageDetails[page.name].pageType || '';
            dataLayer.checkoutStep = checkoutPageDetails[page.name].step || '';
        } else { // SPC
            dataLayer.pageName = pageName;
            dataLayer.pageType = pageType;
            dataLayer.checkoutStep = checkoutStep;
        }

        // Checkout Service Type - WCS / GCP
        // cartSource field is available on GCP services
        const cartSource = order.cartSource || '';
        dataLayer.order_checkout_service_type = (cartSource === 'cart-service') ? 'gcp' : 'wcs';

        if (Object.keys(order).length) {
            const { recipients } = order;

            const giftPayMethod = 'GiftCertificate';
            const giftRewardPayMethod = 'GiftReward';
            const enterpriseGiftCardPayMethod = 'EnterpriseGiftCard';
            let totalGiftPiAmount = 0;
            let giftCertPayMethodAmount = 0;
            let giftRewardPayMethodAmount = 0;
            let enterpriseGiftCardPayMethodAmount = 0;

            if (order.giftCards) {
                order.giftCards.forEach((payInst) => {
                    if (payInst.paymentMethod === giftPayMethod) {
                        giftCertPayMethodAmount += parseFloat(payInst.amount);
                    }

                    if (payInst.paymentMethod === giftRewardPayMethod) {
                        giftRewardPayMethodAmount += parseFloat(payInst.amount);
                    }

                    if (payInst.paymentMethod === enterpriseGiftCardPayMethod) {
                        enterpriseGiftCardPayMethodAmount = giftCertPayMethodAmount + parseFloat(payInst.amount, 0);
                    }
                });

                totalGiftPiAmount = (giftCertPayMethodAmount + giftRewardPayMethodAmount + enterpriseGiftCardPayMethodAmount);
            }

            const giftCardBT = enterpriseGiftCardPayMethodAmount;
            const orderTotal = parseFloat(order.totalProduct || 0) + parseFloat(order.totalShipping || 0) + parseFloat(order.totalTax || 0) + parseFloat(order.totalShippingTax || 0) - parseFloat(totalGiftPiAmount);
            const merchandiseTotalBT = parseFloat(order.totalProduct);
            const taxBT = parseFloat(order.totalTax) + parseFloat(order.totalShippingTax);
            const shippingBT = parseFloat(order.totalShipping);
            const discountBT = parseFloat(order.totalAdjustments);
            const discountShippingBT = parseFloat(order.totalShippingAdjustments);

            // Pr duplicate purchase action on Order Con page refresh [Legacy]
            dataLayer.allow_conversion_tracking = true;
            dataLayer.order_total = orderTotal.toFixed(2) || '0.00';
            dataLayer.order_adjusted_total = (orderTotal + order.totalAdjustments).toFixed(2) || '0.00';
            dataLayer.order_total_balance = parseFloat(order.orderTotalBalance).toFixed(2) || '0.00';
            dataLayer.order_product_total = merchandiseTotalBT.toFixed(2) || '0.00';
            dataLayer.order_tax = taxBT.toFixed(2) || '0.00';
            dataLayer.order_shipping = shippingBT.toFixed(2) || '0.00';
            dataLayer.order_discount = discountBT.toFixed(2) || '0.00';
            dataLayer.order_total_tax = (parseFloat(order.totalTax) + parseFloat(order.totalShippingTax)).toFixed(2) || '0.00';
            dataLayer.order_adjusted_shipping = discountShippingBT.toFixed(2) || '0.00';

            dataLayer.order_promoCode = '';
            dataLayer.order_promoCodeType = '';
            dataLayer.order_promoMemberId = '';
            order.appliedPromos.forEach((orderLevelPromotion) => {
                dataLayer.order_promoCode = orderLevelPromotion.code || '';
                dataLayer.order_promoCodeType  = orderLevelPromotion.field1 || '';
                dataLayer.order_promoMemberId = orderLevelPromotion.memberId || '';
            });

            dataLayer.order_itemsCount = order.orderSize || '';
            dataLayer.order_currency = 'USD';
            dataLayer.order_currencyCode = '$';
            dataLayer.order_bannerCode = '';
            dataLayer.order_giftCard = giftCardBT || '0.00';
            dataLayer.order_brandName = order.brandName || '';
            dataLayer.order_appliedPromos = getAppliedPromotions(order.appliedPromos) || '';

            dataLayer.order_adjustments_total = order.totalAdjustments.toFixed(2) || '0.00';
            dataLayer.order_giftCardsTotal = order.giftCardsTotal.toFixed(2) || '0.00';

            if (paymentDetails) {
                const { paymentMethod } = paymentDetails;
                // TODO: Fix me; paymentMethod object does not contain ccType or ccNum
                // let isCreditCardUsed = false;
                // if (paymentMethod.id === 'CreditCard') {
                //     dataLayer.order_CCType = paymentMethod.ccType.substring(0, 1) || '';
                //     dataLayer.order_Last4CC = paymentMethod.ccNum.substring(paymentMethod.ccNum.length - 4, paymentMethod.ccNum.length) || '';
                //     // isCreditCardUsed = true;
                // }
                dataLayer.order_payment_type = paymentMethod.id;
                dataLayer.order_paymentType = paymentMethod.id; // TODO: Remove me, eventually.
            }

            // Customer Data
            if (order.billingAddress) {
                dataLayer.customer_firstName = order.billingAddress.firstName || '';
                dataLayer.customer_lastName = order.billingAddress.lastName || '';
                dataLayer.customer_city = order.billingAddress.city || '';
                dataLayer.customer_state = order.billingAddress.state || '';
                dataLayer.customer_postal = order.billingAddress.zipCode || '';
                dataLayer.customer_userId = order.billingAddress.hashedUserId || '';
                dataLayer.order_billing_phone = order.billingAddress.phoneNo || '';
                // Override member details if not logged in
                if (!dataLayer.customer_email) {
                    dataLayer.customer_email = order.billingAddress.email || '';
                }

                if (order.billingAddress.country === 'US') {
                    dataLayer.customer_country = 'USA';
                } else {
                    dataLayer.customer_country = order.billingAddress.country || '';
                }
            }

            // Items Data
            let itemArrays = null;
            const products = (recipients) ? getCheckoutProducts(recipients) : [];
            if (products) {
                itemArrays = generateCheckoutDataLayerItemArrays(recipients, products);
            }

            // Order Confirmation [trigger]
            if (order.orderIdWithSourceId && dataLayer.pageType === 'thankyouconfirm') {
                dataLayer.order_id = order.orderIdWithSourceId || '';
            }

            // Merge base properties w/ product arrays
            if (dataLayer && itemArrays) {
                return {
                    ...dataLayer,
                    ...itemArrays,
                };
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            page,
            order,
            pageName,
            pageType,
            checkoutStep,
            paymentDetails,
            function: 'generateCheckoutPageDataLayer',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate checkout page data layer.',
        });
    }

    return dataLayer;
};

export {
    generateCheckoutPageDataLayer,

    generateCheckoutDataLayerItemArrays,
};
