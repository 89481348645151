/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import eshopActionTypes from './EshopCheckout-ActionTypes';

export const sendEshopData = (data) => ({
    type: eshopActionTypes.SEND_ESHOP_CHECKOUT_DATA,
    data,
});

export const eshopCheckoutSuccess = (response) => ({
    type: eshopActionTypes.ESHOP_SUCCESS,
    response,
});
