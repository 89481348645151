/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// IPO Paypal
const PAYPAL_CHECKOUT = 'mbp-checkout/PAYPAL_CHECKOUT';

// BT Baypal
const PAYPAL_GET_CLIENT_ID = 'mbp-checkout/PAYPAL_GET_CLIENT_ID';
const LOAD_PAYPAL_CLIENT_ID = 'mbp-checkout/LOAD_PAYPAL_CLIENT_ID';
const LOAD_PAYPAL_PAYLOAD = 'mbp-checkout/LOAD_PAYPAL_PAYLOAD';
const LOG_PAYPAL_PAYMENT_PAYLOAD = 'mbp-checkout/LOG_PAYPAL_PAYMENT_PAYLOAD';
const UPDATE_FLAG_IS_PAYPAL_BILLINGADDRESS_MISSING_MANDTORY_FIELDS = 'mbp-checkout/UPDATE_FLAG_IS_PAYPAL_BILLINGADDRESS_MISSING_MANDTORY_FIELDS';
const CLEAR_PAYPAL_STATE = 'mbp-checkout/CLEAR_PAYPAL_STATE';
const USE_ANOTHER_PAYPAL_ACCOUNT = 'USE/ANOTHER/PAYPAL/ACCOUNT';

export default {
    PAYPAL_CHECKOUT,
    PAYPAL_GET_CLIENT_ID,
    LOAD_PAYPAL_CLIENT_ID,
    LOAD_PAYPAL_PAYLOAD,
    LOG_PAYPAL_PAYMENT_PAYLOAD,
    UPDATE_FLAG_IS_PAYPAL_BILLINGADDRESS_MISSING_MANDTORY_FIELDS,
    CLEAR_PAYPAL_STATE,
    USE_ANOTHER_PAYPAL_ACCOUNT,
};
