/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

/*
    mbp-account-ui redux

    Checklist for mbp-account-ui operations requiring middleware (all files in src/state/reducers):

            Declare _REQUEST action in ActionType.js
            Define request action in EventDispatch.js, including callList of middleware method(s)
            Write middleware method(s) in Services.js
            Write "Action...Success" reducer in Action.js to handle results of middleware
        and finally:
            write code that dispatches to EventDispatcher(yourAction(params)), where needed

    The gory details:

        Code dispatches to a redux action using definition from EventDispatcher.js
        This action's constant is defined in ActionType.js and ends in _REQUEST.

            Example:  FETCH_ADDRESS_BOOK_REQUEST   'mbp-account-ui/address-book/get/REQUEST'

        EventDispatcher's action includes the parameters passed, as well as callList of middleware to be invoked.

            Example:    fetchAddressBook: recipients => ({
                            type: ActionType.FETCH_ADDRESS_BOOK_REQUEST,
                            recipients,
                            callList: ['fetchAddressBook', 'fetchCountryData'],
                        })

        When dispatched, this action is picked up by the WatcherSaga which then invokes WorkerSagas to invoke the middleware.
        Only _REQUEST actions are picked up by WatcherSaga.  All other actions only picked up by Reducers.js
        A _REQUEST action may additionally be picked up by Reducer which sets state to "Busy" while middleware is running.

        The middleware methods live in state/ducks/Services.js and are methods of the Services class.
        If the middleware retrieves data (as it does in addressbook example), the middleware often stores the data in session
        storage in addition to returning the data.

        If any of the middleware methods fail, a new failure action is formed by replacing /REQUEST with /FAILURE

            Example:    mbp-account-ui/address-book/get/FAILURE         (note: no ACTION constant defined for this)

        This FAILURE action is picked up by Reducers.js which typically just sets a not-ready status in state.

        If all middleware methods succeed, a new success action is formed by replacing /REQUEST with /LOADED.
        The data for this new action is an aggregate of all data retrieved by middleware.

            Example:    type: mbp-account-ui/address-book/get/LOADED       (note: no ACTION constant defined for this)
                        recipients,
                        **addressbook and country data fields filled by middleware**

        This action is picked up by reducer which transforms the original ACTION constant by converting to Capitalize
        case and substituting "REQUEST" with "Success".  This new "Action...Success" is a method in the
        Action class defined in state/reducers/Action.js, and is then called

            Example:    FETCH_ADDRESS_BOOK_REQUEST becomes  FetchAddressBookSuccess

        The Action method then takes action based on the middleware data received.  E.g. If middleware retrieves data,
        the Action method is likely a reducer that stores this data in state.

        ------------------

        Note: Because under this scheme components sometimes do not have direct dependencies that are
              detected by React, the toggleState hack exists.  By setting toggleState to !toggleState at
              the end of an "Action...Success" method, it forces component(s) to update.

*/

import WcClient from 'wc-api';
import mbpLogger from 'mbp-logger';
import QasClient from 'mbp-address-verification';
import qs from 'qs';
import * as Auth from '../../../../../state/ducks/Member/ducks/Auth/Plugin/Auth/Auth';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import orderTrackingAPI from '../../../../../apis/orderstatus-apis/orderTracking';
import orderTrackingAPIByEmail from '../../../../../apis/orderstatus-apis/orderTrackingByEmail';
// import * as auth from '../../../../../state/ducks/Member/ducks/Auth/Plugin/Auth/Auth';
import LocationTypes from '../constants/LocationTypes';
import States from '../constants/States';
import { Countries } from '../constants/Countries';
import APOFPO from '../constants/APOFPO';
import Relationships from '../constants/Relationships';
import CreditCardTypes from '../constants/CreditCardTypes';
import Helper from './Helper';
// eslint-disable-next-line
import { uuidv4 } from '../../utils/uuid';
import { decipher } from '../../utils/crypto';

import http from '../../services/httpService';
import getSiteConfig from '../../utils/getSiteConfig';
import {
    // eslint-disable-next-line
    getOrdersByEmailIdQL,
    // eslint-disable-next-line
    getDetailedOrderByIdQL,
    // eslint-disable-next-line
    getDetailedOrderGuestByIdQL,
    // eslint-disable-next-line
    getDetailedOrderByIdAndZipcodeQL,
    getProductDetailByItemNumberQL,
    findProductByPartNumbersQL,
} from '../../services/graphQL';
import { isEmpty } from '../../utils/object';
import {
    addressBooks,
} from '../../__mocks__/fakeData/recipientData';
import configENV from '../../config';
import { dateMMMMDDYYYY } from '../../utils/dateString';
import { getAccessTokenSafely } from '../../../../../state/ducks/Member/ducks/Auth/Plugin/Auth/Auth';

const {
    PERSIST_KEY, AUTH_LOGIN_URL, SHOP_THE_SITE_KEY, SESSION_STORAGE_ENABLED, SESSION_STORAGE_EXPIRES, GENERIC_ERROR,
} = configENV;

const GRAPHQL_ENV = process.env.APP_GRAPHQL_ENV ? process.env.APP_GRAPHQL_ENV : process.env.NODE_ENV;

export default class Services {
    constructor(siteConfig) {
        this.siteConfig = siteConfig || getSiteConfig();
        this.partnumPrefix = this.siteConfig['partnum-prefix'];
        this.brandName = this.siteConfig['brand-id'];
        this.domain = this.siteConfig.domain;
        const { accessToken, idToken } = Helper.getAuthentication();
        this.userProfile = http.getUserProfile(accessToken, idToken, null);
        this.wcEnv = {
            // // Mock server host name
            // host: 'localhost:3011',
            // protocol: 'http',
            protocol: 'https',
            prefix: '/r/api',
            rootUri: '/',
            brand: this.domain,
        };
    }

    storeShopTheSiteConfirmationInSessionStorage = (data) => {
        const { object } = data;
        let { dontShowModal: noShopTheSiteConfirmation } = data;
        const { ContactId } = this.userProfile;
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && typeof storageJson[ContactId].noShopTheSiteConfirmation !== 'undefined'
                && !noShopTheSiteConfirmation) {
                ({ noShopTheSiteConfirmation } = storageJson[ContactId]);
            }
        }
        Object.keys(object).forEach((key) => {
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                [key]: object[key],
                noShopTheSiteConfirmation,
                timestamp: new Date().getTime(),
            };
        });
        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        return {
            data: { ...object, noShopTheSiteConfirmation },
        };
    };

    setObjectInSessionStorage = (data) => {
        const { object } = data;
        const { ContactId } = this.userProfile;
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
        }
        Object.keys(object).forEach((key) => {
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                [key]: object[key],
                timestamp: new Date().getTime(),
            };
        });
        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        return {
            data: { ...object },
        };
    };

    getObjectInSessionStorage = (key) => {
        const { ContactId } = this.userProfile;
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson && storageJson[ContactId]) {
                return storageJson[ContactId][key];
            }
        }
        return '';
    };

    getchShopTheSiteModalState = () => {
        let noShopTheSiteConfirmation = false;
        const { ContactId } = this.userProfile;
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && typeof storageJson[ContactId].noShopTheSiteConfirmation !== 'undefined') {
                ({ noShopTheSiteConfirmation } = storageJson[ContactId]);
            }
        }
        return {
            data: { noShopTheSiteConfirmation },
        };
    };

    linkUserToGiftList = async (data) => {
        const { accessToken } = this.userProfile;
        const { customerNumber, zipCode, orderNumber, last4CC } = data;
        const pageData = { url: '' };
        const recipients = {};
        const totalPage = 1;
        const currentPage = 1;
        let errorMessage;
        let errorName = 'customerNumber';
        const error = { errorMessage: GENERIC_ERROR, type: 'modal', name: errorName };
        const errorResponse = {
            data: { occasions: [], occasionId: '', recipients, totalPage, currentPage, pageData, error, isGiftListUser: false, isGiftListLoaded: true },
        };
        this.wcEnv.rootUri = '/';
        let resourcePage;
        if (customerNumber && zipCode) {
            errorMessage = "Sorry, we couldn't find your customer number with the provided zip code on the system.";
            resourcePage = `giftlist/customer/${customerNumber}/zipcode/${zipCode}`;
        } else if (customerNumber) {
            // Link by customerNumber from magic link
            errorMessage = "Sorry, we couldn't find your customer number with the provided zip code on the system.";
            resourcePage = `giftlist/customer/${customerNumber}`;
        } else if (orderNumber && last4CC) {
            errorMessage = "Sorry, we couldn't find your order number with the last 4 digits from the credit card used.";
            errorName = 'orderNumber';
            resourcePage = `giftlist/ordernumber/${orderNumber}/cc/${last4CC}`;
        } else {
            return { ...errorResponse };
        }
        return WcClient.wcGetFacade(this.wcEnv, resourcePage, accessToken)
            .then(async (response) => {
                if (response.status === 200) {
                    if (!isEmpty(response.data) && response.data[0].id) {
                        const { data: result } = await this.fetchGiftListRecipients({}, true);
                        if (isEmpty(result.occasions) && result.error) {
                            result.error.errorMessage = errorMessage;
                            result.error.type = 'inline';
                            result.error.name = errorName;
                        } else if (!isEmpty(result.occasions) && result.error) {
                            result.error.errorMessage = '';
                        }
                        return {
                            data: { ...result },
                        };
                    }
                    errorResponse.data.error.errorMessage = errorMessage;
                    errorResponse.data.error.type = 'inline';
                    errorResponse.data.error.name = errorName;
                }
                return {
                    ...errorResponse,
                };
            })
            .catch((err) => {
                const { response } = err;
                if (response && response.status === 412 && errorResponse.data.error) {
                    errorResponse.data.error.errorMessage = errorMessage;
                    errorResponse.data.error.type = 'inline';
                    errorResponse.data.error.name = errorName;
                }
                return { ...errorResponse };
            });
    };

    fetchGiftListOccasions = async () => {
        const { ContactId } = this.userProfile;
        const { accessToken } = this.userProfile;
        const resourcePage = 'giftlist';
        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].giftListOccasions
                && storageJson[ContactId].timestamp) {
                const nowTime = new Date().getTime();
                const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                if (!isExpired) {
                    return storageJson[ContactId].giftListOccasions;
                }
            }
        }

        this.wcEnv.rootUri = '/';
        // this.wcEnv.host = 'localhost:3011';
        // this.wcEnv.protocol = 'http';
        const response = await WcClient.wcGetFacade(this.wcEnv, resourcePage, accessToken, {});
        delete response.headers;
        delete response.request;
        delete response.config;
        const occasionList = [];

        if (response.status === 200 && !isEmpty(response.data)) {
            response.data.forEach((item) => {
                occasionList.push(item);
            });
        }

        // Update sessionStorage
        if (SESSION_STORAGE_ENABLED === 'on') {
            if (!storageJson[ContactId]) {
                storageJson = { [ContactId]: {} };
            }
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                giftListOccasions: [...occasionList],
                timestamp: new Date().getTime(),
            };
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        }

        return [...occasionList];
    };

    getProductsInCart = async (orderId) => {
        const { accessToken } = this.userProfile;
        const totalItemInCart = await orderClient.getOrderDetails(this.wcEnv, accessToken, orderId || '');
        delete totalItemInCart.headers;
        delete totalItemInCart.request;
        delete totalItemInCart.config;
        if (totalItemInCart.status === 200 && !isEmpty(totalItemInCart.data)) {
            const itemsInCartByAddressId = Helper.getGiftListItemFromCart(totalItemInCart);
            return {
                itemsInCartByAddressId,
                itemsInCart: totalItemInCart.data,
            };
        }
        return {};
    };

    // giftListLoginWithCustNbr = async (data) => {
    //     const { auth_client_id: clientId, 'brand-id': brandId } = this.siteConfig;
    //     const { custNbr, register } = data;
    //     Helper.setSessionStorageObject('common', { custNbr }, false);
    //     const routeBack = '/account/gift-list';
    //     let auth0;
    //     if (register === 'Y') {
    //         auth0 = new Auth(clientId, brandId, { routeBack, custNbr, register });
    //     } else {
    //         auth0 = new Auth(clientId, brandId, { routeBack, custNbr });
    //     }
    //     await auth0.login();
    //     return {
    //         data,
    //     };
    // };

    // auth0Login = async (data) => {
    //     const { auth_client_id: clientId, 'brand-id': brandId } = this.siteConfig;
    //     const { register } = data;
    //     const routeBack = '/account/gift-list';
    //     let auth0;
    //     if (register === 'Y') {
    //         auth0 = new Auth(clientId, brandId, { routeBack, register });
    //     } else {
    //         auth0 = new Auth(clientId, brandId, { routeBack });
    //     }
    //     await auth0.login();
    //     return {
    //         data: {
    //             auto0Login: true,
    //         },
    //     };
    // };

    fetchGiftListRecipients = async (data, forceRefresh = false) => {
        const { ContactId, accessToken } = this.userProfile;
        const { setOccasionId, id: occasionIdFromURL, orderId, newPage } = data;
        let occasionId;
        let pageData = { url: '' };
        let recipients = {};
        let totalPage = 1;
        let noShopTheSiteConfirmation = false;
        let currentPage = newPage || 1;
        let error = { errorMessage: '' };
        let occasions = await this.fetchGiftListOccasions().catch((err) => err);
        if (occasions.response && occasions.response.status === 401) {
            pageData.url = AUTH_LOGIN_URL;
            return {
                data: { occasions: [], occasionId: '', recipients, totalPage, currentPage, pageData, error, isGiftListUser: false, isGiftListLoaded: true },
            };
        }

        if (occasionIdFromURL) {
            occasionId = occasionIdFromURL;
        } else {
            try {
                occasionId = setOccasionId || occasions[0].id;
            } catch (ex) {
                // User doesn't have gift list yet
                const custNbr = Helper.getSessionStorageObject('common', 'custNbr', false);
                // Reset the custNbr
                Helper.setSessionStorageObject('common', { custNbr: '' }, false);
                if (!isEmpty(custNbr)) {
                    const linkingResult = await this.linkUserToGiftList({ customerNumber: custNbr });
                    if (linkingResult && !isEmpty(linkingResult.data) && linkingResult.data.occasionId && linkingResult.data.occasions) {
                        ({ data: { occasionId, occasions } } = linkingResult);
                    } else {
                        // TODO: should show the error message modal
                        ({ error } = linkingResult.data);
                        error.errorMessage = `Sorry, we couldn't find the customer number ${custNbr} on the system. Please try with one of the provided options or contact customer service for assistance.`;
                        return {
                            data: { occasions: [], occasionId: '', recipients, totalPage, currentPage, pageData, error, isGiftListUser: false, isGiftListLoaded: true },
                        };
                    }
                } else {
                    // After creating new account, Auth0 redirected users back to /account/gift-list
                    // and the Gift List data need to be reloaded, so we need to set isGiftListLoaded: false to avoid the flashing screen
                    return {
                        data: { occasions: [], occasionId: '', recipients, totalPage, currentPage, pageData, error, isGiftListUser: false, isGiftListLoaded: false },
                    };
                }
            }
        }

        // Reset the custNbr
        Helper.setSessionStorageObject('common', { custNbr: '' }, false);
        // Redirect to /account/gift-list if occasionId not found
        if (occasions.findIndex((occ) => occ.id === occasionId) === -1) {
            pageData = {
                url: '/account/gift-list',
            };
            return {
                data: { occasions, occasionId: occasions[0].id, recipients, totalPage, currentPage, pageData, error, isGiftListUser: true, isGiftListLoaded: true },
            };
        }

        const { itemsInCartByAddressId: productsInCart } = await this.getProductsInCart(orderId);
        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson && storageJson[ContactId]) {
                // Get previous shop the site confirmation modal's state
                ({ noShopTheSiteConfirmation } = storageJson[ContactId]);
                if (
                    !forceRefresh
                    && storageJson[ContactId][`giftList_${occasionId}_${currentPage}`]
                    && storageJson[ContactId].timestamp) {
                    const nowTime = new Date().getTime();
                    const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                    if (!isExpired) {
                        const storageRecipients = storageJson[ContactId][`giftList_${occasionId}_${currentPage}`];
                        ({ recipients, totalPage } = storageRecipients);
                        return {
                            data: { occasions, orderId, occasionId, recipients, totalPage, currentPage, pageData, productsInCart, noShopTheSiteConfirmation, error, isGiftListUser: true, isGiftListLoaded: true },
                        };
                    }
                }
            }
        }
        const resourcePage = `giftlist/${occasionId}/?page=${currentPage}&rnd=${Math.random()}`;
        // Mock Server URL
        // const resourcePage = `get-recipients/${occasionId}`;
        //
        // Set this.wcEnv.rootUri to '/'; not sure why the rootUri has been changed
        this.wcEnv.rootUri = '/';
        // Get Gift List Data by page number
        const response = await WcClient.wcGetFacade(this.wcEnv, resourcePage, accessToken, {});
        recipients[occasionId] = [];
        let responseData = [];
        let availableProducts = [];
        const skuLists = [];
        let dataSource = '';
        let dataBrandCode = '';
        try {
            // eslint-disable-next-line no-underscore-dangle
            responseData = response.data._embedded?.recipient || [];
            dataSource = response.data.source || '';
            dataBrandCode = response.data.brandCode || '';
            totalPage = response.data.total;
            currentPage = response.data.page;
        } catch (ex) {
            // Gift list isn't ready for user or user doesn't have gift list yet
            return {
                data: { occasions: [], orderId, occasionId: '', recipients: {}, totalPage, currentPage, pageData, productsInCart, error, isGiftListUser: false, isGiftListLoaded: true },
            };
        }

        const states = Helper.refactorStateData(States);
        const countries = Helper.refactorObjectData(Countries);
        if (response.status === 200 && !isEmpty(responseData)) {
            responseData.forEach((item) => {
                const { profile, item: { currentItem, orderItemId, recommendation, previousItemMetadata } } = item;
                const selectedCountry = countries.filter((c) => c.id === profile.country);
                // Set default country to US if it's empty or undefined
                const country = selectedCountry.length ? selectedCountry[0] : ({ id: 'US', name: 'United States' });
                const selectedState = states[country.id].filter((c) => c.id === profile.state);
                // Set empty state data to prevent dropdown error
                const state = selectedState.length ? selectedState[0] : ({ id: '', name: '' });
                let giftArrived = '';
                // If isNewRecipient === true, it means no Gift List history for this recipient,
                // and we will call a recipient API to update and get delivery date right after adding to cart.
                // In 4.1, we can't proceed to checkout page due to missing delivery date after adding to cart on Gift List.
                // Not sure about 4.2, but this issue has been raised in 4.1.
                let isNewRecipient = true;
                try {
                    // It won't be concidered as a new recipient if there are month, day, year in the previousItemMetadata object
                    const { requestedShipDate: { monthValue: month, dayOfMonth: day, year } } = previousItemMetadata;
                    const date = new Date(year, month - 1, day);
                    giftArrived = dateMMMMDDYYYY(date);
                    isNewRecipient = false;
                    // eslint-disable-next-line no-empty
                } catch (ex) { }
                // The xOrderAttrValues object will be used to track Gift List product while adding to cart in 4.2
                const xOrderAttrValues = {
                    giftHistoryOrderItemId: orderItemId,
                    giftHistoryOccassionCode: occasionId,
                    // eslint-disable-next-line no-underscore-dangle
                    giftHistoryPromCode: response.data._embedded?.giftlistCode || '',
                    // eslint-disable-next-line no-underscore-dangle
                    giftHistoryCustomerNumber: response.data._embedded?.customerNumber || '',
                    // eslint-disable-next-line no-underscore-dangle
                    giftHistoryRecipientCustomerNumber: profile.recipientNumber || '',
                };
                // Refactor recipient's address object
                const address = {
                    addressId: profile.addressId,
                    street: profile.address1,
                    apt: profile.address2,
                    company: profile.organizationName,
                    city: profile.city,
                    location: {
                        id: 1,
                        name: 'Residence',
                    },
                    state,
                    country,
                    zipcode: profile.zipCode,
                };
                availableProducts = [currentItem, ...recommendation];
                // Refactor products list object for each recipient
                const products = [];
                availableProducts.forEach((rec) => {
                    const price = rec.listPrice;
                    const discountPrice = rec.offerPrice < price ? rec.offerPrice : '';
                    const crossedPrice = discountPrice ? price : '';
                    // Collecting product SKU to get product custom attributes such as wine, personalized, or CYO...
                    let productPartNumber = '';
                    // Keep the original SKU if it starts with tpf_ or cco_
                    if (/^(cco_|tpf_)/.test(rec.sku)) productPartNumber = rec.sku;
                    // Force to use -P- insead of -I- in the findProductByPartNumbers API call
                    else if (/(-I-|-P-)/.test(rec.sku)) productPartNumber = rec.sku.replace(/(-I-|-P-)/, '-P-');
                    else {
                        // Convert partnum-prefix from XXXX-I- to XXXX-P-
                        productPartNumber = `${this.partnumPrefix.replace(/-I-/, '-P-')}${rec.sku}`;
                    }
                    skuLists.push(productPartNumber);
                    const product = {
                        id: rec.partNumber,
                        content: rec.description,
                        crossedPrice,
                        discountPrice,
                        image: rec.largeThumbNailImage,
                        name: rec.name,
                        price,
                        sku: rec.sku,
                        customAttributes: {}, // used to identify wine, personalized, or CYO product
                        xOrderAttrValues, // will be used to track Gift List product while adding to cart in 4.2
                        partNumber: rec.partNumber,
                        productPartNumber,
                        status: rec.shipDateMsg,
                        parentCatalogEntryId: rec.parentCatalogEntryId,
                        seoproductDisplayURL: rec.seoproductDisplayURL,
                        itemContents: rec.itemContents,
                    };
                    products.push(product);
                });
                // Recipient object
                const recipient = {
                    id: orderItemId,
                    addressId: profile.addressId,
                    AddressBookEntryId: '', // TODO: need to import AddressBookEntryId from addressBook if exists
                    address,
                    products,
                    addToCart: '',
                    firstname: profile.firstName,
                    lastname: profile.lastName,
                    occasionId,
                    orderItemId,
                    giftArrived,
                    sourceAttributes: {
                        brandCode: dataBrandCode,
                        source: dataSource,
                    },
                    isNewRecipient,
                    type: 'link',
                };
                // Recipients object / list by Occasion
                recipients[occasionId].push(recipient);
            });
        }

        this.wcEnv.rootUri = '/';
        const resourceURL = 'aggregator/graphql';
        const payload = {
            query: findProductByPartNumbersQL([...new Set(skuLists)], this.domain, GRAPHQL_ENV),
        };
        const customAttr = await WcClient.wcPostFacade(this.wcEnv, resourceURL, accessToken, payload);
        // customAttributes is used to identify wine, personalized, or CYO product
        let customAttributes = [];
        try {
            // eslint-disable-next-line no-underscore-dangle
            customAttributes = customAttr.data.data.findProductByPartNumbers;
        } catch (ex) {
            // Couldn't get custom attribute, but we'll return the valide data anyway
            return {
                data: { occasions, orderId, occasionId, recipients, totalPage, currentPage, pageData, productsInCart, noShopTheSiteConfirmation, error, isGiftListUser: true, isGiftListLoaded: true },
            };
        }
        if (customAttr.status === 200 && !isEmpty(customAttributes)) {
            customAttributes.forEach(async (attr) => {
                const { id } = attr;
                // Keep only the last part of `id` of the customAttr: tpf_XXXXXX, cco_XXXXXX, 1019-P-13332X => 13332X
                const sku = id ? id.replace(/^(tpf_|cco_|\d+-P-|\d+-I-)/, '') : '';
                // Update product with custom attribute
                recipients[occasionId].forEach((rec, i) => {
                    const productIndexBySku = rec.products.findIndex((p) => p.sku === sku);
                    if (productIndexBySku > -1) {
                        recipients[occasionId][i].products[productIndexBySku].customAttributes = attr;
                    }
                });
            });
        }
        // Update sessionStorage
        if (!storageJson[ContactId]) {
            storageJson = { [ContactId]: {} };
        }
        const giftListRecipientsPage1 = storageJson[ContactId][`giftList_${occasionId}_1`];
        if (forceRefresh && !isEmpty(giftListRecipientsPage1)) {
            const totalPageInSession = giftListRecipientsPage1.totalPage;
            let index = 0;
            while (index < totalPageInSession) {
                delete storageJson[ContactId][`giftList_${occasionId}_${index + 1}`];
                index += 1;
            }
        }
        storageJson[ContactId] = {
            ...storageJson[ContactId],
            [`giftList_${occasionId}_${currentPage}`]: { recipients, totalPage },
            timestamp: new Date().getTime(),
        };
        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        return {
            data: { occasions, orderId, occasionId, recipients, totalPage, currentPage, pageData, productsInCart, noShopTheSiteConfirmation, error, isGiftListUser: true, isGiftListLoaded: true },
        };
    };

    fetchGiftListRecipientsByPage = async (data) => {
        const { occasionId: id, newPage } = data;
        const { data: responseData } = await this.fetchGiftListRecipients({ id, newPage }, false);
        const { occasionId, recipients } = responseData;
        const recipientsByPage = recipients[occasionId];
        const { data: { noShopTheSiteConfirmation } } = this.getchShopTheSiteModalState();
        return {
            data: {
                ...responseData,
                recipientsByPage,
                noShopTheSiteConfirmation,
            },
        };
    }

    fetchGiftListAddressBook = () => ({
        data: { addressBooks },
        error: false,
    });

    fetchUserProfile = () => {
        const { userProfile } = this;
        return {
            data: { userProfile },
            error: false,
        };
    };

    fetchAddressBook = async (data) => {
        const { ContactId, accessToken } = this.userProfile;
        // Return exising data
        let { recipients } = data;
        if (recipients.length) {
            return {
                data: { recipients, ContactId },
                error: false,
            };
        }
        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage && SESSION_STORAGE_ENABLED === 'on') {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].recipients
                && storageJson[ContactId].timestamp) {
                const nowTime = new Date().getTime();
                const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                if (!isExpired) {
                    return {
                        data: {
                            recipients: storageJson[ContactId].recipients,
                            ContactId,
                        },
                        error: false,
                    };
                }
            }
        }
        // Get addressbook if data doesn't exist
        const { pageNumber = 0, pageCount = 200 } = data;
        this.wcEnv.rootUri = '/';
        const resourcePage = `addressbook/contacts/${ContactId}/entries?pageNumber=${pageNumber}&pageCount=${pageCount}&IncludeAddresses=true`;
        const response = await WcClient.wcGetFacade(this.wcEnv, resourcePage, accessToken, {});
        const { AddressBookEntries } = response.data.Result;
        recipients = [];
        // Filter out duplicate recipients with the same address
        AddressBookEntries.forEach((entry) => {
            const index = recipients.findIndex((r) => r.FirstName === entry.FirstName && r.LastName === entry.LastName);
            if (index > 0) {
                const isSameAddressLineOne = recipients[index].Addresses[0].AddressLineOne.trim().toUpperCase() === entry.Addresses[0].AddressLineOne.trim().toUpperCase();
                const isSameAddressLineTwo = recipients[index].Addresses[0].AddressLineTwo.trim().toUpperCase() === entry.Addresses[0].AddressLineTwo.trim().toUpperCase();
                const isSameAddressType = recipients[index].Addresses[0].AddressType.trim().toUpperCase() === entry.Addresses[0].AddressType.trim().toUpperCase();
                const isSameCity = recipients[index].Addresses[0].City.trim().toUpperCase() === entry.Addresses[0].City.trim().toUpperCase();
                const isSameStateProvince = recipients[index].Addresses[0].StateProvince.trim().toUpperCase() === entry.Addresses[0].StateProvince.trim().toUpperCase();
                const isSameCountry = recipients[index].Addresses[0].Country.trim().toUpperCase() === entry.Addresses[0].Country.trim().toUpperCase();
                const recipientPostalCode = recipients[index].Addresses[0].PostalCode.trim().replace(/-\d{4}$/, '').toUpperCase(); // remove the zip code extension
                const entryPostalCode = entry.Addresses[0].PostalCode.trim().replace(/-\d{4}$/, '').toUpperCase(); // remove the zip code extension
                const isSamePostalCode = recipientPostalCode === entryPostalCode;
                if (
                    !(isSameAddressLineOne && isSameAddressLineTwo && isSameAddressType && isSameCity && isSameStateProvince && isSameCountry && isSamePostalCode)
                ) {
                    recipients.push(entry);
                }
            } else {
                recipients.push(entry);
            }
        });
        // Update sessionStorage
        if (SESSION_STORAGE_ENABLED === 'on') {
            if (!storageJson[ContactId]) {
                storageJson = { [ContactId]: {} };
            }
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                recipients,
                timestamp: new Date().getTime(),
            };
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        }
        return {
            data: { recipients, ContactId },
            error: false,
        };
    };

    fetchBillingAddress = async (data) => {
        const { ContactId, accessToken } = this.userProfile;
        // Return existing data
        let { billingAddress, wallet } = data;
        if (billingAddress.length) {
            return {
                data: { billingAddress, wallet, ContactId },
                error: false,
            };
        }
        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage && SESSION_STORAGE_ENABLED === 'on') {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].billingAddress
                && storageJson[ContactId].wallet
                && storageJson[ContactId].timestamp) {
                const nowTime = new Date().getTime();
                const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                if (!isExpired) {
                    return {
                        data: {
                            billingAddress:
                                storageJson[ContactId].billingAddress,
                            wallet: storageJson[ContactId].wallet,
                            ContactId,
                        },
                        error: false,
                    };
                }
            }
        }
        // Get data from billingwallet if data deosn't exist
        this.wcEnv.rootUri = '/';
        const resourcePage = `addressbook/composite/billingwallet/${ContactId}`;
        const response = await WcClient.wcGetFacade(this.wcEnv, resourcePage, accessToken, {});
        const {
            billingAddress: { billingAddresses },
            walletResponse: { result },
        } = response.data.Result;
        billingAddress = [...billingAddresses];
        wallet = [];
        // eslint-disable-next-line prefer-destructuring
        if (result) wallet = result.wallet;
        // Update sessionStorage
        if (SESSION_STORAGE_ENABLED === 'on') {
            if (!storageJson[ContactId]) {
                storageJson = { [ContactId]: {} };
            }
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                billingAddress,
                wallet,
                timestamp: new Date().getTime(),
            };
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        }
        return {
            data: { billingAddress, wallet, ContactId },
            error: false,
        };
    };

    fetchOrdersByEmail = async (data) => {
        let error;
        let ROSSOrder;
        let orderDetails;
        const { uuid, options, featureFlags, ssrDeviceType } = data;
        let { orderNumber, email } = data;
        // I -> In transit
        // D/U -> Delivered
        // C -> Cancelled
        // W and all other status -> Order Received
        if (options) {
            if (uuid && options.email && options.email !== '0' && !orderNumber) {
                email = decipher(uuid.slice(0, 8))(options.email);
            }

            if (uuid && options.order && !orderNumber) {
                orderNumber = decipher(uuid.slice(0, 8))(options.order.replace(/-/g, ''));
            }
        }

        /**
         * START ROSS LOGIC
         * */
        const isROSSEnabled = featureFlags?.['is-order-page-using-ross-endpoint'];

        if (isROSSEnabled) {
            const channelUserCode = Helper.getChannelUser(ssrDeviceType || 'desktop', this.domain, true);

            try {
                const user = channelUserCode;
                const rossData = {
                    user,
                    orderNumber,
                    email,
                };

                const token = await getAccessTokenSafely();
                const ROSSOrderResponse = await orderTrackingAPIByEmail(token, rossData, (data?.featureFlags['which-ross-api-version-enabled'] || 'v1'));
                ROSSOrder = ROSSOrderResponse.data;
                ROSSOrder.uuid = uuid;

                if (!isEmpty(ROSSOrder) && isEmpty(ROSSOrder.orders)) {
                    orderDetails = {};
                    ROSSOrder = {};
                    return {
                        data: { ROSSOrder, orderDetails, error: 'There was an error processing your request. Please recheck the details.', orderDataLoaded: true },
                    };
                }
            } catch (err) {
                const message = `error fetching ROSS order list: ${err}`;
                mbpLogger.logError({
                    function: 'Services.fetchOrdersByEmail',
                    module: 'mbp-account-ui',
                    message,
                });
                orderDetails = {};
                ROSSOrder = {};
                return {
                    data: { ROSSOrder, orderDetails, error: err },
                };
            }

            const orderDataLoaded = !isEmpty(ROSSOrder);

            return {
                data: { ROSSOrder, options, error, orderDataLoaded },
            };
        }

        return {
            data: { orderDetails, options, error },
        };
    };

    fetchOrderDetails = async (data) => {
        const { ContactId, isAuthenticated, accessToken } = this.userProfile;
        // eslint-disable-next-line
        const orderNumberPure = data.orderNumber;
        const { uuid, options, featureFlags, ssrDeviceType } = data;
        let { orderNumber, zipCode, billingZipCode } = data;
        // eslint-disable-next-line
        const orderIndex = uuid;
        let orderDetails = {};
        // eslint-disable-next-line
        let orderInfoList = [];
        const errorMessage = 'Unable to obtain order details for this order.';
        // eslint-disable-next-line
        let error = { errorMessage };
        // eslint-disable-next-line
        let token = '';
        // eslint-disable-next-line
        let paymentStatus;
        // eslint-disable-next-line
        let status;
        // I -> In transit
        // D/U -> Delivered
        // C -> Cancelled
        // W and all other status -> Order Received
        // eslint-disable-next-line
        let trackingNumber;
        // eslint-disable-next-line
        let carrier;
        // eslint-disable-next-line
        let update = false;
        if (options) {
            if (options.update) ({ update } = options);
            // eslint-disable-next-line
            if (options.paymentStatus) ({ paymentStatus } = options);
            // eslint-disable-next-line
            if (options.trackingNumber) ({ trackingNumber } = options);
            if (options.carrier) ({ carrier } = options);
            if (options.status) ({ status } = options);
            if (uuid && options.status && !orderNumber) {
                status = decipher(uuid.slice(0, 8))(options.status.replace(/-/g, ''));
            }
            if (uuid && options.zip && options.zip !== '0' && !orderNumber) {
                zipCode = decipher(uuid.slice(0, 8))(options.zip);
            }
            if (uuid && options.zip && options.zip !== '0' && !orderNumber) {
                billingZipCode = decipher(uuid.slice(0, 8))(options.zip);
            }
            if (uuid && options.order && !orderNumber) {
                orderNumber = decipher(uuid.slice(0, 8))(options.order.replace(/-/g, ''));
            }
        }

        /**
         * START ROSS LOGIC
         * */
        const isROSSEnabled = featureFlags?.['is-order-page-using-ross-endpoint'];
        let ROSSOrder = {};

        if (isROSSEnabled) {
            const userAgent = {
                desktop: '30077',
                mobile: '30078',
            };

            try {
                const user = userAgent[ssrDeviceType];
                let rossData = {
                    user,
                    orderNumber,
                };
                if (!isAuthenticated) {
                    rossData = {
                        user,
                        orderNumber,
                        recipientZipCode: zipCode,
                        billingZipCode,
                    };
                }

                const ROSSOrderResponse = await orderTrackingAPI(accessToken, rossData, featureFlags['which-ross-api-version-enabled']);
                ROSSOrder = ROSSOrderResponse.data;
                ROSSOrder.uuid = uuid;
                if (!isEmpty(ROSSOrder) && isEmpty(ROSSOrder.orders)) {
                    orderDetails = {};
                    ROSSOrder = {};
                    return {
                        data: { ROSSOrder, orderDetails, ContactId, error },
                    };
                }
            } catch (err) {
                const message = `error fetching ROSS order list: ${err}`;
                mbpLogger.logError({
                    function: 'Services.fetchOrdersByEmail',
                    module: 'mbp-account-ui',
                    message,
                });
                orderDetails = {};
                ROSSOrder = {};
                return {
                    data: { ROSSOrder, orderDetails, ContactId, error },
                };
            }

            const orderDataLoaded = !isEmpty(ROSSOrder);

            return {
                data: { ROSSOrder, ContactId, options, error, token, orderDataLoaded },
            };
        }
        /**
         * END ROSS LOGIC
         * */

        // Get orderNumber, paymentStatus, trackingNumber, and carrier by uuid
        // const graphqlEnabled = false;
        // if (graphqlEnabled) {
        //     if (isAuthenticated && !orderNumber && graphqlEnabled) {
        //         const { data: { orderList } } = await this.fetchOrdersByEmail();
        //         if (!isEmpty(orderList)) {
        //             try {
        //                 const index = orderList.findIndex((order) => order.uuid === uuid);
        //                 if (index > -1) {
        //                 // eslint-disable-next-line object-curly-newline
        //                     ({ orderNumber, items: [{ paymentStatus, status, trackingNumber, carrier }] } = orderList[index]);
        //                 } else {
        //                 // eslint-disable-next-line object-curly-newline
        //                     return { data: { orderDetails, ContactId, error } };
        //                 }
        //             } catch (ex) {
        //             // eslint-disable-next-line object-curly-newline
        //                 return { data: { orderDetails, ContactId, error } };
        //             }
        //         }
        //     }

        //     if (!orderNumber || (!isAuthenticated && !update && orderNumber && !zipCode && !billingZipCode)) {
        //         return {
        //             data: { orderDetails, ContactId, error },
        //         };
        //     }

        //     let graphQL = '';
        //     if (isAuthenticated) {
        //         graphQL = getDetailedOrderByIdQL(orderNumber);
        //     } else if (zipCode && billingZipCode) {
        //         if (!update) {
        //         // Get order by order number, recipient and billing zip code
        //             graphQL = getDetailedOrderByIdAndZipcodeQL(orderNumber, zipCode, billingZipCode);
        //         } else {
        //         // Get order details after recipient and billing zip code validation
        //             graphQL = getDetailedOrderByIdQL(orderNumber);
        //         }
        //     } else {
        //         graphQL = getDetailedOrderGuestByIdQL(orderNumber);
        //     }

        //     this.wcEnv.rootUri = '/';
        //     const resourcePage = 'orderhistory/graphql';
        //     const payload = {
        //         query: graphQL,
        //     };
        //     const response = await WcClient.wcPostFacade(this.wcEnv, resourcePage, accessToken, payload);

        //     delete response.headers;
        //     delete response.request;
        //     delete response.config;
        //     try {
        //     // eslint-disable-next-line object-curly-newline
        //         ({ data: { data: { getDetailedOrderById: { orderInfoList, error, token } } } } = response);
        //     } catch (ex) {
        //         try {
        //         // eslint-disable-next-line object-curly-newline
        //             ({ data: { data: { getOrderByIdAndZipcode: { orderInfoList, error } } } } = response);
        //         } catch (e) {
        //             console.error('fetchOrderDetails', response);
        //         }
        //     }

        //     // Override shipping status, trackingNumber, and carrier if they are not defined from API response
        //     orderInfoList.forEach((item) => {
        //         if (!item.status && status) item.status = status;
        //         if (token) item.token = token;
        //         if (orderNumberPure) item.orderNumberPure = orderNumberPure;
        //         if (!item.paymentStatus && paymentStatus) item.paymentStatus = paymentStatus;
        //         if (!item.trackingNumber && trackingNumber) item.trackingNumber = trackingNumber;
        //         if (!item.carrier && carrier) item.carrier = carrier;
        //     });
        //     orderDetails[orderIndex] = orderInfoList;
        //     if (error && error.errorMessage) {
        //         error.errorMessage = error.errorMessage.replace(/\sid\s/, ' order number ').replace(/\szipcode\s/, ' zip code ');
        //     }
        // }
        try {
            // eslint-disable-next-line object-curly-newline
            return {
                data: { orderDetails, ContactId, options, error, token },
            };
        } catch (e) {
            console.error('fetchOrderDetails', e);
        }
        return false;
    };

    fetchEmailAddress = () => {
        const { email } = this.userProfile;
        return {
            data: { email },
        };
    };

    fetchEmailAddressFromId = async ({ indivId, accessToken }) => {
        const resourcePage = `giftlist/email?indiv_id=${indivId}`;
        const response = await WcClient.wcGetFacade(this.wcEnv, resourcePage, accessToken);
        const error = { errorMessage: GENERIC_ERROR };
        delete response.headers;
        delete response.request;
        delete response.config;
        try {
            const { data: { email } } = response;
            if (email) error.errorMessage = '';
        } catch (err) {
            console.error(err);
            error.errorMessage = err.description;
        }
        const codeSentToEmail = Helper.getSessionStorageObject('common', 'isCodeSentToEmail');
        const isCodeSentToEmail = (!isEmpty(codeSentToEmail) || typeof codeSentToEmail === 'boolean') ? codeSentToEmail : false;
        return {
            // eslint-disable-next-line object-curly-newline
            data: { indivId, isCodeSentToEmail, response, error },
        };
    }

    // sendCodeToEmail = async (data) => {
    //     const { email, custNbr } = data;
    //     const { auth_passwordless_client_id: passwordlessClientId, 'brand-id': brandId } = this.siteConfig;
    //     const error = { errorMessage: GENERIC_ERROR, type: 'inline', name: 'sendCodeToEmail' };
    //     // const routeBack = '/gifthistory-home-gift-baskets';
    //     const routeBack = '/account/gift-list';
    //     const auth0 = new Auth(passwordlessClientId, brandId, { custNbr, routeBack });
    //     let response = {};
    //     // Store custNbr in storage for calling add giftList by customer# after returning from Auth0
    //     Helper.setSessionStorageObject('common', { custNbr }, false);
    //     Helper.setSessionStorageObject('common', { isCodeSentToEmail: true });
    //     await auth0.handlePasswordlessStart({
    //         connection: 'email',
    //         send: 'code',
    //         email,
    //     })
    //         .then((result) => {
    //             response = result.data;
    //             error.errorMessage = '';
    //         })
    //         .catch((err) => {
    //             console.error('Error sending code: ', err);
    //             Helper.setSessionStorageObject('common', { isCodeSentToEmail: false });
    //         });
    //     return {
    //         data: { ...response, error },
    //     };
    // }

    // auth0PasswordlessLogin = async () => {
    //     const { auth_client_id: clientId, 'brand-id': brandId } = this.siteConfig;
    //     const routeBack = '/';
    //     const magicLogin = 'Y';
    //     const auth0 = new Auth(clientId, brandId, { routeBack, magicLogin });
    //     await auth0.login();
    //     return {
    //         data: {
    //             auto0Login: true,
    //         },
    //     };
    // }

    // submitGiftListVerificationCode = async (data) => {
    //     const { email, code } = data;
    //     const { auth_passwordless_client_id: passwordlessClientId, 'brand-id': brandId } = this.siteConfig;
    //     const error = { errorMessage: GENERIC_ERROR, type: 'field', name: 'verificationCode' };
    //     let response = {};
    //     const auth0 = new Auth(passwordlessClientId, brandId);
    //     await auth0.handlePasswordlessLogin({
    //         connection: 'email',
    //         verificationCode: code,
    //         email,
    //     })
    //         .then((result) => {
    //             response = result.data;
    //             error.errorMessage = '';
    //         })
    //         .catch((err) => {
    //             console.error('Error submitting code: ', err);
    //             if (err.error === 'access_denied') {
    //                 error.errorMessage = 'Invalid or expired code, please try again.';
    //             } else {
    //                 error.errorMessage = err.description;
    //             }
    //         });
    //     return {
    //         data: { ...response, error },
    //     };
    // }

    fetchEmailPreference = async () => {
        const { ContactId, email: customDataEmail, accessToken } = this.userProfile;
        const { email: profileEmail } = Helper.getSessionStorageObject(ContactId, 'userProfile');
        const currentEmail = profileEmail || customDataEmail;
        const message = 'Email address not found in the system.';
        const error = { message };
        let isSubscribed = false;
        this.wcEnv.rootUri = '/';
        const resourcePage = `profile/site/18F/user/${currentEmail}`;
        const response = await WcClient.wcGetFacade(this.wcEnv, resourcePage, accessToken, { rnd: Math.random() });
        delete response.headers;
        delete response.request;
        delete response.config;
        try {
            const { data: { retrieveEmailResponse: { retrieveEmailResponseResult: { retrieveEmailResult: { retrieveEmailMessage } } } } } = response;
            if (retrieveEmailMessage === 'Active') {
                isSubscribed = true;
                error.message = '';
            } else if (retrieveEmailMessage === 'Unsubscribed') {
                error.message = '';
            } else if (retrieveEmailMessage === 'NOTFOUND') {
                // NOTFOUND is Unsubscribed too
                error.message = '';
            } else if (retrieveEmailMessage === 'Held') {
                error.message = 'Email address is in Held status after multiple email bounces.';
            } else {
                error.message = retrieveEmailMessage;
            }
        } catch (err) {
            console.error(err);
            return {
                // eslint-disable-next-line object-curly-newline
                data: { response, isSubscribed, ContactId, error: { message: err.message } },
            };
        }
        const { timestamp } = Helper.getSessionStorageObject(ContactId, 'emailPreference');
        return {
            // eslint-disable-next-line object-curly-newline
            data: { response, isSubscribed, email: currentEmail, ContactId, error, timestamp },
        };
    };

    updateEmailPreference = async (data, requestAction) => {
        const { ContactId, email: customDataEmail, accessToken } = this.userProfile;
        const { email: profileEmail } = Helper.getSessionStorageObject(ContactId, 'userProfile');
        const currentEmail = profileEmail || customDataEmail;
        const message = 'Email address not found in the system.';
        const error = { message };
        let isSubscribed = false;
        const action = requestAction || ((data && data.offer) ? 'SUBSCRIBE' : 'DELETE');
        const payload = {
            email: currentEmail,
        };
        this.wcEnv.rootUri = '/';
        const resourcePage = `profile/site/18F/user/${currentEmail}/action/${action}`;
        const response = await WcClient.wcPostFacade(this.wcEnv, resourcePage, accessToken, payload);
        delete response.headers;
        delete response.request;
        delete response.config;
        try {
            const { data: { updateEmailResponse: { updateEmailResponseResult: { updateEmailResult: { updateEmailMessage } } } } } = response;
            if (/^OK--Created/.test(updateEmailMessage)) {
                isSubscribed = true;
                error.message = '';
            } else if (updateEmailMessage === 'Unsubscribed') {
                error.message = '';
            } else if (updateEmailMessage === 'Held') {
                error.message = 'Email address is in Held status after multiple email bounces.';
            } else {
                error.message = updateEmailMessage;
            }
        } catch (err) {
            console.error(err);
            return {
                // eslint-disable-next-line object-curly-newline
                data: { response, isSubscribed, ContactId, error: { message: err.message } },
            };
        }
        const timestamp = new Date().getTime();
        const object = {
            emailPreference: {
                isSubscribed,
                timestamp,
            },
        };
        Helper.setSessionStorageObject(ContactId, object);
        return {
            // eslint-disable-next-line object-curly-newline
            data: { response, isSubscribed, email: currentEmail, ContactId, error, timestamp },
        };
    };

    fetchSubscriptions = async ({ customerId, brand, subscriptions }) => {
        if (subscriptions.length) {
            return {
                data: { subscriptions },
                error: false,
            };
        }

        try {
            if (!brand || !brand['ibmcloud-api-url'] || !brand['ibmcloud-client-id'] || !brand['ibmcloud-secret'] || !brand['ibmcloud-token']) {
                throw new Error('missing brand parameters');
            }

            const ibmcloudApiUrl = brand['ibmcloud-api-url'];
            const ibmcloudClientId = brand['ibmcloud-client-id'];
            const ibmcloudSecret = brand['ibmcloud-secret'];
            const ibmcloudToken = brand['ibmcloud-token'];

            const dataResponse = await fetch(`https://${ibmcloudApiUrl}/rules/subscription/get-my-subscription`, {
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    'x-ibm-client-id': ibmcloudClientId,
                    'x-ibm-client-secret': ibmcloudSecret,
                    authorization: `Bearer ${ibmcloudToken}`,
                },
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify({ customerId, page: 0, size: 200 }),
            });

            if (!dataResponse.ok) {
                if (dataResponse.status === 404) {  // no subscriptions found for this customer
                    return {
                        data: { subscriptions: [] },
                        error: false,
                    };
                }

                throw new Error(`unexpected response from ibmcloud data fetch: ${dataResponse}`);
            }

            const data = await dataResponse.json();
            const { mySubscriptionResult } = data;
            subscriptions = [...mySubscriptionResult];

            return {
                data: { subscriptions },
                error: null,
            };
        } catch (err) {
            const message = `error in fetch or json parse: ${err}`;
            mbpLogger.logError({
                function: 'Services.fetchSubscriptions',
                module: 'mbp-account-ui',
                message,
            });
            return {
                data: { subscriptions: [] },
                error: { message },
            };
        }
    };

    cancelSubscription = async ({ ruleId, brand }) => {
        try {
            if (!brand || !brand['ibmcloud-api-url'] || !brand['ibmcloud-client-id'] || !brand['ibmcloud-secret'] || !brand['ibmcloud-token']) {
                throw new Error('missing brand parameters');
            }

            const ibmcloudApiUrl = brand['ibmcloud-api-url'];
            const ibmcloudClientId = brand['ibmcloud-client-id'];
            const ibmcloudSecret = brand['ibmcloud-secret'];
            const ibmcloudToken = brand['ibmcloud-token'];

            const dataResponse = await fetch(`https://${ibmcloudApiUrl}/rules/subscription/cancel-subscription`, {
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    accept: 'application/json',
                    'x-ibm-client-id': ibmcloudClientId,
                    'x-ibm-client-secret': ibmcloudSecret,
                    authorization: `Bearer ${ibmcloudToken}`,
                },
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify({ ruleId, cancelledFlag: true }),
            });

            if (!dataResponse.ok) {
                throw new Error(`unexpected response from ibmcloud: ${dataResponse}`);
            }

            return {
                data: { ruleId },
                error: null,
            };
        } catch (err) {
            const message = `error in fetch or json parse: ${err}`;
            mbpLogger.logError({
                function: 'Services.cancelSubscription',
                module: 'mbp-account-ui',
                ruleId,
                message,
            });
            return {
                data: null,
                error: { message },
            };
        }
    };

    updateFirstnameLastname = async (data) => {
        const { firstName, lastName } = data;
        const accessToken = await Auth.getAccessTokenSafely();
        const { sub } = this.userProfile;
        const error = { message: GENERIC_ERROR };
        const resourcePage = `profile/auth0/${sub.replace('|', '%7C')}`;
        const payload = {
            givenName: firstName,
            familyName: lastName,
        };
        let profile;
        let response;
        this.wcEnv.rootUri = '/';
        await WcClient.wcPatchFacade(this.wcEnv, resourcePage, accessToken, payload)
            .then((result) => {
                response = result;
                delete response.headers;
                delete response.request;
                delete response.config;
            })
            .catch((err) => {
                ({ response } = err);
                try {
                    if (response.status === 401) {
                        error.message = 'Sorry, there was a problem. Please try logging in again.';
                    } else if (response.status === 429) {
                        error.message = 'Your account is temporarily disabled. Please check your email for instructions on how to update your account.';
                    }
                } catch (ex) {
                    response = err;
                }
            });
        try {
            if (response.data) {
                if (response.data.errorMessage) error.message = response.data.errorMessage;
                if (response.data.status === 'success') {
                    error.message = '';
                    // update firstName and lastName in localStorage
                    const persistRoot = sessionStorage.getItem('persist:root');
                    const persistRootObj = JSON.parse(persistRoot);
                    const memberObj = JSON.parse(persistRootObj.member);
                    if (memberObj) {
                        ({ profile } = memberObj);
                        if (!isEmpty(profile)) {
                            profile.firstName = firstName;
                            profile.lastName = lastName;
                            memberObj.profile = profile;
                            persistRootObj.member = JSON.stringify(memberObj);
                            sessionStorage.setItem('persist:root', JSON.stringify(persistRootObj));
                        }
                    }
                }
            }
            // eslint-disable-next-line no-empty
        } catch (ex) { }

        return {
            // eslint-disable-next-line object-curly-newline
            data: { response, error },
        };
    };

    updatePassword = async (data) => {
        const { currentPassword, newPassword } = data;
        const { email, sub, accessToken } = this.userProfile;
        const error = { message: GENERIC_ERROR };
        const resourcePage = `profile/auth0/${sub.replace('|', '%7C')}`;
        const payload = {
            logonId: email,
            oldPassword: currentPassword,
            password: newPassword,
        };
        let response;
        this.wcEnv.rootUri = '/';
        await WcClient.wcPatchFacade(this.wcEnv, resourcePage, accessToken, payload)
            .then((result) => {
                response = result;
                delete response.headers;
                delete response.request;
                delete response.config;
            })
            .catch((err) => {
                ({ response } = err);
                try {
                    if (response.status === 401) {
                        error.message = 'Sorry, your password was incorrect. Please double-check your password.';
                    } else if (response.status === 429) {
                        error.message = 'Your account is temporarily disabled. Please check your email for instructions on how to update your account.';
                    } else if (response.status === 503) {
                        error.message = 'Your account is temporarily disabled. Please check your email for instructions on how to update your account.';
                    }
                } catch (ex) {
                    response = err;
                }
            });
        try {
            if (response.data) {
                if (response.data.errorMessage) error.message = response.data.errorMessage;
                if (response.data.status === 'success') {
                    error.message = '';
                }
            }
            // eslint-disable-next-line no-empty
        } catch (ex) { }

        return {
            // eslint-disable-next-line object-curly-newline
            data: { response, error },
        };
    };

    changeNewEmailAddress = async (data) => {
        const { confirmnewemail } = data;
        const { ContactId, email, sub, accessToken } = this.userProfile;
        const message = 'Email address not found in the system.';
        const error = { message };
        let newEmail = '';
        const resourcePage = `profile/auth0/${sub.replace('|', '%7C')}`;
        const payload = {
            emailId: confirmnewemail,
        };
        this.wcEnv.rootUri = '/';
        const response = await WcClient.wcPatchFacade(this.wcEnv, resourcePage, accessToken, payload)
            // .then(async (result) => {
            //     if (result.status === 200) {
            //     }
            //     return {};
            // })
            .catch((r) => {
                try {
                    error.message = r.response.data.message;
                } catch (e) {
                    error.message = r.message;
                }
                return {
                    data: { error },
                };
            });
        delete response.headers;
        delete response.request;
        delete response.config;
        if (response.data) {
            if (response.data.errorMessage) error.message = response.data.errorMessage;
            if (response.data.email) {
                newEmail = response.data.email;
                error.message = '';
            } else if (response.data.status === 'success') {
                newEmail = confirmnewemail;
                error.message = '';
            }
        }
        if (newEmail) {
            // Get previous email preference status
            const { data: { isSubscribed: prevSubscription } } = await this.fetchEmailPreference();
            if (prevSubscription) {
                // Unsubscribe previous email address
                await this.updateEmailPreference(null, 'DELETE');
            }
            const object = {
                userProfile: {
                    email: newEmail,
                    timestamp: new Date().getTime(),
                },
            };
            // Store new userProfile
            Helper.setSessionStorageObject(ContactId, object);
            if (prevSubscription) {
                // Subscribe new email address
                await this.updateEmailPreference(null, 'SUBSCRIBE');
            }
        }

        return {
            data: { response, email, newEmail, error },
        };
    };

    removeGiftListOrAddressBookRecipient = async (data) => {
        const { AddressBookEntryId, recipient: { occasionId, orderItemId }, addressType, currentPage, recipientsByPage } = data;
        const { ContactId, accessToken } = this.userProfile;
        const errorMessage = ['<p class="bold">', 'Oops...', '</p><p>', 'Something went wrong on the page!', '</p>'].join('');
        let response = {};
        if (addressType === 'ADDRESS_BOOK') {
            this.wcEnv.rootUri = '/';
            const resourcePage = `addressbook/contacts/${ContactId}/entries/${AddressBookEntryId}`;
            response = await WcClient.wcDeleteFacade(this.wcEnv, resourcePage, accessToken, {});
            delete response.headers;
            delete response.request;
            delete response.config;
            return {
                data: { response, ContactId },
                error: false,
            };
        }
        // if addressType === 'GIFT_LIST'
        this.wcEnv.rootUri = '/';
        const resourcePage = `giftlist/${occasionId}/recipient/${orderItemId}`;
        response = await WcClient.wcDeleteFacade(this.wcEnv, resourcePage, accessToken, {})
            .then(async (result) => {
                if (result.status === 200) {
                    // Remove gift list product from cart
                    await this.removeProductFromCart(data);

                    // Goto previous page if the last recipient has been deleted
                    const newPage = (recipientsByPage.length === 1 && currentPage > 1) ? (currentPage - 1) : currentPage;
                    // get recipients by page
                    const responseGiftList = await this.fetchGiftListRecipients({ setOccasionId: occasionId, newPage }, true);
                    return { giftList: { ...responseGiftList.data }, status: 200 };
                }
                return {};
            })
            .catch((r) => ({
                data: { error: { errorMessage: r.message } },
                error: false,
            }));
        return {
            data: { response, ContactId, error: { errorMessage } },
            error: false,
        };
    };

    encryptCreditCard = async (cardNumber, sourceID) => {
        const { accessToken } = this.userProfile;
        const resourcePage = 'cryptoservice/tokenize';
        const payload = {
            ccNumber: cardNumber,
            sourceID,
        };
        const error = {};
        this.wcEnv.rootUri = '/';
        return WcClient.wcPostFacade(this.wcEnv, resourcePage, accessToken, payload)
            .then((result) => {
                const response = result;
                delete response.headers;
                delete response.request;
                delete response.config;
                return {
                    data: { response, error },
                };
            })
            .catch((err) => ({ data: { response: {}, error: err } }));
    };

    saveCreditCard = async (data) => {
        const { wallet } = data;
        const { ContactId, accessToken } = this.userProfile;
        // const error = {};
        let response = {};
        return this.encryptCreditCard(wallet.cardNumber, wallet.sourceID)
            .then(async ({ data: { response: result, error } }) => {
                const errorObject = {};
                if (result.status === 200 && result.data.cyptoccNumber && result.data.cyptoccNumber !== 'FALSE') {
                    if (result.data.cyptoccNumber === 'ERROR') {
                        response = result;
                        errorObject.message = 'Please enter a valid card number.';
                        errorObject.type = 'field';
                        errorObject.name = 'cardnumber';
                    } else {
                        wallet.cardNumber = result.data.cyptoccNumber;
                        if (wallet.walletId) {
                            const resourcePage = `wallets/${ContactId}/cards/${wallet.walletId}`;
                            const payload = { wallet };
                            this.wcEnv.rootUri = '/';
                            response = await WcClient.wcPutFacade(this.wcEnv, resourcePage, accessToken, payload);
                        } else {
                            const resourcePage = `wallets/${ContactId}/cards`;
                            const payload = { wallet };
                            this.wcEnv.rootUri = '/';
                            response = await WcClient.wcPostFacade(this.wcEnv, resourcePage, accessToken, payload);
                        }
                    }
                } else {
                    response = result;
                    errorObject.message = error.message || 'Oops! Something went wrong.';
                    errorObject.type = 'popup';
                }
                delete response.headers;
                delete response.request;
                delete response.config;
                return {
                    data: { response, ContactId, error: errorObject },
                };
            })
            .catch((err) => ({
                data: { response, ContactId, error: err },
            }));
    };

    removeCreditCard = async (data) => {
        const { walletId } = data;
        const { ContactId, accessToken } = this.userProfile;
        const resourcePage = `wallets/${ContactId}/cards/${walletId}`;
        const payload = {};
        this.wcEnv.rootUri = '/';
        const response = await WcClient.wcDeleteFacade(this.wcEnv, resourcePage, accessToken, payload);
        delete response.headers;
        delete response.request;
        delete response.config;
        return {
            data: { response, ContactId },
            error: false,
        };
    };

    setDefaultCreditCard = async (data) => {
        const { walletId } = data;
        const { ContactId, accessToken } = this.userProfile;
        const resourcePage = `wallets/${ContactId}/cards/${walletId}`;
        const payload = {
            wallet: {
                walletId,
                subsIndicator: 1,
            },
        };
        this.wcEnv.rootUri = '/';
        const response = await WcClient.wcPutFacade(this.wcEnv, resourcePage, accessToken, payload);
        delete response.headers;
        delete response.request;
        delete response.config;
        return {
            data: { response, ContactId },
            error: false,
        };
    };

    // QAS ADDRESS SEARCH/FORMAT
    // This should be passed a params object that contains the searchString and the country
    searchAddress = async ({ params }) => {
        const response = await QasClient.qasGet({}, 'search', params, true);
        let searchResults = [];
        try {
            if (response.status === 200 && !isEmpty(response.data) && !isEmpty(response.data.results)) {
                searchResults = response.data.results;
            }
        } catch (err) {
            console.error(err);
        }
        return {
            data: { searchResults },
        };
    };

    // This expects a passed address response object that was selected.
    // The object will have a "format" key and is required to parse.
    selectAddress = async ({ address }) => {
        const { format } = address;
        const formatParams = qs.parse(format.substring(format.indexOf('?') + 1));
        const response = await QasClient.qasGet({}, 'format', formatParams);
        const error = {};
        try {
            const { data: { Message } } = response;
            if (Message) error.errorMessage = Message;
        } catch (err) {
            console.error(err);
        }
        return {
            data: { response, error },
        };
    };

    updateRecipientAddress = async (data) => {
        const {
            currentPage,
            occasionId,
            orderItemId,
            templateName,
            AddressBookEntryId,
            recipientData,
            recipientData: { IsBillingAddress, address, addressEntry },
            recipient,
        } = data;
        const { ContactId, accessToken } = this.userProfile;
        let response = {};
        if (templateName !== 'GiftListRecipientForm') {
            if (IsBillingAddress) {
                const resourcePage = `addressbook/contacts/${ContactId}/billingAddress`;
                this.wcEnv.rootUri = '/';
                response = await WcClient.wcPutFacade(this.wcEnv, resourcePage, accessToken, recipientData);
            } else if (AddressBookEntryId) {
                const resourcePage = `addressbook/contacts/${ContactId}/entries/${AddressBookEntryId}/address`;
                this.wcEnv.rootUri = '/';
                response = await WcClient.wcPutFacade(this.wcEnv, resourcePage, accessToken, recipientData);
            } else {
                const resourcePage = `addressbook/contacts/${ContactId}/entryandaddress`;
                this.wcEnv.rootUri = '/';
                response = await WcClient.wcPostFacade(this.wcEnv, resourcePage, accessToken, recipientData);
            }
        } else {
            const resourcePage = orderItemId ? `giftlist/${occasionId}/recipient/${orderItemId}` : `giftlist/${occasionId}/recipient`;
            const profile = {
                firstName: addressEntry.FirstName,
                lastName: addressEntry.LastName,
                locationType: address.AddressType,
                businessTitle: '',
                organizationName: addressEntry.EmployerName,
                phoneNumber: addressEntry.PhoneNumber,
                address1: address.AddressLineOne,
                address2: address.AddressLineTwo,
                city: address.City,
                state: address.StateProvince,
                zipCode: address.PostalCode,
                country: address.Country,
            };
            if (occasionId) {
                this.wcEnv.rootUri = '/';
                const wcPromise = orderItemId ? await WcClient.wcPutFacade(this.wcEnv, resourcePage, accessToken, profile) : await WcClient.wcPostFacade(this.wcEnv, resourcePage, accessToken, profile);
                if (wcPromise.status === 200 && !isEmpty(wcPromise.data) && wcPromise.data.addressId) {
                    response = await this.fetchGiftListRecipients({ setOccasionId: occasionId, newPage: currentPage }, true);
                    response.data.Result = {
                        occasionId,
                        orderItemId,
                        addressId: wcPromise.data.addressId, // return new addressId
                        templateName,
                        AddressBookEntryId,
                        ...recipientData,
                        ...response.data,
                    };
                    // Remove gift list product from cart
                    if (orderItemId && recipient.addToCart && !isEmpty(recipient.cartDetail)) {
                        await this.removeProductFromCart(data);
                    }
                }
            }
        }
        const { Result } = response.data;
        return {
            data: { response: Result, ContactId, occasionId, templateName, error: {} },
        };
    };

    getProductDetailBySKU = async (productId) => {
        const { ContactId, accessToken } = this.userProfile;
        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        const partNumber = `${this.partnumPrefix}${productId}`;
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (
                storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].productsBySKU
                && storageJson[ContactId].productsBySKU[partNumber]) {
                const productsBySKU = storageJson[ContactId].productsBySKU[partNumber];
                return {
                    ...productsBySKU,
                };
            }
        }

        this.wcEnv.rootUri = '/';
        const resourcePage = 'aggregator/graphql';
        const payload = {
            query: getProductDetailByItemNumberQL([partNumber], this.domain, GRAPHQL_ENV),
        };
        const result = await WcClient.wcPostFacade(this.wcEnv, resourcePage, accessToken, payload);
        // Update sessionStorage
        if (result.status === 200 && !isEmpty(result.data.data) && !isEmpty(result.data.data.findSkuByPartNumbers)) {
            if (!storageJson[ContactId]) {
                storageJson = { [ContactId]: {} };
            }
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                productsBySKU: { [partNumber]: { ...result.data, status: result.status } },
                timestamp: new Date().getTime(),
            };
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
            return { ...result.data, status: result.status };
        }
        return {};
    };

    addProductToCart = async (data) => {
        const { accessToken } = this.userProfile;
        const { productId, recipient } = data;
        const {
            orderItemId,
            firstname: firstName,
            lastname: lastName,
            products,
            address: {
                street: address1,
                apt: address2,
                location: {
                    name: locationType,
                },
                city,
                state: {
                    id: stateID,
                },
                country: {
                    id: countryID,
                },
                zipcode: zipCode,
            },
        } = recipient;
        let brandCode = this.siteConfig['product-brand-id'];
        let errorMessage = '<p class="bold">Oops...</p><p>Something went wrong on the page!';
        let response = {};
        let selectedProduct = {};
        let productCode = '';
        let parentCatalogEntryId = '';
        let productsInCart = [];
        let productAddedToCart = '';
        let isCartUpdated = true;
        let itemsInCart = {};
        let seoproductDisplayURL = '';
        let productSkus = [];
        let productType = '';
        let isPersonalizable = false;
        const productIndex = products.findIndex((p) => p.id === productId);
        const giftHistoryOrderItemId = orderItemId || '';
        // Get xOrderAttrValues from the first product for 4.2
        const [{
            xOrderAttrValues = {}, // set xOrderAttrValues' default value to {} preventing error from old data
        }] = products;
        // If product found in the recommendation list
        if (productIndex > -1) {
            selectedProduct = products[productIndex];
            ({
                partNumber: productCode,
                parentCatalogEntryId,
                seoproductDisplayURL,
                customAttributes: {
                    productSkus,
                    productType,
                    isPersonalizable,
                },
            } = selectedProduct);
            const ageVerifyFlag = (!isEmpty(productSkus) && productSkus[0].ageVerifyFlag) ? productSkus[0].ageVerifyFlag : 'false';
            // Wine product:     ageVerifyFlag === 'true'  && productType === 'COLLECTION'
            // CYO Wine product: ageVerifyFlag === 'true'  && productType === 'CUSTOM_ASSORTMENT'
            // CYO product:      ageVerifyFlag === 'false' && productType === 'CUSTOM_ASSORTMENT'
            // PYO product:      ageVerifyFlag === 'false' && productType === 'REGULAR_PRODUCT' && isPersonalizable === true
            // Normal product:   ageVerifyFlag === 'false' && productType === 'COLLECTION'
            // Normal product:   ageVerifyFlag === 'false' && productType === null
            const specialProduct = ageVerifyFlag !== 'false' || productType === 'CUSTOM_ASSORTMENT' || isPersonalizable === true;
            if (specialProduct) {
                // Redirect users to PDP with Shop the Site condition
                // after adding to cart, users can go back to the gift list page by clicking
                // on the `RETUR TO GIFT LIST` link on Mini Cart popup
                if (seoproductDisplayURL) {
                    response.seoproductDisplayURL = seoproductDisplayURL;
                    errorMessage = '';
                    // Store recipient in the Shop the Site session; and it'll be used when add to cart on PDP
                    const recipientWithGiftListURL = { ...recipient, giftListURL: '/account/gift-list' };
                    this.storeShopTheSiteConfirmationInSessionStorage({ object: { [SHOP_THE_SITE_KEY]: recipientWithGiftListURL } });
                    return {
                        data: { response, isCartUpdated: false, productAddedToCart, error: { errorMessage } },
                    };
                }
            }
        } else {
            productCode = productId;
            // Get product detail by SKU Number to check whether user has entered the right product number
            const productDetails = await this.getProductDetailBySKU(productId);
            // If user entered the right product number
            if (
                productDetails.status === 200
                && !isEmpty(productDetails.data?.findSkuByPartNumbers[0]?.parentProduct)
            ) {
                parentCatalogEntryId = '';
                // Destruct product's partNumber and product's URL
                const { partNumber, seo: { url } } = productDetails.data.findSkuByPartNumbers[0].parentProduct;
                if (partNumber) {
                    this.wcEnv.rootUri = '/';
                    const resourceURL = 'aggregator/graphql';
                    const payload = {
                        query: findProductByPartNumbersQL([partNumber], this.domain, GRAPHQL_ENV),
                    };
                    // Verify if it's a special product
                    const customAttr = await WcClient.wcPostFacade(this.wcEnv, resourceURL, accessToken, payload);
                    try {
                        const customAttributes = customAttr.data?.data?.findProductByPartNumbers;
                        ({ productType, productSkus, isPersonalizable } = customAttributes[0]);
                        const ageVerifyFlag = (!isEmpty(productSkus) && productSkus[0].ageVerifyFlag) ? productSkus[0].ageVerifyFlag : 'false';
                        // Wine product:     ageVerifyFlag === 'true'  && productType === 'COLLECTION'
                        // CYO Wine product: ageVerifyFlag === 'true'  && productType === 'CUSTOM_ASSORTMENT'
                        // CYO product:      ageVerifyFlag === 'false' && productType === 'CUSTOM_ASSORTMENT'
                        // PYO product:      ageVerifyFlag === 'false' && productType === 'REGULAR_PRODUCT' && isPersonalizable === true
                        // Normal product:   ageVerifyFlag === 'false' && productType === 'COLLECTION'
                        // Normal product:   ageVerifyFlag === 'false' && productType === null
                        const specialProduct = ageVerifyFlag !== 'false' || productType === 'CUSTOM_ASSORTMENT' || isPersonalizable === true;
                        // If it's a special product and has a product's URL, set redirect to PDP with Shop the Site condition
                        if (specialProduct && url) {
                            response.seoproductDisplayURL = url;
                            errorMessage = '';
                            // Store recipient in the Shop the Site session; and it'll be used when add to cart on PDP
                            const recipientWithGiftListURL = { ...recipient, giftListURL: '/account/gift-list' };
                            this.storeShopTheSiteConfirmationInSessionStorage({ object: { [SHOP_THE_SITE_KEY]: recipientWithGiftListURL } });
                            return {
                                data: { response, isCartUpdated: false, productAddedToCart, error: { errorMessage } },
                            };
                        }
                    } catch (ex) {
                        // Couldn't get custom attribute; we'll return value as product not found
                        errorMessage = `The product code ${productId} was not found.`;
                        return {
                            data: { response: productDetails,
                                error: {
                                    errorMessage,
                                    type: 'field', // Set as error input field
                                    name: 'itemnumber', // input field name in the form
                                },
                            },
                        };
                    }
                }
            } else {
                errorMessage = `The product code ${productId} was not found.`;
                return {
                    data: { response: productDetails,
                        error: {
                            errorMessage,
                            type: 'field', // Set as error input field
                            name: 'itemnumber', // input field name in the form
                        },
                    },
                };
            }
        }

        // Get brandCode based on SKU for cross site product
        if (/^[\d+]+-I-[\d+]+[a-zA-Z]?$/.test(productCode)) {
            const newBrandCode = productCode.replace(/^([\d+]+)-I-[\d+]+[a-zA-Z]?$/, '$1');
            if (newBrandCode.length === 4) {
                brandCode = newBrandCode;
            }
        }

        await orderClient.addToCart(
            this.wcEnv,
            accessToken,
            [
                {
                    firstName,
                    lastName,
                    address1,
                    address2,
                    address3: '',
                    addressTypeIndicator: locationType === 'Business' ? 'B' : 'R', // B=Business/R=Residential
                    city,
                    confirmEmail: '',
                    country: countryID,
                    email: '',
                    locationType, // "Residence,Business,Funeral home,Hospital,Apartment,School,Church,APO/FPO,P.O.Box",
                    mobilePhone: '',
                    organizationName: '',
                    phone: '',
                    state: stateID,
                    zipCode,
                },
            ],
            {
                productCode,
                brandCode,
                productId: parentCatalogEntryId,
                quantity: 1,
                // Converting xOrderAttrValues object to attributes: [{ attributeName, attributeValue }, ...]
                attributes: Object.keys(xOrderAttrValues).map((key) => ({ attributeName: key, attributeValue: xOrderAttrValues[key] })),
            },
            giftHistoryOrderItemId,
        )
            .then(async (result) => {
                response = result.data;
                response.status = result.status;
                response.productId = productCode;
                errorMessage = '';
                productAddedToCart = productCode;
                const orderItemIds = response.orderItemId;
                const cartOrderItemId = Array.isArray(orderItemIds) ? orderItemIds[0] : orderItemIds;
                const orderIdFromCart = !isEmpty(response.orderId) ? response.orderId[0] : '';
                // Always add recipient to cart after adding product to cart
                if (response.status) {
                    await this.addGiftListRecipientToCart(cartOrderItemId, recipient)
                        .catch(async (error) => {
                            // await orderClient.removeOrderItem(this.wcEnv, accessToken, orderItemId, orderIdFromCart);
                            productAddedToCart = '';
                            isCartUpdated = false;
                            // Set generic error message
                            errorMessage = `<p class="bold">We're sorry!</p><p>The product '${productCode}' couldn't be added to add to cart.</p><p>Please try again later or contact customer service for assistance.</p>`;
                            // Override error message if it's available in error response
                            if (error.response?.data?.detailedError?.errorMessage) {
                                errorMessage = `<p class="bold">We're sorry!</p><p>${error.response?.data?.detailedError?.errorMessage}</p><p>Please try again later or contact customer service for assistance.</p>`;
                            }
                        });
                }
                if (orderIdFromCart) {
                    ({ itemsInCartByAddressId: productsInCart, itemsInCart } = await this.getProductsInCart(orderIdFromCart));
                    if (!isEmpty(productsInCart)) {
                        const getItemDetailsFromCart = Helper.getItemDetailsFromCart(orderItemId, productsInCart);
                        if (!getItemDetailsFromCart.hasDeliveryDate) {
                            // Add Gift List's recipient to cart when the delivery date in cart is empty
                            // await this.addGiftListRecipientToCart(cartOrderItemId, recipient);
                            ({ itemsInCartByAddressId: productsInCart, itemsInCart } = await this.getProductsInCart(orderIdFromCart));
                        }
                        // Get product details for GA Tracking
                        response.productDetail = getItemDetailsFromCart.productDetail;
                    }
                }
                response.productsInCart = productsInCart;
                response.itemsInCart = itemsInCart;
            })
            .catch((error) => {
                productAddedToCart = '';
                isCartUpdated = false;
                // Set generic error message
                errorMessage = `<p class="bold">We're sorry!</p><p>The product '${productCode}' couldn't be added to add to cart.</p><p>Please try again later or contact customer service for assistance.</p>`;
                // Override error message if it's available in error response
                if (error.response?.data?.detailedError?.errorMessage) {
                    errorMessage = `<p class="bold">We're sorry!</p><p>${error.response?.data?.detailedError?.errorMessage}</p><p>Please try again later or contact customer service for assistance.</p>`;
                }
                // Try to redirect to PDP with Shop the Site condition when add to cart error
                // so that users won't lose track of adding gift to their recipient
                // after adding to cart, users can go back to the gift list page by clicking
                // on the `RETUR TO GIFT LIST` link on Mini Cart popup
                if (seoproductDisplayURL && isCartUpdated) {
                    response.seoproductDisplayURL = seoproductDisplayURL;
                    errorMessage = '';
                    // Store recipient in the Shop the Site session; and it'll be used when add to cart on PDP
                    const recipientWithGiftListURL = { ...recipient, giftListURL: '/account/gift-list' };
                    this.storeShopTheSiteConfirmationInSessionStorage({ object: { [SHOP_THE_SITE_KEY]: recipientWithGiftListURL } });
                }
            });
        return {
            data: { response, isCartUpdated, productAddedToCart, error: { errorMessage } },
        };
    };

    addGiftListRecipientToCart = async (orderItemId, recipient) => {
        const { accessToken } = this.userProfile;
        const { address, firstname, lastname, sourceAttributes: attributes } = recipient;
        const { source = 'wcs' } = attributes;
        this.wcEnv.rootUri = '/';
        const resourcePage = `checkout/cart/${orderItemId}/recipient`;
        const payload = {
            recipient: {
                address1: address.street,
                address2: address.apt,
                address3: '',
                city: address.city,
                country: address.country.id,
                dpvIndicator: 'Y',
                firstName: firstname || 'FirstName',
                isAddressVerified: 'Y', // Y/N/O
                isFormValid: false,
                isTelephoneMadatory: 'n',
                lastName: lastname,
                locationType: address.location.name,
                nameOfLocation: '',
                organizationName: address.company,
                phone: '',
                state: address.state.id,
                zipCode: address.zipcode,
            },
            updateStoredAddress: true,
        };
        if (source !== 'mongo') {
            payload.entryId = '';
        }
        const { data } = await WcClient.wcPutFacade(this.wcEnv, resourcePage, accessToken, payload).then((response) => response);
        return data;
    };

    updateOrderIdSession = (orderId) => {
        this.removeOrderIdSession();
        const persistCheckout = JSON.parse(sessionStorage.getItem('persist:checkout'));
        if (!isEmpty(persistCheckout) && /\{"orderId":""\}/.test(persistCheckout.order)) {
            persistCheckout.order = JSON.stringify({ orderId });
            sessionStorage.setItem('persist:checkout', JSON.stringify(persistCheckout));
        }
    };

    removeOrderIdSession = () => {
        const persistCheckout = JSON.parse(sessionStorage.getItem('persist:checkout'));
        if (!isEmpty(persistCheckout) && /\{"orderId":"[^"]+"\}/.test(persistCheckout.order)) {
            persistCheckout.order = JSON.stringify({ orderId: '' });
            sessionStorage.setItem('persist:checkout', JSON.stringify(persistCheckout));
        }
    };

    removeProductFromCart = async (data) => {
        const { accessToken } = this.userProfile;
        const { recipient: { cartDetail: cartData } } = data;
        let errorMessage = ['<p class="bold">', 'Oops...', '</p><p>', 'Something went wrong on the page!', '</p>'].join('');
        let response = {};
        let productsInCart = [];
        let itemsInCart = {};
        if (!isEmpty(cartData)) {
            const { orderItemId, orderId } = cartData;
            await orderClient.removeOrderItem(this.wcEnv, accessToken, orderItemId, orderId)
                .then(async (result) => {
                    response = result.data || {};
                    response.status = result.status;
                    errorMessage = '';
                    if (response.status === 200 && response.orderId) {
                        response.orderId = orderId;
                        ({ itemsInCartByAddressId: productsInCart, itemsInCart } = await this.getProductsInCart(orderId));
                    }
                    response.productsInCart = productsInCart;
                    response.itemsInCart = itemsInCart;
                })
                .catch((error) => {
                    console.error(error);
                    response.status = error.status;
                    return {
                        data: { response, isCartUpdated: false, error: { errorMessage } },
                    };
                });
        }
        return {
            data: { response, isCartUpdated: true, error: { errorMessage } },
        };
    };

    fetchModalContent = async (url) => {
        const { accessToken } = this.userProfile;
        this.wcEnv.rootUri = '/';
        const { data } = await WcClient.wcGetFacade(this.wcEnv, url, accessToken, {});
        return { data, error: false };
    };

    fetchCountryData = () => ({
        data: {
            locations: LocationTypes[this.brandName] || LocationTypes.default,
            states: Helper.refactorStateData(States),
            countries: Helper.refactorObjectData(Countries),
            APOFPO,
            relationships: Relationships,
            cardtypes: Helper.refactorObjectData(CreditCardTypes),
        },
        error: false,
    });
}
