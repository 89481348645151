/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { all } from 'redux-saga/effects';

import { accountSagas } from '../../app/pages/Account/state/sagas';
import { duck as tmDuck } from '../ducks/TagManager';
import memberWatcherSagas from '../ducks/Member/sagas';
import checkoutSagas from '../ducks/Checkout/Checkout-Operations';
import SCIWatchers from '../ducks/SCI/SCI-Operations';
import CGCWatcher from '../ducks/CGC/CGC-Operations';
import * as app from '../ducks/App';

// TODO: SA - Restore sagas
export const universalSagas = [
    ...app.watchers,
    ...checkoutSagas,
    ...memberWatcherSagas,
    accountSagas,
    ...tmDuck.operations.watchers,
    ...SCIWatchers,
    ...CGCWatcher,
];

export default function* rootSaga() {
    yield all(universalSagas);
}
