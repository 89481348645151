/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { getCountryName, getLanguageName } from '../../state/ducks/Member/ducks/Auth/helper/helper';

/**
 * Find host from either:
 *  1. window object
 *  2. env var (wc_host)
 *  3. default: localhost
*/
const getBrandHost = () => {
    const windowHostname = (typeof window !== 'undefined') && window.location && window.location.hostname;

    // Handle common SSR host (proxyserver.common)
    if (!windowHostname) {
        const { ssrConfig } = global.mbp;
        if (ssrConfig && ssrConfig.hostName) {
            return ssrConfig.hostName;
        }
    }

    if (windowHostname) {
        return windowHostname;
    }

    return null;
};

// @intention: make prefix path as {language/country} i.e en/usa base for internationalization
// @param: {string} url or slug
// @return: {string} internationalization prefix path
const determineInternationalizationPrefixPath = (url) => {
    const language = getLanguageName();
    const country = getCountryName();
    if (language && country) {
        const prefixPath = `${language}${country}${url}`;
        return prefixPath;
    }
    return url;
};

export const determineCollectionPrefixPath = (url, countryFlag = true) => {
    if (getBrandHost().includes('.plants') || getBrandHost().includes('-plants') || getBrandHost().includes('plants.') || getBrandHost().includes('plants-')) {
        if (url === '/zodiac-plants') {
            return `${url}`;
        }
        return `/c${url}`;
    }
    const language = getLanguageName();
    // /us just applies when we are in the homepage.
    const country = getCountryName() === '/' ? '/us' : getCountryName();
    if (language && country && url && url.length) {
        let prefixPath = '';
        if (countryFlag) {
            prefixPath = `${language}${country}/c${url}`;
        } else {
            prefixPath = `${language}/c${url}`;
        }
        return prefixPath;
    }
    return url;
};

export const setCollectionPrefixPath = (url) => `/c${url}`;

export const determineProductPrefixPath = (url, countryFlag = true) => {
    if (getBrandHost().includes('.plants') || getBrandHost().includes('-plants') || getBrandHost().includes('plants.') || getBrandHost().includes('plants-')) {
        return `/p${url}`;
    }
    const language = getLanguageName();
    const country = getCountryName();
    if (language && country) {
        let productURL = '';
        if (countryFlag) {
            productURL = `${language}${country}/p${url}`;
        } else {
            productURL = `${language}/p${url}`;
        }
        return productURL;
    }
    return url;
};

export const setProductPrefixPath = (url) => `/p${url}`;

export const determineLanguagePrefixPath = (url) => {
    if (getBrandHost().includes('.plants') || getBrandHost().includes('-plants') || getBrandHost().includes('plants.') || getBrandHost().includes('plants-')) {
        return url;
    }
    const language = getLanguageName();
    if (language) {
        const slugPath = `${language}${url}`;
        return slugPath;
    }
    return url;
};

export default determineInternationalizationPrefixPath;
