/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import $ from 'jquery';
import Cookies from 'universal-cookie';

import mbpLogger from 'mbp-logger';

import * as sessionTrackingHelpers from '../../session/sessionTrackingHelpers';
import { loadDataLayerForPageView, loadDataLayerForEvent } from './tealiumDataLayerHelpers';
import scriptCache from '../../../../components/Page/helpers/scriptCache';
import { when } from '../../../../components/Page/helpers/pageHelpers';

// LH Check
const lh = (lhFeature) => {
    // eslint-disable-next-line no-underscore-dangle
    const lhCheck = !!(window.__nativeURL || window.__nativePromise || window.__nativeError);

    if (lhFeature === 'Y') {
        return lhCheck;
    }

    // Default
    return false;
};

const handleVendorTags = () => {
    if (typeof window !== 'undefined' && typeof window.yotpo !== 'undefined' && typeof window.yotpo.refreshWidgets === 'function') {
        try {
            // Call refresh only if yotpo initialized
            if (window.yotpo.initialized) {
                window.yotpo.refreshWidgets();
            }
        } catch (exception) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                message: 'Unable to refresh the yotpo widget',
                jsError: exception,
            });
        }
    }
};

export const trackTealiumEvent = (config, rawDataLayer) => {
    mbpLogger.logDebug({
        appName: process.env.npm_package_name,
        config,
        rawDataLayer,
        message: 'TagManager | rawDataLayer',
    });

    // TODO: Pass in location and feature flags? maybe...
    // Check session attribution
    // sessionTrackingHelpers.validateSessionAttribution({ location, featureFlags });

    // Build Data Layer
    const vendorDataLayer = loadDataLayerForEvent(rawDataLayer);
    const sessionDataLayer = sessionTrackingHelpers.generateSessionDataLayer(vendorDataLayer);

    // Prevent duplicate GA trackers for post-pageview events
    vendorDataLayer.gua_tracker = 'false';

    const dataLayer = {
        ...vendorDataLayer,
        ...sessionDataLayer,
    };

    if (vendorDataLayer && window.utag && window.utag.link) {
        // Logging for attribution debugging
        if (typeof window !== 'undefined' && window.sessionHelpersLoggingEnabled && sessionDataLayer.campaignMedium === 'online_media') {
            mbpLogger.logError({
                function: 'attributionDebug',
                appName: process.env.npm_package_name,
                module: 'sesssionTrackingHelpers',
                message: `Event firing.
                    || Event: ${JSON.stringify(rawDataLayer)}`,
            });
        }
        window.utag.link(dataLayer);
    }

    return vendorDataLayer;
};

const checkAndLog = (sessionDataLayer) => {
    if (typeof window !== 'undefined' && window.sessionHelpersLoggingEnabled && sessionDataLayer.campaignMedium === 'online_media') {
        mbpLogger.logError({
            function: 'attributionDebug',
            appName: process.env.npm_package_name,
            module: 'sesssionTrackingHelpers',
            message: `PageView firing.
                || Session DataLayer: ${JSON.stringify(sessionDataLayer)}`,
        });
    }
};

const fireQueuedEvent = (queuedEvents) => {
    if (queuedEvents.length) {
        queuedEvents.forEach((evtDataLayer) => trackTealiumEvent({}, { ...evtDataLayer, nonInteraction: true }));
    }
    return queuedEvents.length;
};

const testCallback = () => ((window.utag && window.utag.handler.iflag === 1) || false);

const succssCallback = (location, featureFlags, dl, queuedEvents) => {
    // Process Session
    const processedSession = sessionTrackingHelpers.processCurrentSession({ location, featureFlags });

    if (processedSession) {
        const sessionDataLayer = sessionTrackingHelpers.generateSessionDataLayer(dl);
        const pageLoadDataLayer = {
            ...dl,
            ...sessionDataLayer,
        };

        // Logging for attribution debugging
        checkAndLog(sessionDataLayer);
        window.utag.view(pageLoadDataLayer);

        // Fire queued events
        fireQueuedEvent(queuedEvents);
    }
};

const getTIQENV = (env) => {
    const TIQ_ENV_COOKIE_NAME = 'tiqEnv';
    const cookies = new Cookies();
    return (cookies.get(TIQ_ENV_COOKIE_NAME)) ? cookies.get(TIQ_ENV_COOKIE_NAME) : env; // Determine correct env
};

const setOnTealiumTagLoad = (isTealiumTagsLoaded) => {
    if (isTealiumTagsLoaded) {
        window.utag_cfg_ovrd.dom_complete = true;
    }
    return isTealiumTagsLoaded;
};

// Exclude default TIQ data
const validKey = (key) => (
    // Tealium-based
    key.indexOf('cp.') === -1
    && key.indexOf('dom.') === -1
    && key.indexOf('meta.') === -1
    && key.indexOf('ut.') === -1
    && key.indexOf('tealium_') === -1
    && key.indexOf('timestamp') === -1

    // Generated
    && key.indexOf('product_') === -1
    && key.indexOf('category_') === -1
    && key.indexOf('checkout_') === -1
    && key.indexOf('customer_') === -1
    && key.indexOf('order_') === -1
    && key.indexOf('gua_') === -1
    && key.indexOf('ga_') === -1
);

const getUTagGetData = () => {
    const currentUtagData = {};
    Object.keys(window.utag_data).forEach((key) => {
        if (validKey(key)) {
            currentUtagData[key] = window.utag_data[key];
        }
    });
    return currentUtagData;
};

export const trackTealiumPageView = ({
    account, profile, env,
}, args) => {
    let dataLayer = null;
    const {
        lhCheckFlag,
        featureFlags,
        location,
        queuedEvents,
        ...data
    } = args;

    const lhFeature = lhCheckFlag || 'Y'; // Default: On
    try {
        // Required params check
        if (!account || !profile || !env || !args) {
            // TODO: review the data has sensible information before LIVE
            mbpLogger.logError({
                account,
                profile,
                env,
                args,
                function: 'trackTealiumPageView',
                appName: process.env.npm_package_name,
                module: 'mbp-tag-managment',
                message: 'Missing required params. [account/profile/env/args]',
            });
        }

        if (location?.state) {
            data.giftDate = location?.state?.giftDate || null;
            data.giftOccasion = location?.state?.giftOccasion || null;
            data.giftZipcode = location?.state?.giftZipcode || null;
        }
        // Build Data Layer
        dataLayer = loadDataLayerForPageView(data);

        if (dataLayer || !lh(lhFeature)) {
            // Exlcude dom, cp, ut
            const currentUtagData = getUTagGetData();

            // Keep utag_data in sync with dataLayer
            // Object.keys(dataLayer).forEach((key) => {
            //     window.utag_data[key] = dataLayer[key];
            // });
            // Append current utag_data to virtual page view datalayer

            // Merge default TIQ data layer with custom data layer;
            const dl = { ...dataLayer, ...currentUtagData };

            // Check session attribution
            sessionTrackingHelpers.validateSessionAttribution({ location, featureFlags });

            // If Tealium already loaded, just record page view
            if (window.utag?.track) {
                // Prevent duplicate GA trackers for virtual page views
                dl.gua_tracker = 'false';

                const sessionDataLayer = sessionTrackingHelpers.generateSessionDataLayer(dl);
                const pageNavigationDataLayer = {
                    ...dl,
                    ...sessionDataLayer,
                };
                // Logging for attribution debugging
                checkAndLog(sessionDataLayer);
                // Register a virtual page-view in Tealium
                window.utag.view(pageNavigationDataLayer);

                // Fire queued events
                fireQueuedEvent(queuedEvents);

                // Load third party libraries like yotpo.
                handleVendorTags();
            } else { // Otherwise, load Tealium SDK, then record page view
                const tiqEnv = getTIQENV(env);
                const tagManagerScriptCache = scriptCache({
                    tiqSDK: `https://tags.tiqcdn.com/utag/${account}/${profile}/${tiqEnv}/utag.js`,
                });

                // INIT [Page Load]
                window.isPWA = true;
                window.isMobile = false;

                // TODO: No, No, No.
                window.jQuery = $;
                window.$ = $;
                // TODO: Seriously, No. And remove that pesky import above as well!

                // Since this is a single-page-app web site that fires page views manually, tell Tealium not to auto-fire page views.
                window.utag_cfg_ovrd = {
                    noview: true,
                };

                setOnTealiumTagLoad(featureFlags['is-tealium-tags-load-onload']);
                tagManagerScriptCache.tiqSDK.onLoad(() => {
                    mbpLogger.logDebug({ appName: process.env.npm_package_name, message: 'tiqSDK loaded' });

                    const test = () => (testCallback());
                    const success = () => (succssCallback(location, featureFlags, dl, queuedEvents));
                    // Load third party libraries like yotpo.
                    // TODO: Review if it is possible to make it works into the sucess function.
                    when(test, handleVendorTags, 2000, 20, 1);

                    // Start Tealium loader
                    when(test, success, 250, 20);
                });
            }
        } else {
            // TODO: review the data has sensible information before LIVE
            mbpLogger.logWarning({
                dataLayer,
                account,
                profile,
                env,
                data,
                function: 'loadDataLayer',
                appName: process.env.npm_package_name,
                module: 'mbp-tag-managment',
                jsError: null,
                message: 'Missing data layer.',
            });
        }
    } catch (ex) {
        // TODO: review the data has sensible information before LIVE
        mbpLogger.logWarning({
            account,
            profile,
            env,
            data,
            function: 'loadDataLayer',
            appName: process.env.npm_package_name,
            module: 'mbp-tag-managment',
            jsError: ex,
            message: 'Failed to track Tealium page view.',
        });
    }

    return dataLayer;
};
