/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { getCurrentViewOrderItem } from '../../../Cart/Cart-Selectors';

const GIFT_MESSAGE_LEN = 200;

export const getGiftMessage = (state) => {
    const recipients = getCurrentViewOrderItem(state);
    if (recipients && recipients.orderItems) {
        const orderItem = recipients.orderItems[0];
        if (orderItem.field2) {
            return orderItem.field2;
        }
        return 'No Message';
    }
    return 'No Message';
};

export const getformatFullGifMessage = (state) => {
    const giftMessage = getGiftMessage(state);
    if (giftMessage && giftMessage !== 'No Message') {
        const giftMsg = giftMessage.split('From:');
        const [msgText, msgAuthor] = giftMsg;
        return {
            message: msgText,
            author: msgAuthor,
        };
    }
    return {
        message: 'No Message',
        author: '',
    };
};

export const getGiftMessageAttribute = (state) => ({ ...{ ...state }.checkout }.recipient);

export const getGiftMessageInputText = (state) => getGiftMessageAttribute(state).giftMessage.message;

export const getGiftMessageAuthor = (state) => getGiftMessageAttribute(state).giftMessage.author;

export const getGiftMessageField = (state) => getGiftMessageAttribute(state).giftMessage;

export const getGiftMessageFormStatus = (state) => getGiftMessageAttribute(state).isFormValid;

export const getGiftMessageLen = (state) => {
    const message = getGiftMessageInputText(state);
    const msgLen = GIFT_MESSAGE_LEN - message.length;
    return msgLen;
};

export const getGiftMessageOccasion = (state) => state.giftMessage.occasion;
