/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import { trackTealiumPageView, trackTealiumEvent } from '../../../../../app/helpers/tracking/vendors/Tealium/tealiumHelpers';

const activeVendor = 'tealium';

const vendors = {
    tealium: {
        config: {
            account: mbpUtil.getEnv('APP_TIQ_ACCOUNT') || '1800flowers',
            // profile: mbpUtil.getEnv('APP_TIQ_PROFILE') || 'main',
            profile: 'ca',
            env: mbpUtil.getEnv('APP_TIQ_ENV') || 'qa',
        },
        pageViewTrackingMethod: trackTealiumPageView,
        eventTrackingMethod: trackTealiumEvent,
    },
};

const fireVendorMethod = (methodName, args) => {
    let results = null;

    try {
        Object.keys(vendors).forEach((vendor) => {
            if (vendor === activeVendor) {
                const { config } = vendors[vendor];
                const trackingMethod = vendors[vendor][methodName];
                if (typeof window !== 'undefined') {
                    results = trackingMethod(config, args);
                }
            }
        });
    } catch (ex) {
        mbpLogger.logWarning({
            methodName,
            vendors,
            args,
            function: 'fireVendorMethod',
            appName: process.env.npm_package_name,
            module: 'mbp-tag-managment',
            jsError: ex,
            message: 'Failed to fire vendor method',
        });
    }

    return results;
};

/*
    Used with trackEvent to take tracking params off the url from Composer for promo_clicks
        - e.g. BannersImage.js
*/
export const deparamitizeTrackingURL = (url) => {
    // If no params, exit
    if (url.indexOf('?') === -1) {
        return null;
    }
    // tracking keys generated from params + deparamitized URL
    let eventAction = '';
    let eventCategory = '';
    let eventLabel = '';
    const rinsedURL = url.slice(0, url.indexOf('?'));
    // pull off params
    const paramURL = url.slice(url.indexOf('?'));
    // split into array of params
    const arrayOfParams = paramURL.slice(1).split('&');

    let  otherParams = '';
    // map over each param and assign value to correct variable
    arrayOfParams.map((param) => {
        // eventAction
        if (param.includes('ea=')) {
            eventAction = param.slice(param.indexOf('=') + 1);
        // eventCategory
        } else if (param.includes('ec=')) {
            eventCategory = param.slice(param.indexOf('=') + 1);
        // eventLabel
        } else if (param.includes('el=')) {
            eventLabel = param.slice(param.indexOf('=') + 1);
        } else {
            mbpLogger.logWarning({
                message: 'Unknown Tracking Parameter',
                parameter: param,
                url,
                function: 'deparamitizeTrackingURL',
                appName: process.env.npm_package_name,
                module: 'mbp-tag-manager',
            });
            otherParams += `${param}${'&'}`;
        }
        return null;
    });
    return {
        rinsedURL,
        eventAction,
        eventCategory,
        eventLabel,
        urlWithParam: `${rinsedURL}${'?'}${otherParams.slice(0, -1)}`,
    };
};

export const firePageDataLayerBrowserEvents = (props) => {
    const {
        pageDataLayer,
        delayedTimeout,
    } = props;

    if (typeof document !== 'undefined') {
        document.dispatchEvent(new CustomEvent('PageDataLayerLoaded', { detail: pageDataLayer }));

        if (delayedTimeout) {
            setTimeout(() => {
                document.dispatchEvent(new CustomEvent('DelayedPageDataLayerLoaded', { detail: pageDataLayer }));
            }, delayedTimeout);
        }

        return true;
    }

    return false;
};

// Tracking handlers
export const trackEvent = (data) => fireVendorMethod('eventTrackingMethod', data);  // eslint-disable-line arrow-parens
export const trackPageView = (data) => fireVendorMethod('pageViewTrackingMethod', data);  // eslint-disable-line arrow-parens
export const trackCartUpdate = (flagState, action, cartData) => {
    if (typeof document !== 'undefined') {
        document.dispatchEvent(new CustomEvent(action, { detail: cartData }));
        return true;
    }
    return false;
};
