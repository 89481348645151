/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable object-curly-newline */
/* eslint-disable import/prefer-default-export */
import configENV from '../../config';
import { isEmpty } from '../../utils/object';

const {
    DEV_ENV,
    ACCESS_TOKEN,
    ID_TOKEN,
    IS_AUTHENTICATED,
    PERSIST_KEY,
    SESSION_STORAGE_ENABLED,
} = configENV;

const refactorObjectData = (countries) => {
    const newArray = [];
    Object.keys(countries).forEach((key) => {
        newArray.push({ id: key, name: countries[key] });
    });
    return newArray;
};

const refactorStateData = (states) => {
    const newState = {};
    Object.keys(states).forEach((key) => {
        newState[key] = [];
        Object.keys(states[key]).forEach((k) => {
            newState[key].push({ id: k, name: states[key][k] });
        });
    });
    return newState;
};

const getAuthentication = () => {
    if (DEV_ENV) {
        const isAuthenticated = ACCESS_TOKEN && ID_TOKEN ? IS_AUTHENTICATED : false;
        return {
            isAuthenticated,
            accessToken: ACCESS_TOKEN,
            idToken: ID_TOKEN,
        };
    }
    try {
        const { auth } = JSON.parse(JSON.parse(sessionStorage.getItem('persist:root')).member);
        if (!auth.user_profile) {
            auth.idToken = localStorage.getItem('user_profile');
        } else if (!auth.idToken) {
            auth.idToken = auth.user_profile;
        }
        return auth;
    } catch (ex) {
        return {};
    }
};

const setState = (state, content) => {
    const newState = { ...state, ...content };
    if (SESSION_STORAGE_ENABLED === 'on') {
        let { billingAddress, wallet, recipients, orderList } = content;
        const { ContactId } = newState;
        if (
            (billingAddress || wallet || recipients || orderList)
            && ContactId
        ) {
            const accountStorage = sessionStorage.getItem(PERSIST_KEY);
            if (accountStorage) {
                let storageJson = JSON.parse(accountStorage);
                if (!storageJson) storageJson = { [ContactId]: {} };
                if (recipients) {
                    ({ recipients } = newState);
                    storageJson[ContactId] = {

                        ...storageJson[ContactId],
                        recipients,
                        timestamp: new Date().getTime(),
                    };
                }
                if (billingAddress) {
                    ({ billingAddress } = newState);
                    storageJson[ContactId] = {

                        ...storageJson[ContactId],
                        billingAddress,
                        timestamp: new Date().getTime(),
                    };
                }
                if (wallet) {
                    ({ wallet } = newState);
                    storageJson[ContactId] = {

                        ...storageJson[ContactId],
                        wallet,
                        timestamp: new Date().getTime(),
                    };
                }
                if (orderList) {
                    ({ orderList } = newState);
                    storageJson[ContactId] = {

                        ...storageJson[ContactId],
                        orderList,
                        timestamp: new Date().getTime(),
                    };
                }
                sessionStorage.setItem(
                    PERSIST_KEY,
                    JSON.stringify(storageJson),
                );
            }
        }
    }
    return newState;
};

const setSessionStorageObject = (ContactId, object, isSession = true) => {
    const Storage = isSession ? sessionStorage : localStorage;
    const accountStorage = Storage.getItem(PERSIST_KEY);
    let storageJson = accountStorage
        ? JSON.parse(accountStorage)
        : { [ContactId]: {} };
    const commonStorage = storageJson.common;
    if (!storageJson[ContactId] && !commonStorage) {
        storageJson = { [ContactId]: {} };
    } else if (commonStorage) {
        storageJson = { common: commonStorage };
    }
    storageJson[ContactId] = { ...storageJson[ContactId], ...object };
    Storage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
    return object;
};

const getSessionStorageObject = (ContactId, objectKey, isSession = true) => {
    const Storage = isSession ? sessionStorage : localStorage;
    const accountStorage = Storage.getItem(PERSIST_KEY);
    const storageJson = accountStorage
        ? JSON.parse(accountStorage)
        : { [ContactId]: {} };
    if (storageJson[ContactId] && storageJson[ContactId][objectKey]) {
        return storageJson[ContactId][objectKey];
    }
    return {};
};

const getGiftListItemFromCart = ({ data }) => {
    try {
        const {
            orderDetails: {
                fdOrderVO: {
                    orderId,
                    recipients,
                },
            },
        } = data;
        const returnData = {};
        if (!isEmpty(recipients)) {
            recipients.forEach((recipient) => {
                const { orderItems, recipientAddress } = recipient;
                if (!isEmpty(orderItems) && !isEmpty(recipientAddress)) {
                    orderItems.forEach((order) => {
                        const {
                            addressId,
                            orderItemsId: orderItemId,
                            xOrderAttrValues,
                            promisedAvailableTime,
                            catalogEntryVO: {
                                parentCatentryId,
                                skuCode: partNumber,
                                parentSkuName: name,
                                imagePath,
                                flowersImgName,
                                skuListPrice,
                                skuOfferPrice,
                                parentCatentryId: parentCatalogEntryId,
                            },
                        } = order;
                        // Get product SKU from partNumber: cco_222211 or cco_VDY16_156991
                        const sku = partNumber.replace(/[^-]+-[IP]-/, '').replace(/([^_]+_)*(\d+)$/g, '$2');
                        // Don't add 'c.jpg' if flowersImgName already ended by .jpeg or .png
                        const image = /\.(jpe?g|png)$/.test(flowersImgName) ? `${imagePath}${flowersImgName}` : `${imagePath}${flowersImgName}c.jpg`;
                        const productDetail = {
                            content: '',
                            crossedPrice: (skuListPrice === skuOfferPrice) ? '' : skuListPrice,
                            discountPrice: (skuListPrice === skuOfferPrice) ? '' : skuOfferPrice,
                            id: partNumber,
                            image,
                            flowersImgName,
                            imagePath,
                            name,
                            parentCatalogEntryId,
                            partNumber,
                            price: skuListPrice,
                            sku,
                        };
                        returnData[orderItemId] = {
                            addressId,
                            orderId,
                            orderItemId,
                            parentCatentryId,
                            sku,
                            xOrderAttrValues,
                            productDetail,
                            promisedAvailableTime,
                        };
                    });
                }
            });
        }
        return returnData;
    } catch (ex) {
        return {};
    }
};

const getItemDetailsFromCart = (orderItemId, productsInCart) => {
    let productDetail = {};
    let hasDeliveryDate = false;
    if (!isEmpty(productsInCart)) {
        // eslint-disable-next-line consistent-return
        Object.keys(productsInCart).forEach((addressId) => {
            const productInCart = productsInCart[addressId];
            const { promisedAvailableTime, xOrderAttrValues: { giftHistoryOrderItemId } } = productInCart;
            if (orderItemId === giftHistoryOrderItemId) {
                ({ productDetail } = productInCart);
                hasDeliveryDate = promisedAvailableTime !== '';
            }
        });
    }
    return {
        hasDeliveryDate,
        productDetail,
    };
};

const updateRecipientsFromCartItems = (recipients, occasionId, productsInCart) => {
    const newRecipients = recipients;
    // Adding default value of addToCart and cartDetail to recipient object
    if (!isEmpty(newRecipients[occasionId])) {
        newRecipients[occasionId].forEach((item, key) => {
            newRecipients[occasionId][key].addToCart = '';
            newRecipients[occasionId][key].cartDetail = {};
        });
    }
    if (!isEmpty(productsInCart)) {
        Object.keys(productsInCart).forEach((orderItemId) => {
            const productInCart = productsInCart[orderItemId];
            // Get giftHistoryOrderItemId from productInCart.xOrderAttrValues
            // MongoDB use giftHistoryOrderItemId (no `s`) instead of giftHistoryOrderItemsId (with `s`)
            let { xOrderAttrValues: { giftHistoryOrderItemId } } = productInCart;
            // For WCS
            if (!giftHistoryOrderItemId) {
                ({ xOrderAttrValues: { giftHistoryOrderItemsId: giftHistoryOrderItemId } } = productInCart);
            }
            // Update recipient object by adding: addToCart & cartDetail object
            // cartDetail object will be used as an item in cart indicator for Gift List
            // Look into DesktopRecipientDetails.js, MobileRecipientDetails.js, and ProductAddedToCart.js for more info
            if (!isEmpty(newRecipients[occasionId]) && giftHistoryOrderItemId) {
                newRecipients[occasionId].forEach((item, key) => {
                    if (item.orderItemId === giftHistoryOrderItemId) {
                        const { products } = item;
                        const sku = productInCart.sku.replace(/-P-/, '-I-').replace(/([^_]+_)*(\d+)$/g, '$2'); // Get product SKU from partNumber: cco_222211 or cco_VDY16_156991
                        newRecipients[occasionId][key].addToCart = sku;
                        newRecipients[occasionId][key].cartDetail = {
                            orderId: productInCart.orderId,
                            orderItemId: productInCart.orderItemId,
                            skuCatentryId: productInCart.parentCatentryId,
                        };
                        products.forEach((prod) => {
                            if (prod.partNumber === sku) {
                                newRecipients[occasionId][key].cartDetail.productDetail = {
                                    ...prod,
                                };
                            }
                        });
                        // If users added product by product number
                        if (isEmpty(newRecipients[occasionId][key].cartDetail.productDetail)) {
                            newRecipients[occasionId][key].cartDetail.productDetail = productInCart.productDetail;
                        }
                    }
                });
            }
        });
    }
    return newRecipients;
};

const checkRemainingRecipient = (recipients, occasionId) => {
    let counter = 0;
    if (!isEmpty(recipients[occasionId])) {
        recipients[occasionId].forEach((item, key) => {
            if (!recipients[occasionId][key].addToCart) {
                counter += 1;
            }
        });
    }
    return counter;
};

const getChannelUser = (deviceType, pathname, featureFlag) => {
    const userDevice = deviceType === 'mobile' ? 'mobile' : 'desktop';
    const defaultCode = userDevice === 'mobile' ? '30002' : '30001';

    const brands = {
        '1800flowers': { desktop: '30016', mobile: '30026' },
        plants: { desktop: '30137', mobile: '30138' },
    };

    if (featureFlag) return brands?.[pathname]?.[userDevice] || defaultCode;

    return defaultCode;
};

const Helper = {
    refactorObjectData,
    refactorStateData,
    setState,
    getAuthentication,
    setSessionStorageObject,
    getSessionStorageObject,
    getGiftListItemFromCart,
    getItemDetailsFromCart,
    updateRecipientsFromCartItems,
    checkRemainingRecipient,
    getChannelUser,
};

export default Helper;
