/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { combineReducers } from 'redux';
import { duck as tmDuck } from '../ducks/TagManager';

import * as app from '../ducks/App';

// MEMBER (User Profile and Auth Info)
import member from './Member-Reducers/Member-Reducers';

// ACCOUNT
import account from './Account-Reducers/Account-Reducers';

// CHECKOUT
import checkout from '../ducks/Checkout';

// UI
import ui from './UI-Reducer/UI-Reducer';

// PAGE
import page from './Page-Reducers/Page-Reducers';

// TODO SA - page ducks removed, need to reevaluate all ops that try to access page state
// const page = combineReducers({
//     brands: BrandReducers,
// });
// Commented out above code and created reducer for page (Page-Reducer) for handling page state into test cases.

export default combineReducers({
    app: app.default,
    tagManager: tmDuck.default,
    page,
    member,
    ui,
    checkout,
    account,
});
