/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as propertiesActions from './Properties-Actions';
import propertiesOperations from './Properties-Operations';
import propertiesReducer from './Properties-Reducer';

export { propertiesActions, propertiesOperations };
export default propertiesReducer;
