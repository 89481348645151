/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
    arrayOf, node, string, bool,
} from 'prop-types';
import useVisibility from './useVisibility';
import { getIsBot } from '../../../state/ducks/App/App-Selectors';

const RenderWhenVisible = (props) => {
    const {
        children,
        placeholderWidth,
        placeholderHeight,
        renderSSR = false,
    } = props;

    const [visibile, ref] = useVisibility();
    const isBot = useSelector(getIsBot);
    const isSSR = (typeof window === 'undefined');

    if (!isBot && isSSR && !renderSSR) {
        return null;
    }

    let style = {
        width: placeholderWidth || '100vw',
        height: placeholderHeight || '100vh',
    };

    if (visibile) style = {};

    return (
        <div
            ref={ref}
            style={style}
        >
            {visibile ? children : null}
        </div>
    );
};

RenderWhenVisible.propTypes = {
    children: arrayOf(node).isRequired,
    placeholderWidth: string,
    placeholderHeight: string,
    renderSSR: bool,
};

RenderWhenVisible.defaultProps = {
    placeholderWidth: '',
    placeholderHeight: '',
    renderSSR: false,
};

export default RenderWhenVisible;
