/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpUtil from 'mbp-api-util';
import Cookies from 'universal-cookie';

export const getEnvVarValue = (variableName) => {
    const value = mbpUtil.getEnv(variableName);
    return value;
};

export const getExpiresAtTime = (expiresIn) => ((expiresIn * 1000) + new Date().getTime());

export function MeasureTimeTaken() {
    const clockTime = {
        start: 0,
        end: 0,
        timeTaken: 0,
    };

    const timeTaken = () => {
        if (clockTime.start > 0 && clockTime.end > 0) {
            clockTime.timeTaken = clockTime.end - clockTime.start;
        }

        return clockTime;
    };

    const startClock = () => {
        clockTime.start = window.performance.now();
        return clockTime.start;
    };

    const endClock = () => {
        clockTime.end = window.performance.now();
        return timeTaken();
    };

    return {
        startClock,
        endClock,
    };
}

export const redirectToHome = () => {
    if (typeof window !== 'undefined') {
        window.location.assign('/en');
    }
};

export function circuitBreaker(thresholdCount) {
    let count = 0;

    function breakCircuit() {
        if (count < thresholdCount) {
            count += 1;
            return false;
        }

        return true;
    }

    return breakCircuit;
}

export const parseIdToken = (objToken, arrGetValOf) => {
    const objReturn = {};
    Object.keys(objToken).forEach((val) => {
        const strNode = val.toString();
        const iStartPos = strNode.lastIndexOf('/') + 1;
        const iEndPos = strNode.length;
        const strKey = strNode.substring(iStartPos, iEndPos);
        if (arrGetValOf.indexOf(strKey) >= 0) {
            objReturn[strKey] = objToken[strNode];
        }
    });
    return objReturn;
};

export const removeTokenInSession = () => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
        window.sessionStorage.removeItem('app:accessToken');
    }
};

export const removeTokenLocalStorage = (name) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.removeItem(name);
    }
};

export const setTokenLocalStorage = (value, name) => {
    if (typeof window !== 'undefined' && window.localStorage) {
        if (value && value !== undefined) {
            window.localStorage.setItem(name, JSON.stringify(value));
        }
    }
};

export const setCookie = (name, value, expiresIn) => {
    const cookie = new Cookies();
    const currentDate = new Date();
    const expDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + expiresIn));
    cookie.set(name, value, {
        expires: expDate,
    });
};

export const getTokenLocalStorage = (name) => ((typeof window !== 'undefined' && window.localStorage) ? window.localStorage.getItem(name) : '');

// eslint-disable-next-line prefer-template
const b64DecodeUnicode = (str) => decodeURIComponent(Array.prototype.map.call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

export const parseJwt = (token) => JSON.parse(b64DecodeUnicode(token.split('.')[1].replace('-', '+').replace('_', '/')));

export const getCookie = (name) => {
    if (typeof document !== 'undefined') {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    return '';
};

export const getWindowLocation = () => {
    if (typeof window !== 'undefined' && window.location) {
        return window.location.origin;
    }
    return 'http://localhost';
};

export const setLanguageName = (language) => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
        if (language && language.length > 0 && language !== '') {
            window.sessionStorage.setItem('language', btoa(String(language).toLowerCase()));
        }
    }
};

export const getLanguageName = () => {
    if (typeof window !== 'undefined' && window.sessionStorage && window.sessionStorage.getItem('language')) {
        return atob(window.sessionStorage.getItem('language'));
    }
    return '/en';
};

export const setCountryName = (country) => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
        window.sessionStorage.setItem('country', btoa(String(country).toLowerCase()));
    }
};

export const setCurrencyName = (currencyName) => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
        window.sessionStorage.setItem('currency', currencyName);
    }
};

export const getCurrencyName = () => {
    if (typeof window !== 'undefined' && window.sessionStorage && window.sessionStorage.getItem('currency')) {
        return window.sessionStorage.getItem('currency');
    }
    return 'USD';
};

export const getDefaultLanguage = () => {
    if (typeof window !== 'undefined' && window.sessionStorage && window.sessionStorage.getItem('language')) {
        return atob(window.sessionStorage.getItem('language'));
    }
    return null;
};

export const getDefaultCountry = () => {
    if (typeof window !== 'undefined' && window.sessionStorage && window.sessionStorage.getItem('country')) {
        return atob(window.sessionStorage.getItem('country'));
    }
    return null;
};

export const getDefaultCurrency = () => {
    if (typeof window !== 'undefined' && window.sessionStorage && window.sessionStorage.getItem('currency')) {
        return window.sessionStorage.getItem('currency');
    }
    return null;
};

export const setNonceKey = (nonce) => {
    window.sessionStorage.setItem('nonce_key', btoa(nonce));
};

export const getNonceKey = () => {
    if (window.sessionStorage.getItem('nonce_key') !== null) {
        return atob(window.sessionStorage.getItem('nonce_key'));
    }
    return '';
};

// Get Session Storage for recently viewed products
export const getRecentlyViewedProducts = () => {
    if (typeof window !== 'undefined' && window.sessionStorage.getItem('recentlyViewedProducts') !== null) {
        return JSON.parse(window.sessionStorage.getItem('recentlyViewedProducts'));
    }
    return [];
};

// Set Session Storage for recently viewed products
export const setRecentlyViewedProducts = (storageValue) => {
    if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('recentlyViewedProducts', JSON.stringify([storageValue].concat(getRecentlyViewedProducts().filter((item) => item.partNumber !== storageValue.partNumber))));
    }
};

export const flattenObject = (obj) => {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, flattenObject(obj[key]));
        } else {
            flattened[key] = obj[key];
        }
    });

    return flattened;
};

export const setLocale = () => {
    const language = getLanguageName().replace('/', '');
    if (language === 'en') return 'en-us';
    return language;
};

export const getCountryName = () => {
    if (typeof window !== 'undefined' && window.sessionStorage && window.sessionStorage.getItem('country')) {
        return atob(window.sessionStorage.getItem('country'));
    }
    return '/';
};

export const verifyScopeExist = (accessToken, scopeToVerify) => {
    if (accessToken) {
        const decryptJWT = parseJwt(accessToken);
        const scopesInToken = decryptJWT?.scope || '';
        if (scopeToVerify && scopesInToken.indexOf(scopeToVerify) >= 0) {
            return true;
        }

        // Supporting the new JWT scope
        const permissionInToken = decryptJWT?.permissions || [];
        if (permissionInToken && permissionInToken.includes(scopeToVerify) >= 0) {
            return true;
        }
    }
    return false;
};
