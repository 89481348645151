/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import actionTypes from './Paypal-ActionTypes';

export const clearPaypalState = () => ({
    type: actionTypes.CLEAR_PAYPAL_STATE,
});

export const paypalGetClientId = () => ({
    type: actionTypes.PAYPAL_GET_CLIENT_ID,
});

export const loadPaypalClientId = (data) => ({
    type: actionTypes.LOAD_PAYPAL_CLIENT_ID,
    data,
});

export const loadPaypalPayload = (data) => ({
    type: actionTypes.LOAD_PAYPAL_PAYLOAD,
    data,
});

export const updatePaypalBillingAddressMissingMandoryFields = (data) => ({
    type: actionTypes.UPDATE_FLAG_IS_PAYPAL_BILLINGADDRESS_MISSING_MANDTORY_FIELDS,
    data,
});

export const logPaypalPaymentPayload = (payload, history) => ({
    type: actionTypes.LOG_PAYPAL_PAYMENT_PAYLOAD,
    data: {
        payload,
        history,
    },
});

export const useAnotherPaypalAccount = (change) => ({
    type: actionTypes.USE_ANOTHER_PAYPAL_ACCOUNT,
    data: {
        change,
    },
});

export default {};
