/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Provider } from './LocaleContext';
import { getCountryName, getCurrencyName, getLanguageName } from '../../../state/ducks/Member/ducks/Auth/helper/helper';

const LocaleProvider = (props) => {
    const [language, setLanguage] = useState(getLanguageName());
    const [country, setCountry] = useState(getCountryName());
    const [currency, setCurrency] = useState(getCurrencyName());
    const updateLanguage = (e) => {
        if (e) {
            setLanguage(e);
        }
    };
    const updateCountry = (e) => {
        if (e) {
            setCountry(e);
        }
    };
    const updateCurrency = (e) => {
        if (e) {
            setCurrency(e);
        }
    };
    return (
        <Provider
            value={
                {
                    language,
                    currency,
                    country,
                    updateLanguage,
                    updateCurrency,
                    updateCountry,
                }
            }
        >
            {/* eslint-disable react/destructuring-assignment */}
            {props.children}
        </Provider>
    );
};

LocaleProvider.propTypes = {
    children: PropTypes.object.isRequired,
};

export default LocaleProvider;
