/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import actionTypes from './Cart-ActionTypes';

export const loadCart = (cart) => ({
    type: actionTypes.LOAD_CART,
    data: cart,
});

export const getCart = (orderId) => ({
    type: actionTypes.GET_CART,
    orderId,
});

export const loadItems = (orderId) => ({
    type: actionTypes.GET_ITEM,
    orderId,
});

export const receiveItem = (itemJson) => ({
    type: actionTypes.RECEIVE_ITEM,
    items: itemJson,
});

export const cartLoaded = () => ({
    type: actionTypes.CART_LOADED,
});

export const recalculateGiftCardTotal = () => ({
    type: actionTypes.RECALCULATE_GIFT_CARD_TOTAL,
});

export const callHandleCartFailure = (error) => ({
    type: actionTypes.CALL_HANDLE_CART_FAILURE,
    data: {
        error,
    },
});
