/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Order-ActionTypes';

export const getOrderId = (orderId) => ({
    type: actionTypes.GET_ORDER_ID,
    data: orderId,
});

export const callAtlasOrderNumberAPI = (token) => ({
    type: actionTypes.CALL_ATLAS_ORDER_ID,
    token,
});

export const atlasOrderNumberSuccess = (atlasOrderId) => ({
    type: actionTypes.GET_ATLAS_ORDER_ID,
    data: atlasOrderId,
});

export const loadOrderId = (orderId) => ({
    type: actionTypes.LOAD_ORDER_ID,
    data: orderId,
});

export const loadCartCount = (cartCount) => ({
    type: actionTypes.LOAD_CART_COUNT,
    data: {
        cartCount,
    },
});

export const loadExpressCheckout = () => ({
    type: actionTypes.LOAD_EXPRESS_CHECKOUT,
});

export const clearOrderId = () => ({
    type: actionTypes.CLEAR_ORDER_ID_CHECKOUT,
});
