/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */
/* eslint-disable object-curly-newline */

/*
    mbp-account-ui redux

    Redux Actions are declared in Action & ActionType.
    You perform a Redux Action by dispatching to EventDispatcher(yourAction(params)).
    Redux Actions are defined in EventDispatcher object like this:
        yourAction: params => {
            type: ActionType.ACTION_NAME_CONSTANT,
            callList: [ middleware methods to call ],      // these are the methods of the Services object, they optionally return data object
            params
        }

    If all middleware methods succeeded, a new action is then dispatched with:
        {
            type: ActionType.ACTION_NAME_CONSTANT with final "/REQUEST" changed to "/LOADED",
            params,
            { ... all data returned by middleware methods }
        }

    If not, a new action is dispatched with type ActionType.ACTION_NAME_CONSTANT with final "/REQUEST" changed to "/FAILURE"

*/

import { takeEvery, call, put, all, fork } from 'redux-saga/effects';
// import MBPLogger from 'mbp-logger';

// import { actions as tagManagerActions } from '../../../../../state/ducks/TagManager';
// import { decode as htmlDecode } from '../../utils/htmlEntity';
import {
    getActionKey,
    getSuccessType,
    getFailureType,
} from '../reducers/ActionType';
import { deepObjectExtend, isEmpty } from '../../utils/object';
import Services from '../reducers/Services';
// import GAEventObject from '../../tealium/GAEventObject';

import configENV from '../../config';

const { ENABLE_CONSOLE_LOG } = configENV;

// const getBaseCode = (productCode) => {
//     let baseCode = '';
//     try {
//         if (productCode) {
//             baseCode = productCode;
//             if (productCode.indexOf('_') !== -1) {
//                 const productCodeArr = productCode.split('_');
//                 if (productCodeArr.length > 2) {
//                     [, , baseCode] = productCodeArr;
//                 } else if (productCodeArr.length <= 2) {
//                     [, baseCode] = productCodeArr;
//                 }
//             } else if (productCode.indexOf('-') !== -1) {
//                 [, , baseCode] = productCode.split('-');
//             }
//             if (baseCode.replace(/[A-Za-z]*$/, '') !== '') {
//                 baseCode = baseCode.replace(/[A-Za-z]*$/, '');
//             }
//         }
//     } catch (ex) {
//         MBPLogger.logError({
//             productCode,
//             function: 'getBaseCode',
//             module: 'mbp-page',
//             jsError: ex,
//             message: 'Failed to get product base code.',
//         });
//     }
//     return baseCode;
// };

// const getAddToCartGAEventObject = (EventObject, productDetail, productBrandId) => {
//     const eventObject = EventObject;
//     eventObject.productBrands = [productBrandId.toString()];
//     eventObject.productIds = [getBaseCode(productDetail.partNumber)];
//     eventObject.productNames = [htmlDecode(productDetail.name)];
//     eventObject.eventLabel = [htmlDecode(productDetail.name)];
//     eventObject.productPrices = [productDetail.price.toString()];
//     eventObject.productSkus = [productDetail.parentCatalogEntryId];
//     eventObject.productVariants = [productDetail.partNumber];
//     return eventObject;
// };

const mergeAPIResponseToDataObject = (response, data) => {
    if (response && response.length) {
        const { ...myObj } = [...response.map((x) => x.data)];
        const responseData = {};
        Object.keys(myObj).forEach((key) => {
            Object.assign(responseData, myObj[key]);
        });
        deepObjectExtend(data, responseData);
    }
    return data;
};

export function* workerSagas(action) {
    const { callList } = action;
    const type = getSuccessType(action.type);
    const typeError = getFailureType(action.type);
    let data = { ...action };
    delete data.type;
    delete data.callList;
    const services = new Services();
    try {
        if (ENABLE_CONSOLE_LOG) {
            console.log('SAGA >>>', getActionKey(action.type), callList);
        }
        if (data.modal && data.modal.content && data.modal.content.url) {
            const content = yield services.fetchModalContent(data.modal.content.url);
            /* istanbul ignore next */
            data.modal.content = content.data;
        }
        if (callList && callList.length) {
            callList.forEach((x) => {
                if (typeof services[x] !== 'function') {
                    console.error('[-] Error: argument fn is undefined');
                    // throw new Error('argument fn is undefined');
                }
            });
            const response = yield all(callList.map((x) => call(services[x], data)));
            data = mergeAPIResponseToDataObject(response, data);
        }
        if (ENABLE_CONSOLE_LOG) {
            console.log('SAGA >>>', getActionKey(action.type), data);
        }
        yield put({ type, data });
        if (
            data.response
            && data.isCartUpdated
            && !isEmpty(data.response.orderId)
        ) {
            // if (
            //     action.trackEvent
            //     && !isEmpty(GAEventObject[action.trackEvent])
            //     && action.trackEventLabel === 'add_to_cart'
            //     && !isEmpty(data.response.productDetail)
            // ) {
            //     const productBrandId = services.siteConfig['product-brand-id'] || '';
            //     GAEventObject[action.trackEvent] = getAddToCartGAEventObject(GAEventObject[action.trackEvent], data.response.productDetail, productBrandId);
            //     yield put(tagManagerActions.trackEvent(GAEventObject[action.trackEvent]));
            // }
            yield put({ type: 'LOAD_ORDER_ID', data: [data.response.orderId] });
            yield put({
                type: 'mbp-checkout/CART/LOAD',
                data: data.response.itemsInCart,
            });
        }
        // else if (action.trackEvent && !isEmpty(GAEventObject[action.trackEvent])) {
        //     if (action.trackEventLabel && action.trackEventLabel !== 'add_to_cart') {
        //         GAEventObject[action.trackEvent].eventLabel = action.trackEventLabel;
        //         yield put(tagManagerActions.trackEvent(GAEventObject[action.trackEvent]));
        //     } else if (!action.trackEventLabel) {
        //         yield put(tagManagerActions.trackEvent(GAEventObject[action.trackEvent]));
        //     }
        // }
    } catch (error) {
        if (ENABLE_CONSOLE_LOG) console.log('SAGA >>>', typeError, error);
        yield put({ type: typeError, error });
    }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watcherSagas() {
    // yield takeEvery(action => getActionKey(action.type), workerSagas);
    yield takeEvery((action) => getActionKey(action.type), workerSagas);
}

export const accountSagas = fork(watcherSagas);
