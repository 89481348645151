/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, put, takeEvery, call,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';
import {
    loadOrderId, getOrderId, atlasOrderNumberSuccess, callAtlasOrderNumberAPI,
} from './Order-Actions';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';
import { errorActions }  from '../Common/ducks/Error';
import { nextAtlasOrderNumber } from '../../../../../apis/checkout-apis/generateNextAtlasOrderNumber';

// eslint-disable-next-line consistent-return
const getAtlasOrderNumber = async (headers) => {
    let response;
    try {
        response = await nextAtlasOrderNumber(headers);
        if (response) {
            return response.data;
        }
    } catch (e) {
        mbpLogger.logError({
            function: 'getAtlasOrderNumber',
            module: 'Order-operations',
            message: response?.message,
        });
        response = { status: 500, errorMsg: response?.message };
        return response;
    }
};

function* fetchAtlasOrderNumber(data) {
    try {
        const headers = { authorization: `Bearer ${data?.token}` };
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const result = yield call(getAtlasOrderNumber, headers);
        if (result?.status === 200) {
            yield put(atlasOrderNumberSuccess(result?.order_number));
        } else {
            yield put(errorActions.loadError({ errType: 'atlas order number API failed', errMsg: 'Unable to process. Please try after sometime.' }));
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (e) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        mbpLogger.logError({
            function: 'fetchAtlasOrderNumber',
            module: 'Order-operations',
            message: e,
        });
        yield put(errorActions.loadError({ errType: 'atlas order number', errMsg: 'Unable to proceed further. Please try after sometime.' }));
    }
}

function* setOrderId(action) {
    try {
        yield put(loadOrderId(action.data));
    } catch (e) {
        console.log('ERROR');
    }
}

function* watchSetOrderId() {
    yield takeEvery(getOrderId().type, setOrderId);
}

function* watchGetAtlasOrderId() {
    yield takeEvery(callAtlasOrderNumberAPI().type, fetchAtlasOrderNumber);
}

export default function* rootSaga() {
    yield [
        watchGetAtlasOrderId(),
    ];
}

const watchers = [
    fork(watchSetOrderId),
    fork(watchGetAtlasOrderId),
];

export {
    watchers,
    setOrderId,
    fetchAtlasOrderNumber,

    // Actions
    loadOrderId,
    getOrderId,
    atlasOrderNumberSuccess,
    callAtlasOrderNumberAPI,
};
