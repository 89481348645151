/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/cart';
const RESOURCE_ROOT_FACADE = '/checkout';
/**
 * update order item via facade
 * @param {*} wcEnv
 *
 */
const updateCart = (wcEnv, jwtToken, orderItemId, quantity, delivery, greeting, orderId, personalization = null) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;
    let resourcePage = `${RESOURCE_PAGE_FACADE}/${orderItemId}`;
    if (orderId) {
        resourcePage = `${RESOURCE_PAGE_FACADE}/${orderId}/item/${orderItemId}`;
    }
    const payload = {
        quantity, delivery, greeting, personalization,
    };

    return restClient.putFacade(env, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default updateCart;
