/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';
import { combineReducers } from 'redux';

import * as appActions from './App-Actions';
import config from './ducks/Config';
import brand from './ducks/Brand';

import utils from '../../utils';

const SHELL_INITIAL_STATE = {
    loaded: false,
    type: 'home',
};

const SSR_INITIAL_STATE = {
    config: {
        isBot: false,
        loaded: false,
        hostname: '',
        path: '',
        deviceType: 'mobile',
    },
};

const shell = utils.createReducer(SHELL_INITIAL_STATE)({
    [appActions.setAppShellType().type]: (state, action) => createState(state || SHELL_INITIAL_STATE, ['type', action.payload]),
    [appActions.setAppShellLoadState().type]: (state, action) => createState(state || SHELL_INITIAL_STATE, ['loaded', action.payload]),
});

const ssr = utils.createReducer(SSR_INITIAL_STATE)({
    [appActions.initSSR().type]: (state, action) => createState(state || SSR_INITIAL_STATE, ['config', action.payload]),
    [appActions.setSSRDeviceType().type]: (state, action) => createState(state || SSR_INITIAL_STATE, ['config.deviceType', action.payload]),
});

export default combineReducers({
    shell,
    config,
    ssr,
    brand,
});
