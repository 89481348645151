/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-bitwise */
/* eslint-disable space-infix-ops */
/* eslint-disable no-mixed-operators */
/* eslint-disable import/prefer-default-export */

// Ref https://v8.dev/blog/math-random
export const uuidv4 = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (
    c
            // eslint-disable-next-line no-undef
            ^ ((window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
).toString(16));
