/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './PaymentReadiness-ActionTypes';

export const pageInitializationStart = () => ({
    type: actionTypes.PAGE_INITIALIZATION_START,
});

export const pageInitializationCompleted = () => ({
    type: actionTypes.PAGE_INITIALIZATION_COMPLETED,
});
