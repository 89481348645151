/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import axios from 'axios';
import mbpUtil from 'mbp-api-util';

const wcHost = mbpUtil.getEnv('APP_WC_HOST');

export const getWishlistAPI = async (userId, header) => axios.get(`https://${wcHost}/r/api/mbp-canada-checkout/wishlist/getProduct?userId=${userId}`, header).then((response) => response).catch((err) => ({ status: err.status }));

export const addAndRemoveProductAPI = async (payload, header) => axios.post(`https://${wcHost}/r/api/mbp-canada-checkout/wishlist/addAndRemoveProduct`, payload, header).then((response) => response).catch((err) => ({ status: err.status }));
