/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable no-param-reassign */
import restClient from '../../app/helpers/restClient';

const RESOURCE_ROOT_FACADE = '/smartgiftit';
const RESOURCE_PAGE_FACADE = '/apps';
const RESOURCE_PAGE_ACTION = '/movies-anywhere/movie-code-delivery/multi-recipient';

export const processNBCUMovieAPI = (wcEnv, jwtToken, payload, params, headers) => {
    wcEnv.rootUri = RESOURCE_ROOT_FACADE;
    const resourcePage = `${RESOURCE_PAGE_FACADE}${RESOURCE_PAGE_ACTION}`;
    return restClient.postFacade(wcEnv, resourcePage, jwtToken, payload, params, headers, false)
        .then((response) => response);
};

export default {};
