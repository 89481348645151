/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpUtil from 'mbp-api-util';

import * as paypalActions from './Paypal-Actions';
import * as paypalOperations from './Paypal-Operations';
import * as paypalHelpers from './Paypal-Helpers';
import paypalReducer from './Paypal-Reducer';

// Plugin Config
const payPayPluginConfig = {
    sdkURL: mbpUtil.getEnv('APP_PAYPAL_SDK_URL'),
    paypalEnv: mbpUtil.getEnv('APP_PAYPAL_ENV'),
};

export {
    paypalOperations,
    paypalActions,
    paypalReducer,
    paypalHelpers,
    payPayPluginConfig,
};
