/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './Payment-Actions';
import initialState from '../../initialState';
// '../../state/initialState';

export const paymentDetailsReducer = (state = initialState.payment.paymentDetails, action) => {
    switch (action.type) {
        case actions.updateCompletedPaymentDetails().type:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export const paymentMethodsReducer = (state = initialState.payment.paymentMethods, action) => {
    switch (action.type) {
        case actions.loadPaymentMethods().type:
            return { ...state, ...action.data };
        case actions.activePaymentMethod().type: {
            const updateState = { ...state };
            updateState[action.data.paymentMethod].active = 1;
            return updateState;
        }
        case actions.inActivePaymentMethod().type: {
            const updateState = { ...state };
            updateState[action.data.paymentMethod].active = 0;
            return updateState;
        }
        default:
            return state;
    }
};
