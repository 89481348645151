/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-underscore-dangle */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import Cookies  from 'universal-cookie';
import mbpUtil from 'mbp-api-util';
import MultiAPILink from '../helpers/MultiAPILink';
import serilizeGetQuery from '../helpers/serilizeGetQuery';

export const GRAPHQL_ENV = mbpUtil.getEnv('APP_GRAPHQL_ENV')
    ? mbpUtil.getEnv('APP_GRAPHQL_ENV')
    : process.env.NODE_ENV || 'development';

const buildGraphQLClient = (props = {}) => {
    const {
        host = mbpUtil.getEnv('APP_WC_HOST'),
        ssrMode,
        initialState,
    } = props;

    const ssrGraphQLHost = mbpUtil.getEnv('APP_GRAPHQL_HOST_SSR') || 'aggregator.acquisition-services.svc.cluster.local:8080';
    const uri = (typeof window !== 'undefined')
        ? `https://${host}/r/api/aggregator/graphql` // Client-Side
        : `${mbpUtil.getEnv('APP_WC_PROTOCOL_SSR')}://${ssrGraphQLHost}${mbpUtil.getEnv('APP_API_PREFIX_SSR')}/aggregator/graphql`; // Server-Side

    const getShopperManagerCookie = () => {
        const SM_COOKIE = 'ShopperManagerEnterprise';
        const cookies = new Cookies();
        return cookies.get(SM_COOKIE) || 'NO_ShopperManagerEnterprise_COOKIE';
    };

    const customFetchSerializer = (url, options) => {
        let cleanedURL = url;
        if (options.method === 'GET') {
            cleanedURL = serilizeGetQuery(url);
        }
        return fetch(cleanedURL, {
            ...options,
            headers: {
                ...options.headers,
                'baggage-sessionid': getShopperManagerCookie(),
            },
        });
    };

    const surrogateSet = new Set();
    const afterwareAddSurrogateKeys = new ApolloLink((operation, forward) => forward(operation).map((response) => {
        const context = operation.getContext();
        const { response: { headers } } = context;
        const surrogateKey = headers.get('surrogate-key');
        surrogateSet.add(surrogateKey);
        return response;
    }));

    const link = ApolloLink.from([
        onError(({ graphQLErrors, networkError }) => {
            // TODO add mbp-logger
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path }) => console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ));
            }
            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        afterwareAddSurrogateKeys,
        new MultiAPILink({
            uri,
            fetch: customFetchSerializer,
        }),
    ]);

    const cache = new InMemoryCache().restore(initialState || {});

    // cache invalidation headers
    const getSurrogateKeys = () => [...Array.from(surrogateSet)];

    const apolloClient = new ApolloClient({
        link,
        cache,
        ...(ssrMode ? { ssrMode: true } : { ssrForceFetchDelay: 100000 }),
        connectToDevTools: true,
    });

    return { apolloClient, getSurrogateKeys };
};

export default buildGraphQLClient;
