/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import {
    func, object, bool, string,
} from 'prop-types';
import QueryString from 'qs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { compose } from 'recompose';
import mbpLogger  from 'mbp-logger';

import AppShell from './components/AppShell/AppShell';
import publicRoutes from '../routes/publicRoutes';
// import privateRoutes from '../routes/privateRoutes';
import bProcessor from './helpers/attribution/processBanner';

import { getAppLanguage, getBannerCode, getSSRDeviceType } from '../state/ducks/App/App-Selectors';
import { setBannerCode, setChatbotOpenState } from '../state/ducks/App/App-Actions';
import { getFeatureFlags } from '../state/ducks/App/ducks/Config/Config-Selectors';
import { trackApp } from './helpers/tracking/common/commonTrackingHelpers';
import { addRouteChanges } from '../state/ducks/Member/ducks/Auth/Auth-Actions';
import { getBrand } from '../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getLanguageName } from '../state/ducks/Member/ducks/Auth/helper/helper';
import { getCart } from '../state/ducks/Checkout/ducks/Cart/Cart-Actions';
import { getProfileInfo, getOrderId } from '../state/ducks/Member/ducks/Common/Common-Selectors';

// intersection-observer polyfill
if (typeof window !== 'undefined' && typeof window.IntersectionObserver === 'undefined') {
    // eslint-disable-next-line no-unused-expressions
    import('intersection-observer');
}

const addRouteEventListener = (history, logRouteChanges) => {
    logRouteChanges(history.location.pathname);
    history.listen((location) => {
        logRouteChanges(location.pathname);
    });
};

class App extends React.Component {
    componentDidMount() {
        const {
            addRoute,
            history,
            setBannerCodeAction,
            bannerCode,
            enableChatBot,
            actionGetCart,
        } = this.props;

        const urlParams = QueryString.parse(history.location?.search?.slice(1));
        if (!bannerCode) {
            const getURLBannerCode = () => urlParams?.r || urlParams?.ref;
            setBannerCodeAction(getURLBannerCode);
        }

        addRouteEventListener(history, addRoute);

        // Reset chatbot state before refresh tab
        window.onbeforeunload = () => {
            enableChatBot(false);
        };
        /* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
        window.ire && window.ire('identify', { customerId: '', customerEmail: this.props.userProfile?.email });

        if (this.props.orderId) actionGetCart();
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            featureFlags, brand, history, appLanguage,
        } = this.props;
        if (featureFlags['is-verbose-debugging-enabled']) {
            if (this.props) {
                Object.entries(this.props).forEach(([key, val]) => prevProps[key] !== val
                && mbpLogger.logDebug({
                    prev: prevProps[key],
                    val,
                    function: 'componentDidUpdate',
                    module: 'App',
                    message: `Prop '${key}' changed`,
                }));
            }
            if (this.state) {
                Object.entries(this.state).forEach(([key, val]) => prevState[key] !== val
                && mbpLogger.logDebug({
                    prev: prevState[key],
                    val,
                    function: 'componentDidUpdate',
                    module: 'App',
                    message: `State '${key}' changed`,
                }));
            }
        }
        if (featureFlags['is-internationalization-enabled']) {
            if (brand && brand?.presentation_family === 'growthbrand' && appLanguage) {
                const path = window?.location?.pathname;
                let defaultLanguage = appLanguage || getLanguageName();
                if (!defaultLanguage) {
                    defaultLanguage = '/en';
                }
                if (path && path === '/') {
                    history.push({
                        pathname: defaultLanguage,
                    });
                }
            }
        }
    }

    render() {
        const {
            isServer,
            isShell,
            location,
            history,
            apolloClientSSR,
            bannerCode,
            featureFlags,
            is404,
            brand,
            ssrDeviceType,
            productUnavailableName,
        } = this.props;

        if (location.pathname.indexOf('ProcessExpressRequest') > 0) {
            bProcessor.processAttribution(this.props, 'ProcessExpressRequest');
            history.push(`/checkout/express${location.search}`);
        } else if (location.pathname.indexOf('/Entrance') < 0) {
            // added to ignore entrace it will be handled in server side
            bProcessor.processAttribution(this.props, 'Attribution', bannerCode || '', featureFlags);
        }

        return (
            <>
                <AppShell
                    Helmet={Helmet}
                    isServer={isServer} // from universal loader
                    isShell={isShell} // from universal loader
                    apolloClientSSR={apolloClientSSR}
                    featureFlags={featureFlags}
                    bannerCode={bannerCode}
                    brand={brand}
                    ssrDeviceType={ssrDeviceType}
                >
                    <Switch>
                        {/* Public */
                            publicRoutes.map((route, index) => {
                                const { Component, path } = route;
                                const keyRoute = `route${index}`;
                                return (
                                    <Route
                                        path={path}
                                        key={keyRoute}
                                        forceRefresh="true"
                                        render={() => <Component Helmet={Helmet} is404={is404} productUnavailableName={productUnavailableName} />}
                                    />
                                );
                            })
                        }
                        {/* Private */
                            // Object.keys(privateRoutes).map((keyName) => {
                            //     const { component, path } = privateRoutes[keyName];
                            //     return <Route exact path={path} key={keyName} component={component} />;
                            // })
                        }
                    </Switch>
                </AppShell>
            </>
        );
    }
}

App.propTypes = {
    addRoute: func.isRequired,
    history: object.isRequired,
    isServer: bool,
    apolloClientSSR: func,
    isShell: bool,
    location: object.isRequired,
    bannerCode: string,
    setBannerCodeAction: func.isRequired,
    featureFlags: object,
    is404: bool,
    ssrDeviceType: string.isRequired,
    brand: object.isRequired,
    productUnavailableName: string,
    appLanguage: string,
    enableChatBot: func.isRequired,
    userProfile: object.isRequired,
    actionGetCart: func.isRequired,
    orderId: string.isRequired,
};

App.defaultProps = {
    isServer: false,
    isShell: false,
    apolloClientSSR: null,
    bannerCode: '',
    featureFlags: {},
    is404: false,
    productUnavailableName: '',
    appLanguage: '',
};

const mapStateToProps = (state) => ({   // eslint-disable-line arrow-parens
    bannerCode: getBannerCode(state),
    featureFlags: getFeatureFlags(state),
    ssrDeviceType: getSSRDeviceType(state),
    brand: getBrand(state),
    appLanguage: getAppLanguage(state),
    userProfile: getProfileInfo(state),
    orderId: getOrderId(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        addRoute: addRouteChanges,
        setBannerCodeAction: setBannerCode,
        enableChatBot: setChatbotOpenState,
        actionGetCart: getCart,
    },
    dispatch,
);

// Required for SSR
export const AppHelmet = Helmet;

const enhance = compose(
    trackApp('mbp-pwa-ui'),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(App);
