/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const Relationships = [
    { id: '0', name: 'Self' },
    { id: '1', name: 'Mother' },
    { id: '2', name: 'Father' },
    { id: '3', name: 'Son' },
    { id: '4', name: 'Daughter' },
    { id: '5', name: 'Brother' },
    { id: '6', name: 'Sister' },
    { id: '7', name: 'Grandfather' },
    { id: '8', name: 'Grandmother' },
    { id: '9', name: 'School' },
    { id: '10', name: 'Uncle' },
    { id: '11', name: 'Cousin' },
    { id: '12', name: 'Nephew' },
    { id: '13', name: 'Niece' },
    { id: '14', name: 'Husband' },
    { id: '15', name: 'Wife' },
    { id: '16', name: 'Boyfriend' },
    { id: '17', name: 'Girlfriend' },
    { id: '18', name: 'Friend' },
    { id: '19', name: 'Other' },
];

export default Relationships;
