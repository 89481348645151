/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, takeEvery, put, select,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';

import memberDucks from '../../../../../Member/ducks';
import * as giftMessageActions from './GiftMessageForm-Actions';
import { errorActions, errorOperations } from '../../../Common/ducks/Error';
import { getOrderId } from '../../../Common/Common-Selectors';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import * as actionTypes from './GiftMessageForm-ActionTypes';
import { retrieveCart } from '../../../Cart/Cart-Operations';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

const {
    auth: {
        authOperations: {
            workers: {
                checkJWT,
            },
        },
    },
} = memberDucks;

const updateGiftMessageAPI = ({
    wcEnv, jwtToken, orderItemId, giftMessage, occasionCode, orderId,
}) => (
    orderClient.updateCart(
        wcEnv,
        jwtToken,
        orderItemId,
        null,
        null,
        {
            message: giftMessage,
            occasionCode,
        },
        orderId,
    )
);
const gciPersonalizationCompleteAPI = ({
    wcEnv, jwtToken, orderItemId, orderId, personalizationResponse,
}) => (
    orderClient.putPersonalizationResponse(
        wcEnv,
        jwtToken,
        orderItemId,
        orderId,
        personalizationResponse,
    )
);

function* addExpressCheckoutGiftMessage(action) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.clearErrors({
            errType: 'giftMessage',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: `${action.data.orderItemId}_missing_info`,
        }));

        const getAuthor = (action.data?.getAuthor || false);
        let getGiftMessage = (action.data?.giftMessage || '');
        if (getAuthor) {
            const testMatch = getGiftMessage.match(/[^|_]*$/);
            if (testMatch.length > 0 && testMatch[0] !== 'No Card Message') {
                getGiftMessage = getGiftMessage.replace(/[^|_]*$/, `From:${testMatch[0]}`);
            }
        }
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            giftMessage: getGiftMessage,
            occasionCode: null,
            orderId,
        };
        const updateGiftMessageResponse = yield call(updateGiftMessageAPI, configObj);
        if (updateGiftMessageResponse.data.successFlag) {
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            yield put(giftMessageActions.giftMessageUpdatedSuccessfully());
        }
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            module: 'mbp-pwa-ui',
            function: 'addGiftMessage',
            jsError: ex,
            message: 'Add gift message',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'giftMessage');
    }
}

function* pwaDesktopAddGiftMessage(action) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'giftMessage',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            giftMessage: action.data.giftMessage,
            occasionCode: action.data.occasionCode,
            orderId,
        };
        // Save Gift Message
        yield call(updateGiftMessageAPI, configObj);

        yield put(giftMessageActions.giftMessageUpdatedSuccessfully());
        yield put(giftMessageActions.resetGiftMessageForm());
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            module: 'mbp-pwa-ui',
            function: 'addGiftMessage',
            jsError: ex,
            message: 'Add gift message',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'giftMessage');
    }
}

function* handleGiftMessageChange(action) {
    try {
        switch (action.data.name) {
            case 'message':
                yield put(giftMessageActions.loadGiftMessageChange(action.data.value));
                break;
            case 'author':
                yield put(giftMessageActions.loadAuthorChange(action.data.value));
                break;
            case 'occasion':
                yield put(giftMessageActions.updateOccasion(action.data.value));
                break;
            default:
                break;
        }
    } catch (ex) {
        console.log(ex);
    }
}

// Formatting the full message in text and author.
export function* formatFullGifMessage(fullGiftMsg) {
    const giftMsg = fullGiftMsg.split('From:');
    const [msgText, msgAuthor] = giftMsg;
    if (msgText) {
        yield put(giftMessageActions.loadGiftMessageChange(msgText.replace(/\|/g, '\n').trim()));
    }
    if (msgAuthor) {
        yield put(giftMessageActions.loadAuthorChange(msgAuthor.trim()));
    }
    if (msgText && msgAuthor) {
        yield put(giftMessageActions.updateFormStatus(true));
    }
}

export function* watchGiftMessageChange() {
    yield takeEvery(actionTypes.TRIGGER_CHANGE_GIFT_MESSAGE, handleGiftMessageChange);
}

function* addGiftMessage(action) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.clearErrors({
            errType: 'giftMessage',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: `${action.data.orderItemId}_missing_info`,
        }));
        const getAuthor = (action.data?.getAuthor || false);
        let getGiftMessage = (action.data?.giftMessage || '');
        if (getAuthor) {
            const testMatch = getGiftMessage.match(/[^|_]*$/);
            if (testMatch.length > 0 && testMatch[0] !== 'No Card Message') {
                getGiftMessage = getGiftMessage.replace(/[^|_]*$/, `From:${testMatch[0]}`);
            }
        }
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            giftMessage: getGiftMessage,
            occasionCode: null,
            orderId,
        };
        yield call(updateGiftMessageAPI, configObj);
        yield call(retrieveCart);
        /*
        const eventAction = (appSource === 'SPC') ? 'SPC_GIFT_MESSAGE_SAVE' : 'PWA_GIFT_MESSAGE_SAVE';
        const promoEventObj = {
            eventCategory: 'Checkout',
            eventAction,
            eventLabel: {
                giftMessage: action.data.giftMessage,
            },
        };
        const { occasion } = action.data;
        if (occasion) {
            promoEventObj.eventLabel.occasion = action.data.occasion;
        }
        yield put(tmDuck.actions.trackEvent(promoEventObj));
        */
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield put(giftMessageActions.giftMessageUpdatedSuccessfully());
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            module: 'mbp-pwa-ui',
            function: 'addGiftMessage',
            jsError: ex,
            message: 'Add gift message',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'giftMessage');
    }
}

function* giftMessageUpdate(action) {
    try {
        yield call(addGiftMessage, action);
        if (action.data.closeForm) {
            yield call(action.data.closeForm);
        }
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            module: 'mbp-pwa-ui',
            function: 'giftMessageUpdate',
            jsError: ex,
            message: 'Gift Message Update Failed',
        });
    }
}

function* gciPersonalizationComplete(action) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.clearErrors({
            errType: 'giftMessage',
            field: 'error',
        }));
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const payloadData = action.data;
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: payloadData.orderItemId,
            orderId,
            personalizationResponse: payloadData.personalizationCompleteResponse,
        };
        yield call(gciPersonalizationCompleteAPI, configObj);
        yield call(retrieveCart);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            module: 'mbp-pwa-ui',
            function: 'giftMessageGciPersonalizationComplete',
            jsError: ex,
            message: 'Gift Message GCI Personalization complete Failed',
        });
    }
}

function* watchGiftMessageUpdate() {
    yield takeEvery(giftMessageActions.updateGiftMessage().type, giftMessageUpdate);
}

function* watchGciPersonalizationComplete() {
    yield takeEvery(giftMessageActions.gciPersonalizationComplete().type, gciPersonalizationComplete);
}

const watchers = [
    fork(watchGiftMessageChange),
    fork(watchGiftMessageUpdate),
    fork(watchGciPersonalizationComplete),
];

export {
    watchers,
    pwaDesktopAddGiftMessage,
    addExpressCheckoutGiftMessage,
};

export default {};
