/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpLogger from 'mbp-logger';
import moment from 'moment';
import { getCountryDetailsByThreeDigitCountryCode, getCountryDetailsByTwoDigitCountryCode } from '../../helpers/countryCode';
import { getCurrencyName } from '../../../Member/ducks/Auth/helper/helper';
import convertUSDToCurrency from '../../../../../apis/multicurrency-apis/convertUSDToCurrency';

const countryMapper = {
    CANADA: 'CA',
    'UNITED STATES OF AMERICA': 'US',
    CAN: 'CA',
    USA: 'US',
};

function checkIsInternational(orderItem) {
    const isInternational = orderItem?.xOrderAttrValues?.internationalCountryCode;
    if (isInternational) {
        return true;
    }
    return false;
}

function getInternationalCountryField(orderItem) {
    let countryCode = '';
    const intCountryCode = orderItem?.xOrderAttrValues?.internationalCountryCode || '';
    if (intCountryCode.length === 3) {
        const countryDetails = getCountryDetailsByThreeDigitCountryCode(intCountryCode);
        countryCode = countryDetails.countryCodeTwoDigit || '';
    } else {
        countryCode = intCountryCode;
    }
    return countryCode;
}

function getCountryField(country) {
    const countryCode = country || '';
    return countryMapper[countryCode] || countryCode;
}

const getOrderDate = (timePlaced) => {
    let returnOrderDate = '';
    if (timePlaced) {
        // replace T to space and / to - for timePlaced string
        // timePlaced - 2020-10-10T07:00:00 or 2020-10-10 07:00:00
        const orderDate = timePlaced.replace('T', ' ').replace(/-/g, '/').replace('.000', '');
        returnOrderDate = moment(new Date(`${orderDate} EST`).toString()).toDate('MM/DD/YYYY, h:mm a');
    }
    return returnOrderDate;
};

const filterInternationalCountry = (countriesArray, url) => {
    // strategy:
    // 1. Remove USA, CAN from the array. eg : ["USA", "CAN", "AUS"] - return ["AUS"] (international countries)
    // 2. Find the country details in the countryCode file for each item in the new array (international countries).
    // 3. Find the first item that match the 2 digit country code with the one in the url
    // 4. Return the 3 digit country code from the item

    // url structure /language/2-digit-country-iso-code/p/product-name
    const countryUrl = url?.split('/')[2] || '';
    let internationalCountry = '';
    const internationalCountriesArray = countriesArray.filter((countryCode) => !['USA', 'CAN'].includes(countryCode));
    const internationalCountriesDetailArray = internationalCountriesArray.map((countryCode) => getCountryDetailsByThreeDigitCountryCode(countryCode));
    let countryItem = internationalCountriesDetailArray.find((item) => item.countryCodeTwoDigit === countryUrl.toUpperCase());
    if (countryItem) {
        // this line is to make sure that we send the correct 3 digit ISO code
        // Remember the order of the country code file: 1. correct ISO codes eg IND 2. NON-ISO codes eg INA
        countryItem = getCountryDetailsByTwoDigitCountryCode(countryItem.countryCodeTwoDigit);
        internationalCountry = countryItem.countryCodeThreeDigit;
    }
    return internationalCountry;
};

const convertCurrency = async (jwt, salePrice, retailPrice, type = '') => {
    /*
    common issues one can run into:
        1. salePrice or retailPrice is a string
        2. salePrice or retailPrice is a 0
    Both will break the api inside convertUSDToCurrency
     */
    if (!salePrice) {
        /* eslint-disable-next-line no-param-reassign */
        salePrice = retailPrice;
    }
    if (!retailPrice) {
        /* eslint-disable-next-line no-param-reassign */
        retailPrice = salePrice;
    }
    try {
        const currency = getCurrencyName();
        if (typeof salePrice === 'string') {
            /* eslint-disable-next-line no-param-reassign */
            salePrice = Number(salePrice);
        }
        if (typeof retailPrice === 'string') {
            /* eslint-disable-next-line no-param-reassign */
            retailPrice = Number(retailPrice);
        }
        const { data } = await convertUSDToCurrency(null, jwt, salePrice, retailPrice, currency);
        const convertedSalesPrice = parseFloat(data?.prices?.[0]?.salePrice || salePrice);
        const convertedRetailPrice = parseFloat(data?.prices?.[0]?.retailPrice || retailPrice);
        return {
            salePrice: convertedSalesPrice,
            retailPrice: convertedRetailPrice,
        };
    } catch (ex) {
        mbpLogger.logError({
            jsError: ex,
            message: `convertCurrency call failed at ${type}`,
        });
        return { retailPrice, salePrice };
    }
};

export {
    getOrderDate,
    getCountryField,
    checkIsInternational,
    getInternationalCountryField,
    filterInternationalCountry,
    convertCurrency,
};
