/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    fork, take, put, call,
} from 'redux-saga/effects';

import mbpBrandClient from 'mbp-api-brand';
import mbpLogger from 'mbp-logger';

import * as brandActions from './Brand-Actions';
import { operations as configOperations } from '../Config';
import { getBrandById } from './Brand-Helpers';

const wcEnv = {};
function* fetchBrandData({ currentBrandId }) {
    mbpLogger.logDebug({
        currentBrandId,
        function: 'fetchBrandData',
        module: 'mbp-operations | Brand',
        message: 'Fetch brand data',
    });

    if (currentBrandId) {
        let brand;
        brand = yield call(mbpBrandClient.getBrandById, wcEnv, currentBrandId);
        if (!brand) {
            brand = yield call(getBrandById, currentBrandId);
        }
        mbpLogger.logDebug({
            currentBrandId,
            function: 'fetchBrandData',
            module: 'mbp-operations | Brand',
            message: 'Fetch brand from API',
        });

        // Brand Config (ref-brand)
        const brandConfig = yield call(configOperations.onLoadConfig, {
            topic: 'ref-brand',
            brand,
        });
        mbpLogger.logDebug({
            brandConfig,
            function: 'fetchBrandData',
            module: 'mbp-operations | Brand',
            message: 'Fetch brand from config server',
        });

        return {
            ...brand,
            ...brandConfig,
        };
    }

    return null;
}

function* onLoadBrand({ user, currentBrandId }) {
    mbpLogger.logDebug({
        user,
        currentBrandId,
        module: 'mbp-page',
        message: 'onLoadBrand',
    });

    let brand = null;

    try {
        brand = yield call(fetchBrandData, { currentBrandId });

        if (brand) {
            yield put(brandActions.brandLoaded(currentBrandId, brand));

            return brand;
        }
    } catch (ex) {
        mbpLogger.logError({
            user,
            currentBrandId,
            function: 'onLoadBrand',
            module: 'mbp-page',
            jsError: ex,
            message: 'onLoadBrand FAILED',
        });
        yield put(brandActions.brandLoadFailed(ex));
    }

    return null;
}

function* watchLoadBrands() {
    let action = yield take(brandActions.loadBrand().type);

    while (action !== END) {
        yield fork(onLoadBrand, action.payload);
        action = yield take(brandActions.loadBrand().type);
    }
}

const watchers = [fork(watchLoadBrands)];

export { onLoadBrand, watchers };
