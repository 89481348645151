/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import * as actionTypes from './DTW-ActionTypes';

export const getDTW = (data) => ({
    type: actionTypes.GET_DTW,
    data,
});

export const updateDTWOptions = (data) => ({
    type: actionTypes.UPDATE_DTW_OPTIONS,
    data,
});

export const selectDTW = (data) => ({
    type: actionTypes.SELECT_DTW,
    data,
});

export const updateSelectedDTW = (data) => ({
    type: actionTypes.UPDATE_SELECTED_DTW,
    data,

});

export const submitDTW = (data) => ({
    type: actionTypes.SUBMIT_DTW,
    data,
});
