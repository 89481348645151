/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native
import accountReducers from '../../../app/pages/Account/state/reducers';

const accountPersistConfig = {
    key: 'account',
    storage: sessionStorage,
    whitelist: ['states', 'countries', 'locations', 'relationships', 'APOFPO', 'cardtypes'],
};

export default persistReducer(accountPersistConfig, accountReducers);
