/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as remindersActionTypes from './Reminders-ActionTypes';

// API actions
export const getRemindersByJWT = () => ({
    type: remindersActionTypes.GET_REMINDERS_BY_EMAIL,
});

export const deleteReminderById = (payload) => ({
    type: remindersActionTypes.DELETE_REMINDERS_BY_ID,
    payload,
});

// Store updating actions
export const remindersLoaded = (reminders) => ({
    type: remindersActionTypes.REMINDERS_LOADED,
    payload: reminders,
});

export const removeReminderById = (reminderId) => ({
    type: remindersActionTypes.REMOVE_REMINDER_BY_ID,
    payload: reminderId,
});

export const clearReminders = () => ({
    type: remindersActionTypes.CLEAR_ALL_REMINDERS,
});

export const showDialog = (payload) => ({
    type: remindersActionTypes.SET_SHOW_DIALOG, // value depends on payload
    payload,
});

export const setRemindersDialogEventCategory = (payload) => ({
    type: remindersActionTypes.SET_REMINDERS_DIALOG_EVENT_CATEGORY, // value depends on payload
    payload,
});

export const setToolTipStatus = (status) => ({
    type: remindersActionTypes.SET_TOOLTIP_STATUS,
    payload: status || false,
});

export const setReauthenticateStatus = () => ({
    type: remindersActionTypes.SET_REAUTHENTICATION_STATUS,
});

export const setUserHideBanner = () => ({
    type: remindersActionTypes.USER_HIDE_BANNER,
});
