/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export async function asyncForEach(myArray, callback) {
    if (typeof callback !== 'function') {
        throw new TypeError(`${callback} is not a function`);
    }
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < myArray.length; index++) {
        // eslint-disable-next-line no-await-in-loop
        await callback(myArray[index], index, myArray);
    }
}

export default {};
