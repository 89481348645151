/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import loadable from '@loadable/component';
import { object } from 'prop-types';
import mbpLogger from 'mbp-logger';

const ViewAllCountriesPageContainer = loadable(() => import('../app/components/ContentTypeComponents/ViewAllCountries/ViewAllCountriesPageContainer'));
const ErrorBrowsingContainer = loadable(() => import('../app/components/GraphqlComponents/GraphqlCoreBrowsing/ErrorBrowsingContainer'));
const CustomerServiceIssueWithMyOrder = loadable(() => import('../app/components/GraphqlComponents/CustomerService/CustomerServiceIssueWithMyOrder/GraphqlCustomerServiceIssueWithMyOrder'));
const CustomerServiceEmailUsPage = loadable(() => import('../app/components/GraphqlComponents/CustomerService/CustomerServiceEmailUsPage/CustomerServiceEmailUsPage'));
const EmailUsPage = loadable(() => import('../app/components/GraphqlComponents/CustomerService/CustomerServiceEmailUsPage/EmailUsPage'));
const GraphqlPlantGuide = loadable(() => import('../app/components/GraphqlComponents/GraphqlPlantGuidePage/GraphqlPlantGuide'));
const GraphqlZodiacPlants = loadable(() => import('../app/components/GraphqlComponents/GraphqlZodiacPlants/GraphqlZodiacPlants'));
const Loader = ({ error }) => {
    if (error) {
    // return (
    //     <div>
    //         Error!
    //         <button type="button" onClick={retry}>Retry</button>
    //     </div>
    // );
        mbpLogger.logError({
            function: 'publicRoutes',
            module: 'mbp-pwa-ui',
            jsError: error,
            message: 'Checkout loadable route failed',
        });
    }

    return <div />;
};

Loader.propTypes = {
    error: object,
};
Loader.defaultProps = {
    error: null,
};

const LoadableCheckout = loadable(() => import(/* webpackChunkName: "Account" */ '../app/pages/Checkout/Checkout'));
const Auth = loadable(() => import(/* webpackChunkName: "Account" */ '../app/pages/Auth/Auth'));
const CustomerReviewsPlants = loadable(() => import(/* webpackChunkName: "Account" */ '../app/components/GraphqlComponents/CustomerReviewsPlants/CustomerReviewsPlants'));
const LoadableAccount = loadable(() => import(/* webpackChunkName: "Account" */ '../app/pages/Account/Account'));
// const LoadableAuth = loadable(() => import(/* webpackChunkName: "Auth" */ '../app/pages/Auth/Auth'));
const LoadableCoreBrowsing = loadable(() => import(/* webpackChunkName: "CoreBrowsingContainer" */ '../app/components/GraphqlComponents/GraphqlCoreBrowsing/CoreBrowsingContainer'));
const LoadableCollectionBrowsingContainer = loadable(() => import(/* webpackChunkName: "CollectionBrowsingContainer" */ '../app/components/GraphqlComponents/GraphqlCoreBrowsing/CollectionBrowsingContainer'));
const LoadableInternationalBrowsingContainer = loadable(() => import(/* webpackChunkName: "InternationalBrowsingContainer" */ '../app/components/GraphqlComponents/GraphqlCoreBrowsing/InternationalBrowsingContainer'));
const LoadableNavigationBrowsing = loadable(() => import(/* webpackChunkName: "CoreBrowsingContainer" */ '../app/components/GraphqlComponents/GraphqlCoreBrowsing/NavigationBrowsingContainer'));
const LoadablePlantsSearch = loadable(() => import(/* webpackChunkName: "PlantsSearchPage" */ '../app/components/GraphqlComponents/Search/PlantsSearchPage'));
const LoadableWishlistPage = loadable(() => import(/* webpackChunkName: "Wishlist" */ '../app/components/GraphqlComponents/WishlistPage/Wishlist'));
const LoadableCustomerServiceModifyOrder = loadable(() => import(/* webpackChunkName: "Wishlist" */ '../app/components/GraphqlComponents/CustomerService/CustomerServiceModifyOrder/GraphqlCustomerServiceModifyOrder'));
// TODO: Do not restore me until NextJS has been implemented
// const LoadableCoreBrowsing = Loadable({
//     loader: () => import(/* webpackChunkName: "CoreBrowsing" */ '../app/pages/CoreBrowsing/CoreBrowsing'),
//     loading: Loader,
// });

export default [
    {
        path: '/search',
        Component: LoadablePlantsSearch,
    },
    {
        path: '/favorites',
        Component: LoadableWishlistPage,
    },
    {
        path: '/:lang([a-zA-Z]{2})/issue-with-order',
        Component: CustomerServiceIssueWithMyOrder,
    },
    {
        path: '/:lang([a-zA-Z]{2})/modify-order',
        Component: LoadableCustomerServiceModifyOrder,
    },
    {
        path: '/account/:page?',
        Component: LoadableAccount,
    },
    {
        path: '/:lang([a-zA-Z]{2})/track-order',
        Component: LoadableAccount,
    },
    {
        path: '/:lang([a-zA-Z]{2})/order-information/:uuid/:order?/:zip?/:status?',
        Component: LoadableAccount,
    },
    {
        path: '/checkout/:page/:orderId',
        Component: LoadableCheckout,
    },
    {
        path: '/checkout/:page',
        Component: LoadableCheckout,
    },
    /* {
        path: '/auth/:page',
        Component: LoadableAuth,
    },
    {
        path: '/auth/:page',
        Component: LoadableAuth,
    }, */
    {
        path: '/:lang([a-zA-Z]{2})/morecountry',
        Component: ViewAllCountriesPageContainer,
    },
    {
        path: '/email-us',
        Component: EmailUsPage,
    },
    {
        path: '/plant-guide/:slug*',
        Component: GraphqlPlantGuide,
    },
    {
        path: '/zodiac-plants',
        Component: GraphqlZodiacPlants,
    },
    {
        path: '/customer-reviews',
        Component: CustomerReviewsPlants,
    },
    {
        path: '/:lang([a-zA-Z]{2})/email-us',
        Component: CustomerServiceEmailUsPage,
    },
    {
        /* /404 for error page */
        path: '/404',
        Component: ErrorBrowsingContainer,
    },
    {
        /* /404 for error page */
        path: '/:lang([a-zA-Z]{2})/404',
        Component: ErrorBrowsingContainer,
    },
    {
        /*  allow product URL followed by /p i.e www.0800flowers.com/en/can/p/sympathy/allwhitearrangement-95097 */
        path: '/:lang([a-zA-Z]{2})/p/:slug*',
        Component: LoadableCollectionBrowsingContainer,
    },
    {
        /*  allow product URL followed by /p i.e www.0800flowers.com/en/can/p/sympathy/allwhitearrangement-95097 */
        path: '/:lang([a-zA-Z]{2})/:countryCode([a-zA-Z]{2})/p/:slug*',
        Component: LoadableCollectionBrowsingContainer,
    },
    {
        /*  allow collection URL followed with /c i.e www.0800flowers.com/en/can/c/international/sympathy-flowers-canada */
        path: '/:lang([a-zA-Z]{2})/c/:slug*',
        Component: LoadableCollectionBrowsingContainer,
    },
    {
        /*  allow collection URL followed with /c i.e www.0800flowers.com/en/can/c/international/sympathy-flowers-canada */
        path: '/:lang([a-zA-Z]{2})/:countryCode([a-zA-Z]{2})/c/:slug*',
        Component: LoadableCollectionBrowsingContainer,
    },
    {
        /*  allow international country page with iso 3 alpha code i.e www.0800flowers.com/en/can */
        path: '/:lang([a-zA-Z]{2})/:countryCode([a-zA-Z]{2})/:slug*',
        Component: LoadableInternationalBrowsingContainer,
    },
    {
        /*  allow international home page i.e www.0800flowers.com/en/ */
        path: '/:lang([a-zA-Z]{2})',
        Component: LoadableInternationalBrowsingContainer,
    },
    {
        path: '/p/:slug*',
        Component: LoadableNavigationBrowsing,
    },
    {
        path: '/c/:slug*',
        Component: LoadableNavigationBrowsing,
    },
    {
        path: '/track-order',
        Component: LoadableAccount,
    },
    {
        path: '/order-information/:uuid?/:order?/:email?',
        Component: LoadableAccount,
    },
    {
        path: '/modify-order',
        Component: LoadableCustomerServiceModifyOrder,
    },
    {
        path: '/issue-with-my-order',
        Component: CustomerServiceIssueWithMyOrder,
    },
    {
        path: '/auth/:page',
        Component: Auth,
    },
    {
        path: '*',
        Component: LoadableCoreBrowsing,
    },
];
