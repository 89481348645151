/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';

const APP_AUTH_DOMAIN =  mbpUtil.getEnv('APP_AUTH_DOMAIN') || 'http://localhost';
const APP_AUTH_AUDIENCE = mbpUtil.getEnv('APP_AUTH_AUDIENCE') ? mbpUtil.getEnv('APP_AUTH_AUDIENCE') : mbpUtil.getEnv('APP_AUTH_DOMAIN') || 'localhost';
const APP_ORIGIN = (typeof window !== 'undefined' && window.location) ? window.location.origin : 'http://localhost';

const authConfig = {
    domain: APP_AUTH_DOMAIN,
    audience: APP_AUTH_AUDIENCE,
    redirectURL: `${APP_ORIGIN}/auth/callback`,
    appSource: mbpUtil.getEnv('APP_AUTH_LOGIN_SOURCE') || '',
    connection: 'T0-Direct-MDM-DB',
};

export default authConfig;
