/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
// Celebrations greeting card
import appActionTypes from './CGC-ActionTypes';

export const resetCGCCardData = () => ({
    type: appActionTypes.CGC_RESET_CARD_DATA,
});

export const setCGCCardData = (cardData) => ({
    type: appActionTypes.CGC_SET_CARD_DATA,
    payload: cardData,
});

export const addCGCDataToApi = (data) => ({
    type: appActionTypes.CGC_ADD_DATA_TO_API,
    payload: data,
});
export const addCGCLabelsDataToApi = (data) => ({
    type: appActionTypes.CGC_ADD_LABELS_DATA_TO_API,
    payload: data,
});

export const getCGCCustomCardData = (data) => ({
    type: appActionTypes.CGC_GET_CUSTOM_CARD_DATA,
    payload: data,
});

export const setCGCCustomCardData = (data) => ({
    type: appActionTypes.CGC_SET_CUSTOM_CARD_DATA,
    payload: data,
});
