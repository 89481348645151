/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import actionTypes from './Payment-ActionTypes';

const processPaymentPage = (props) => ({
    type: actionTypes.PROCESS_PAYMENT_PAGE,
    data: props,
});

const loadCompletedPaymentDetails = () => ({
    type: actionTypes.LOAD_COMPLETE_PAYMENT_DETAILS,
});

const updateCompletedPaymentDetails = (data) => ({
    type: actionTypes.UPDATE_COMPLETE_PAYMENT_DETAILS,
    data,
});

const activePaymentMethod = (paymentMethod) => ({
    type: actionTypes.ACTIVE_PAYMENT_METHOD,
    data: {
        paymentMethod,
    },
});

const inActivePaymentMethod = (paymentMethod) => ({
    type: actionTypes.INACTIVE_PAYMENT_METHOD,
    data: {
        paymentMethod,
    },
});

const placeOrder = (config) => ({
    type: actionTypes.PLACE_ORDER,
    payload: config,
});

const placeOrderComplete = () => ({
    type: actionTypes.PLACE_ORDER_COMPLETE,
});

const recordPaymentMethodType = () => ({
    type: actionTypes.RECORD_PAYMENT_METHOD_TYPE,
});

const recordPaymentMethodTypeCompleted = () => ({
    type: actionTypes.RECORD_PAYMENT_METHOD_TYPE_COMPLETED,
});

const applyGiftCard = (giftcardNumber, giftcardPin) => ({
    type: actionTypes.APPLY_GIFT_CARD_ACTION,
    payload: {
        giftcardNumber,
        giftcardPin,
    },
});

const applyGiftCardProccessCompleted = () => ({
    type: actionTypes.APPLY_GIFT_CARD_PROCESS_COMPLETED,
});

const removeGiftCard = (giftcardNumber, giftCardId) => ({
    type: actionTypes.REMOVE_GIFT_CARD_ACTION,
    payload: {
        giftcardNumber,
        giftCardId,
    },
});

const removeGiftCardProccessCompleted = () => ({
    type: actionTypes.REMOVE_GIFT_CARD_PROCESS_COMPLETED,
});

const loadPaymentMethods = (data) => ({
    type: actionTypes.CHECKOUT_PAYMENT_METHODS_LOAD,
    data,
});

export const updateShowOrderConfirmation = () => ({
    type: actionTypes.SHOW_ORDER_CONFIRMATION,
});

const validateApiDesktop = () => ({
    type: actionTypes.CHECKOUT_PAYMENT_METHODS_VALIDATE_API_DESKTOP,
});

const processNBCUMovie = (isFromApplePay, history) => ({
    type: actionTypes.PROCESS_NBCU_MOVIE,
    payload: {
        isFromApplePay,
        history,
    },
});

const applePayOrderSuccess = (history) => ({
    type: actionTypes.APPLE_PAY_ORDER_SUCCESS,
    payload: { history },
});

const applePayOrderValidateMerchantSuccess = () => ({
    type: actionTypes.APPLE_PAY_VALIDATE_MERCHANT_SUCCESS,
});

export {
    processPaymentPage,
    placeOrder,
    placeOrderComplete,
    updateCompletedPaymentDetails,
    loadCompletedPaymentDetails,
    activePaymentMethod,
    inActivePaymentMethod,
    applyGiftCard,
    applyGiftCardProccessCompleted,
    removeGiftCard,
    removeGiftCardProccessCompleted,
    loadPaymentMethods,
    validateApiDesktop,
    recordPaymentMethodType,
    recordPaymentMethodTypeCompleted,
    processNBCUMovie,
    applePayOrderSuccess,
    applePayOrderValidateMerchantSuccess,
};
