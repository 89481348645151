/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/payment';
const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_ACTION = '/giftcard';
/**
 * Apply a gift card
 * @param {*} wcEnv
 * @param {*} orderId
 * @param {*} giftcardNumber
 * @param {*} giftcardPin
 * @param {*} nonGiftCardItemsTotal
 * @param {*} isSavingsPass
 * @param {*} processSavingsPass
 * @param {*} removeSavingsPassPIId
 */
const applyGiftCard = (
    wcEnv, jwtToken,
    orderId,
    giftcardNumber,
    giftcardPin,
    nonGiftCardItemsTotal,
    isSavingsPass,
    processSavingsPass,
    removeSavingsPassPIId,
) => {
    const payload = {
        egcOrderId: orderId,
        payMethodId: 'EnterpriseGiftCard',
        account: giftcardNumber,
        securityCode: giftcardPin,
        nonGiftCardItemsTotal,
        isSavingsPass,
        processSavingsPass,
        removeSavingsPassPIId,
        fromClient: 'mbp-ui-app',
    };

    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;
    const resourcePage = `${RESOURCE_PAGE_FACADE}/${orderId}${RESOURCE_PAGE_ACTION}`;

    return restClient.postFacade(env, resourcePage, jwtToken, payload)
        .then((response) => response);
};

export default applyGiftCard;

/* current system params
PiId:
storeccno:
storeccname:
ccExpMonth:
ccExpYear:
plccstoreccno: /
plccstoreccname: /
chkFlw: Y
payMethodId: EnterpriseGiftCard
piAmount: 0.00
egcOrderId: 6000407504
storeId: 21051
domainStoreId:
orderTotalBalance: 101.98
giftCardItemsTotal: 0.00
nonGiftCardItemsTotal: 101.98
giftCardsTotal: 0.00
promoAccount:
promoPin:
isSavingsPass: false
processSavingsPass: true
removeSavingsPassPIId:
account: 11111111111111111111
securityCode: 1111
*/
