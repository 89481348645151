/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';

import * as visaCheckoutActions from './VisaCheckout-Actions';
import * as visaCheckoutOperations from './VisaCheckout-Operations';

// Plugin Config
const visaCheckoutPluginConfig = {
    sdkURL: mbpUtil.getEnv('APP_VISA_CHECKOUT_SDK_URL'),
    imgURL: mbpUtil.getEnv('APP_VISA_CHECKOUT_IMG_URL'),
};

export {
    visaCheckoutOperations, visaCheckoutActions, visaCheckoutPluginConfig,
};
