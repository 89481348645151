/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { commonWatcherSagas } from './ducks/Common';
import { checkoutWatcherSagas } from './ducks/Checkout';
import { cartWatcherSagas } from './ducks/Cart';
import { orderWatcherSagas } from './ducks/Order';
import { paymentWatcherSagas } from './ducks/Payment';
import { recipientWatcherSagas } from './ducks/Recipient';
import { eshopCheckoutWatcherSagas } from './ducks/EshopCheckout';

const watcherSagas = [
    ...commonWatcherSagas,
    ...cartWatcherSagas,
    ...checkoutWatcherSagas,
    ...orderWatcherSagas,
    ...paymentWatcherSagas,
    ...recipientWatcherSagas,
    ...eshopCheckoutWatcherSagas,
];

export default watcherSagas;
