/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const supportedLanguage = ['en', 'fr', 'es'];
const currencyMapping = [{
    currency: 'USD',
    symbol: '$',
}, {
    currency: 'EUR',
    symbol: '€',
}, {
    currency: 'CAD',
    symbol: 'C$',
}, {
    currency: 'GBP',
    symbol: '£',
}];

const refineLanguage = (languageArr) => {
    languageArr.forEach((element, index) => {
        if (element.length > 2) {
            /* eslint no-param-reassign: ["error", { "props": false }] */
            languageArr[index] = element.slice(0, 2);
        }
    });
    return languageArr;
};

const getLanguageCode = (paramCode) => {
    const languageCodeArr = paramCode?.length > 1 ? paramCode.split(',') : [];
    const refineLanguageArr = refineLanguage(languageCodeArr);
    const resultLanguage = refineLanguageArr.filter((element) => {
        if (supportedLanguage.indexOf(element) !== -1) {
            return element;
        }
        return false;
    });
    if (resultLanguage.length === 0) {
        return 'en';
    }
    return resultLanguage[0];
};

const getCurrencyCode = (ipInfo) => {
    const currencyDetails = currencyMapping.filter((currency) => currency.currency === ipInfo.currency);
    if (currencyDetails.length === 0) {
        return {
            currency: 'USD',
            symbol: '$',
        };
    }
    return currencyDetails[0];
};

const getCurrencySymbol = (sessionStorageCurrency) => {
    const data = currencyMapping.filter((currency) => {
        if (currency.currency === sessionStorageCurrency) {
            return currency;
        }
        return null;
    });
    return data[0].symbol;
};

export {
    getLanguageCode,
    getCurrencyCode,
    getCurrencySymbol,
};

export default {};
