/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createSelector } from 'reselect';
import { getBrandMovieSkus } from '../../../App/App-Selectors';
import { getWalletEntries } from '../../../Member/ducks/Common/Common-Selectors';

import brandThemes from '../../../../../app/components/AppShell/brandtheme';

export const getCurrentBrandId = (state) => ({ ...{ ...state }.ui }.brandId) || '';

/**
 * @param {} state
 */
export const getBrandsById = (state) => {
    if (state && state.page && state.page.brands && state.page.brands.byId) {
        return state.page.brands.byId;
    }

    return null;
};

// Brand (Client Id)
export const getBrand = createSelector(
    [
        getCurrentBrandId,
        getBrandsById,
    ],
    (currentBrandId, brandsById) => {
        if (currentBrandId && brandsById[currentBrandId]) {
            return brandsById[currentBrandId];
        }

        return null;
    },
);

/**
 * @param {} state
 */
export const getConfigByDomain = (state) => {
    if (state && state.app && state.app.config && state.app.config.byDomain) {
        return state.app.config.byDomain;
    }
    return null;
};

/**
 * @param {} state
 */
// Flags
export const getFlags = createSelector(
    [
        getBrand,
        getConfigByDomain,
    ],
    (brand, config) => {
        if (brand && brand.domain && config) {
            return config[brand.domain]['app-feature-flags'];
        }
        return {};
    },
);

/**
 * @param {} state
 */
// fdOrderVO
export const getFDOrder = (state) => {
    if (state
        && state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO
    ) {
        return state.checkout.cart.orderDetails.fdOrderVO;
    }
    return null;
};

// export const getJWT = (state) => getAccessToken(state);
export const getLoaderStatus = (state) => state.checkout.common.showLoadingMessage.flag;
export const getLoaderId = (state) => state.checkout.common.showLoadingMessage.loaderId;

export const getOrder = (state) => state?.checkout?.order;
export const getOrderId = (state) => state?.checkout?.order?.orderId;
export const getTotalItemInCart = (state) => state?.checkout?.order?.cartCount;
export const getAtlasOrderId = (state) => state?.checkout?.order?.atlasOrderId;
export const getIsExpressCheckout = (state) => state?.checkout?.order?.expressCheckout;
export const getCartData = (state) => state?.checkout?.cart;
export const getAmount = (state) => ({ orderTotal: state?.checkout?.cart?.orderDetails?.fdOrderVO?.orderTotalBalance });
export const getGiftCards = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.giftCards;
const getProperties = (state) => state?.checkout?.common?.properties;
export const getOrderTotalProduct = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.totalProduct;
export const getOrderDeliveryExtraFee = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.detailedShipping?.shippingCharge?.surcharges?.[0]?.amount;
export const getTaxConfirmation = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.taxStatus;
export const getRecipientAddress = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.recipients;
const getTotalAdjustments = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.totalAdjustments;
const getorderTotalBeforeTaxes = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.orderTotalBeforeTaxes;
const getTotalShippingAdjustments = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.totalShippingAdjustments;
export const getOrderTotalShipping = (state) => {
    const fdOrderVO = getFDOrder(state);
    if (fdOrderVO && fdOrderVO?.detailedShipping?.shippingCharge?.baseCharges && fdOrderVO?.detailedShipping?.shippingCharge?.upCharges) {
        return (+fdOrderVO?.detailedShipping?.shippingCharge?.baseCharges + (+fdOrderVO?.detailedShipping?.shippingCharge?.upCharges)).toFixed(2);
    }
    return 0;
};
export const getOrderTotalSurcharge = (state) => {
    const fdOrderVO = getFDOrder(state);
    const totalSurcharge = fdOrderVO?.detailedShipping?.shippingCharge?.upCharges;
    return Number(totalSurcharge || 0)?.toFixed(2);
};
export const getOrderTotalTax = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.totalTax;
const getOrderPromotions = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.appliedPromos;
const getGCsAppliedToOrder = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.appliedGiftCards;
const getShippingTax = (state) => state?.checkout?.cart?.orderDetails?.fdOrderVO?.totalShippingTax;

export const getBilling = (state) => {
    if (
        state
        && state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO
        && state.checkout.cart.orderDetails.fdOrderVO.billingAddress
    ) {
        return {
            firstName: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.firstName,
            lastName: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.lastName,
            address1: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.addressLine1,
            address2: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.addressLine2,
            state: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.state,
            country: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.country,
            zipCode: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.zipCode,
            city: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.city,
            phone: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.phoneNo,
            email: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.email,
            confirmEmail: state.checkout.cart.orderDetails.fdOrderVO.billingAddress.email,
        };
    }

    return {};
};

export default getBilling;
// Error
export const getError = (state) => state?.checkout?.common?.error;
export const getPaymentError = (state) => state?.checkout?.common?.error?.payment;
export const getCartError = (state) => state?.checkout?.common?.error?.cart || '';
export const getRecipientFormError = (state) => state?.checkout?.common?.error?.recipientForm;
export const getRecipientGiftMessageError = (state) => state?.checkout?.common?.error?.giftMessage;
export const getGiftCardError = (state) => state?.checkout?.common?.error?.giftCard;
export const getPromotionError = (state) => state?.checkout?.common?.error?.promotion;
export const getCalendarError = (state) => state?.checkout?.common?.error?.calendar;
export const getDTWError = (state) => state?.checkout?.common?.error?.dtw;
export const getPassportError = (state) => state?.checkout?.common?.error?.passport;
export const getPayPalError = (state) => state?.checkout?.common?.error?.paypal;

export const getIsPromotionHasError = (state) => {
    const promoError = getPromotionError(state);
    if (promoError?.error || promoError?.promotionCode) {
        return true;
    }
    return false;
};

export const getIsPaymentHasError = (state) => {
    const paymentError = getPaymentError(state);
    let errorMessage = '';
    Object.keys(paymentError).forEach((fieldName) => {
        if (paymentError[fieldName] !== '' && fieldName !== 'validateBillingAddress' && fieldName !== 'error') {
            errorMessage += `${paymentError[fieldName]} `;
        }
    });

    if (errorMessage !== '') {
        return true;
    }

    return false;
};

// Recipient
export const getCalShippingAttempt = (state) => state.checkout.recipient.calShipping.calShippingChargesAttempt;
export const getCalShippingStatus = (state) => state.checkout.recipient.calShipping.calShippingApplied;
export const getRecipientForm = (state) => state.checkout.recipient.recipientForm;
export const getCurrentRecipients = (state) => state.checkout.recipient.recipients;
export const getWorkingRecipient = (state) => state.checkout.recipient.workingRecipient;
export const getShippingInfo = (state) => state.checkout.recipient.shippingInfo;
export const getGiftMessageInfo = (state) => state.checkout.recipient.giftMessage;
export const getUserSubmittedProductFilterZipcode = (state) => state.ui.appContext.userSubmittedProductFilterZipcode;
export const getLoggedOrderItemDetails = (state) => state.checkout.recipient.orderItems;
export const getIfZipCodeIsValid = (state) => {
    const recipientFormError = getRecipientFormError(state);
    // avoid international product - zipcode is saved as 00000
    if (recipientFormError?.zipCode !== '00000' && recipientFormError?.zipCode === 'Invalid Zip Code') {
        return false;
    }
    return true;
};

// QAS Address Search
export const getQasSearchSuggestion = (state) => state.checkout.common.addressSearchQAS.suggestion;
export const getQasSearchFormattedAddress = (state) => state.checkout.common.addressSearchQAS.formattedAddress;
export const getShowValidatedAddressSuggestion = (state) => state.checkout.common.addressSearchQAS.showValidatedAddressSuggestion;
export const getHasQasFailed = (state) => state.checkout.common.addressSearchQAS.qasFailed;

// Gift Expressions
export const getGiftMessageExpressions = (state) => state.checkout.recipient.giftMessageExpressions;

// Payment
export const getCreditCardBrand = (state) => state.checkout.payment.creditCardBrand;
export const getPaymentDetails = (state) => state.checkout.payment.paymentDetails;
export const getRewardPointsInfo = (state) => state.checkout.payment.rewardPoints;
export const getPaymentInfo = (state) => state.checkout.payment.paymentForm;
export const getBillingInfo = (state) => state.checkout.payment.billingAddressForm;
export const getBillingFormFilled = (state) => state.checkout.payment.billingAddressForm.isFormValid;
export const getPaymentMethod = (state) => state.checkout.payment.paymentForm.paymentMethod;
export const getPaymentMethods = (state) => state.checkout.payment.paymentMethods;
export const getApplePayStatus = (state) => state.checkout.payment?.paymentMethods?.ApplePay?.active;
export const getPaypalPaymentDetails = (state) => state.checkout.payment.paypal;
export const getIsPaypalHasBillingDetails = (state) => {
    const { paypal } = state.checkout.payment;
    if (paypal.isBillingAddressMissingMandatoryFields && paypal.payload.nonce) {
        return false;
    }
    return true;
};
export const getChangePaypalAccount = (state) => state.checkout.payment.paypal.changeAccount;
export const getSavedPaypalAccount = createSelector(
    [
        getWalletEntries,
    ],
    (walletCards) => {
        if (walletCards.length > 0) {
            return walletCards.filter((card) => card?.cardType === 'PY')[0];
        }
        return {};
    },
);
export const getPaymentUsingPaypalWallet = createSelector(
    [
        getFlags,
        getChangePaypalAccount,
        getSavedPaypalAccount,
    ],
    (flags, changeAccount, savedPaypalAccount) => {
        if (flags['is-paypal-wallet-enabled'] && !changeAccount && savedPaypalAccount?.cardType === 'PY') {
            return true;
        }
        return false;
    },
);
export const getPaypalTokenizedCard = (state) => {
    const savedPaypalAccount = getSavedPaypalAccount(state);
    if (savedPaypalAccount?.cardNumber) {
        return savedPaypalAccount.cardNumber;
    }
    return null;
};

// Payment - Alternative Methods
export const getPayPalVO = (state) => state.checkout.cart.orderDetails && state.checkout.cart.orderDetails.payPalVO;
export const getChasePayVO = (state) => state.checkout.cart.orderDetails && state.checkout.cart.orderDetails.chasePayVO;
export const getVisaCheckoutVO = (state) => state.checkout.cart.orderDetails && state.checkout.cart.orderDetails.VISACheckoutVO;
// Payment - Readiness - is true if validationOrder API Returns true
export const getPaymentPageReady = (state) => state.checkout.payment.paymentReadiness.pageReady;
export const getIfPayPalPaymentFailedDueToMissingAddress = createSelector(
    [
        getPaymentError,
    ],
    (paymentFailedError) => {
        if (paymentFailedError.error === 'ERROR_PAYPAL_ADDRESS') {
            return true;
        }
        return false;
    },
);

// Subscription
export const getSubscription = (state) => state.checkout.payment.subscription;
export const getEmailOptIn = (state) => state.checkout.payment.subscription.emailOptIn;
export const getSMSOptIn = (state) => state.checkout.payment.subscription.smsOptIn;
export const getOptInEntryMessage = (state) => state.checkout.payment.subscription.optInEntryMessage;
// account - place order
export const getAccountMessage = (state) => {
    if (
        state
        && state.checkout
        && state.checkout.payment
        && state.checkout.payment.account
        && state.checkout.payment.account.accountMessage
    ) {
        return state.checkout.payment.account.accountMessage;
    }

    return null;
};
export const getUserProfile = (state) => state?.account?.userProfile;

// promotion
export const getPromotionDetails = (state) => state.checkout.payment.promotion;

const getGiftCardDiscounts = (state) => {
    const giftCards = getGiftCards(state);

    if (giftCards && giftCards.length > 0) {
        let result = giftCards.reduce((sum, { amount }) => sum + parseFloat(amount), 0);
        result = result.toString();

        if (result.indexOf('.') === -1) {
            result += '.00';
            return result;
        }

        return result;
    }

    return '0.00';
};

export const getOrderTotalBalance = (state) => {
    if (state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO
        && state.checkout.cart.orderDetails.fdOrderVO.orderTotalBalance) {
        const total = state.checkout.cart.orderDetails.fdOrderVO.orderTotalBalance - getGiftCardDiscounts(state);
        if (total <= 0) {
            return 0;
        }
        return total.toFixed(2);
    }
    return 0;
};

export const safeGetItemCost = (state) => {
    if (state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO
        && state.checkout.cart.orderDetails.fdOrderVO.totalProduct) {
        const itemPrice = state.checkout.cart.orderDetails.fdOrderVO.totalProduct;
        if (itemPrice <= 0) {
            return 0;
        }
        return itemPrice.toFixed(2);
    }
    return '';
};

export const safeGetTax = (state) => {
    if (state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO
        && state.checkout.cart.orderDetails.fdOrderVO.totalTax) {
        const tax = state.checkout.cart.orderDetails.fdOrderVO.totalTax;
        if (tax <= 0) {
            return 0;
        }
        return tax.toFixed(2);
    }
    return '';
};

export const safeGetShipping = (state) => {
    if (state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO
        && state.checkout.cart.orderDetails.fdOrderVO.totalShipping) {
        const shipping = state.checkout.cart.orderDetails.fdOrderVO.totalShipping;
        if (shipping <= 0) {
            return 0;
        }
        return shipping.toFixed(2);
    }
    return '';
};

export const disablePaymentFields = (state) => (getOrderTotalBalance(state) === 0);

export const getPlaceOrderStatus = createSelector(
    [
        getPaymentInfo,
        getBillingInfo,
        disablePaymentFields,
        getPayPalVO,
        getChasePayVO,
        getVisaCheckoutVO,
        getPaypalPaymentDetails,
        getPaymentUsingPaypalWallet,
        getCalShippingStatus,
    ],
    (paymentInfo, billingInfo, disablePaymentMethod, payPal, chasePay, visaCheckout, paypalBT) => {
        if (chasePay || payPal) {
            return true;
        }

        if (visaCheckout) { // Visa Checkout
            return true;
        }

        if (getPaymentUsingPaypalWallet) {
            return true;
        }

        if (paypalBT.payload && paypalBT.payload.nonce && billingInfo.isFormValid) { // Paypal Braintree
            return true;
        }

        if (paymentInfo && billingInfo) { // Credit Card
            if (disablePaymentMethod && billingInfo.isFormValid) { // Gift Card covers order
                return true;
            }
            if (paymentInfo.isFormValid && billingInfo.isFormValid) { // Credit Card
                return true;
            }
            return false;
        }
        return false;
    },
);

export const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

// Exclude Passport Item from the cart
export const getOrderItemExcludePassport = (state) => {
    if (state.checkout?.cart?.orderDetails?.fdOrderVO?.recipients) {
        const { recipients } = state.checkout.cart.orderDetails.fdOrderVO;
        return recipients.filter((items) => (
            items.orderItems.filter((orderItems) => orderItems.catalogEntryVO.primeFreeShipFlag !== 'M').length !== 0
        ));
    }
    return [];
};

export const isDeliveryAddressFilledForAllItems = (state) => {
    const recipients = getOrderItemExcludePassport(state);
    let addressFilled = true;
    if (recipients && recipients.length > 0) {
        recipients.forEach((orderItem) => {
            const { recipientAddress } = orderItem;
            if (recipientAddress && !recipientAddress.firstName) {
                addressFilled = false;
            }
        });
    } else {
        addressFilled = false;
    }
    return addressFilled;
};

export const getReviewOrderStatus = createSelector(
    [
        getPaymentInfo,
        getBillingInfo,
        disablePaymentFields,
        getSMSOptIn,
        getPaymentUsingPaypalWallet,
        getIsPaypalHasBillingDetails,
        isDeliveryAddressFilledForAllItems,
    ],
    (paymentInfo, billingInfo, disablePaymentMethod, smsOptIn, usePaypalWallet, paypalHasBillingAddress, orderItemAddressFilled) => {
        // sms opt-in enabled
        if (smsOptIn) {
            const mobilePhone = removeMaskForPhoneNumber(billingInfo.mobilePhone);
            if (smsOptIn && mobilePhone.length !== 10) {
                return false;
            }
        }

        // Giftcard Fully Applied & Billing Form filled
        if (disablePaymentMethod && billingInfo.isFormValid) { // Gift Card covers order
            return true;
        }

        // credit card
        if (paymentInfo.paymentMethod.id === 'CreditCard'
            && paymentInfo.isFormValid
            && billingInfo.isFormValid) {
            return true;
        }

        // Paypal Wallet Card Or Paypal - Missing Billing Address
        if (paymentInfo.paymentMethod.id === 'PayPal'
            && ((usePaypalWallet || !paypalHasBillingAddress)
            && !billingInfo.isFormValid)) {
            return false;
        }

        // all other payment methods
        if (paymentInfo.paymentMethod.id !== 'CreditCard' && orderItemAddressFilled) {
            return true;
        }

        return false;
    },
);

// TODO: Moved to checkout-selectors.js. Possibly remove me.
export const getEPaymentDetails = createSelector(
    [
        getPaymentDetails, getPayPalVO, getChasePayVO, getVisaCheckoutVO,
    ],
    (paymentInfo, payPalVO, chasePayVO, visaCheckoutVO) => {
        let paymentDetails = {};
        const paymentMethodId = paymentInfo.payMethodId ? paymentInfo.payMethodId.toLowerCase() : '';

        try {
            if (payPalVO || paymentMethodId === 'paypal') {
                paymentDetails.paymentMethod = {
                    id: 'PayPal',
                    name: 'PayPal',
                };

                // Append VO
                paymentDetails = { ...paymentDetails, ...payPalVO };
            } else if (chasePayVO || paymentMethodId === 'chasepay') {
                paymentDetails.paymentMethod = {
                    id: 'ChasePay',
                    name: 'Chase Pay',
                };

                // Append VO
                paymentDetails = { ...paymentDetails, ...chasePayVO };
            } else if (visaCheckoutVO || paymentMethodId === 'visacheckout') {
                paymentDetails.paymentMethod = {
                    id: 'VisaCheckout',
                    name: 'Click to Pay',
                };

                // Append VO
                paymentDetails = { ...paymentDetails, ...visaCheckoutVO };
            } else if (paymentMethodId === 'applepaycheckout') {
                paymentDetails.paymentMethod = {
                    id: 'ApplePay',
                    name: 'Apple Pay',
                };
            } else if (paymentMethodId === 'googlepay') {
                paymentDetails.paymentMethod = {
                    id: 'GooglePay',
                    name: 'Google Pay',
                };
            } else {
                paymentDetails.paymentMethod = {
                    id: 'CreditCard',
                    name: 'Credit Card',
                };
            }
        } catch (ex) {
            console.error('ERROR: getOrderDetails selector FAILED', ex);
        }

        return paymentDetails;
    },
);

export const getCompletedOrderPaymentDetails = createSelector(
    [
        getPayPalVO, getChasePayVO,
    ],
    (payPalVO, chasePayVO) => {
        const paymentDetails = {};

        try {
            if (payPalVO) {
                paymentDetails.paymentMethod = 'PayPal';
            } else if (chasePayVO) {
                paymentDetails.paymentMethod = 'ChasePay';
            } else {
                paymentDetails.paymentMethod = 'CreditCard';
                paymentDetails.ccNum = '';
                paymentDetails.ccType = 'Visa';
            }
        } catch (ex) {
            console.error('ERROR: getOrderDetails selector FAILED', ex);
        }

        return paymentDetails;
    },
);

export const getCartLoaded = (state) => state.checkout?.cart?.cartLoaded;

export const getSelectedMovie = createSelector(
    [
        getOrderItemExcludePassport,
        getBrandMovieSkus,
    ],
    (recipients, brandMovieSkus) => {
        let movieItemsArray;
        if (recipients.length === 1) {
            let orderItems;
            if (recipients) {
                ({ orderItems } = recipients[0]);
            }
            if (orderItems && brandMovieSkus) {
                // below regex removes all brand logic from a skuCode and just sends the sku
                // 1001-I-4343B -> 4343B
                movieItemsArray = orderItems.filter((orderItem) => brandMovieSkus[orderItem.catalogEntryVO.skuCode.match(/[^-_]*$/)]);
            }
        } else if (recipients.length > 1) {
            movieItemsArray = recipients.filter((recipient) => brandMovieSkus[recipient.orderItems[0].catalogEntryVO.skuCode.match(/[^-_]*$/)]);
        }
        return movieItemsArray?.length ? movieItemsArray : null;
    },
);

export const getAllItemsExceptForMovieAndPassport = createSelector(
    [
        getOrderItemExcludePassport,
        getBrandMovieSkus,
    ],
    (recipients, brandMovieSkus) => {
        let movieItems;
        if (recipients.length === 1) {
            let orderItems;
            if (recipients) {
                ({ orderItems } = recipients[0]);
            }
            if (orderItems && brandMovieSkus) {
                // below regex removes all brand logic from a skuCode and just sends the sku
                // 1001-I-4343B -> 4343B
                movieItems = orderItems.filter((orderItem) => !brandMovieSkus[orderItem.catalogEntryVO.skuCode.match(/[^-_]*$/)]);
            }
        } else if (recipients.length > 1) {
            movieItems = recipients.filter((recipient) => !brandMovieSkus[recipient.orderItems[0].catalogEntryVO.skuCode.match(/[^-_]*$/)]);
        }
        return movieItems?.length ? movieItems : null;
    },
);

export const getCartOrderDetails = (state) => {
    if (state && state.checkout && state.checkout.cart && state.checkout.cart.orderDetails) {
        return state.checkout.cart.orderDetails;
    }

    return null;
};

export const getRecipientsExcludePassport = createSelector(
    [
        getOrder,
        getOrderId,
        getCartData,
        getCartOrderDetails,
        getFDOrder,
        getOrderItemExcludePassport,
    ],
    (order, orderId, cart, orderDetails, fdOrder, excudePassportItem) => {
        let recips = [];
        try {
            if (!order && !orderId) {
                return [];
            }
            if (cart && orderDetails && fdOrder && fdOrder.recipients) {
                recips = excudePassportItem;
            }
        } catch (ex) {
            console.error('ERROR: getRecipients selector FAILED', ex);
        }
        return recips;
    },
);

export const safeGetOrderItemsId = (state) => {
    if (state
        && state.checkout
        && state.checkout.cart
        && state.checkout.cart.fdOrderVO
        && state.checkout.cart.fdOrderVO.recipients) {
        return state.checkout.cart.orderDetails.fdOrderVO.recipients[0].orderItems[0].orderItemsId;
    }
    return '';
};

// Get the list of orderItem ids
export const getOrderItemIds = (state) => {
    const orderItemIds = [];
    if (state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO) {
        const { recipients } = state.checkout.cart.orderDetails.fdOrderVO;
        recipients.filter((items) => (
            items.orderItems.filter((orderItems) => orderItems.catalogEntryVO.primeFreeShipFlag !== 'M').length !== 0
        )).forEach((items) => {
            items.orderItems.forEach((item) => {
                orderItemIds.push(item.orderItemsId);
            });
        });
    }
    return orderItemIds;
};

// Get Order Item By Order Id
export const getOrderItemById = (orderItemList, OrderItemId) => {
    const filterOrderItem = [];
    if (orderItemList.length > 0) {
        orderItemList.forEach((items) => {
            items.orderItems.forEach((orderItems) => {
                if (orderItems.orderItemsId === OrderItemId) {
                    const { recipientAddress } = items;
                    filterOrderItem[0] = {
                        orderItems: [orderItems],
                        recipientAddress,
                    };
                }
            });
        });
    }
    return filterOrderItem;
};

export const getPersonalizationPrice = (state) => {
    let totalPrice = 0;
    const totalProductPrice = getOrderTotalProduct(state);
    const { recipients } = getFDOrder(state);
    recipients.forEach((recipient) => {
        const { orderItems } = recipient;
        orderItems.forEach((order) => {
            totalPrice += order.totalProduct;
        });
    });
    return parseFloat(totalProductPrice - totalPrice).toFixed(2);
};

export const getCartCount = (state) => {
    const fdOrderVO = getFDOrder(state);
    if (fdOrderVO) {
        const { recipients } = fdOrderVO;
        if (recipients?.length > 0) {
            return recipients.map((items) => (
                items.orderItems.length
            ))?.reduce((total, orderItem) => total + orderItem);
        }
    }
    return 0;
};

export const getCurrentEditingRecipient = createSelector(
    [
        getWorkingRecipient,
        getOrderItemIds,
        getOrderItemExcludePassport,
    ],
    (workingRecipientIndex, orderItemIds, orderItems) => {
        if (orderItemIds.length > 0) {
            const editingOrderItem = orderItemIds[workingRecipientIndex];
            return getOrderItemById(orderItems, editingOrderItem);
        }
        return [];
    },
);

export const getLastAddedOrderItemDetails = (state) => {
    let orderItemDetails = {};
    const orderItems = getOrderItemExcludePassport(state);
    const lastAddedOrderItem = getLoggedOrderItemDetails(state);
    if (Object.keys(lastAddedOrderItem).length > 0) {
        const lastAddedOrderItemId = Object.keys(lastAddedOrderItem)[0];
        orderItemDetails = getOrderItemById(orderItems, lastAddedOrderItemId);
        return orderItemDetails?.[0]?.orderItems?.[0] ?? {};
    }
    return orderItemDetails;
};

// Passport

export const getPaymentMethodToPurchasePassport = () => ['CreditCard', 'PayPal'];

// Get Passport Item from the cart
export const getPassportItem = (state) => {
    if (state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO) {
        const { recipients } = state.checkout.cart.orderDetails.fdOrderVO;
        return recipients.filter((items) => (
            items.orderItems.filter((orderItems) => orderItems.catalogEntryVO.primeFreeShipFlag === 'M').length !== 0
        ));
    }
    return [];
};

export const getPassportSubscriptionStatus = (state) => {
    const passportItem = getPassportItem(state);
    if (passportItem.length > 0) {
        return true;
    }
    return false;
};

// Passport Bundle
export const getPassportBundleData = (state) => state?.checkout?.payment?.passport?.passportBundle;

export const getPassportData = (state) => state?.checkout?.payment?.passport?.passportData;
export const getPassportOrderItemId = (state) => state?.checkout?.payment?.passport?.passportOrderItemId;
export const getPassportTncContentMarkUp = (state) => state?.checkout?.payment?.passport?.tncContent;
export const getPassportBannerStatus = (state) => {
    const paymentMethod = getPaymentMethod(state);
    const allowedPaymentMethods = getPaymentMethodToPurchasePassport();
    if (allowedPaymentMethods.includes(paymentMethod?.id)) {
        return true;
    }
    return false;
};
export const getStatusOfPassportApplyRmGC = (state) => state?.checkout?.payment?.passport?.showPassportConsentToRmGC;
export const getPassportProcessStatus = (state) => state?.checkout?.payment?.passport?.passportProcess;
export const getIsPassportCapPriceReached = (state) => {
    const shippingPrice = getOrderTotalShipping(state);
    const passportSubscriptionStatus = getPassportSubscriptionStatus(state);
    const brandDetails = getBrand(state) || '';
    const passportItemPrice = brandDetails['passport-item-price'] || 0;
    if ((shippingPrice > passportItemPrice) && !passportSubscriptionStatus) {
        return true;
    }
    return false;
};
export const dynamicPassportCharge = (state) => state?.checkout?.payment?.passport?.dynamicPassportCharge;
export const getPassportExpirationMessage = (state) => state?.checkout?.payment?.passport?.passportData?.passport_expiration_messaging;

export const getOrderCharges = (state) => {
    const fdOrder = getFDOrder(state);
    if (fdOrder && fdOrder?.totalShippingMap) {
        return fdOrder?.totalShippingMap;
    }
    return {
        shippingCharge: 0,
        serviceCharge: 0,
    };
};

export const getStateDeliveryFee = (state) => {
    const fdOrder = getFDOrder(state);
    const detailShippingCharges = fdOrder?.detailedShipping || {};
    const deliveryFeeKeys = [];
    const deliveryFees = {};

    let serviceSurCharges = [];
    let shippingSurCharges = [];

    if (detailShippingCharges?.serviceCharge?.surcharges?.length > 0) {
        serviceSurCharges = detailShippingCharges?.serviceCharge?.surcharges;

        // Start add the lines in a group
        serviceSurCharges.forEach((charges) => {
            const surchargeId = charges?.surchargeId || '';
            if (charges.display) {
                deliveryFeeKeys.push(surchargeId);
                deliveryFees[surchargeId] = parseFloat(charges?.amount).toFixed(2);
            }
        });
    }

    if (detailShippingCharges?.shippingCharge?.surcharges?.length > 0) {
        shippingSurCharges = detailShippingCharges?.shippingCharge?.surcharges;

        shippingSurCharges.forEach((charges) => {
            const surchargeId = charges?.surchargeId || '';
            if (charges.display) {
                if (deliveryFeeKeys.includes(surchargeId)) {
                    // value is already in the object + extra value is in shipping
                    const totalAmount = (Number(deliveryFees[surchargeId]) + Number(charges.amount)) || 0;
                    deliveryFees[surchargeId] = parseFloat(totalAmount).toFixed(2);
                } else {
                    deliveryFeeKeys.push(surchargeId);
                    deliveryFees[surchargeId] = parseFloat(charges.amount).toFixed(2);
                }
            }
        });
    }

    if (deliveryFeeKeys.length > 0) {
        return deliveryFees;
    }

    return null;
};

export const getIsPassportOnlyItemOnCart = (state) => {
    const passportItem = getPassportItem(state);
    const orderItems = getRecipientsExcludePassport(state);
    if (passportItem.length > 0 && orderItems && orderItems.length === 0) {
        return true;
    }
    return false;
};

export const getOrderProductTypes = (state) => {
    const recipients = getOrderItemExcludePassport(state);
    const isPassportOnlyItem = getIsPassportOnlyItemOnCart(state);
    let hasFPTProduct = false;
    let hasGPTProduct = false;

    if (recipients.length > 0) {
        recipients.forEach((items) => {
            items.orderItems.forEach((item) => {
                if (item.catalogEntryVO.parentProductType === 'FPT') {
                    hasFPTProduct = true;
                }
                if (item.catalogEntryVO.parentProductType === 'GPT') {
                    hasGPTProduct = true;
                }
            });
        });
    }
    if (isPassportOnlyItem) {
        hasGPTProduct = true;
    }
    return {
        hasFPTProduct,
        hasGPTProduct,
    };
};

/**
* @param {} state
*/
export const getOrderStatus = (state) => {
    if (state
        && state.checkout
        && state.checkout.order
        && state.checkout.order.orderId
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO
        && state.checkout.cart.orderDetails.fdOrderVO.status) {
        return state.checkout.cart.orderDetails.fdOrderVO.status;
    }

    return null;
};

const countNoOfOrderItems = (orderItems) => {
    let noOfItems = 0;
    Object.keys(orderItems).forEach((index) => {
        noOfItems += orderItems[index].orderItems.length;
    });
    return noOfItems;
};

export const getOrderTotalSummary = (state) => ({
    totalProduct: getOrderTotalProduct(state),
    totalShipping: getOrderTotalShipping(state),
    totalShippingAdjustments: getTotalShippingAdjustments(state),
    totalAdjustments: getTotalAdjustments(state),
    totalTax: (getOrderTotalTax(state) + getShippingTax(state)).toFixed(2),
    orderTotalBalance: getOrderTotalBalance(state),
    totalGiftCards: getGiftCardDiscounts(state),
    orderTotalBeforeTaxes: getorderTotalBeforeTaxes(state),
    TaxConfirmation: getTaxConfirmation(state),
    totalSurcharge: getOrderTotalSurcharge(state),
    deliveryExtraFee: getOrderDeliveryExtraFee(state),
});

export const getCheckoutProperties = createSelector(
    [getProperties],
    (bar) => bar,
);

export const getAppliedPromotions = (state) => ({
    appliedPromotions: getOrderPromotions(state),
});

export const getAppliedGiftCards = (state) => ({
    appliedGiftcards: getGCsAppliedToOrder(state),
});

export const getSubTotal = (state) => {
    if (state.checkout.cart.orderDetails.fdOrderVO && state.checkout.cart.orderDetails.fdOrderVO.subTotal) {
        return state.checkout.cart.orderDetails.fdOrderVO.subTotal;
    }

    return null;
};

export const getGiftCardDetails = (state) => {
    const fdOrderVO = getFDOrder(state);
    if (fdOrderVO) {
        return fdOrderVO.giftCards;
    }
    return [];
};

export const getIfGiftCardApplied = (state) => {
    const giftCard = getGiftCardDetails(state);
    if (giftCard && giftCard.length > 0) {
        return true;
    }
    return false;
};

export const getRecipients = createSelector(
    [
        getOrder,
        getOrderId,
        getCartData,
        getCartOrderDetails,
        getFDOrder,
    ],
    (order, orderId, cart, orderDetails, fdOrder) => {
        let recips = [];

        try {
            if (!order && !orderId) {
                return [];
            }
            if (cart && orderDetails && fdOrder && fdOrder.recipients) {
                recips = fdOrder.recipients;
            }
        } catch (ex) {
            console.error('ERROR: getRecipients selector FAILED', ex);
        }

        return recips;
    },
);

export const getOrderDetails = createSelector(
    [
        getCartOrderDetails,
    ],
    (orderDetails) => {
        try {
            if (orderDetails) {
                return orderDetails;
            }
        } catch (ex) {
            console.error('ERROR: getOrderDetails selector FAILED', ex);
        }

        return null;
    },
);

export const getNoOfOrderItems = (state) => {
    const orderItems = getRecipientsExcludePassport(state);
    if (orderItems && orderItems.length > 0) {
        return countNoOfOrderItems(orderItems);
    }
    return 0;
};

export const isDeliveryMessageAndDateFilledForAllItems = (state) => {
    const recipients = getOrderItemExcludePassport(state);
    let isDelMsgAndDateFilled = true;
    if (recipients && recipients.length > 0) {
        recipients.forEach((orderItem) => {
            orderItem.orderItems.forEach((item) => {
                if (!item.field2 || !item.promisedAvailableTime) {
                    isDelMsgAndDateFilled =  false;
                }
            });
        });
    } else {
        isDelMsgAndDateFilled =  false;
    }
    return isDelMsgAndDateFilled;
};

export const continueToPayment = createSelector(
    [
        isDeliveryAddressFilledForAllItems,
        isDeliveryMessageAndDateFilledForAllItems,
        getIsPassportOnlyItemOnCart,
    ],
    (orderItemAddressFilled, orderItemMsgAndDateFilled, passportOnlyItemOnCart) => {
        if (passportOnlyItemOnCart) {
            return true;
        }
        if (orderItemAddressFilled && orderItemMsgAndDateFilled) {
            return true;
        }
        return false;
    },
);

/**
 * @param {} state
 */
// Brand Customer Care Contact
export const getCustomerCare = (state) => {
    const brandDetails = getBrand(state);
    if (brandDetails && brandDetails.phoneNumber) {
        return brandDetails.phoneNumber;
    }
    return '';
};

/**
 * @param {} state
 */
// Get Passport Feature Flag
export const getFlagEnablePassport = (state) => {
    const flags = getFlags(state);
    if (flags && Object.prototype.hasOwnProperty.call(flags, 'is-product-passport-banner-enabled')) {
        return flags['is-product-passport-banner-enabled'];
    }
    return false;
};

export const getFlagEnableCheckoutMonitoring = (flags) => {
    if (flags && Object.prototype.hasOwnProperty.call(flags, 'is-checkout-monitoring-enabled')) {
        return flags['is-checkout-monitoring-enabled'];
    }

    // default to monitoring
    return true;
};

/**
 * @param {} state
 */
// Get Espot
export const getEspotById = (state, id) => {
    if (state && state.entities && state.entities.espots) {
        let espot = state.entities.espots.byId[id];
        if (!espot) {
            const arrEspotName = Object.keys(state.entities.espots.byId).filter(((espotId) => (espotId.indexOf(id) >= 0)));
            const [espotName] = arrEspotName;
            espot = state.entities.espots.byId[espotName] || null;
        }
        return espot;
    }
    return null;
};

export const getCdnImagePath = (state) => {
    const brandDetails = getBrand(state) || '';
    return `https://cdn1.${brandDetails['domain-name']}.com/wcsstore/${brandDetails.identifier}/images`;
};

export const getProductsById = function getProductsById(state) {
    if (state && state.catalog && state.catalog.products && state.catalog.products.byId) {
        return state.catalog.products.byId;
    }
    return null;
};

export const getDonationItem = (state) => {
    if (state
        && state.cart
        && state.cart.orderDetails
        && state.cart.orderDetails.fdOrderVO) {
        const { recipients } = state.cart.orderDetails.fdOrderVO;
        return recipients.filter((items) => (
            items.orderItems.filter((orderItems) => orderItems.lineItemType === 'DONATION').length !== 0
        ));
    }
    return [];
};

export const getIsDonationOnlyItemOnCart = (state) => {
    const donationItem = getDonationItem(state);
    const orderItems = getRecipientsExcludePassport(state);
    if (donationItem.length > 0 && orderItems && orderItems.length === 0) {
        return true;
    }
    return false;
};

export const getSelectedProductSku = function getSelectedProductSku(state) {
    if (state && state.ui && state.ui.pdpCart && state.ui.pdpCart.selectedSKUProduct) {
        return state.ui.pdpCart.selectedSKUProduct;
    }
    return null;
};

export const getShopRunnerEligibleItemInCart = (state) => {
    let shopRunnerEligbleItem = [];
    if (state.checkout
        && state.checkout.cart
        && state.checkout.cart.orderDetails
        && state.checkout.cart.orderDetails.fdOrderVO) {
        const { recipients } = state.checkout.cart.orderDetails.fdOrderVO;
        shopRunnerEligbleItem = recipients.filter((items) => (
            items.orderItems.filter((orderItems) => orderItems.catalogEntryVO.primeFreeShipFlag === 'Y' || orderItems.catalogEntryVO.primeFreeShipFlag === 'S').length !== 0
        ));
    }
    if (shopRunnerEligbleItem.length > 0) {
        return true;
    }
    return false;
};

// DTW - selectors
export const getDTWOptions = (state) => {
    if (state.checkout && state.checkout.common && state.checkout.common.dtw) {
        return state.checkout.common.dtw;
    }

    return null;
};

export const isCheckoutPage = () => {
    if (typeof window !== 'undefined') {
        if (window.location.pathname.indexOf('/checkout') >= 0 || window.location.pathname.indexOf('/add-ons') >= 0) {
            return true;
        }
    }
    return false;
};

export const getLogo = createSelector(
    [
        getBrand,
    ],
    (currentBrand) => {
        if (currentBrand && currentBrand.code) {
            const { logos } = brandThemes[brandThemes[currentBrand.code] ? currentBrand.code : '18F'];
            return logos?.primary_logo?.url;
        }
        return null;
    },
);

// Which Checkout Variation to use (Food or Floral)
export const getCheckoutVariation = () => ('floral');

export const getGiftMessageConfiguration = createSelector(
    [
        getBrand,
    ],
    (currentBrand) => {
        if (currentBrand && currentBrand.code) {
            const { giftMessageConfig } = brandThemes[brandThemes[currentBrand.code] ? currentBrand.code : '18F'];
            return giftMessageConfig;
        }

        return {};
    },
);

// Checkout Food Supported Payment Method
export const getSupportedPaymentMethods = createSelector(
    [getFlags, getApplePayStatus],
    (flags, applePayStatus) => {
        const paymentMethods = ['CreditCard'];

        // Paypal Enabled
        if (flags['is-paypal-braintree-enabled']) {
            paymentMethods.push('PayPal');
        }

        // Chase Pay Enabled
        if (flags['is-chasepay-enabled']) {
            paymentMethods.push('ChasePay');
        }

        // Apple Pay Enabled
        if (flags['is-apple-pay-enabled-checkout'] && applePayStatus === 1) {
            paymentMethods.push('ApplePay');
        }

        return paymentMethods;
    },
);
export const getGreetingCards = (state) => {
    const { fdOrderVO } = getOrderDetails(state) || {};
    const greetingCards = [];
    if (fdOrderVO?.recipients?.length) {
        fdOrderVO.recipients.forEach((recipient) => {
            if (recipient?.orderItems) {
                recipient.orderItems.forEach((orderItem) => {
                    if (orderItem?.greetingCards?.length) {
                        orderItem.greetingCards.forEach((card) => {
                            greetingCards.push({
                                keyId: card?.xorderAttrValues?.CIkey_Id,
                                ordersId: orderItem.ordersId,
                                orderItemsId: orderItem.orderItemsId,
                                skuCode: card?.catalogEntryVO?.skuCode,
                            });
                        });
                    }
                });
            }
        });
    }
    return greetingCards;
};
