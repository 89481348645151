/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const MBPCHECKOUT_ADD_TO_CART = 'mbp-checkout/ADD/TO/CART';
const MBPCHECKOUT_ADD_TO_CART_SUCCESS = 'mbp-checkout/ADD/TO/CART/SUCCESS';
const MBPCHECKOUT_ADD_TO_CART_FAILED = 'mbp-checkout/ADD/TO/CART/FAILED';
const APPLY_PROMOTION_ACTION = 'mbp-checkout/APPLY/PROMOTION';
const REMOVE_PROMOTION_ACTION = 'mbp-checkout/REMOVE/PROMOTION';
const CALCULATE_SHIPPING_ACTION = 'mbp-checkout/CALCULATE/SHIPPING';
const CALCULATE_SHIPPING_COMPLETE = 'mbp-checkout/CALCULATE/SHIPPING/COMPLETE';
const PERSIST_ATTRIBUTION_ACTION = 'mbp-checkout/PERSIST/ATTRIBUTION';
const SET_SUBMIT_PAYMENT_BUTTONT_TYPE = 'SET_SUBMIT_PAYMENT_BUTTONT_TYPE';
const SAVE_BANNER_PROCESS_CHECK_COMPLETED = 'mbp-checkout/SAVE_BANNER_PROCESS_CHECK_COMPLETED';
const SAVE_BANNER_PROCESS_CHECK_FAILED = 'mbp-checkout/SAVE_BANNER_PROCESS_CHECK_FAILED';
const APPLY_PROMOTION_COMPLETED = 'mbp-checkout/APPLY_PROMOTION_COMPLETED';
const REMOVE_PROMOTION_COMPLETED = 'mbp-checkout/REMOVE_PROMOTION_COMPLETED';
const LOAD_ORDER_DATA = 'mbp-checkout/LOAD/CART';
const CHECK_APPLE_PAY_ENABLED = 'mbp-checkout/CHECK/APPLE/PAY/ENABLED';
const APP_PROCESS_PAGE = 'mbp-checkout/APP_PROCESS_PAGE';
const ADD_ADDONS_TO_CART = 'mbp-checkout/ADD/ADDONS/TO/CART';
const ADD_WRAPUPS_TO_CART = 'mbp-checkout/ADD/WRAPUPS/TO/CART';
const ADD_ADDONS_AND_WRAPUP_TO_CART = 'mbp-checkout/ADD/ADDONS/AND/WRAPUP/TO/CART';

const MBPCHECKOUT_ADD_TO_CART_FOOD = 'mbp-checkout/ADD/TO/CART/FOOD';
const VALIDATESHOPCART = 'mbp-checkout/PROCESS/EXPRESS/CHECKOUT/VALIDATESHOPCART';

export default {
    MBPCHECKOUT_ADD_TO_CART,
    MBPCHECKOUT_ADD_TO_CART_SUCCESS,
    MBPCHECKOUT_ADD_TO_CART_FAILED,
    APPLY_PROMOTION_ACTION,
    REMOVE_PROMOTION_ACTION,
    CALCULATE_SHIPPING_ACTION,
    CALCULATE_SHIPPING_COMPLETE,
    LOAD_ORDER_DATA,
    PERSIST_ATTRIBUTION_ACTION,
    SET_SUBMIT_PAYMENT_BUTTONT_TYPE,
    SAVE_BANNER_PROCESS_CHECK_COMPLETED,
    SAVE_BANNER_PROCESS_CHECK_FAILED,
    APPLY_PROMOTION_COMPLETED,
    REMOVE_PROMOTION_COMPLETED,
    CHECK_APPLE_PAY_ENABLED,
    APP_PROCESS_PAGE,
    ADD_ADDONS_TO_CART,
    ADD_WRAPUPS_TO_CART,
    ADD_ADDONS_AND_WRAPUP_TO_CART,

    // Food
    MBPCHECKOUT_ADD_TO_CART_FOOD,
    VALIDATESHOPCART,
};
