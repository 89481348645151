/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/cart';
const RESOURCE_ROOT_FACADE = '/checkout';
/**
 * Get order details via the facade layer by user - user determined by token
 * https://localhost:8080/checkout/cart?brand=flowers
 *
 * @param {*} wcEnv
 * @param {*} orderId
 */
const getOrderDetailsForUser = (wcEnv, jwtToken) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = RESOURCE_PAGE_FACADE;

    return restClient.getFacade(env, resourcePage, jwtToken)
        .then((response) => response);
};

export default getOrderDetailsForUser;
