/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable function-paren-newline */
/* eslint-disable dot-notation */

import 'cross-fetch/polyfill';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {
    object, func, bool, string, node, shape,
} from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import mbpLogger from 'mbp-logger';
import loadable from '@loadable/component';

// Brand Themes
// eslint-disable-next-line import/no-unresolved
import brandThemes from './brandtheme';

// components
import RenderWhenVisible from '../../helpers/componentVisibility/RenderWhenVisible';
import GraphqlPassportContainer from '../GraphqlComponents/GraphqlPassportContainer/GraphqlPassportContainer';
import TrustArc from '../vendors/TrustArc/TrustArc';

// helpers
import { GRAPHQL_ENV } from '../../gql';
import { setLocale, getLanguageName } from '../../../state/ducks/Member/ducks/Auth/helper/helper';
import {
    setCountryData, loadAppShell, setDataDictionary,
} from '../../../state/ducks/App/App-Actions';

// redux
import { getAppShellLoadedState, getPageType } from '../../../state/ducks/App/App-Selectors';

const LoadableGraphqlFooterFood = loadable(() => import(/* webpackChunkName: "GraphqlFooterFood" */ '../GraphqlComponents/GraphqlFooterFood/GraphqlFooterFood'));
const LoadableGraphqlFooter = loadable(() => import(/* webpackChunkName: "GraphqlFooter" */ '../GraphqlComponents/GraphqlFooter/GraphqlFooter'));

const LoadableHeaderBuilder = loadable(() => import(/* webpackChunkName: "GraphqlHeader" */ '../GraphqlComponents/GraphqlHeader/HeaderBuilder'));
// third parties
const LoadableChromeSignin = loadable(() => import(/* webpackChunkName: "ChromeSignin" */ '../GraphqlComponents/ChromeSignin/ChromeSignin'));
const MultiBrandChatButton = loadable(() => import(/* webpackChunkName: "MultiBrandChatButton" */ '../MultiBrandChatController/MultiBrandChatButton'));

const styles = () => ({
    customerService: {
        minHeight: '400px !important',
    },
});
class AppShell extends React.PureComponent {
    componentDidMount() {
        const {
            location, loadShell, appShellLoaded,
        } = this.props;
        if (typeof window !== 'undefined' && !appShellLoaded) {
            loadShell(window.location.hostname, location);
        }

        // Clean up SSR JSS
        const jssStyles = document.getElementById('jss-server-side');
        if (jssStyles && jssStyles.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { featureFlags, pageType } = this.props;
        if (featureFlags['is-verbose-debugging-enabled']) {
            if (this.props) {
                Object.entries(this.props).forEach(
                    ([key, val]) => prevProps[key] !== val
                        && mbpLogger.logDebug({
                            val,
                            function: 'componentDidUpdate',
                            module: 'AppShell',
                            message: `Prop '${key}' changed`,
                        }),
                );
            }

            if (this.state) {
                Object.entries(this.state).forEach(
                    ([key, val]) => prevState[key] !== val
                        && mbpLogger.logDebug({
                            val,
                            function: 'componentDidUpdate',
                            module: 'AppShell',
                            message: `State '${key}' changed`,
                        }),
                );
            }
        }

        if (pageType?.type === 'product') {
            if (typeof document !== 'undefined') {
                document.getElementById('root').style.position = 'static';
            }
        }
    }

    render() {
        const {
            Helmet,
            brand,
            appShellLoaded,
            children,
            featureFlags,
            location,
            bannerCode,
            passportData,
            // SSR
            // isServer,
            // isShell,
            ssrDeviceType,
            setCountryDataToStore,
            setDataDictionaryToStore,
            classes,
        } = this.props;
        if (brand && brand['domain-name'] && appShellLoaded) {
            const THEME_QUERY = gql`
                query brand_configuration {
                    findContent(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", contentType:"brand_configuration")
                    {
                        content
                    }
                }
            `;
            const COUNTRY_DATA_QUERY = gql`
                query find_content {
                    findContent(brand:"global", environment: "${GRAPHQL_ENV}",locale: "${setLocale()}" contentType: "country_finder")
                    {
                      content
                    }
                }
            `;
            const DATA_DICTIONARY_QUERY = gql`
            query find_content {
                findContent(brand:"${brand['domain-name']}", environment: "${GRAPHQL_ENV}", locale: "${setLocale()}", contentType:"data_dictionary")
                {
                    content
                }
            }
            `;
            /**
             *
             * addCMSTheme - add Contentstack theme values to brandTheme
             *
             * By convention, all CMS values are snake_case, rather than standard js camelCase.
             * It was decided to preserve the snake_case when transferring values to brandTheme,
             * so that brandTheme attributes would always exactly match CMS names.
             * It also has the effect of signaling an attribute came from CMS rather than a hardcoded/legacy value.
             *
             * @param {object} brandTheme brand's theme object from brandsConfig[ brandcode ], e.g. brandsConfig.HD
             * @param {object} cmsData    brand theme values from contentstack (object in first entry of content.entries of brand_theme_full)
             */
            const addCMSTheme = (brandTheme, cmsData) => {
                // font-family to use for this brand's site
                // e.g. "font-family: lato, Arial, Verdana, sans-serif;"
                if (cmsData.global_font_family) {
                    brandTheme.global_font_family = cmsData.global_font_family;      // eslint-disable-line no-param-reassign
                } else {
                    mbpLogger.logError({
                        query: THEME_QUERY,
                        component: 'AppShell.js',
                        message: 'Brand has no global_font_family',
                        env: GRAPHQL_ENV,
                    });
                }

                brandTheme.palette.cms = brandTheme.palette.cms ? brandTheme.palette.cms : {};          // eslint-disable-line no-param-reassign

                // Color palette from CMS.
                // e.g.
                //     brandTheme.palette.cms.primary = "a1001a"
                //     brandTheme.palette.cms['Body Font'] = "#2f2f2f"
                ['colors', 'cta_colors', 'layout_colors', 'additional_colors', 'additional_palette_colors'].forEach((sectionName) => {
                    if (cmsData[sectionName]) {
                        const section = cmsData[sectionName];
                        if (Array.isArray(section)) {
                            /*
                                Entries expected in this form:
                                    {
                                        "title": "Body Font",
                                        "color": {
                                            "color": "#2f2f2f",
                                            "description": "NIGHT RIDER",
                                            "transparency": "",
                                            "version": "1"
                                        }
                                    },
                            */
                            section.forEach((entry) => {
                                brandTheme.palette.cms[entry.title] = entry.color.color;      // eslint-disable-line no-param-reassign
                            });
                        } else {
                            /*
                                Entries expected in this form:
                                    "primary_hover": {
                                        "color": "#790000",
                                        "description": "MAROON",
                                        "transparency": "",
                                        "version": "1"
                                    },
                           */
                            Object.keys(section).forEach((entryKey) => {
                                brandTheme.palette.cms[entryKey] = section[entryKey].color;      // eslint-disable-line no-param-reassign
                            });
                        }
                    }
                });

                // e.g. brandTheme.logos.primary_logo = {
                //     "content_type": "image/svg+xml",
                //     "filename": "hd-logo-black.svg",
                //     "url": "https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/bltff290eac2ecfd735/5e20dfa82d54964f9f397316/hd-logo-black.svg",
                //     "title": "hd-logo-black.svg",
                //     "description": "Harry and David Logo"
                // }
                if (cmsData.logos) {
                    brandTheme.logos = {};      // eslint-disable-line no-param-reassign
                    Object.keys(cmsData.logos).forEach((logoKey) => {
                        if (cmsData.logos[logoKey]) {
                            const cmsLogo = cmsData.logos[logoKey];
                            brandTheme.logos[logoKey] = {      // eslint-disable-line no-param-reassign
                                contentType: cmsLogo.content_type || null,
                                filename: cmsLogo.filename || null,
                                description: cmsLogo.description || null,
                                title: cmsLogo.title || null,
                                url: cmsLogo.url || null,
                            };
                        }
                    });
                }

                // Array of fonts, as defined by business in Contentstack (Brand Configuration > Typography Stack),
                // in order of primacy e.g. primary font is:
                //    brandTheme.cms.typography[0] = {
                //     "font_name": "Lato - sans",
                //     "font_style": "Main copy, sans serif",
                //     "font_stack": "font-family: 'latoregular', Arial, Verdana, sans-serif;",
                //     "font_file_primary": "DancingScript-Regular.woff2",
                //     "font_file_fallback": "DancingScript-Regular.woff"
                // }
                // Generates <style> object to inject into #root
                if (cmsData?.typography?.length) {
                    brandTheme.typography.cms = []; // eslint-disable-line no-param-reassign
                    cmsData.typography.forEach((font) => {
                        if (font.font_reference) {
                            brandTheme.typography.cms.push({ ...font.font_reference });
                        } else {
                            brandTheme.typography.cms.push({ ...font });
                        }
                    });

                    const stackArr = [];
                    // set global font stack and fontsize for EM reference
                    stackArr.push(`body {${cmsData.global_font_family} font-size: ${cmsData.baseline_font_size}px}`);
                    if (brandTheme.typography.cms.length) {
                        brandTheme.typography.cms.forEach((k) => {
                            if (k?.webfont?.length) {
                                const webFonts = k.webfont[1]?.url ? `\nsrc: url(${k.webfont[0].url}), url(${k.webfont[1]?.url ? k.webfont[1].url : k.webfont[0].url})` : '';
                                const str = `@font-face {font-family: ${k.font_name}; ${webFonts}; font-display: swap;}`;
                                stackArr.push(str);
                                return str;
                            }
                            return null;
                        });
                    }
                    // eslint-disable-next-line no-param-reassign
                    brandTheme.typography.styleblock = <style data-type="font-stack">{stackArr.join('\n')}</style>;
                }

                // Other key-pair values from Contentstack,
                // such as cta_icon = ">" or ">>", presentation_family = 'food' or 'flower'
                // e.g. if (brandTheme.presentation_family === 'food') {
                if (cmsData.other) {
                    Object.assign(brandTheme, cmsData.other);
                }
            };

            const AppShellPassportData = () => {
                if (
                    featureFlags
                    && featureFlags['is-passport-enabled']
                    && passportData
                    && !passportData?.item_id
                ) {
                    return <GraphqlPassportContainer />;
                }
                return null;
            };

            const renderMultiBrandChat = () => {
                if (
                    featureFlags
                    && featureFlags['is-genesys-chat-enabled']
                    && ssrDeviceType !== 'mobile'
                    && ssrDeviceType !== 'tablet'
                    && typeof window !== 'undefined'
                ) {
                    return appShellLoaded && <MultiBrandChatButton />;
                }
                return null;
            };

            const renderChromeDropDown = () => {
                if (featureFlags['is-chrome-signin-enabled']) {
                    return <LoadableChromeSignin />;
                }
                return null;
            };

            const renderNoIndexNoFollow = () => {
                if (featureFlags['is-seo-noindex-enabled'] && featureFlags['is-seo-nofollow-enabled']) {
                    return <Helmet><meta name="ROBOTS" content="NOINDEX,NOFOLLOW" /></Helmet>;
                }
                return null;
            };

            // TODO
            // - implement a better way to get brand instead of props drilling -> Redux store -> Connect to parent components with queries, hand to children as props?
            return (
                <>
                    <Query query={THEME_QUERY}>
                        {({ loading, error, data }) => {
                            if (loading) {
                                return null;
                            }

                            if (error) {
                                mbpLogger.logError({
                                    query: THEME_QUERY,
                                    component: 'AppShell.js',
                                    message: 'Error loading theme from Graphql',
                                    env: GRAPHQL_ENV,
                                    error,
                                });
                                // DO NOT ABORT -- continue onward if THEME not in contentstack
                            }

                            // brandThemes[brand.code] may not yet exist for brands under development.
                            // Must contain valid brand configuration data for production brands.

                            if (brandThemes[brand.code]) {
                                if (!data || !data.findContent || !data.findContent.content || !data.findContent.content.entries
                                    || !Array.isArray(data.findContent.content.entries)
                                    || !data.findContent.content.entries[0]) {
                                    mbpLogger.logWarning({
                                        query: THEME_QUERY,
                                        component: 'AppShell.js',
                                        message: 'No theme data returned for query',
                                        env: GRAPHQL_ENV,
                                    });
                                } else {
                                    addCMSTheme(brandThemes[brand.code], data.findContent.content.entries[0]);
                                }
                            }

                            const {
                                palette,
                                typography,
                                spacing,
                                breakpoints,
                                overrides,
                                presentation_family,
                            } = brandThemes[brandThemes[brand.code] ? brand.code : '18F'];  // if brand not set up yet, use 18F settings

                            // To get font family from cms
                            typography.getFontFamily = (fontName) => {
                                const filteredFont = (typography?.cms || []).filter((font) => font.font_name === fontName);
                                if (filteredFont.length) {
                                    return filteredFont[0].font_style;
                                }
                                return null;
                            };
                            const theme = createTheme({
                                palette,
                                typography,
                                spacing,
                                breakpoints,
                                overrides,
                            });
                            const innerHeightScreen = 'calc(110vh - 236px)';
                            const csh = location.pathname?.indexOf('customer-service') > -1 && classes.customerService;
                            return (
                                <ThemeProvider theme={theme}>
                                    <>
                                        <CssBaseline />
                                        {typography.styleblock}
                                        {renderNoIndexNoFollow()}
                                        <AppShellPassportData />
                                        {renderChromeDropDown()}
                                        {renderMultiBrandChat()}
                                        {/* TODO: Add HydrateLater component for HeaderBuilder */}
                                        {<LoadableHeaderBuilder
                                            brand={brand}
                                            deviceType={ssrDeviceType}
                                            presentationFamily={presentation_family}
                                            bannerCode={bannerCode}
                                        />}
                                        <div style={{ minHeight: `${innerHeightScreen}` }} className={csh}>
                                            {children}
                                        </div>
                                        { featureFlags['is-trustarc-enabled'] && <TrustArc /> }
                                        <RenderWhenVisible>
                                            {presentation_family === 'food' || featureFlags['is-footer-v2-enabled']
                                            // TODO remove presentation family food once flag is enabled on all food brands
                                                ? <LoadableGraphqlFooterFood brand={brand} ssrDeviceType={ssrDeviceType} featureFlags={featureFlags} />
                                                : <LoadableGraphqlFooter brand={brand} ssrDeviceType={ssrDeviceType} featureFlags={featureFlags} />}
                                        </RenderWhenVisible>
                                    </>
                                </ThemeProvider>
                            );
                        }}
                    </Query>
                    <Query query={COUNTRY_DATA_QUERY}>
                        {
                            ({ loading, error, data }) => {
                                if (loading) {
                                    return null;
                                }
                                if (error) {
                                    mbpLogger.logError({
                                        query: COUNTRY_DATA_QUERY,
                                        component: 'AppShell.js',
                                        message: 'Error loading country data from Graphql',
                                        env: GRAPHQL_ENV,
                                        error,
                                    });
                                }
                                if (data) {
                                    const countryData = data.findContent?.content?.entries?.[0].country_group;
                                    setCountryDataToStore(countryData);
                                    return true;
                                }
                                return null;
                            }
                        }
                    </Query>
                    { getLanguageName() !== '/en'
                        ? (
                            <Query query={DATA_DICTIONARY_QUERY}>
                                {
                                    ({ loading, error, data }) => {
                                        if (loading) return null;
                                        if (error) {
                                            mbpLogger.logError({
                                                query: DATA_DICTIONARY_QUERY,
                                                component: 'AppShell.js',
                                                message: 'Error loading data dictionary from Graphql',
                                                env: GRAPHQL_ENV,
                                                error,
                                            });
                                        }
                                        if (data) {
                                            const dataDictionary1 = data?.findContent?.content?.entries?.[0]?.data_dictionary;
                                            const dataDictionary2 = data?.findContent?.content?.entries?.[0]?.data_dictionary_2;
                                            const dataDictionary3 = data?.findContent?.content?.entries?.[0]?.data_dictionary_3;
                                            const dataDictionary4 = data?.findContent?.content?.entries?.[0]?.data_dictionary_4;
                                            const dataDictionary = {
                                                ...dataDictionary1, ...dataDictionary2, ...dataDictionary3, ...dataDictionary4,
                                            };
                                            setDataDictionaryToStore(dataDictionary);
                                            return true;
                                        }
                                        return null;
                                    }
                                }
                            </Query>
                        ) : null }
                </>
            );
        }

        return null;
    }
}

AppShell.propTypes = {
    Helmet: func.isRequired,
    children: node.isRequired,
    brand: object,
    location: object.isRequired,
    loadShell: func.isRequired,
    appShellLoaded: bool.isRequired,
    ssrDeviceType: string,
    // isServer: bool,
    // isShell: bool,
    bannerCode: string,
    featureFlags: object,
    passportData: shape({
        item_id: string,
    }),
    setCountryDataToStore: func.isRequired,
    setDataDictionaryToStore: func.isRequired,
    classes: object,
    pageType: object.isRequired,
};

AppShell.defaultProps = {
    brand: {},
    ssrDeviceType: '',
    featureFlags: {},
    passportData: {},
    bannerCode: null,
    classes: {},
};

const mapDispatchToProps = (dispatch) => ({
    loadShell: bindActionCreators(loadAppShell, dispatch),
    setCountryDataToStore: bindActionCreators(setCountryData, dispatch),
    setDataDictionaryToStore: bindActionCreators(setDataDictionary, dispatch),
});

const mapStateToProps = (state) => ({   // eslint-disable-line arrow-parens
    appShellLoaded: getAppShellLoadedState(state),
    passportData: state?.checkout?.payment?.passport?.passportData,
    pageType: getPageType(state),
});

export default (withStyles(styles)(withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(AppShell),
)));
