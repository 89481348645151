/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import parser from 'ua-parser-js';
import mbpLogger from 'mbp-logger';

/*
    @intention discoverDeviceType - determines device based on parsed UA, should add device detection logic to here
    @intention isDesktopMobileTablet - reusable helper that handles deployed and local environments, takes a parsed or unparsed UA

    IMPORTANT NOTES:
        ~ Mobile
        ~ Desktop
        ~ Tablets
            ** tablet is not a accepted viewport for GQL queries currently. Its behavior is overriden at the query level Graphql Homepage and Header as examples
            - sometimes come through without device.type, in this case it is undefined and they fall through into the browser logic which returns dekstop e.g. FireFox on Android
            - for Ipads using Safari, the device.type is tablet but the browser.name contains mobile, so the device logic has to be above browser
*/

/**
 *
 * @param {object} parsedUserAgent // userAgent object after ua-parser
 */
const discoverDeviceType = (parsedUserAgent) => {
    const { device = '', browser = '' } = parsedUserAgent; // more are available from parsedUA (cpu, os, etc)
    // mobile checks
    if (device && device.type === 'mobile') {
        return 'mobile';
    }
    // tablet checks
    if (device && device.type === 'tablet') {
        return 'tablet';
    }
    // desktop checks
    // can add array of browser strings to check against (forcing IE to always desktop)
    if (browser && !browser?.name?.toLowerCase()?.includes('mobile')) { // when mocking emulator mobile from desktop, UA still has browser info, need to exclude mobile
        return 'desktop';
    }
    // mobile first (this can be changed to default and pass the determination job to <DesktopMobile />) -> queries using viewport will still need to default to 'desktop' or 'mobile' in this case
    return 'mobile';
};

/**
 *
 * @param {object || string} ssrUserAgent // parsed userAgent from initial request or unparsed userAgent
 */
const isDesktopMobileTablet = (ssrUserAgent) => {
    if (typeof ssrUserAgent === 'object') {
        return discoverDeviceType(ssrUserAgent);
    }

    // un parsed UA
    if (typeof ssrUserAgent === 'string')  {
        const parsedUA = parser(ssrUserAgent);
        return discoverDeviceType(parsedUA);
    }

    // localhost:3000 will not have a UA from SSR
    if (!ssrUserAgent) {
        if (typeof navigator === 'undefined' || !navigator || !navigator.userAgent) return 'mobile';

        const localDeviceType = parser(navigator.userAgent);
        return discoverDeviceType(localDeviceType);
    }

    return mbpLogger.logError({
        component: 'isDesktopMobileTablet.js',
        message: `Unhandled input given to isDesktopMobileTablet ${ssrUserAgent}`,
    });
};

export default isDesktopMobileTablet;
