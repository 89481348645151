/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import * as cart from './ducks/Cart';
import * as checkout from './ducks/Checkout';
import * as common from './ducks/Common';
import * as order from './ducks/Order';
import * as payment from './ducks/Payment';
import * as recipient from './ducks/Recipient';

import * as selectors from './Checkout-Selectors';
import reducers from './Checkout-Reducers';
import watchers from './Checkout-Operations';

const ducks = {
    cart,
    checkout,
    common,
    order,
    payment,
    recipient,
};

export {
    ducks,
    selectors,
    watchers,
};

export default reducers;
