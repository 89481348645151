/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const GET_DTW = 'mbp-checkout/GET/DTW';
export const UPDATE_DTW_OPTIONS = 'mbp-checkout/UPDATE_DTW_OPTIONS';
export const SELECT_DTW = 'mbp-checkout/SELECT_DTW';
export const UPDATE_SELECTED_DTW = 'mbp-checkout/UPDATE_SELECTED_DTW';
export const SUBMIT_DTW = 'mbp-checkout/SUBMIT_DTW';
