/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const LOAD_CART = 'mbp-checkout/CART/LOAD';
const GET_CART = 'mbp-checkout/GET/CART';
const LOAD_DUPLICATE_ITEM = 'mbp-checkout/CART/LOAD/DUPLICATE_ITEM';
const GET_ITEM = 'mbp-checkout/CART/GET_ITEM';
const UPDATE_QTY = 'mbp-checkout/CART/UPDATE_QTY';
const RECEIVE_ITEM = 'mbp-checkout/CART/RECEIVE_ITEM';
const CART_LOADED = 'mbp-checkout/CART/LOADED';
const RECALCULATE_GIFT_CARD_TOTAL = 'mbp-checkout/RECALCULATE_GIFT_CARD_TOTAL';
const CALL_HANDLE_CART_FAILURE = 'mbp-checkout/CALL_HANDLE_CART_FAILURE';

export default {
    LOAD_CART,
    GET_CART,
    LOAD_DUPLICATE_ITEM,
    GET_ITEM,
    UPDATE_QTY,
    RECEIVE_ITEM,
    CART_LOADED,
    RECALCULATE_GIFT_CARD_TOTAL,
    CALL_HANDLE_CART_FAILURE,
};
