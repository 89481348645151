/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const TRIGGER_PASSPORT_SUBSCRIPTION = 'mbp-checkout/TRIGGER_PASSPORT_SUBSCRIPTION';
export const SUBSCRIBE_PASSPORT_DATA = 'mbp-checkout/SUBSCRIBE_PASSPORT_DATA';
export const SUBSCRIBE_PASSPORT = 'mbp-checkout/SUBSCRIBE_PASSPORT';
export const UNSUBSCRIBE_PASSPORT = 'mbp-checkout/UNSUBSCRIBE_PASSPORT';
export const GET_PASSPORT_TNC_CONTENT = 'mbp-checkout/GET_PASSPORT_TNC_CONTENT';
export const LOAD_PASSPORT_TNC_CONTENT = 'mbp-checkout/LOAD_PASSPORT_TNC_CONTENT';
export const TRIGGER_ADD_PASSPORT_BY_REMOVE_GIFTCARD = 'mbp-checkout/TRIGGER_ADD_PASSPORT_BY_REMOVE_GIFTCARD';
export const SHOW_PASSPORT_MODAL_TO_REMOVE_GIFTCARD = 'mbp-checkout/SHOW_PASSPORT_MODAL_TO_REMOVE_GIFTCARD';
export const HIDE_PASSPORT_MODAL_TO_REMOVE_GIFTCARD = 'mbp-checkout/HIDE_PASSPORT_MODAL_TO_REMOVE_GIFTCARD';
export const PASSPORT_PROCESS_START = 'mbp-checkout/PASSPORT_PROCESS_START';
export const PASSPORT_PROCESS_END = 'mbp-checkout/PASSPORT_PROCESS_END';
export const GET_DYNAMIC_PASSPORT_CHARGE = 'mbp-checkout/GET_DYNAMIC_PASSPORT_CHARGE';
export const LOAD_PASSPORT_DYNAMIC_CHARGE = 'mbp-checkout/LOAD_PASSPORT_DYNAMIC_CHARGE';
export const PASSPORT_BUNDLE_DATA = 'mbp-checkout/PASSPORT_BUNDLE_DATA';
