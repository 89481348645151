/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, takeEvery, call, put,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

import {
    subscriptionOptIn,
    setSubscriptionMessage,
} from './Subscription-Action';
import memberDucks from '../../../../../Member/ducks';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const createSubscriptionOptIn = ({
    JWT_TOKEN,
    optInEntryObj,
}) => orderClient.createOptInEntry({}, JWT_TOKEN, optInEntryObj);

function* submitSubscriptionOptIn(action) {
    try {
        const JWT_TOKEN = yield call(checkJWT);
        const { data } = action;
        const subscriptionOptInResult = yield call(createSubscriptionOptIn, {
            JWT_TOKEN,
            optInEntryObj: data,
        });
        yield put(setSubscriptionMessage(subscriptionOptInResult.data));
    } catch (ex) {
        mbpLogger.logError({
            function: 'addSubscribeOptIn',
            module: 'mbp-pwa-ui',
            message: 'addSubscribeOptIn - Error',
            jsError: ex,
        });
        console.log(ex);
    }
}

function* watchSubcriptionOptIn() {
    yield takeEvery(subscriptionOptIn().type, submitSubscriptionOptIn);
}

const watchers = [
    fork(watchSubcriptionOptIn),
];

export {
    watchers,
    subscriptionOptIn,
};
export default {};
