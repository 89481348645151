/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { decodeHTML } from 'entities';
// import qs from 'qs';

import mbpLogger from 'mbp-logger';

import { getDataLayerActionEntry } from './pageDataLayerCommonHelpers';
import { generateCheckoutPageDataLayer } from './pageDataLayerCheckoutHelpers';
import { generateMyAccountDataLayer } from './pageDataLayerMyAccountHelpers';

// const generatetePageViewEvents = ({
//     productImpArrays,
// }) => {
//     const events = [];
//     const eventParams = {
//         eventCategory: 'Site Wide',
//         eventAction: 'Large Request',
//         nonInteraction: '1',
//     };

//     // Provide array of events
//     events.push({
//         ...eventParams,
//         ...productImpArrays,
//     });

//     return events;
// };

// Build data layer for Product page
const generateProductPageDataLayer = (page, pageDataLayer) => {
    const { location, dataLayer } = page;
    const { product } = getDataLayerActionEntry('track_product_data', dataLayer) || {};
    const { passportData } = getDataLayerActionEntry('track_passport_data', dataLayer) || {};
    const baseDataLayer = { ...pageDataLayer };

    if (!product) {
        mbpLogger.logError({
            page,
            product,
            dataLayer,
            function: 'generateProductPageDataLayer',
            module: 'mbp-page',
            message: 'Missing required params [product]',
        });

        return false;
    }

    try {
        baseDataLayer.pageType = 'product';
        baseDataLayer.pageName = 'Product';

        if (product) {
            baseDataLayer.name = decodeHTML(product.name) || '';
            baseDataLayer.id = product.partNumber || '';
            baseDataLayer.canonicalUrl = page.path || '';
            baseDataLayer.products = [product];
            baseDataLayer.passportItem = passportData;
        }

        if (location.state) {
            const {
                categoryId, categoryName, categoryPath, categoryLegacyId,
            } = location.state;

            baseDataLayer.category = {
                id: categoryId,
                shortDescription: categoryName,
                categoryPath,
                legacyId: categoryLegacyId,
            };
        }
    } catch (ex) {
        mbpLogger.logError({
            product,
            pageDataLayer,
            function: 'generateProductPageDataLayer',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate Product data layer input data.',
        });
    }

    return baseDataLayer;
};

// Build data layer object for Category pages
const generateCategoryPageDataLayer = (page, pageDataLayer) => {
    const { dataLayer } = page;
    const baseDataLayer = { ...pageDataLayer };

    if (!page) {
        mbpLogger.logError({
            page,
            pageDataLayer,
            function: 'generateCategoryPageDataLayer',
            module: 'tag-manager',
            message: 'Missing required params [page]',
        });

        return false;
    }

    try {
        baseDataLayer.pageType = 'category';
        baseDataLayer.pageName = 'Category';

        const track_category_data = getDataLayerActionEntry('track_category_data', dataLayer);
        const track_product_listing_data = getDataLayerActionEntry('track_product_listing_data', dataLayer); // TODO: Replace with category.products
        if (track_category_data && track_product_listing_data) {
            const { category = {} } = track_category_data;
            const { products = [] } = track_product_listing_data;

            baseDataLayer.name = decodeHTML(category?.shortDescription) || '';
            baseDataLayer.id = category?.id;
            baseDataLayer.legacyId = category?.legacyId;
            // baseDataLayer.identifier = category.identifier;
            baseDataLayer.canonicalUrl = page?.path || '';

            if (products) {
                // baseDataLayer.productsImpressions = true; //TODO: Currently handled in Tealium extension

                // Generate ga_events
                // baseDataLayer.events = generatetePageViewEvents({ productImpArrays });

                baseDataLayer.products = products;
            }

            if (category) {
                baseDataLayer.category = {
                    ...category,
                    path: page?.path || '',
                };
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            page,
            function: 'generateCategoryPageDataLayer',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate Category data layer input data.',
        });
    }

    return {
        ...baseDataLayer,
    };
};

// Build data layer object for Search Results pages
const generateSearchResultsDataLayer = (page, pageDataLayer) => {
    const { dataLayer } = page;
    const baseDataLayer = { ...pageDataLayer };

    if (!page) {
        mbpLogger.logError({
            page,
            pageDataLayer,
            function: 'generateSearchResultsDataLayer',
            module: 'tag-manager',
            message: 'Missing required params [data]',
        });
        return false;
    }

    try {
        baseDataLayer.pageType = 'search';
        baseDataLayer.pageName = 'Search';

        const track_product_listing_data = getDataLayerActionEntry('track_product_listing_data', dataLayer);
        if (track_product_listing_data) {
            const { searchTerm, products } = track_product_listing_data;
            baseDataLayer.searchTerm = searchTerm;
            baseDataLayer.products = products;

            // baseDataLayer.productsImpressions = true; //TODO: Currently handled in Tealium extension

            // Generate ga_events
            // baseDataLayer.events = generatetePageViewEvents({ productImpArrays });
        }
    } catch (ex) {
        mbpLogger.logError({
            page,
            pageDataLayer,
            function: 'generateSearchResultsDataLayer',
            module: 'tag-manager',
            jsError: ex,
            message: 'Failed to generate Search Results data layer input data.',
        });
    }

    return baseDataLayer;
};

// Build Page path value.
// - Implicitly include URL params (default: exclude all)
const getPagePath = (location) => {
    if (location) {
        // Handle searchTerm params
        if (location.search.indexOf('searchTerm') !== -1) {
            return `${location.pathname}${location.search}`;
        }

        // Default
        return location.pathname;
    }

    return 'PAGE_NOT_SET';
};

const generateCustomerVariants = (contentTestVariants, redirectTestVariant) => {
    let eventsArray = '';

    if (contentTestVariants?.length > 0) {
        eventsArray = [...contentTestVariants, ...eventsArray];
    }

    if (redirectTestVariant?.eventAction) {
        eventsArray = [redirectTestVariant, ...eventsArray];
    }

    return eventsArray;
};

// Generate page data layer object
export const generatePageDataLayer = (data) => {
    if (!data) {
        mbpLogger.logError({
            data,
            function: 'generatePageDataLayer',
            module: 'tag-manager',
            message: 'Missing required params',
        });

        return null;
    }

    const {
        page,
        session: {
            user = {},
            paymentDetails = {},
        } = {},
    } = data;
    let pageDataLayer = {};
    const isMobile = (typeof window !== 'undefined') ? window.isMobile : false;

    // Data
    const track_seo_data = getDataLayerActionEntry('track_seo_data', page.dataLayer);

    try {
        pageDataLayer.page = getPagePath(page.location);

        if  (isMobile) {
            // AMP
            pageDataLayer.ampEnabled = true;
        }

        // Set Brand
        pageDataLayer.brand = page.brand;

        // Transport Type
        // const transport = (navigator && navigator.sendBeacon) ? 'beacon' : 'xhr';
        // pageDataLayer.guaTransportType = transport;

        // Customer Details
        pageDataLayer.customer_id = user?.email || '';
        pageDataLayer.customer_firstName = user?.firstName || '';
        pageDataLayer.customer_lastName = user?.lastName || '';
        pageDataLayer.customer_email = user?.email || '';
        pageDataLayer.customer_contactId = user?.contactId || '';
        pageDataLayer.customer_userRole = user?.userRole || '';
        const abTestingGAEvents = page?.variantTestGAEvents || page?.redirectTestGAEvent
            ? generateCustomerVariants(page?.variantTestGAEvents, page?.redirectTestGAEvent)
            : '';
        pageDataLayer.customer_variants = abTestingGAEvents || '';
        // pageDataLayer.customer_userId = user?.email || ''; // TODO: Add hashed email to Member state (see order.billingAddress.hashedEmail)
        // pageDataLayer.customer_id_prefix = 'loginId_prefix' || ''; // TODO: Not sure what this is used for. JSP: fn:replace(person.credential.logonID, logonId, '')
        // pageDataLayer.customer_crm_id = (order.fdUserDataVO) ? order.fdUserDataVO.crmID : ''; // TODO: Do we still use this?

        // Location
        if (page.locationURL) {
            pageDataLayer.location = page.locationURL;
        }

        // Referrer
        if (page.referrer) {
            pageDataLayer.referrer = page.referrer;
        }

        // Title
        pageDataLayer.title = decodeHTML((track_seo_data && track_seo_data.seoData.page_title.trim() !== '') ? track_seo_data.seoData.page_title : document.title);

        // Inventory status
        pageDataLayer.instock = true; // Static value because the product will no be on the site if it's out of stock. Required for BounceX.

        const track_order_data = getDataLayerActionEntry('track_order_data', page.dataLayer);
        if (track_order_data && track_order_data.order) { // Checkout
            page.paymentDetails = paymentDetails || null;

            pageDataLayer = generateCheckoutPageDataLayer(page, pageDataLayer);
        }

        if (page.account) { // Account
            // baseDataLayer.brand = page.brand.code || '';
            pageDataLayer = generateMyAccountDataLayer(page, pageDataLayer);
        }

        if (page.type === 'home') {
            pageDataLayer.pageType = 'homepage';
            pageDataLayer.pageName = 'Homepage';
        }

        if (page.type === 'product') {
            pageDataLayer = generateProductPageDataLayer(page, pageDataLayer);
        }

        if (page.type === 'category') {
            pageDataLayer = generateCategoryPageDataLayer(page, pageDataLayer);
        }

        if (page.path && page.path.indexOf('/searchterm') !== -1) {
            pageDataLayer = generateSearchResultsDataLayer(page, pageDataLayer);
        }

        // Category Template
        if (page.type === 'category-template') {
            pageDataLayer.pageType = page.type || '';
            pageDataLayer.pageName = page.contentType || '';
        }

        // Add-Ons page
        if (page.path && page.path.indexOf('/add-ons') !== -1) {
            pageDataLayer.pageType = 'productAddOns';
            pageDataLayer.pageName = 'ProductAddOns';
        }

        //  Content page
        if (page.type === 'content' || page.type === 'content-page') {
            pageDataLayer.pageType = page.type || '';
            pageDataLayer.pageName = page.contentType || '';
        }

        //  Content page
        if (page.type === 'department') {
            pageDataLayer.pageType = page.type || '';
            pageDataLayer.pageName = page.contentType || '';
        }

        // Radio Intersitial form results
        if (page.path && page.path.indexOf('/radio-offers-results') !== -1) {
            pageDataLayer.radioEventTerm = page.path.split('/')[2];
        }

        // Not Found
        if (page.type === 'notfound') {
            pageDataLayer.pageType = page.type || '';
            pageDataLayer.pageName = page.contentType || '';
        }
        return  pageDataLayer;
    } catch (ex) {
        mbpLogger.logError({
            data,
            function: 'generatePageDataLayer',
            module: 'mbp-page',
            jsError: ex,
            message: 'Generate page data layer object failed.',
        });
    }

    return null;
};

export default {};
