/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './ChasePay-ActionTypes';

export const completeChasePayCheckout = (history) => ({
    type: actionTypes.COMPLETE_CHASE_PAY_CHECKOUT,
    data: {
        history,
    },
});

export default {};
