/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
import * as actionTypes from './Properties-ActionTypes';

const propertiesReducer = (state = initialState.common.properties, action) => {
    switch (action.type) {
        case actionTypes.LOAD_CHECKOUT_PROPERTIES:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export default propertiesReducer;
