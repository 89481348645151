/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-underscore-dangle */

import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory, createMemoryHistory } from 'history';
import createSagaMiddleware, { END } from 'redux-saga';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native

import MBPLogger from 'mbp-logger';

import rootReducer from './reducers';

export const configureHistory = (url) => {
    let history = null;

    if (typeof window !== 'undefined') {
        history = createBrowserHistory();
    } else {
        const config = {};
        if (url) {
            config.initialEntries = [url];
        }
        history = createMemoryHistory(config);
    }

    return history;
};

const sagaMiddleware = createSagaMiddleware();
const devTools =  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    : () => (noop) => noop;
const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: ['member'],
};
const configureStore = (initialState = {}) => {
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const middlewares = [
        sagaMiddleware, // Redux-Saga
        MBPLogger.middlewareLogger.logger, // MBP-Logger
        MBPLogger.middlewareLogger.crashReporter, // MBP-Logger
    ];
    const enhancers = [applyMiddleware(...middlewares), devTools()];
    const composedEnhancers = compose(...enhancers);
    const store = createStore(persistedReducer, initialState, composedEnhancers);
    store.runSaga = sagaMiddleware.run;

    store.close = () => {
        store.dispatch(END);
    };

    return store;
};

export default configureStore;
