/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../initialState';
import * as actions from './Order-Actions';

const orderReducer = (state = initialState.order, action) => {
    switch (action.type) {
        case actions.loadCartCount().type:
            return { ...state, cartCount: action.data.cartCount };
        case actions.loadOrderId().type:
            return { ...state, orderId: action.data[0] };
        case actions.atlasOrderNumberSuccess().type:
            return { ...state, atlasOrderId: action.data };
        // TODO: clear atlas order id on successful eshop order place
        case actions.clearOrderId().type:
            return { ...state, orderId: '' };
        case actions.loadExpressCheckout().type:
            return { ...state, expressCheckout: true };
        default:
            return state;
    }
};

export default orderReducer;
