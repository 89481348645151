/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import checkoutActionTypes from './Checkout-ActionTypes';

export const addToCart = (item, history, deliveryType = null, categoryId = null, partNumber = null, skipAddons = false, deliveryDate = null, categoryName = null, categoryPath = null, isPassportBundleItem = false, movieSelected = null, selectedSku = {}, isPassportBundle = {}, retailPriceObj = {}, enableMinicart = false, handleMiniCartModalClick = () => {}) => ({
    type: checkoutActionTypes.MBPCHECKOUT_ADD_TO_CART,
    // deliveryType, categoryId, partNumber, and skipAddons, deliveryDate, categoryNames
    // categoryPath, isPassportBundleItem, movieSelected can all be omitted
    // they will not effect desired behavior for non-18F brands.
    payload: {
        item,
        history,
        deliveryType,
        categoryId,
        partNumber,
        skipAddons,
        deliveryDate,
        categoryName,
        categoryPath,
        isPassportBundleItem,
        movieSelected,
        selectedSku,
        isPassportBundle,
        retailPriceObj,
        enableMinicart,
        handleMiniCartModalClick,
    },
});

// oldAddonsOrderItemIds is the add-ons that already have the product into the cart.
export const addAddonsToCart = (addonData, history, orderItemId, oldAddonsOrderItemIds, selectedAddOnItems) => ({
    type: checkoutActionTypes.ADD_ADDONS_TO_CART,
    payload: {
        addonData,
        history,
        orderItemId,
        oldAddonsOrderItemIds,
        selectedAddOnItems,
    },
});

export const addWrapUpsToCart = (wrapupData, history, orderItemId) => ({
    type: checkoutActionTypes.ADD_WRAPUPS_TO_CART,
    payload: {
        wrapupData,
        history,
        orderItemId,
    },
});

export const addAddonsAndWrapupToCart = (addonData, wrapupData, history, orderItemId, oldAddonsOrderItemIds, selectedAddOnItems) => ({
    type: checkoutActionTypes.ADD_ADDONS_AND_WRAPUP_TO_CART,
    payload: {
        addonData,
        history,
        orderItemId,
        oldAddonsOrderItemIds,
        selectedAddOnItems,
        wrapupData,
    },
});

export const addToCartSuccess = () => ({
    type: checkoutActionTypes.MBPCHECKOUT_ADD_TO_CART_SUCCESS,
});

export const addToCartFailed = (exception) => ({
    type: checkoutActionTypes.MBPCHECKOUT_ADD_TO_CART_FAILED,
    payload: exception,
});

export const applyPromotion = (promoCode, membershipId, pageType = '') => ({
    type: checkoutActionTypes.APPLY_PROMOTION_ACTION,
    payload: {
        promoCode,
        membershipId,
        pageType,
    },
});

export const applyPromotionCompleted = () => ({
    type: checkoutActionTypes.APPLY_PROMOTION_COMPLETED,
});

export const removePromotion = (promoCode, pageType) => ({
    type: checkoutActionTypes.REMOVE_PROMOTION_ACTION,
    payload: {
        promoCode,
        pageType,
    },
});

export const removePromotionCompleted = () => ({
    type: checkoutActionTypes.REMOVE_PROMOTION_COMPLETED,
});

export const calculateShipping = () => ({
    type: checkoutActionTypes.CALCULATE_SHIPPING_ACTION,
});

export const calculateShippingComplete = () => ({
    type: checkoutActionTypes.CALCULATE_SHIPPING_COMPLETE,
});

export const loadOrderData = (order) => ({
    type: checkoutActionTypes.LOAD_ORDER_DATA,
    data: order,
});

export const saveBannerToOrder = (bannerCode, lsid) => ({
    type: checkoutActionTypes.PERSIST_ATTRIBUTION_ACTION,
    payload: {
        bannerCode,
        lsid,
    },
});

export const saveBannerProcessCheckCompleted = () => ({
    type: checkoutActionTypes.SAVE_BANNER_PROCESS_CHECK_COMPLETED,
});

export const saveBannerProcessCheckFailed = () => ({
    type: checkoutActionTypes.SAVE_BANNER_PROCESS_CHECK_FAILED,
});

export const setSubmitPaymentButtonType = (type) => ({
    type: checkoutActionTypes.SET_SUBMIT_PAYMENT_BUTTONT_TYPE,
    payload: type,
});

export const promotionActions = {
    removePromotion,
    applyPromotion,
};

export const checkApplePayEnabled = () => ({
    type: checkoutActionTypes.CHECK_APPLE_PAY_ENABLED,
});

export const appProcessPage = (page, history) => ({
    type: checkoutActionTypes.APP_PROCESS_PAGE,
    data: {
        page,
        history,
    },
});

export const addToCartFood = (item, history, categoryId = null, partNumber = null, categoryPath = null, categoryName = null, enableMinicart = false, handleMiniCartModalClick = () => {}, personalization = null, isMobile = false, passportBundle = {}, retailPriceObj = {}) => ({
    type: checkoutActionTypes.MBPCHECKOUT_ADD_TO_CART_FOOD,
    payload: {
        item,
        history,
        categoryId,
        partNumber,
        categoryPath,
        categoryName,
        enableMinicart,
        handleMiniCartModalClick,
        personalization,
        isMobile,
        passportBundle,
        retailPriceObj,
    },
});

export const addToCartPlants = (item, history, categoryId = null, partNumber = null, categoryPath = null, categoryName = null, enableMinicart = false, handleMiniCartModalClick = () => {}, personalization = null, isMobile = false, passportBundle = {}, retailPriceObj = {}, deliveryDate = null) => ({
    type: checkoutActionTypes.MBPCHECKOUT_ADD_TO_CART,
    payload: {
        item,
        history,
        categoryId,
        partNumber,
        categoryPath,
        categoryName,
        enableMinicart,
        handleMiniCartModalClick,
        personalization,
        isMobile,
        passportBundle,
        retailPriceObj,
        deliveryDate,
    },
});

export const validateShopCart = (payload) => ({
    type: checkoutActionTypes.VALIDATESHOPCART,
    data: payload,
});
