/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { END } from 'redux-saga';
import {
    call, take, put, fork, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import { fetchCGCCardData, fetchCGCLabeldData, fetchCGCCustomCardData } from '../../../apis/checkout-apis/fetchCGCData';
import { getGCIPersonalizationKey, getCGCCardData } from '../App/App-Selectors';
import { getBrand } from '../App/ducks/Brand/Brand-Selectors';
import { showLoadingMessageActions } from '../Checkout/ducks/Common/ducks/ShowLoadingMessage';
import { gciPersonalizationComplete } from '../Checkout/ducks/Recipient/ducks/GiftMessageForm/GiftMessageForm-Actions';
import {
    setCGCCardData,
    addCGCDataToApi,
    addCGCLabelsDataToApi,
    getCGCCustomCardData,
    setCGCCustomCardData,
} from './CGC-Actions';

const addCGCData = (data) => fetchCGCCardData(data)
    .then((response) => response)
    .catch((err) => {
        mbpLogger.logDebug({
            err,
            function: 'addCGCData',
            module: 'App-Operations',
            message: 'onAddCGCData',
        });
    });
const addCGCLabesData = (data) => fetchCGCLabeldData(data)
    .then((response) => response)
    .catch((err) => {
        mbpLogger.logDebug({
            err,
            function: 'addCGCLabelsData',
            module: 'App-Operations',
            message: 'onAddCGCLabelsData',
        });
    });

function* workerAddCGCData(data) {
    const {
        cardData, message, cgcData,
    } = data;
    try {
        const backCardAttributes = cgcData?.brandData?.back_card_attributes;
        const messageStyle = message?.style;
        const brandData = cgcData?.brandData;
        const innerView = cardData?.inner_view;
        const frontHighResImage = cardData?.front_view?.print_image_high_res_?.url;
        const brand = yield select(getBrand);
        const gciKeyId = yield select(getGCIPersonalizationKey);
        const obj = {
            message_body: message?.text,
            front_logo: brandData?.brand_logo?.url,
            cover_image: frontHighResImage,
            pd_guid: gciKeyId,
            message_font_color: messageStyle?.color || '#000',
            message_font_family: messageStyle?.fontFamily || innerView?.heading_font_family,
            text_alignment: messageStyle?.textAlign || innerView?.heading_alignment,
            message_font_size: innerView?.heading_font_size,
            back_card_font_family: backCardAttributes?.font_family || 'Arial',
            back_card_font_size: backCardAttributes?.back_view_font_size || '',
            back_card_font_color: backCardAttributes?.font_color || '#000',
            back_card_disclaimer: brandData?.card_back_view_disclaimer || '',
            back_card_color: backCardAttributes?.background_color || '#fff',
            card_size: `${brandData?.card_size_height}x${brandData?.card_size_width}` || '',
            card_orientation: cardData.card_orientation?.toLowerCase() || '',
            productSku: cgcData.productSku,
            OrdersItemId: cgcData.orderItemId,
            cardSku: cardData.sku,
        };

        // add data to Reducer and API
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const response = yield call(addCGCData, obj);
        if (response?.data?.success) {
            let cgcStateData = yield select(getCGCCardData);
            if (cgcStateData.some((stateData) => stateData.orderItemId === cgcData.orderItemId)) {
                cgcStateData = cgcStateData.filter((stateData) => stateData.orderItemId !== cgcData.orderItemId) || [];
            }

            const newStateData = [...cgcStateData, cgcData];
            const responseData = {
                key_id: gciKeyId,
                sku: cardData.sku,
                text: message.text,
                thumbnail: `${cardData?.front_view.card_image?.url}?width=63`,
                action: 'personalizationComplete',
            };

            const personalizationCompleteResponse = {
                brandCode: brand.id,
                itemType: 'GCI',
                productCode: cardData?.sku,
                response: {
                    responseData: JSON.stringify(responseData),
                },
            };

            yield put(gciPersonalizationComplete({
                orderItemId: cgcData.orderItemId,
                personalizationCompleteResponse,
            }));
            yield put(showLoadingMessageActions.updateShowLoadingMessage());
            yield put(setCGCCardData(newStateData));
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerAddCGCData',
            module: 'mbp-ui-app',
            jsError: ex,
            message: 'Failed to save card',
        });
    }
}
// worker to add cgcLabelsData
function* workerAddCGCLabelsData(data) {
    const {
        cardData, message, imageConverted, customsCardType, customType,
    } = data;
    const messageSetting = cardData?.message_settings;
    const messageStyle = message?.style;
    const messageImageSettings = cardData?.message_image_settings;
    // add data to Reducer and API for CGC labels
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const gciKeyId = yield select(getGCIPersonalizationKey);
        const obj = {
            card_type: customType,
            message_body: message?.text,
            font_size: messageSetting?.heading_font_size,
            label_size: `${cardData?.label_width}x${cardData?.label_height}`,
            label_type: customsCardType,
            background_image: cardData?.label_image?.url,
            cover_image: imageConverted || '',
            text_color: messageStyle?.color || messageSetting?.message_color,
            font_family: messageStyle?.fontFamily || messageSetting?.heading_font_family,
            text_alignment: messageStyle?.textAlign || messageSetting?.heading_alignment,
            pd_guid: gciKeyId,
            container_border_color: cardData?.container_border_color,
            container_border_width: cardData?.container_border_width,
            content_area_width: cardData?.content_area_width,
            content_area_height: cardData?.content_area_height,
            bleed_width: cardData?.bleed_width,
            image_width: messageImageSettings?.image_width,
            message_width: messageImageSettings?.message_width,
            space_between_image_and_message: messageImageSettings?.space_between_message_image,
        };
        const response = yield call(addCGCLabesData, obj);
        if (response.success) {
            yield put(setCGCCustomCardData({}));
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        } else {
            mbpLogger.logError({
                function: 'workerAddCGCLabels',
                module: 'mbp-ui-app',
                response,
                message: 'add labels response failed',
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerAddCGCLabels',
            module: 'mbp-ui-app',
            jsError: ex,
            message: 'Failed to Call Api',
        });
    }
}

function* workerGetCGCCustomCardData(keyId) {
    yield put(setCGCCustomCardData({}));
    const response = yield call(fetchCGCCustomCardData, keyId);
    if (response.status === 200) {
        yield put(setCGCCustomCardData(response.data.data));
    }
}

function* watchAddCGCData() {
    let action = yield take(addCGCDataToApi().type);

    while (action !== END) {
        yield fork(workerAddCGCData, action.payload);
        action = yield take(addCGCDataToApi().type);
    }
}
function* watchAddCGCLabelsData() {
    let action = yield take(addCGCLabelsDataToApi().type);

    while (action !== END) {
        yield fork(workerAddCGCLabelsData, action.payload);
        action = yield take(addCGCLabelsDataToApi().type);
    }
}

function* watchGetCGCCustomCardData() {
    let action = yield take(getCGCCustomCardData().type);

    while (action !== END) {
        yield fork(workerGetCGCCustomCardData, action.payload);
        action = yield take(getCGCCustomCardData().type);
    }
}
const watchers = [fork(watchAddCGCData), fork(watchAddCGCLabelsData), fork(watchGetCGCCustomCardData)];
export default watchers;
