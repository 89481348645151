/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    take, fork, call, put, select, takeLatest,
} from 'redux-saga/effects';
// TODO remove in the future, now still doing testing. CO-1456
// import Cookies from 'universal-cookie';
import qs from 'qs';
import Cookies from 'universal-cookie';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import { duck as tmDuck } from '../../../TagManager';
import * as commonSelectors from '../Common/Common-Selectors';
import { errorActions, errorOperations } from '../Common/ducks/Error';
import { orderOperations } from '../Order';
import { retryCalculateShippingActions } from '../Recipient/ducks/RetryCalculateShipping';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';
import { promotionActions } from '../Payment/ducks/Promotion';
import { activePaymentMethod, inActivePaymentMethod } from '../Payment/Payment-Actions';
import { formatFullGifMessage, pwaDesktopAddGiftMessage } from '../Recipient/ducks/GiftMessageForm/GiftMessageForm-Operations';
import { retrieveCart, recalculateGiftCardTotalWorker } from '../Cart/Cart-Operations';
import { loadPaymentDetailsWorker, onPaymentPageProcessing } from '../Payment/Payment-Operations';
import {
    getSavedAddress, getLocationType, getBrandMovieSkus, getUserSubmittedProductFilterZipcode, getProductRetailPrice, getAddressSource,
} from '../../../App/App-Selectors';
import {
    updateBillingAddress, updateBillingAddressSuccess, updateBillingAddressFailed,
} from '../Payment/ducks/BillingAddressForm/BillingAddressForm-Actions';
import { setWorkingRecipient, loadOrderItemDetails } from '../Recipient/Recipient-Actions';
import { passportActions } from '../Payment/ducks/Passport';
import { recipientFormFields } from '../Recipient/ducks/RecipientForm/RecipientForm-Helpers';
import { checkIsInternational, getInternationalCountryField, getCountryField } from './Checkout-Helper';
import { passportBundleItemAddedToCart, setProductRetailPrice } from '../../../App/App-Actions';
import { loadCardIsleAvailable } from '../Recipient/ducks/CardIsle/CardIsle-Operations';
import { setShopTheSiteLinkBackToGiftList } from '../../../../../app/pages/Account/utils/shopTheSite';
import { isEmpty } from '../../../../../app/pages/Account/utils/object';

import memberDucks from '../../../Member/ducks';
import { loadAddressBook } from '../../../Member/ducks/AddressBook/AddressBook-Actions';
import {
    getUserType,
    getProfileInfo,
    getIsAuthenticatedStatus,
} from '../../../Member/ducks/Common/Common-Selectors';

import {
    updateFormAddressFromPreFill,
    triggerStateCityData,
} from '../Recipient/ducks/RecipientForm/RecipientForm-Actions';

import {
    addToCart,
    addToCartSuccess,
    addToCartFailed,
    calculateShipping,
    calculateShippingComplete,
    saveBannerToOrder,
    applyPromotion,
    applyPromotionCompleted,
    removePromotion,
    removePromotionCompleted,
    saveBannerProcessCheckCompleted,
    checkApplePayEnabled,
    appProcessPage,
    addAddonsToCart,
    addWrapUpsToCart,
    addAddonsAndWrapupToCart,
    addToCartFood,
    validateShopCart,
} from './Checkout-Actions';

import { getBaseCode } from '../../../../../app/helpers/tracking/product/dataLayerProductHelpers';
import { convertAddressByAddressSource } from '../../helpers/preCheckoutAddressNormalizer';
import { callHandleCartFailure } from '../Cart/Cart-Actions';
import cartServices from '../../../../../apis/cart-service-apis/index';
import { getFeatureFlags } from '../../../App/ducks/Config/Config-Selectors';

const {
    auth: {
        authActions: {
            triggerInterstitialLogin,
        },
        authOperations,
    },
    wallet: {
        walletActions: {
            loadWallet,
        },
    },
} = memberDucks;

const { checkJWT, handleAuthorizationApiFailures } = authOperations.workers;

const { getFlagEnableCheckoutMonitoring, getFlags } = commonSelectors;

const postAddToCart = ({
    JWT_TOKEN, item, recipients, giftHistoryOrderItemId, personalization,
}) => orderClient.addToCart({}, JWT_TOKEN, recipients, item, giftHistoryOrderItemId, personalization);

// ValidateShopCart API
// const postValidateShopCart = ({ JWT_TOKEN, orderId }) => orderClient.validateShopCart({}, JWT_TOKEN, orderId);

// const getOrderDetailsByUser = jwt => orderClient.fetchOrderDetailsByUser({}, jwt);

const postAddAddonsToCart = ({
    JWT_TOKEN,
    addonData,
    orderItemId,
    orderId,
}) => orderClient.addAddonItemsToCart({}, JWT_TOKEN, orderItemId, addonData, orderId);

const postAddWrapupsToCart = ({
    JWT_TOKEN,
    wrapupData,
    orderId,
}) => orderClient.addWrapupsToCart({}, JWT_TOKEN, wrapupData, orderId);

const doApplyPromotion = ({
    JWT_TOKEN, orderId, promoCode, membershipId, currentPayment,
}) => orderClient.applyPromotion(
    {},
    JWT_TOKEN,
    orderId,
    promoCode,
    membershipId,
    '',
    '',
    currentPayment,
);

const doRemovePromotion = ({ JWT_TOKEN, orderId, promoCode }) => orderClient.removePromotion({}, JWT_TOKEN, orderId, promoCode);

const calOrderShipping = ({ JWT_TOKEN, orderId, calEstimatedShippping }) => orderClient.calculateShipping({}, JWT_TOKEN, orderId, calEstimatedShippping);

const doUpdateBillingAddress = ({ JWT_TOKEN, orderId, billingAddress }) => orderClient.updateBillingAddress({}, JWT_TOKEN, orderId, billingAddress);
const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

const addRecipient = ({
    JWT_TOKEN, orderItemId, recipient, entryId, orderId,
}) => orderClient.addRecipient(
    {},
    JWT_TOKEN,
    orderItemId,
    recipient,
    entryId,
    orderId,
);

function* getPreCheckoutRecipientAddress() {
    const savedAddress = yield select(getSavedAddress);
    const recipient = yield call(convertAddressByAddressSource, savedAddress);
    const locationType = yield select(getLocationType);
    recipient.locationType = locationType;
    return recipient;
}

let handleFailsDueToAccessTokenAddToCart = false;
function* onAddToCart({
    item,
    history,
    categoryId,
    categoryName,
    categoryPath,
    isPassportBundleItem,
    movieSelected,
    selectedSku,
    isPassportBundle,
    retailPriceObj,
    enableMinicart = false,
    handleMiniCartModalClick = () => {},
}) {
    // deliveryType, categoryId, and partNumber are all optional parameters that are for 18F specific navigation logic.
    // If not provied they both default to null.
    const logOrderId = yield select(commonSelectors.getOrderId);
    const args = { item, history };
    const flagState = yield select(getFlags);
    const isInterstitialLoginEnabled = flagState['is-interstitial-login-enabled'];
    const whichCheckoutVariation = flagState['which-checkout-variation'];
    const userProfile = yield select(getProfileInfo);
    const isAuth = yield select(getIsAuthenticatedStatus);
    let isRememberedUser = false;
    if (userProfile.email) {
        isRememberedUser = true;
    }
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onAddToCart',
                module: 'Checkout-Operations',
                message: 'onAddToCart - Start',
                item,
                orderId: logOrderId,
            });
        }
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));
        const JWT_TOKEN = yield call(checkJWT);
        const locationType = yield select(getLocationType);
        const zipCode = yield select(getUserSubmittedProductFilterZipcode);
        const reqPayload = {
            item,
            JWT_TOKEN,
            giftHistoryOrderItemId: '',
        };
        const preCheckoutSavedAddress = yield select(getSavedAddress);
        if (Object.keys(preCheckoutSavedAddress).length !== 0 && !(preCheckoutSavedAddress.addressSource === 'SCI' && locationType !== 'Funeral home')) {
            // If there is an address saved in Redux pre checkout
            // (from Guided Nav, address book, SCI)
            const recipientAddress = yield call(getPreCheckoutRecipientAddress);
            reqPayload.recipients = [recipientAddress];
        } else if (locationType && zipCode && item?.delivery?.zipCode !== '00000') {
            reqPayload.recipients = [{
                zipCode,
                locationType,
            }];
        }
        const addtoCartResp = yield call(postAddToCart, reqPayload);
        const [orderId] = addtoCartResp.data.orderId;
        yield call(orderOperations.setOrderId, {
            data: addtoCartResp.data.orderId,
        });
        if (orderId !== '' && enableMinicart) {
            yield call(handleMiniCartModalClick, true);
        }
        // Handle assignment for orderItemId coming in from both WCS services and Cart services
        // WCS services returns orderItemId as string
        // Cart services returns orderItemId as array - first item on the array is the item added to the cart
        let orderItemId = '';
        if (addtoCartResp?.data?.orderItemId && typeof addtoCartResp.data.orderItemId === 'string') {
            orderItemId = addtoCartResp.data.orderItemId;
        } else if (addtoCartResp?.data?.orderItemId && Array.isArray(addtoCartResp.data.orderItemId)) {
            const arrOrderItemId = addtoCartResp.data.orderItemId;
            orderItemId = arrOrderItemId[0];
        }
        const brandMovieSkus = yield select(getBrandMovieSkus);
        if (!brandMovieSkus[item.productCode]) {
            yield put(loadOrderItemDetails({
                orderItemId,
                orderItemDetails: item,
            }));
        }
        // setting retail price on redux to use it on checkout flow
        // this approach is considering multicurrency
        // Examples of retailPriceObj:
        // CA -> { USD: 54.99, CAD: 69.99 }
        // 08F -> { USD: 45, CAD: 59.99, EUR: 39.99, GBP: 34.99 }
        // Other brand -> { USD: 54.99 }
        // TODO: Remove this once we will get retail price and sale price into cart api response to use this on checkout flow
        if (Object.keys(retailPriceObj).length) {
            const listOfRetailPrices = yield select(getProductRetailPrice);
            const retailPriceData = [...listOfRetailPrices, { [orderItemId]: retailPriceObj }];
            yield put(setProductRetailPrice(retailPriceData));
        }

        handleFailsDueToAccessTokenAddToCart = false;
        if (isPassportBundleItem) {
            yield put(passportBundleItemAddedToCart(item.productCode));
        }
        const giftMessage = yield select(commonSelectors.getGiftMessageInfo);
        if (giftMessage && giftMessage.message && orderItemId) {
            const giftMessageObj = giftMessage.message;
            const messageTextPiped = giftMessageObj.replace(/\n/g, '|');
            const payloadGiftMessage = messageTextPiped;
            const occasionCode = giftMessage.occasion || '';
            const actionDataSaveGiftMessage = {
                data: {
                    orderItemId,
                    giftMessage: payloadGiftMessage,
                    occasionCode,
                },
            };
            // Save Gift Message Saga
            yield call(pwaDesktopAddGiftMessage, actionDataSaveGiftMessage);
        }
        yield call(retrieveCart);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        const cartCount = yield select(commonSelectors.getCartCount);
        // Track Add-to-Cart
        const recips = yield select(commonSelectors.getRecipients);
        let addedItem = null;
        recips.forEach((recip) => {
            const { orderItems } = recip;
            orderItems.forEach((orderItem) => {
                if (orderItem.orderItemsId === orderItemId.toString()) {
                    addedItem = orderItem;
                }
            });
        });
        if (addedItem) {
            const { price, catalogEntryVO } = addedItem;
            const trackAddToCartPayload = {
                event: 'addToCart',
                eventName: 'add_to_cart',
                eventCategory: 'Product Page', // TODO: find out from gabby
                eventAction: 'Add to Cart', // TODO: find out from gabby
                eventLabel: catalogEntryVO.parentSkuName,
                products: [
                    {
                        name: catalogEntryVO.parentSkuName,
                        partNumber: catalogEntryVO.skuCode,
                        baseCode: getBaseCode(catalogEntryVO.skuCode),
                        brand: catalogEntryVO.brandId,
                        sKUs: [{
                            partNumber: catalogEntryVO.skuCode,
                        }],
                        price,
                        quantity: args.item.quantity,
                        position: 1,
                        categoryId: categoryId || '',
                        categoryName: categoryName || '',
                        categoryPath: categoryPath || '',
                    },
                ],
            };
            yield put(tmDuck.actions.trackEvent(trackAddToCartPayload));
        }
        if (movieSelected) {
            const movieItem = { ...item, brandCode: '1001', productCode: movieSelected.skuSelected.sku };
            yield call(onAddToCart, { item: movieItem });
        }
        // Get the product type according to the current product has been added.
        if (isPassportBundle?.enable) {
            yield put(tmDuck.actions.trackEvent({
                eventCategory: 'product',
                eventAction: 'Passport Banner Click',
                eventLabel: 'Bundle',
                eventName: 'feature_interaction',
            }));
            yield put(passportActions.triggerPassportSubscription('product', isPassportBundle?.passportSku));
            // regext for -- catching promocode from the url params
            if (history?.location?.search && new RegExp(/promoCode=(.*)/).test(history.location.search)) {
                const match = (history.location.search).match(/promoCode=(.*)/);
                if (match.length >= 1) {
                    yield put(applyPromotion(match[1]));
                }
            }
        }
        // The following if statements are for 18F specific navigation.
        // If the 18F specific params are not passed, the user will be navigated to the cart page as per the previous saga behavior
        if (!enableMinicart && history) {
            if (cartCount > 1 || (cartCount && selectedSku?.isInternational)) {
                yield call(history.push, `/checkout/cart/${orderId}`);
            } else if (whichCheckoutVariation === 'food' && isInterstitialLoginEnabled && !isAuth && !isRememberedUser) {
                yield put(triggerInterstitialLogin(history));
            } else {
                yield call(history.push, `/checkout/shipping/${orderId}`);
            }
        }

        yield put(addToCartSuccess());
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onAddToCart',
                module: 'Checkout-Operations',
                message: 'onAddToCart - success',
                item,
                orderId,
            });
        }
    } catch (ex) {
        // yield put(callHandleCartFailure(ex));
        mbpLogger.logError({
            item,
            history,
            module: 'Checkout-Operations',
            function: 'onAddToCart',
            jsError: ex,
            message: 'onAddToCart Failed',
            orderId: logOrderId,
        });
        const { response } = ex;
        if (response
            && response.status
            && ['401', '403'].includes(response.status.toString())
            && !handleFailsDueToAccessTokenAddToCart) {
            handleFailsDueToAccessTokenAddToCart = true;
            if (getFlagEnableCheckoutMonitoring(flagState) === true) {
                // log updated as ERROR to track on prod env
                mbpLogger.logError({
                    function: 'onAddToCart',
                    module: 'Checkout-Operations',
                    message: `onAddToCart Failed - Status code: ${response.status} proceeding to get access token`,
                    orderId: logOrderId,
                });
            }
            yield call(handleAuthorizationApiFailures, onAddToCart, args);
            handleFailsDueToAccessTokenAddToCart = false;
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
        yield put(addToCartFailed(ex));
    }
}

// oldAddonsOrderItemIds is the add-ons that already have the product into the cart.
function* onAddAddonsToCart({
    addonData, history, orderItemId, selectedAddOnItems,
}) {
    const orderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFlags);
    try {
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onAddAddonItemsToCart',
                module: 'Checkout-Operations',
                message: 'onAddAddonItemsToCart - Start',
                addonData,
                orderItemId,
            });
        }
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));
        const JWT_TOKEN = yield call(checkJWT);
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // TODO: Here start the delete old addons, currently doesnt work for multiples delete
        // TODO: Look with the new service car to delete the multiples old addons, also review the sagas
        // to see what is the better place to delete it
        // // Delete the old addons in the cart before add the new ones.
        // if (oldAddonsOrderItemIds && oldAddonsOrderItemIds.length > 0) {
        //     console.log('oldAddonsOrderItemIds:', oldAddonsOrderItemIds);
        //     yield all(oldAddonsOrderItemIds.map((addonOrderItemId) => {
        //         const configObjDelete = {
        //             wcEnv: {},
        //             JWT_TOKEN,
        //             orderItemId: addonOrderItemId,
        //             orderId,
        //         };
        //         console.log('configObjDelete:', configObjDelete);
        //         const result = call(removeItem, configObjDelete);
        //         put(errorActions.clearErrors({
        //             errType: 'cart',
        //             field: 'error',
        //         }));
        //         call(retrieveCart);
        //         console.log('result:', result);

        //         return  result;
        //     }));
        // }
        // yield put(errorActions.clearErrors({
        //     errType: 'cart',
        //     field: 'error',
        // }));
        // TODO: Here end the delete old addons, currently doesnt work for multiples delete

        // Track Add to Carts
        yield put(tmDuck.actions.trackEvent({
            eventName: 'add_to_cart',
            eventCategory: 'Shopping',
            eventAction: 'Product AddOn Added',
            products: Object.keys(selectedAddOnItems).map((key) => {
                const {
                    name, groupName, brandId, partNumber, prices,
                } = selectedAddOnItems[key];

                return {
                    name: groupName || name || '',
                    partNumber,
                    brand: brandId || '',
                    baseCode: getBaseCode(partNumber),
                    sKUs: [{
                        partNumber,
                    }],
                    prices,
                    quantity: '1',
                    position: 1,
                    // categoryId: categoryId || '',
                    categoryName: 'AddOns' || '',
                    // categoryPath: categoryPath || '',
                };
            }),
        }));

        yield call(postAddAddonsToCart, {
            addonData,
            orderItemId,
            JWT_TOKEN,
            orderId,
        });
        yield call(retrieveCart);

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        if (history) {
            const cartCount = yield select(commonSelectors.getCartCount);
            if (cartCount > 1) {
                yield call(history.push, `/checkout/cart/${orderId}`);
            } else {
                yield call(history.push, `/checkout/shipping/${orderId}`);
            }
        }
        yield put(addToCartSuccess());
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onAddAddonItemsToCart',
                module: 'Checkout-Operations',
                message: 'onAddAddonItemsToCart - success',
                addonData,
                orderItemId,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            addonData,
            history,
            module: 'Checkout-Operations',
            function: 'onAddAddonItemsToCart',
            jsError: ex,
            message: 'onAddAddonItemsToCart Failed',
            orderItemId,
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
        yield put(addToCartFailed(ex));
    }
}

function* onAddWrapupsToCart({ wrapupData, history }) {
    const orderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFlags);
    try {
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onAddWrapupsToCart',
                module: 'Checkout-Operations',
                message: 'onAddWrapupsToCart - Start',
                wrapupData,
                orderId,
            });
        }
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));
        const JWT_TOKEN = yield call(checkJWT);
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield call(postAddWrapupsToCart, {
            wrapupData,
            JWT_TOKEN,
            orderId,
        });
        yield call(retrieveCart);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        if (history) {
            const cartCount = yield select(commonSelectors.getCartCount);
            if (cartCount > 1) {
                yield call(history.push, `/checkout/cart/${orderId}`);
            } else {
                yield call(history.push, `/checkout/shipping/${orderId}`);
            }
        }
        yield put(addToCartSuccess());
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onAddWrapupsToCart',
                module: 'Checkout-Operations',
                message: 'onAddWrapupsToCart - success',
                wrapupData,
                orderId,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            wrapupData,
            history,
            module: 'Checkout-Operations',
            function: 'onAddWrapupsToCart',
            jsError: ex,
            message: 'onAddWrapupsToCart Failed',
            orderId,
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
        yield put(addToCartFailed(ex));
    }
}

// oldAddonsOrderItemIds is the add-ons that already have the product into the cart.
function* onAddAddonsAndWrapupToCart({
    addonData, history, orderItemId, selectedAddOnItems, wrapupData,
}) {
    const orderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(getFlags);
    try {
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onAddAddonsAndWrapupToCart',
                module: 'Checkout-Operations',
                message: 'onAddAddonsAndWrapupToCart - Start',
                addonData,
                orderItemId,
            });
        }
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));
        const JWT_TOKEN = yield call(checkJWT);
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // TODO: Here start the delete old addons, currently doesnt work for multiples delete
        // TODO: Look with the new service car to delete the multiples old addons, also review the sagas
        // to see what is the better place to delete it
        // // Delete the old addons in the cart before add the new ones.
        // if (oldAddonsOrderItemIds && oldAddonsOrderItemIds.length > 0) {
        //     console.log('oldAddonsOrderItemIds:', oldAddonsOrderItemIds);
        //     yield all(oldAddonsOrderItemIds.map((addonOrderItemId) => {
        //         const configObjDelete = {
        //             wcEnv: {},
        //             JWT_TOKEN,
        //             orderItemId: addonOrderItemId,
        //             orderId,
        //         };
        //         console.log('configObjDelete:', configObjDelete);
        //         const result = call(removeItem, configObjDelete);
        //         put(errorActions.clearErrors({
        //             errType: 'cart',
        //             field: 'error',
        //         }));
        //         call(retrieveCart);
        //         console.log('result:', result);

        //         return  result;
        //     }));
        // }
        // yield put(errorActions.clearErrors({
        //     errType: 'cart',
        //     field: 'error',
        // }));
        // TODO: Here end the delete old addons, currently doesnt work for multiples delete

        // Track Add to Carts
        yield put(tmDuck.actions.trackEvent({
            eventName: 'add_to_cart',
            eventCategory: 'Shopping',
            eventAction: 'Product AddOn Added',
            products: Object.keys(selectedAddOnItems).map((key) => {
                const {
                    name, groupName, brandId, partNumber, prices,
                } = selectedAddOnItems[key];

                return {
                    name: groupName || name || '',
                    partNumber,
                    brand: brandId || '',
                    sKUs: [{
                        partNumber,
                    }],
                    prices,
                    quantity: '1',
                    position: 1,
                    // categoryId: categoryId || '',
                    categoryName: 'AddOns' || '',
                    // categoryPath: categoryPath || '',
                };
            }),
        }));
        if (addonData) {
            yield call(postAddAddonsToCart, {
                addonData,
                orderItemId,
                JWT_TOKEN,
                orderId,
            });
        }

        if (wrapupData) {
            yield call(postAddWrapupsToCart, {
                wrapupData,
                JWT_TOKEN,
                orderId,
            });
        }
        yield call(retrieveCart);

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        if (history) {
            const cartCount = yield select(commonSelectors.getCartCount);
            if (cartCount > 1) {
                yield call(history.push, `/checkout/cart/${orderId}`);
            } else {
                yield call(history.push, `/checkout/shipping/${orderId}`);
            }
        }
        yield put(addToCartSuccess());
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onAddAddonItemsToCart',
                module: 'Checkout-Operations',
                message: 'onAddAddonItemsToCart - success',
                addonData,
                orderItemId,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            addonData,
            history,
            module: 'Checkout-Operations',
            function: 'onAddAddonItemsToCart',
            jsError: ex,
            message: 'onAddAddonItemsToCart Failed',
            orderItemId,
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
        yield put(addToCartFailed(ex));
    }
}

function* onLoadOrderDetails() {
    try {
        yield call(retrieveCart);
    } catch (ex) {
        mbpLogger.logError({
            module: 'Checkout-Operations',
            function: 'onLoadOrderDetails',
            jsError: ex,
            message: 'Failed to load order details',
        });
    }
}

let handleFailsDueToAccessTokenFoodAddToCart = false;
function* onAddToCartFood({
    recipients = [], // Set a default value to prevent object destructuring error args = { recipients, item, history };
    item,
    history,
    categoryId,
    partNumber,
    categoryName,
    categoryPath,
    enableMinicart,
    handleMiniCartModalClick,
    isMobile,
    passportBundle,
    retailPriceObj,
}) {
    const logOrderId = yield select(commonSelectors.getOrderId);
    const args = { recipients, item, history };
    const cartCount = yield select(commonSelectors.getCartCount);
    const flagState = yield select(getFlags);
    const isWrapupEnabledFlag = flagState['is-wrapup-enabled'];
    const whichCheckoutVariation = 'floral'; // CA brand support only floral ## flagState['which-checkout-variation'];
    const isInterstitialLoginEnabled = flagState['is-interstitial-login-enabled'];
    handleFailsDueToAccessTokenFoodAddToCart = false;

    try {
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === 'Y') {
            mbpLogger.logError({
                function: 'onAddToCartFood',
                module: 'Checkout-Operations',
                message: 'onAddToCartFood - Start',
                item,
                orderId: logOrderId,
            });
        }
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));
        const JWT_TOKEN = yield call(checkJWT);
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        // Add Gift List recipient
        let giftHistoryOrderItemId = '';
        let giftContactId = '';
        if (!isEmpty(item.giftList)) {
            giftContactId = item.giftList.contactId;
            // giftHistoryOrderItemId is used in 4.1
            giftHistoryOrderItemId = item.giftList.orderItemId;
            // xOrderAttrValues is used in 4.2
            const { xOrderAttrValues } = item.giftList;
            if (Array.isArray(item.attributes)) {
                Object.keys(xOrderAttrValues).forEach((key) => {
                    // Converting xOrderAttrValues object to item.attributes: [{ attributeName, attributeValue }, ...]
                    item.attributes.push({
                        attributeName: key,
                        attributeValue: xOrderAttrValues[key],
                    });
                });
            }
        }
        const addtoCartResp = yield call(postAddToCart, {
            recipients,
            item,
            JWT_TOKEN,
            giftHistoryOrderItemId,
        });
        const [orderId] = addtoCartResp.data.orderId;
        yield call(orderOperations.setOrderId, {
            data: addtoCartResp.data.orderId,
        });
        yield call(retrieveCart);
        if (orderId !== '' && enableMinicart) {
            yield call(handleMiniCartModalClick, true);
        }
        if (!isEmpty(item.giftList) && giftContactId) {
            if (Array.isArray(recipients)) {
                const {
                    address: {
                        company,
                        street: address1,
                        apt: address2,
                        location,
                        city,
                        state,
                        country,
                        zipcode: zipCode,
                    },
                    firstname: firstName,
                    lastname: lastName,
                } = item.giftList;

                const recipient = {
                    address1,
                    address2,
                    city,
                    country: country.id,
                    email: '',
                    firstName,
                    lastName,
                    locationType: location.name,
                    organizationName: company,
                    phone: '',
                    state: state.id,
                    zipCode,
                    isTelephoneMadatory: 'n',
                    isAddressVerified: 'Y',
                    dpvIndicator: 'Y',
                    nameOfLocation: '',
                    useAddressForAll: false,
                    checkSameAsDeliveryAddress: false,
                };
                recipients.push(recipient);
            } else {
                mbpLogger.logError({
                    function: 'onAddToCartFood',
                    module: 'Checkout-Operations',
                    message: 'onAddToCartFood - typeof recipients is not an Array',
                    data: { recipients, item },
                    orderId: logOrderId,
                });
            }

            // Always update Gift List recipient after adding to cart
            if (orderId !== '' && recipients && recipients.length && addtoCartResp.data?.orderItemId?.length) {
                const reqObj = {
                    wcEnv: {},
                    JWT_TOKEN,
                    orderItemId: addtoCartResp.data.orderItemId[0],
                    recipient: recipients[0],
                    entryId: null,
                    orderId,
                };
                yield call(addRecipient, reqObj);
            }

            // Clear Shop the Site data and set RETURN TO GIFT LIST link on mini cart
            // and remove Shop The Site floating banner
            yield call(setShopTheSiteLinkBackToGiftList, giftContactId);
        }
        const preCheckoutSavedAddress = yield select(getSavedAddress);
        const locationType = yield select(getLocationType);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        if (passportBundle?.enable) {
            yield put(passportActions.triggerPassportSubscription('product', passportBundle?.passportSku));
        }

        if (!enableMinicart && history) {
            if (locationType !== 'Funeral home' && isWrapupEnabledFlag && preCheckoutSavedAddress?.addressSource !== 'SCI') {
                yield call(history.push, '/wrap-up', { categoryId, partNumber });
            } else if (whichCheckoutVariation === 'floral' && cartCount > 1) {
                yield call(history.push, `/checkout/cart/${orderId || ''}`);
            } else if (whichCheckoutVariation === 'food' && isMobile) {
                yield call(history.push, `/checkout/cart/${orderId || ''}`);
            } else if (whichCheckoutVariation === 'food' && isInterstitialLoginEnabled) {
                yield put(triggerInterstitialLogin(history));
            } else {
                yield call(history.push, `/checkout/shipping/${orderId || ''}`);
            }
        }
        let orderItemId = '';
        if (addtoCartResp?.data?.orderItemId && typeof addtoCartResp.data.orderItemId === 'string') {
            orderItemId = addtoCartResp.data.orderItemId;
        } else if (addtoCartResp?.data?.orderItemId && Array.isArray(addtoCartResp.data.orderItemId)) {
            const arrOrderItemId = addtoCartResp.data.orderItemId;
            orderItemId = arrOrderItemId[0];
        }
        yield put(loadOrderItemDetails({
            orderItemId,
            orderItemDetails: item,
        }));
        // setting retail price on redux to use it on checkout flow
        // this approach is considering multicurrency
        // Examples of retailPriceObj:
        // CA -> { USD: 54.99, CAD: 69.99 }
        // 08F -> { USD: 45, CAD: 59.99, EUR: 39.99, GBP: 34.99 }
        // Other brand -> { USD: 54.99 }
        // TODO: Remove this once we will get retail price and sale price into cart api response to use this on checkout flow
        if (Object.keys(retailPriceObj).length) {
            const listOfRetailPrices = yield select(getProductRetailPrice);
            const retailPriceData = [...listOfRetailPrices, { [orderItemId]: retailPriceObj }];
            yield put(setProductRetailPrice(retailPriceData));
        }

        // Track Add-to-Cart
        const recips = yield select(commonSelectors.getRecipients);
        let addedItem = null;

        recips.forEach((recip) => {
            const { orderItems } = recip;

            orderItems.forEach((orderItem) => {
                if (orderItem.orderItemsId === orderItemId.toString()) {
                    addedItem = orderItem;
                }
            });
        });

        if (addedItem) {
            const { price, catalogEntryVO } = addedItem;
            const trackAddToCartPayload = {
                eventName: 'add_to_cart',
                eventCategory: 'Product Page', // TODO: find out from gabby
                eventAction: 'Add to Cart', // TODO: find out from gabby
                eventLabel: catalogEntryVO.parentSkuName,
                products: [
                    {
                        name: catalogEntryVO.parentSkuName,
                        partNumber: catalogEntryVO.skuCode,
                        baseCode: getBaseCode(catalogEntryVO.skuCode),
                        brand: catalogEntryVO.brandId,
                        sKUs: [{
                            partNumber: catalogEntryVO.skuCode,
                        }],
                        price,
                        quantity: args.item.quantity,
                        position: 1,
                        categoryId: categoryId || '',
                        categoryName: categoryName || '',
                        categoryPath: categoryPath || '',
                    },
                ],
            };

            yield put(tmDuck.actions.trackEvent(trackAddToCartPayload));
        }
        yield put(addToCartSuccess());
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === 'Y') {
            mbpLogger.logError({
                function: 'onAddToCartFood',
                module: 'Checkout-Operations',
                message: 'onAddToCartFood - success',
                item,
                orderId,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            recipients,
            item,
            history,
            module: 'Checkout-Operations',
            function: 'onAddToCartFood',
            jsError: ex,
            message: 'onAddToCartFood Failed',
            orderId: logOrderId,
        });
        const { response } = ex;
        if (response
            && response.status
            && ['401', '403'].includes(response.status.toString()) && !handleFailsDueToAccessTokenFoodAddToCart) {
            handleFailsDueToAccessTokenFoodAddToCart = true;
            if (getFlagEnableCheckoutMonitoring(flagState) === 'Y') {
                // log updated as ERROR to track on prod env
                mbpLogger.logError({
                    function: 'onAddToCartFood',
                    module: 'Checkout-Operations',
                    message: `onAddToCartFood Failed - Status code: ${response.status} proceeding to get access token`,
                    orderId: logOrderId,
                });
            }
            yield call(handleAuthorizationApiFailures, onAddToCartFood, args);
            handleFailsDueToAccessTokenFoodAddToCart = false;
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
        yield put(addToCartFailed(ex));
    }
}

function* watchAddToCartFood() {
    let action = yield take(addToCartFood().type);
    while (action !== END) {
        yield fork(onAddToCartFood, action.payload);
        action = yield take(addToCartFood().type);
    }
}

export function* calcShipping() {
    function* handleCalcShippingFailure(err) {
        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: 'error',
        }));

        // Retry Calculate Shipping
        // Commented out retry calcualte shipping code to check the impact of calculate shipping API calls
        /*
        const noOfAttempt = yield select(commonSelectors.getCalShippingAttempt);
        if (noOfAttempt < 1) {
            yield put(retryCalculateShippingActions.updateRetryCalculateShippingAttempt());
            yield call(calcShipping);
        } else {
            // Retry Calcualte Shipping Fails
            // Get Cart
            yield call(retrieveCart, 'payment');
            yield put(retryCalculateShippingActions.updateCalculateShippingApplied(false));

            // Log Error only if error status is 401, 403, 404, 500, 501, 503
            const arrErrorCodes = [401, 403, 404, 500, 501, 503];
            const errCode = err?.response?.status;

            if (errCode && arrErrorCodes.includes(errCode)) {
                yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
                yield call(errorOperations.getError, err, 'payment');
            }
        }
        */

        // Get Cart
        yield call(retrieveCart, 'payment');
        yield put(retryCalculateShippingActions.updateCalculateShippingApplied(false));

        // Log Error only if error status is 401, 403, 404, 500, 501, 503, 504
        const errCode = err?.response?.status;
        if (errCode && errorOperations.arrErrorCodes.includes(errCode)) {
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            yield call(errorOperations.getError, err, 'payment');
        }
    }

    const logOrderId = yield select(commonSelectors.getOrderId);

    try {
        // yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const orderId = yield select(commonSelectors.getOrderId);

        if (orderId) {
            const JWT_TOKEN = yield call(checkJWT);
            const flagState = yield select(commonSelectors.getFlags);

            // Calculate Estimate Shipping
            const isShippingEstimated = flagState['is-estimated-shipping-enabled'] || false;

            let calEstimatedShippping = 'false';
            if (isShippingEstimated) {
                calEstimatedShippping = 'true';
            }

            mbpLogger.logDebug({
                module: 'Checkout-Operations',
                function: 'calcShipping',
                message: 'Call - Calculate Shipping API',
                orderId: logOrderId,
            });

            // Call Calculate Shipping API
            const calculatOrd = yield call(calOrderShipping, {
                JWT_TOKEN,
                orderId,
                calEstimatedShippping,
            });

            mbpLogger.logDebug({
                module: 'Checkout-Operations',
                function: 'calcShipping',
                message: 'Response - Calculate Shipping API',
                orderId: logOrderId,
            });

            const { successFlag, result } = calculatOrd.data;
            if (successFlag === true || successFlag === 'true' || result === 'SUCCESS') {
                yield put(errorActions.clearErrors({
                    errType: 'payment',
                    field: 'error',
                }));
            }
        } else {
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                module: 'Checkout-Operations.js',
                function: 'calcShipping',
                message: 'Calculate Shipping - No OrderId',
                orderId: logOrderId,
                windowLocation,
            });
        }
    } catch (exp) {
        mbpLogger.logError({
            module: 'Checkout-Operations',
            function: 'calcShipping',
            jsError: exp,
            message: 'Error occured while calculating order',
            orderId: logOrderId,
        });
        yield call(handleCalcShippingFailure, exp);
    }
    yield put(calculateShippingComplete());
}

function* prepareRetreiveCart(page) {
    try {
        const isGiftCardApplied = yield select(commonSelectors.getIfGiftCardApplied);

        const flagState = yield select(getFlags);
        const isShippingEstimated = flagState['is-estimated-shipping-enabled'] || false;

        if (page === 'payment') {
            // Only call calculate shipping on payment page, if Gift Card is applied
            if (isGiftCardApplied) {
                yield put(retryCalculateShippingActions.clearCalculateShippingAttempt());
                yield fork(calcShipping);
                yield take(calculateShippingComplete().type);
                yield call(recalculateGiftCardTotalWorker);
            }
        } else if (
            page === 'review' || page === 'payment-review'
            || (page === 'checkout-food' && isShippingEstimated) // Cart page and shipping page in food brands
        ) {
            yield put(retryCalculateShippingActions.clearCalculateShippingAttempt());
            yield fork(calcShipping);
            yield take(calculateShippingComplete().type);
            yield put(passportActions.getDynamicPassportCharge());
            if (isGiftCardApplied) {
                yield call(recalculateGiftCardTotalWorker);
            }
        }
    } catch (ex) {
        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            function: 'prepareRetreiveCart',
            jsError: ex,
            message: 'Error occured while prepareRetreiveCart',
            orderId,
        });
    }
}

function* onApplyPromotion({ promoCode, membershipId, pageType }) {
    try {
        if (pageType === 'payment') {
            // Calculate Shipping before Apply Promo Code
            yield fork(calcShipping);
            yield take(calculateShippingComplete().type);
        }

        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.clearErrors({
            errType: 'promotion',
            field: 'error',
        }));

        const orderId = yield select(commonSelectors.getOrderId);

        if (orderId) {
            // Prepare
            const JWT_TOKEN = yield call(checkJWT);
            const selectedPaymentMethod = yield select(commonSelectors.getPaymentMethod);
            const currentPayment = selectedPaymentMethod && selectedPaymentMethod.id ? selectedPaymentMethod.id : '';

            // Apply Promo
            const res = yield call(doApplyPromotion, {
                JWT_TOKEN,
                orderId,
                promoCode,
                membershipId,
                currentPayment,

            });
            yield put(promotionActions.clearAllFieldPromotionForm());
            // Tracker
            const eventAction = 'PWA_Apply_Promo_Succeeded';
            const promoEventObj = {
                eventCategory: 'Checkout',
                eventAction,
                eventLabel: `${promoCode} has been applied`,
                eventName: 'feature_interaction',
                eventValue: res,
            };
            yield put(tmDuck.actions.trackEvent(promoEventObj));

            yield call(prepareRetreiveCart, pageType);

            yield call(retrieveCart);
        } else {
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                promoCode,
                membershipId,
                module: 'Checkout-Operations.js',
                function: 'onApplyPromotion',
                message: 'Apply Promotion - No Order Id',
                windowLocation,
            });
        }

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (exp) {
        const cookies = new Cookies();
        const originalCookie = cookies.get('banner');
        mbpLogger.logError({
            promoCode,
            membershipId,
            module: 'Checkout-Operations',
            function: 'onApplyPromotion',
            jsError: exp,
            message: 'onApplyPromotion Failed',
        });
        const eventAction = 'PWA_Apply_Promo_Failed';
        const promoEventObj = {
            eventCategory: 'Checkout',
            eventAction,
            eventLabel: exp,
            eventName: 'feature_interaction',
        };

        yield put(tmDuck.actions.trackEvent(promoEventObj));
        yield call(errorOperations.getError, exp, 'promotion');
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        if (originalCookie) {
            cookies.set('banner', originalCookie, {
                path: '/',
                encode: String,
            });
        }
    }
    yield put(applyPromotionCompleted());
}

function* onRemovePromotion({ promoCode, pageType }) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.clearErrors({
            errType: 'promotion',
            field: 'error',
        }));

        const orderId = yield select(commonSelectors.getOrderId);
        if (orderId) {
            const JWT_TOKEN = yield call(checkJWT);
            const res = yield call(doRemovePromotion, {
                JWT_TOKEN,
                orderId,
                promoCode,
            });

            const eventAction = 'PWA_Remove_Promo_Succeeded';
            const promoEventObj = {
                eventCategory: 'Checkout',
                eventAction,
                eventLabel: 'Promo Code Removed',
                eventName: 'feature_interaction',
                eventValue: res,
            };

            yield put(tmDuck.actions.trackEvent(promoEventObj));

            yield call(prepareRetreiveCart, pageType);

            yield call(retrieveCart);
        } else {
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                promoCode,
                module: 'Checkout-Operations.js',
                function: 'removePromotion',
                message: 'Remove Promotion - No OrderId',
                windowLocation,
            });
        }

        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (exp) {
        mbpLogger.logError({
            promoCode,
            module: 'Checkout-Operations.js',
            function: 'removePromotion',
            jsError: exp,
            message: 'removePromotion Failed',
        });

        const eventAction = 'PWA_Remove_Promo_Failed';
        const promoEventObj = {
            eventCategory: 'Checkout',
            eventAction,
            eventLabel: exp,
            eventName: 'feature_interaction',
        };

        yield put(tmDuck.actions.trackEvent(promoEventObj));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, exp, 'promotion');
    }
    yield put(removePromotionCompleted());
}

function* checkoutRouteHandler(history) {
    try {
        const allOrderItems = yield select(commonSelectors.getRecipients);
        const isPassportOnlyItem = yield select(commonSelectors.getIsPassportOnlyItemOnCart);
        const orderId = yield select(commonSelectors.getOrderId);
        const orderStatus = yield select(commonSelectors.getOrderStatus);

        if (history) {
            const pagePath = history.location.pathname;
            if (orderStatus === 'M') {
                history.push(`/checkout/order-confirmation/${orderId}`);
            } else if (pagePath.indexOf('/shipping') > 0) {
            // IF Passport only item in cart proceed to payment page
                if (isPassportOnlyItem) {
                    history.push(`/checkout/payment/${orderId}`);
                } else if (allOrderItems.length === 0) {
                    history.push(`/checkout/cart/${orderId}`);
                }
            } else if (pagePath.indexOf('/payment') > 0) {
                if (allOrderItems.length === 0) {
                    history.push(`/checkout/cart/${orderId}`);
                }
            } else if (pagePath.indexOf('/review-order') > 0) {
                const placeOrderStatus = yield select(commonSelectors.getPlaceOrderStatus);
                const selectedPaymentMethod = yield select(commonSelectors.getPaymentMethod);
                const paymentForm = yield select(commonSelectors.getPaymentInfo);

                if (selectedPaymentMethod.id === 'CreditCard' && !paymentForm.nameOnCreditCard && !placeOrderStatus && allOrderItems.length > 0) {
                    history.push(`/checkout/payment/${orderId}`);
                } else if (allOrderItems.length === 0) {
                    history.push(`/checkout/cart/${orderId}`);
                }
            } else {
                history.push(`/checkout/cart/${orderId}`);
            }
        }
    } catch (ex) {
        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            function: 'checkoutRouteHandler',
            jsError: ex,
            message: 'Error occured while handling checkout route',
            orderId,
        });
    }
}

function* processValidateShopCart(action) {
    const {
        orderId,
        history,
    } = action.data;
    // api for ValidateShopCart
    // const JWT_TOKEN = yield call(checkJWT);
    // yield call(postValidateShopCart, {
    //     JWT_TOKEN,
    //     orderId,
    // });
    yield call(history.push, `/checkout/shipping/${orderId}`);
}

// TODO remove in the future, now still doing testing. CO-1456
// function removeBannerCookie() {
//     const cookies = new Cookies();
//     cookies.remove('banner', { path: '/' });
// }

export function* onPersistAttribution({ bannerCode, lsid }) {
    try {
        const cookies = new Cookies();
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);

        const addressSource = yield select(getAddressSource);
        let sourceValue = '';
        if (addressSource === 'SCI') {
            sourceValue = 'sci';
        } else if (addressSource === 'smg') {
            sourceValue = 'smg';
        }

        const payload = {
            orderId: `${orderId}`,
            lsId: `${lsid}`,
        };

        mbpLogger.logInfo({
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations.js',
            function: 'onPersistAttribution',
            orderId,
            addressSource,
            bannerCookies: (cookies.get('banner')) ? cookies.get('banner') : '',
            message: 'Attribution properties',
        });

        const featureFlags = yield select(getFeatureFlags);

        const attributes = [];
        if (sourceValue && featureFlags['is-source-attribute-used-in-checkout']) {
            attributes.push(
                {
                    attributeName: 'source',
                    attributeValue: sourceValue,
                },
            );
        }
        if (attributes.length) {
            payload.attributes = attributes;
        }

        mbpLogger.logInfo({
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations.js',
            function: 'onPersistAttribution',
            bannerCode,
            payload,
            bannerCookie: (cookies.get('banner')) ? cookies.get('banner') : '',
            message: 'persistAttrResp payload',
        });

        const persistAttrResp = yield call(cartServices.recordAttributions, {
            jwtToken,
            cartId: orderId,
            bannerCode,
            attributes,
        });

        mbpLogger.logInfo({
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations.js',
            function: 'onPersistAttribution',
            persistAttrResp,
            message: 'persistAttrResp response',
        });

        yield put(saveBannerProcessCheckCompleted());
    } catch (ex) {
        yield put(callHandleCartFailure(ex));

        mbpLogger.logError({
            appName: process.env.npm_package_name,
            module: 'Checkout-Operations',
            function: 'onPersistAttribution',
            jsError: ex,
            message: 'Error occured while persisting banner information',
        });
        yield put(saveBannerProcessCheckCompleted());
    }
}

const checkApplePayAvailable = (merchantIdentifier) => new Promise((resolve) => {
    const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(merchantIdentifier);
    promise.then(resolve).catch(() => resolve(false));
});

function* workerApplePayAvailable() {
    mbpLogger.logDebug({
        module: 'Checkout-Operations',
        function: 'workerApplePayAvailable',
        message: 'initiate apple pay availability check',
    });
    try {
        const brand = yield select(commonSelectors.getBrand);
        const merchantIdentifier = mbpUtil.getEnv('APP_APPLE_PAY_MERCHANT_ID') || `${brand['apple-pay-merchant-id']}`;
        const applePayEnabled = yield call(
            checkApplePayAvailable,
            merchantIdentifier,
        );
        if (applePayEnabled) {
            yield put(activePaymentMethod('ApplePay'));
        } else {
            yield put(inActivePaymentMethod('ApplePay'));
        }
    } catch (ex) {
        yield put(inActivePaymentMethod('ApplePay'));
        mbpLogger.logError({
            function: 'workerApplePayAvailable',
            module: 'Checkout-Operations',
            jsError: ex,
            message: 'workerApplePayAvailable failed',
        });
    }
}

function* watchApplyPromotion() {
    let action = yield take(applyPromotion().type);
    mbpLogger.logDebug({
        action,
        module: 'Checkout-Operations',
        function: 'watchApplyPromotion',
        message: 'action applied in Promotion',
    });
    while (action !== END) {
        yield fork(onApplyPromotion, action.payload);
        action = yield take(applyPromotion().type);
    }
}

function* watchRemovePromotion() {
    let action = yield take(removePromotion().type);
    mbpLogger.logDebug({
        action,
        module: 'Checkout-Operations',
        function: 'watchRemovePromotion',
        message: 'action removed in Promotion',
    });

    while (action !== END) {
        yield fork(onRemovePromotion, action.payload);

        action = yield take(removePromotion().type);
    }
}

function* watchCalculateShipping() {
    let action = yield take(calculateShipping().type);
    mbpLogger.logDebug({
        action,
        module: 'Checkout-Operations',
        function: 'watchCalculateShipping',
        message: 'action calculate shipping',
    });
    while (action !== END) {
        yield fork(calcShipping, action);

        action = yield take(calculateShipping().type);
    }
}

function* watchAddToCart() {
    let action = yield take(addToCart().type);

    while (action !== END) {
        yield fork(onAddToCart, action.payload);

        action = yield take(addToCart().type);
    }
}

function* watchAddAddonsToCart() {
    let action = yield take(addAddonsToCart().type);
    while (action !== END) {
        yield fork(onAddAddonsToCart, action.payload);
        action = yield take(addAddonsToCart().type);
    }
}

function* watchAddWrapUpsToCart() {
    let action = yield take(addWrapUpsToCart().type);
    while (action !== END) {
        yield fork(onAddWrapupsToCart, action.payload);
        action = yield take(addWrapUpsToCart().type);
    }
}

function* watchAddAddonsAndWrapUpsToCart() {
    let action = yield take(addAddonsAndWrapupToCart().type);
    while (action !== END) {
        yield fork(onAddAddonsAndWrapupToCart, action.payload);
        action = yield take(addAddonsAndWrapupToCart().type);
    }
}

function* watchPersistAttribution() {
    let action = yield take(saveBannerToOrder().type);
    while (action !== END) {
        yield fork(onPersistAttribution, action.payload);
        action = yield take(saveBannerToOrder().type);
    }
}

function* watchCheckApplePayAvailability() {
    yield takeLatest(checkApplePayEnabled().type, workerApplePayAvailable);
}

function* processUpdateBillingAddress(action) {
    try {
        // show loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        // prepare save billing address
        const orderId = yield select(commonSelectors.getOrderId);
        if (orderId) {
            const JWT_TOKEN = yield call(checkJWT);
            const { data, page } = action.payload;
            const payload = {
                address1: data.address1,
                address2: data.address2,
                address3: data.address3,
                addressId: data.addressId,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: removeMaskForPhoneNumber(data.phone),
                city: data.city,
                state: data.state,
                country: data.country,
                zipCode: data.zipCode,
                email: data.email,
                confirmEmail: data.email,
                mobilePhone: removeMaskForPhoneNumber(data.mobilePhone),
            };
            // save billing address
            const updateAddressResponse = yield call(doUpdateBillingAddress, {
                JWT_TOKEN,
                orderId,
                billingAddress: payload,
            });

            // success
            if (updateAddressResponse.data.successFlag) {
                yield put(updateBillingAddressSuccess());
                yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
                if (page !== 'payment-review' && page !== 'expressCheckout') {
                    action.payload.history.push(`/checkout/review-order/${orderId}`);
                }
            } else {
                yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

                // load error
                yield call(errorOperations.getError, updateAddressResponse, 'payment');

                mbpLogger.logError({
                    updateAddressResponse,
                    module: 'Checkout-Operations.js',
                    function: 'processUpdateBillingAddress',
                    message: 'Error billing address form, information is missing',
                });
                yield put(updateBillingAddressFailed());
            }
        } else {
            // log error
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                action,
                module: 'Checkout-Operations.js',
                function: 'processUpdateBillingAddress',
                message: 'processUpdateBillingAddress - no OrderId',
                windowLocation,
            });
        }
    } catch (ex) {
        // turn off loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        // load error
        yield call(errorOperations.getError, ex, 'payment');

        // log error
        mbpLogger.logError({
            action,
            module: 'Checkout-Operations.js',
            function: 'processUpdateBillingAddress',
            jsError: ex,
            message: 'Error occured while processing billing address form',
        });

        yield put(updateBillingAddressFailed());
    }
}

function getItemIndex(orderItemCount, itemIndex) {
    if (itemIndex < 1) {
        return 0;
    }
    if (itemIndex > orderItemCount) {
        return orderItemCount - 1;
    }
    return parseInt(itemIndex, 10) - 1;
}

function* workerProcessShippingPage(action) {
    const {
        history,
    } = action.data;
    yield put(showLoadingMessageActions.updateShowLoadingMessage());
    yield call(retrieveCart);

    const orderItemCount = yield select(commonSelectors.getNoOfOrderItems);
    const { search } = history.location;
    const queryParams = qs.parse(search.replace('?', ''));

    if (queryParams && queryParams.edit) {
        const itemIndex = yield call(getItemIndex, orderItemCount, queryParams.edit);
        yield put(setWorkingRecipient(itemIndex));
    } else {
        yield put(setWorkingRecipient(0));
    }

    if (orderItemCount > 0) {
        const [orderItemToEdit] = yield select(commonSelectors.getCurrentEditingRecipient);
        if (orderItemToEdit?.recipientAddress) {
            const [orderItems] = orderItemToEdit?.orderItems || [];
            const isInternational = yield call(checkIsInternational, orderItems);
            const recipientFormData = recipientFormFields(orderItemToEdit.recipientAddress, orderItems);
            const pdpZipCode = yield select(commonSelectors.getUserSubmittedProductFilterZipcode);
            // Use zipcode entered in pdp page if zipcode not returned
            recipientFormData.zipCode = recipientFormData.zipCode || pdpZipCode;

            // set fields values for internation products
            if (isInternational) {
                const countryField = yield call(getInternationalCountryField, orderItems);
                recipientFormData.country = countryField;
                recipientFormData.state = 'OS';
                recipientFormData.zipCode = '00000';
            } else {
                recipientFormData.country = yield call(getCountryField, orderItemToEdit.recipientAddress?.country);
            }

            if (orderItemToEdit.recipientAddress?.firstName) {
                const { firstName, lastName, phoneNo } = orderItemToEdit.recipientAddress;
                if (firstName && lastName && phoneNo) {
                    recipientFormData.isFormValid = true;
                } else {
                    recipientFormData.isFormValid = false;
                }

                yield put(updateFormAddressFromPreFill(recipientFormData));
            } else {
                recipientFormData.isFormValid = false;
                yield put(updateFormAddressFromPreFill(recipientFormData));
            }

            const flagState = yield select(getFlags);
            const cIEnabled = flagState['is-card-isle-enabled'] || false;
            if (cIEnabled) {
                // Review the product has card isle available.
                yield call(loadCardIsleAvailable);
            }

            // Load CityList and State from Zipcode
            if (recipientFormData?.zipCode && !isInternational) {
                yield put(triggerStateCityData(recipientFormData.zipCode));
            }

            if (orderItemToEdit?.orderItems?.length > 0 && orderItemToEdit.orderItems[0]?.field2) {
                const giftMessageData = orderItemToEdit.orderItems[0].field2;
                yield call(formatFullGifMessage, giftMessageData);
            }
        }
    }

    yield call(checkoutRouteHandler, history);
    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* workerProcessPaymentPage(action) {
    const {
        history,
    } = action.data;
    const userType = yield select(getUserType);

    yield put(showLoadingMessageActions.updateShowLoadingMessage());
    if (typeof window !== 'undefined' && !window.location.host.includes('localhost') && window.ApplePaySession) {
        yield call(workerApplePayAvailable);
    }

    if (userType === 'R') {
        // Payment Page Dependent APIs
        yield put(loadWallet());
    }

    yield call(prepareRetreiveCart, 'payment');

    yield call(retrieveCart);

    const recipients = yield select(commonSelectors.getRecipients);
    if (recipients.length > 0) {
        yield call(onPaymentPageProcessing, action);
    }

    yield call(checkoutRouteHandler, history);

    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* workerProcessReviewOrderPage(action) {
    const {
        history,
    } = action.data;
    yield put(showLoadingMessageActions.updateShowLoadingMessage());
    yield call(prepareRetreiveCart, 'review');
    yield call(retrieveCart);
    yield call(checkoutRouteHandler, history);

    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

//  When the payment and review page are in only one page.
function* workerProcessPaymentReviewPage(action) {
    const {
        history,
    } = action.data;
    const userType = yield select(getUserType);

    yield put(showLoadingMessageActions.updateShowLoadingMessage());
    if (typeof window !== 'undefined' && !window.location.host.includes('localhost') && window.ApplePaySession) {
        yield call(workerApplePayAvailable);
    }
    if (userType === 'R') {
        // Payment Page Dependent APIs
        yield put(loadWallet());
    }

    // Need preparte cart as review for calculate shipping.
    yield call(prepareRetreiveCart, 'review');

    yield call(retrieveCart);

    const recipients = yield select(commonSelectors.getRecipients);

    if (recipients.length > 0) {
        yield call(onPaymentPageProcessing, action);
    }

    yield call(checkoutRouteHandler, history);

    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* workerConfirmationPage(action) {
    const {
        history,
    } = action.data;

    mbpLogger.logDebug({
        module: 'mbp-pwa-ui',
        function: 'workerConfirmationPage',
        action,
    });

    yield call(loadPaymentDetailsWorker);
    yield call(retrieveCart);
    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

    const orderStatus = yield select(commonSelectors.getOrderStatus);
    const orderId = yield select(commonSelectors.getOrderId);

    if (orderStatus === 'M') {
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: 'error',
        }));
    }

    // If order is pending redirect to cart page
    if (orderStatus === 'P') {
        const brand = yield select(commonSelectors.getBrand);
        if (brand?.code !== 'CAN') {
            history.push(`/checkout/cart/${orderId}`);
        }
    }
}

// checkout food flow cart and shipping page
function* workerProcessCheckoutFoodPage(action) {
    const {
        history,
        page,
    } = action.data;

    const userType = yield select(getUserType);

    yield put(showLoadingMessageActions.updateShowLoadingMessage());

    yield call(prepareRetreiveCart, 'checkout-food');

    if (page === 'shipping-food' && userType === 'R') {
        yield put(loadAddressBook());
    }

    yield call(retrieveCart);

    yield call(checkoutRouteHandler, history);

    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* workerAppController(action) {
    const { data: { page, history } } = action;

    let orderStatus = yield select(commonSelectors.getOrderStatus);
    if (orderStatus === null) {
        yield call(retrieveCart);
        orderStatus = yield select(commonSelectors.getOrderStatus);
    }
    if (orderStatus === 'M' && page !== 'confirmation-order') {
        yield call(checkoutRouteHandler, history);
    } else if (page === 'shipping') {
        yield fork(workerProcessShippingPage, action);
    } else if (page === 'payment') {
        yield fork(workerProcessPaymentPage, action);
    } else if (page === 'review-order') {
        yield fork(workerProcessReviewOrderPage, action);
    } else if (page === 'payment-review') {
        yield fork(workerProcessPaymentReviewPage, action);
    } else if (page === 'confirmation-order') {
        yield fork(workerConfirmationPage, action);
    } else if (page === 'cart-food' || page === 'shipping-food') {
        yield fork(workerProcessCheckoutFoodPage, action);
    }
}

function* watcherAppPageProcess() {
    let action = yield take(appProcessPage().type);
    while (action !== END) {
        yield fork(workerAppController, action);
        action = yield take(appProcessPage().type);
    }
}

function* watcherBillingPageProcess() {
    yield takeLatest(updateBillingAddress().type, processUpdateBillingAddress);
}

function* watcherValidateShopCart() {
    yield takeLatest(validateShopCart().type, processValidateShopCart);
}

const watchers = [
    fork(watchAddToCart),
    fork(watchApplyPromotion),
    fork(watchRemovePromotion),
    fork(watchCalculateShipping),
    fork(watchPersistAttribution),
    fork(watchCheckApplePayAvailability),
    fork(watcherAppPageProcess),
    fork(watcherBillingPageProcess),
    fork(watchAddAddonsToCart),
    fork(watchAddWrapUpsToCart),
    fork(watchAddAddonsAndWrapUpsToCart),
    fork(watchAddToCartFood),
    fork(watcherValidateShopCart),
];

export {
    watchers,
    onLoadOrderDetails,
    // Actions
    addToCart,
    postAddToCart,
    addToCartFailed,
    prepareRetreiveCart,
    checkoutRouteHandler,
    onApplyPromotion,
    processUpdateBillingAddress,
};
export default {};
