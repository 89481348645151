/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    take, takeEvery, fork, call, select, put, takeLatest,
} from 'redux-saga/effects';
import Cookies  from 'universal-cookie';
import mbpLogger from 'mbp-logger';
import { format } from 'date-fns';
import CryptoJS from 'crypto-js';
import mbpUtil from 'mbp-api-util';
import orderClient from '../../../../../apis/checkout-apis/orderClient';

import memberDucks from '../../../Member/ducks';
import * as checkoutActions from '../Checkout/Checkout-Actions';
import {
    updateBillingFormStatus,
    billingClearUpdateFields,
    triggerStateCityData,
    updateBillingForm,
} from './ducks/BillingAddressForm/BillingAddressForm-Actions';
import { paymentFormActions } from './ducks/PaymentForm';
import { errorActions, errorOperations }  from '../Common/ducks/Error';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';
import { setPassportStatus } from './ducks/Passport/Passport-Operations';
import { clearPaypalState } from './plugins/Paypal/Paypal-Actions';
import { retrieveCart } from '../Cart/Cart-Operations';
import {
    updateCompletedPaymentDetails,
    processPaymentPage,
    placeOrder,
    placeOrderComplete,
    updateShowOrderConfirmation,
    loadCompletedPaymentDetails,
    applyGiftCard,
    applyGiftCardProccessCompleted,
    removeGiftCard,
    removeGiftCardProccessCompleted,
    validateApiDesktop,
    recordPaymentMethodType,
    recordPaymentMethodTypeCompleted,
    processNBCUMovie,
    applePayOrderSuccess,
    applePayOrderValidateMerchantSuccess,
}  from './Payment-Actions';
import { triggerValidateOrder, triggerValidateOrderCompleted } from '../Recipient/Recipient-Actions';
import { paymentReadinessActions } from './ducks/PaymentReadiness';
import * as commonSelectors from '../Common/Common-Selectors';
import { getBrand } from '../../../App/ducks/Brand/Brand-Selectors';
import { processNBCUMovieAPI } from '../../../../../apis/order-apis/processNBCUMovie';
import {  processCGCGreetingCardAPI } from '../../../../../apis/order-apis/processCGCGreetingCardApi';
import { getFeatureFlags } from '../../../App/ducks/Config/Config-Selectors';
import { resetCGCCardData } from '../../../CGC/CGC-Actions';
import { placeCurrencyOrder } from '../../../../../apis/order-apis/processCurrencyCheckoutApi';
import { getCurrencyName, getNonceKey, setNonceKey } from '../../../Member/ducks/Auth/helper/helper';

const {
    auth: {
        authOperations,
    },
    addressBook: {
        addressBookOperations: {
            workers: {
                workerLoadBillingAddress,
            },
        },
    },
    common: {
        commonSelectors: {
            getIsAuthenticatedStatus,
        },
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const getStateCityData = ({
    jwtToken, zipCode,
}) => orderClient.getCityStateFromZip(
    {},
    jwtToken,
    zipCode,
);

const applyGCOnOrder = ({
    JWT_TOKEN,
    orderId,
    giftcardNumber,
    giftcardPin,

}) => orderClient.applyGiftCard({}, JWT_TOKEN, orderId, giftcardNumber, giftcardPin, '', '', '', '');

const removeGCOnOrder = ({
    JWT_TOKEN,
    orderId,
    giftcardNumber,
    giftCardId,

}) => orderClient.removeGiftCard({}, JWT_TOKEN, orderId, giftcardNumber, giftCardId);

const validateOrderAPI = (jwtToken, orderId) => orderClient.validateOrder({}, jwtToken, orderId);

const getPaymentDetails = (configObj) => orderClient.getPaymentDetails(
    {},
    configObj.jwtToken,
    configObj.orderId,
);

const setRecordPaymentMethod = ({
    JWT_TOKEN,
    orderId,
    paymentMethodType,
}) => orderClient.recordPaymentMethod({}, JWT_TOKEN, orderId, paymentMethodType);

const processNBCUMovieCall = ({
    JWT_TOKEN,
    payload,
    params,
    headers,
}) => processNBCUMovieAPI({}, JWT_TOKEN, payload, params, headers);

const parseBannerCookie = () => {
    const cookies = new Cookies();
    const bCookie = cookies.get('banner');

    if (bCookie) {
        const bAttr = JSON.parse(JSON.stringify(bCookie));

        mbpLogger.logInfo({
            bCookie,
            function: 'parseBannerCookie',
            appName: process.env.npm_package_name || 'growth',
            module: 'growth-checkout :: checkout',
            message: 'Parsed banner cookie for order upload',
        });

        return bAttr;
    }
    return '';
};

const processCurrencyAPIEndPoint = async (data, jwt) => {
    const response = await placeCurrencyOrder(data, jwt);
    return response;
};

const processCelebrationsGreetingCard = async (data) => {
    try {
        const response = await processCGCGreetingCardAPI(data);
        if (!response.status === 200) {
            mbpLogger.logError({
                module: 'mbp-checkout',
                function: 'processCelebrationsGreetingCard',
                message: `card-placed call failed with response ${response.status}`,
                data,
                response,
            });
        }
        return response;
    } catch (er) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'processCelebrationsGreetingCard',
            message: 'card-placed call failed with error',
            data,
            error: er,
        });
        return er;
    }
};

function* persistBanner() {
    const logOrderId = yield select(commonSelectors.getOrderId);
    try {
        mbpLogger.logInfo({
            function: 'persistBanner',
            appName: process.env.npm_package_name || 'growth',
            module: 'growth-pwa-ui',
            message: 'persistBanner - Start',
            orderId: logOrderId,
        });

        const bannerObj = parseBannerCookie();
        if (bannerObj !== '' && bannerObj.c !== 'undefined') {
            mbpLogger.logInfo({
                function: 'persistBanner',
                appName: process.env.npm_package_name || 'growth',
                module: 'growth-pwa-ui',
                bannerObj,
                orderId: logOrderId,
                message: 'call saveBannerToOrder action',
            });
            yield put(checkoutActions.saveBannerToOrder(bannerObj.c, bannerObj.l)); // c = bannerCode, l= lsid
            yield take(checkoutActions.saveBannerProcessCheckCompleted().type);
        }
        mbpLogger.logInfo({
            function: 'persistBanner',
            appName: process.env.npm_package_name || 'growth',
            module: 'growth-pwa-ui',
            message: 'persistBanner - End',
            orderId: logOrderId,
        });
    } catch (ex) {
        mbpLogger.logInfo({
            appName: process.env.npm_package_name || 'growth',
            module: 'growth-pwa-ui',
            function: 'persistBanner',
            jsError: ex,
            message: 'persistBanner Failed - Error occured while parsing banner',
            orderId: logOrderId,
        });
    }
}

const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

const removeMaskForAccountNumber = (number) => {
    const strAccountNumber = number.toString();
    if (strAccountNumber.indexOf('-') >= 0) {
        return strAccountNumber.split('-').join('');
    }
    return strAccountNumber;
};

const emptyBilling = () => ({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    address3: '',
    addressId: '',
    city: '',
    email: '',
    confirmEmail: '',
    country: '',
    locationType: '',
    organizationName: '',
    phone: '',
    mobilePhone: '',
    state: '',
    zipCode: '',
});

function* buildConfigObj({
    disablePaymentFields, paypalPayment, visaCheckoutPayment, params, googlePayment,
}) {
    let configObj = {};
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(commonSelectors.getFlags);
    try {
        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                module: 'mbp-checkout',
                message: 'buildConfigObj - Start',
                orderId: logOrderId,
            });
        }

        const paymentMethod = yield select(commonSelectors.getPaymentMethod);
        const jwtToken = yield call(checkJWT);
        const isSmsOptIn = yield select(commonSelectors.getSMSOptIn);
        const brand = yield select(getBrand);
        configObj = {
            orderId: yield select(commonSelectors.getOrderId),
            jwtToken,
            billingInfo: yield select(commonSelectors.getBillingInfo),
            orderTotal: yield select(commonSelectors.getAmount),
            user: yield select(commonSelectors.getSubscription),
        };

        // Add Passport
        if (yield select(commonSelectors.getPassportSubscriptionStatus)) {
            configObj.orderTotal.passportTermsAndConditionChecked = 'Y';
        }

        // Unmask Phone Number
        if (configObj.billingInfo.phone) {
            configObj.billingInfo.phone = removeMaskForPhoneNumber(configObj.billingInfo.phone);
        }

        // Unmask Mobile Phone
        if (configObj.billingInfo.mobilePhone) {
            configObj.billingInfo.mobilePhone = removeMaskForPhoneNumber(configObj.billingInfo.mobilePhone);
        }

        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                module: 'mbp-checkout',
                message: `Payment Method - ${paymentMethod.id}`,
                orderId: logOrderId,
            });
        }

        // Add params to configObj
        configObj.params = params || {};
        if (disablePaymentFields === false) {
            const paymentInfo = yield select(commonSelectors.getPaymentInfo);
            configObj.paymentInfo = { ...paymentInfo };

            if (paymentMethod.id === 'PayPal') {
                configObj.paymentInfo = {};
                // Set payment method accepted by place order
                configObj.paymentInfo.paymentMethod = 'paypal';
                const tokenizedPaypalCard = yield select(commonSelectors.getPaypalTokenizedCard);
                if (paypalPayment.isTokenizedPaypalCard && tokenizedPaypalCard) {
                    configObj.paymentInfo.accountNumber = tokenizedPaypalCard;
                    configObj.paymentInfo.isCardTokenized = true;
                } else if (paypalPayment.nonce) {
                    if (brand?.code === 'CAN') {
                        configObj.paymentInfo.accountNumber = CryptoJS.AES.encrypt(paypalPayment.nonce, mbpUtil.getEnv('APP_CRYPTO_KEY')).toString();
                    } else {
                        configObj.paymentInfo.accountNumber = paypalPayment.nonce;
                    }
                }
            } else if (paymentMethod.id === 'GooglePay') {
                configObj.paymentInfo = {};
                configObj.paymentInfo.paymentMethod = 'GooglePay';
                configObj.paymentInfo.accountNumber = googlePayment.nonce;
            } else if (paymentMethod.id === 'ChasePay') {
                // Set payment method accepted by place order
                configObj.paymentInfo.paymentMethod = 'CHASECheckout';
                const emptyBillingAddress = yield call(emptyBilling);
                if (isSmsOptIn) {
                    emptyBillingAddress.mobilePhone = configObj.billingInfo.mobilePhone;
                }
                configObj.billingInfo = emptyBillingAddress;
            } else if (paymentMethod.id === 'VisaCheckout' && visaCheckoutPayment === true) {
                // Set payment method accepted by place order
                configObj.paymentInfo.paymentMethod = 'visacheckout';
                const emptyBillingAddress = yield call(emptyBilling);
                if (isSmsOptIn) {
                    emptyBillingAddress.mobilePhone = configObj.billingInfo.mobilePhone;
                }
                configObj.billingInfo = emptyBillingAddress;
            } else {
                // log updated as ERROR to track on prod env
                if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
                    mbpLogger.logError({
                        function: 'buildConfigObj',
                        module: 'mbp-checkout',
                        message: 'Payment Method - Creditcard',
                        orderId: logOrderId,
                    });
                }

                configObj.paymentInfo.isCardTokenized = false;
                if (paymentInfo && paymentInfo.isFromWallet) {
                    configObj.paymentInfo.isCardTokenized = true;
                }

                configObj.paymentInfo.paymentMethod = 'creditcard';
                configObj.paymentInfo.accountNumber = removeMaskForAccountNumber(configObj.paymentInfo.accountNumber);
                const amount = yield select(commonSelectors.getAmount);
                configObj.paymentInfo.amount = yield amount.orderTotal;

                if (!configObj.paymentInfo.isCardTokenized) {
                    configObj.paymentInfo.lastFourDigits = configObj.paymentInfo.accountNumber.substr(configObj.paymentInfo.accountNumber.length - 4);
                }

                // If the reward points have information, send it in the payment information
                const rewardPointsInfo = yield select(commonSelectors.getRewardPointsInfo);
                if (rewardPointsInfo
                    && rewardPointsInfo.selected
                    && rewardPointsInfo.selected.amount
                    && rewardPointsInfo.selected.points
                ) {
                    configObj.paymentInfo.rewards = rewardPointsInfo.selected;
                }
                if (!flagState['is-internationalization-enabled'] && !brand?.code === 'CAN') {
                    if (configObj.paymentInfo
                        && configObj.paymentInfo.expirationMonth
                        && parseInt(configObj.paymentInfo.expirationMonth, 10) < 10) {
                        const twoDigitMonth = `0${parseInt(configObj.paymentInfo.expirationMonth, 10)}`;
                        configObj.paymentInfo.expirationMonth = twoDigitMonth;
                    }
                    if (configObj.paymentInfo
                        && configObj.paymentInfo.expirationYear
                        && parseInt(configObj.paymentInfo.expirationYear, 10) < 100) {
                        const fourDigitYear = `20${configObj.paymentInfo.expirationYear}`;
                        configObj.paymentInfo.expirationYear = fourDigitYear;
                    }
                }
            }
        } else {
            // log updated as ERROR to track on prod env
            if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
                mbpLogger.logError({
                    function: 'buildConfigObj',
                    module: 'mbp-checkout',
                    message: 'Gift Message Covers Order Total',
                    orderId: logOrderId,
                });
            }
            // Gift Message Covers Order Total
            configObj.paymentInfo = {
                paymentMethod: 'GiftCard',
            };
        }
        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'buildConfigObj',
                module: 'mbp-checkout',
                message: 'buildConfigObj - End - payload config obj prepared',
                orderId: logOrderId,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'buildConfigObj',
            jsError: ex,
            message: 'buildConfigObj Failed',
            orderId: logOrderId,
        });
    }
    return configObj;
}

function* loadPaymentDetailsWorker() {
    try {
        const jwtToken = yield call(checkJWT);
        const configObj = {
            orderId: yield select(commonSelectors.getOrderId),
            jwtToken,
        };
        const completedPaymentDetails = yield call(getPaymentDetails, configObj);

        mbpLogger.logDebug({
            module: 'mbp-pwa-ui',
            function: 'loadPaymentDetailsWorker',
            completedPaymentDetails,
            configObj,
        });

        if (Array.isArray(completedPaymentDetails)) {
            yield put(updateCompletedPaymentDetails(completedPaymentDetails[0]));
        } else {
            yield put(updateCompletedPaymentDetails(completedPaymentDetails));
        }
    } catch (ex) {
        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            function: 'onPlaceOrder',
            module: 'mbp-checkout',
            message: 'Failed onPlaceOrder',
            jsError: ex,
            orderId,
        });
    }
}

function* onProcessNBCUMovie(action) {
    mbpLogger.logDebug({
        function: 'onProcessNBCUMovie',
        module: 'mbp-checkout',
        message: 'onProcessNBCUMovie - Start',
    });
    const JWT_TOKEN = yield call(checkJWT);
    let history;
    let isFromApplePay;
    if (action?.payload?.isFromApplePay && action?.payload?.history) {
        // if the order is placed with ApplePay the normal placeOrder saga does not run
        // in that case, this saga is triggered by a watcher instead of just called in the place order saga body
        ({ isFromApplePay } = action.payload.isFromApplePay);
        ({ history } = action.payload.history);
    }
    if (isFromApplePay) {
        // if from apple pay need to get a picture of the cart to make sure billing data is there
        yield call(retrieveCart);
    }
    const merchantOrderId = yield select(commonSelectors.getOrderId);
    const movieItems = yield select(commonSelectors.getSelectedMovie);
    const brand = yield select(getBrand);
    const merchantCode = brand?.domain;
    const payload = {};
    try {
        const billingInfo = yield select(commonSelectors.getBilling);
        const recipient = yield select(commonSelectors.getRecipientAddress);
        // if all addresses are identical they are stored in one recipientAddress object
        // however if they are different they are stored as a key for each order item
        // The below if statement accounts for both these possibilities
        payload.data = movieItems.map((movieItem) => {
            let deliveryDate;
            let sku;
            let recipientEmail;
            let recipientFirstName;
            let recipientLastName;
            let giftMessage;
            if (!movieItem.orderItems) {
                deliveryDate = format(new Date(movieItem.formattedPromisedAvailableTime), 'yyyy-MM-dd');
                // below regex removes all brand logic from a skuCode and just sends the sku
                // 1001-I-4343B -> 4343B
                sku = movieItem.catalogEntryVO.skuCode.match(/[^-_]*$/)[0];
                const recipientInfo = recipient?.[0]?.recipientAddress;
                recipientFirstName = recipientInfo.firstName;
                recipientLastName = recipientInfo.lastName;
                recipientEmail = recipientInfo.email;
                giftMessage = movieItem.field2;
            } else if (movieItem.orderItems) {
                const orderItem = movieItem.orderItems[0];
                deliveryDate = format(new Date(orderItem.formattedPromisedAvailableTime), 'yyyy-MM-dd');
                sku = orderItem.catalogEntryVO.skuCode.match(/[^-_]*$/)[0];
                recipientFirstName = movieItem.recipientAddress.firstName;
                recipientLastName = movieItem.recipientAddress.lastName;
                recipientEmail = movieItem.recipientAddress.email;
                giftMessage = orderItem.field2;
            }
            const senderEmail = billingInfo.email;
            const senderFirstName = billingInfo.firstName;
            const senderLastName = billingInfo.lastName;
            const movieItemForPayload = {
                sender: {
                    email: senderEmail,
                    firstName: senderFirstName,
                    lastName: senderLastName,
                },
                recipient: {
                    email: recipientEmail,
                    firstName: recipientFirstName,
                    lastName: recipientLastName,
                },
                meta: {
                    deliveryDate,
                    merchantOrderId,
                },
                nbcuMovieTicket: {
                    movieSkuCode: sku,
                },
            };
            if (giftMessage !== ' From: ') {
                movieItemForPayload.giftMessage = giftMessage;
            }
            return movieItemForPayload;
        });
        const params = {
            merchantCode,
        };
        const headers = {
        };
        const response = yield call(processNBCUMovieCall, {
            JWT_TOKEN, payload, params, headers,
        });
        if (isFromApplePay && history) {
        // normally this push would happen at the end of the placeorder saga but since that does not run
        // if the order was placed with apple pay, the push happens here
            history.push(`/checkout/order-confirmation/${merchantOrderId}`, { sharePageLink: response?.data?.data?.sharePageLink });
        }
        return response?.data?.data?.sharePageLink;
    } catch (ex) {
        mbpLogger.logError({
            function: 'onProcessNBCUMovie',
            module: 'mbp-checkout',
            message: 'Failed onProcessNBCUMovie',
            jsError: ex,
            orderId: merchantOrderId,
        });
        if (isFromApplePay && history) {
            // normally this push would happen at the end of the placeorder saga but since that does not run
            // if the order was placed with apple pay, the push happens here
            history.push(`/checkout/order-confirmation/${merchantOrderId}`);
        }
        return null;
    }
}

function* onPlaceOrder(action) {
    const logOrderId = yield select(commonSelectors.getOrderId);
    const flagState = yield select(commonSelectors.getFlags);
    try {
        const {
            history, source,
            disablePaymentFields, params,
            paypalPayment, visaCheckoutPayment,
            googlePayment,
        } = action.payload;
        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                module: 'mbp-checkout',
                message: 'onPlaceOrder | START',
                orderId: logOrderId,
            });
        }

        // If the banner exist in the cookie.
        yield call(persistBanner);

        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        yield put(errorActions.clearErrors({
            errType: 'paymentClearAllFields',
            field: 'error',
        }));

        const configObj = yield call(buildConfigObj, {
            disablePaymentFields,
            paypalPayment,
            visaCheckoutPayment,
            params,
            googlePayment,
        });

        if (source && configObj.params) {
            configObj.params.sourceApp = source;
        }
        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                module: 'mbp-checkout',
                message: 'call - placeOrder API',
                orderId: logOrderId,
            });
        }
        const brand = yield select(getBrand);
        // Place Order API Call
        let placeOrderResponse;

        if (brand?.code === 'CAN') {
            const cartOrderDetails = yield select(commonSelectors.getCartOrderDetails);
            const configOptions = {
                currency: getCurrencyName(),
                paymentMethodNonce: CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(getNonceKey()), mbpUtil.getEnv('APP_CRYPTO_KEY')).toString(),
                billingAddress: configObj.billingInfo,
                payment: configObj.paymentInfo,
                order: configObj.orderTotal,
                orderId: configObj.orderId,
                user: configObj.user,
                orderDetails: cartOrderDetails,
                sourceApp: 'Growthbrand',
            };
            placeOrderResponse = yield call(processCurrencyAPIEndPoint, configOptions, configObj.jwtToken);
        } else {
            placeOrderResponse = yield call(
                orderClient.placeOrder,
                {},
                configObj.jwtToken,
                configObj.orderId,
                configObj.params,
                configObj.billingInfo,
                configObj.paymentInfo,
                configObj.orderTotal,
                configObj.user,
            );
        }

        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onPlaceOrder',
                module: 'growth-pwa-ui',
                message: 'placeOrder API Response',
                orderId: logOrderId,
            });
        }
        if (placeOrderResponse?.data?.successFlag === true || placeOrderResponse?.data?.successFlag === 'true') {
            if (source === 'PWA-DESKTOP' || source === 'PWA' || source === 'SPC') {
                yield put(paymentFormActions.paymentClearUpdateFields());
                yield put(billingClearUpdateFields());
                yield put(clearPaypalState());
            }
            // reset payment nonce
            if (brand?.code === 'CAN') {
                setNonceKey('');
            }
            // Get Cart
            yield call(retrieveCart);

            const orderStatus = yield select(commonSelectors.getOrderStatus);
            const movieItemInCart = yield select(commonSelectors.getSelectedMovie);
            if (orderStatus === 'M') {
                yield put(placeOrderComplete());
                yield put(updateShowOrderConfirmation());
                const flags = yield select(getFeatureFlags);

                if (history && (source === 'PWA-DESKTOP' || source === 'PWA')) {
                    let sharePageLink;
                    if (movieItemInCart) {
                        sharePageLink = yield call(onProcessNBCUMovie);
                    }
                    history.push(`/checkout/order-confirmation/${configObj.orderId}`, { sharePageLink });
                }

                if (flags['is-celebration-card-enabled-checkout']) {
                    const greetingCardData = yield select(commonSelectors.getGreetingCards);
                    if (greetingCardData?.length) {
                        const confirmCard = yield call(processCelebrationsGreetingCard, greetingCardData);
                        if (confirmCard?.data?.message !== 'Path updated') {
                            mbpLogger.logError({
                                function: 'onPlaceOrder',
                                module: 'growth-pwa-ui',
                                message: 'Failed updating celebrations card',
                                cardData: greetingCardData,
                                cgcResponse: confirmCard,
                            });
                        }
                        yield put(resetCGCCardData());
                    }
                }

                yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            }
        } else {
            yield put(paymentFormActions.paymentUpdateFormStatus(false));
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            throw new Error(`${placeOrderResponse.data}`);
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'onPlaceOrder',
            module: 'growth-pwa-ui',
            message: 'Failed onPlaceOrder',
            jsError: ex,
            orderId: logOrderId,
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'payment');
        const IsPaymentHasError = yield select(commonSelectors.getIsPaymentHasError);
        const { history } = action.payload;
        if (IsPaymentHasError) {
            history.push(`/checkout/payment/${logOrderId}`);
        }
        yield put(paymentFormActions.paymentUpdateFormStatus(false));
    }
}

function* applyGC({ giftcardNumber, giftcardPin }) {
    try {
        // Calculate Shipping Befor Apply GC
        yield put(checkoutActions.calculateShipping());
        yield take(checkoutActions.calculateShippingComplete().type);
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'giftCard',
            field: 'error',
        }));

        const JWT_TOKEN = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);
        const applyGcResult = yield call(applyGCOnOrder, {
            JWT_TOKEN,
            orderId,
            giftcardNumber,
            giftcardPin,
        });

        if (applyGcResult.data.successFlag === 'true') {
            yield call(retrieveCart);
        } else {
            // To do : parse the error and show appropriate message to custome
            yield call(errorOperations.getError, 'Gift Card Could not be applied', 'giftCard');
        }

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (exp) {
        const orderId = yield select(commonSelectors.getOrderId);

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        yield call(errorOperations.getError, exp, 'giftCard');

        mbpLogger.logError({
            module: 'growth-pwa-ui',
            function: 'applyGC',
            jsError: exp,
            message: 'Failed to apply Gift Card',
            orderId,
        });
    }
    yield put(applyGiftCardProccessCompleted());
}

function* removeGC({ giftcardNumber, giftCardId }) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'giftCard',
            field: 'error',
        }));

        const JWT_TOKEN = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);
        const removeGcResult = yield call(removeGCOnOrder, {
            JWT_TOKEN,
            orderId,
            giftcardNumber,
            giftCardId,
        });

        if (removeGcResult.data.successFlag === 'true') {
            yield call(retrieveCart);
        }

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (exp) {
        const orderId = yield select(commonSelectors.getOrderId);

        // Load Error Message
        yield call(errorOperations.getError, exp, 'giftCard');

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        mbpLogger.logError({
            module: 'growth-pwa-ui',
            function: 'removeGC',
            jsError: exp,
            message: 'Failed to remove Gift Card',
            orderId,
        });
    }
    yield put(removeGiftCardProccessCompleted());
}

function* fillSavedBillingAddress() {
    try {
        mbpLogger.logDebug({
            function: 'fillSavedBillingAddress',
            module: 'growth-pwa-ui',
            message: 'fillSavedBillingAddress - Start',
        });
        // const billingFormAddress = yield select(commonSelectors.getBillingInfo);
        const isAuthenticated = yield select(getIsAuthenticatedStatus);

        if (isAuthenticated) {
            yield call(workerLoadBillingAddress);
        }
        mbpLogger.logDebug({
            function: 'fillSavedBillingAddress',
            module: 'growth-pwa-ui',
            message: 'fillSavedBillingAddress - End',
        });
    } catch (ex) {
        mbpLogger.logError({
            function: 'fillSavedBillingAddress',
            module: 'growth-pwa-ui',
            message: 'Failed - fillSavedBillingAddress',
            jsError: ex,
        });
    }
}

function* recordPaymentMethodTypeWorker() {
    try {
        const currentPaymentMethod = yield select(commonSelectors.getPaymentMethod);

        const orderId = yield select(commonSelectors.getOrderId);
        if (orderId) {
            const JWT_TOKEN = yield call(checkJWT);
            const paymentMethodType = currentPaymentMethod.id || 'CreditCard';
            const recordResult = yield call(setRecordPaymentMethod, { JWT_TOKEN, orderId, paymentMethodType });

            if (recordResult.data.result === 'SUCCESS') {
                yield put(recordPaymentMethodTypeCompleted());
            }
        } else {
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                function: 'recordPaymentMethodTypeWorker',
                module: 'growth-checkout',
                message: 'recordPaymentMethodType - no OrderId',
                windowLocation,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'recordPaymentMethodTypeWorker',
            module: 'Payment-Operations.js',
            message: 'Failed - recordPaymentMethodType',
            jsError: ex,
        });
    }
}

// Handle all the page load processing required for the Payment page.
function* onPaymentPageProcessing(props) {
    const logOrderId = yield select(commonSelectors.getOrderId);
    try {
        const flagState = yield select(commonSelectors.getFlags);
        const { history } = props.data;
        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onPaymentPageProcessing',
                module: 'growth-pwa-ui',
                message: 'onPaymentPageProcessing - Start',
                orderId: logOrderId,
            });
        }
        yield put(paymentReadinessActions.pageInitializationStart());

        const isPaymentErrors = yield select(commonSelectors.getIsPaymentHasError);
        const isPlaceOrderStatus = yield select(commonSelectors.getPlaceOrderStatus);

        if (!isPaymentErrors && isPlaceOrderStatus) {
            yield put(errorActions.clearErrors({ errType: 'paymentClearAllFields' }));
        }
        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onPaymentPageProcessing',
                module: 'growth-pwa-ui',
                message: 'Call RetrieveCart before starting payment process',
                orderId: logOrderId,
            });
        }

        yield put(recordPaymentMethodType());

        const paypalPayment = yield select(commonSelectors.getPaypalPaymentDetails);

        if (paypalPayment.payload.nonce) {
            const availablePaymentMethods = yield select(commonSelectors.getPaymentMethods);
            yield put(paymentFormActions.setPaymentMethod(availablePaymentMethods.PayPal));
        } else {
            // log updated as ERROR to track on prod env
            if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
                mbpLogger.logError({
                    function: 'onPaymentPageProcessing',
                    module: 'growth-pwa-ui',
                    message: 'Proceed to validate order',
                    orderId: logOrderId,
                });
            }

            // Validate Order
            yield put(triggerValidateOrder(history));
            const validateOrder = yield take(triggerValidateOrderCompleted().type);

            // log updated as ERROR to track on prod env
            if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
                mbpLogger.logError({
                    function: 'onPaymentPageProcessing',
                    module: 'growth-pwa-ui',
                    message: 'Validate order completed',
                    orderId: logOrderId,
                });
            }
            if (validateOrder.status) {
                // Applied the promotion if exist in the cookie.
                const bannerObj = parseBannerCookie();
                if (bannerObj && bannerObj.p && bannerObj.p !== '') {
                    yield put(checkoutActions.applyPromotion(bannerObj.p, ''));
                    yield take(checkoutActions.applyPromotionCompleted().type);
                }

                yield call(fillSavedBillingAddress);
                yield call(setPassportStatus);
            }
        }

        yield put(paymentReadinessActions.pageInitializationCompleted());

        // log updated as ERROR to track on prod env
        if (commonSelectors.getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'onPaymentPageProcessing',
                module: 'growth-pwa-ui',
                message: 'Payment Page Initialization Completed',
                orderId: logOrderId,
            });
        }
    } catch (ex) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        mbpLogger.logError({
            function: 'onPaymentPageProcessing',
            module: 'growth-pwa-ui',
            message: 'Failed - onPaymentPageProcessing',
            jsError: ex,
            orderId: logOrderId,
        });
    }
}

function* onApplePayOrderSuccess(action) {
    const { history } = action.payload;
    try {
        yield call(retrieveCart);
        const orderId = yield select(commonSelectors.getOrderId);
        yield call(history.push, `/checkout/order-confirmation/${orderId}`);
    } catch (ex) {
        mbpLogger.logError({
            function: 'onApplePayOrderSuccess',
            module: 'growth-pwa-ui',
            message: 'Failed - onApplePayOrderSuccess',
            jsError: ex,
        });
    }
}

function* onApplePayMerchantSuccess() {
    try {
        // If the banner exist in the cookie.
        yield call(persistBanner);
    } catch (ex) {
        mbpLogger.logError({
            function: 'onApplePayMerchantSuccess',
            module: 'growth-pwa-ui',
            message: 'Failed - onApplePayMerchantSuccess',
            jsError: ex,
        });
    }
}

function* processPaymentPageWatcher() {
    let action = yield take(processPaymentPage().type);
    while (action !== END) {
        yield call(onPaymentPageProcessing, action);
        action = yield take(processPaymentPage().type);
    }
}

function* placeOrderWatcher() {
    let action = yield take(placeOrder().type);
    while (action !== END) {
        yield call(onPlaceOrder, action);
        action = yield take(placeOrder().type);
    }
}

function* loadPaymentDetailsWatcher() {
    yield takeEvery(loadCompletedPaymentDetails().type, loadPaymentDetailsWorker);
}

function* recordPaymentMethodTypeWatcher() {
    let action = yield take(recordPaymentMethodType().type);
    while (action !== END) {
        yield call(recordPaymentMethodTypeWorker, action);
        action = yield take(recordPaymentMethodType().type);
    }
}

function* applyGiftCardWatcher() {
    let action = yield take(applyGiftCard().type);
    while (action !== END) {
        yield call(applyGC, action.payload);
        action = yield take(applyGiftCard().type);
    }
}

function* removeGiftCardWatcher() {
    let action = yield take(removeGiftCard().type);
    while (action !== END) {
        yield call(removeGC, action.payload);
        action = yield take(removeGiftCard().type);
    }
}

function* validateOrderDesktopApi() {
    const orderId = yield select(commonSelectors.getOrderId);
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        yield put(errorActions.clearErrors({
            errType: 'paymentClearAllFields',
            field: 'error',
        }));

        if (orderId) {
            const jwtToken = yield call(checkJWT);
            yield call(validateOrderAPI, jwtToken, orderId);
            yield put(updateBillingFormStatus(true));
        } else {
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                module: 'Payment-Operations.js',
                function: 'validateOrderDesktopApi',
                meessage: 'validateOrderDesktopApi - no OrderId',
                orderId,
                windowLocation,
            });
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            module: 'Payment-Operations.js',
            function: 'validateOrderDesktopApi',
            jsError: ex,
            orderId,
        });

        yield put(updateBillingFormStatus(false));
        yield call(errorOperations.getError, ex, 'payment');
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* validateOrderDesktopApiWatcher() {
    yield takeEvery(validateApiDesktop().type, validateOrderDesktopApi);
}

const isCandaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);

function* billingFormTriggerStateCityData(action) {
    try {
        let formZipCode = action.data.trim();
        let labelZip = 'Zip Code';
        let checkZip = false;

        if ((formZipCode.length === 6 || formZipCode.length === 7) && isCandaZip(formZipCode)) {
            checkZip = true;
            const zipSplit = formZipCode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            formZipCode = zipSplit.join('');
            labelZip = 'Postal Code';
        } else if (formZipCode.length >= 5) {
            checkZip = true;
        }

        const jwtToken = yield call(checkJWT);
        const reqObj = {
            jwtToken,
            zipCode: formZipCode,
        };

        yield put(errorActions.loadError({ errType: 'payment', errMsg: { zipCode: '' } }));

        if (reqObj && reqObj.zipCode && checkZip) {
            const resStateCityData = yield call(getStateCityData, reqObj);
            const cityStateDate = resStateCityData?.data?.esbSaltaServiceResponse;

            if (cityStateDate && cityStateDate.checkZipResponse
                && cityStateDate.checkZipResponse.checkZipResult
                && cityStateDate.checkZipResponse.checkZipResult.flwsErrors) {
                yield put(errorActions.loadError({ errType: 'payment', errMsg: { zipCode: `${'Invalid '}${labelZip}` } }));
            }

            if (cityStateDate && cityStateDate.checkZipResponse
                && cityStateDate.checkZipResponse.checkZipResult
                && cityStateDate.checkZipResponse.checkZipResult.location
                && cityStateDate.checkZipResponse.checkZipResult.location.state) {
                const {
                    checkZipResponse: {
                        checkZipResult: {
                            location: {
                                state,
                                country,
                                cityList: {
                                    city,
                                },
                                zipCode,
                            },
                        },
                    },
                } = cityStateDate;
                const cityList = [
                    ...new Set(city),
                ];

                yield put(errorActions.loadError({ errType: 'payment', errMsg: { zipCode: '' } }));

                if (country === 'CAN' || country === 'USA') {
                    yield put(updateBillingForm({
                        country: country.slice(0, -1),
                        state,
                        cityArray: cityList,
                        city: cityList[0].toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
                        zipCode,
                    }));
                } else {
                    yield put(updateBillingForm({
                        state,
                        cityArray: cityList,
                        city: cityList[0].toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
                        zipCode,
                    }));
                }
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            module: 'growth-pwa-ui',
            functio: 'billingFormTriggerStateCityData',
            jsError: ex,
        });
    }
}

function* watcherTriggerStateCityData() {
    yield takeEvery(triggerStateCityData().type, billingFormTriggerStateCityData);
}

function* watcherProcessNBCUMovie() {
    yield takeLatest(processNBCUMovie().type, onProcessNBCUMovie);
}

function* watcherApplePayOrderSuccess() {
    yield takeLatest(applePayOrderSuccess().type, onApplePayOrderSuccess);
}

function* watcherApplePayValidateMerchantSuccess() {
    yield takeLatest(applePayOrderValidateMerchantSuccess().type, onApplePayMerchantSuccess);
}

// Payment Plugins
// Watcher Sagas
const watchers = [
    fork(processPaymentPageWatcher),
    fork(placeOrderWatcher),
    fork(loadPaymentDetailsWatcher),
    fork(applyGiftCardWatcher),
    fork(removeGiftCardWatcher),
    fork(validateOrderDesktopApiWatcher),
    fork(watcherTriggerStateCityData),
    fork(recordPaymentMethodTypeWatcher),
    fork(watcherProcessNBCUMovie),
    fork(watcherApplePayOrderSuccess),
    fork(watcherApplePayValidateMerchantSuccess),
];

export {
    watchers,
    onPlaceOrder,
    loadPaymentDetailsWorker,
    onPaymentPageProcessing,
};
