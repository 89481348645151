/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
export default {
    auth: {
        isAuthenticated: false,
        accessToken: '',
        userType: '',
        expiresAt: '',
        lastVisitedPage: [],
    },
    profile: {
        firstName: '',
        lastName: '',
        userRole: '',
        contactId: '',
    },
    addressBook: {
        address: [],
    },
    wallet: {
        paymentMethod: [],
    },
};
*/

/*

User Role :

'' - empty non passport user
P - Passport user
S - Shoprunner user

*/

export default {
    auth: {
        isAuthenticated: false,
        accessToken: '',
        userType: '',
        expiresAt: '',
        lastVisitedPage: '',
        authError: '',
        authSuccess: '',
        rememberConsent: false,
    },
    profile: {
        firstName: '',
        lastName: '',
        userRole: '',
        contactId: '',
        email: '',
    },
    shopRunner: {
        srToken: '',
    },
    addressBook: {
        addressBookEntries: [],
        billingAddress: [],
        totalEntries: 0,
        totalPages: 0,
        pageNumber: 0,
        guestAddressBookEntries: [],
    },
    wallet: {
        walletEntries: [],
        error: {
            walletSaveCreditCard: '',
        },
        creditCardSavedFlag: false,
    },
};
