/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/cart';
const RESOURCE_ROOT_FACADE = '/checkout';
/** '';
 * add item to cart via facade
 * @param {*} wcEnv
 *
 */

// TODO: start - this is a refactor for add to cart one time is checkout free, this will be a better solution
// const addToCart = (wcEnv, jwtToken, params = {}, data) => {
//     const env = wcEnv;
//     env.rootUri = RESOURCE_ROOT_FACADE;
//     const { recipients, product, giftHistoryOrderItemId } = data;
//     const payload = { recipients, product, giftHistoryOrderItemId };

//     if (data?.personalization) {
//         payload.personalization = data.personalization;
//     }

//     return restClient.postFacade(env, RESOURCE_PAGE_FACADE, jwtToken, payload, params)
//         .then((response) => response);
// };
// TODO: end

const addToCart = (wcEnv, jwtToken, recipients, product, giftHistoryOrderItemId, personalization) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    let payload = { recipients, product, giftHistoryOrderItemId };
    if (payload.product.delivery) {
        payload.product.delivery.zipCode = payload.product.delivery.zipCode || 10001;
    }

    if (personalization) {
        payload = {
            recipients, product, giftHistoryOrderItemId, personalization,
        };
    }
    return restClient.postFacade(env, RESOURCE_PAGE_FACADE, jwtToken, payload)
        .then((response) => response);
};

export default addToCart;
