/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    fork, call, select, takeLatest, put,
} from 'redux-saga/effects';
import { delay } from 'redux-saga';
import Cookies  from 'universal-cookie';
import personClient from 'wc-api-person';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';

import * as actions from './Shoprunner-Actions';
import { shopRunner } from './helpers/helpers';
import {
    getBrand,
    getShopRunnerToken,
    getFlags,
    getAccessToken,
} from '../Common/Common-Selectors';

import { updateShopRunnerUser } from '../Profile/Profile-Actions';

const validateShopRunnerApi = ({
    wcEnv,
    JWT_TOKEN,
    srToken,
    brandName,
}) => personClient.validateShopRunner(wcEnv, JWT_TOKEN, srToken, brandName);

function* workerHandleValidateShopRunner(action) {
    try {
        const JWT_TOKEN = yield select(getAccessToken);
        const brandDetails = yield select(getBrand);
        const { data: { srToken } } = action;
        if (srToken
            && JWT_TOKEN
            && brandDetails
            && brandDetails['domain-name']) {
            const brandName = brandDetails['domain-name'];
            const wcEnv = {
                host: mbpUtil.getEnv('APP_WC_HOST'),
            };
            const validateShopRunner = yield call(validateShopRunnerApi, {
                wcEnv,
                JWT_TOKEN,
                brandName,
                srToken,
            });
            const { data: { isTokenValid, srtoken } } = validateShopRunner;
            if (isTokenValid === 'true' && srtoken) {
                const cookies = new Cookies();
                cookies.set('srTokenValue', srtoken, { path: '/' });
                yield put(actions.loadSrToken(srtoken));
                yield put(updateShopRunnerUser());
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'workerHandleValidateShopRunner',
            jsError: ex,
            message: 'Shoprunner Validate API Failed',
        });
    }
}

function waitTillShopRunnerTokenIsSet() {
    let count = 0;
    const MAX_COUNT = 100;
    const poll = (resolve) => {
        // eslint-disable-next-line no-underscore-dangle
        const { token } = window._shoprunner_com;
        if (token) {
            resolve(token);
        } else if (count <= MAX_COUNT) {
            count += 1;
            setTimeout(() => poll(resolve), 100);
        }
    };
    return new Promise(poll);
}

function* waitTillGuestTokenIsSet() {
    let count = 0;
    const MAX_COUNT = 100;
    function* keepPooling() {
        yield delay(100);
        const accesstoken = yield select(getAccessToken);
        if (accesstoken) {
            return true;
        }
        if (count <= MAX_COUNT) {
            count += 1;
            yield call(keepPooling);
        }
        return false;
    }
    return yield call(keepPooling);
}

function* workerLoadShopRunner() {
    if (typeof window !== 'undefined') {
        const brandDetails = yield select(getBrand);
        let srPartnerCode = '';
        if (brandDetails && brandDetails['shoprunner-partner-code']) {
            srPartnerCode = brandDetails['shoprunner-partner-code'];
            yield fork(shopRunner, srPartnerCode);
        }
        const isSrTokenSet = yield select(getShopRunnerToken);
        if (!isSrTokenSet && srPartnerCode) {
            const avoidAuthCallBack = window.location.href.includes('/auth/callback');
            if (!avoidAuthCallBack) {
                yield call(waitTillShopRunnerTokenIsSet);
                yield call(waitTillGuestTokenIsSet);
                // eslint-disable-next-line no-underscore-dangle
                const { token: srToken } = window._shoprunner_com;
                const accesstoken = yield select(getAccessToken);
                if (srToken && accesstoken) {
                    const payLoad = { data: { srToken } };
                    const featureFlags = yield select(getFlags);
                    if (featureFlags
                        && featureFlags['is-shoprunner-enabled']
                        && featureFlags['is-shoprunner-enabled'] === true) {
                        yield call(workerHandleValidateShopRunner, payLoad);
                    }
                }
            }
        }
    }
}

function* watchLoadShopRunner() {
    yield takeLatest(actions.loadShopRunner().type, workerLoadShopRunner);
}

function* workerValidateShopRunnerToken() {
    const featureFlags = yield select(getFlags);
    if (featureFlags
        && featureFlags['is-shoprunner-enabled']
        && featureFlags['is-shoprunner-enabled'] === true) {
        const srToken = yield select(getShopRunnerToken);
        const accesstoken = yield select(getAccessToken);
        if (srToken && accesstoken) {
            const payLoad = { data: { srToken } };
            yield call(workerHandleValidateShopRunner, payLoad);
        }
    }
}

function* watchValidateShopRunnerToken() {
    yield takeLatest(actions.validateShopRunnerToken().type, workerValidateShopRunnerToken);
}

const watchers = [
    fork(watchLoadShopRunner),
    fork(watchValidateShopRunnerToken),
];

const workers = {
    workerLoadShopRunner,
    workerValidateShopRunnerToken,
};

export {
    watchers,
    workers,
};
