/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const SUBMIT_VISA_CHECKOUT_PAYMENT_CONTEXT = 'mbp-checkout/VISACHECKOUT/PAYMENT/CONTEXT';

export {
    SUBMIT_VISA_CHECKOUT_PAYMENT_CONTEXT,
};

export default {};
