/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import Cookies from 'universal-cookie';

const getCookie = (name) => {
    const cookies = new Cookies();
    return cookies.get(name);
};

export default getCookie;
