/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, put, select, take, takeEvery, all,
} from 'redux-saga/effects';
// import CsAPI from 'mbp-contentstack-sdk';
import mbpLogger from 'mbp-logger';

import { duck as tmDuck } from '../../../../../TagManager';
import memberDucks from '../../../../../Member/ducks';
import * as actions from './Passport-Action';
import * as actionsTypes from './Passport-ActionTypes';
import {
    getPassportSubscriptionStatus,
    // getPassportTncContentMarkUp,
    getPassportData,
    getPassportOrderItemId,
    getPassportItem,
    getIfGiftCardApplied,
    getAppliedGiftCards,
    getOrderId,
} from '../../../Common/Common-Selectors';

import { setOrderId } from '../../../Order/Order-Operations';
import { calculateShipping, calculateShippingComplete } from '../../../Checkout/Checkout-Actions';
import { retrieveCart } from '../../../Cart/Cart-Operations';
import { errorActions, errorOperations }  from '../../../Common/ducks/Error';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

import { getBaseCode } from '../../../../../../../app/helpers/tracking/product/dataLayerProductHelpers';

const {
    auth: {
        authOperations,
    },
    profile: {
        profileActions: {
            setProfileUserRole,
        },
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const { GenericErrorMessage } = errorOperations;

const removeItem = ({
    wcEnv,
    jwtToken,
    orderItemId,
    orderId,
}) => orderClient.removeOrderItem(wcEnv, jwtToken, orderItemId, orderId);

const postAddToCart = ({ JWT_TOKEN, item, recipients }) => orderClient.addToCart({}, JWT_TOKEN, recipients, item, '');

// API Client Handlers
const dynamicPassportChargeApi = (object) => orderClient.dynamicPassportCharge(object.wcEnv, object.JWT_TOKEN, object.orderId, object.passportItem, object.passportBrand);

function* proccessPassportSubscription(action) {
    // Show Site Loader
    yield put(showLoadingMessageActions.updateShowLoadingMessage());

    // Clear Error
    yield put(errorActions.clearErrors({
        errType: 'passport',
        field: 'error',
    }));

    let trackEventCategory = '';

    const JWT_TOKEN = yield call(checkJWT);
    const passportSubscriptionStatus = yield select(getPassportSubscriptionStatus);
    const { pageOn } = action.data;

    const passportData = yield select(getPassportData);

    let productCode = passportData?.item_id;
    // if passport bundle flow
    if (action.data.passportSku) {
        productCode = action.data.passportSku;
        // if dynamic passportSku is present
    } else if (passportData?.passport_dynamic_price?.passportSku) {
        productCode = passportData?.passport_dynamic_price?.passportSku;
    }

    const brandCode = passportData?.brand_id;

    if (!passportSubscriptionStatus) {
        if (brandCode && productCode) {
            const item = { brandCode, productCode, quantity: '1' };
            const addToCartResp = yield call(postAddToCart, { recipients: null, item, JWT_TOKEN });
            const { orderItemId, orderId } = addToCartResp.data;

            if (pageOn === 'product') {
                trackEventCategory = 'Shopping';
                yield call(setOrderId, { data: orderId });
            } else if (pageOn === 'payment') {
                trackEventCategory = 'Checkout';
                yield put(calculateShipping());
                yield take(calculateShippingComplete().type);
                yield put(actions.getDynamicPassportCharge());
            }

            yield call(retrieveCart);
            yield put(actions.subscribePassport(orderItemId));
            yield put(setProfileUserRole('M'));

            const eventAction = 'Add to cart';
            const { primeName, primeSku, primePrice } = addToCartResp.data;
            const passportEventObj = {
                eventName: 'add_to_cart',
                eventCategory: trackEventCategory,
                eventAction,
                eventLabel: 'Passport|<<pageType>>',
                products: [
                    {
                        name: primeName,
                        partNumber: primeSku,
                        baseCode: getBaseCode(primeSku),
                        brand: brandCode,
                        sKUs: [{
                            partNumber: primeSku,
                        }],
                        price: primePrice,
                        quantity: '1',
                        position: 1,
                        categoryId: '12 Months of Passport',
                    },
                ],
            };
            yield put(tmDuck.actions.trackEvent(passportEventObj));
        } else {
            mbpLogger.logError({
                module: 'mbp-checkout',
                function: 'workerGetPassportTncContent',
                message: 'brandCode && productCode are missing',
            });
            const genricErrorMsg = yield call(GenericErrorMessage);
            yield put(errorActions.loadError({
                errType: 'passport',
                errMsg: genricErrorMsg,
            }));
        }
    } else {
        const passportOrderItemId = yield select(getPassportOrderItemId);
        const configObj = {
            wcEnv: {},
            jwtToken: JWT_TOKEN,
            orderItemId: passportOrderItemId,
        };

        // Remove Passport
        yield call(removeItem, configObj);

        if (pageOn === 'payment') {
            trackEventCategory = 'Checkout';
            yield put(calculateShipping());
            yield take(calculateShippingComplete().type);
            yield put(actions.getDynamicPassportCharge());
        }

        yield call(retrieveCart);
        yield put(actions.unSubscribePassport());
        yield put(setProfileUserRole(''));

        // Tealium Tracking Starts
        const eventAction = 'Remove from cart (pwa)';
        const passportEventObj = {
            eventCategory: trackEventCategory,
            eventAction,
            eventLabel: 'Passport|<<pageType>>',
        };
        yield put(tmDuck.actions.trackEvent(passportEventObj));
        // Tealium Tracking Ends
    }
    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* workerTriggerPassportSubscription(action) {
    try {
        const ifGiftCardApplied = yield select(getIfGiftCardApplied);
        if (!ifGiftCardApplied) {
            yield call(proccessPassportSubscription, action);
        } else {
            // GC is applied show modal seeking to remove GC
            yield put(actions.showPassportModalToRemoveGC());
        }
    } catch (ex) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'passport');
    }
}

function* watcherTriggerPassportSubscription() {
    yield takeEvery(actionsTypes.TRIGGER_PASSPORT_SUBSCRIPTION, workerTriggerPassportSubscription);
}

// function* workerGetPassportTncContent() {
//     try {
//         const tncContent = yield select(getPassportTncContentMarkUp);
//         if (tncContent === '') {
//             const resp = yield call(CsAPI.csGet, {}, 'passport_terms_and_conditions');
//             yield put(actions.loadPassportTncContent(resp.data.entries[0].passport_terms_and_conditons));
//         }
//     } catch (ex) {
//         mbpLogger.logError({
//             module: 'mbp-checkout',
//             function: 'workerGetPassportTncContent',
//             jsError: ex,
//             message: 'Load Passport TnC Content',
//         });
//     }
// }

// function* watchGetPassportTncContent() {
//     yield takeEvery(actionsTypes.GET_PASSPORT_TNC_CONTENT, workerGetPassportTncContent);
// }

function* setPassportStatus() {
    const logOrderId = yield select(getOrderId);
    try {
        mbpLogger.logDebug({
            module: 'mbp-checkout',
            function: 'setPassportStatus',
            message: 'setPassportStatus - Start',
            orderId: logOrderId,
        });
        const orderItem = yield select(getPassportItem);
        if (orderItem.length > 0) {
            const [passportItem] = orderItem;
            const [orderItemDetails] = passportItem.orderItems;
            const { orderItemsId } = orderItemDetails;
            yield put(actions.subscribePassport(orderItemsId));
        } else {
            yield put(actions.unSubscribePassport());
        }
        mbpLogger.logDebug({
            module: 'mbp-checkout',
            function: 'setPassportStatus',
            message: 'setPassportStatus - End',
            orderId: logOrderId,
        });
    } catch (ex) {
        mbpLogger.logDebug({
            module: 'mbp-checkout',
            function: 'setPassportStatus',
            message: 'setPassportStatus - End',
            jsError: ex,
            orderId: logOrderId,
        });
    }
}

function* workerRemoveAllAppliedGiftCard(action) {
    try {
        const { appliedGiftcards } = yield select(getAppliedGiftCards);
        if (appliedGiftcards && Object.keys(appliedGiftcards).length > 0) {
            const JWT_TOKEN = yield call(checkJWT);
            const orderId = yield select(getOrderId);
            const rmGCPromise = Object.keys(appliedGiftcards).map((gcId) => {
                const gcDetails = appliedGiftcards[gcId];
                const { giftCardNumber, piId } = gcDetails;
                return orderClient.removeGiftCard({}, JWT_TOKEN, orderId, giftCardNumber, piId);
            });
            yield all(rmGCPromise);
            yield call(proccessPassportSubscription, action);
            yield put(actions.hidePassportModalToRemoveGC());
        }
    } catch (ex) {
        yield put(actions.hidePassportModalToRemoveGC());
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'passport');
    }
}

function* watcherTriggerAddPassportByRemoveGiftCard() {
    yield takeEvery(actionsTypes.TRIGGER_ADD_PASSPORT_BY_REMOVE_GIFTCARD, workerRemoveAllAppliedGiftCard);
}

function* workerDynamicPassportCharge() {
    try {
        const orderId = yield select(getOrderId);
        if (orderId) {
            const JWT_TOKEN = yield call(checkJWT);

            const passportData = yield select(getPassportData);

            const passportItem = passportData?.passport_dynamic_price?.passportSku || passportData?.item_id;

            const passportBrand = passportData?.brand_id;

            const object = {
                wcEnv: {},
                JWT_TOKEN,
                orderId,
                passportItem,
                passportBrand,
            };
            const response = yield call(dynamicPassportChargeApi, object);
            yield put(actions.loadPassportDynamicCharge(response.data));
        } else { // no orderid
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                function: 'workerDynamicPassportCharge',
                module: 'Passport-Operations',
                message: 'Dynamic Passport Charges - No Order Id',
                orderId,
                windowLocation,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'workerDynamicPassportCharge',
            jsError: ex,
            message: 'Load Passport Dynamic Charge',
        });
    }
}

function* watchDynamicPassportCharge() {
    yield takeEvery(actionsTypes.GET_DYNAMIC_PASSPORT_CHARGE, workerDynamicPassportCharge);
}

const watchers = [
    fork(watcherTriggerAddPassportByRemoveGiftCard),
    fork(watcherTriggerPassportSubscription),
    // fork(watchGetPassportTncContent),
    fork(watchDynamicPassportCharge),
];

export {
    watchers,
    workerTriggerPassportSubscription,
    setPassportStatus,
    dynamicPassportChargeApi,
};

export default {};
