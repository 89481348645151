/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './RecipientForm-ActionTypes';

export const updateRecipientForm = (data) => ({
    type: actionTypes.UPDATE_RECIPIENT_FORM,
    data,
});

export const updateForm = (event) => ({
    type: actionTypes.UPDATE_FORM,
    data: event,
});

export const proceedToSave = (data) => ({
    type: actionTypes.TRIGGER_PROCEED_TO_SAVE,
    data,
});

export const submitForm = (data) => ({
    type: actionTypes.TRIGGER_SUBMIT_FORM,
    data,
});

export const updateFormAddressFromPreFill = (preFillData) => ({
    type: actionTypes.PREFILL_FORM_ADDRESS,
    data: preFillData,
});
export const updateFormValues = (event) => ({
    type: actionTypes.UPDATE_FORM_VALUES,
    data: event,
});
export const handleAddressBookChange = (recipient) => ({
    type: actionTypes.TRIGGER_UPDATE_FROM_ADDRESS_BOOK,
    data: recipient,
});
export const clearRecipientFormFields = () => ({
    type: actionTypes.CLEAR_UPDATE_FIELDS,
});
export const triggerClearRecipientFormFields = () => ({
    type: actionTypes.TRIGGER_CLEAR_UPDATE_FIELDS,
});
export const updateFromAddressBook = (data) => ({
    type: actionTypes.UPDATE_FROM_ADDRESS_BOOK,
    data,
});

export const updateRecipientFormStatus = (data) => ({
    type: actionTypes.RECIPIENT_UPDATE_FORM_STATUS,
    data,
});

export const checkSameAsDeliveryAddressAddress = (data) => ({
    type: actionTypes.BILLING_PREFILL_FORM_ADDRESS,
    data,
});

export const updatedRecipientAddressSuccessfully = () => ({
    type: actionTypes.UPDATED_RECIPIENT_ADDRESS_SUCCESSFULLY,
});

export const submitRecipientPwaDesktop = (data) => ({
    type: actionTypes.SUBMIT_RECIPIENT_PWA_DESKTOP,
    data,
});

export const proceedSaveRecipientPwaDesktop = (data) => ({
    type: actionTypes.SAVE_RECIPIENT_PWA_DESKTOP,
    data,
});

export const triggerStateCityData = (data) => ({
    type: actionTypes.RECIPIENT_GET_STATE_CITY_DATA,
    data,
});

export const saveRecipientAddressPreCheckout = () => ({
    type: actionTypes.SAVE_RECIPIENT_ADDRESS_PRE_CHECKOUT,
});

export const saveRecipientAddressPreCheckoutCompleted = () => ({
    type: actionTypes.SAVE_RECIPIENT_ADDRESS_PRE_CHECKOUT_COMPLETED,
});
