/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import QueryString from 'qs';
import serialize from 'serialize-javascript';
import Cookies from 'universal-cookie';

import mbpLogger from 'mbp-logger';

import * as attributionHelpers from '../../attribution/attributionHelpers';

const cookies = new Cookies();

const ANALYTICS_STORAGE_NAME = 'analyticsSession';
const CAMPAIGN_PROPS = {
    utm_campaign: 'campaignName',
    adcampaign: 'campaignName',
    utm_content: 'campaignContent',
    utm_medium: 'campaignMedium',
    utm_source: 'campaignSource',
    utm_term: 'campaignKeyword',
    kw: 'campaignKeyword',
    gclid: 'googleClickId',
    referrer: 'referrer',
};

export const getAnalyticsStorageData = () => {
    let analyticsStorage = {};
    const analyticsStorageRaw = window.localStorage.getItem(ANALYTICS_STORAGE_NAME);

    if (!analyticsStorageRaw) {
        return analyticsStorage;
    }

    try {
        analyticsStorage = JSON.parse(analyticsStorageRaw);
    } catch (error) {
        mbpLogger.logError({
            error,
            analyticsStorageRaw,
            function: 'getAnalyticsStorageData',
            module: 'sessionTrackingHelpers',
        });
    }

    return analyticsStorage;
};

export const setAnalyticsStorageData = (rawData) => {
    const data = serialize(rawData);
    const result = window.localStorage.setItem(ANALYTICS_STORAGE_NAME, data);

    return result;
};
export const clearSessionData = () => window.localStorage.removeItem(ANALYTICS_STORAGE_NAME);

function clearCookieData(featureFlags, hasData, attributionCookie, analyticsSession) {
    if (featureFlags?.['is-attribution-clear-cookie-when-ga-mismatch-enforced'] && !hasData && attributionCookie) {
        if (typeof window !== 'undefined' && window.sessionHelpersLoggingEnabled) {
            mbpLogger.logError({
                function: 'attributionDebug',
                appName: process.env.npm_package_name || 'growth',
                module: 'sesssionTrackingHelpers',
                message: `Removing Banner cookie due to no analytics session.
                    Flag: ${featureFlags['is-attribution-clear-cookie-when-ga-mismatch-enforced']} 
                    || Session: ${JSON.stringify(analyticsSession)} 
                    || Cookie: ${JSON.stringify(attributionCookie)}`,
            });
        }
        cookies.remove('banner');
    }
}

function clearSessionWhileAttrbMissing(hasData, attributionCookie, analyticsSession) {
    if (hasData && !attributionCookie) {
        if (typeof window !== 'undefined' && window.sessionHelpersLoggingEnabled) {
            mbpLogger.logError({
                function: 'attributionDebug',
                appName: process.env.npm_package_name || 'growth',
                module: 'sesssionTrackingHelpers',
                message: `Removing analytics data due to no cookie. Session: ${JSON.stringify(analyticsSession)} || Cookie: ${JSON.stringify(attributionCookie)}`,
            });
        }
        clearSessionData();
    }
}

function clearCookieWhileNoBanner(bannerCode, attributionCookie, analyticsSession) {
    if (!bannerCode) { // Banner code not found in GA session data. Clear session data and attribution cookie.
        if (typeof window !== 'undefined' && window.sessionHelpersLoggingEnabled) {
            // function/module/message/jsError
            mbpLogger.logError({
                function: 'attributionDebug',
                appName: process.env.npm_package_name || 'growth',
                module: 'sesssionTrackingHelpers',
                message: `Removing analytics data due to no banner code. Banner Code: ${bannerCode} || Session: ${JSON.stringify(analyticsSession)} || Cookie: ${JSON.stringify(attributionCookie)}`,
            });
        }
        cookies.remove('banner');
        clearSessionData();
    }
}

function clearSessionWhileNotMatch(featureFlags, hasData, attributionCookie, analyticsSession) {
    if (featureFlags?.['is-attribution-sync-reset-enabled']
            && (hasData && attributionCookie)
    ) {
        const parsedAnalyticsSessionData = analyticsSession; // GA attribution becomes source of truth.

        // Pull "landingParams" (defined by original attribution URL) and look for r or ref params to re-build GA attribution..
        // If no usable URL params are found to rebuild attribution cookie, GA attribution is cleared.
        if (parsedAnalyticsSessionData.landingParams) {
            const { landingParams } = parsedAnalyticsSessionData;
            const bannerCode = landingParams?.r || landingParams?.ref;
            const attributionCode = attributionCookie?.c;

            if (bannerCode && bannerCode !== attributionCode) { // BannerCode found and does not match attribution cookie
                const attributionURL = new URL(parsedAnalyticsSessionData.location || '');

                if (attributionURL) {
                    const attributionProps = {
                        attributionCookieExpiration: '',
                        location: attributionURL,
                        featureFlags,
                    };

                    attributionHelpers.processAttribution(attributionProps, 'Attribution');
                }
            }
            // Banner code not found in GA session data. Clear session data and attribution cookie.
            clearCookieWhileNoBanner(bannerCode, attributionCookie, analyticsSession);
        }
    }
}

export const syncAttributionState = ({
    featureFlags,
}) => {
    try {
        const analyticsSession = getAnalyticsStorageData();
        const attributionCookie = cookies.get('banner');

        const hasData = Object.keys(analyticsSession).length;

        // Clear cookie data if attribution session data is missing.
        clearCookieData(featureFlags, hasData, attributionCookie, analyticsSession);

        // Clear session data if attribution cookie is missing.
        clearSessionWhileAttrbMissing(hasData, attributionCookie, analyticsSession);

        // Reset cookie attribution if both GA and cookie attribution exist, but don't match.
        clearSessionWhileNotMatch(featureFlags, hasData, attributionCookie, analyticsSession);
    } catch (ex) {
        mbpLogger.logError({
            jsError: ex,
            function: 'syncAttributionState',
            appName: process.env.npm_package_name,
            module: 'sesssionTrackingHelpers',
            message: 'Failed to sync attribution.',
        });
    }
};

export const validateSessionAttribution = (props) => {
    const { featureFlags } = props;

    // Validate Search Attribution
    if (featureFlags?.['is-attribution-naturalsearch-clear-enabled']) {
        const approvedReferrers = featureFlags?.['attribution-natural-search-refs'] || '.google.=seogoogle|.yahoo.=seoyahoo|.bing.=seobing|.ask.=seoask|.comcast.=seocomcast|.aol.=seoaol';

        // Is this from search?
        const defaultSearchBannerCode = attributionHelpers.checkForSearchAttribution({ featureFlags, approvedReferrers });

        if (defaultSearchBannerCode) {
            const queryString = (typeof window !== 'undefined') ? window.location.search.replace('?', '') : null;
            const bannerCookie = cookies.get('banner'); // banner object

            mbpLogger.logInfo({
                function: 'validateSessionAttribution',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                bannerCookie,
                defaultSearchBannerCode,
                queryString,
                message: 'validateSessionAttribution | ',
            });

            if ((bannerCookie?.c && bannerCookie?.c?.indexOf?.(defaultSearchBannerCode) !== -1) // cookie property and associated bannerCode value match (multi-tab natural search)
                || (!bannerCookie && defaultSearchBannerCode && !queryString.length) // No cookie, but associated banner code found w/o URL params (single-tab natural search)
            ) {
                mbpLogger.logInfo({
                    function: 'validateSessionAttribution',
                    appName: process.env.npm_package_name,
                    module: 'mbp-pwa-ui',
                    message: 'validateSessionAttribution :: found natural search attribution, clear session',
                });

                clearSessionData();
            }
        }
    }
};

/**
 * processCurrentSession
 *
 * Handles both page-load and page-transition session details.
 *
 * Page-Load (New Session or Session Stiching)
 * - Store query params from new session (tab) to override all current sessions (tabs) [Local Storage]
 * -- Add any valid URL params (utms, dclid, gclid, etc)
 * -- Set "referrer" state (new) - if new params are provided to allow it to be sent on the first page navigation
 *
 * @param {Object} props
 */
export const processCurrentSession = (props = {}) => {
    const { location, featureFlags } = props;

    // No Storage
    if (!window.localStorage) {
        return false;
    }

    try {
        const urlParams = location ? QueryString.parse(location.search.replace('?', '')) : {}; // Current URL params (utm_campaign)
        let currentParams = getAnalyticsStorageData(); // Existing session params (campaignName)

        if (featureFlags['is-attribution-modified-campaign-props-enabled']) {
            CAMPAIGN_PROPS.ref = 'ref';
            CAMPAIGN_PROPS.r = 'r';
        }
        // Do any of the URL params contain campaign parameters?
        const isNewCampaign = Object.keys(CAMPAIGN_PROPS).some((key) => urlParams[key]);

        if (isNewCampaign) {
            currentParams = {};

            // Set location (pull from existing values, or set values for fresh session)
            currentParams.location = currentParams.location || window.location.href;
            currentParams.landingParams = currentParams.landingParams || urlParams;

            // if the new params match any property in our campaign list, clear out the referrer so it can be properly set
            delete currentParams.referrer;

            // Persist cross-tab session data [LOCAL STORAGE]
            setAnalyticsStorageData(currentParams);
        }

        // Sync Attribution state (GA vs. banner cookie)
        if (featureFlags['is-attribution-sync-enabled']) {
            syncAttributionState({
                featureFlags,
            });
        }

        if (featureFlags['is-attribution-natural-search-sync-enabled']) {
            const bannerCookie = cookies.get('banner');
            if (bannerCookie?.c?.startsWith('seo')) {
                // Clear out analyticsSession to override any previous campaigns when we are currently seo campaign.
                setAnalyticsStorageData({});
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            location,
            jsError: ex,
            function: 'processCurrentSession',
            appName: process.env.npm_package_name || 'growth',
            module: 'mbp-tag-manager',
            message: 'sesssionTrackingHelpers | processCurrentSession',
        });
    }

    return true;
};

const updateDataLayer = (data, landingParams, analyticsSession, srcDataLayer) => {
    const dataLayer = { ...data };
    // Build session campaign properties object against CAMPAIGN_PROPS whitelist
    if (landingParams) {
        Object.keys(CAMPAIGN_PROPS).forEach((key) => {
            if (landingParams[key]) {
                const campaignPropsValue = CAMPAIGN_PROPS[key];

                dataLayer[campaignPropsValue] = landingParams[key];
            }
        });
    }

    // Add googleClickId (gclid)
    if (analyticsSession.gclid) {
        dataLayer.googleClickId = analyticsSession.gclid;
    }

    // Add Page URL w/ campaign params [override]
    if (srcDataLayer.page && Object.keys(landingParams).length) {
        const delim = (srcDataLayer.page.indexOf('?') === -1) ? '?' : '&';
        dataLayer.page = `${srcDataLayer.page}${delim}${QueryString.stringify(landingParams)}`;
    }
    return dataLayer;
};
// Pull campaign data from Local storage and append to data layer
export const generateSessionDataLayer = (srcDataLayer) => {
    let dataLayer = {};

    try {
        if (!srcDataLayer && !window.localStorage && !window.sessionStorage) {
            return {};
        }

        const analyticsSession = getAnalyticsStorageData();

        if (Object.keys(analyticsSession).length) {
            const { landingParams } = analyticsSession;

            if (landingParams.clickid) {
                landingParams.clickid = landingParams.clickid.replace(/[&\\#, +()$~%@.'":*?<>{}]/g, ''); // replacing special chars allowing - and _
            }

            dataLayer = updateDataLayer(dataLayer, landingParams, analyticsSession, srcDataLayer);

            // Add location
            const { location } = analyticsSession;
            dataLayer.gua_location = location;

            // if this is the first time the referrer has not been cleared, clear it now
            // so future GA calls send an empty referrer
            if (!analyticsSession.referrer) {
                analyticsSession.referrer = '';

                setAnalyticsStorageData(analyticsSession);
            }

            // Logging for attribution debugging
            // Only log for campaign customers
            if (typeof window !== 'undefined' && window.sessionHelpersLoggingEnabled && dataLayer.campaignMedium === 'online_media') {
                mbpLogger.logError({
                    function: 'attributionDebug',
                    appName: process.env.npm_package_name || 'growth',
                    module: 'sesssionTrackingHelpers',
                    message: `Generating Session Data Layer.
                        || Session: ${JSON.stringify(analyticsSession)}
                        || DataLayer: ${JSON.stringify(dataLayer)}`,
                });
            }

            dataLayer.gua_referrer = analyticsSession.referrer;
        }

        return {
            ...dataLayer,
            ...analyticsSession,
        };
    } catch (ex) {
        mbpLogger.logError({
            dataLayer,
            jsError: ex,
            function: 'appendSessionDataLayer',
            appName: process.env.npm_package_name || 'growth',
            module: 'mbp-tag-manager',
            message: 'sesssionTrackingHelpers | appendSessionDataLayer',
        });
    }

    return {};
};
