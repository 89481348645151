/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END, delay } from 'redux-saga';
import {
    call, take, put, fork, select, takeLatest, race,
} from 'redux-saga/effects';
import Cookies from 'universal-cookie';
import mbpLogger from 'mbp-logger';
import axios from 'axios';
import mbpUtil from 'mbp-api-util';
import brandThemes from '../../../app/components/AppShell/brandtheme';
import { getCurrencyCode, getLanguageCode } from './ducks/Common/locale';
import {
    getSearchResult,
    loadAppShell,
    loadAppShellSSR,
    setAppShellLoadState,
    initSSR,
    clearSearchResult,
    setProductFilterZipcodeValidity,
    setValidatedZipcode,
    validateProductFilterZipcode,
    verifyWine,
    verifyWineDone,
    verifyWineFailed,
    setWineData,
    fetchGCIKey,
    setGCIKey,
    setSearchResult,
    setAgeVerifyFlagInvalid,
    createEmailSubscription,
    setEmailSubscriptionData,
    resetEmailSubscription, setLanguage, setCurrency, setCountry, setProductInWishlist, getUsersWishlist,
} from './App-Actions';
import { getBrandIdByHostName, isWineAvailable } from './App-Helpers';
import { getCurrentUser } from './App-Selectors';
import orderClient from '../../../apis/checkout-apis/orderClient';
import { getBrand } from './ducks/Brand/Brand-Selectors';
import isDesktopMobileTablet from '../../../app/helpers/DesktopMobile/isDesktopMobileTablet';
import { addToCartFood } from '../Checkout/ducks/Checkout/Checkout-Actions';
import { onLoadBrand } from './ducks/Brand/Brand-Operations';
import { onLoadFlags } from './ducks/Config/Config-Operations';
import { checkJWT } from '../Member/ducks/Auth/Auth-Operations';
import { subscribeEmailApi } from '../../../apis/account-apis/subscribeEmailApi';
import {
    getDefaultCountry,
    getDefaultCurrency,
    getDefaultLanguage,
    setCountryName,
    setCurrencyName,
    setLanguageName,
} from '../Member/ducks/Auth/helper/helper';
import { fetchGeoIPData } from '../../../apis/geo-ip-apis/fetchGeoIPData';
import { getWishlistAPI } from '../../../apis/wishlist-apis/wishlist';

const wcEnv = {};
const DEFAULT_QAS_API_WAIT_TIME = 10000;
const fetchVerifyWine = (jwt, payload) => orderClient.verifyWine(wcEnv, jwt, payload);
const fetchGCIKeyAPI = ({ jwt, params }) => orderClient.getGCIKeyAPI(wcEnv, jwt, params);
const getGeoIPData = () => fetchGeoIPData();

const getUsersProductInWishlist = async (payload, header) => {
    let response;
    try {
        response = await getWishlistAPI(payload.userId, header);
        return response;
    } catch (e) {
        mbpLogger.logError({
            function: 'workerAddProdutToWishlist',
            module: 'mbp-ui-app',
            jsError: e,
            message: 'Error in wishlist API',
        });
        response = { status: 500, errorMsg: response?.message };
        return response;
    }
};

function* loadIPGeoDetails() {
    let response;
    try {
        const raceCondition =  yield race({
            task: yield call(getGeoIPData), // call ipapi.co and get result within 10 sec
            timer: delay(DEFAULT_QAS_API_WAIT_TIME, 'delayed'),
        });

        if (raceCondition.timer) {
            response = {
                result: 'delayed IP-Geo api response',
                responseType: 'error',
            };
        } else if (raceCondition.task) {
            response = {
                result: raceCondition.task,
                responseType: 'success',
            };
        }
    } catch (ex) {
        response = {
            result: ex,
            responseType: 'error',
        };
    }
    return response;
}

// TODO get endpoint from env variables
function* onLoadAppShell({ hostname, location, deviceType }) {
    try {
        mbpLogger.logDebug({
            hostname,
            location,
            function: 'onLoadAppShell',
            module: 'mbp-pwa-ui',
            message: 'Load App | START',
        });

        // Load Page Shell metadata
        const user = yield select(getCurrentUser);

        // Load Brand
        // TODO: replace with new brand config service
        let brandId = '';
        if (hostname.includes('.ca')) {
            brandId = 'CAN';
        } else {
            brandId = getBrandIdByHostName(hostname);
        }
        const brand = yield call(onLoadBrand, { user, currentBrandId: brandId });

        mbpLogger.logDebug({
            brand,
            function: 'onLoadAppShell',
            module: 'mbp-pwa-ui',
            message: 'Load App | brand',
        });

        yield call(onLoadFlags, { brand });

        if (brand?.code === '08F') {
            const resultGeoIPData = yield call(loadIPGeoDetails);
            const { result } = resultGeoIPData;

            if (resultGeoIPData.responseType === 'success'
                && result) {
                if (result) {
                    const determinCurrency = getCurrencyCode(result);
                    const currency = { currencySymbol: determinCurrency?.symbol || '$', currencyName: determinCurrency?.currency };
                    const language = getLanguageCode(result?.languages || '');
                    const country = (result?.country_code || '');
                    yield put(setLanguage(language || ''));
                    yield put(setCurrency(currency));
                    yield put(setCountry(String(country).toLowerCase() || ''));
                    // set default to country, currency, language for browser session
                    const sessionCountry = getDefaultCountry();
                    const sessionCurrency = getDefaultCurrency();
                    const sessionLanguage = getDefaultLanguage();
                    if (!sessionCountry) {
                        setCountryName(`/${country}`);
                    }
                    if (!sessionCurrency) {
                        setCurrencyName(`${determinCurrency?.currency}`);
                    }
                    if (!sessionLanguage) {
                        setLanguageName(`/${language}`);
                    }
                }
            } else if (resultGeoIPData.responseType === 'error') {
                yield put(setLanguage('en'));
                yield put({ currencySymbol: '$', currencyName: 'USD' });
                yield put(setCountry(''));
            }
        }
        // client-side will not trigger SSR ops
        if (hostname.includes('localhost') && !hostname.includes('8080')) {
            const mockSSRConfig = {};
            // isDesktopMobileTablet condition added for dev mode
            mockSSRConfig.deviceType = deviceType || isDesktopMobileTablet();
            mockSSRConfig.isBot = false;
            mockSSRConfig.hostname = hostname;
            mockSSRConfig.path = location.pathname;
            yield put(initSSR(mockSSRConfig));
        }

        // Set page shell state
        yield put(setAppShellLoadState(true));
    } catch (ex) {
        mbpLogger.logError({
            hostname,
            location,
            function: 'onLoadAppShell',
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to load app data.',
        });
    }
}

function* watchLoadAppShell() {
    let action = yield take(loadAppShell().type);

    while (action !== END) {
        yield fork(onLoadAppShell, action.payload);

        action = yield take(loadAppShell().type);
    }
}

function* watchLoadAppSSR() {
    let action = yield take(loadAppShellSSR().type);

    while (action !== END) {
        const config = { ...action.payload };

        const ssrConfig = {};

        if (config.isBot) {
            ssrConfig.isBot = config.isBot;
        }

        if (config.hostName) {
            ssrConfig.hostName = config.hostName;
        }

        if (config.location) {
            ssrConfig.path = config.location.pathname;
        }

        // Set shopper manager id (baggage-session id header on incoming requests)
        if (config.shopperManagerId) {
            ssrConfig.shopperManagerId = config.shopperManagerId;
        }

        if (config.userAgent.ua) {
            ssrConfig.deviceType = isDesktopMobileTablet(config.userAgent.ua);
        }

        yield put(initSSR(ssrConfig));

        if (config.isShell) {
            yield call(onLoadAppShell, {
                hostname: config.hostName,
                location: config.location,
                deviceType: ssrConfig.deviceType,
            });
        } else {
            yield call(onLoadAppShell, {
                hostname: config.hostName,
                location: config.location,
                deviceType: ssrConfig.deviceType,
            });
        }

        action = yield take(loadAppShellSSR().type);
    }
}

// Zip Validation Sagas
function* workerValidateProductFilterZipcode(action) {
    mbpLogger.logDebug({
        function: 'workerValidateProductFilterZipcode',
        module: 'mbp-pwa-ui',
        message: 'Validate Zipcode',
    });
    try {
        const zipcode = action.payload;
        const accessToken = yield call(checkJWT);
        if (zipcode.length >= 5) {
            const response = yield call(orderClient.getCityStateFromZip, ...[{}, accessToken, zipcode]);
            const { data } = response;
            if (data?.esbSaltaServiceResponse?.checkZipResponse?.checkZipResult?.location?.zipCode) {
                yield put(setValidatedZipcode(zipcode));
                yield put(setProductFilterZipcodeValidity(true));
            } else if (
                data?.esbSaltaServiceResponse?.checkZipResponse?.checkZipResult?.flwsErrors) {
                yield put(setProductFilterZipcodeValidity(false));
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerValidateProductFilterZipcode',
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'workerValidateProductFilterZipcode - validate zipcode failed',
        });
    }
}

function* watchValidateProductFilterZipcode() {
    yield takeLatest(validateProductFilterZipcode().type, workerValidateProductFilterZipcode);
}

function* onVerifyWine({ wineData, addToCart, handleMobileAgeVerification }) {
    // Check for required data
    if (!wineData) {
        return;
    }

    const user = yield select(getCurrentUser);
    const response = yield call(fetchVerifyWine, user.accessToken, wineData);

    const { data } = response;

    if (data) {
        const wineResponse = {
            ...data,
            wineVerify: {
                birthDay: wineData.birthDay,
                birthMonth: wineData.birthMonth,
                birthYear: wineData.birthYear,
                minAge: wineData.minAge,
            },
        };
        yield put(setWineData(wineResponse));
    }

    if (!data || data.error || !isWineAvailable(data)) {
        yield put(verifyWineFailed(data));
        yield put(setAgeVerifyFlagInvalid(false));
        return;
    }

    yield put(verifyWineDone(data));

    if (addToCart && addToCart.partNumber) {
        const {
            reqObj,
            history,
            partNumber,
            categoryId,
            categoryName,
            categoryPath,
            enableMinicart,
            handleMiniCartModalClick,
            personalization,
            isMobileData,
        } = addToCart;

        let item = reqObj;
        if (wineData?.birthDay) {
            item = {
                ...reqObj,
                wineVerify: {
                    birthDay: wineData.birthDay,
                    birthMonth: wineData.birthMonth,
                    birthYear: wineData.birthYear,
                    minAge: wineData.minAge,
                },
            };
        }
        handleMobileAgeVerification(false);
        yield put(addToCartFood(item, history, partNumber, categoryId, categoryName, categoryPath, enableMinicart, handleMiniCartModalClick, personalization, isMobileData));
    }
}

const getSuggestionData = (params) => axios.get('https://brm-suggest-0.brsrvr.com/api/v1/suggest/?', { params }).then((response) => response.data);

function* onSearchResult(searchValue) {
    const brand = yield select(getBrand);
    const { bloomreach } = brandThemes[brand?.code] || {};
    const APP_BLOOMREACH_AUTH_KEY = mbpUtil.getEnv('APP_BLOOMREACH_AUTH_KEY');

    if (!bloomreach?.account_id || !APP_BLOOMREACH_AUTH_KEY) {
        return;
    }

    const cookies = new Cookies();
    const br_uid_2 = cookies.get('_br_uid_2');
    const params = {
        account_id: bloomreach.account_id,
        auth_key: APP_BLOOMREACH_AUTH_KEY,
        domain_key: bloomreach?.domain_key || '',
        request_id: bloomreach?.request_id || '',
        _br_uid_2: br_uid_2,
        url: bloomreach?.url || '',
        q: searchValue,
        request_type: 'suggest',
        view_id: bloomreach?.view_id || '',
    };
    const Data = yield call(getSuggestionData, params);
    if (Data) {
        yield put(setSearchResult(Data.response));
    }
}

function* watchGetSearchResult() {
    let action = yield take(getSearchResult().type);
    while (action !== END) {
        yield fork(onSearchResult, action.payload);
        action = yield take(getSearchResult().type);
    }
}

function* watchVerifyWine() {
    let action = yield take(verifyWine().type);
    while (action !== END) {
        yield fork(onVerifyWine, action.payload);
        action = yield take(verifyWine().type);
    }
}

function* workerCreateEmailSubscription(data) {
    try {
        const { email } = data;
        yield put(resetEmailSubscription);
        const token = yield call(checkJWT);
        const brand = yield select(getBrand);
        const response = yield call(subscribeEmailApi, { email, brandCode: brand.code, token });
        yield put(setEmailSubscriptionData(response.data));
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerCreateEmailSubscription',
            module: 'mbp-ui-app',
            jsError: ex,
        });
    }
}

function* watchCreateEmailSubscription() {
    let action = yield take(createEmailSubscription().type);
    while (action !== END) {
        yield fork(workerCreateEmailSubscription, action.payload);
        action = yield take(createEmailSubscription().type);
    }
}

function* workerFetchGCIKey(gciPayload) {
    const jwt = yield call(checkJWT);
    const { params } = gciPayload;
    try {
        const response = yield call(fetchGCIKeyAPI, { jwt, params });
        const { data } = response;
        const { gciKeyId } = data || {};
        if (gciKeyId) {
            yield put(setGCIKey(gciKeyId));
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerFetchGCIKey',
            module: 'mbp-ui-app',
            jsError: ex,
            message: 'Failed to load key',
        });
    }
}

function* watcherFetchGCIKey() {
    let action = yield take(fetchGCIKey().type);
    while (action !== END) {
        yield fork(workerFetchGCIKey, action.payload);
        action = yield take(fetchGCIKey().type);
    }
}

function* workerGetProdutFroWishlist(payload) {
    const jwt = yield call(checkJWT);
    const header = {
        headers: {
            'content-type': 'application/json',
            authorization: `Bearer ${jwt}`,
        },
    };
    try {
        const apiResponse = yield call(getUsersProductInWishlist, payload, header);
        if (apiResponse?.status === 200) {
            yield put(setProductInWishlist(apiResponse.data.data));
        } else {
            yield put(setProductInWishlist([]));
            mbpLogger.logError({
                function: 'workerAddProdutToWishlist',
                module: 'mbp-ui-app',
                message: 'Error in wishlist API',
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerAddProdutToWishlist',
            module: 'mbp-ui-app',
            jsError: ex,
            message: 'Error in wishlist API',
        });
    }
}

function* watcherFetchUserWishlist() {
    let action = yield take(getUsersWishlist().type);
    while (action !== END) {
        yield fork(workerGetProdutFroWishlist, action.payload);
        action = yield take(getUsersWishlist().type);
    }
}

const watchers = [fork(watchLoadAppShell), fork(watchValidateProductFilterZipcode), fork(watchVerifyWine), fork(watcherFetchGCIKey), fork(watchGetSearchResult), fork(watchCreateEmailSubscription), fork(watcherFetchUserWishlist)];

const serverWatchers = [fork(watchLoadAppSSR)];

export {
    onLoadAppShell,
    watchers,
    serverWatchers,
    getSearchResult,
    clearSearchResult,
    workerValidateProductFilterZipcode,
    watchValidateProductFilterZipcode,
    onVerifyWine,
    watchVerifyWine,
    verifyWine,
    setWineData,
};
