/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const ErrTextMapping = {
    _ERR_PAY_CARD_NUMBER_INVALID: 'The card number is invalid',
    _ERR_PRODUCT_OUT_OF_STOCK: 'Product is out of stock.',
};

const ErrMessageForErrorKeyMapping = {
    _ERR_PROD_NOT_ORDERABLE: 'Product cannot currently be ordered',
};

export {
    ErrTextMapping,
    ErrMessageForErrorKeyMapping,
};
