/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './GiftMessageForm-ActionTypes';

export const updateGiftMessage = (data) => ({
    type: actionTypes.UPDATE_GIFT_MESSAGE,
    data,
});

export const giftMessageUpdatedSuccessfully = () => ({
    type: actionTypes.GIFT_MESSAGE_UPDATED_SUCCESSFULLY,
});

export const submitGiftMessage = (data) => ({
    type: actionTypes.TRIGGER_ADD_GIFT_MESSAGE,
    actionAs: data,
});

export const handleChange = (data) => ({
    type: actionTypes.TRIGGER_CHANGE_GIFT_MESSAGE,
    data,
});

export const loadGiftMessageChange = (data) => ({
    type: actionTypes.LOAD_GIFT_MESSAGE_CHANGE,
    data,
});

export const loadAuthorChange = (data) => ({
    type: actionTypes.HANDLE_AUTHOR_CHANGE,
    data,
});

export const updateFormStatus = (data) => ({
    type: actionTypes.UPDATE_FORM_STATUS,
    data,
});

export const updateOccasion = (data) => ({
    type: actionTypes.UPDATE_OCCASSION,
    data,
});

export const showGiftMessageForm = (data) => ({
    type: actionTypes.SHOW_GIFT_MESSAGE_FORM,
    data,
});

export const skipGiftMessageForm = (data) => ({
    type: actionTypes.TRIGGER_SKIP_MESSAGE,
    data,
});

export const resetGiftMessageForm = (data) => ({
    type: actionTypes.TRIGGER_RESET_MESSAGE,
    data,
});

export const gciPersonalizationComplete = (data) => ({
    type: actionTypes.TRIGGER_GCI_PERSONALIZATION_COMPLETE,
    data,
});

export default {};
