/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint operator-linebreak: ["error", "none"] */
const SET_READY_STATE_REQUEST =    'mbp-account-ui/gift-list/set/state/ready/REQUEST';
const HANDLE_NAVIGATE_TO_PAGE_REQUEST = 'mbp-account-ui/navigate/to/REQUEST';
const HANDLE_REMOVE_REDIRECT_REQUEST = 'mbp-account-ui/navigate/reset/REQUEST';

const FETCH_GIFT_LIST_EMAIL_ADDRESS_FROM_ID_REQUEST = 'mbp-account-ui/gift-list/landing-page/get/email/REQUEST';
const FETCH_GIFT_LISTS_REQUEST = 'mbp-account-ui/gift-list/get/lists/REQUEST';
const HANDLE_GIFT_LIST_SEND_CODE_TO_EMAIL_REQUEST =    'mbp-account-ui/gift-list/send-code/REQUEST';
const HANDLE_GIFT_LIST_SUBMIT_VERIFICATION_CODE_REQUEST =    'mbp-account-ui/gift-list/submit-verification-code/REQUEST';
const HANDLE_GIFT_LIST_LOGIN_WITH_CUST_NBR_REQUEST =    'mbp-account-ui/gift-list/login-with-custnbr/REQUEST';
const HANDLE_GIFT_LIST_OCCASION_CHANGE_REQUEST =    'mbp-account-ui/gift-list/occasion/change/REQUEST';
const HANDLE_GIFT_LIST_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/gift-list/recipient/click/REQUEST';
const HANDLE_GIFT_LIST_ADD_NEW_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/gift-list/recipient/add/REQUEST';
const HANDLE_GIFT_LIST_EDIT_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/gift-list/recipient/edit/REQUEST';
const HANDLE_GIFT_LIST_RECOMMENDATION_CLICKED_REQUEST =    'mbp-account-ui/gift-list/recommendation/click/REQUEST';
const HANDLE_GIFT_LIST_ADD_TO_CART_REQUEST =    'mbp-account-ui/gift-list/add-to-cart/click/REQUEST';
const HANDLE_GIFT_LIST_CHOOSE_SOMETHING_DIFFERENT_REQUEST =    'mbp-account-ui/gift-list/choose-something-else/click/REQUEST';
const HANDLE_GIFT_LIST_CHECKOUT_CLICKED_REQUEST =    'mbp-account-ui/gift-list/checkout/click/REQUEST';
const HANDLE_GIFT_LIST_SHOP_THE_SITE_CLICKED_REQUEST =    'mbp-account-ui/gift-list/shop-the-site/click/REQUEST';
const HANDLE_GIFT_LIST_CONFIRM_SHOP_THE_SITE_CLICKED_REQUEST =    'mbp-account-ui/gift-list/shop-the-site/confirm/click/REQUEST';
const HANDLE_GIFT_LIST_ENTER_ITEM_NUMBER_CLICKED_REQUEST =    'mbp-account-ui/gift-list/enter-item-number/click/REQUEST';
const HANDLE_GIFT_LIST_PAGINATION_CHANGED_REQUEST =    'mbp-account-ui/gift-list/page/change/REQUEST';
const HANDLE_GIFT_LIST_SUBMIT_LINK_USER_REQUEST =    'mbp-account-ui/gift-list/find-by-customer-or-order/sumbit/REQUEST';

const FETCH_AUTHENTICATION_STATE_REQUEST =    'mbp-account-ui/auth/get-state/REQUEST';
const FETCH_USER_PROFILE_REQUEST =    'mbp-account-ui/home/user-profile/get/REQUEST';
const FETCH_ADDRESS_BOOK_REQUEST = 'mbp-account-ui/address-book/get/REQUEST';
const FETCH_BILLING_ADDRESS_REQUEST =    'mbp-account-ui/billing-address/get/REQUEST';
const FETCH_ORDER_BY_EMAIL_REQUEST = 'mbp-account-ui/orders/email/get/REQUEST';
const FETCH_ORDER_DETAIL_BY_ORDER_NUMBER_REQUEST =    'mbp-account-ui/orders/detail/get/REQUEST';
const FETCH_EMAIL_PREFERENCE_REQUEST =    'mbp-account-ui/email/preference/get/REQUEST';
const FETCH_EMAIL_ADDRESS_REQUEST = 'mbp-account-ui/email/id/get/REQUEST';
const FETCH_SUBSCRIPTIONS_REQUEST = 'mbp-account-ui/subscriptions/get/REQUEST';
const HANDLE_CANCEL_SUBSCRIPTION_REQUEST = 'mbp-account-ui/subscription/cancel/REQUEST';
const HANDLE_OPEN_MODAL_REQUEST = 'mbp-account-ui/modal/open/REQUEST';
const HANDLE_UPDATE_MODAL_REQUEST = 'mbp-account-ui/modal/update/REQUEST';
const HANDLE_CLOSE_MODAL_REQUEST = 'mbp-account-ui/modal/close/REQUEST';
const HANDLE_ACCOUNT_SIGN_IN_REQUEST = 'mbp-account-ui/sign-in/REQUEST';
const HANDLE_SUBMIT_CREATE_ACCOUNT_REQUEST =    'mbp-account-ui/create-account/submit/REQUEST';
const HANDLE_CLICK_RESET_PASSWORD_REQUEST =    'mbp-account-ui/reset-password/click/REQUEST';
const HANDLE_SUBMIT_RESET_PASSWORD_REQUEST =    'mbp-account-ui/reset-password/submit/REQUEST';
const HANDLE_CLEAR_PAGE_DATA_REQUEST = 'mbp-account-ui/clear-page-data/REQUEST';
const HANDLE_CLICK_CONTINUE_SHOPPING_REQUEST =    'mbp-account-ui/continue-shopping/click/REQUEST';
const HANDLE_EDIT_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/recipient/edit/REQUEST';
const HANDLE_SUBMIT_ADD_NEW_RECIPIENT_REQUEST =    'mbp-account-ui/recipient/save/REQUEST';
const HANDLE_REMOVE_RECIPIENT_CLICKED_REQUEST =    'mbp-account-ui/recipient/remove/click/REQUEST';
const HANDLE_SUBMIT_REMOVE_RECIPIENT_REQUEST =    'mbp-account-ui/recipient/remove/REQUEST';
const HANDLE_ADD_NEW_RECIPIENT_REQUEST = 'mbp-account-ui/recipient/add/REQUEST';
const HANDLE_CHANGE_SORT_BY_REQUEST = 'mbp-account-ui/recipient/sortby/REQUEST';
const HANDLE_CLICK_EDIT_BILLING_ADDRESS_REQUEST =    'mbp-account-ui/billing/edit/click/REQUEST';
const HANDLE_SUBMIT_PROFILE_AND_PASSWORD_REQUEST = 'mbp-account-ui/submitprofile/edit/REQUEST';
const HANDLE_UPDATE_FIRSTNAME_LASTNAME_REQUEST = 'mbp-account-ui/firstlastname/edit/REQUEST';
const HANDLE_UPDATE_PASSWORD_REQUEST = 'mbp-account-ui/password/edit/REQUEST';
const HANDLE_CLICK_ADD_NEW_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/add/REQUEST';
const HANDLE_SAVE_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/save/REQUEST';
const HANDLE_CLICK_REMOVE_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/remove/click/REQUEST';
const HANDLE_CLICK_EDIT_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/edit/click/REQUEST';
const HANDLE_REMOVE_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/remove/REQUEST';
const HANDLE_SET_DEFAULT_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/wallet/set-default/REQUEST';
const HANDLE_SUBMIT_ORDER_BY_ZIP_CODE_REQUEST =    'mbp-account-ui/billing/orders/zip-code/REQUEST';
const HANDLE_SUBMIT_ORDER_BY_EMAIL_REQUEST =    'mbp-account-ui/billing/orders/email/REQUEST';
const HANDLE_UPDATE_EMAIL_PREFERENCE_REQUEST =    'mbp-account-ui/email/preference/update/REQUEST';
const HANDLE_UPDATE_NEW_EMAIL_ADDRESS_REQUEST =    'mbp-account-ui/email/id/update/REQUEST';
const HANDLE_VERIFY_ADDRESS_REQUEST =   'mbp-account-ui/qas/address/verify/REQUEST';
const THREAD_SEARCH_ADDRESS_REQUEST =   'mbp-account-ui/qas/address/search/REQUEST';
const THREAD_SELECT_AN_ADDRESS_REQUEST =    'mbp-account-ui/qas/address/select/REQUEST';
const THREAD_SET_ADDRESS_VERIFICATION_STATE_REQUEST =    'mbp-account-ui/qas/address/verification/state/set/REQUEST';
const HANDLE_CLEAR_AUTO_SEARCH_REQUEST = 'mbp-account-ui/qas/address/search/clear/REQUEST';
const HANDLE_AUTH0_LOGIN_CLICKED_REQUEST = 'mbp-account-ui/auth0/login/REQUEST';
const HANDLE_PASSWORDLESS_LOGIN_REQUEST = 'mbp-account-ui/auth0/login/passwordless/REQUEST';
const HANDLE_DESKTOP_ADD_NEW_RECIPIENT_REQUEST = 'mbp-account-ui/desktop/add/new/REQUEST';
const HANDLE_CLOSE_DIALOG_REQUEST = 'mbp-account-ui/desktop/close/dialog/REQUEST';
const HANDLE_DESKTOP_ADD_NEW__FORM_REQUEST = 'mbp-account-ui/desktop/add/new/form/REQUEST';
const HANDLE_DESKTOP_SINGLE_ADDRESS_GET_REQUEST = 'mbp-account-ui/desktop/single/address/get/REQUEST';
const HANDLE_DESKTOP_EDIT_ADDRESS_REQUEST = 'mbp-account-ui/desktop/edit/address/REQUEST';
const HANDLE_DESKTOP_ADDRESS_REMOVE_REQUEST = 'mbp-account-ui/desktop/address/REQUEST';
const HANDLE_DESKTOP_CHANGE_PASSWORD_REQUEST = 'mbp-account-ui/account/dailog/desktop/REQUEST';
const HANDLE_DESKTOP_CHANGE_EMAIL_REQUEST = 'mbp-account-ui/desktopemail/preference/update/REQUEST';
const HANDLE_DESKTOP_CLICK_EDIT_CREDIT_CARD_REQUEST =    'mbp-account-ui/billing/desktop/wallet/edit/click/REQUEST';
const HANDLE_SET_DEFAULT_BILLING_VALUE_REQUEST =    'mbp-account-ui/billing/set/default/value/REQUEST';
const HANDLE_DESKTOP_DIALOG_CONFIRM_CARD_REQUEST = 'mbp-account-ui/billing/desktop/dialog/confirm/card/REQUEST';

const ActionType = {
    FETCH_GIFT_LIST_EMAIL_ADDRESS_FROM_ID_REQUEST,
    FETCH_GIFT_LISTS_REQUEST,
    FETCH_AUTHENTICATION_STATE_REQUEST,
    FETCH_USER_PROFILE_REQUEST,
    FETCH_ADDRESS_BOOK_REQUEST,
    FETCH_BILLING_ADDRESS_REQUEST,
    FETCH_ORDER_BY_EMAIL_REQUEST,
    FETCH_ORDER_DETAIL_BY_ORDER_NUMBER_REQUEST,
    FETCH_EMAIL_PREFERENCE_REQUEST,
    FETCH_EMAIL_ADDRESS_REQUEST,
    FETCH_SUBSCRIPTIONS_REQUEST,
    SET_READY_STATE_REQUEST,
    HANDLE_VERIFY_ADDRESS_REQUEST,
    THREAD_SEARCH_ADDRESS_REQUEST,
    THREAD_SELECT_AN_ADDRESS_REQUEST,
    THREAD_SET_ADDRESS_VERIFICATION_STATE_REQUEST,
    HANDLE_CLEAR_AUTO_SEARCH_REQUEST,
    HANDLE_GIFT_LIST_SEND_CODE_TO_EMAIL_REQUEST,
    HANDLE_GIFT_LIST_SUBMIT_VERIFICATION_CODE_REQUEST,
    HANDLE_GIFT_LIST_LOGIN_WITH_CUST_NBR_REQUEST,
    HANDLE_GIFT_LIST_OCCASION_CHANGE_REQUEST,
    HANDLE_GIFT_LIST_PAGINATION_CHANGED_REQUEST,
    HANDLE_GIFT_LIST_RECIPIENT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_ADD_NEW_RECIPIENT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_RECOMMENDATION_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_ADD_TO_CART_REQUEST,
    HANDLE_GIFT_LIST_CHOOSE_SOMETHING_DIFFERENT_REQUEST,
    HANDLE_GIFT_LIST_SHOP_THE_SITE_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_CONFIRM_SHOP_THE_SITE_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_SUBMIT_LINK_USER_REQUEST,
    HANDLE_NAVIGATE_TO_PAGE_REQUEST,
    HANDLE_REMOVE_REDIRECT_REQUEST,
    HANDLE_EDIT_RECIPIENT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_EDIT_RECIPIENT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_CHECKOUT_CLICKED_REQUEST,
    HANDLE_GIFT_LIST_ENTER_ITEM_NUMBER_CLICKED_REQUEST,
    HANDLE_ACCOUNT_SIGN_IN_REQUEST,
    HANDLE_SUBMIT_CREATE_ACCOUNT_REQUEST,
    HANDLE_CLICK_RESET_PASSWORD_REQUEST,
    HANDLE_SUBMIT_RESET_PASSWORD_REQUEST,
    HANDLE_CLEAR_PAGE_DATA_REQUEST,
    HANDLE_OPEN_MODAL_REQUEST,
    HANDLE_UPDATE_MODAL_REQUEST,
    HANDLE_CLOSE_MODAL_REQUEST,
    HANDLE_CLICK_CONTINUE_SHOPPING_REQUEST,
    HANDLE_REMOVE_RECIPIENT_CLICKED_REQUEST,
    HANDLE_SUBMIT_REMOVE_RECIPIENT_REQUEST,
    HANDLE_SUBMIT_ADD_NEW_RECIPIENT_REQUEST,
    HANDLE_ADD_NEW_RECIPIENT_REQUEST,
    HANDLE_CHANGE_SORT_BY_REQUEST,
    HANDLE_CLICK_EDIT_BILLING_ADDRESS_REQUEST,
    HANDLE_SUBMIT_PROFILE_AND_PASSWORD_REQUEST,
    HANDLE_UPDATE_FIRSTNAME_LASTNAME_REQUEST,
    HANDLE_UPDATE_PASSWORD_REQUEST,
    HANDLE_CLICK_ADD_NEW_CREDIT_CARD_REQUEST,
    HANDLE_SAVE_CREDIT_CARD_REQUEST,
    HANDLE_CLICK_REMOVE_CREDIT_CARD_REQUEST,
    HANDLE_REMOVE_CREDIT_CARD_REQUEST,
    HANDLE_SET_DEFAULT_CREDIT_CARD_REQUEST,
    HANDLE_CLICK_EDIT_CREDIT_CARD_REQUEST,
    HANDLE_SUBMIT_ORDER_BY_ZIP_CODE_REQUEST,
    HANDLE_SUBMIT_ORDER_BY_EMAIL_REQUEST,
    HANDLE_UPDATE_EMAIL_PREFERENCE_REQUEST,
    HANDLE_UPDATE_NEW_EMAIL_ADDRESS_REQUEST,
    HANDLE_CANCEL_SUBSCRIPTION_REQUEST,
    HANDLE_AUTH0_LOGIN_CLICKED_REQUEST,
    HANDLE_PASSWORDLESS_LOGIN_REQUEST,
    HANDLE_DESKTOP_ADD_NEW_RECIPIENT_REQUEST,
    HANDLE_CLOSE_DIALOG_REQUEST,
    HANDLE_DESKTOP_ADD_NEW__FORM_REQUEST,
    HANDLE_DESKTOP_SINGLE_ADDRESS_GET_REQUEST,
    HANDLE_DESKTOP_EDIT_ADDRESS_REQUEST,
    HANDLE_DESKTOP_ADDRESS_REMOVE_REQUEST,
    HANDLE_DESKTOP_CHANGE_PASSWORD_REQUEST,
    HANDLE_DESKTOP_CHANGE_EMAIL_REQUEST,
    HANDLE_DESKTOP_CLICK_EDIT_CREDIT_CARD_REQUEST,
    HANDLE_SET_DEFAULT_BILLING_VALUE_REQUEST,
    HANDLE_DESKTOP_DIALOG_CONFIRM_CARD_REQUEST,
};

const ActionKeyValue = [];

Object.entries(ActionType).forEach((entry) => {
    const [key, value] = entry;
    ActionKeyValue.push({ key, value });
});

export const getActionKey = (value) => {
    const index = ActionKeyValue.findIndex((item) => item.value === value);
    /* istanbul ignore next */
    if (index > -1) {
        return ActionKeyValue[index].key;
    }
    return false;
};

export const getSuccessType = (type) => type.replace(/\/REQUEST$/, '/LOADED');
export const getFailureType = (type) => type.replace(/\/REQUEST$/, '/FAILURE');

export default ActionType;
