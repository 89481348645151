/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// Used this translation list to convert French and Spanish to English for using in the API call
const locationTranslations = {
    // fr
    Résidence: 'Residence',
    Entreprise: 'Business',
    'Maison funéraire': 'Funeral home',
    Hôpital: 'Hospital',
    Appartement: 'Apartment',
    École: 'School',
    Église: 'Church',
    // es
    Residencia: 'Residence',
    Negocio: 'Business',
    'Casa funeraria': 'Funeral home',
    Hospital: 'Hospital',
    Apartamento: 'Apartment',
    Colegio: 'School',
    Iglesia: 'Church',
};

// App Selectors
export const getAppShellLoadedState = (state) => state.app.shell.loaded;
export const getPageFullyLoadedState = (state) => state.ui.pageFullyLoaded;

// SSR selectors
export const getIsBot = (state) => state.app.ssr.config?.isBot;
export const getSSRHostname = (state) => state.app.ssr.config.hostname;
export const getSSRPath = (state) => state.app.ssr.config.path;
export const getSSRLoadingState = (state) => state.app.ssr.config.loaded;
export const getSSRDeviceType = (state) => state.app.ssr.config.deviceType;
export const getSSRConfig = (state) => state.app.ssr.config;

// Member Selectors
export const getCurrentUser = (state) => state.member.auth;

// UI Selectors
export const getSuggestionData = (state) => state.ui.suggestData;
export const getPassportBundleItemCurrentlyInCart = (state) => state?.ui?.appContext?.passportBundleProductInCart?.passportBundleItemCurrentlyInCart;
export const getAppCountry = (state) => state?.ui?.country;
export const getAppLanguage = (state) => state?.ui?.language;
export const getAppCurrency = (state) => state?.ui?.currency;
export const getEmailSubscriptionStatus = (state) => state?.ui?.emailSubscription || {};
export const getPageType = (state) => state?.ui?.page;

// CGC Card
export const getCGCCardData = (state) => state.ui?.appContext?.cgcCardData || [];

// GuidedNav FindAGiftFast selectors
export const getValidatedZipcode = (state) => state.ui.appContext.validatedZipcode;
export const getIsProductFilterZipcodeValid = (state) => state.ui.appContext.isProductFilterZipcodeValid;
export const getUserSubmittedProductFilterZipcode = (state) => state.ui.appContext.userSubmittedProductFilterZipcode;
export const getLocationType = (state) => locationTranslations[state.ui.appContext.locationType] || state.ui.appContext.locationType;
export const getProductFilterOccasion = (state) => state.ui.appContext.occasion;
// export const getCategoryLockStatus = state => state.ui.category.locked; // not in use

// Address Prefill Selectors
export const getSavedAddress = (state) => state.ui?.appContext?.userSubmittedAddress || null;
export const getAddressSource = (state) => state.ui?.appContext?.userSubmittedAddress?.addressSource || null;

// Banner Code Select || nullor
export const getBannerCode = (state) => state.ui?.appContext?.bannerCode || null;

// wine verification for PDP
export const getWineValidation = (state) => state.ui?.pdpCart?.wineData || null;

// submit wine data for PDP
export const getWineData = (state) => state.ui?.appContext?.wineData || null;

// wine verification for PDP age verified
export const getAgeVerificationFlag = (state) => state.ui?.appContext?.isAgeVerified || null;

// NBCU movie skus
export const getBrandMovieSkus = (state) => state.ui?.appContext?.brandMovieSkus;

// Add to cart position in PDP
export const getAddToCartPosition = (state) => state.ui?.appContext?.addToCartPosition || null;

// Config
export const getConfigOption = (state, entry) => {
    if (entry !== '') {
        return state.app.config[entry];
    }

    return null;
};

// GCI
export const getGCIData = (state) => state?.ui?.currentProduct?.gciData || {};
export const getGCIPersonalizationKey = (state) => state?.ui?.currentProduct?.gciKey || '';
export const getCustomCardData = (state) => state?.ui?.appContext?.cgcCustomCardData || {};

// Retail price
export const getProductRetailPrice = (state) => state.ui?.currentProduct?.retailPrice || '';

// Subscription Modal Popup user confirmation flag
export const getUserConfirmationForSubscriptionPopup = (state) => state.ui?.currentProduct?.userConfirmationForSubscriptionPopup || false;

// page Information
export const getPageInformation = (state) => state.ui?.page || null;
// getting currency information
export const getCurrencyData = (state) => state.ui?.appContext?.currencyData;
export const getCountryData = (state) => state.ui?.appContext?.countryData;

// data dictionary
export const getDataDictionary = (state) => state.ui.dataDictionary;

export const getUserWishlist = (state) => state.ui.Wishlist;

export const getUsersProductIdFromWishlist = (state) => state.ui?.Wishlist?.map((item) => item.productId) || [];

export default {};
