/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const GET_ORDER_ID = 'GET_ORDER_ID';
export const LOAD_ORDER_ID = 'LOAD_ORDER_ID';
export const GET_ATLAS_ORDER_ID = 'GET_ATLAS_ORDER_ID';
export const CALL_ATLAS_ORDER_ID = 'CALL_ATLAS_ORDER_ID';
export const LOAD_EXPRESS_CHECKOUT = 'mbp-checkout/LOAD_EXPRESS_CHECKOUT';
export const CLEAR_ORDER_ID_CHECKOUT = 'mbp-checkout/CLEAR_ORDER_ID_CHECKOUT';
export const LOAD_CART_COUNT = 'mbp-checkout/LOAD_CART_COUNT';

export default {
    GET_ORDER_ID,
    LOAD_ORDER_ID,
    CALL_ATLAS_ORDER_ID,
    GET_ATLAS_ORDER_ID,
    LOAD_EXPRESS_CHECKOUT,
    CLEAR_ORDER_ID_CHECKOUT,
    LOAD_CART_COUNT,
};
