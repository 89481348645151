/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, takeEvery, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import memberDucks from '../../../../../Member/ducks';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { errorActions, errorOperations }  from '../../../Common/ducks/Error';
import { retrieveCart } from '../../Cart-Operations';
import { duplicateItem } from './DuplicateItem-Actions';
import { prepareRetreiveCart } from '../../../Checkout/Checkout-Operations';
import { getOrderId } from '../../../Common/Common-Selectors';
import duplicateCart from '../../../../../../../apis/checkout-apis/duplicateCart';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const requestDuplicateItem = ({
    wcEnv, jwtToken, orderItemId, orderId,
}) => duplicateCart(wcEnv, jwtToken, orderItemId, orderId);
/**
 * @description this will send the api to duplicate the item using the itemorder
 * @param {*} action
 */
function* onDuplicateItem(action) {
    try {
        // Show Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Prepare Duplicate
        const { orderItemId, pageProcess, history } = action.data;
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId,
            orderId,
        };

        // Call Duplicate API
        yield call(requestDuplicateItem, configObj);

        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));

        // Prepare Retreive cart
        yield call(prepareRetreiveCart, pageProcess);

        // Fetch the latest cart data
        yield call(retrieveCart);

        if (history && history.location.pathname?.indexOf('payment') >= 0 && pageProcess === 'checkout-food') {
            history.push(`/checkout/shipping/${orderId}`);
        }

        // Hide Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (err) {
        const orderId = yield select(getOrderId);
        mbpLogger.logError({
            orderId,
            orderItem: action.data.orderItemId,
            module: 'mbp-checkout',
            function: 'duplicate item',
            jsError: err,
            message: 'duplicate item Failed',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, err, 'cart');
    }
}

function* watchDuplicateItem() {
    yield takeEvery(duplicateItem().type, onDuplicateItem);
}

const watchers = [
    fork(watchDuplicateItem),
];

export { watchers };

export default {};
