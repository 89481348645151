/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const orderTrackingAPI = (jwt, payload, version = 'v1') => {
    const wcEnv = {
        rootUri: '/',
    };
    const resourcePath = version === 'v2' ? 'retention/selfservice/order/v2/status' : 'retention/selfservice/order/status';
    return restClient.postFacade(wcEnv, resourcePath, jwt, payload, null, null, false).then((response) => response);
};

export default orderTrackingAPI;
