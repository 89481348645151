/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getEnterpriseId = (state) => state.member?.enterpriseId?.enterpriseId;
export const getEnterpriseIdLoadingError = (state) => state?.member?.enterpriseId?.error;
export const getEnterpriseIdLoadingState = (state) => state?.member?.enterpriseId?.loading;

export default {};
