/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import axios from 'axios';
import * as propertyActions from './Properties-ActionTypes';

export const getCheckoutProperties = () => ({
    type: propertyActions.GET_CHECKOUT_PROPERTIES,
});

export const loadCheckoutProperties = (properties) => ({
    type: propertyActions.LOAD_CHECKOUT_PROPERTIES,
    data: properties,
});

export const retriveCheckoutProperties = () => axios.get('properties endpoint goes here');
