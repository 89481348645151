/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { authWatcherSagas } from './ducks/Auth';
import { profileWatcherSagas } from './ducks/Profile';
import { addressBookWatcherSagas } from './ducks/AddressBook';
import { walletWatcherSagas } from './ducks/Wallet';
import { shopRunnerWatcherSagas } from './ducks/Shoprunner';

const watcherSagas = [
    ...authWatcherSagas,
    ...profileWatcherSagas,
    ...addressBookWatcherSagas,
    ...walletWatcherSagas,
    ...shopRunnerWatcherSagas,
];

export default watcherSagas;
