/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import qs from 'qs';

import mbpLogger from 'mbp-logger';

import attributionConstants from './attributionsConstants';
import {
    checkForSearchAttribution,
    setEntryBrandCookie,
    setBannerCookieSession,
    setBannerCookie,
} from './attributionHelpers';

const stringfy = (queryObj) => (queryObj !== 'undefined'
    ? Object.keys(queryObj)
        .map((key) => `${key}=${queryObj[key]}`)
        .join('&')
    : '');

const getlandingPageUrl = (lpUrl, qParams) => {
    mbpLogger.logDebug({
        lpUrl,
        qParams,
        function: 'processAttribution | getlandingPageUrl',
        module: 'growth-pwa-ui',
        message: 'getlandingPageUrl | START',
    });

    const newQueryStrObj = qParams;
    const delim = (lpUrl.indexOf('?') !== -1) ? '&' : '?';
    // Build landing page URL
    const nLpUrl = `${lpUrl}${delim}${stringfy(newQueryStrObj)}`;

    mbpLogger.logDebug({
        nLpUrl,
        function: 'processAttribution | getlandingPageUrl',
        module: 'growth-pwa-ui',
        message: 'getlandingPageUrl | landingPageUrl',
    });

    return nLpUrl;
};

const generateBannerDataObject = (bannerData) => {
    const {
        code, eyebrowImage, lsId, promoCode,
    } = bannerData;

    return {
        c: code || '',
        e: eyebrowImage || '',
        l: lsId || '',
        p: promoCode || '',

    };
};

const setBannerRedirect = (parsedQueryParams, location) => {
    const lsid =  (attributionConstants.LSID in parsedQueryParams) ? parsedQueryParams.lsid : '';
    if (parsedQueryParams && location) {
        const landingPageUrl = location.search ? `/${location.search}` : '/';

        const bannerCookieVal = generateBannerDataObject({
            code: parsedQueryParams?.r || parsedQueryParams?.ref || parsedQueryParams?.LocNumber,
            eyebrowImage: '',
            lsId: lsid,
            promoCode: '',
        });

        mbpLogger.logInfo({
            landingPageUrl,
            bannerCookieVal,
            function: 'processAttribution | setBannerRedirect',
            appName: process.env.npm_package_name,
            module: 'growth-pwa-ui',
            message: 'setBannerRedirect | END',
        });

        return {
            landingPageUrl: landingPageUrl || '/',
            bannerCookieVal: JSON.stringify(bannerCookieVal),
        };
    }

    return {
        landingPageUrl: '/',
    };
};

// Handle application attribution tracking
// -> Attribution - Standard
// -> ProcessExpressRequest -> Express Checkout
async function processAttribution(props, processFor, attrBannerCode) {
    let bannerDetails = 'NOT_SET';

    try {
        const {
            featureFlags, location, attributionURL, attributionCookieExpiration,
        } = props;
        const queryParams = (attributionURL) ? attributionURL.search : location.search;
        const parsedQueryParams = (queryParams) ? qs.parse(queryParams.slice(1)) : null;

        if (featureFlags) {
            mbpLogger.logInfo({
                function: 'processAttribution',
                appName: process.env.npm_package_name,
                module: 'growth-pwa-ui',
                message: `processAttribution | START } attrBannerCode "${attrBannerCode}" parsedQueryParams ${props?.location?.search.slice(1)}`,
            });
            let hasBanner = false;
            let bannerCode = '';
            let overrideReferrer = null;

            let hasEntryBrand = false;
            let entryBrand = '';

            if (parsedQueryParams) {
                if (attributionConstants.BANNER_CODE in parsedQueryParams) {
                    hasBanner = 'BANNER_CODE';
                    bannerCode = parsedQueryParams[attributionConstants.BANNER_CODE];
                }

                if (attributionConstants.ITEM_SKU in parsedQueryParams) {
                    hasBanner = 'ITEM_SKU';
                    bannerCode = parsedQueryParams[attributionConstants.ITEM_SKU];
                }

                if (attributionConstants.BANNER_CODE_REF in parsedQueryParams) {
                    hasBanner = 'BANNER_CODE_REF';
                    bannerCode = parsedQueryParams[attributionConstants.BANNER_CODE_REF];
                }

                if (attributionConstants.SCI_LOC_NUMBER in parsedQueryParams) {
                    hasBanner = 'SCI_LOC_NUMBER';
                    entryBrand = parsedQueryParams[attributionConstants.SCI_LOC_NUMBER];
                }

                if (attributionConstants.ENTRY_BRAND in parsedQueryParams) {
                    hasEntryBrand = 'ENTRY_BRAND';
                    entryBrand = parsedQueryParams[attributionConstants.ENTRY_BRAND];
                }

                mbpLogger.logInfo({
                    function: 'processAttribution',
                    appName: process.env.npm_package_name,
                    module: 'growth-pwa-ui',
                    message: `processAttribution | bannerCode "${bannerCode}" from "${hasBanner}`,
                });

                overrideReferrer = parsedQueryParams.testreferrer ? parsedQueryParams.testreferrer : null;
            }

            const defaultSearchBannerCode = checkForSearchAttribution({
                featureFlags,
                overrideReferrer,
            });

            // Set entry brand value in the cookies
            if (hasEntryBrand && entryBrand) {
                setEntryBrandCookie({
                    exprires: attributionCookieExpiration,
                    value: entryBrand,
                });
            }

            // Standard Attribution campaign tracking (utm)
            if (!defaultSearchBannerCode && hasBanner && bannerCode) {
                bannerDetails = setBannerRedirect(parsedQueryParams, location);

                mbpLogger.logInfo({
                    function: 'processAttribution',
                    appName: process.env.npm_package_name,
                    module: 'growth-pwa-ui',
                    bannerDetails,
                    message: 'Standard Attribution ::: banner details',
                });

                if (processFor === 'Attribution' || processFor === 'ProcessExpressRequest') {
                    const {
                        bannerCookieVal,
                    } = bannerDetails;

                    setBannerCookie({
                        expires: attributionCookieExpiration,
                        value: bannerCookieVal,
                    });

                    setBannerCookieSession({
                        value: bannerCookieVal,
                    });

                    mbpLogger.logInfo({
                        function: 'processAttribution',
                        appName: process.env.npm_package_name,
                        module: 'growth-pwa-ui',
                        bannerCookieVal,
                        message: 'Standard attribution ::: cookie set',
                    });

                    return bannerCookieVal;
                }
            }

            // Search attribution tracking
            if (defaultSearchBannerCode) { // referrer like seogoogle
                // Default - Natural
                let code = defaultSearchBannerCode;

                mbpLogger.logInfo({
                    function: 'processAttribution',
                    appName: process.env.npm_package_name,
                    module: 'growth-pwa-ui',
                    code,
                    message: `Search Attribution ::: START | default "${code}"`,
                });

                // With banner or url r/ref param (PAID)
                if (attrBannerCode || bannerCode) {
                    code = attrBannerCode || bannerCode; // r= attribution from redux store - Paid (or URL if redux entry is missing)

                    if (typeof attrBannerCode === 'object') {
                        code = attrBannerCode.code;
                    }

                    mbpLogger.logInfo({
                        function: 'processAttribution',
                        appName: process.env.npm_package_name,
                        module: 'growth-pwa-ui',
                        code,
                        message: `Search Attribution ::: PAID | ${code}"`,
                    });
                } else {
                    mbpLogger.logInfo({
                        function: 'processAttribution',
                        appName: process.env.npm_package_name,
                        module: 'growth-pwa-ui',
                        code,
                        message: `Search Attribution ::: NATURAL | ${code}"`,
                    });
                }

                const nsCookieValue = generateBannerDataObject({
                    code,
                });

                setBannerCookie({
                    expires: attributionCookieExpiration,
                    value: nsCookieValue,
                });

                setBannerCookieSession({
                    value: nsCookieValue,
                });

                mbpLogger.logInfo({
                    function: 'processAttribution',
                    appName: process.env.npm_package_name,
                    module: 'growth-pwa-ui',
                    nsCookieValue,
                    code,
                    message: 'Search Attribution ::: END; cookie set',
                });

                return nsCookieValue;
            }
        }
    } catch (e) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            jsError: e,
            message: 'processAttribution failed',
        });
    }

    mbpLogger.logInfo({
        function: 'processAttribution',
        appName: process.env.npm_package_name,
        module: 'growth-pwa-ui',
        bannerDetails,
        message: 'processAttribution ::: END',
    });

    return bannerDetails;
}

export default {
    processAttribution,
    getlandingPageUrl,
};
