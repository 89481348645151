/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as accountActionTypes from './Account-ActionTypes';

export const accountRegister = (data) => ({
    type: accountActionTypes.SET_ACCOUNT_REGISTER,
    data,
});

export const setAccountMessage = (data) => ({
    type: accountActionTypes.SET_ACCOUNT_MESSAGE,
    data,
});
