/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, takeEvery, call, put,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import * as cardBrandActions from './CreditCardBrand-Actions';
import CardType from './Plugin/CardType/CardType';

const getCardType = (data) => {
    const cardNumber = data.toString().split('-').join('');
    const typeOfCard = CardType(cardNumber);
    if (typeOfCard.length === 1) {
        const [card] = typeOfCard;
        return card.type;
    }
    return '';
};

function* loadCardBrand(action) {
    try {
        const brand =  yield call(getCardType, [action.data]);
        yield put(cardBrandActions.loadCreditCardBrand(brand));
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            function: 'loadCardBrand',
            message: 'Failed finding credit card type',
        });
    }
}

function* loadCardBrandWatcher() {
    yield takeEvery(cardBrandActions.getCreditCardBrand().type, loadCardBrand);
}

const watchers = [
    fork(loadCardBrandWatcher),
];

export { watchers };

export default {};
