/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'recompose';
import scriptLoader from 'react-async-script-loader';
import mbpUtil from 'mbp-api-util';

const appGraphQLEnv = mbpUtil.getEnv('APP_GRAPHQL_ENV') || process.env.NODE_ENV || 'development';
const trustURL = appGraphQLEnv === 'production' ? '1800flowersprod.com' : 'qa.1800flowersprod.com';

const TRUSTARC_NOTICE_URL = `//consent.trustarc.com/notice?domain=${trustURL}&c=teconsent&js=nj&noticeType=bb&gtm=1&cdn=1&irmc=irmlink&text=true`;

const styles = () => ({
    consentBanner: {
        display: 'block',
        position: 'fixed',
        bottom: '0',
        zIndex: '1000',
        width: '100%',
    },
});

const TrustArc = ({ classes }) => (
    <>
        <div className={classes.consentBanner}>
            <div id="consent_blackbar" />
        </div>
    </>
);

TrustArc.propTypes = {
    classes: object.isRequired,
};

const enhance = compose(
    withStyles(styles),
    scriptLoader(TRUSTARC_NOTICE_URL),
);

export default enhance(TrustArc);
