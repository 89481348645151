/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const CARD_ISLE_AVAILABLE = 'CARD_ISLE_AVAILABLE';
export const LOAD_CARD_ISLE = 'LOAD_CARD_ISLE';
export const CLEAR_CARD_ISLE_STATE = 'CLEAR_CARD_ISLE_STATE';
export const SET_CARD_ISLE_SELECTED = 'SET_CARD_ISLE_SELECTED';
export const ADD_CARD_ISLE_SELECTED = 'ADD_CARD_ISLE_SELECTED';
export const ADD_CARD_ISLE_SELECTED_COMPLETE = 'ADD_CARD_ISLE_SELECTED_COMPLETE';
