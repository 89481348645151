/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { combineReducers } from 'redux';
import profileReducer from './ducks/Profile';
import authReducer from './ducks/Auth';
import shopRunnerReducer from './ducks/Shoprunner';
import addressBookReducer from './ducks/AddressBook';
import walletReducer from  './ducks/Wallet';
import abTestingReducer from './ducks/ABTesting';

export default combineReducers({
    profile: profileReducer,
    auth: authReducer,
    shopRunner: shopRunnerReducer,
    addressBook: addressBookReducer,
    wallet: walletReducer,
    abTesting: abTestingReducer,
});
