/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    fork, take, call, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

import memberDucks from '../../../../../Member/ducks';
import * as updateCartActions from './UpdateCart-Actions';
import { commonSelectors } from '../../../Common';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { errorActions, errorOperations } from '../../../Common/ducks/Error';
import { retrieveCart } from '../../Cart-Operations';
import { checkoutOperations } from '../../../Checkout';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;
const { prepareRetreiveCart } = checkoutOperations;

const updateCart = (reqObj) => orderClient.updateCart(
    reqObj.wcEnv,
    reqObj.jwtToken,
    reqObj.orderItemId,
    reqObj.quantity,
    reqObj.updateDeliveryDate,
    null,
    reqObj.orderId,
    reqObj?.personalization || null,
);

function* updateOrder(action) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: 'error',
        }));

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);

        switch (action.updateOrderProps.updateType) {
            case 'delivery': {
                const reqObj = {
                    wcEnv: {},
                    jwtToken,
                    orderItemId: action.data.orderItemId,
                    quantity: action.data.quantity,
                    updateDeliveryDate: action.data.updateDeliveryDate,
                    orderId,
                };
                yield call(updateCart, reqObj);
                yield call(prepareRetreiveCart, action.updateOrderProps.page);
                yield call(retrieveCart);
                yield call(action.closeDeliveryCalendar);
                break;
            }
            case 'quantity': {
                const reqObj = {
                    wcEnv: {},
                    jwtToken,
                    orderItemId: action.data.orderItemId,
                    quantity: action.data.quantity,
                    orderId,
                };
                yield call(updateCart, reqObj);
                yield call(prepareRetreiveCart, action.updateOrderProps.page);
                yield call(retrieveCart);
                break;
            }
            case 'personalization': {
                const reqObj = {
                    wcEnv: {},
                    jwtToken,
                    orderItemId: action.data.orderItemId,
                    quantity: null,
                    updateDeliveryDate: {},
                    orderId,
                    personalization: action.data.personalization,
                };

                yield call(updateCart, reqObj);
                yield call(prepareRetreiveCart, action.updateOrderProps.page);
                yield call(retrieveCart);
                break;
            }
            default:
                return;
        }

        // Hide Site Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
        yield call(retrieveCart);
        mbpLogger.logError({
            action,
            module: 'mbp-pwa-ui',
            function: 'updateOrder',
            jsError: ex,
            message: 'Update Operation: update order failed.',
        });
    }
}

function* updateOrderWatcher() {
    let action = yield take(updateCartActions.updateOrder().type);
    while (action !== END) {
        yield call(updateOrder, action);
        action = yield take(updateCartActions.updateOrder().type);
    }
}

const watchers = [
    fork(updateOrderWatcher),
];

export { watchers };

export default {};
