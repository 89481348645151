/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
/* eslint-disable no-param-reassign */

import restClient from '../../app/helpers/restClient';

const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_FACADE = '/payment';
const RESOURCE_PAGE_ACTION = '/clienttoken';

const getPaymentClientToken = (wcEnv, jwtToken) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = `${RESOURCE_PAGE_FACADE}${RESOURCE_PAGE_ACTION}`;

    return restClient.getFacade(env, resourcePage, jwtToken);
};

export default getPaymentClientToken;
