/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, call, takeEvery, put, select,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';
import { duck as tmDuck } from '../../../../../TagManager';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';
import memberDucks from '../../../../../Member/ducks';
import * as commonSelectors from '../../../Common/Common-Selectors';
import { unSubscribePassport } from '../../../Payment/ducks/Passport/Passport-Action';
import { setWorkingRecipient } from '../../../Recipient/Recipient-Actions';
import { recipientFormFields } from '../../../Recipient/ducks/RecipientForm/RecipientForm-Helpers';
import * as formActions from '../../../Recipient/ducks/RecipientForm/RecipientForm-Actions';
import { formatFullGifMessage } from '../../../Recipient/ducks/GiftMessageForm/GiftMessageForm-Operations';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { errorActions, errorOperations }  from '../../../Common/ducks/Error';
import { retrieveCart } from '../../Cart-Operations';
import { deleteItem, deleteItemFood, deleteMiniCartItem } from './DeleteItem-Actions';
import { checkoutOperations } from '../../../Checkout';
import { checkIsInternational, getInternationalCountryField, getCountryField } from '../../../Checkout/Checkout-Helper';
import { getPassportBundleItemCurrentlyInCart, getProductRetailPrice } from '../../../../../App/App-Selectors';
import { passportBundleItemRemovedCart, setProductRetailPrice } from '../../../../../App/App-Actions';
import { loadCardIsleAvailable } from '../../../Recipient/ducks/CardIsle/CardIsle-Operations';
import { trackCartUpdate } from '../../../../../TagManager/ducks/TagManager/TagManager-Helpers';

const { prepareRetreiveCart, checkoutRouteHandler } = checkoutOperations;

const {
    auth: {
        authOperations,
    },
    profile: {
        profileActions: {
            setProfileUserRole,
        },
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const removeItem = ({
    wcEnv,
    jwtToken,
    orderItemId,
    orderId,
}) => orderClient.removeOrderItem(wcEnv, jwtToken, orderItemId, orderId);

function* onDeleteItem(action) {
    try {
        const {
            orderItemId, isPassport, typeOrderItem, positionItem, history, skuCode, parentSku, emptyCart,
        } = action.data;
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const orderId = yield select(commonSelectors.getOrderId);
        const jwtToken = yield call(checkJWT);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId,
            orderId,
        };
        const recipientEditingIndex = yield select(commonSelectors.getWorkingRecipient);
        const orderItemCount = yield select(commonSelectors.getNoOfOrderItems);
        const cartDetails = yield select(commonSelectors.getCartData);

        if (emptyCart) {
            if (cartDetails) {
                const recipients = cartDetails.orderDetails?.fdOrderVO?.recipients;
                if (recipients && Array.isArray(recipients) && recipients.length) {
                    // eslint-disable-next-line
                    for (const recipient of recipients) {
                        const orderItems = recipient.orderItems;
                        if (orderItems && Array.isArray(orderItems) && orderItems.length) {
                            // eslint-disable-next-line
                            for (const orderItem of orderItems) {
                                const orderItemObj = {
                                    wcEnv: {},
                                    jwtToken,
                                    orderItemId: orderItem.orderItemsId,
                                    orderId,
                                };
                                yield call(removeItem, orderItemObj);
                            }
                        }
                    }
                }
            }
        } else {
            yield call(removeItem, configObj);
        }
        // clear error
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: orderItemId,
        }));
        const isPassportItemInCart = yield select(getPassportBundleItemCurrentlyInCart);
        if (isPassportItemInCart) {
            yield put(passportBundleItemRemovedCart(skuCode));
        }
        const bundleData = yield select(commonSelectors.getPassportBundleData);
        const passportData = yield select(commonSelectors.getPassportItem);
        const parentSkuCart = passportData?.[0]?.orderItems?.[0]?.catalogEntryVO?.parentSkuCode || null;
        const bundleSkuData = bundleData?.productSku || null;
        if (
            parentSku // Dont delete this if all the elements are undefined is trying delete twice.
            && bundleSkuData // Dont delete this if all the elements are undefined is trying delete twice.
            && parentSkuCart  // Dont delete this if all the elements are undefined is trying delete twice.
            && parentSku === bundleSkuData
            && parentSkuCart === bundleData?.passportSku
        ) {
            const passportOrderId = passportData?.[0]?.orderItems?.[0]?.orderItemsId;
            const passConfigObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: passportOrderId,
                orderId,
            };
            yield call(removeItem, passConfigObj);
        }
        // To remove retail price which has been removed from cart
        // TODO: remove this code once we will get retail price and sale price form cart api for checkout flow
        const listOfRetailPrices = yield select(getProductRetailPrice);
        if (listOfRetailPrices) {
            const modifiedPriceList = listOfRetailPrices.filter((item) => !(Object.keys(item)).includes(orderItemId));
            yield put(setProductRetailPrice(modifiedPriceList));
        }
        // Tealium Tracking Start
        const eventAction = 'Remove from cart';
        const eventName = 'feature_interaction';
        let removeItemTracker = {};
        if (isPassport) {
            removeItemTracker = {
                eventCategory: 'Checkout',
                eventAction,
                eventName,
                eventLabel: 'Passport|<<pageType>>',
            };
            yield put(unSubscribePassport());
            yield put(setProfileUserRole(''));
        } else {
            removeItemTracker = {
                eventCategory: 'Checkout',
                eventAction,
                eventName,
                eventLabel: `${skuCode}|<<pageType>>`,
            };
        }
        yield put(tmDuck.actions.trackEvent(removeItemTracker));
        // Tealium Tracking Ends
        yield call(prepareRetreiveCart, typeOrderItem);
        yield call(retrieveCart);
        yield call(checkoutRouteHandler, history);
        const flagState = yield select(commonSelectors.getFlags);
        if (typeof window !== 'undefined' && window.location.href.indexOf('/shipping/') > 0) {
            const cIEnabled = flagState['is-card-isle-enabled'] || false;
            if (cIEnabled) {
                // Review the product has card isle available.
                yield call(loadCardIsleAvailable);
            }
            const totalItems = String(orderItemCount - 1);
            if (positionItem === totalItems || positionItem < recipientEditingIndex) {
                const newIndexEdit = recipientEditingIndex > 0 ? recipientEditingIndex - 1 : recipientEditingIndex;
                yield put(setWorkingRecipient((newIndexEdit)));
            }
            const [editingRecipient] = yield select(commonSelectors.getCurrentEditingRecipient);
            if (editingRecipient?.recipientAddress) {
                const recipientDetails = editingRecipient.recipientAddress;
                const [orderItems] = editingRecipient?.orderItems || [];
                const isInternational = yield call(checkIsInternational, orderItems);
                const fillRecipientDetails = yield call(recipientFormFields, recipientDetails, orderItems);
                if (isInternational) {
                    const countryField = yield call(getInternationalCountryField, orderItems);
                    fillRecipientDetails.country = countryField;
                    fillRecipientDetails.state = 'OS';
                    fillRecipientDetails.zipCode = '00000';
                } else {
                    fillRecipientDetails.country = yield call(getCountryField, recipientDetails?.country);
                }
                // clear recipient
                yield put(formActions.clearRecipientFormFields());
                if (recipientDetails?.firstName) {
                    const { firstName, lastName, phoneNo } = recipientDetails;
                    if (firstName && lastName && phoneNo) {
                        fillRecipientDetails.isFormValid = true;
                    } else {
                        fillRecipientDetails.isFormValid = false;
                    }
                    yield put(formActions.updateFormAddressFromPreFill(fillRecipientDetails));
                } else {
                    fillRecipientDetails.isFormValid = false;
                    yield put(formActions.updateFormAddressFromPreFill(fillRecipientDetails));
                }
                // Load CityList and State from Zipcode
                if (fillRecipientDetails?.zipCode && !isInternational) {
                    yield put(formActions.triggerStateCityData(fillRecipientDetails.zipCode));
                }
                if (editingRecipient?.orderItems?.length > 0 &&  editingRecipient.orderItems[0]?.field2) {
                    const giftMessageData = editingRecipient.orderItems[0].field2;
                    yield call(formatFullGifMessage, giftMessageData);
                }
            }
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        // Retrive current cart
        // Send cart snapshot to cart track event
        const fdOrder = yield select(commonSelectors.getFDOrder);
        trackCartUpdate(flagState, 'EventRemoveFromCart', fdOrder);
    } catch (ex) {
        mbpLogger.logError({
            orderItem: action.data,
            module: 'mbp-checkout',
            function: 'delete item',
            jsError: ex,
            message: 'delete item Failed',
        });
        // yield put(callHandleCartFailure(ex));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
    }
}

function* watchDeleteItem() {
    yield takeEvery(deleteItem().type, onDeleteItem);
}

function* workerHandleDeleteItem(action) {
    try {
        // Show Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Prepare Delete
        const {
            orderItemId, isPassport, history, page, skuCode, parentSku,
        } = action.data;
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId,
            orderId,
        };

        // Call Delete Item API
        yield call(removeItem, configObj);

        const bundleData = yield select(commonSelectors.getPassportBundleData);
        if (parentSku === bundleData?.productSku) {
            const passportData = yield select(commonSelectors.getPassportItem);
            let passportOrderId;
            if (passportData?.[0]?.orderItems?.[0]?.catalogEntryVO?.parentSkuCode === bundleData?.passportSku) {
                passportOrderId = passportData?.[0]?.orderItems?.[0]?.orderItemsId;
            }
            const passConfigObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: passportOrderId,
                orderId,
            };
            yield call(removeItem, passConfigObj);
        }

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));

        // Tealium Tracking Start
        const eventAction = 'Remove from cart';
        const eventName = 'feature_interaction';
        let removeItemTracker = {};
        if (isPassport) {
            removeItemTracker = {
                eventCategory: '<<pageType>> page',
                eventAction,
                eventName,
                eventLabel: 'Passport|<<pageType>>',
            };
        } else {
            removeItemTracker = {
                eventCategory: '<<pageType>> page',
                eventAction,
                eventName,
                eventLabel: `${skuCode}|<<pageType>>`,
            };
        }
        yield put(tmDuck.actions.trackEvent(removeItemTracker));
        // Tealium Tracking Ends

        yield call(prepareRetreiveCart, page);

        // Get Latest Cart
        yield call(retrieveCart);

        yield call(checkoutRouteHandler, history);

        // Hide Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            orderId,
            orderItem: action.data,
            module: 'mbp-checkout',
            function: 'delete item',
            jsError: ex,
            message: 'delete item Failed',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
    }
}

function* workerHandleMiniCartDeleteItem(action) {
    try {
        // Show Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Prepare Delete
        const {
            orderItemId, page, parentSku, isPassport,
        } = action.data;
        const jwtToken = yield call(checkJWT);
        const orderId = yield select(commonSelectors.getOrderId);
        const configObj = {
            wcEnv: {},
            jwtToken,
            orderItemId,
            orderId,
        };

        // Call Delete Item API
        yield call(removeItem, configObj);

        const bundleData = yield select(commonSelectors.getPassportBundleData);
        const passportData = yield select(commonSelectors.getPassportItem);

        if (
            parentSku // Dont delete this if all the elements are undefined is trying delete twice.
            && bundleData // Dont delete this if all the elements are undefined is trying delete twice.
            && passportData?.[0]?.orderItems?.[0]?.catalogEntryVO?.parentSkuCode  // Dont delete this if all the elements are undefined is trying delete twice.
            && parentSku === bundleData?.productSku
            && passportData[0].orderItems[0].catalogEntryVO.parentSkuCode === bundleData?.passportSku
            && passportData?.[0]?.orderItems?.[0]?.orderItemsId
        ) {
            const passportOrderId = passportData?.[0]?.orderItems?.[0]?.orderItemsId;
            const passConfigObj = {
                wcEnv: {},
                jwtToken,
                orderItemId: passportOrderId,
                orderId,
            };
            yield call(removeItem, passConfigObj);
        }

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'mini cart',
            field: 'error',
        }));

        // Tealium Tracking Start
        const eventAction = 'Remove from cart';
        const eventName = 'feature_interaction';
        let removeItemTracker = {};
        if (isPassport) {
            removeItemTracker = {
                eventCategory: '<<pageType>> page',
                eventAction,
                eventName,
                eventLabel: 'Passport|<<pageType>>',
            };
        } else {
            removeItemTracker = {
                eventCategory: '<<pageType>> page',
                eventAction,
                eventName,
                eventLabel: `${parentSku}|<<pageType>>`,
            };
        }
        yield put(tmDuck.actions.trackEvent(removeItemTracker));
        // Tealium Tracking Ends

        const cartCount = yield select(commonSelectors.getCartCount);
        if (cartCount - 1 > 0) {
            yield call(prepareRetreiveCart, page);
        }

        // Get Latest Cart
        yield call(retrieveCart);

        // Hide Loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        const orderId = yield select(commonSelectors.getOrderId);
        mbpLogger.logError({
            orderId,
            orderItem: action.data,
            appName: process.env.npm_package_name,
            module: 'mbp-pwa-ui',
            function: 'mini cart delete item',
            jsError: ex,
            message: 'mini cart delete item Failed',
        });
        // yield put(callHandleCartFailure(ex));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        yield call(errorOperations.getError, ex, 'cart');
    }
}

function* watchHandleDeleteItem() {
    yield takeEvery(deleteItemFood().type, workerHandleDeleteItem);
}

function* watchHandleMiniCartDeleteItem() {
    yield takeEvery(deleteMiniCartItem().type, workerHandleMiniCartDeleteItem);
}

const watchers = [
    fork(watchDeleteItem),
    fork(watchHandleDeleteItem),
    fork(watchHandleMiniCartDeleteItem),
];

export { watchers, removeItem };

export default {};
