/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, take, put, call, select,
} from 'redux-saga/effects';
import { END } from 'redux-saga';
import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

import memberDucks from '../../../../../Member/ducks';
import * as actions from './DTW-Actions';
import * as actionTypes from './DTW-ActionTypes';

import * as commonSelector from '../../Common-Selectors';
import * as cartActions from '../../../Cart/Cart-Actions';
import * as showLoadingMessageActions from '../ShowLoadingMessage/ShowLoadingMessage-Actions';
import * as errorActions from '../Error/Error-Actions';
import * as errorOperations from '../Error/Error-Operations';

/*
import {
    calculateShipping,
    calculateShippingComplete,
} from '../../../Checkout/Checkout-Actions';
import * as passportActions from '../../../Payment/ducks/Passport/Passport-Action';
*/

import { checkoutOperations } from '../../../Checkout';

const { prepareRetreiveCart } = checkoutOperations;

const {
    auth: {
        // authActions,
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const getDeliveryTimeWindow = ({
    jwtToken, orderItemId, deliveryDate, orderId,
}) => orderClient.getDeliveryTimeWindow({}, jwtToken, orderItemId, deliveryDate, orderId);

const updateCart = (reqObj) => orderClient.updateCart(
    reqObj.wcEnv,
    reqObj.jwtToken,
    reqObj.orderItemId,
    null,
    reqObj.delivery,
    null,
    reqObj.orderId,
);

function* onGetDTW(action) {
    try {
        const { data } = action;
        const JWT_TOKEN = yield call(checkJWT);
        const orderId = yield select(commonSelector.getOrderId);

        if (orderId) {
            const reqObj = {
                jwtToken: JWT_TOKEN,
                orderItemId: data.orderItemId,
                deliveryDate: data.deliveryDate,
                orderId,
            };
            yield put(showLoadingMessageActions.updateShowLoadingMessage());
            const getDTWResponse = yield call(getDeliveryTimeWindow, reqObj);
            if (
                getDTWResponse
                && getDTWResponse.data
                && getDTWResponse.data.options
                && getDTWResponse.data.options.length > 0
            ) {
                const standardDelivery = getDTWResponse.data.options[getDTWResponse.data.options.length - 1];
                getDTWResponse.data.options.pop();
                getDTWResponse.data.options.splice(0, 0, standardDelivery);
            }

            yield put(actions.updateDTWOptions(getDTWResponse.data));
        } else {
            mbpLogger.logError({
                data: action.data,
                module: 'DTW-Operations.js',
                function: 'onGetDTW',
                message: 'onGetDTW - no OrderId',
            });
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
        mbpLogger.logError({
            data: action.data,
            module: 'DTW-Operations.js',
            function: 'onGetDTW',
            jsError: ex,
            message: 'onGetDTW',
            windowLocation,
        });
    }
}

function* watchOnGetDTW() {
    let action = yield take(actions.getDTW().type);
    while (action !== END) {
        yield fork(onGetDTW, action.data);

        action = yield take(actions.getDTW().type);
    }
}

function* onSubmitDTW(action) {
    try {
        const { data } = action;
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.clearErrors({
            errType: 'dtw',
            field: 'error',
        }));
        if (data) {
            const JWT_TOKEN = yield call(checkJWT);
            const orderId = yield select(commonSelector.getOrderId);
            const reqObj = {
                wcEnv: {},
                jwtToken: JWT_TOKEN,
                orderId,
                orderItemId: data.orderItemId,
                delivery: {
                    dtwAmount: data.amount,
                    dtwName: data.name,
                    dtwOptionId: data.dtwId,
                },
            };
            const response = yield call(updateCart, reqObj);
            if (response.data.successFlag === true) {
                yield call(prepareRetreiveCart, 'review');
                yield put(cartActions.getCart());
            }
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            data: action.data,
            module: 'mbp-pwa-ui',
            function: 'onSubmitDTW',
            jsError: ex,
            message: 'Submit DTW option failed',
        });
        yield call(errorOperations.getError, ex, 'dtw');
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* watchOnSubmitDTW() {
    let action = yield take(actions.submitDTW().type);

    while (action !== END) {
        yield call(onSubmitDTW, action);
        action = yield take(actions.submitDTW().type);
    }
}

function* onDTWSelect(action) {
    try {
        const options = yield select(commonSelector.getDTWOptions);
        const foundOption = options.find((option) => option.name === action.data);
        yield put(actions.updateSelectedDTW(foundOption));
    } catch (ex) {
        mbpLogger.logError({
            data: action.data,
            module: 'mbp-pwa-ui',
            function: 'onDTWSelect',
            jsError: ex,
            message: 'onDTWSelect',
        });
    }
}

function* watchOnDTWSelect() {
    let action = yield take(actionTypes.SELECT_DTW);

    while (action !== END) {
        yield call(onDTWSelect, action);
        action = yield take(actionTypes.SELECT_DTW);
    }
}

const watchers = [
    fork(watchOnDTWSelect),
    fork(watchOnGetDTW),
    fork(watchOnSubmitDTW),
];

export { watchers };

export default {};
