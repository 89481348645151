/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
import * as actions from './RetryCalculateShipping-Actions';

const reducerRetryCalculateShipping = (state = initialState.recipient.calShipping, action) => {
    switch (action.type) {
        case actions.updateRetryCalculateShippingAttempt().type:
            return {
                ...state,
                calShippingChargesAttempt: state.calShippingChargesAttempt + 1,
            };
        case actions.clearCalculateShippingAttempt().type:
            return {
                ...state,
                calShippingChargesAttempt: 0,
            };
        case actions.updateCalculateShippingApplied().type:
            return {
                ...state,
                calShippingApplied: action.data,
            };
        default:
            return state;
    }
};

export default reducerRetryCalculateShipping;
