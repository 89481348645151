/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Promotion-ActionTypes';

export const updateFieldPromotionForm = (data) => ({
    type: actionTypes.UPDATE_FIELD_PROMOTION_FORM,
    data,
});

export const clearAllFieldPromotionForm = () => ({
    type: actionTypes.CLEAR_ALL_FIELD_PROMOTION_FORM,
});
