/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';

import * as actions from './CreditCardBrand-Actions';

const creditCardBrandReducer = (state = initialState.payment.creditCardBrand, action) => {
    switch (action.type) {
        case actions.loadCreditCardBrand().type:
            return action.data;
        default:
            return state;
    }
};

export default creditCardBrandReducer;
