/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 * CartService APIs
 */

import createLabelGreetingCard from './createLabelGreetingCard';
import donation from './donation';
import mergeCart from './mergeCart';
import generateGreetingCard from './generateGreetingCard';
import getLabelGreetingCard from './getLabelGreetingCard';
import recordAttributions from './recordAttributions';
import uploadImageGreetingCard from './uploadImageGreetingCard';
import getCartCount from './getCartCount';

const cartServices = {
    createLabelGreetingCard,
    donation,
    generateGreetingCard,
    getLabelGreetingCard,
    mergeCart,
    recordAttributions,
    uploadImageGreetingCard,
    getCartCount,
};

export default cartServices;
