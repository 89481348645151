/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpUtil from 'mbp-api-util';

import * as chasePayActions from './ChasePay-Actions';
import * as chasePayOperations from './ChasePay-Operations';

// Plugin Config
const chasePayPluginConfig = {
    sdkURL: mbpUtil.getEnv('APP_CHASEPAY_SDK_URL'),
};

export { chasePayPluginConfig, chasePayActions, chasePayOperations };
