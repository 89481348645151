/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import brandConfig from '../../../../../app/components/AppShell/brandtheme';

const brandsByBrandId = {
    TPF: 'thepopcornfactory',
    CCO: 'cheryls',
    '18F': 'flowers',
    '18B': 'baskets',
    FBQ: 'fruitbouquets',
    HD: 'harryanddavid',
    WF: 'wolfermans',
    SY: 'stockyards',
    SCH: 'simplychocolate',
    CEL: 'celebrations',
    NAPCO: 'napcoimports',
    GOODSEY: 'goodsey',
    '08F': '0800flowers',
    CAN: 'canada',
    PLA: 'plants',
};

export const getBrandById = (brandId) => {
    let brand = brandConfig['18F'];
    if (brandId) {
        brand = brandConfig[brandId];
    }
    return brand;
};

export const getBrandByBrandId = (currentBrandId) => {
    let curBrand = '';

    if (currentBrandId) {
        Object.keys(brandsByBrandId).forEach((brandId) => {
            if (brandId === currentBrandId) {
                curBrand = brandsByBrandId[brandId];
            }
        });
    }
    return curBrand || 'flowers';
};

export default {};
