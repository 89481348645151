/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import * as app from '../../ducks/App';
import * as CGCActions from '../../ducks/CGC/CGC-Actions';
import utils from '../../utils';

import { addPassportBundleItem, removePassportBundleItem } from '../../../app/helpers/passportBundleItemStateMutators';

const appActions = app.actions;
const brandActions = app.ducks.brand.actions;

export const INITIAL_STATE = {
    // isLoading: false, //TODO: https://www.google.com/search?q=react+ui+isLoading+pattern&rlz=1C1GGRV_enUS749US750&oq=react+ui+isLoading+pattern&aqs=chrome..69i57.6150j1j7&sourceid=chrome&ie=UTF-8
    // pageId: null,
    urlId: null,
    brandId: null,
    path: null,
    appContext: {
        locationType: 'Residence',
        occasion: {
            name: '',
            code: '',
        },
        validatedZipcode: '',
        userSubmittedProductFilterZipcode: '',
        isProductFilterZipcodeValid: null,
        userSubmittedAddress: {},
        bannerCode: '',
        passportBundleProductInCart: {
            passportBundleItemCurrentlyInCart: false,
            passportBundleItemSkus: {},
        },
        wineData: null,
        cgcCardData: [],
        cgcCustomCardData: {},
        brandMovieSkus: {},
        chatBotOpen: false,
        currencyData: {
            currencyName: 'USD',
            currencySymbol: '$',
        },
        countryData: {},
        languageData: {},
    },
    currentProduct: {
        gciData: null,
        gciKey: null,
        addToCartPosition: null,
        userConfirmationForSubscriptionPopup: false,
    },
    pdpCart: {
        parentProductBrandId: null,
        selectedSKUProduct: null,
        selectedCYOProducts: null,
        selectedCYOComponentCount: 0,
        selectedPYOProducts: null,
        maxCYOComponents: 0,
        recipients: null,
        zipcodeData: null,
        wineData: null,
    },
    suggestData: {},
    country: '',
    language: '',
    currency: '',
    emailSubscription: {},
    dataDictionary: {},
    Wishlist: [],
};

const uiPersistConfig = {
    key: 'ui',
    storage: sessionStorage,
    whiteList: [
        'appContext',
    ],
};

export default persistReducer(uiPersistConfig, utils.createReducer(INITIAL_STATE)({
    // App
    [appActions.setAppShellLoadState().type]: (state, action) => createState(state || INITIAL_STATE, ['pageShellLoaded', action.payload]),
    [brandActions.brandLoaded().type]: (state, action) => createState(state || INITIAL_STATE, ['brandId', action.id]),
    [appActions.setSearchResult().type]: (state, action) => createState(state || INITIAL_STATE, ['suggestData', action.payload]),
    [appActions.clearSearchResult().type]: (state, action) => createState(state || INITIAL_STATE, ['suggestData', INITIAL_STATE.suggestData || action.payload]),
    // These two reducers change the category locked status in state.
    // [appActions.lockCategoryPage().type]: state =>
    //   createState(state || INITIAL_STATE, ['category.locked', true]),
    // [appActions.unlockCategoryPage().type]: state =>
    //   createState(state || INITIAL_STATE, ['category.locked', false]),

    // App Context
    // These two reducers set and clear the zipcode which is used to filter products based on availability.
    [appActions.setValidatedZipcode().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.validatedZipcode', action.payload]),
    [appActions.clearValidatedZipcode().type]: (state) => createState(state || INITIAL_STATE, ['appContext.validatedZipcode', '']),
    [appActions.setUserSubmittedProductFilterZipcode().type]: (state, action) => createState(state || INITIAL_STATE, [
        'appContext.userSubmittedProductFilterZipcode',
        action.payload,
    ]),
    [appActions.clearUserSubmittedProductFilterZipcode().type]: (state) => createState(state || INITIAL_STATE, ['appContext.userSubmittedProductFilterZipcode', '']),
    [appActions.setProductFilterZipcodeValidity().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.isProductFilterZipcodeValid', action.payload]),
    [appActions.resetProductFilterZipcodeValidity().type]: (state) => createState(state || INITIAL_STATE, ['appContext.isProductFilterZipcodeValid', null]),
    // These two reducers set and clear the location type which is used to filter products based on availability.
    [appActions.setLocationType().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.locationType', action.payload]),
    [appActions.clearLocationType().type]: (state) => createState(state || INITIAL_STATE, ['appContext.locationType', '']),
    // These two reducers set and clear a gift location which can be set by the find a gift fast widget.
    [appActions.setProductFilterOccasion().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.occasion', action.payload]),
    [appActions.clearProductFilterOccasion().type]: (state) => createState(state || INITIAL_STATE, ['appContext.occasion', { name: '', code: '' }]),
    // These two reducers set and clear any address that can be selected by the user before checkout (guided nav / address book etc)
    [appActions.setUserSelectedAddress().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.userSubmittedAddress', action.payload]),
    [appActions.clearUserSelectedAddress().type]: (state) => createState(state || INITIAL_STATE, ['appContext.userSubmittedAddress', {}]),
    // Set bannercode for persistent eybrow in cross browsing
    [appActions.setBannerCode().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.bannerCode', action.payload]),
    [appActions.clearBannerCode().type]: (state) => createState(state || INITIAL_STATE, ['appContext.bannerCode', '']),
    [appActions.passportBundleItemAddedToCart().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.passportBundleProductInCart', addPassportBundleItem(state.appContext.passportBundleProductInCart, action.payload)]),
    [appActions.passportBundleItemRemovedCart().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.passportBundleProductInCart', removePassportBundleItem(state.appContext.passportBundleProductInCart, action.payload)]),

    [appActions.setWineData().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.wineData', action.payload]),
    [appActions.resetWineData().type]: (state) => createState(state || INITIAL_STATE, ['appContext.wineData', null]),

    [appActions.resetAgeVerifyFlag().type]: (state) => createState(state || INITIAL_STATE, ['appContext.isAgeVerified', null]),
    [appActions.setAgeVerifyFlagValid().type]: (state) => createState(state || INITIAL_STATE, ['appContext.isAgeVerified', true]),
    [appActions.setBrandMovieSkus().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.brandMovieSkus', { ...state.appContext.brandMovieSkus, ...action.payload }]),
    // This reducer is to set the status of email subscription box
    [appActions.setEmailSubscriptionData().type]: (state, action) => createState(state || INITIAL_STATE, ['emailSubscription', action.payload]),
    [appActions.resetEmailSubscription().type]: (state) => createState(state || INITIAL_STATE, ['emailSubscription', {}]),

    [appActions.setGCIData().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.gciData', action.payload]),
    [appActions.clearGCIData().type]: (state) => createState(state || INITIAL_STATE, ['currentProduct.gciData', {}]),
    [appActions.setGCIKey().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.gciKey', action.payload]),
    [appActions.setProductRetailPrice().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.retailPrice', action.payload]),
    [appActions.setUserConfirmationForSubscriptionPopup().type]: (state, action) => createState(state || INITIAL_STATE, ['currentProduct.userConfirmationForSubscriptionPopup', action.payload]),

    // reducer to set the position of the addtoCart button
    [appActions.setAddtoCartPosition().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.addToCartPosition', action.payload]),
    // reducer to set the country name
    [appActions.setCountry().type]: (state, action) => createState(state || INITIAL_STATE, ['country', action.payload]),
    [appActions.setCurrency().type]: (state, action) => createState(state || INITIAL_STATE, ['currency', action.payload]),
    [appActions.setLanguage().type]: (state, action) => createState(state || INITIAL_STATE, ['language', action.payload]),
    // Open and close chatbot
    [appActions.setChatbotOpenState().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.chatBotOpen', action.payload]),
    // reducer to set cgc card data
    [CGCActions.resetCGCCardData().type]: (state) => createState(state || INITIAL_STATE, ['appContext.cgcCardData', []]),
    [CGCActions.setCGCCardData().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.cgcCardData', action.payload]),
    [CGCActions.setCGCCustomCardData().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.cgcCustomCardData', action.payload]),
    [appActions.setPageInformation().type]: (state, action) => createState(state || INITIAL_STATE, ['page.type', action.payload]),
    // reducer to set the currency
    [appActions.setCurrency().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.currencyData', action.payload]),
    [appActions.setCountryData().type]: (state, action) => createState(state || INITIAL_STATE, ['appContext.countryData', action.payload]),
    // reducer to set the data dictionary
    [appActions.setDataDictionary().type]: (state, action) => createState(state || INITIAL_STATE, ['dataDictionary', action.payload]),
    [appActions.setProductInWishlist().type]: (state, action) => createState(state || INITIAL_STATE, ['Wishlist', action.payload]),
}));
