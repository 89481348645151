/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
import * as actionTypes from './Error-ActionTypes';

const errorReducer = (state = initialState.common.error, action) => {
    switch (action.type) {
        case actionTypes.LOAD_ERROR: {
            switch (action.data.errType) {
                case 'promotion': {
                    let updateError = { ...action.data.errMsg };
                    updateError = { ...state.promotion, ...action.data.errMsg };
                    return { ...state, promotion: updateError };
                }
                case 'giftCard':
                    return { ...state, giftCard: action.data.errMsg };
                case 'cart':
                    return { ...state, cart: action.data.errMsg };
                case 'recipientForm': {
                    let updateError = { ...action.data.errMsg };
                    updateError = { ...state.recipientForm, ...action.data.errMsg };
                    return { ...state, recipientForm: updateError };
                }
                case 'payment': {
                    let updateError = { ...action.data.errMsg };
                    updateError = { ...state.payment, ...action.data.errMsg };
                    return { ...state, payment: updateError };
                }
                case 'apiError': {
                    let updateError = { ...action.data.errMsg };
                    updateError = { ...state.payment, ...action.data.errMsg };
                    return { ...state, payment: updateError, apiError: 'apiError' };
                }
                case 'calendar':
                    return { ...state, calendar: action.data.errMsg };
                case 'giftMessage':
                    return { ...state, giftMessage: action.data.errMsg };
                case 'dtw':
                    return { ...state, dtw: action.data.errMsg };
                case 'passport':
                    return { ...state, passport: action.data.errMsg };
                case 'paypal':
                    return { ...state, paypal: action.data.errMsg };
                case 'validateBillingAddress': {
                    let updateError = { ...action.data.errMsg };
                    updateError = { ...state.payment, validateBillingAddress: action.data.errMsg };
                    return { ...state, payment: updateError };
                }
                default:
                    return { ...state, error: action.data.errMsg };
            }
        }
        case actionTypes.CLEAR_ERRORS: {
            switch (action.data.errType) {
                case 'promotion': {
                    const clearError = {
                        promotionCode: '',
                        error: '',
                    };
                    return { ...state, promotion: clearError };
                }
                case 'giftCard':
                    return { ...state, giftCard: '' };
                case 'cart':
                    return { ...state, cart: '' };
                case 'recipientForm': {
                    const clearError = { ...state.recipientForm };
                    clearError[action.data.field] = '';
                    return { ...state, recipientForm: clearError };
                }
                case 'recipientFormClearAllFields': {
                    const clearError = {
                        ...state.recipientForm,
                        zipCode: '',
                        locationType: '',
                        firstName: '',
                        lastName: '',
                        address1: '',
                        city: '',
                        error: '',
                    };
                    return { ...state, recipientForm: clearError };
                }
                case 'payment': {
                    const clearError = { ...state.payment };
                    clearError[action.data.field] = '';
                    return { ...state, payment: clearError };
                }
                case 'validateBillingAddress': {
                    const clearError = { ...state.payment };
                    clearError.validateBillingAddress = false;
                    return { ...state, payment: clearError };
                }
                case 'paymentClearAllFields': {
                    const clearError = {
                        lastName: '',
                        firstName: '',
                        address1: '',
                        email: '',
                        confirmEmail: '',
                        state: '',
                        phone: '',
                        zipCode: '',
                        city: '',
                        accountNumber: '',
                        cvv: '',
                        error: '',
                        invalidYearAndMonth: '',
                        nameOnCreditCard: '',
                        validateBillingAddress: false,
                        expirationYearError: '',
                        expirationMonthError: '',
                        detailedError: '',
                    };
                    return { ...state, payment: clearError };
                }
                case 'calendar':
                    return { ...state, calendar: '' };
                case 'giftMessage':
                    return { ...state, giftMessage: '' };
                case 'passport':
                    return { ...state, passport: '' };
                case 'paypal':
                    return { ...state, paypal: '' };
                case 'apiError':
                    return { ...state, apiError: '' };
                default:
                    return { ...state, error: '' };
            }
        }
        default:
            return state;
    }
};

export default errorReducer;
