/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/styles/makeStyles';
import {
    string, bool,
} from 'prop-types';

const styles = () => ({
    root: {
        padding: '5px 10px',
    },
    title: {
        fontSize: '2.1em',
    },
    link: {
        color: 'blue',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    reloadButon: {
        display: 'block',
        margin: '32px auto',
        textTransform: 'capitalize',
    },
});

const useStyles = makeStyles(styles);

const PageErrorBoundary = ({ errorMessage, showReload }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography paragraph className={classes.title} align="center" variant="h4">
                {errorMessage}
            </Typography>

            {showReload && (
                <Button
                    className={classes.reloadButon}
                    onClick={() => {
                        if (typeof window !== 'undefined') {
                            window.location.reload();
                        }
                    }}
                    variant="contained"
                    color="primary"
                >
                    Try Again
                </Button>
            )}
        </div>
    );
};

PageErrorBoundary.propTypes = {
    errorMessage: string.isRequired,
    showReload: bool.isRequired,
};

export default PageErrorBoundary;
