/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { decodeHTML } from 'entities';

import mbpLogger from 'mbp-logger';
import Cookies  from 'universal-cookie';
import isDesktopMobileTablet from '../../../DesktopMobile/isDesktopMobileTablet';
import { convertPassportDataToProduct, generateDataLayerProductArrays } from './tealiumProductDataLayerHelpers';

export const isCoreBrowsing = (pageType) => (
    pageType === 'category'
    || pageType === 'product'
    || pageType === 'search'
);

const getDevice = () => {
    if (typeof navigator !== 'undefined') {
        const navigatorUserAgent = navigator.userAgent;
        return isDesktopMobileTablet(navigatorUserAgent);
    }
    mbpLogger.logError({
        function: 'getDevice',
        module: 'tag-manager',
        message: 'navigator Object is not defined',
    });
    return 'desktop'; // return desktop by default
};

// Array of custom events picked up by GUA tag
export const buildCustomGAEvents = (events) => {
    const customGAEvents = [];
    const MAX_IMPRESSIONS = 40;

    mbpLogger.logDebug({
        events,
        function: 'buildCustomGAEvents',
        module: 'mbp-tag-managment',
        message: 'START',
    });

    try {
        if (events) {
            events.forEach((event) => {
                const {
                    eventName, eventAction, eventCategory, eventLabel,
                    nonInteraction,
                    ...eventData
                } = event;

                // Impressions
                //  Slice impressions arrays (while loop) = 40 max
                //  Send event w/ each slice
                if (eventData.productImpressionIds) {
                    const productImpressionIds = [...eventData.productImpressionIds];
                    const productImpressionBrands = [...eventData.productImpressionBrands];
                    const productImpressionCategoryNames = [...eventData.productImpressionCategoryNames];
                    const productImpressionListNames = [...eventData.productImpressionListNames];
                    const productImpressionListPositions = [...eventData.productImpressionListPositions];
                    const productImpressionNames = [...eventData.productImpressionNames];
                    const productImpressionPrices = [...eventData.productImpressionPrices];
                    const productImpressionSkus = [...eventData.productImpressionSkus];
                    const productImpressionVariants = [...eventData.productImpressionVariants];

                    while (productImpressionIds.length) {
                        const impArrays = {
                            enh_impression_ids: productImpressionIds.splice(0, MAX_IMPRESSIONS),
                            enh_impression_brands: productImpressionBrands.splice(0, MAX_IMPRESSIONS),
                            enh_impression_categories: productImpressionCategoryNames.splice(0, MAX_IMPRESSIONS),
                            enh_impression_list_names: productImpressionListNames.splice(0, MAX_IMPRESSIONS),
                            enh_impression_list_positions: productImpressionListPositions.splice(0, MAX_IMPRESSIONS),
                            enh_impression_names: productImpressionNames.splice(0, MAX_IMPRESSIONS),
                            enh_impression_prices: productImpressionPrices.splice(0, MAX_IMPRESSIONS),
                            enh_impression_skus: productImpressionSkus.splice(0, MAX_IMPRESSIONS),
                            enh_impression_variants: productImpressionVariants.splice(0, MAX_IMPRESSIONS),
                        };

                        if (impArrays) {
                            const eventParams = {
                                eventCategory,
                                eventAction,
                            };

                            if (eventName) {
                                eventParams.eventName = eventName;
                            }

                            if (nonInteraction) {
                                eventParams.nonInteraction = nonInteraction;
                            }

                            customGAEvents.push({ ...eventParams, ...impArrays });
                        }
                    }
                }
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            events,
            function: 'buildCustomGAEvents',
            module: 'mbp-tag-managment',
            jsError: ex,
            message: 'Failed to build custom GUA events (gua_events).',
        });
    }

    return customGAEvents;
};

const cookies = new Cookies();

export const generateCheckoutDataLayer = (props, baseDataLayer) => {
    const dataLayer = {};

    try {
        Object.assign(dataLayer, baseDataLayer);

        // order_
        Object.keys(props)
            .filter((key) => key.indexOf('order_') !== -1)
            .forEach((key) => {
                dataLayer[key] = props[key];
            });

        // Allow Conversion
        if (props.allow_conversion_tracking) {
            dataLayer.allow_conversion_tracking = props.allow_conversion_tracking;
        }

        // Set Checkout Step
        if (props.checkoutStep) {
            dataLayer.checkout_step = props.checkoutStep;
        }

        if (props.products) {
            dataLayer.product_addons = props.productAddons || [''];
            dataLayer.product_adjustments = props.productAdjustments || [''];
            dataLayer.product_attributes = props.productAttributes || [''];
            dataLayer.product_category_identifiers = props.productCategoryNames || [''];
            dataLayer.product_category_ids = props.productCategoryIds || [''];
            dataLayer.product_category_names = props.productCategoryNames || [''];
            dataLayer.product_category_paths = props.productCategoryPaths || [''];
            dataLayer.product_components = props.productComponents || [''];
            dataLayer.product_coupons = props.productCoupons || [''];
            dataLayer.product_deliveryDates = props.productDeliveryDates || [''];
            dataLayer.product_deliveryMethods = props.productDeliveryMethods || [''];
            dataLayer.product_greetingCards = props.productGreetingCards || [''];
            dataLayer.product_image_urls = props.productImageUrls || [''];
            dataLayer.product_orderItemIds = props.productOrderItemIds || [''];
            dataLayer.product_productIds = props.productBaseCodes || [''];
            dataLayer.product_productSkus = props.productSkus || [''];
            dataLayer.product_brands = props.productBrands || [''];
            dataLayer.product_quantities = props.productQuantities || [''];
            dataLayer.product_recipients = props.productRecipients || [''];
            dataLayer.product_totals = props.productTotals || [''];
            dataLayer.product_types = props.productTypes || [''];
            dataLayer.product_urls = props.productUrls || [''];
        }
    } catch (ex) {
        mbpLogger.logError({
            props,
            baseDataLayer,
            function: 'generateCheckoutDataLayer',
            module: 'mbp-tag-managment',
            jsError: ex,
            message: 'Failed to generate tealium checkout data layer.',
        });
    }

    return dataLayer;
};

// const getCategoryFromBreadcrumb = (breadCrumbs) => {
//     const categoryName = breadCrumbs.filter((d, i) => (i === breadCrumbs.length - 1));

//     return categoryName && categoryName[0];
// };

export const loadDataLayerForEvent = (rawDataLayer) => {
    const dataLayer = {};
    let productArrays = {};

    const {
        eventCategory,
        eventAction,
        eventName,
        eventLabel,
        nonInteraction,
        pageName,
        pageType,
        userRole,
        smartgiftDomain,
    } = rawDataLayer;

    try {
        // fail if dont have 2 required event params (category/action)
        if (!eventCategory || !eventAction) { throw new Error('Event prop undefined'); }
        if (rawDataLayer.searchTerm) { dataLayer.search_term = rawDataLayer.searchTerm; }
        if (rawDataLayer.selectedFilter) { dataLayer.selected_filter = rawDataLayer.selectedFilter; }
        if (rawDataLayer.nonInteraction) { dataLayer.nonInteraction = nonInteraction; }

        dataLayer.event_name = eventName || '';
        dataLayer.event_action = eventAction || '';
        dataLayer.event_category = eventCategory || '';
        dataLayer.event_label = decodeHTML(eventLabel) || '';
        dataLayer.event_page_name = pageName || '';
        dataLayer.event_page_type = pageType || '';
        dataLayer.event_user_role = userRole || '';

        // Smart Gift
        if (smartgiftDomain) {
            dataLayer.smartgift_domain = smartgiftDomain;
        }

        // Build raw product arrays
        if (rawDataLayer.products) {
            const productArrayProps = {};

            // Product List (Search Term)
            if (rawDataLayer.searchTerm) {
                productArrayProps.searchTerm = rawDataLayer.searchTerm;
            }

            // Product Positon Seed
            if (rawDataLayer.productListPositionSeed) {
                productArrayProps.productListPositionSeed = rawDataLayer.productListPositionSeed;
            }

            productArrays = generateDataLayerProductArrays({
                products: rawDataLayer.products,
                props: productArrayProps,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            eventCategory,
            eventAction,
            eventName,
            eventLabel,
            function: 'handleAddToCart',
            module: 'mbp-tag-managment',
            jsError: ex,
            message: 'Tag Manager - loadDataLayer failed',
        });
    }

    return {
        ...rawDataLayer,
        ...dataLayer,
        ...productArrays,
    };
};

// Build Tealium-specific data layer
export const loadDataLayerForPageView = (data) => {
    const {
        brand,
        userRole, // System Data
        page, pageType, pageName, // Page Data
        campaignName, campaignSource, campaignMedium, // Campaign Data
    } = data;
    let dataLayer = {};
    let productArrays = {};
    let passportProductArrays = {};
    const productProps = {};

    if (!pageType || !pageName) {
        mbpLogger.logError({
            pageType,
            pageName,
            function: 'loadDataLayer',
            module: 'mbp-tag-managment',
            message: 'Tag Manager - loadDataLayer missing required params. [pageType / pageName]',
        });

        return null;
    }

    try {
        if (pageType && brand) {
            dataLayer = {
                brand_current: brand.code || 'NO_CURRENT_BRAND',
                brand_entering: brand.code || 'NO_ENTERING_BRAND',
                cellid: cookies.get('cellid') || 'NO_CELL',
                country_code: 'US', // TODO: Get from browser?
                page: page || 'TIQ_PAGE_NOT_SET',
                page_language: 'en', // ^^
                page_name: pageName,
                page_type: pageType,
                user_role: userRole || '',
            };

            // Location
            if (data.location) {
                dataLayer.gua_location = data.location;
            }

            // Referrer
            if (data.referrer) {
                dataLayer.gua_referrer = data.referrer;
            }

            // Title
            if (data.title) {
                dataLayer.gua_title = data.title;
            }

            // Radio
            if (data.radioEventTerm) {
                dataLayer.radio_term = data.radioEventTerm;
                dataLayer.radio_personality = data.radioEventTerm;
            }

            // wcs_mobile
            // TODO: Data layer - Add mobile support
            dataLayer.wcs_mobile = false;

            // Device type based on viewport width
            dataLayer.device_type = getDevice();
            dataLayer.is_mobile = getDevice() === 'mobile';

            // TODO: Common properties
            //  Transport Type
            //  PII
            //  User ID
            //  Clean Category name
            //  Session ID

            // customer_
            Object.keys(data)
                .filter((key) => key.indexOf('customer_') !== -1)
                .forEach((key) => {
                    dataLayer[key] = data[key];
                });

            // Campaign Params
            if (campaignName && campaignSource && campaignMedium) {
                // Add campaign properties to data layer
                Object.keys(data).forEach((property) => {
                    if (property.indexOf('campaign') !== -1) {
                        dataLayer[property] = data[property];
                    }
                });

                // Add googleClickId (gclid)
                if (data.googleClickId) {
                    dataLayer.googleClickId = data.googleClickId;
                }
            }

            // Search Term
            if (data.searchTerm) {
                dataLayer.search_term = data.searchTerm;
                productProps.searchTerm = data.searchTerm;
            }

            if (isCoreBrowsing(pageType)) {
                // Category
                if (pageType === 'category') {
                    dataLayer.category_id = data.id;
                    dataLayer.category_legacy_id = data.legacyId;
                    dataLayer.category_identifier = data.identifier;
                    dataLayer.category_name = decodeHTML(data.name);
                    dataLayer.category_sympathy_flag = data.sympathyFlag || '';
                    dataLayer.category_canonical_url = data.canonicalUrl || '';

                    productProps.category = data.category || {};
                }

                if (pageType === 'product') {
                    productProps.category = data.category || {};
                }

                // Impressions (gua_events)
                dataLayer.ga_events = buildCustomGAEvents(data.events);
            } else if (page.indexOf('/checkout') !== -1) {
                dataLayer = generateCheckoutDataLayer(data, dataLayer);
            }

            // Product Arrays
            if (data.products
                && (pageType !== 'homepage' && pageType !== 'content')
            ) {
                const { passportItem } = data;
                if (passportItem) {
                    const passportProduct = convertPassportDataToProduct(passportItem);
                    passportProductArrays = generateDataLayerProductArrays({
                        products: passportProduct,
                        impressions: true,
                        props: productProps,
                    });
                }

                productArrays = generateDataLayerProductArrays({
                    products: data.products,
                    props: productProps,
                });
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            data,
            function: 'loadDataLayerForPageView',
            module: 'mbp-tag-managment',
            jsError: ex,
            message: 'Tag Manager - loadDataLayerForPageView failed',
        });
    }

    return {
        ...dataLayer,
        ...productArrays,
        ...passportProductArrays,
    };
};
