/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    put, fork, call, takeEvery, select, take,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

import { trackEvent } from '../../../../../TagManager/ducks/TagManager/TagManager-Actions';
import memberDucks from '../../../../../Member/ducks';
import { recipientFormFields } from '../RecipientForm/RecipientForm-Helpers';
import {
    cardIsleAvailable,
    loadCardIsle,
    addCardIsle,
    addCardIsleComplete,
    // resetCardIsle,
} from './CardIsle-Actions';
import { loadError } from '../../../Common/ducks/Error/Error-Actions';
import {
    getCurrentEditingRecipient, getUserSubmittedProductFilterZipcode, getOrderId,
} from '../../../Common/Common-Selectors';
import { getCardIsleSelected } from './CardIsle-Selector';

const {
    auth: {
        authOperations: {
            workers: {
                checkJWT,
            },
        },
    },
} = memberDucks;

const getGreetingAvailability = ({
    jwtToken,
    productCode,
    brandCode,
    zipCode,
    locationType,
    deliveryDate,
}) => orderClient.getGreetingAvailability(
    {},
    jwtToken,
    productCode,
    brandCode,
    zipCode,
    locationType,
    deliveryDate,
);

const addGreetingCard = ({
    jwtToken, orderItemId, greetingCardData, orderId,
}) => orderClient.addGreetingCard(
    {},
    jwtToken,
    orderItemId,
    greetingCardData,
    orderId,
);

function* loadCardIsleAvailable() {
    try {
        const [orderItemToEdit] = yield select(getCurrentEditingRecipient);

        if (orderItemToEdit && orderItemToEdit.recipientAddress) {
            const [orderItems] = orderItemToEdit.orderItems;
            const { catalogEntryVO } = orderItems;
            const brandCode = catalogEntryVO.brandCode || '';
            const productCode = catalogEntryVO.skuCode.replace(`${brandCode}-I-`, '') || '';

            const currentDate = orderItems?.promisedAvailableTime ? new Date(orderItems.promisedAvailableTime) : null;
            if (currentDate) {
                const currentDay = (`0${currentDate.getDate()}`).slice(-2);
                const currentMonth = (`0${currentDate.getMonth() + 1}`).slice(-2);

                const deliveryDate = currentDate ? `${currentMonth}/${currentDay}/${currentDate.getFullYear()}` : null;

                const pdpZipCode = yield select(getUserSubmittedProductFilterZipcode);
                const recipientFormData = recipientFormFields(orderItemToEdit.recipientAddress);

                if (recipientFormData && (orderItems?.zipCode || recipientFormData.zipCode) && recipientFormData.locationType) {
                    const jwtToken = yield call(checkJWT);
                    const configObj = {
                        jwtToken,
                        productCode,
                        brandCode,
                        zipCode: recipientFormData.zipCode || orderItems.zipCode || pdpZipCode,
                        locationType: recipientFormData.locationType || 'Residence',
                        deliveryDate,
                    };

                    const response = yield call(getGreetingAvailability, configObj);
                    yield put(loadCardIsle(response.data));

                    // Track Card Isle Impression - Available
                    if (response.data?.available) {
                        const taggingCardIsle = {
                            eventCategory: 'Checkout',
                            eventAction: 'Card Isle',
                            eventLabel: 'Impression',
                        };
                        yield put(trackEvent(taggingCardIsle));
                    }
                }
            } else {
                mbpLogger.logError({
                    module: 'mbp-checkout',
                    function: 'loadCardIsleAvailable',
                    currentDate,
                    message: 'Failed in getting cardIsle availability',
                });
                yield put(loadError({
                    errType: 'cardIsle',
                    errMsg: 'We are sorry, there seems to be a technical issue with cardIsle delivery date, please try again later or use alternate payment method to complete order',
                }));
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'loadCardIsleAvailable',
            jsError: ex,
            message: 'Failed in getting cardIsle availability',
        });
        yield put(loadError({
            errType: 'cardIsle',
            errMsg: 'We are sorry, there seems to be a technical issue with cardIsle payment method, please try again later or use alternate payment method to complete order',
        }));
    }
}

function* loadCardIsleAvailableWatcher() {
    yield takeEvery(cardIsleAvailable().type, loadCardIsleAvailable);
}

function* addCardIsleToCart(orderItemId) {
    try {
        const [orderItemToEdit] = yield select(getCurrentEditingRecipient);
        if (orderItemToEdit && orderItemToEdit.recipientAddress) {
            const cardIsleSelected = yield select(getCardIsleSelected);

            if (
                orderItemToEdit && orderItemToEdit.orderItems
                && cardIsleSelected && cardIsleSelected.pickupCode
            ) {
                const [orderItems] = orderItemToEdit.orderItems;

                const { catalogEntryVO } = orderItems;
                const brandCode = catalogEntryVO.brandCode || '';
                const recipientFormData = recipientFormFields(orderItemToEdit.recipientAddress);
                const orderId = yield select(getOrderId);
                const zipCode = orderItems.zipCode || recipientFormData.zipCode || '';

                if (recipientFormData && zipCode && recipientFormData.locationType) {
                    const jwtToken = yield call(checkJWT);

                    const reqObjCI = {
                        jwtToken,
                        orderItemId,
                        greetingCardData: {
                            brandCode,
                            productCode: cardIsleSelected.productCode, // card isle product code
                            pickUpCode: cardIsleSelected.pickupCode,
                            cardIsleMessage: cardIsleSelected.textMessage,
                            cardIsleName: cardIsleSelected.name,
                            cardIsleImage: cardIsleSelected.img,
                            cardIsleType: 'CI',
                            thumbNail: cardIsleSelected.img,
                        },
                        orderId,
                    };
                    // Review the product has card isle available.
                    yield call(addGreetingCard, reqObjCI);
                    yield put(addCardIsleComplete());

                    // Tagging for save cardisle
                    const taggingCardIsle = {
                        eventCategory: 'Checkout',
                        eventAction: 'Card Isle',
                        eventLabel: `${cardIsleSelected.name}|${cardIsleSelected.textMessage}`,
                    };
                    yield put(trackEvent(taggingCardIsle));
                }
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'loadCardIsleAvailable',
            jsError: ex,
            message: 'Failed in getting cardIsle availability',
        });
        yield put(loadError({
            errType: 'cardIsle',
            errMsg: 'We are sorry, there seems to be a technical issue with cardIsle payment method, please try again later or use alternate payment method to complete order',
        }));
    }
}

function* addCardIsleToCartWatcher() {
    let action = yield take(addCardIsle().type);
    while (action !== END) {
        yield fork(addCardIsleToCart, action.orderItemId);
        action = yield take(addCardIsle().type);
    }
}

const watchers = [
    fork(loadCardIsleAvailableWatcher),
    fork(addCardIsleToCartWatcher),
];

export {
    watchers,
    loadCardIsleAvailable,
    addCardIsleToCart,
};

export default {};
