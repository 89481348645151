/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import createState from 'redux-create-state';

import utils from '../../../../utils';
import * as tagManagerActions from './TagManager-Actions';

const INITIAL_STATE = {
    pageView: {
        pageName: '',
        pageType: '',
    },
    events: [],
    campaign: null, // Marketing Campaign (Analytics)
    pageViewFired: false,
};

const tagManagerReducer = utils.createReducer(INITIAL_STATE)({
    [tagManagerActions.setCurrentPageView().type]: (state, action) => createState(state || INITIAL_STATE, ['pageView', action.payload]),
    [tagManagerActions.queueEvent().type]: (state, action) => createState(state || INITIAL_STATE, ['events', (events) => {
        // Clear Queued Events
        if (action.payload === 'clear') {
            return [];
        }

        events.push(action.payload.dataLayer);

        return events;
    }]),
    [tagManagerActions.setSessionCampaign().type]: (state, action) => createState(state || INITIAL_STATE, ['campaign', action.payload]),

    [tagManagerActions.trackPageViewComplete().type]: (state, action) => createState(state || INITIAL_STATE, ['pageViewFired', action.payload]),
});

export default tagManagerReducer;
