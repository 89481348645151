/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/*
 * Define the action types related to MBP UI App
 */
const MBP_UI_LOAD_APP_CLIENT = 'mbp-pwa-ui/LOAD/APP/CLIENT';
const MBP_UI_LOAD_APP_SERVER = 'mbp-pwa-ui/LOAD/APP/SERVER';
const MBP_UI_CLOSE_APP = 'mbp-pwa-ui/CLOSE/APP';
const APP_SET_CURRENT_BRAND_ID = 'mbp-pwa-ui/SET/CURRENT/BRAND/ID';
const APP_SET_CURRENT_URL_ID = 'mbp-pwa-ui/SET/CURRENT/URL/ID';
const PAGE_SHELL_LOADED = 'mbp-pwa-ui/shell/LOADED';
const MBP_UI_SSR_INIT = 'mbp-pwa-ui/INIT/SSR';
const MBP_UI_SET_BOT_STATE = 'mbp-pwa-ui/SET/BOT/STATE';
const MBP_UI_SET_SSR_STATE = 'mbp-pwa-ui/SET/SSR/STATE';
const MBP_UI_SET_SSR_HOSTNAME = 'mbp-pwa-ui/SET/SSR/HOSTNAME';
const MBP_UI_SET_SSR_PATH = 'mbp-pwa-ui/SET/SSR/PATH';
const MBP_UI_SET_SSR_DEVICE_TYPE = 'mbp-pwa-ui/SET/SSR/DEVICE/TYPE';
const MBP_UI_SET_APP_SHELL_TYPE = 'mbp-pwa-ui/SET/APP/SHELL/TYPE';
const MBP_UI_APP_GET_SEARCH_RESULT = 'MBP_UI_APP_GET_SEARCH_RESULT';
const MBP_UI_APP_SET_SEARCH_DATA = 'MBP_UI_APP_SET_SEARCH_DATA';
const MBP_UI_APP_SET_SEARCH_ClEAR = 'MBP_UI_APP_SET_SEARCH_ClEAR';

const MBP_UI_VALIDATE_PRODUCT_FILTER_ZIPCODE = 'mbp-pwa-ui/VALIDATE/PRODUCT/FILTER/ZIPCODE';
const MBP_UI_SET_PRODUCT_FILTER_ZIPCODE_VALIDITY = 'mbp-pwa-ui/IS/PRODUCT/FILTER/ZIPCODE/VALID';
const MBP_UI_RESET_PRODUCT_FILTER_ZIPCODE_VALIDITY =  'mbp-pwa-ui/RESET/PRODUCT/FILTER/ZIPCODE/VALIDITY';
const MBP_UI_SET_VALIDATED_ZIPCODE = 'mbp-pwa-ui/SET/VALIDATED/ZIPCODE';
const MBP_UI_CLEAR_VALIDATED_ZIPCODE = 'mbp-pwa-ui/CLEAR/VALIDATED/ZIPCODE';
const MBP_UI_SET_SUBMITTED_PRODUCT_FILTER_ZIPCODE =  'mbp-pwa-ui/SET/SUBMITTED/PRODUCT/FILTER/ZIPCODE';
const MBP_UI_CLEAR_SUBMITTED_PRODUCT_FILTER_ZIPCODE =  'mbp-pwa-ui/CLEAR/SUBMITTED/PRODUCT/FILTER/ZIPCODE';
const MBP_UI_SET_LOCATION_TYPE = 'mbp-pwa-ui/SET/LOCATION/TYPE';
const MBP_UI_CLEAR_LOCATION_TYPE = 'mbp-pwa-ui/CLEAR/LOCATION/TYPE';
const MBP_UI_SET_PRODUCT_FILTER_OCCASION = 'mbp-pwa-ui/SET/PRODUCT/FILTER/OCCASION';
const MBP_UI_CLEAR_PRODUCT_FILTER_OCCASION = 'mbp-pwa-ui/CLEAR/PRODUCT/FILTER/OCCASION';
const MBP_UI_LOCK_CATEGORY = 'mbp-pwa-ui/LOCK/CATEGORY';
const MBP_UI_UNLOCK_CATEGORY = 'mbp-pwa-ui/UNLOCK/CATEGORY';
const MBP_UI_CATEGORY_LOADED = 'mbp-pwa-ui/CATEGORY/LOADED';
const MBP_UI_SET_USER_SELECTED_ADDRESS = 'mbp-pwa-ui/SET/GUIDED/NAV/ADDRESS';
const MBP_UI_CLEAR_USER_SELECTED_ADDRESS = 'mbp-pwa-ui/CLEAR/GUIDED/NAV/ADDRESS';
const APP_SET_BANNER_CODE = 'APP_SET_BANNER_CODE';
const APP_CLEAR_BANNER_CODE = 'APP_CLEAR_BANNER_CODE';
const APP_PASSPORT_BUNDLE_ITEM_ADD_TO_CART = 'APP_PASSPORT_BUNDLE_ITEM_ADD_TO_CART';
const APP_PASSPORT_BUNDLE_ITEM_REMOVE_FROM_CART = 'APP_PASSPORT_BUNDLE_ITEM_REMOVE_FROM_CART';
const MBPPRODUCT_SET_PDP_WINE = 'mbp-pwa-ui/PRODUCT/SET/PRODUCT/WINE/';
const MBPPRODUCT_RESET_PDP_WINE = 'mbp-pwa-ui/PRODUCT/RESET/PRODUCT/WINE/';
const WINE_VERIFY = 'mbp-pwa-ui/PRODUCT/GET/PRODUCT/WINE/VERIFY';
const WINE_VERIFY_DONE = 'mbp-pwa-ui/PRODUCT/GET/PRODUCT/WINE/VERIFY/DONE';
const WINE_VERIFY_FAILED = 'mbp-pwa-ui/PRODUCT/GET/PRODUCT/WINE/VERIFY/FAILED';
const APP_SET_BRAND_MOVIE_SKUS = 'mbp-pwa-ui/SET/BRAND/MOVIE/SKUS';
const SET_GCI_DATA = 'mbp-pwa-ui/SET/GCI/DATA';
const CLEAR_GCI_DATA = 'mbp-pwa-ui/CLEAR/GCI/DATA';
const FETCH_GCI_KEY = 'mbp-pwa-ui/FETCH/GCI/KEY';
const SET_GCI_KEY = 'mbp-pwa-ui/SET/GCI/KEY';
const SET_EMAIL_SUBSCRIPTION_DATA = 'SET_EMAIL_SUBSCRIPTION_DATA';
const RESET_EMAIL_SUBSCRIPTION_DATA = 'RESET_EMAIL_SUBSCRIPTION_DATA';
const CREATE_EMAIL_SUBSCRIPTION = 'CREATE_EMAIL_SUBSCRIPTION';
const APP_RESET_AGE_FLAG = 'APP_RESET_AGE_FLAG';
const APP_SET_AGE_FLAG_TRUE = 'APP_SET_AGE_FLAG_TRUE';
const APP_SET_AGE_FLAG_FALSE = 'APP_SET_AGE_FLAG_FALSE';
const APP_SET_ADDTOCART_POSITION = 'APP_SET_ADDTOCART_POSITION';
const SET_COUNTRY = 'mbp-pwa-ui/SET/COUNTRY';
const APP_SET_PRODUCT_RETAIL_PRICE = 'APP_SET_PRODUCT_RETAIL_PRICE';
const APP_SET_CHATBOT_OPEN_STATE = 'APP_SET_CHATBOT_OPEN_STATE';
const APP_SET_USER_CONFIRMATION_FOR_POPUP = 'APP_SET_USER_CONFIRMATION_FOR_POPUP';
const APP_SET_LANGUAGE = 'APP_SET_LANGUAGE';
const APP_SET_CURRENCY = 'APP_SET_CURRENCY';
const APP_SET_COUNTRY = 'APP_SET_COUNTRY';
const PAGE_INFORMATION = 'PAGE_INFORMATION';
const SET_CURRENCY = 'SET_CURRENCY';
const SET_COUNTRY_DATA = 'SET_COUNTRY_DATA';
const SET_DATA_DICTIONARY = 'SET_DATA_DICTIONARY';

export default {
    MBP_UI_LOAD_APP_CLIENT,
    MBP_UI_LOAD_APP_SERVER,
    MBP_UI_CLOSE_APP,
    MBPPRODUCT_SET_PDP_WINE,
    MBPPRODUCT_RESET_PDP_WINE,
    APP_SET_CURRENT_URL_ID,
    APP_SET_CURRENT_BRAND_ID,
    PAGE_SHELL_LOADED,
    MBP_UI_SSR_INIT,
    MBP_UI_SET_BOT_STATE,
    MBP_UI_SET_SSR_STATE,
    MBP_UI_SET_SSR_HOSTNAME,
    MBP_UI_SET_SSR_PATH,
    MBP_UI_SET_SSR_DEVICE_TYPE,
    MBP_UI_SET_APP_SHELL_TYPE,
    MBP_UI_APP_GET_SEARCH_RESULT,
    MBP_UI_APP_SET_SEARCH_DATA,
    MBP_UI_APP_SET_SEARCH_ClEAR,
    WINE_VERIFY_DONE,
    WINE_VERIFY_FAILED,
    WINE_VERIFY,
    APP_RESET_AGE_FLAG,
    APP_SET_AGE_FLAG_TRUE,
    APP_SET_AGE_FLAG_FALSE,
    APP_SET_ADDTOCART_POSITION,

    MBP_UI_VALIDATE_PRODUCT_FILTER_ZIPCODE,
    MBP_UI_SET_PRODUCT_FILTER_ZIPCODE_VALIDITY,
    MBP_UI_RESET_PRODUCT_FILTER_ZIPCODE_VALIDITY,
    MBP_UI_SET_VALIDATED_ZIPCODE,
    MBP_UI_CLEAR_VALIDATED_ZIPCODE,
    MBP_UI_SET_SUBMITTED_PRODUCT_FILTER_ZIPCODE,
    MBP_UI_CLEAR_SUBMITTED_PRODUCT_FILTER_ZIPCODE,
    MBP_UI_SET_LOCATION_TYPE,
    MBP_UI_CLEAR_LOCATION_TYPE,
    MBP_UI_SET_PRODUCT_FILTER_OCCASION,
    MBP_UI_CLEAR_PRODUCT_FILTER_OCCASION,
    MBP_UI_LOCK_CATEGORY,
    MBP_UI_UNLOCK_CATEGORY,
    MBP_UI_CATEGORY_LOADED,
    MBP_UI_SET_USER_SELECTED_ADDRESS,
    MBP_UI_CLEAR_USER_SELECTED_ADDRESS,
    SET_EMAIL_SUBSCRIPTION_DATA,
    CREATE_EMAIL_SUBSCRIPTION,
    RESET_EMAIL_SUBSCRIPTION_DATA,
    APP_SET_BANNER_CODE,
    APP_CLEAR_BANNER_CODE,
    APP_PASSPORT_BUNDLE_ITEM_ADD_TO_CART,
    APP_PASSPORT_BUNDLE_ITEM_REMOVE_FROM_CART,
    APP_SET_BRAND_MOVIE_SKUS,
    SET_GCI_DATA,
    CLEAR_GCI_DATA,
    FETCH_GCI_KEY,
    SET_GCI_KEY,
    SET_COUNTRY,
    APP_SET_PRODUCT_RETAIL_PRICE,
    APP_SET_CHATBOT_OPEN_STATE,
    APP_SET_USER_CONFIRMATION_FOR_POPUP,
    APP_SET_LANGUAGE,
    APP_SET_CURRENCY,
    APP_SET_COUNTRY,
    PAGE_INFORMATION,
    SET_CURRENCY,
    SET_COUNTRY_DATA,
    SET_DATA_DICTIONARY,
};
