/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './VisaCheckout-ActionTypes';

export const submitVisaCheckoutPaymentContext = (data) => ({
    type: actionTypes.SUBMIT_VISA_CHECKOUT_PAYMENT_CONTEXT,
    data,
});

export default {};
