/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable no-param-reassign */

import restClient from '../../app/helpers/restClient';

const convertUSDToCurrency = (wcEnv, jwt, salePrice, retailPrice, currency, partNumber = '9999-999X') => {
    const data = {
        prices: [
            {
                partNumber,
                salePrice,
                retailPrice,
                currency,
            },
        ],
    };
    if (!wcEnv) {
        wcEnv = {
            rootUri: '/acquisition',
        };
    }
    if (!wcEnv.rootUri) {
        wcEnv.rootUri = '/acquisition';
    }
    const resourcePath = '/price-engine/convertCurrency';
    return restClient.postFacade(wcEnv, resourcePath, jwt, data, null, null, false).then((response) => response);
};

export default convertUSDToCurrency;
