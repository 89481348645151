/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
import * as actionTypes from './ShowLoadingMessage-ActionTypes';

const showLoadingMessage = (state = initialState.common.showLoadingMessage, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SHOW_LOADING_MESSAGE:
            return { ...state, flag: true };
        case actionTypes.FLAGOFF_SHOW_LOADING_MESSAGE:
            return { ...state, flag: false };
        case actionTypes.UPDATE_LOADER_ID:
            return { ...state, loaderId: action.data };
        default:
            return state;
    }
};

export default showLoadingMessage;
