/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { object, element } from 'prop-types';

const ScrollToTop = ({ children, location: { pathname } }) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.scrollTo({
                top: 0,
                left: 0,
            });
        }
    }, [pathname]);

    return children || null;
};

ScrollToTop.propTypes = {
    location: object.isRequired,
    children: element.isRequired,
};

export default withRouter(ScrollToTop);
