/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 * Checkout APIs
 */

import getOrderDetails from './getOrderDetails';
import getOrderDetailsForUser from './getOrderDetailsForUser';
import getPaymentDetails from './getPaymentDetails';
import applyPromotion from './applyPromotion';
import removePromotion from './removePromotion';
import placeOrder from './placeOrder';
import applyGiftCard from './applyGiftCard';
import addRecipient from './addRecipient';
import calculateShipping from './calculateShipping';
import removeOrderItem from './removeOrderItem';
import updateCart from './updateCart';
import duplicateCart from './duplicateCart';
import getCartCount from './getCartCount';
import getDeliveryCalendar from './getDeliveryCalendar';
import orderPrepare from './orderPrepare';
import getDeliveryTimeWindow from './getDeliveryTimeWindow';
import updateBillingAddress from './updateBillingAddress';
import getPaymentMethods from './getPaymentMethods';
import removeGiftCard from './removeGiftCard';
import getPersonalizationKey from './getPersonalizationKey';
import savePersonalization from './savePersonalization';
import getPayPalExpressCheckoutSession from './getPayPalExpressCheckoutSession';
import processChasePay from './processChasePay';
import processVisaCheckout from './processVisaCheckout';
import validateOrder from './validateOrder';
import getChasePayCheckoutSession from './getChasePayCheckoutSession';
import verifyWine from './verifyWine';
import reCalculateGiftCardAmount from './reCalculateGiftCardAmount';
import dynamicPassportCharge from './dynamicPassportCharge';
import getCityStateFromZip from './getCityStateFromZip';
import getPayPalClientToken from './getPayPalClientToken';
import getBraintreePayPalClientToken from './getBraintreePayPalClientToken';
import paypalProcessPayload from './paypalProcessPayload';
import createOptInEntry from './createOptInEntry';
import addAddonItemsToCart from './addAddonItemsToCart';
import addCreditCard from './addCreditCard';
import addWrapupsToCart from './addWrapupsToCart';
import rewardElegilibity from './rewardElegilibity';
import rewardBalance from './rewardBalance';
import getGreetingAvailability from './getGreetingAvailability';
import recordPaymentMethod from './recordPaymentMethod';
import addGreetingCard from './addGreetingCard';
import getPaymentClientToken from './getPaymentClientToken';
import {
    processCgcGreetingCard, processCGCGreetingCardAPI, generateCgcCard, fetchCGCCardData,
    generateCgcLabel, fetchCGCLabeldData, fetchCGCCustomCardData, getCgcCustomCard,
} from './fetchCGCData';
import addToCart from './addToCart';
import getGCIKey from './getGCIKey';
import putPersonalizationResponse from './putPersonalizationResponse';

import {
    validateMerchantApplePay,
    savePayMethodApplePay,
    saveShipMethodApplePay,
    authorizeApplePay,
    saveRecipientAddressApplePay,
    cancelApplePay,
    placeOrderApplePay,
} from './applePay';

const orderClient = {
    getOrderDetails,
    getOrderDetailsForUser,
    getPaymentDetails,
    getCartCount,
    addToCart,
    addRecipient,
    removeOrderItem,
    duplicateCart,
    updateCart,
    calculateShipping,
    getPersonalizationKey,
    savePersonalization,
    applyPromotion,
    removePromotion,
    getDeliveryCalendar,
    getPaymentMethods,
    getDeliveryTimeWindow,
    updateBillingAddress,
    applyGiftCard,
    removeGiftCard,
    getPayPalExpressCheckoutSession,
    getChasePayCheckoutSession,
    processChasePay,
    processVisaCheckout,
    orderPrepare,
    placeOrder,
    validateOrder,
    verifyWine,
    reCalculateGiftCardAmount,
    validateMerchantApplePay,
    savePayMethodApplePay,
    saveShipMethodApplePay,
    authorizeApplePay,
    saveRecipientAddressApplePay,
    cancelApplePay,
    placeOrderApplePay,
    dynamicPassportCharge,
    getCityStateFromZip,
    getPayPalClientToken,
    getBraintreePayPalClientToken,
    paypalProcessPayload,
    createOptInEntry,
    addAddonItemsToCart,
    addCreditCard,
    addWrapupsToCart,
    rewardElegilibity,
    rewardBalance,
    getGreetingAvailability,
    recordPaymentMethod,
    addGreetingCard,
    processCgcGreetingCard,
    processCGCGreetingCardAPI,
    getGCIKey,
    generateCgcCard,
    fetchCGCCardData,
    generateCgcLabel,
    fetchCGCLabeldData,
    fetchCGCCustomCardData,
    getCgcCustomCard,
    putPersonalizationResponse,
    getPaymentClientToken,
};

export default orderClient;
