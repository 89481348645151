/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import {
    fork, takeLatest, call, select, put,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import {
    getGooglePayClientId,
    loadGooglePayClientId,
    clearGooglePayClientId,
    submitGooglePayment,
} from './GooglePay-Actions';
import { normalizeGooglePaymentBillingAddress } from './GooglePay-Helper';
import { onPlaceOrder } from '../../Payment-Operations';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

import { updateBillingForm } from '../../ducks/BillingAddressForm/BillingAddressForm-Actions';
import * as showLoadingMessageActions from '../../../Common/ducks/ShowLoadingMessage/ShowLoadingMessage-Actions';
import { checkJWT } from '../../../../../Member/ducks/Auth/Auth-Operations';
import { getOrderId } from '../../../Common/Common-Selectors';

const getGooglePayClientToken = (config) => orderClient.getPayPalClientToken({}, config.jwtToken, config.orderId);

function* workerGetGooglePayClientId() {
    try {
        mbpLogger.logDebug({
            module: 'mbp-pwa-ui',
            function: 'workerGetGooglePayClientId',
            message: 'Get Google Pay Client Id',
        });

        // show loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const jwtToken = yield call(checkJWT);
        const configObj = {
            jwtToken,
            orderId: yield select(getOrderId),
        };
        // Get Paypal Session Client Token
        const response = yield call(getGooglePayClientToken, configObj);
        const { clientToken } = response.data;

        yield put(clearGooglePayClientId());
        yield put(loadGooglePayClientId(clientToken));

        // hide loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'paypalGetClientIdWorker',
            jsError: ex,
            message: 'Failed in getting paypal client id',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* workerSubmitGooglePayment(action) {
    try {
        mbpLogger.logDebug({
            module: 'mbp-pwa-ui',
            function: 'workerSubmitGooglePayment',
            message: 'Submit Google Pay Payment',
        });

        const { paymentDataResponse, result, history } = action.data;
        const billingAddress = normalizeGooglePaymentBillingAddress(paymentDataResponse);
        billingAddress.isFormValid = true;

        yield put(updateBillingForm(billingAddress));

        const placeOrderConfig = {
            payload: {
                history,
                params: {},
                disablePaymentFields: false,
                source: 'PWA',
                googlePayment: {
                    nonce: result.nonce,
                },
            },
        };
        yield call(onPlaceOrder, placeOrderConfig);
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            function: 'workerSubmitGooglePayment',
            jsError: ex,
            message: 'Failed submitting GooglePay payment',
        });
    }
}

function* watcherSubmitGooglePayment() {
    yield takeLatest(submitGooglePayment().type, workerSubmitGooglePayment);
}

function* watcherGetGooglePayClientId() {
    yield takeLatest(getGooglePayClientId().type, workerGetGooglePayClientId);
}

const watchers = [
    fork(watcherGetGooglePayClientId),
    fork(watcherSubmitGooglePayment),
];

export {
    watchers,
};

export default {};
