/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// API
export const GET_REMINDERS_BY_EMAIL = 'GET/REMINDERS/BY/EMAIL';
export const DELETE_REMINDERS_BY_ID = 'DELETE/REMINDERS/BY/ID';

// Store
export const REMINDERS_LOADED = 'REMINDERS/LOADED';
export const REMOVE_REMINDER_BY_ID = 'REMOVE/REMINDER/BY/ID';
export const CLEAR_ALL_REMINDERS = 'CLEAR/ALL/REMINDERS';

// Reminder Dialog
export const SET_SHOW_DIALOG = 'REMINDER/SET_SHOW_DIALOG';
export const SET_REMINDERS_DIALOG_EVENT_CATEGORY = 'SET_REMINDERS_DIALOG_EVENT_CATEGORY';

// Reminder Banner close by user
export const USER_HIDE_BANNER = 'REMINDER/USER_HIDE_BANNER';

// Reminder tool Tip
export const SET_TOOLTIP_STATUS = 'REMINDER/SET_TOOL_TIP_STATUS';
export const SET_REAUTHENTICATION_STATUS = 'REMINDER/REAUTHENTICATE/SET_TOOL_TIP_STATUS';
