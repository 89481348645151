/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import axios from 'axios';
import {
    fork, call, put, takeLatest,
} from 'redux-saga/effects';

import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';
import {
    setLocationType, setUserSelectedAddress, setUserSubmittedProductFilterZipcode, setValidatedZipcode, setProductFilterZipcodeValidity,
} from '../App/App-Actions';
import { getSCIData } from './SCI-Actions';
import { checkJWT } from '../Member/ducks/Auth/Auth-Operations';
import { determineCollectionPrefixPath } from '../../../app/helpers/determineInternationalizationPath';
import getDeliveryCalendarByProduct from '../../../apis/product-apis/getDeliveryCalendarByProduct';

const getSCIDataFromAPI = ({ mId, JWT }) => axios.get(`https://${mbpUtil.getEnv('APP_WC_HOST')}/r/api/sci/webapi/api/1800flowers/decedentinfo/${mId}`,
    {
        headers: {
            Authorization: `Bearer ${JWT}`,
        },
    })
    .then((response) => response.data)
    .catch((err) => {
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            message: `Error from SCI endpoint SCI-Operations.js \n error = ${err}`,
        });
    });

const checkFPTcoverage = async ({ ZipCode, JWT }) => {
    let date = new Date();
    date = date.toISOString().substring(0, 10);
    const result = await getDeliveryCalendarByProduct(
        {},
        JWT,
        '95097S', // SKU
        '1001', // brandcode
        ZipCode,
        'Residence', // location type
        date, // today's date
        'G', // customer type
    )
        .then((response) => response?.data?.response?.calendarDates);
    return result;
};

function* workerGetSCIData(action) {
    try {
        const JWT = yield call(checkJWT);
        const {
            mId = '', utm_term = '', referrer = '', bannerCodeData: { url = determineCollectionPrefixPath('/sympathy-flowers-canada', false) }, history = {},
        } = action.payload;
        const apiData = yield call(getSCIDataFromAPI, { mId, JWT });
        const { Location: { ZipCode = '' } = {}, DispositionType = '' } = apiData;
        if (apiData) {
            yield put(setLocationType('Funeral home'));
            yield put(setValidatedZipcode(ZipCode));
            yield put(setProductFilterZipcodeValidity(true));
            yield put(setUserSubmittedProductFilterZipcode(ZipCode));

            // START - GB42-1354
            const calendarDates = yield call(checkFPTcoverage, { ZipCode, JWT });
            const FPTcoverage = calendarDates?.[0]?.deliverySLA === 'FPT' ? 'YES' : 'NO';
            let ServiceTime;

            if (apiData?.FuneralServices?.[0]?.DateString) {
                ServiceTime = 'Future';
            } else if (apiData?.PastFuneralServices?.[0]?.DateString) {
                ServiceTime = 'Past';
            } else {
                ServiceTime = 'None';
            }

            const ServiceType = apiData?.FuneralServices?.[0]?.IsPublic || apiData?.PastFuneralServices?.[0]?.IsPublic ? 'Public' : 'Private';
            const Disposition = apiData?.DispositionName || apiData?.DispositionType;
            apiData.ServiceTime = ServiceTime;
            apiData.FPTcoverage = FPTcoverage;
            apiData.ServiceType = ServiceType;
            apiData.Disposition = Disposition;
            yield put(setUserSelectedAddress({ addressComponents: apiData, addressSource: 'SCI' }));
            // END - GB42-1354
        }
        // first part of the URL comes from CMS (bannercode)
        // we get mId, utm_term(campagin_id), utm_referrer from the fhDirect link
        // we get utm_disposition from api call above
        // can we/should we move all this to just the CMS?
        const utm_mId_param = mId ? `&utm_mId=${mId}` : '';
        const utm_term_param = utm_term ? `&utm_term=${utm_term}` : '';
        const utm_disposition_param = DispositionType ? `&utm_disposition=${DispositionType}` : '&utm_disposition=unknown';
        const utm_referrer_param = referrer ? `&utm_referrer=${referrer}` : '';
        const redirectUrl = `${url}${utm_mId_param}${utm_term_param}${utm_disposition_param}${utm_referrer_param}`;
        yield call(history.push, redirectUrl);
    } catch (ex) {
        mbpLogger.logError({
            message: `Something went wrong attempting to reach SCI API \n error = ${ex} \n action = ${action}`,
            module: 'mbp-pwa-ui',
        });
        const { history, bannerCodeData: { url = determineCollectionPrefixPath('/sympathy-flowers-canada', false) } } = action.payload;
        yield put(setUserSelectedAddress({ addressSource: 'SCI' }));
        yield call(history.push, url);
    }
}

function* watchGetSCIData() {
    yield takeLatest(getSCIData().type, workerGetSCIData);
}

const watchers = [
    fork(watchGetSCIData),
];

export default watchers;
