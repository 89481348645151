/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

// const RESOURCE_PAGE_FACADE = "/wallets";
const RESOURCE_ROOT_FACADE = '/wallets/';
const RESOURCE_PAGE_ACTION = '/cards';

// TODO: delete if it is not in use anymore
const addCreditCard = (wcEnv, jwtToken, payloadData) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;
    const {
        contactId, accountNumber, nameOnCreditCard, expirationMonth, expirationYear, cardType,
    } = payloadData;
    const firstTwoDigits = accountNumber.substring(0, 2);
    const accountNumberLength = accountNumber.length;
    const lastFourDigits = accountNumber.substring((accountNumberLength - 4), accountNumberLength);
    let mapExpirationYear = expirationYear;
    if (expirationYear.length === 4) {
        mapExpirationYear = expirationYear.substring(2, 4);
    }
    const expiryDate = `${expirationMonth}/${mapExpirationYear}`;
    const payload = {
        wallet: {
            cardType,
            payType: 'CC',
            cardHolderName: nameOnCreditCard,
            cardNumber: accountNumber,
            firstTwoDigits,
            lastFourDigits,
            expiryDate,
            status: 'A',
            subsIndicator: 0,
            cryptoFlag: 'AES',
            tokenFlag: 0,
        },
    };
    const resourcePage = contactId + RESOURCE_PAGE_ACTION;
    return restClient.postFacade(env, resourcePage, jwtToken, payload)
        .then((response) => response, (error) => error);
};

export default addCreditCard;
