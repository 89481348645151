/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    call, fork, put, takeEvery,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import * as actions from './EshopCheckout-Actions';
import { errorActions }  from '../Common/ducks/Error';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';
import { eshopSendOrder } from '../../../../../apis/checkout-apis/eshopCheckout';

// eslint-disable-next-line consistent-return
const callEshopCheckout = async (payload) => {
    const headers = { authorization: `Bearer ${payload?.data?.token}` };
    let response;
    try {
        response = await eshopSendOrder(payload?.data?.eshopCheckoutPayload, headers);
        if (response) {
            return response.data;
        }
    } catch (e) {
        mbpLogger.logError({
            function: 'callEshopCheckout',
            module: 'eshop-checkout',
            message: response?.message,
        });
        response = { status: 500, errorMsg: response?.message };
        return response;
    }
};

export function* sendEshopData(checkoutData) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        const redirectionData = yield call(callEshopCheckout, checkoutData);
        if (redirectionData?.status === 201) {
            yield put(actions.eshopCheckoutSuccess(redirectionData));
            if (typeof window !== 'undefined') {
                window.location.href = redirectionData?.data?.redirectUrl;
            }
        } else {
            mbpLogger.logError({
                function: 'sendEshopData',
                module: 'eshop-checkout',
                message: 'Error from API call',
                redirectionData,
            });
            yield put(errorActions.loadError({ errType: 'eshop checkout', errMsg: 'Unable to checkout. Please try after sometime.' }));
        }
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (error) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        mbpLogger.logError({
            function: 'sendEshopData',
            module: 'eshop-checkout',
            message: error,
        });
        yield put(errorActions.loadError({ errType: 'eshop checkout', errMsg: 'Unable to checkout. Please try after sometime.' }));
    }
}

export function* watchEshopCheckoutData() {
    yield takeEvery(actions.sendEshopData().type, sendEshopData);
}

export default function* rootSaga() {
    yield [
        watchEshopCheckoutData(),
    ];
}

const watchers = [
    fork(watchEshopCheckoutData),
];

export {
    watchers,
};
