/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';
import Cookies from 'universal-cookie';
import parser from 'ua-parser-js';

const getKey = (entry, brand) => entry.substring(brand.length + 1);

export const parseConfig = (rawConfig, domain) => {
    const parsedConfig = {};

    try {
        mbpLogger.logDebug({
            rawConfig,
            domain,
            function: 'parseConfig',
            module: 'mbp-pwa-ui',
            message: 'Parse Config | START',
        });

        // Pull out brand-specfic properties
        Object.keys(rawConfig).forEach((entry) => {
            if (entry.indexOf(domain) !== -1) {
                const fkey = entry.startsWith(domain) ? getKey(entry, domain) : '';

                if (fkey) {
                    parsedConfig[fkey] = rawConfig[entry];
                }
            }
        });
    } catch (ex) {
        mbpLogger.logError({
            domain,
            rawConfig,
            function: 'parseConfig',
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to get parse config data.',
        });
    }

    return parsedConfig;
};

export const isCanaryModeActive = () => {
    const cookies = new Cookies();
    const canaryCookie = cookies.get('canary');
    if (canaryCookie && canaryCookie === 'true') {
        return true;
    }
    return false;
};

// Browser detection code taken from https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
export const getBrowser = () => {
    let sBrowser = '';
    const sUsrAg = (typeof window !== 'undefined') ? navigator.userAgent : '';
    // The order matters here, and this may report false positives for unlisted browsers.
    if (sUsrAg.indexOf('Firefox') > -1) {
        sBrowser = 'Mozilla Firefox';
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
        sBrowser = 'Samsung Internet';
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
        sBrowser = 'Opera';
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf('Trident') > -1) {
        sBrowser = 'Microsoft Internet Explorer';
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf('Edge') > -1) {
        sBrowser = 'Microsoft Edge';
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf('Chrome') > -1) {
        sBrowser = 'Chrome';
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf('Safari') > -1) {
        sBrowser = 'Apple Safari';
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        sBrowser = 'unknown';
    }
    return sBrowser;
};

export const getOperatingSystem = () => {
    const parsedUserAgent = (typeof window !== 'undefined') ? parser(navigator.userAgent) : { os: {} };
    const { os: { name = '' } } = parsedUserAgent;
    return name;
};

export const getAppVersion = () => {
    let version = '';
    const isCanary = isCanaryModeActive();
    if (isCanary) {
        version = 'canary';
    }
    return version;
};

export default {};
