/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

function checkBillingAddressReturnedFromPaypal(details, mobilePhone = '1234567891') {
    const { email, phone, billingAddress } = details;
    const {
        line1, city, state, postalCode, countryCode,
    } = billingAddress;
    if (billingAddress && line1 && city && state && postalCode && countryCode && email && (phone || mobilePhone || '1234567891')) {
        return true;
    }
    return false;
}

export {
    removeMaskForPhoneNumber,
    checkBillingAddressReturnedFromPaypal,
};

export default {};
