/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './GiftMessageExpressions-Actions';
import initialState from '../../../../initialState';

const giftMessageExpressionsReducer = (state = initialState.recipient.giftMessageExpressions, action) => {
    switch (action.type) {
        case actions.updateGiftExpressions().type:
            return { ...state, ...action.data };
        default:
            return state;
    }
};

export default giftMessageExpressionsReducer;
