/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const countryCodeMapping = [
    // ISO CODES ++++++++++++++++++++++++++
    {
        countryURL: '/Afghanistan',
        countryURLTrimNumeric: '/afghanistan',
        countryCodeTwoDigit: 'AF',
        countryCodeThreeDigit: 'AFG',
        countryName: 'Afghanistan',
    },
    {
        countryURL: '/Albania',
        countryURLTrimNumeric: '/albania',
        countryCodeTwoDigit: 'AL',
        countryCodeThreeDigit: 'ALB',
        countryName: 'Albania',
    },
    {
        countryURL: '/Algeria',
        countryURLTrimNumeric: '/algeria',
        countryCodeTwoDigit: 'DZ',
        countryCodeThreeDigit: 'DZA',
        countryName: 'Algeria',
    },
    {
        countryURL: '/American-Samoa',
        countryURLTrimNumeric: '/americansamoa',
        countryCodeTwoDigit: 'AS',
        countryCodeThreeDigit: 'ASM',
        countryName: 'American Samoa',
    },
    {
        countryURL: '/Andorra',
        countryURLTrimNumeric: '/andorra',
        countryCodeTwoDigit: 'AD',
        countryCodeThreeDigit: 'AND',
        countryName: 'Andorra',
    },
    {
        countryURL: '/international/angola-12510',
        countryURLTrimNumeric: '/international/angola-',
        countryCodeTwoDigit: 'AO',
        countryCodeThreeDigit: 'AGO',
        countryName: 'Angola',
    },
    {
        countryURL: '/Anguilla',
        countryURLTrimNumeric: '/anguilla',
        countryCodeTwoDigit: 'AI',
        countryCodeThreeDigit: 'AIA',
        countryName: 'Anguilla',
    },
    {
        countryURL: '/Antartica',
        countryURLTrimNumeric: '/antartica',
        countryCodeTwoDigit: 'AQ',
        countryCodeThreeDigit: 'ATA',
        countryName: 'Antartica',
    },
    {
        countryURL: '/Antigua-Barbuda',
        countryURLTrimNumeric: '/antigua-barbuda',
        countryCodeTwoDigit: 'AG',
        countryCodeThreeDigit: 'ATG',
        countryName: 'Antigua and Barbuda',
    },
    {
        countryURL: '/argentina',
        countryURLTrimNumeric: '/argentina',
        countryCodeTwoDigit: 'AR',
        countryCodeThreeDigit: 'ARG',
        countryName: 'Argentina',
    },
    {
        countryURL: '/armenia',
        countryURLTrimNumeric: '/armenia',
        countryCodeTwoDigit: 'AM',
        countryCodeThreeDigit: 'ARM',
        countryName: 'Armenia',
    },
    {
        countryURL: '/aruba',
        countryURLTrimNumeric: '/aruba',
        countryCodeTwoDigit: 'AW',
        countryCodeThreeDigit: 'ABW',
        countryName: 'Aruba',
    },
    {
        countryURL: '/australia',
        countryURLTrimNumeric: '/australia',
        countryCodeTwoDigit: 'AU',
        countryCodeThreeDigit: 'AUS',
        countryName: 'Australia',
    },
    {
        countryURL: '/austria',
        countryURLTrimNumeric: '/austria',
        countryCodeTwoDigit: 'AT',
        countryCodeThreeDigit: 'AUT',
        countryName: 'Austria',
    },
    {
        countryURL: '/Azerbaijan',
        countryURLTrimNumeric: '/azerbaijan',
        countryCodeTwoDigit: 'AZ',
        countryCodeThreeDigit: 'AZE',
        countryName: 'Azerbaijan',
    },
    {
        countryURL: '/international/bahamas-11934',
        countryURLTrimNumeric: '/international/bahamas-',
        countryCodeTwoDigit: 'BS',
        countryCodeThreeDigit: 'BHS',
        countryName: 'Bahamas',
    },
    {
        countryURL: '/international/bahamas-11934',
        countryURLTrimNumeric: '/international/bahamas-',
        countryCodeTwoDigit: 'BS',
        countryCodeThreeDigit: 'BAH',
        countryName: 'Bahamas',
    },
    {
        countryURL: '/bahrain',
        countryURLTrimNumeric: '/bahrain',
        countryCodeTwoDigit: 'BH',
        countryCodeThreeDigit: 'BHR',
        countryName: 'Bahrain',
    },
    {
        countryURL: '/blangladesh',
        countryURLTrimNumeric: '/blangladesh',
        countryCodeTwoDigit: 'BD',
        countryCodeThreeDigit: 'BGD',
        countryName: 'Blangladesh',
    },
    {
        countryURL: '/barbados',
        countryURLTrimNumeric: '/barbados',
        countryCodeTwoDigit: 'BB',
        countryCodeThreeDigit: 'BRB',
        countryName: 'Barbados',
    },
    {
        countryCodeTwoDigit: 'BY',
        countryCodeThreeDigit: 'BLR',
        countryName: 'Belarus',
        countryURL: '/belarus',
        countryURLTrimNumeric: '/belarus',
    },
    {
        countryURL: '/international/belgium-12400',
        countryURLTrimNumeric: '/international/belgium-',
        countryCodeTwoDigit: 'BE',
        countryCodeThreeDigit: 'BEL',
        countryName: 'Belgium',
    },
    {
        countryCodeTwoDigit: 'BZ',
        countryCodeThreeDigit: 'BLZ',
        countryName: 'Belize',
        countryURL: '/belize',
        countryURLTrimNumeric: '/belize',
    },
    {
        countryCodeTwoDigit: 'BJ',
        countryCodeThreeDigit: 'BEN',
        countryName: 'Benin',
        countryURL: '/benin',
        countryURLTrimNumeric: '/benin',
    },
    {
        countryCodeTwoDigit: 'BM',
        countryCodeThreeDigit: 'BMU',
        countryName: 'Bermuda',
        countryURL: '/bermuda',
        countryURLTrimNumeric: '/bermuda',
    },
    {
        countryCodeTwoDigit: 'BT',
        countryCodeThreeDigit: 'BTN',
        countryName: 'Bhutan',
        countryURL: '/bhutan',
        countryURLTrimNumeric: '/bhutan',
    },
    {
        countryURL: '/bolivia',
        countryURLTrimNumeric: '/bolivia',
        countryCodeTwoDigit: 'BO',
        countryCodeThreeDigit: 'BOL',
        countryName: 'Bolivia',
    },
    {
        countryURL: '/bonaire-saint-eustatius-saba',
        countryURLTrimNumeric: '/bonaire-saint-eustatius-saba',
        countryCodeTwoDigit: 'BQ',
        countryCodeThreeDigit: 'BES',
        countryName: 'Bonaire Saint Eustatius Saba',
    },
    {
        countryURL: '/bonia',
        countryURLTrimNumeric: '/bosnia',
        countryCodeTwoDigit: 'BA',
        countryCodeThreeDigit: 'BIH',
        countryName: 'Bosnia and Herzegovina',
    },
    {
        countryURL: '/botswana',
        countryURLTrimNumeric: '/botswana',
        countryCodeTwoDigit: 'BW',
        countryCodeThreeDigit: 'BWA',
        countryName: 'Botswana',
    },
    {
        countryURL: '/bouvet-island',
        countryURLTrimNumeric: '/bouvet-island',
        countryCodeTwoDigit: 'BV',
        countryCodeThreeDigit: 'BVT',
        countryName: 'Bouvet Island',
    },
    {
        countryURL: '/brazil',
        countryURLTrimNumeric: '/brazil',
        countryCodeTwoDigit: 'BR',
        countryCodeThreeDigit: 'BRA',
        countryName: 'Brazil',
    },
    {
        countryURL: '/british-indian-ocean-territory',
        countryURLTrimNumeric: '/british-indian-ocean-territory',
        countryCodeTwoDigit: 'IO',
        countryCodeThreeDigit: 'IOT',
        countryName: 'British Indian Ocean Territory',
    },
    {
        countryURL: '/brunei',
        countryURLTrimNumeric: '/brunei',
        countryCodeTwoDigit: 'BN',
        countryCodeThreeDigit: 'BRN',
        countryName: 'Brunei',
    },
    {
        countryCodeTwoDigit: 'BG',
        countryCodeThreeDigit: 'BGR',
        countryName: 'Bulgaria',
        countryURL: '/bulgaria',
        countryURLTrimNumeric: '/bulgaria',
    },
    {
        countryCodeTwoDigit: 'BF',
        countryCodeThreeDigit: 'BFA',
        countryName: 'Burkina Faso',
        countryURL: '/burkina-faso',
        countryURLTrimNumeric: '/burkina-faso',
    },
    {
        countryCodeTwoDigit: 'BI',
        countryCodeThreeDigit: 'BDI',
        countryName: 'Burundi',
        countryURL: '/burundi',
        countryURLTrimNumeric: '/burundi',
    },
    {
        countryCodeTwoDigit: 'KH',
        countryCodeThreeDigit: 'KHM',
        countryName: 'Cambodia',
        countryURL: '/cambodia',
        countryURLTrimNumeric: '/cambodia',
    },
    {
        countryCodeTwoDigit: 'CM',
        countryCodeThreeDigit: 'CMR',
        countryName: 'Cameroon',
        countryURL: '/Cameroon',
        countryURLTrimNumeric: '/cameroon',
    },
    {
        countryURL: '/canada',
        countryURLTrimNumeric: '/canada',
        countryCodeTwoDigit: 'CA',
        countryCodeThreeDigit: 'CAN',
        countryName: 'Canada',
    },
    {
        countryURL: '/cabo-verde',
        countryURLTrimNumeric: '/cabo-verde',
        countryCodeTwoDigit: 'CV',
        countryCodeThreeDigit: 'CPV',
        countryName: 'Cabo Verde',
    },
    {
        countryCodeTwoDigit: 'KY',
        countryCodeThreeDigit: 'CYM',
        countryName: 'Cayman Islands',
        countryURL: '/cayman-islands',
        countryURLTrimNumeric: '/cayman-islands',
    },
    {
        countryCodeTwoDigit: 'CF',
        countryCodeThreeDigit: 'CAF',
        countryName: 'Central African Republic',
        countryURL: '/central-african-republic',
        countryURLTrimNumeric: '/central-african-republic',
    },
    {
        countryCodeTwoDigit: 'TD',
        countryCodeThreeDigit: 'TCD',
        countryName: 'Chad',
        countryURL: '/chad',
        countryURLTrimNumeric: '/chad',
    },
    {
        countryURL: '/international/chile-12490',
        countryURLTrimNumeric: '/international/chile-',
        countryCodeTwoDigit: 'CL',
        countryCodeThreeDigit: 'CHL',
        countryName: 'Chile',
    },
    {
        countryURL: '/china',
        countryURLTrimNumeric: '/china',
        countryCodeTwoDigit: 'CN',
        countryCodeThreeDigit: 'CHN',
        countryName: 'China',
    },
    {
        countryURL: '/christmas-island',
        countryURLTrimNumeric: '/christmas-island',
        countryCodeTwoDigit: 'CX',
        countryCodeThreeDigit: 'CXR',
        countryName: 'Christmas Island',
    },
    {
        countryURL: '/cocos-island',
        countryURLTrimNumeric: '/cocos-island',
        countryCodeTwoDigit: 'CC',
        countryCodeThreeDigit: 'CCK',
        countryName: 'Cocos Island',
    },
    {
        countryCodeTwoDigit: 'CO',
        countryCodeThreeDigit: 'COL',
        countryName: 'Colombia',
        countryURL: '/colombia',
        countryURLTrimNumeric: '/colombia',
    },
    {
        countryCodeTwoDigit: 'KM',
        countryCodeThreeDigit: 'COM',
        countryName: 'Comoros',
        countryURL: '/comoros',
        countryURLTrimNumeric: '/comoros',
    },
    {
        countryCodeTwoDigit: 'CG',
        countryCodeThreeDigit: 'COG',
        countryName: 'Congo',
        countryURL: '/congo',
        countryURLTrimNumeric: '/congo',
    },
    {
        countryCodeTwoDigit: 'CD',
        countryCodeThreeDigit: 'COD',
        countryName: 'Democratic Republic of the Congo',
        countryURL: '/democratic-republic-congo',
        countryURLTrimNumeric: '/democratic-republic-congo',
    },
    {
        countryCodeTwoDigit: 'CK',
        countryCodeThreeDigit: 'COK',
        countryName: 'Cook Islands',
        countryURL: '/cook-islands',
        countryURLTrimNumeric: '/cook-islands',
    },
    {
        countryCodeTwoDigit: 'CR',
        countryCodeThreeDigit: 'CRI',
        countryName: 'Costa Rica',
        countryURL: '/costa-rica-12486',
        countryURLTrimNumeric: '/costa-rica-',
    },
    {
        countryCodeTwoDigit: 'CI',
        countryCodeThreeDigit: 'CIV',
        countryName: 'Cote d\'Ivoire',
        countryURL: '/cote-divoire',
        countryURLTrimNumeric: '/cote-divoire',
    },
    {
        countryCodeTwoDigit: 'HR',
        countryCodeThreeDigit: 'HRV',
        countryName: 'Croatia',
        countryURL: '/croatia',
        countryURLTrimNumeric: '/croatia',
    },
    {
        countryCodeTwoDigit: 'CU',
        countryCodeThreeDigit: 'CUB',
        countryName: 'Cuba',
        countryURL: '/cuba',
        countryURLTrimNumeric: '/cuba',
    },
    {
        countryCodeTwoDigit: 'CW',
        countryCodeThreeDigit: 'CUW',
        countryName: 'Curaçao',
        countryURL: '/Curacao',
        countryURLTrimNumeric: '/curacao',
    },
    {
        countryCodeTwoDigit: 'CY',
        countryCodeThreeDigit: 'CYP',
        countryName: 'Cyprus',
        countryURL: '/cyprus',
        countryURLTrimNumeric: '/cyprus',
    },
    {
        countryCodeTwoDigit: 'CZ',
        countryCodeThreeDigit: 'CZE',
        countryName: 'Czech Republic',
        countryURL: '/international/czechrepublic-12559',
        countryURLTrimNumeric: '/international/czechrepublic-',
    },
    {
        countryURL: '/denmark',
        countryURLTrimNumeric: '/denmark',
        countryCodeTwoDigit: 'DK',
        countryCodeThreeDigit: 'DNK',
        countryName: 'Denmark',
    },
    {
        countryURL: '/djibouti',
        countryURLTrimNumeric: '/djibouti',
        countryCodeTwoDigit: 'DJ',
        countryCodeThreeDigit: 'DJI',
        countryName: 'Djibouti',
    },
    {
        countryURL: '/dominica',
        countryURLTrimNumeric: '/dominica',
        countryCodeTwoDigit: 'DM',
        countryCodeThreeDigit: 'DMA',
        countryName: 'Dominica',
    },
    {
        countryURL: '/dominican-republic',
        countryURLTrimNumeric: '/dominican-republic',
        countryCodeTwoDigit: 'DO',
        countryCodeThreeDigit: 'DOM',
        countryName: 'Dominican Republic',
    },
    {
        countryURL: '/east-timor',
        countryURLTrimNumeric: '/east-timor',
        countryCodeTwoDigit: 'TL',
        countryCodeThreeDigit: 'TLS',
        countryName: 'Timor-Leste',
    },
    {
        countryURL: '/ecuador',
        countryURLTrimNumeric: '/ecuador',
        countryCodeTwoDigit: 'EC',
        countryCodeThreeDigit: 'ECU',
        countryName: 'Ecuador',
    }, {
        countryURL: '/international/egypt-12511',
        countryURLTrimNumeric: '/international/egypt-',
        countryCodeTwoDigit: 'EG',
        countryCodeThreeDigit: 'EGY',
        countryName: 'Egypt',
    },
    {
        countryCodeTwoDigit: 'SV',
        countryCodeThreeDigit: 'SLV',
        countryName: 'El Salvador',
        countryURL: '/international/el-salvador-12487',
        countryURLTrimNumeric: '/international/el-salvador-',
    },
    {
        countryCodeTwoDigit: 'SX',
        countryCodeThreeDigit: 'SXM',
        countryName: 'Sint Maarten (Dutch part)',
        countryURL: '/international/sint-maarten',
        countryURLTrimNumeric: '/sint-maarten',
    },
    {
        countryCodeTwoDigit: 'GQ',
        countryCodeThreeDigit: 'GNQ',
        countryName: 'Equatorial Guinea',
        countryURL: '/equatorial-guinea',
        countryURLTrimNumeric: '/equatorial-guinea',
    },
    {
        countryCodeTwoDigit: 'ER',
        countryCodeThreeDigit: 'ERI',
        countryName: 'Eritrea',
        countryURL: '/eritrea',
        countryURLTrimNumeric: '/eritrea',
    },
    {
        countryCodeTwoDigit: 'EE',
        countryCodeThreeDigit: 'EST',
        countryName: 'Estonia',
        countryURL: '/estonia',
        countryURLTrimNumeric: '/estonia',
    },
    {
        countryCodeTwoDigit: 'ET',
        countryCodeThreeDigit: 'ETH',
        countryName: 'Ethiopia',
        countryURL: '/ethiopia',
        countryURLTrimNumeric: '/ethiopia',
    },
    {
        countryCodeTwoDigit: 'FK',
        countryCodeThreeDigit: 'FLK',
        countryName: 'Falkland Islands',
        countryURL: '/falkland-islands',
        countryURLTrimNumeric: '/falkland-islands',
    },
    {
        countryURL: '/faroe-islands',
        countryURLTrimNumeric: '/faroe-islands',
        countryCodeTwoDigit: 'FO',
        countryCodeThreeDigit: 'FRO',
        countryName: 'Faroe Islands',
    },
    {
        countryURL: '/fiji',
        countryURLTrimNumeric: '/fiji',
        countryCodeTwoDigit: 'FJ',
        countryCodeThreeDigit: 'FJI',
        countryName: 'Fiji',
    },
    {
        countryURL: '/finland',
        countryURLTrimNumeric: '/finland',
        countryCodeTwoDigit: 'FI',
        countryCodeThreeDigit: 'FIN',
        countryName: 'Finland',
    },
    {
        countryURL: '/france',
        countryURLTrimNumeric: '/france',
        countryCodeTwoDigit: 'FR',
        countryCodeThreeDigit: 'FRA',
        countryName: 'France',
    },
    {
        countryURL: '/french-guiana',
        countryURLTrimNumeric: '/french-guiana',
        countryCodeTwoDigit: 'GF',
        countryCodeThreeDigit: 'GUF',
        countryName: 'French Guiana',
    },
    {
        countryURL: '/french-polynesia',
        countryURLTrimNumeric: '/french-polynesia',
        countryCodeTwoDigit: 'PF',
        countryCodeThreeDigit: 'PYF',
        countryName: 'French-polynesia',
    },
    {
        countryURL: '/french-southern-territories',
        countryURLTrimNumeric: '/french-southern-territories',
        countryCodeTwoDigit: 'TF',
        countryCodeThreeDigit: 'ATF',
        countryName: 'French Southern Territories',
    },
    {
        countryURL: '/gabon',
        countryURLTrimNumeric: '/gabon',
        countryCodeTwoDigit: 'GA',
        countryCodeThreeDigit: 'GAB',
        countryName: 'Gabon',
    },
    {
        countryURL: '/gambia',
        countryURLTrimNumeric: '/gambia',
        countryCodeTwoDigit: 'GM',
        countryCodeThreeDigit: 'GMB',
        countryName: 'Gambia',
    },
    {
        countryCodeTwoDigit: 'GE',
        countryCodeThreeDigit: 'GEO',
        countryName: 'Georgia',
        countryURL: '/georgia',
        countryURLTrimNumeric: '/georgia',
    },
    {
        countryURL: '/germany',
        countryURLTrimNumeric: '/germany',
        countryCodeTwoDigit: 'DE',
        countryCodeThreeDigit: 'DEU',
        countryName: 'Germany',
    },
    {
        countryURL: '/ghana',
        countryURLTrimNumeric: '/ghana',
        countryCodeTwoDigit: 'GH',
        countryCodeThreeDigit: 'GHA',
        countryName: 'Ghana',
    },
    {
        countryCodeTwoDigit: 'GI',
        countryCodeThreeDigit: 'GIB',
        countryName: 'Gibraltar',
        countryURL: '/gibraltar',
        countryURLTrimNumeric: '/gibraltar',
    },
    {
        countryURL: '/greece',
        countryURLTrimNumeric: '/greece',
        countryCodeTwoDigit: 'GR',
        countryCodeThreeDigit: 'GRC',
        countryName: 'Greece',
    },
    {
        countryURL: '/south-georgia',
        countryURLTrimNumeric: '/south-georgia',
        countryCodeTwoDigit: 'GS',
        countryCodeThreeDigit: 'SGS',
        countryName: 'South Georgia and the South Sandwich Islands',
    },
    {
        countryURL: '/greenland',
        countryURLTrimNumeric: '/greenland',
        countryCodeTwoDigit: 'GL',
        countryCodeThreeDigit: 'GRL',
        countryName: 'Greenland',
    },
    {
        countryURL: '/grenada',
        countryURLTrimNumeric: '/grenada',
        countryCodeTwoDigit: 'GD',
        countryCodeThreeDigit: 'GRD',
        countryName: 'Grenada',
    },
    {
        countryURL: '/guadeloupe',
        countryURLTrimNumeric: '/guadeloupe',
        countryCodeTwoDigit: 'GP',
        countryCodeThreeDigit: 'GLP',
        countryName: 'Guadeloupe',
    },
    {
        countryURL: '/international/guam-12485',
        countryURLTrimNumeric: '/international/guam-',
        countryCodeTwoDigit: 'GU',
        countryCodeThreeDigit: 'GUM',
        countryName: 'Guam',
    },
    {
        countryURL: '/international/guatemala-12488',
        countryURLTrimNumeric: '/international/guatemala-',
        countryCodeTwoDigit: 'GT',
        countryCodeThreeDigit: 'GTM',
        countryName: 'Guatemala',
    },
    {
        countryURL: '/guernsey',
        countryURLTrimNumeric: '/guernsey',
        countryCodeTwoDigit: 'GG',
        countryCodeThreeDigit: 'GGY',
        countryName: 'Guernsey',
    },
    {
        countryURL: '/guinea',
        countryURLTrimNumeric: '/guinea',
        countryCodeTwoDigit: 'GN',
        countryCodeThreeDigit: 'GIN',
        countryName: 'Guinea',
    },
    {
        countryURL: '/guinea-bissau',
        countryURLTrimNumeric: '/guinea-bissau',
        countryCodeTwoDigit: 'GW',
        countryCodeThreeDigit: 'GNB',
        countryName: 'Guinea-Bissau',
    },
    {
        countryURL: '/international/guyana-12394',
        countryURLTrimNumeric: '/international/guyana-',
        countryCodeTwoDigit: 'GY',
        countryCodeThreeDigit: 'GUY',
        countryName: 'Guyana',
    },
    {
        countryURL: '/haiti',
        countryURLTrimNumeric: '/haiti',
        countryCodeTwoDigit: 'HT',
        countryCodeThreeDigit: 'HTI',
        countryName: 'Haiti',
    },
    {
        countryURL: '/heard-island-and-mcdonald-island',
        countryURLTrimNumeric: '/heard-island-and-mcdonald-island',
        countryCodeTwoDigit: 'HM',
        countryCodeThreeDigit: 'HMD',
        countryName: 'Heard Island and McDonald Islands',
    },
    {
        countryURL: '/holy-see',
        countryURLTrimNumeric: '/holy-see',
        countryCodeTwoDigit: 'VA',
        countryCodeThreeDigit: 'VAT',
        countryName: 'Holy See',
    },
    {
        countryURL: '/honduras',
        countryURLTrimNumeric: '/honduras',
        countryCodeTwoDigit: 'HN',
        countryCodeThreeDigit: 'HND',
        countryName: 'Honduras',
    },
    {
        countryCodeTwoDigit: 'HK',
        countryCodeThreeDigit: 'HKG',
        countryName: 'Hong Kong S.A.R. of China',
        countryURL: '/hong-kong',
        countryURLTrimNumeric: '/hong-kong',
    },
    {
        countryURL: '/hungary',
        countryURLTrimNumeric: '/hungary',
        countryCodeTwoDigit: 'HU',
        countryCodeThreeDigit: 'HUN',
        countryName: 'Hungary',
    },
    {
        countryCodeTwoDigit: 'IS',
        countryCodeThreeDigit: 'ISL',
        countryName: 'Iceland',
        countryURL: '/iceland',
        countryURLTrimNumeric: '/iceland',
    },
    {
        countryURL: '/india',
        countryURLTrimNumeric: '/india',
        countryCodeTwoDigit: 'IN',
        countryCodeThreeDigit: 'IND',
        countryName: 'India',
    },
    {
        countryURL: '/international/indonesia-12491',
        countryURLTrimNumeric: '/international/indonesia-',
        countryCodeTwoDigit: 'ID',
        countryCodeThreeDigit: 'IDN',
        countryName: 'Indonesia',
    },
    {
        countryURL: '/iran',
        countryURLTrimNumeric: '/iran',
        countryCodeTwoDigit: 'IR',
        countryCodeThreeDigit: 'IRN',
        countryName: 'Iran',
    },
    {
        countryURL: '/iraq',
        countryURLTrimNumeric: '/iraq',
        countryCodeTwoDigit: 'IQ',
        countryCodeThreeDigit: 'IRQ',
        countryName: 'Iraq',
    },
    {
        countryURL: '/ireland',
        countryURLTrimNumeric: '/ireland',
        countryCodeTwoDigit: 'IE',
        countryCodeThreeDigit: 'IRE',
        countryName: 'Ireland',
    },
    {
        countryURL: '/isle-man',
        countryURLTrimNumeric: '/isle-man',
        countryCodeTwoDigit: 'IM',
        countryCodeThreeDigit: 'IMN',
        countryName: 'Isle of Man',
    },
    {
        countryURL: '/israel',
        countryURLTrimNumeric: '/israel',
        countryCodeTwoDigit: 'IL',
        countryCodeThreeDigit: 'ISR',
        countryName: 'Israel',
    },
    {
        countryURL: '/italy',
        countryURLTrimNumeric: '/italy',
        countryCodeTwoDigit: 'IT',
        countryCodeThreeDigit: 'ITA',
        countryName: 'Italy',
    },
    {
        countryURL: '/jamaica',
        countryURLTrimNumeric: '/jamaica',
        countryCodeTwoDigit: 'JM',
        countryCodeThreeDigit: 'JAM',
        countryName: 'Jamaica',
    },
    {
        countryURL: '/japan',
        countryURLTrimNumeric: '/japan',
        countryCodeTwoDigit: 'JP',
        countryCodeThreeDigit: 'JPN',
        countryName: 'Japan',
    },
    {
        countryURL: '/jersey',
        countryURLTrimNumeric: '/jersey',
        countryCodeTwoDigit: 'JE',
        countryCodeThreeDigit: 'JEY',
        countryName: 'Jersey',
    },
    {
        countryCodeTwoDigit: 'JO',
        countryCodeThreeDigit: 'JOR',
        countryName: 'Jordan',
        countryURL: '/jordan',
        countryURLTrimNumeric: '/jordan',
    },
    {
        countryCodeTwoDigit: 'KZ',
        countryCodeThreeDigit: 'KAZ',
        countryName: 'Kazakhstan',
        countryURL: '/kazakhstan',
        countryURLTrimNumeric: '/kazakhstan',
    },
    {
        countryCodeTwoDigit: 'KE',
        countryCodeThreeDigit: 'KEN',
        countryName: 'Kenya',
        countryURL: '/kenya',
        countryURLTrimNumeric: '/kenya',
    },
    {
        countryCodeTwoDigit: 'KI',
        countryCodeThreeDigit: 'KIR',
        countryName: 'Kiribati',
        countryURL: '/kiribati',
        countryURLTrimNumeric: '/kiribati',
    },
    {
        countryCodeTwoDigit: 'KP',
        countryCodeThreeDigit: 'PRK',
        countryName: 'Korea, North',
        countryURL: '/korea-north',
        countryURLTrimNumeric: '/korea-north',
    },
    {
        countryCodeTwoDigit: 'KR',
        countryCodeThreeDigit: 'KOR',
        countryName: 'Korea, South',
        countryURL: '/international/south-korea-12398',
        countryURLTrimNumeric: '/international/south-korea-',
    },
    {
        countryURL: '/kuwait',
        countryURLTrimNumeric: '/kuwait',
        countryCodeTwoDigit: 'KW',
        countryCodeThreeDigit: 'KWT',
        countryName: 'Kuwait',
    },
    {
        countryURL: '/kyrgyzstan',
        countryURLTrimNumeric: '/kyrgyzstan',
        countryCodeTwoDigit: 'KG',
        countryCodeThreeDigit: 'KGZ',
        countryName: 'Kyrgyzstan',
    },
    {
        countryURL: '/laos',
        countryURLTrimNumeric: '/laos',
        countryCodeTwoDigit: 'LA',
        countryCodeThreeDigit: 'LAO',
        countryName: 'Lao People\'s Democratic Republic',
    },
    {
        countryCodeTwoDigit: 'LV',
        countryCodeThreeDigit: 'LVA',
        countryName: 'Latvia',
        countryURL: '/latvia',
        countryURLTrimNumeric: '/latvia',
    },
    {
        countryCodeTwoDigit: 'LB',
        countryCodeThreeDigit: 'LBN',
        countryName: 'Lebanon',
        countryURL: '/lebanon',
        countryURLTrimNumeric: '/lebanon',
    },
    {
        countryCodeTwoDigit: 'LS',
        countryCodeThreeDigit: 'LSO',
        countryName: 'Lesotho',
        countryURL: '/lesotho',
        countryURLTrimNumeric: '/lesotho',
    },
    {
        countryCodeTwoDigit: 'LR',
        countryCodeThreeDigit: 'LBR',
        countryName: 'Liberia',
        countryURL: '/liberia',
        countryURLTrimNumeric: '/liberia',
    },
    {
        countryCodeTwoDigit: 'LY',
        countryCodeThreeDigit: 'LBY',
        countryName: 'Libya',
        countryURL: '/libya',
        countryURLTrimNumeric: '/libya',
    },
    {
        countryCodeTwoDigit: 'LI',
        countryCodeThreeDigit: 'LIE',
        countryName: 'Liechtenstein',
        countryURL: '/liechtenstein',
        countryURLTrimNumeric: '/liechtenstein',
    }, {
        countryCodeTwoDigit: 'LT',
        countryCodeThreeDigit: 'LTU',
        countryName: 'Lithuania',
        countryURL: '/lithuania',
        countryURLTrimNumeric: '/lithuania',
    },
    {
        countryURL: '/international/luxembourg-12401',
        countryURLTrimNumeric: '/international/luxembourg-',
        countryCodeTwoDigit: 'LU',
        countryCodeThreeDigit: 'LUX',
        countryName: 'Luxembourg',
    },
    {
        countryURL: '/macau',
        countryURLTrimNumeric: '/macau',
        countryCodeTwoDigit: 'MO',
        countryCodeThreeDigit: 'MAC',
        countryName: 'China, Macao Special Administrative Region',
    },
    {
        countryURL: '/macedonia',
        countryURLTrimNumeric: '/macedonia',
        countryCodeTwoDigit: 'MK',
        countryCodeThreeDigit: 'MKD',
        countryName: 'Macedonia',
    },
    {
        countryURL: '/madagascar',
        countryURLTrimNumeric: '/madagascar',
        countryCodeTwoDigit: 'MG',
        countryCodeThreeDigit: 'MDG',
        countryName: 'Madagascar',
    },
    {
        countryURL: '/malawi',
        countryURLTrimNumeric: '/malawi',
        countryCodeTwoDigit: 'MW',
        countryCodeThreeDigit: 'MWI',
        countryName: 'Malawi',
    },
    {
        countryURL: '/malaysia',
        countryURLTrimNumeric: '/malaysia',
        countryCodeTwoDigit: 'MYS',
        countryCodeThreeDigit: 'MY',
        countryName: 'Malaysia',
    },
    {
        countryURL: '/maldives',
        countryURLTrimNumeric: '/maldives',
        countryCodeTwoDigit: 'MV',
        countryCodeThreeDigit: 'MDV',
        countryName: 'Maldives',
    },
    {
        countryURL: '/mali',
        countryURLTrimNumeric: '/mali',
        countryCodeTwoDigit: 'ML',
        countryCodeThreeDigit: 'MLI',
        countryName: 'Mali',
    },
    {
        countryCodeTwoDigit: 'MT',
        countryCodeThreeDigit: 'MLT',
        countryName: 'Malta',
        countryURL: '/malta',
        countryURLTrimNumeric: '/malta',
    },
    {
        countryURL: '/marshall-island',
        countryURLTrimNumeric: '/marshall-island',
        countryCodeTwoDigit: 'MH',
        countryCodeThreeDigit: 'MHL',
        countryName: 'Marshall Island',
    },
    {
        countryURL: '/martinique',
        countryURLTrimNumeric: '/martinique',
        countryCodeTwoDigit: 'MQ',
        countryCodeThreeDigit: 'MTQ',
        countryName: 'Martinique',
    },
    {
        countryURL: '/mauritania',
        countryURLTrimNumeric: '/mauritania',
        countryCodeTwoDigit: 'MR',
        countryCodeThreeDigit: 'MRT',
        countryName: 'Mauritania',
    },
    {
        countryURL: '/mauritius',
        countryURLTrimNumeric: '/mauritius',
        countryCodeTwoDigit: 'MU',
        countryCodeThreeDigit: 'MUS',
        countryName: 'Mauritius',
    },
    {
        countryURL: '/mayotte',
        countryURLTrimNumeric: '/mayotte',
        countryCodeTwoDigit: 'YT',
        countryCodeThreeDigit: 'MYT',
        countryName: 'Mayotte',
    },
    {
        countryURL: '/mexico',
        countryURLTrimNumeric: '/mexico',
        countryCodeTwoDigit: 'MX',
        countryCodeThreeDigit: 'MEX',
        countryName: 'Mexico',
    },
    {
        countryURL: '/micronesia',
        countryURLTrimNumeric: '/micronesia',
        countryCodeTwoDigit: 'FM',
        countryCodeThreeDigit: 'FSM',
        countryName: 'Micronesia',
    },
    {
        countryURL: '/moldova',
        countryURLTrimNumeric: '/moldova',
        countryCodeTwoDigit: 'MD',
        countryCodeThreeDigit: 'MDA',
        countryName: 'Moldova',
    },
    {
        countryURL: '/monaco',
        countryURLTrimNumeric: '/monaco',
        countryCodeTwoDigit: 'MC',
        countryCodeThreeDigit: 'MCO',
        countryName: 'Monaco',
    },
    {
        countryURL: '/mongolia',
        countryURLTrimNumeric: '/mongolia',
        countryCodeTwoDigit: 'MN',
        countryCodeThreeDigit: 'MNG',
        countryName: 'Mongolia',
    },
    {
        countryURL: '/montenegro',
        countryURLTrimNumeric: '/montenegro',
        countryCodeTwoDigit: 'ME',
        countryCodeThreeDigit: 'MNE',
        countryName: 'Montenegro',
    },
    {
        countryURL: '/montserrat',
        countryURLTrimNumeric: '/montserrat',
        countryCodeTwoDigit: 'MS',
        countryCodeThreeDigit: 'MSR',
        countryName: 'Montserrat',
    },
    {
        countryCodeTwoDigit: 'MA',
        countryCodeThreeDigit: 'MAR',
        countryName: 'Morocco',
        countryURL: '/morocco',
        countryURLTrimNumeric: '/morocco',
    },
    {
        countryCodeTwoDigit: 'MZ',
        countryCodeThreeDigit: 'MOZ',
        countryName: 'Mozambique',
        countryURL: '/mozambique',
        countryURLTrimNumeric: '/mozambique',
    },
    {
        countryCodeTwoDigit: 'MM',
        countryCodeThreeDigit: 'MMR',
        countryName: 'Myanmar',
        countryURL: '/myanmar',
        countryURLTrimNumeric: '/myanmar',
    },
    {
        countryCodeTwoDigit: 'NA',
        countryCodeThreeDigit: 'NAM',
        countryName: 'Namibia',
        countryURL: '/namibia',
        countryURLTrimNumeric: '/namibia',
    },
    {
        countryCodeTwoDigit: 'NR',
        countryCodeThreeDigit: 'NRU',
        countryName: 'Nauru',
        countryURL: '/nauru',
        countryURLTrimNumeric: '/nauru',
    },
    {
        countryURL: '/nepal',
        countryURLTrimNumeric: '/nepal',
        countryCodeTwoDigit: 'NP',
        countryCodeThreeDigit: 'NAP',
        countryName: 'Nepal',
    },
    {
        countryURL: '/nepal',
        countryURLTrimNumeric: '/nepal',
        countryCodeTwoDigit: 'NP',
        countryCodeThreeDigit: 'NPL',
        countryName: 'Nepal',
    },
    {
        countryURL: '/netherlands',
        countryURLTrimNumeric: '/netherlands',
        countryCodeTwoDigit: 'NL',
        countryCodeThreeDigit: 'NLD',
        countryName: 'Netherlands',
    },
    {
        countryURL: '/new-caledonia',
        countryURLTrimNumeric: '/new-caledonia',
        countryCodeTwoDigit: 'NC',
        countryCodeThreeDigit: 'NCL',
        countryName: 'New Caledonia',
    },
    {
        countryCodeTwoDigit: 'NZ',
        countryCodeThreeDigit: 'NZL',
        countryName: 'New Zealand',
        countryURL: '/new-zealand',
        countryURLTrimNumeric: '/new-zealand',
    },
    {
        countryCodeTwoDigit: 'NI',
        countryCodeThreeDigit: 'NIC',
        countryName: 'Nicaragua',
        countryURL: '/nicaragua',
        countryURLTrimNumeric: '/nicaragua',
    },
    {
        countryCodeTwoDigit: 'NE',
        countryCodeThreeDigit: 'NER',
        countryName: 'Niger',
        countryURL: '/niger',
        countryURLTrimNumeric: '/niger',
    },
    {
        countryCodeTwoDigit: 'NG',
        countryCodeThreeDigit: 'NGA',
        countryName: 'Nigeria',
        countryURL: '/nigeria',
        countryURLTrimNumeric: '/nigeria',
    },
    {
        countryCodeTwoDigit: 'NU',
        countryCodeThreeDigit: 'NIU',
        countryName: 'Niue',
        countryURL: '/niue',
        countryURLTrimNumeric: '/niue',
    },
    {
        countryCodeTwoDigit: 'NF',
        countryCodeThreeDigit: 'NFK',
        countryName: 'Norfolk Island',
        countryURL: '/norfolk-island',
        countryURLTrimNumeric: '/norfolk-island',
    },
    {
        countryURL: '/northern-mariana-islands',
        countryURLTrimNumeric: '/northern-mariana-islands',
        countryCodeTwoDigit: 'MP',
        countryCodeThreeDigit: 'MNP',
        countryName: 'Northern Mariana Islands',
    },
    {
        countryURL: '/norway',
        countryURLTrimNumeric: '/norway',
        countryCodeTwoDigit: 'NO',
        countryCodeThreeDigit: 'NOR',
        countryName: 'Norway',
    },
    {
        countryURL: '/international/romania-12452',
        countryURLTrimNumeric: '/international/romania-',
        countryCodeTwoDigit: 'OM',
        countryCodeThreeDigit: 'OMN',
        countryName: 'Oman',
    },
    {
        countryURL: '/pakistan',
        countryURLTrimNumeric: '/pakistan',
        countryCodeTwoDigit: 'PK',
        countryCodeThreeDigit: 'PAK',
        countryName: 'Pakistan',
    },
    {
        countryURL: '/palau',
        countryURLTrimNumeric: '/palau',
        countryCodeTwoDigit: 'PW',
        countryCodeThreeDigit: 'PLW',
        countryName: 'Palau',
    },
    {
        countryURL: '/palestine',
        countryURLTrimNumeric: '/palestine',
        countryCodeTwoDigit: 'PS',
        countryCodeThreeDigit: 'PSE',
        countryName: 'State of Palestine',
    },
    {
        countryURL: '/panama',
        countryURLTrimNumeric: '/panama',
        countryCodeTwoDigit: 'PA',
        countryCodeThreeDigit: 'PAN',
        countryName: 'Panama',
    },
    {
        countryURL: '/papua-new-guinea',
        countryURLTrimNumeric: '/papua-new-guinea',
        countryCodeTwoDigit: 'PG',
        countryCodeThreeDigit: 'PNG',
        countryName: 'Papua New Guinea',
    },
    {
        countryURL: '/paraguay',
        countryURLTrimNumeric: '/paraguay',
        countryCodeTwoDigit: 'PY',
        countryCodeThreeDigit: 'PRY',
        countryName: 'Paraguay',
    },
    {
        countryURL: '/peru',
        countryURLTrimNumeric: '/peru',
        countryCodeTwoDigit: 'PE',
        countryCodeThreeDigit: 'PER',
        countryName: 'Peru',
    },
    {
        countryCodeTwoDigit: 'PH',
        countryCodeThreeDigit: 'PHL',
        countryName: 'Philippines',
        countryURL: '/philippines',
        countryURLTrimNumeric: '/philippines',
    },
    {
        countryCodeTwoDigit: 'PN',
        countryCodeThreeDigit: 'PCN',
        countryName: 'Pitcairn',
        countryURL: '/pitcairn',
        countryURLTrimNumeric: '/pitcairn',
    },
    {
        countryURL: '/international/poland-12111',
        countryURLTrimNumeric: '/international/poland-',
        countryCodeTwoDigit: 'PL',
        countryCodeThreeDigit: 'POL',
        countryName: 'Poland',
    },
    {
        countryURL: '/portugal',
        countryURLTrimNumeric: '/portugal',
        countryCodeTwoDigit: 'PT',
        countryCodeThreeDigit: 'PRT',
        countryName: 'Portugal',
    },
    {
        countryURL: '/puerto-rico',
        countryURLTrimNumeric: '/puerto-rico',
        countryCodeTwoDigit: 'PR',
        countryCodeThreeDigit: 'PRI',
        countryName: 'Puerto Rico',
    },
    {
        countryURL: '/qatar',
        countryURLTrimNumeric: '/qatar',
        countryCodeTwoDigit: 'QA',
        countryCodeThreeDigit: 'QAT',
        countryName: 'Qatar',
    },
    {
        countryURL: '/reunion',
        countryURLTrimNumeric: '/reunion',
        countryCodeTwoDigit: 'RE',
        countryCodeThreeDigit: 'REU',
        countryName: 'Reunion',
    },
    {
        countryURL: '/international/romania-12452',
        countryURLTrimNumeric: '/international/romania-',
        countryCodeTwoDigit: 'RO',
        countryCodeThreeDigit: 'ROU',
        countryName: 'Romania',
    },
    {
        countryCodeTwoDigit: 'RU',
        countryCodeThreeDigit: 'RUS',
        countryName: 'Russian Federation',
        countryURL: '/international/russia-12377',
        countryURLTrimNumeric: '/international/russia-',
    },
    {
        countryURL: '/rwanda',
        countryURLTrimNumeric: '/rwanda',
        countryCodeTwoDigit: 'RW',
        countryCodeThreeDigit: 'RWA',
        countryName: 'Rwanda',
    },
    {
        countryURL: '/saint-barthelemy',
        countryURLTrimNumeric: '/saint-barthelemy',
        countryCodeTwoDigit: 'BL',
        countryCodeThreeDigit: 'BLM',
        countryName: 'Saint-Barthélemy',
    },
    {
        countryURL: '/saint-helena',
        countryURLTrimNumeric: '/saint-helena',
        countryCodeTwoDigit: 'SH',
        countryCodeThreeDigit: 'SHN',
        countryName: 'Saint Helena',
    },
    {
        countryURL: '/saint-kitts-nevis',
        countryURLTrimNumeric: '/saint-kitts-nevis',
        countryCodeTwoDigit: 'KN',
        countryCodeThreeDigit: 'KNA',
        countryName: 'Saint Kitts and Nevis',
    },
    {
        countryURL: '/saint-lucia',
        countryURLTrimNumeric: '/saint-lucia',
        countryCodeTwoDigit: 'LC',
        countryCodeThreeDigit: 'LCA',
        countryName: 'Saint Lucia',
    },
    {
        countryURL: '/saint-martin',
        countryURLTrimNumeric: '/saint-martin',
        countryCodeTwoDigit: 'MF',
        countryCodeThreeDigit: 'MAF',
        countryName: 'Saint Martin',
    },
    {
        countryURL: '/saint-pierre-miquelon',
        countryURLTrimNumeric: '/saint-pierre-miquelon',
        countryCodeTwoDigit: 'PM',
        countryCodeThreeDigit: 'SPM',
        countryName: 'Saint Pierre and Miquelon',
    },
    {
        countryURL: '/saint-vicent-grenadines',
        countryURLTrimNumeric: '/saint-vicent-grenadines',
        countryCodeTwoDigit: 'VC',
        countryCodeThreeDigit: 'VCT',
        countryName: 'Saint Vicent and the Grenadines',
    },
    {
        countryURL: '/samoa',
        countryURLTrimNumeric: '/samoa',
        countryCodeTwoDigit: 'WS',
        countryCodeThreeDigit: 'WSM',
        countryName: 'Samoa',
    },
    {
        countryURL: '/san-marino',
        countryURLTrimNumeric: '/san-marino',
        countryCodeTwoDigit: 'SM',
        countryCodeThreeDigit: 'SMR',
        countryName: 'San Marino',
    },
    {
        countryURL: '/sao-tome-principe',
        countryURLTrimNumeric: '/sao-tome-principe',
        countryCodeTwoDigit: 'ST',
        countryCodeThreeDigit: 'STP',
        countryName: 'Sao Tome and Principe',
    },
    {
        countryCodeTwoDigit: 'SA',
        countryCodeThreeDigit: 'SAU',
        countryName: 'Saudi Arabia',
        countryURL: '/international/saudi-arabia-12417',
        countryURLTrimNumeric: '/international/saudi-arabia-',
    },
    {
        countryURL: '/senegal',
        countryURLTrimNumeric: '/senegal',
        countryCodeTwoDigit: 'SN',
        countryCodeThreeDigit: 'SEN',
        countryName: 'Senegal',
    },
    {
        countryURL: '/serbia',
        countryURLTrimNumeric: '/serbia',
        countryCodeTwoDigit: 'RS',
        countryCodeThreeDigit: 'SRB',
        countryName: 'Serbia',
    },
    {
        countryURL: '/Seychelles',
        countryURLTrimNumeric: '/seychelles',
        countryCodeTwoDigit: 'SC',
        countryCodeThreeDigit: 'SYC',
        countryName: 'Seychelles',
    },
    {
        countryURL: '/sierra-leone',
        countryURLTrimNumeric: '/sierra-leone',
        countryCodeTwoDigit: 'SL',
        countryCodeThreeDigit: 'SLE',
        countryName: 'Sierra Leone',
    },
    {
        countryURL: '/singapore',
        countryURLTrimNumeric: '/singapore',
        countryCodeTwoDigit: 'SG',
        countryCodeThreeDigit: 'SGP',
        countryName: 'Singapore',
    },

    {
        countryURL: '/slovakia',
        countryURLTrimNumeric: '/slovakia',
        countryCodeTwoDigit: 'SK',
        countryCodeThreeDigit: 'SVK',
        countryName: 'Slovakia',
    },
    {
        countryURL: '/slovenia',
        countryURLTrimNumeric: '/slovenia',
        countryCodeTwoDigit: 'SI',
        countryCodeThreeDigit: 'SVN',
        countryName: 'Slovenia',
    },
    {
        countryURL: '/solomon-islands',
        countryURLTrimNumeric: '/solomon-islands',
        countryCodeTwoDigit: 'SB',
        countryCodeThreeDigit: 'SLB',
        countryName: 'Solomon Islands',
    },
    {
        countryURL: '/somalia',
        countryURLTrimNumeric: '/somalia',
        countryCodeTwoDigit: 'SO',
        countryCodeThreeDigit: 'SOM',
        countryName: 'Somalia',
    },
    {
        countryCodeTwoDigit: 'ZA',
        countryCodeThreeDigit: 'ZAF',
        countryName: 'South Africa',
        countryURL: '/south-africa',
        countryURLTrimNumeric: '/south-africa',
    },
    {
        countryCodeTwoDigit: 'SS',
        countryCodeThreeDigit: 'SSD',
        countryName: 'South Sudan',
        countryURL: '/south-sudan',
        countryURLTrimNumeric: '/south-sudan',
    },
    {
        countryURL: '/spain',
        countryURLTrimNumeric: '/spain',
        countryCodeTwoDigit: 'ES',
        countryCodeThreeDigit: 'ESP',
        countryName: 'Spain',
    },
    {
        countryURL: '/sri-lanka',
        countryURLTrimNumeric: '/sri-lanka',
        countryCodeTwoDigit: 'LK',
        countryCodeThreeDigit: 'LKA',
        countryName: 'Sri Lanka',
    },
    {
        countryURL: '/sudan',
        countryURLTrimNumeric: '/sudan',
        countryCodeTwoDigit: 'SD',
        countryCodeThreeDigit: 'SDN',
        countryName: 'Sudan',
    },
    {
        countryCodeTwoDigit: 'SR',
        countryCodeThreeDigit: 'SUR',
        countryName: 'Suriname',
        countryURL: '/suriname',
        countryURLTrimNumeric: '/suriname',
    },
    {
        countryCodeTwoDigit: 'SJ',
        countryCodeThreeDigit: 'SJM',
        countryName: 'Svalbard Jan Mayen Islands',
        countryURL: '/svalbard-jan-mayen-islands',
        countryURLTrimNumeric: '/svalbard-jan-mayen-islands',
    },
    {
        countryURL: '/swaziland',
        countryURLTrimNumeric: '/swaziland',
        countryCodeTwoDigit: 'SZ',
        countryCodeThreeDigit: 'SWZ',
        countryName: 'Swaziland',
    },
    {
        countryURL: '/sweden',
        countryURLTrimNumeric: '/sweden',
        countryCodeTwoDigit: 'SE',
        countryCodeThreeDigit: 'SWE',
        countryName: 'Sweden',
    },
    {
        countryURL: '/switzerland',
        countryURLTrimNumeric: '/switzerland',
        countryCodeTwoDigit: 'CH',
        countryCodeThreeDigit: 'CHE',
        countryName: 'Switzerland',
    },
    {
        countryURL: '/syrian-arab-republic',
        countryURLTrimNumeric: '/syrian-arab-republic',
        countryCodeTwoDigit: 'SY',
        countryCodeThreeDigit: 'SYR',
        countryName: 'Syrian Arab Republic',
    },
    {
        countryCodeTwoDigit: 'TW',
        countryCodeThreeDigit: 'TWN',
        countryName: 'Taiwan',
        countryURL: '/international/taiwain-12408',
        countryURLTrimNumeric: '/international/taiwain-',
    },
    {
        countryCodeTwoDigit: 'TJ',
        countryCodeThreeDigit: 'TJK',
        countryName: 'Tajikistan',
        countryURL: '/tajikistan',
        countryURLTrimNumeric: '/tajikistan',
    },
    {
        countryCodeTwoDigit: 'TZ',
        countryCodeThreeDigit: 'TZA',
        countryName: 'Tanzania',
        countryURL: '/tanzania',
        countryURLTrimNumeric: '/tanzania',
    },
    {
        countryURL: '/international/thailand-12411',
        countryURLTrimNumeric: '/international/thailand-',
        countryCodeTwoDigit: 'TH',
        countryCodeThreeDigit: 'THA',
        countryName: 'Thailand',
    },
    {
        countryCodeTwoDigit: 'TG',
        countryCodeThreeDigit: 'TGO',
        countryName: 'Togo',
        countryURL: '/togo',
        countryURLTrimNumeric: '/togo',
    },
    {
        countryCodeTwoDigit: 'TK',
        countryCodeThreeDigit: 'TKL',
        countryName: 'Tokelau',
        countryURL: '/tokelau',
        countryURLTrimNumeric: '/tokelau',
    },
    {
        countryCodeTwoDigit: 'TO',
        countryCodeThreeDigit: 'TON',
        countryName: 'Tonga',
        countryURL: '/tonga',
        countryURLTrimNumeric: '/tonga',
    },
    {
        countryCodeTwoDigit: 'TT',
        countryCodeThreeDigit: 'TTO',
        countryName: 'Trinidad And Tobago',
        countryURL: '/trinidad-and-tobago',
        countryURLTrimNumeric: '/trinidad-and-tobago',
    },
    {
        countryURL: '/international/tunisia-13354',
        countryURLTrimNumeric: '/international/tunisia-',
        countryCodeTwoDigit: 'TN',
        countryCodeThreeDigit: 'TUN',
        countryName: 'Tunisia',
    },
    {
        countryURL: '/turkey',
        countryURLTrimNumeric: '/turkey',
        countryCodeTwoDigit: 'TR',
        countryCodeThreeDigit: 'TUR',
        countryName: 'Turkey',
    },
    {
        countryCodeTwoDigit: 'TM',
        countryCodeThreeDigit: 'TKM',
        countryName: 'Turkmenistan',
        countryURL: '/turkmenistan',
        countryURLTrimNumeric: '/turkmenistan',
    },
    {
        countryCodeTwoDigit: 'TC',
        countryCodeThreeDigit: 'TCA',
        countryName: 'Turks and Caicos Islands',
        countryURL: '/turks-caicos-islands',
        countryURLTrimNumeric: '/turks-caicos-islands',
    },
    {
        countryCodeTwoDigit: 'TV',
        countryCodeThreeDigit: 'TUV',
        countryName: 'Tuvalu',
        countryURL: '/tuvalu',
        countryURLTrimNumeric: '/tuvalu',
    },
    {
        countryCodeTwoDigit: 'UG',
        countryCodeThreeDigit: 'UGA',
        countryName: 'uganda',
        countryURL: '/uganda',
        countryURLTrimNumeric: '/uganda',
    },
    {
        countryURL: '/international/ukraine-12569',
        countryURLTrimNumeric: '/international/ukraine-',
        countryCodeTwoDigit: 'UA',
        countryCodeThreeDigit: 'UKR',
        countryName: 'Ukraine',
    },
    {
        countryCodeTwoDigit: 'AE',
        countryCodeThreeDigit: 'ARE',
        countryName: 'United Arab Emirates',
        countryURL: '/international/united-arab-emirates-12399',
        countryURLTrimNumeric: '/international/united-arab-emirates-',
    },
    {
        countryCodeTwoDigit: 'GB',
        countryCodeThreeDigit: 'GBR',
        countryName: 'United Kingdom of Great Britain and Northern Ireland',
        countryURL: '/international/greatbritain-12117',
        countryURLTrimNumeric: '/international/greatbritain-',
    },
    {
        countryCodeTwoDigit: 'US',
        countryCodeThreeDigit: 'USA',
        countryName: 'United States',
        countryURL: '/',
        countryURLTrimNumeric: '/',
    },
    {
        countryCodeTwoDigit: 'UM',
        countryCodeThreeDigit: 'UMI',
        countryName: 'United States Minor Outlying Islands',
        countryURL: '/united-state-minor',
        countryURLTrimNumeric: '/united-state-minor',
    },
    {
        countryCodeTwoDigit: 'VI',
        countryCodeThreeDigit: 'VIR',
        countryName: 'Virgin Islands, US',
        countryURL: '/virgin-islands-us',
        countryURLTrimNumeric: '/virgin-islands-us',
    },
    {
        countryURL: '/uruguay',
        countryURLTrimNumeric: '/uruguay',
        countryCodeTwoDigit: 'UY',
        countryCodeThreeDigit: 'URY',
        countryName: 'Uruguay',
    },
    {
        countryURL: '/Uzbekistan',
        countryURLTrimNumeric: '/uzbekistan',
        countryCodeTwoDigit: 'UZ',
        countryCodeThreeDigit: 'UZB',
        countryName: 'Uzbekistan',
    },
    {
        countryURL: '/vanuatu',
        countryURLTrimNumeric: '/vanuatu',
        countryCodeTwoDigit: 'VU',
        countryCodeThreeDigit: 'VUT',
        countryName: 'Vanuatu',
    },
    {
        countryURL: '/venezuela',
        countryURLTrimNumeric: '/venezuela',
        countryCodeTwoDigit: 'VE',
        countryCodeThreeDigit: 'VEN',
        countryName: 'Venezuela',
    },
    {
        countryCodeTwoDigit: 'VN',
        countryCodeThreeDigit: 'VNM',
        countryName: 'Viet Nam',
        countryURL: '/international/vietnam-12532',
        countryURLTrimNumeric: '/international/vietnam-',
    },
    {
        countryURL: '/virgin-islands-british',
        countryURLTrimNumeric: '/virgin-islands-british',
        countryCodeTwoDigit: 'VG',
        countryCodeThreeDigit: 'VGB',
        countryName: 'Virgin',
    },
    {
        countryURL: '/wallis-futuna',
        countryURLTrimNumeric: '/wallis-futuna',
        countryCodeTwoDigit: 'WF',
        countryCodeThreeDigit: 'WLF',
        countryName: 'Wallis and Futuna Islands',
    },
    {
        countryURL: '/western-sahara',
        countryURLTrimNumeric: '/western-sahara',
        countryCodeTwoDigit: 'EH',
        countryCodeThreeDigit: 'ESH',
        countryName: 'Western Sahara',
    },
    {
        countryURL: '/yemen',
        countryURLTrimNumeric: '/yemen',
        countryCodeTwoDigit: 'YE',
        countryCodeThreeDigit: 'YEM',
        countryName: 'Yemen',
    },
    {
        countryURL: '/yugoslavia',
        countryURLTrimNumeric: '/yugoslavia',
        countryCodeTwoDigit: 'YU',
        countryCodeThreeDigit: 'YUG',
        countryName: 'Yugoslavia',
    },
    {
        countryURL: '/zambia',
        countryURLTrimNumeric: '/zambia',
        countryCodeTwoDigit: 'ZM',
        countryCodeThreeDigit: 'ZMB',
        countryName: 'Zambia',
    },
    {
        countryURL: '/zimbabwe',
        countryURLTrimNumeric: '/zimbabwe',
        countryCodeTwoDigit: 'ZW',
        countryCodeThreeDigit: 'ZWE',
        countryName: 'Zimbabwe',
    },
    // NON-ISO CODES ++++++++++++++++++++++++++
    {
        countryURL: '/germany',
        countryURLTrimNumeric: '/germany',
        countryCodeTwoDigit: 'DE',
        countryCodeThreeDigit: 'GER',
        countryName: 'Germany',
    },
    {
        countryURL: '/netherlands',
        countryURLTrimNumeric: '/netherlands',
        countryCodeTwoDigit: 'NL',
        countryCodeThreeDigit: 'NET',
        countryName: 'Netherlands',
    },
    {
        countryCodeTwoDigit: 'NT',
        countryCodeThreeDigit: 'NIR',
        countryName: 'Northen Ireland',
        countryURL: '/northen-ireland',
        countryURLTrimNumeric: '/northen-ireland',
    },
    {
        countryURL: '/scotland',
        countryURLTrimNumeric: '/scotland',
        countryCodeTwoDigit: 'CS',
        countryCodeThreeDigit: 'SCO',
        countryName: 'Scotland',
    },
    {
        countryCodeTwoDigit: 'GB',
        countryCodeThreeDigit: 'UNI',
        countryName: 'United Kingdom',
        countryURL: '/united-kingdom',
        countryURLTrimNumeric: '/united-kingdom',
    },
    {
        countryURL: '/england',
        countryURLTrimNumeric: '/england',
        countryCodeTwoDigit: 'GB',
        countryCodeThreeDigit: 'ENG',
        countryName: 'England',
    },
    {
        countryURL: '/wales',
        countryURLTrimNumeric: '/wales',
        countryCodeTwoDigit: 'WA',
        countryCodeThreeDigit: 'WAL',
        countryName: 'Wales',
    },
    {
        countryURL: '/spain',
        countryURLTrimNumeric: '/spain',
        countryCodeTwoDigit: 'ES',
        countryCodeThreeDigit: 'SPA',
        countryName: 'Spain',
    },
    {
        countryURL: '/spain',
        countryURLTrimNumeric: '/spain',
        countryCodeTwoDigit: 'ES',
        countryCodeThreeDigit: 'SPN',
        countryName: 'Spain',
    },
    {
        countryURL: '/germany',
        countryURLTrimNumeric: '/germany',
        countryCodeTwoDigit: 'DE',
        countryCodeThreeDigit: 'GRY',
        countryName: 'Germany',
    },
    {
        countryCodeTwoDigit: 'TT',
        countryCodeThreeDigit: 'TRI',
        countryName: 'Trinidad',
        countryURL: '/trinidad',
        countryURLTrimNumeric: '/trinidad',
    },
    {
        countryURL: '/international/guatemala-12488',
        countryURLTrimNumeric: '/international/guatemala-',
        countryCodeTwoDigit: 'GT',
        countryCodeThreeDigit: 'GUA',
        countryName: 'Guatemala',
    },
    {
        countryCodeTwoDigit: 'HK',
        countryCodeThreeDigit: 'HOK',
        countryName: 'Hong Kong S.A.R. of China',
        countryURL: '/hong-kong',
        countryURLTrimNumeric: '/hong-kong',
    },
    {
        countryURL: '/india',
        countryURLTrimNumeric: '/india',
        countryCodeTwoDigit: 'IN',
        countryCodeThreeDigit: 'INA',
        countryName: 'India',
    },
    {
        countryURL: '/netherlands',
        countryURLTrimNumeric: '/netherlands',
        countryCodeTwoDigit: 'HO',
        countryCodeThreeDigit: 'HOL',
        countryName: 'Holland',
    },
    {
        countryCodeTwoDigit: 'HN',
        countryCodeThreeDigit: 'HON',
        countryName: 'Honduras',
        countryURL: '/honduras',
        countryURLTrimNumeric: '/honduras',
    },
    {
        countryCodeTwoDigit: 'NZ',
        countryCodeThreeDigit: 'NZE',
        countryName: 'New Zealand',
        countryURL: '/new-zealand',
        countryURLTrimNumeric: '/new-zealand',
    },
    {
        countryURL: '/portugal',
        countryURLTrimNumeric: '/portugal',
        countryCodeTwoDigit: 'PT',
        countryCodeThreeDigit: 'POR',
        countryName: 'Portugal',
    },
    {
        countryURL: '/austria',
        countryURLTrimNumeric: '/austria',
        countryCodeTwoDigit: 'AT',
        countryCodeThreeDigit: 'AUI',
        countryName: 'Austria',
    },
    {
        countryCodeTwoDigit: 'CR',
        countryCodeThreeDigit: 'COS',
        countryName: 'Costa Rica',
        countryURL: '/costa-rica-12486',
        countryURLTrimNumeric: '/costa-rica-',
    },
    {
        countryURL: '/dominican-republic',
        countryURLTrimNumeric: '/dominican-republic',
        countryCodeTwoDigit: 'DO',
        countryCodeThreeDigit: 'DOR',
        countryName: 'Dominican Republic',
    },
    {
        countryURL: '/france',
        countryURLTrimNumeric: '/france',
        countryCodeTwoDigit: 'FR',
        countryCodeThreeDigit: 'FRC',
        countryName: 'France',
    },
    {
        countryURL: '/ireland',
        countryURLTrimNumeric: '/ireland',
        countryCodeTwoDigit: 'IE',
        countryCodeThreeDigit: 'IRL',
        countryName: 'Ireland',
    },
    {
        countryURL: '/international/romania-12452',
        countryURLTrimNumeric: '/international/romania-',
        countryCodeTwoDigit: 'OM',
        countryCodeThreeDigit: 'OMA',
        countryName: 'Oman',
    },
    {
        countryURL: '/international/romania-12452',
        countryURLTrimNumeric: '/international/romania-',
        countryCodeTwoDigit: 'RO',
        countryCodeThreeDigit: 'ROM',
        countryName: 'Romania',
    },
    {
        countryCodeTwoDigit: 'SV',
        countryCodeThreeDigit: 'ELS',
        countryName: 'El Salvador',
        countryURL: '/international/el-salvador-12487',
        countryURLTrimNumeric: '/international/el-salvador-',
    },
    {
        countryURL: '/international/bahamas-11934',
        countryURLTrimNumeric: '/international/bahamas-',
        countryCodeTwoDigit: 'BS',
        countryCodeThreeDigit: 'BHS',
        countryName: 'Bahamas',
    },
];

const getCountryCodeByCategoryPath = (categoryPath) => {
    const countryDetails = countryCodeMapping.filter((country) => categoryPath.includes(country.countryURLTrimNumeric));
    if (countryDetails.length === 0) {
        return {};
    }
    return countryDetails[0];
};

const getCountryDetailsByThreeDigitCountryCode = (countryCode) => {
    const countryDetails = countryCodeMapping.filter((country) => (
        country.countryCodeThreeDigit === countryCode
    ));
    if (countryDetails.length === 0) {
        return {};
    }
    return countryDetails[0];
};

const getCountryDetailsByTwoDigitCountryCode = (countryCode) => {
    const countryDetails = countryCodeMapping.filter((country) => country.countryCodeTwoDigit === countryCode);
    if (countryDetails.length === 0) {
        return {};
    }
    return countryDetails[0];
};

export {
    getCountryCodeByCategoryPath,
    getCountryDetailsByThreeDigitCountryCode,
    getCountryDetailsByTwoDigitCountryCode,
};

export default {};
