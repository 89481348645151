/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './Cart-Actions';
import { duplicateItemActions } from './ducks/DuplicateItem';
import initialState from '../../initialState';

const cartReducer = (state = initialState.cart, action) => {
    switch (action.type) {
        case actions.loadCart().type:
            return { ...state.cart, ...action.data };
        case duplicateItemActions.loadDuplicateItem().type:
            return { ...state.cart, ...[state.orderDetails.fdOrderVO.recipients].push(action.data) };
        case actions.receiveItem().type:
            return { ...state.cart, orderItem: action.items };
        case actions.cartLoaded().type:
            return { ...state, cartLoaded: true };
        default:
            return state;
    }
};

export default cartReducer;
