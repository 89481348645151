/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const getCurrentPageView = (state) => state.tagManager.pageView;
export const getCurrentPageType = (state) => state.tagManager.pageView?.pageType;
export const getPageViewFiredState = (state) => state.tagManager.pageViewFired;
export const getQueuedEvents = (state) => state.tagManager.events;
export const getCampaign = (state) => state.tagManager.campaign;

export const getCurrentBrandId = (state) => state.ui.brandId;
export const getConfigByDomains = (state) => state.app.config.byDomain;

// Member Role
export const getUserRole = (state) => state.member?.profile?.userRole || '';

// Member
export const getMemberProfile = (state) => state.member.profile || null;

export const getMemberUserId = (state) => {
    // First look to the profile state
    if (state?.member?.profile?.hashedUserId) {
        return state?.member?.profile?.hashedUserId;
    }

    // If profile state is not found, defer to billing address object
    if (state?.cart?.orderDetails?.fdOrderVO?.billingAddress?.hashedUserId) {
        return state.cart.orderDetails.fdOrderVO.billingAddress.hashedUserId;
    }

    return null;
};
