/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    put, call, fork, take, select, takeLatest,
} from 'redux-saga/effects';

import sdkFeatureFlags from 'feature-flags-sdk';
import configClient from 'mbp-config-server-api';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';

import memberDucks from '../../../Member/ducks';

import * as configActions from './Config-Actions';
import * as configHelpers from './Config-Helpers';
import * as configSelector from './Config-Selectors';
import { getSSRConfig } from '../../App-Selectors';

const {
    common: {
        commonSelectors: {
            getProfileInfo,
            getUserType,
            getUserRole,
        },
    },
    profile: {
        profileActions: {
            setProfileEmailId,
            setProfileDetails,
            loadedProfileUserRole,
        },
    },
} = memberDucks;

function* getConfig({ config, domain }) {
    try {
        mbpLogger.logDebug({
            config,
            domain,
            function: 'getConfig',
            module: 'mbp-pwa-ui',
            message: 'Get Config | START',
        });

        const host = (typeof window !== 'undefined') ? mbpUtil.getEnv('APP_WC_HOST') : mbpUtil.getEnv('APP_CONFIG_HOST_SSR');
        const configResponse = yield call(
            configClient.getConfig,
            {
                host,
            },
            config.topic,
            config.branch || 'master',
        );

        mbpLogger.logDebug({
            host,
            configResponse,
            function: 'getConfig',
            module: 'mbp-pwa-ui',
            message: 'Config Service Response',
        });

        if (configResponse && configResponse.data && configResponse.data.propertySources.length) {
            const [configPropertySource] = configResponse.data.propertySources;

            mbpLogger.logDebug({
                configPropertySource,
                function: 'getConfig',
                module: 'mbp-pwa-ui',
                message: 'Config data found.',
            });

            return configHelpers.parseConfig(configPropertySource.source, domain);
        }
    } catch (ex) {
        mbpLogger.logError({
            config,
            domain,
            function: 'getConfig',
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to get config data from service.',
        });
    }

    return {};
}

function* onLoadConfig({ topic, branch, brand }) {
    let config = {};

    try {
        mbpLogger.logDebug({
            topic,
            branch,
            brand,
            function: 'onLoadConfig',
            module: 'mbp-pwa-ui',
            message: 'Load Config | START',
        });

        config = yield call(getConfig, {
            config: {
                topic,
                branch,
            },
            domain: brand.domain,
        });

        yield put(configActions.configLoaded(brand.domain, topic, config));
    } catch (ex) {
        put(configActions.configLoadFailed(ex));
        mbpLogger.logError({
            topic,
            branch,
            brand,
            function: 'onLoadConfig',
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to load config data.',
        });
    }

    return config;
}

function* getFlags(brand) {
    let flags = {};
    const profile = yield select(getProfileInfo);
    // use shopper-manager id from incoming req header
    const { deviceType, shopperManagerId } = yield select(getSSRConfig);
    const userKey = shopperManagerId || 'anonymous';
    const userType = yield select(getUserType);
    const userStatus = yield select(getUserRole);
    const email = (profile.email) ? profile.email : '';
    const browser = yield call(configHelpers.getBrowser);
    const version = yield call(configHelpers.getAppVersion);
    const isSSR = (typeof window === 'undefined');

    if (userKey || isSSR) {
        const payload = {
            userKey,
            userType: userType || 'G',
            userStatus,
            email,
            firstName: profile.firstName,
            lastName: profile.lastName,
            browser,
            brand,
            version,
            device: deviceType,
        };

        const featureFlagsResponse = yield call(sdkFeatureFlags.getFeatureFlags, {}, 'mbp-ui', payload);
        flags = featureFlagsResponse.data;
    }
    return flags;
}

function* onLoadFlags({
    brand,
}) {
    const topic = 'app-feature-flags';
    let flags = {};
    try {
        mbpLogger.logDebug({
            brand,
            function: 'onLoadFlags',
            module: 'mbp-pwa-ui',
            message: 'Load Flag | START',
        });
        if (brand && brand.domain) {
            const { domain } = brand;
            flags = yield call(getFlags, domain);
            yield put(configActions.configLoaded(domain, topic, flags));
        }
    } catch (ex) {
        put(configActions.configLoadFailed(ex));
        mbpLogger.logError({
            topic,
            brand,
            function: 'onLoadFlags',
            module: 'mbp-pwa-ui',
            jsError: ex,
            message: 'Failed to load flag data.',
        });
    }
    return flags;
}

function* watchLoadConfigs() {
    let action = yield take(configActions.loadConfig().type);

    while (action !== END) {
        yield fork(onLoadConfig, action.payload);
        action = yield take(configActions.loadConfig().type);
    }
}

function* watchLoadFlags() {
    let action = yield take(configActions.loadFlags().type);
    while (action !== END) {
        const brand = yield select(configSelector.getBrand);
        yield fork(onLoadFlags, { brand });
        action = yield take(configActions.loadFlags().type);
    }
}

function* workerHandleUpdateFeatureFlags() {
    const brand = yield select(configSelector.getBrand);
    yield fork(onLoadFlags, { brand });
}

function* watchActionsToUpdateFeatureFlags() {
    yield takeLatest([
        setProfileEmailId().type,
        setProfileDetails().type,
        loadedProfileUserRole().type,
    ], workerHandleUpdateFeatureFlags);
}

const watchers = [
    fork(watchLoadConfigs),
    fork(watchLoadFlags),
    fork(watchActionsToUpdateFeatureFlags),
];

export {
    getFlags,
    onLoadFlags,
    getConfig,
    onLoadConfig,
    watchers,
};
