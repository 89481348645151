/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { object, func } from 'prop-types';
import mbpLogger from 'mbp-logger';
import QueryString from 'qs';

import { GRAPHQL_ENV } from '../../../gql';
import * as app from '../../../../state/ducks/App';
import { setPassportData } from '../../../../state/ducks/Checkout/ducks/Payment/ducks/Passport/Passport-Action';

const brandSelectors = app.ducks.brand.selectors;

const GraphqlPassportContainer = ({ brand, setPassportCodeAction, history }) => {
    const Passport_PAGE_QUERY = gql`
        query PassportQuery {
            findContent(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", contentType:"passport") {
                content
        }
    }
`;
    const { loading, error, data } = useQuery(Passport_PAGE_QUERY);
    // getting utm params from url
    const urlParams = history?.location?.search ? QueryString.parse(history.location.search.replace('?', '')) : {};
    if (loading) {
        return '';
    }

    if (error) {
        mbpLogger.logError({
            query: Passport_PAGE_QUERY,
            component: 'GraphqlPassportContainer.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return false;
    }

    if (!data) {
        mbpLogger.logWarning({
            query: Passport_PAGE_QUERY,
            component: 'GraphqlPassportContainer.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
    } else if (!data?.findContent?.content) {
        mbpLogger.logWarning({
            query: Passport_PAGE_QUERY,
            component: 'GraphqlPassportContainer.js',
            message: 'No content returned for query',
            env: GRAPHQL_ENV,
        });
    } else {
        const passportData = data?.findContent?.content?.entries || [];
        // checking utm params and saving them in redux state
        if (urlParams?.utm_campaign && urlParams?.utm_medium) {
            let passportSku;
            if (passportData.length > 0) {
                const pricing_params = passportData[0]?.passport_pricing_conditional_logic?.utm_params || [];
                pricing_params.forEach((pricing_param) => {
                    const enable = pricing_param?.enable;
                    const campaign = pricing_param?.utm_campaign;
                    const medium = pricing_param?.utm_medium;
                    if (enable && urlParams?.utm_campaign === campaign && urlParams?.utm_medium === medium) {
                        passportSku = pricing_param?.passport_sku;
                    }
                });
                passportData[0].passport_dynamic_price = {
                    campaign: urlParams.utm_campaign,
                    medium: urlParams.utm_medium,
                    passportSku,
                };
            }
        }
        setPassportCodeAction(passportData[0]);
    }
    return true;
};

GraphqlPassportContainer.propTypes = {
    setPassportCodeAction: func.isRequired,
    brand: object.isRequired,
    history: object.isRequired,
};

const mapStateToProps = (state) => ({
    brand: brandSelectors.getBrand(state),
});
const mapDispatchToProps = (dispatch) => ({
    setPassportCodeAction: bindActionCreators(setPassportData, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GraphqlPassportContainer));
