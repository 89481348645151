/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const getGoogleZip = (addressObj) => addressObj.addressComponents.filter((component) => component.types[0] === 'postal_code')[0].long_name;
const getSCIZip = (addressObj) => addressObj?.addressComponents?.Location?.ZipCode;
const getAddressBookZip = (addressObj) => addressObj?.addressComponents?.PostalCode.split('-')[0];

const googleAddressFieldCapture = (addressComponents, typeOfAddressComponent, longOrShortName) => {
    // this function gets specific fields from a saved google places address.
    // If there is not a particular field in the saved address the function returns an empty string.
    const requestedAddressComponent = addressComponents.filter((addressComponent) => addressComponent.types[0] === typeOfAddressComponent);
    if (requestedAddressComponent.length > 0) {
        return requestedAddressComponent[0][longOrShortName];
    }
    return '';
};

const googleAddressConversion = (addressComponents) => {
    // see below for example of addressComponents from google
    const streetNumber = googleAddressFieldCapture(addressComponents, 'street_number', 'long_name');
    const streetName = googleAddressFieldCapture(addressComponents, 'route', 'short_name');
    let city = googleAddressFieldCapture(addressComponents, 'sublocality_level_1', 'long_name');
    if (city === '') { // sometimes the city is represented by the locality address component as opposed to sublocality_level_1
        city = googleAddressFieldCapture(addressComponents, 'locality', 'long_name');
    }
    const state = googleAddressFieldCapture(addressComponents, 'administrative_area_level_1', 'short_name');
    const country = googleAddressFieldCapture(addressComponents, 'country', 'short_name');
    const zipCode = googleAddressFieldCapture(addressComponents, 'postal_code', 'long_name');

    const addressObjForSubmission = {
        // the '.' value is temporary until an API can be updated
        firstName: '.',
        lastName: '.',
        address1: `${streetNumber} ${streetName}`,
        address2: '',
        city,
        state,
        zipCode,
        nameOfLocation: '',
        organizationName: '',
        country,
        // because the address comes from google places it is sent as verified and dpvIndicator = Y
        // (dpvIndicator is a QAS variable)
        dpvIndicator: 'Y',
        isAddressVerified: 'Y',
    };
    return addressObjForSubmission;
};

const SCIAddressConversion = (addressComponents) => {
    const addressObjForSubmission = {
        firstName: addressComponents.DecedentFirstName,
        lastName: addressComponents.DecedentLastName,
        address1: addressComponents.Location.Address1,
        address2: addressComponents.Location.Address2 ?? '',
        city: addressComponents.Location.City,
        state: addressComponents.Location.StateorProv,
        nameOfLocation: addressComponents.Location.Name,
        zipCode: addressComponents.Location.ZipCode,
        organizationName: addressComponents.Location.Name,
        phone: addressComponents.Location.PhoneNumber?.split('-').join(''),
        country: 'US',
        // because the address comes from SCI it is sent as verified and dpvIndicator = Y
        // (dpvIndicator is a QAS variable)
        dpvIndicator: 'Y',
        isAddressVerified: 'Y',
    };
    return addressObjForSubmission;
};

const addressBookAddressConversion = (addressComponents) => {
    const addressObjForSubmission = {
        firstName: addressComponents?.FirstName ?? '.',
        lastName: addressComponents?.LastName ?? '.',
        address1: addressComponents.AddressLineOne,
        address2: addressComponents.AddressLineTwo,
        city: addressComponents.City,
        state: addressComponents.StateProvince,
        zipCode: addressComponents.PostalCode.split('-')[0],
        nameOfLocation: '',
        organizationName: addressComponents?.EmployerName ?? '',
        country: addressComponents.Country,
        dpvIndicator: 'Y',
        isAddressVerified: 'Y',
    };
    if (addressComponents?.PhoneNumber) {
        addressObjForSubmission.phone = addressComponents.PhoneNumber;
    }
    return addressObjForSubmission;
};

export const getZipByAddressSource = (addressObj) => {
    if (addressObj.addressSource === 'google') {
        return getGoogleZip(addressObj);
    }
    if (addressObj.addressSource === 'SCI') {
        return getSCIZip(addressObj);
    }
    if (addressObj.addressSource === 'pdpAddressBook') {
        return getAddressBookZip(addressObj);
    }
    return '';
};

export const convertAddressByAddressSource = (addressObj) => {
    if (addressObj.addressSource === 'google') {
        return googleAddressConversion(addressObj.addressComponents);
    }
    if (addressObj.addressSource === 'SCI') {
        return SCIAddressConversion(addressObj.addressComponents);
    }
    if (addressObj.addressSource === 'pdpAddressBook') {
        return addressBookAddressConversion(addressObj.addressComponents);
    }
    return {};
};

// Linter requires a default export.

export default {};

/* // example of a google places address.

[
   {
      "long_name":"110",
      "short_name":"110",
      "types":[
         "street_number"
      ]
   },
   {
      "long_name":"Park Place",
      "short_name":"Park Pl",
      "types":[
         "route"
      ]
   },
   {
      "long_name":"Park Slope",
      "short_name":"Park Slope",
      "types":[
         "neighborhood",
         "political"
      ]
   },
   {
      "long_name":"Brooklyn",
      "short_name":"Brooklyn",
      "types":[
         "sublocality_level_1",
         "sublocality",
         "political"
      ]
   },
   {
      "long_name":"Kings County",
      "short_name":"Kings County",
      "types":[
         "administrative_area_level_2",
         "political"
      ]
   },
   {
      "long_name":"New York",
      "short_name":"NY",
      "types":[
         "administrative_area_level_1",
         "political"
      ]
   },
   {
      "long_name":"United States",
      "short_name":"US",
      "types":[
         "country",
         "political"
      ]
   },
   {
      "long_name":"11217",
      "short_name":"11217",
      "types":[
         "postal_code"
      ]
   },
   {
      "long_name":"2804",
      "short_name":"2804",
      "types":[
         "postal_code_suffix"
      ]
   }
]

*/

/*
Example of a SCI address

{
  "DecedentPrefix": "",
  "DecedentSuffix": "",
  "DecedentMaidenName": "",
  "DecedentNickName": "",
  "DecedentFullName": "James M. Myers",
  "Location": {
    "Name": "Hillcrest Funeral Home-West",
    "Address1": "5054 Doniphan Dr.",
    "Address2": null,
    "City": "El Paso",
    "StateorProv": "TX",
    "ZipCode": "79932",
    "PhoneNumber": "(915) 587-0202",
    "ClientId": "5093"
  },
  "FuneralServices": [],
  "DecedentFirstName": "James",
  "DecedentMiddleName": "M.",
  "DecedentLastName": "Myers",
  "DispositionType": "unknown",
  "DispositionName": null,
  "PrimaryPhotoUrl": "//d2mjvz2lqjkhe7.cloudfront.net/as/assets-mem-com/cmi/9/7/8/4/8984879/20200106_122842066_0.jpg?a.sharpen=2"
}
*/
