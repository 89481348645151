/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { decodeHTML } from 'entities';

import mbpLogger from 'mbp-logger';

import * as dataLayerProductHelpers from '../../product/dataLayerProductHelpers';

// Build Product arrays
export const generateDataLayerProductArrays = ({
    products,
    impressions = false,
    props = {},
}) => {
    const MAX_PRODUCT_COUNT = 20;
    const tempDataLayer = {};

    // Products
    const productIds = [];
    const productNames = [];
    const productPrices = [];
    const productSkus = [];
    const productBaseCodes = [];
    const productBrands = [];
    const productCategoryIds = [];
    const productCategoryLegacyIds = [];
    const productCategoryNames = [];
    const productCategoryPaths = [];
    const productVariants = [];
    const productListNames = [];
    const productListPositions = [];
    const productQuantities = [];

    // Impressions
    const productImpressionBrands = [];
    const productImpressionCategoryIds = [];
    const productImpressionCategoryLegacyIds = [];
    const productImpressionCategoryNames = [];
    const productImpressionCategoryPaths = [];
    const productImpressionIds = [];
    const productImpressionBaseCodes = [];
    const productImpressionListNames = [];
    const productImpressionListPositions = [];
    const productImpressionNames = [];
    const productImpressionSkus = [];
    const productImpressionPrices = [];
    const productImpressionVariants = [];

    try {
        const { category } = props;
        Object.keys(products).forEach((key, idx) => {
            try {
                // Limit Product list to MAX_PRODUCT_COUNT
                if (idx < MAX_PRODUCT_COUNT) {
                    const product = products[key];
                    const skus = (product.sKUs) ? product.sKUs.map((sku) => sku.partNumber) : [product.partNumber];

                    // Valid product
                    if (dataLayerProductHelpers.isValidProduct(product)) {
                        // Allow for seeding product list position
                        const productCount = props.productListPositionSeed ? ((idx + props.productListPositionSeed) + 1) : (idx + 1);

                        // Impressions (Category / Search / UpSells / AddOns / Components)
                        if (impressions) {
                            productImpressionIds.push(dataLayerProductHelpers.getBaseCode(product.partNumber || ''));
                            productImpressionNames.push(decodeHTML(product.name) || '');
                            productImpressionBaseCodes.push(product.baseCode || dataLayerProductHelpers.getBaseCode(product.partNumber) || '');
                            productImpressionPrices.push(dataLayerProductHelpers.getPrice(product) || '');
                            productImpressionSkus.push(...skus || '');
                            productImpressionVariants.push(...skus || '');
                            productImpressionBrands.push(product.brandId || dataLayerProductHelpers.getBrandId(product.partNumber) || '');
                            productImpressionListPositions.push(productCount || '');

                            if (category) { // Category-Based
                                productImpressionCategoryIds.push(category.id || '');
                                productImpressionCategoryLegacyIds.push(category.legacyId || '');
                                productImpressionCategoryNames.push(category.shortDescription ? decodeHTML(category.shortDescription) : '');
                                productImpressionCategoryPaths.push(category.path || category.categoryPath || '');
                            } else { // Product-Based
                                productImpressionCategoryIds.push(product.categoryId || '');
                                productImpressionCategoryLegacyIds.push(product.categoryLegacyId || '');
                                productImpressionCategoryNames.push((product.categoryName) ? decodeHTML(product.categoryName) : '');
                                productImpressionCategoryPaths.push(product.categoryPath || '');
                            }

                            if (props.searchTerm) {
                                productImpressionListNames.push(`Search: ${props.searchTerm}` || '');
                            }

                            if (product.categoryName) {
                                productImpressionListNames.push(product.categoryName || '');
                            }
                        } else {
                            productIds.push(dataLayerProductHelpers.getBaseCode(product.partNumber) || '');
                            productNames.push(decodeHTML(product.name) || '');
                            productPrices.push(dataLayerProductHelpers.getPrice(product) || '');
                            productSkus.push(...skus || ''); // TODO: DATALAYER - Find skus
                            productVariants.push(...skus || ''); // TODO: DATALAYER - Find skus
                            productBaseCodes.push(product.baseCode || dataLayerProductHelpers.getBaseCode(product.partNumber) || '');
                            productBrands.push(product.brandId || dataLayerProductHelpers.getBrandId(product.partNumber) || '');
                            productListPositions.push(productCount || '');
                            productQuantities.push(product.quantity || 1); // by default added 1

                            if (category) { // Category-Based
                                productCategoryIds.push(category.id || '');
                                productCategoryLegacyIds.push(category.legacyId || '');
                                productCategoryNames.push(category.shortDescription ? decodeHTML(category.shortDescription) : '');
                                productCategoryPaths.push(category.path || category.categoryPath || '');
                            } else { // Product-Based
                                productCategoryIds.push(product.categoryId || '');
                                productCategoryLegacyIds.push(product.categoryLegacyId || '');
                                productCategoryNames.push((product.categoryName) ? decodeHTML(product.categoryName) : '');
                                productCategoryPaths.push(product.categoryPath || '');
                            }

                            if (product.categoryName || category) {
                                productListNames.push(product.categoryName || decodeHTML(category?.shortDescription || '')  || '');
                            }

                            if (props.searchTerm) {
                                productListNames.push(`Search: ${props.searchTerm}` || '');
                            }
                        }
                    }
                }
            } catch (ex) {
                mbpLogger.logError({
                    products,
                    impressions,
                    props,
                    function: 'generateDataLayerProductArrays',
                    module: 'mbp-page',
                    jsError: ex,
                    message: 'Failed to add product to product arrays.',
                });
            }
        });

        // Impressions (Displayed to the customer)
        if (productImpressionIds.length) {
            tempDataLayer.product_imp_ids = productImpressionIds || [''];
            tempDataLayer.product_imp_base_codes = productImpressionBaseCodes || [''];
            tempDataLayer.product_imp_brands = productImpressionBrands || [''];
            tempDataLayer.product_imp_category_ids = productImpressionCategoryIds || [''];
            tempDataLayer.product_imp_category_legacy_ids = productImpressionCategoryLegacyIds || [''];
            tempDataLayer.product_imp_category_names = productImpressionCategoryNames || [''];
            tempDataLayer.product_imp_category_paths = productImpressionCategoryPaths || [''];
            tempDataLayer.product_imp_categories = productImpressionCategoryPaths || [''];
            tempDataLayer.product_imp_list_names = productImpressionListNames || [''];
            tempDataLayer.product_imp_list_positions = productImpressionListPositions || [''];
            tempDataLayer.product_imp_names = productImpressionNames || [''];
            tempDataLayer.product_imp_prices = productImpressionPrices || [''];
            tempDataLayer.product_imp_skus = productImpressionSkus || [''];
            tempDataLayer.product_imp_sku_ids = productImpressionSkus || [''];
            tempDataLayer.product_imp_variants = productImpressionVariants || [''];
        } else {
            tempDataLayer.product_ids = productIds || [''];
            tempDataLayer.product_names = productNames || [''];
            tempDataLayer.product_prices = productPrices || [''];
            tempDataLayer.product_sku_ids = productSkus || [''];
            tempDataLayer.product_skus = productSkus || [''];
            tempDataLayer.product_variants = productVariants || [''];
            tempDataLayer.product_base_codes = productBaseCodes || [''];
            tempDataLayer.product_brands = productBrands || [''];
            tempDataLayer.product_category_ids = productCategoryIds || [''];
            tempDataLayer.product_category_legacy_ids = productCategoryLegacyIds || [''];
            tempDataLayer.product_category_names = productCategoryNames || [''];
            tempDataLayer.product_category_paths = productCategoryPaths || [''];
            tempDataLayer.product_categories = productCategoryPaths || [''];
            tempDataLayer.product_quantities = productQuantities || [''];
            tempDataLayer.product_list_names = productListNames || [''];
            tempDataLayer.product_list_positions = productListPositions || [''];
        }
    } catch (ex) {
        mbpLogger.logError({
            products,
            impressions,
            props,
            function: 'generateDataLayerProductArrays',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to generate product arrays.',
        });
    }

    return tempDataLayer;
};

export const convertPassportDataToProduct = (passport) => {
    const passportItem = passport?.item_id || null;
    if (passportItem) {
        const item = {};
        item[passportItem] = {
            brandId: passport?.brand_id || '',
            baseCode: passport?.item_id || '',
            partNumber: passport?.item_id,
            name: 'Passport',
            price: passport?.price,
            sKUs: [{ partNumber: passport?.item_id || '' }],
        };
        return item;
    }
    return {};
};

export const convertToProduct = (list) => {
    const products = {};
    if (list) {
        list.forEach((item) => {
            products[item.id] = item;
        });
    }
    return products;
};

export default {
    generateDataLayerProductArrays,
    convertPassportDataToProduct,
};
