/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    fork, takeEvery, call, put, select, take,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../apis/checkout-apis/orderClient';
import shippingChargeAPI from '../../../../../apis/checkout-apis/shippingCharge';
import paymentPromotionAPI from '../../../../../apis/checkout-apis/paymentPromotion';

import memberDucks from '../../../Member/ducks';
import {
    getFlagEnableCheckoutMonitoring,
    getFlags,
    getOrderId,
    getIfGiftCardApplied,
} from '../Common/Common-Selectors';
import { circuitBreaker, getCurrencyName } from '../../../Member/ducks/Auth/helper/helper';
import {
    loadCart, getCart, loadItems, receiveItem, cartLoaded, recalculateGiftCardTotal, callHandleCartFailure,
} from './Cart-Actions';
import { errorActions, errorOperations } from '../Common/ducks/Error';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';
import { loadCartCount, loadOrderId } from '../Order/Order-Actions';
import { determineLanguagePrefixPath } from '../../../../../app/helpers/determineInternationalizationPath';
import { calculateTax } from '../../../../../apis/checkout-apis/calculateTax';
import { asyncForEach } from '../../../../../app/helpers/checkout/utils';
import {
    logGuestSession, logHydraidUserSession, logUserLoggedInSuccess, clearAuthDependentState,
} from '../../../Member/ducks/Auth/Auth-Actions';
import cartServices from '../../../../../apis/cart-service-apis/cartServices';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

// API Client Handlers

const retrieveCartApi = (object) => orderClient.getOrderDetails(object.wcEnv, object.jwtToken, object.orderId);
const fetchItem = (orderId, jwtToken) => orderClient.getOrderDetails(
    {},
    jwtToken,
    orderId,
).then((response) => response.data.orderDetails.fdOrderVO).catch((err) => console.log(err));

const reCalculateGiftCardTotalApi = ({
    JWT_TOKEN,
    orderId,
}) => orderClient.reCalculateGiftCardAmount({}, JWT_TOKEN, orderId);

const shippingAPI = (jwt, payload) => shippingChargeAPI(jwt, payload);
const promotionAPI = (jwt, payload) => paymentPromotionAPI(jwt, payload);

// helpers
const checkRecipientsDetails = (recipients) => {
    if (recipients.length) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < recipients.length; i++) {
            const recipientAddress = recipients[i].recipientAddress;
            if (!recipientAddress.lastName) return false;
            if (!recipientAddress.phoneNo) return false;
            if (!recipientAddress.state) return false;
            if (!recipientAddress.country) return false;
            if (!recipientAddress.firstName) return false;
            if (!recipientAddress.stateCode) return false;
            if (!recipientAddress.addressLine1) return false;
            if (!recipientAddress.city) return false;
            if (!recipientAddress.zipCode) return false;
            if (!recipientAddress.businessTitle) return false;
        }
    }
    return true;
};

const getProductType = (recipients, lineRef) => {
    let productType = '';
    recipients.forEach((recipient) => {
        const orderItem = recipient.orderItems.find((item) => item.orderItemsId === lineRef);
        if (orderItem) {
            productType = orderItem.catalogEntryVO?.productType;
        }
    });
    return productType;
};

const roundNearest99 = (value) => {
    const valueFloat = parseFloat(value);
    // add 0.99 if the number doesn't have the decimal part i.e. 1, 2, 3, 4...
    if (valueFloat > 0 && valueFloat % 1 === 0) {
        return parseFloat((valueFloat + 0.99).toFixed(2));
    }
    // if the number has decimal part then roud it up to the .99 i.e. 1.26 => 1.99
    if (valueFloat >= 0.01) {
        return parseFloat((Math.ceil(valueFloat) - 0.01).toFixed(2));
    }
    // negative values logic
    if (valueFloat < 0) {
        return parseFloat((Math.ceil(valueFloat) + 0.99).toFixed(2));
    }
    return parseFloat(valueFloat.toFixed(2));
};

const calculateBaseCharge = (promotionId, shippingData) => {
    let baseCharge = '-0.00';
    // eslint-disable-next-line no-unused-expressions
    if (Array.isArray(shippingData) && shippingData.length) {
        shippingData.forEach((item) => {
            if (item.lineRef === promotionId) {
                if (item.shippingCharge?.baseCharge?.amount) {
                    baseCharge = item.shippingCharge.baseCharge.amount;
                }
            }
        });
        // rounding of base charge
        baseCharge = roundNearest99(baseCharge);
        // to String
        baseCharge = `-${baseCharge}`;
    }
    return baseCharge;
};
/**
 * Handle Cart Failure
 * Cart Failure occurs when stale OrderId is used.
 * Try to rehydraid session from localstorage or redirect to homepage
*/
export function* handleCartFailure(action) {
    const logOrderId = yield select(getOrderId);
    const { error } = action.data;
    const errorKey = error?.response?.data?.detailedError?.errorKey || '';

    if (errorKey === 'ERROR_NOT_AUTHORIZED_TO_ACCESS_CART') {
        mbpLogger.logError({
            function: 'handleCartFailure',
            module: 'Cart-Operations.js',
            message: 'Error - ERROR_NOT_AUTHORIZED_TO_ACCESS_CART',
            orderId: logOrderId,
        });
    } else if (errorKey === 'ERROR_INVALID_CART_STATE') {
        mbpLogger.logError({
            function: 'handleCartFailure',
            module: 'Cart-Operations.js',
            message: 'Error - ERROR_NOT_AUTHORIZED_TO_ACCESS_CART',
            orderId: logOrderId,
        });

        yield put(loadOrderId(''));
        yield put(loadCartCount(0));

        if (window !== 'undefined') {
            window.location.href = determineLanguagePrefixPath('/');
        }
    }
}

function* recalculateGiftCardTotalWorker() {
    const logOrderId = yield select(getOrderId);
    try {
        const isGiftCardApplied = yield select(getIfGiftCardApplied);
        if (isGiftCardApplied) {
            yield put(errorActions.clearErrors({
                errType: 'giftCard',
                field: 'error',
            }));

            const orderId = yield select(getOrderId);
            if (orderId) {
                const JWT_TOKEN = yield call(checkJWT);
                yield call(reCalculateGiftCardTotalApi, { JWT_TOKEN, orderId });
            } else {
                mbpLogger.logError({
                    function: 'recalculateGiftCardTotalWorker',
                    module: 'Cart-Operations.js',
                    message: 'Recalculate Gift Card Total - No OrderId',
                    orderId: logOrderId,
                });
            }
        }
    } catch (ex) {
        yield call(errorOperations.getError, ex, 'giftCard');
        mbpLogger.logError({
            function: 'recalculateGiftCardTotalWorker',
            module: 'mbp-checkout',
            message: 'Recalculate Gift Card Total failed',
            orderId: logOrderId,
            error: ex,
        });
    }
}

function* retrieveCart() {
    const orderId = yield select(getOrderId);
    const flagState = yield select(getFlags);
    let fetchTax = false;
    if (typeof window !== 'undefined') {
        const pathName = window.location.pathname;
        const checkoutPaths = ['review-order', 'order-confirmation', 'payment']; // Added check for payment endpoint for mobile tax
        fetchTax = (checkoutPaths.some((path) => pathName.includes(path)));
    }
    try {
        // log updated as ERROR to track on prod env
        if (getFlagEnableCheckoutMonitoring(flagState) === true) {
            mbpLogger.logError({
                function: 'retrieveCart',
                module: 'Cart-Operations',
                message: 'monitor: Call RetrieveCart API - Start',
                orderId,
            });
        }

        yield put(errorActions.clearErrors({
            errType: 'cart',
            field: 'error',
        }));

        yield put(errorActions.clearErrors({
            errType: 'apiError',
            field: 'error',
        }));

        if (orderId) {
            const jwtToken = yield call(checkJWT);
            const object = {
                wcEnv: {},
                jwtToken,
                orderId,
            };

            const originalCartData = yield call(retrieveCartApi, object);
            // log updated as ERROR to track on prod env
            if (getFlagEnableCheckoutMonitoring(flagState) === true) {
                mbpLogger.logError({
                    function: 'retrieveCart',
                    module: 'Cart-Operations',
                    message: 'RetrieveCart API - Response',
                    orderId,
                });
            }
            const cartCount = originalCartData?.data?.orderDetails?.fdOrderVO?.orderSize;
            yield put(loadCartCount(Number(cartCount)));
            // common to all the api calls
            const currencyName = getCurrencyName();
            /**
             * START SHIPPING CALL
             */
            let totalShippingCloned = parseFloat(originalCartData?.data?.orderDetails?.fdOrderVO?.totalShipping);
            const cartDataClonedShipping = originalCartData?.data ? JSON.parse(JSON.stringify(originalCartData?.data)) : {};
            let shippingResponseData = [];
            if (flagState['is-canada-shipping-homegrown-on'] && currencyName !== 'USD' && (cartDataClonedShipping.orderDetails?.fdOrderVO?.totalShipping > 0 || cartDataClonedShipping.orderDetails?.fdOrderVO?.totalShippingAdjustments < 0)) {
                const recipients = cartDataClonedShipping.orderDetails?.fdOrderVO?.recipients || [];
                if (recipients.length) {
                    const hasRecipientDetails = checkRecipientsDetails(recipients);
                    if (hasRecipientDetails) {
                        // remove shipCharge, shipTaxAmount, taxAmount from original cart response
                        recipients.forEach((recipient) => {
                            recipient.orderItems.forEach((orderItem) => {
                            // eslint-disable-next-line no-param-reassign
                                delete orderItem.shipCharge;
                                // eslint-disable-next-line no-param-reassign
                                delete orderItem.shipTaxAmout;
                                // eslint-disable-next-line no-param-reassign
                                delete orderItem.taxAmout;
                            });
                        });
                        const payload = {
                            currency: currencyName,
                            recipients,
                        };
                        try {
                            const shippingResponse = yield call(shippingAPI, jwtToken, payload);
                            if (shippingResponse?.status === 200) {
                                let totalShipping = 0;
                                let shippingCharge = 0;
                                let serviceCharge = 0;
                                const result = shippingResponse?.data?.result || [];
                                shippingResponseData = result;
                                if (result.length) {
                                    result.forEach((item) => {
                                        const productType = getProductType(recipients, item.lineRef);
                                        let individualShipCharge = 0;
                                        if (item.shippingCharge?.baseCharge?.amount) {
                                            individualShipCharge += roundNearest99(item.shippingCharge.baseCharge.amount);
                                        }
                                        if (item.shippingCharge?.upCharge?.amount) {
                                            individualShipCharge += roundNearest99(item.shippingCharge.upCharge.amount);
                                        }
                                        if (item.shippingCharge?.dtwCharge) {
                                            individualShipCharge += roundNearest99(item.shippingCharge.dtwCharge);
                                        }
                                        if (item.shippingCharge?.surcharge && item.shippingCharge?.surcharge.surcharge?.length) {
                                            const surchargeArray = item.shippingCharge?.surcharge.surcharge;
                                            surchargeArray.forEach((surcharge) => {
                                                individualShipCharge += roundNearest99(surcharge.amount);
                                            });
                                        }
                                        // override in originalCartData
                                        // eslint-disable-next-line no-unused-expressions
                                        originalCartData.data.orderDetails?.fdOrderVO?.recipients.forEach((recipient) => {
                                            recipient.orderItems.forEach((orderItem) => {
                                                if (orderItem.orderItemsId === item.lineRef) {
                                                    // eslint-disable-next-line no-param-reassign
                                                    orderItem.shipCharge = individualShipCharge;
                                                }
                                            });
                                        });
                                        if (productType === 'FPT') {
                                            serviceCharge += individualShipCharge;
                                        } else {
                                            shippingCharge += individualShipCharge;
                                        }
                                    });
                                    // rounding of shippingCharge and serviceChage
                                    // shippingCharge = shippingCharge >= 0.01 ? Math.ceil(shippingCharge) - 0.01 : shippingCharge;
                                    // serviceCharge = serviceCharge >= 0.01 ? Math.ceil(serviceCharge) - 0.01 : serviceCharge;
                                    totalShipping = shippingCharge + serviceCharge;
                                    // set totalShippingCloned to be use in orderTotalBalance calculation for BrainTree
                                    totalShippingCloned = totalShipping;
                                    // override totalShipping in originalCartData
                                    originalCartData.data.orderDetails.fdOrderVO.totalShipping = totalShipping;
                                    originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.shippingCharge = shippingCharge;
                                    originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.serviceCharge = serviceCharge;
                                } else {
                                    originalCartData.data.orderDetails.fdOrderVO.totalShipping = 0;
                                    originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.shippingCharge = 0;
                                    originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.serviceCharge = 0;
                                }
                            } else {
                                originalCartData.data.orderDetails.fdOrderVO.totalShipping = 0;
                                originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.shippingCharge = 0;
                                originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.serviceCharge = 0;
                            }
                        } catch (error) {
                            // set total shipping to 0 if there's an error
                            originalCartData.data.orderDetails.fdOrderVO.totalShipping = 0;
                            originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.shippingCharge = 0;
                            originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.serviceCharge = 0;
                            yield call(errorOperations.getError, error, 'cart');
                        }
                    } else {
                        originalCartData.data.orderDetails.fdOrderVO.totalShipping = 0;
                        originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.shippingCharge = 0;
                        originalCartData.data.orderDetails.fdOrderVO.totalShippingMap.serviceCharge = 0;
                    }
                }
            }
            /**
             * END SHIPPING CALL
             */

            const cartDataCloned = originalCartData?.data ? JSON.parse(JSON.stringify(originalCartData?.data)) : {};
            const headers = {
                authorization: `Bearer ${jwtToken}`,
            };
            /**
             * START PROMOTION CALL
             */
            let totalPromotionCloned = parseFloat(originalCartData?.data?.orderDetails?.fdOrderVO?.totalAdjustments);
            let promotions = [];
            let totalShippingAdjustments = 0; // specific promo code: SHIPTESTPROMO
            if (flagState['is-canada-promotion-homegrown-on'] && currencyName !== 'USD' && cartDataCloned?.orderDetails?.fdOrderVO?.appliedPromos?.[0]?.appliedAmount < 0) {
                const { orderDetails } = cartDataCloned;
                const payload = {
                    orderDetails,
                };
                try {
                    const promotionResponse = yield call(promotionAPI, jwtToken, payload);
                    if (promotionResponse?.status === 200) {
                        promotions = promotionResponse.data?.response?.promotions?.[0]?.actions || [];
                        totalPromotionCloned = 0;
                        promotions.forEach((promotion) => {
                            if (promotion.promotionType === 'SHIPPING') {
                                const baseChargeAmount = calculateBaseCharge(promotion.targetItem, shippingResponseData);
                                // eslint-disable-next-line no-param-reassign
                                promotion.amount = baseChargeAmount;
                                totalShippingAdjustments += parseFloat(promotion.amount);
                            }
                            totalPromotionCloned += parseFloat(promotion.amount);
                        });

                        if (totalShippingAdjustments < 0) {
                            originalCartData.data.orderDetails.fdOrderVO.totalShippingAdjustments = totalShippingAdjustments.toFixed(2);
                            // newTotalShipping = serviceCharge + shippingCharge + (-totalShippingAdjustments);
                            // totalShippingCloned = serviceCharge + shippingCharge
                            const newTotalShipping = totalShippingCloned + totalShippingAdjustments;
                            originalCartData.data.orderDetails.fdOrderVO.totalShipping = newTotalShipping;
                        }
                        if (totalPromotionCloned < 0) {
                            originalCartData.data.orderDetails.fdOrderVO.appliedPromos[0].appliedAmount = totalPromotionCloned.toFixed(2);
                        }
                    } else {
                        originalCartData.data.orderDetails.fdOrderVO.appliedPromos[0].appliedAmount = 0;
                        originalCartData.data.orderDetails.fdOrderVO.totalShippingAdjustments = 0;
                    }
                } catch (error) {
                    originalCartData.data.orderDetails.fdOrderVO.appliedPromos[0].appliedAmount = 0;
                    originalCartData.data.orderDetails.fdOrderVO.totalShippingAdjustments = 0;
                    yield call(errorOperations.getError, error, 'cart');
                }
            }
            /**
             * END PROMOTION CALL
             */

            /**
             * START TAX CALL
             */
            // Convert totalProduct, orderItem.price, orderItem.totalProduct if the currency isn't USD
            let totalProductCloned = parseFloat(originalCartData?.data?.orderDetails?.fdOrderVO?.totalProduct);

            if (flagState['is-canada-tax-homegrown-on'] && currencyName !== 'USD' && cartDataCloned?.orderDetails?.fdOrderVO?.totalTax >= 0 && cartDataCloned?.orderDetails?.fdOrderVO?.totalProduct && fetchTax) {
                const recipientsCloned = cartDataCloned.orderDetails.fdOrderVO.recipients;
                const hasRecipientDetails = checkRecipientsDetails(recipientsCloned);
                if (hasRecipientDetails) {
                    totalProductCloned = 0;
                    yield asyncForEach(recipientsCloned, async (recipient, recipientIndex) => {
                        await asyncForEach(recipient.orderItems, async (orderItem, orderIndex) => {
                            // `taxAmout` not `taxAmount`; it's the wrong spelling in the API response
                            if (orderItem.taxAmout || orderItem.taxAmout >= 0) {
                                // Calculate totalProductCloned
                                const itemPrice = orderItem.price;
                                const itemTotalPrice = orderItem.totalProduct;
                                recipientsCloned[recipientIndex].orderItems[orderIndex].price = parseFloat(itemPrice);
                                recipientsCloned[recipientIndex].orderItems[orderIndex].totalProduct = parseFloat(itemTotalPrice);
                                totalProductCloned += parseFloat(itemTotalPrice);
                            }
                        });
                    });

                    // Call calculateTax API with new cartDataCloned
                    try {
                        if (totalProductCloned > 0) {
                            cartDataCloned.orderDetails.fdOrderVO.totalProduct = totalProductCloned;
                        }
                        // `actions` array is needed for tax calculation API
                        if (promotions.length) {
                            cartDataCloned.orderDetails.fdOrderVO.appliedPromos[0].actions = promotions;
                        }
                        const taxResponse = yield call(calculateTax, cartDataCloned, headers);
                        if (taxResponse?.status === 200) {
                            // Override cartDataCloned with the taxResponse.data
                            cartDataCloned.orderDetails = taxResponse?.data?.orderDetails;
                            // Override totalTax of originalCartData
                            originalCartData.data.orderDetails.fdOrderVO.totalTax = cartDataCloned.orderDetails.fdOrderVO?.totalTax;
                            originalCartData.data.orderDetails.fdOrderVO.estimatedTotalTaxes = cartDataCloned.orderDetails.fdOrderVO?.estimatedTotalTaxes;

                            yield asyncForEach(cartDataCloned.orderDetails.fdOrderVO?.recipients, async (recipient, recipientIndex) => {
                                await asyncForEach(recipient.orderItems, async (orderItem, orderIndex) => {
                                    // `taxAmout` not `taxAmount`; it's the wrong spelling in the API response
                                    if (orderItem.taxAmout) {
                                        // Override taxAmout in originalCartData
                                        originalCartData.data.orderDetails.fdOrderVO.recipients[recipientIndex].orderItems[orderIndex].taxAmout = orderItem.taxAmout;
                                    }
                                });
                            });
                            originalCartData.data.orderDetails.fdOrderVO.taxStatus = true;
                        } else {
                            // Set Tax amount back to TBD if there is an error
                            originalCartData.data.orderDetails.fdOrderVO.totalTax = 0;
                            originalCartData.data.orderDetails.fdOrderVO.taxStatus = true;
                        }
                    } catch (ex) {
                        // Set Tax amount back to TBD if there is an error
                        originalCartData.data.orderDetails.fdOrderVO.totalTax = 0;
                        yield call(errorOperations.getError, ex, 'apiError');
                        originalCartData.data.orderDetails.fdOrderVO.taxStatus = true;
                    }
                } else {
                    // Set Tax amount back to TBD if there is an error
                    originalCartData.data.orderDetails.fdOrderVO.totalTax = 0;
                }
            }
            /**
             * END TAX CALL
             */

            // Calculate orderTotalBalance for BrainTree checkout
            const totalTaxCloned = originalCartData.data.orderDetails.fdOrderVO.totalTax;
            const giftCardsTotalCloned = cartDataCloned.orderDetails.fdOrderVO.giftCardsTotal;
            // TODO: NEEDS TO INCLUDE PROMOTION
            if (totalProductCloned > 0 && totalShippingCloned > 0) {
                const orderTotalBeforeTaxes = totalProductCloned + totalShippingCloned - Math.abs(giftCardsTotalCloned) + totalPromotionCloned;
                const orderTotalBalance = orderTotalBeforeTaxes + totalTaxCloned;
                if (orderTotalBalance <= 0) {
                    originalCartData.data.orderDetails.fdOrderVO.orderTotalBeforeTaxes = 0;
                    originalCartData.data.orderDetails.fdOrderVO.orderTotalBalance = 0;
                } else {
                    originalCartData.data.orderDetails.fdOrderVO.orderTotalBeforeTaxes = parseFloat(orderTotalBeforeTaxes.toFixed(2));
                    originalCartData.data.orderDetails.fdOrderVO.orderTotalBalance = parseFloat(orderTotalBalance.toFixed(2));
                }
            }
            yield put(loadCart(originalCartData?.data));
            // Success
            yield put(cartLoaded());

            // log
            if (getFlagEnableCheckoutMonitoring(flagState) === true) {
                // log updated as ERROR to track on prod env
                mbpLogger.logError({
                    function: 'retrieveCart',
                    module: 'mbp-checkout',
                    message: 'Call RetrieveCart API - End',
                    orderId,
                });
            }
        } else { // no orderid
            const windowLocation = (typeof window !== 'undefined') ? window.location.href : '';
            mbpLogger.logError({
                function: 'retrieveCart',
                module: 'Cart-Operations',
                message: 'Retrieve Cart - No Order Id',
                orderId,
                windowLocation,
            });
        }
    } catch (ex) {
        mbpLogger.logError({
            function: 'retrieveCart',
            module: 'mbp-checkout',
            jsError: ex,
            message: 'RetrieveCart API failed',
            orderId,
        });
        yield call(errorOperations.getError, ex, 'apiError');
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } finally {
        yield put(cartLoaded());
    }
}

function* getItemInfo(action) {
    try {
        const jwtToken = yield call(checkJWT);
        const items = yield call(fetchItem, [action.orderId], jwtToken);
        yield put(receiveItem(items));
    } catch (e) {
        console.log(e);
    }
}

function* watchCartRetrieve() {
    yield takeEvery(getCart().type, retrieveCart);
}

function* shoppingCartWatcherInfo() {
    yield takeEvery(loadItems().type, getItemInfo);
}

function* recalculateGiftCardTotalWatcher() {
    let action = yield take(recalculateGiftCardTotal().type);
    while (action !== END) {
        yield call(recalculateGiftCardTotalWorker);
        action = yield take(recalculateGiftCardTotal().type);
    }
}

function* watcherHandleCartFailure() {
    yield takeEvery(callHandleCartFailure().type, handleCartFailure);
}

const circuitBreakGetCartInSession = circuitBreaker(2);

export function* getCartInSession(action) {
    try {
        const { accessToken } = action.data;
        if (typeof window !== 'undefined' && accessToken) {
            const isCheckoutPage = window.location.href.includes('/checkout');
            const isOrderConfirmationPage = window.location.href.includes('/order-confirmation');
            const payload = {
                env: {},
                jwtToken: accessToken,
            };

            if (!isOrderConfirmationPage) {
                if (isCheckoutPage) {
                    const responseCart = yield call(cartServices.getCartOrderDetails, {
                        wcEnv: {},
                        jwtToken: accessToken,
                        cartId: '',
                    });
                    const cartId = responseCart.data.cartId;
                    let cartCount = responseCart?.data?.orderSize ? Number(responseCart.data.orderSize) : 0;

                    if (cartCount <= 0) {
                        const responseCartCount = yield call(cartServices.getCartCount, payload);
                        cartCount = responseCartCount?.data?.itemCount || 0;
                    }

                    yield put(loadCart(responseCart.data));
                    yield put(loadOrderId([cartId]));
                    yield put(loadCartCount(Number(cartCount)));
                } else {
                    const responseCart = yield call(cartServices.getCartCount, payload);
                    const cartId = responseCart?.data?.cartId;
                    const cartCount = responseCart?.data?.itemCount || 0;

                    yield put(loadOrderId([cartId]));
                    yield put(loadCartCount(Number(cartCount)));
                }
            }
        } else {
            yield put(loadCart({}));
            yield put(loadOrderId(['']));
            yield put(loadCartCount(0));
        }
    } catch (ex) {
        yield put(loadCart({}));
        yield put(loadOrderId(['']));
        yield put(loadCartCount(0));

        const { response } = ex;
        if (response
            && response.status
            && ['401', '403'].includes(response.status.toString())
            && !circuitBreakGetCartInSession()) {
            mbpLogger.logError({
                function: 'getCartInSession',
                appName: process.env.npm_package_name,
                module: 'cart-Operations',
                message: `getCartInSession Failed - Status code: ${response.status} proceeding to get access token`,
            });

            yield put(clearAuthDependentState(true));

            yield call(checkJWT);
        }

        if (response?.data?.errorKey !== 'ERROR_CART_NOT_FOUND') {
            mbpLogger.logError({
                function: 'getCartInSession',
                appName: process.env.npm_package_name,
                module: 'Auth-Operations.js',
                message: 'Get Cart To rehydraid session failed',
            });
        }
    }
}

function* watcherGetCartInSession() {
    yield takeEvery([
        logHydraidUserSession().type,
        logUserLoggedInSuccess().type,
        logGuestSession().type,
    ], getCartInSession);
}

const watchers = [
    fork(watchCartRetrieve),
    fork(shoppingCartWatcherInfo),
    fork(recalculateGiftCardTotalWatcher),
    fork(watcherHandleCartFailure),
    fork(watcherGetCartInSession),
];

export {
    watchers,
    retrieveCart,
    recalculateGiftCardTotalWorker,
    // Actions
    loadCart,
    getCart,
    retrieveCartApi,
};
