/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import qs from 'qs';

import mbpLogger from 'mbp-logger';

import { useRef, useState, useEffect } from 'react';
import mbpUtil from 'mbp-api-util';
import scriptCache from './scriptCache';

// third-party script URLs
const mapKey = mbpUtil.getEnv('APP_GOOGLEMAP_API_KEY');
const GOOGLE_MAPS_SRC_URL = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;

const buildScriptCacheObject = () => {
    const scriptCacheObject = {};

    // all brands (no conditional check)
    Object.assign(scriptCacheObject, {
        googleMaps: GOOGLE_MAPS_SRC_URL,
    });

    return scriptCacheObject;
};

// Build Page Data based on the WCS URL details (token/patternName)
export const buildPageData = (
    match, // react-router
    currentPage, // Current Page (Page-Selector)
    pageData,
) => {
    let data = {};
    try {
        const { brand, url } = currentPage;
        const {
            order, paymentDetails, product, category, categoryProducts, page, searchTerm, searchProducts, ...pageDetails // Page Body data (Page-Selector)
        } = pageData;

        // Init data layer
        data = {
            brand,
            url,
            ...pageDetails,
        };
        const scriptCacheObject = buildScriptCacheObject();
        const cache = scriptCache(scriptCacheObject);

        // script onload example:
        cache.googleMaps.onLoad(() => {
            // console.log('Google Maps loaded.');
        });

        // Checkout
        if (match.url.indexOf('/checkout') !== -1) {
            // Load Checkout Data
            data.match = match;
            data.order = order;
            data.paymentDetails = paymentDetails;

            const checkoutPageDetails = {
                'interstitial-login': {
                    title: 'Sign In or Checkout as Guest',
                },
                cart: {
                    title: 'Shopping Cart',
                },
                shipping: {
                    title: 'Shipping and Delivery',
                },
                payment: {
                    title: 'Payment',
                },
                'order-confirmation': {
                    title: 'Order Confirmation',
                },
            };

            // Set Title
            data.title = checkoutPageDetails[match.params.page].title || '';

            // Product Page
        } else if (match.url.indexOf('/account') !== -1) {
            data.title = 'My Account';
            if (match.url.indexOf('/account/orders/') !== -1) {
                data.title = 'Track Order Status';
            }
        } else if (match.url.match(/\/gifthistory-home-/)) {
            data.title = 'Gift History';
        } else if (product) {
            data.product = product;
            // Landing Pages Page
        } else if (category && (category.departmentPage || category.clubLanderPage || category.categoryLanderPage || category.contentPage)) {
            data.category = category;
            // Category Pages
        } else if (category) {
            data.category = category;
            data.products = categoryProducts;
            // Search Results
        } else if (searchTerm) {
            data.searchTerm = searchTerm;
            data.products = searchProducts;
            // Content Pages
        } else if (page) {
            data.page = page;
        }
    } catch (ex) {
        mbpLogger.logError({
            match,
            currentPage,
            pageData,
            function: 'buildPageData',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to build page data.',
        });
    }

    return data;
};

// Get page type from URL tokens or match.url
export const getPageType = ({ match, pagePath }) => {
    try {
        // Checkout
        if (match.url.indexOf('/checkout') !== -1) {
            // Load Checkout Data
            return 'checkout';
        }

        // Account
        if (match.url.indexOf('/account') !== -1) {
            // Load Account Data
            return 'account';
        }

        // Core Browsing
        if (pagePath && pagePath.tokens) {
            const { tokens, patternName } = pagePath;
            const {
                ProductToken, CategoryToken, StaticPagesToken, SearchTermToken,
            } = tokens;

            // Home Page
            if (patternName === 'HomePageURL') {
                return 'home';
            }

            // Product Page
            if (ProductToken) {
                return 'product';
            }

            // Category Page
            if (CategoryToken) {
                return 'category';
            }

            // Content Pages
            if (StaticPagesToken || patternName === 'StaticPagesPattern') {
                return 'content';
            }

            // Search Results
            if (SearchTermToken) {
                return 'search';
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            match,
            pagePath,
            function: 'getPageType',
            module: 'mbp-page',
            jsError: ex,
            message: 'getPageType FAILED',
        });
        console.error('ERROR: Failed to get page type.', ex);
    }

    return null;
};

// Get page type from URL tokens or match.url
export const getPageSkeletonType = ({ match, location }) => {
    try {
        const { search } = location;
        const parsedQSParams = qs.parse(search.replace('?', ''));

        // URL Param override
        if (parsedQSParams.dest) {
            return parsedQSParams.dest || null;
        }

        // Checkout
        if (match.url.indexOf('/checkout') !== -1) {
            // Load Checkout Data
            return 'checkout';
        }

        // Account
        if (match.url.indexOf('/account') !== -1) {
            // Load Account Data
            return 'account';
        }

        // Search
        if (match.url.indexOf('/searchterm') !== -1) {
            return 'search';
        }

        return 'home'; // Default
    } catch (ex) {
        mbpLogger.logError({
            match,
            location,
            function: 'getPageType',
            module: 'mbp-page',
            jsError: ex,
            message: 'getPageType FAILED',
        });
        console.error('ERROR: Failed to get page type.', ex);
    }

    return null;
};

// attempt, success, and fail helper methods
let totalAttempts = 0;
const incrementAttempts = (callback, interval, maxAttempts) => {
    totalAttempts += 1;

    if (typeof maxAttempts !== 'undefined') {
        if (totalAttempts > maxAttempts) {
            clearInterval(interval);
            callback();

            const urlPath = window.location.pathname || '';

            mbpLogger.logWarning({
                jsError: {
                    callback,
                    interval,
                    maxAttempts,
                    urlPath,
                },
                function: 'incrementAttempts',
                appName: process.env.npm_package_name,
                module: 'mbp-tag-managment',
                message: 'Reached maximum number of attempts. Going to stop checking.',
            });
        }
    }
};

const timer = (args, test, callback, sleep, maxAttempts) => {
    const interval = setInterval(() => {
        try {
            if (test(...args)) {
                clearInterval(interval);
                callback();
            } else {
                incrementAttempts(callback, interval, maxAttempts);
            }
        } catch (e) {
            mbpLogger.logError({
                args,
                callback,
                sleep,
                maxAttempts,
                jsError: e,
                function: 'timer',
                module: 'mbp-tag-managment',
                message: 'Failure in check.',
            });

            incrementAttempts(callback, interval, maxAttempts);
        }
    }, sleep);
};

export const when = (test, callback, sleep = 100, maxAttempts) => {
    totalAttempts = 0;
    // eslint-disable-next-line no-undef
    const args = Array.prototype.slice.call(arguments, 2);

    timer(args, test, callback, sleep, maxAttempts);
};

/*
 * @description hook to find the intersection between two elements
 * @param {object} First Param
 * @return {array} Element reference and intersection object
 */
export const useIntersect = ({ root = null, rootMargin, threshold = 1 }) => {
    const [entry, updateEntry] = useState({});
    const [node, setNode] = useState(null);
    const observer = useRef(null);
    useEffect(() => {
        if (observer.current) observer.current.disconnect();
        observer.current = new window.IntersectionObserver(([entryValue]) => updateEntry(entryValue), { root, rootMargin, threshold });
        const { current: currentObserver } = observer;
        if (node) currentObserver.observe(node);
        return () => currentObserver.disconnect();
    }, [node]);
    return [setNode, entry];
};

export default {};
