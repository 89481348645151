/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import axios from 'axios';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';
import restClient from '../../app/helpers/restClient';

const endpoints = {
    generateCard: '/generate-card',
    generateLabel: '/generate-label',
    getCustomCard: '/label',
    cardPlaced: '/card-placed',
};

const wcEnv = {
    rootUri: '/checkout/greetingcards',
};

const cgcDomain = mbpUtil.getEnv('APP_CELEBRATIONS_GREETING_DOMAIN');

const getProtocol = () => {
    // SSR
    if (typeof window === 'undefined') {
        return process.env.APP_WC_PROTOCOL_SSR || 'https';
    }

    return mbpUtil.getEnv('APP_WC_PROTOCOL') || 'https';
};

export const fetchCGCCardData = (data) => axios.post(
    `${getProtocol()}://${cgcDomain}${endpoints.generateCard}`,
    data,
)
    .then((response) => response)
    .catch((err) => {
        mbpLogger.logError({
            err,
            function: 'fetchCGCCardData',
            module: 'App-Operations',
        });
    });

export const fetchCGCLabeldData = (data) => axios.post(
    `${getProtocol()}://${cgcDomain}${endpoints.generateLabel}`,
    data,
)
    .then((response) => response)
    .catch((err) => {
        mbpLogger.logError({
            err,
            function: 'fetchCGCLabeldData',
            module: 'App-Operations',
        });
    });

export const fetchCGCCustomCardData = (keyId) => axios.get(
    `${getProtocol()}://${cgcDomain}${endpoints.getCustomCard}/${keyId}`,
)
    .then((response) => response)
    .catch((err) => {
        mbpLogger.logError({
            err,
            function: 'fetchCGCCustomCardData',
            module: 'App-Operations',
        });
    });

export const processCGCGreetingCardAPI = (data) => axios.post(
    `${getProtocol()}://${cgcDomain}${endpoints.cardPlaced}`,
    { data },
).then((response) => response);

export const generateCgcCard = (data, token) => restClient.postFacade(
    wcEnv,
    endpoints.generateCard,
    token,
    data,
)
    .then((response) => response)
    .catch((err) => {
        mbpLogger.logError({
            err,
            function: 'generateCgcCard',
            module: 'App-Operations',
        });
    });

export const generateCgcLabel = (data, token) => restClient.postFacade(
    wcEnv,
    endpoints.generateLabel,
    token,
    data,
).then((response) => response)
    .catch((err) => {
        mbpLogger.logError({
            err,
            function: 'generateCgcLabel',
            module: 'App-Operations',
        });
    });

export const getCgcCustomCard = (keyId, token) => restClient.getFacade(
    wcEnv,
    `${endpoints.generateLabel}/${keyId}`,
    token,
)
    .then((response) => response)
    .catch((err) => {
        mbpLogger.logError({
            err,
            function: 'getCgcCustomCard',
            module: 'App-Operations',
        });
    });

export const processCgcGreetingCard = (data, token) => restClient.postFacade(
    wcEnv,
    endpoints.cardPlaced,
    token,
)
    .then((response) => response)
    .catch((err) => {
        mbpLogger.logError({
            err,
            function: 'processCgcGreetingCard',
        });
    });

export default {};
