/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, takeEvery, put, call, take, select, takeLatest,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';
import memberDucks from '../../../../../Member/ducks';
import { errorActions, errorOperations }  from '../../../Common/ducks/Error';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';

import { retrieveCart } from '../../../Cart/Cart-Operations';
import * as formActions from './RecipientForm-Actions';
import { recipientFormFields } from './RecipientForm-Helpers';
import { shippingInfoActions } from '../ShippingInfo';
import { updateBillingFormAutoFilled, updateCheckBillingAddressSameForm } from '../../../Payment/ducks/BillingAddressForm/BillingAddressForm-Actions';
import { qasAddressSearchOperations  } from '../../../Common/ducks/AddressSearchQAS';
import * as commonSelectors from '../../../Common/Common-Selectors';
import { pwaDesktopAddGiftMessage, formatFullGifMessage } from '../GiftMessageForm/GiftMessageForm-Operations';
import { updateWorkingRecipient } from '../../Recipient-Actions';
import { giftMessageFormSelectors } from '../GiftMessageForm';
import { getSavedAddress, getLocationType } from '../../../../../App/App-Selectors';
import { convertAddressByAddressSource } from '../../../../helpers/preCheckoutAddressNormalizer';
import { getInternationalCountryField, checkIsInternational, getCountryField } from '../../../Checkout/Checkout-Helper';
import { addCardIsleToCart, loadCardIsleAvailable } from '../CardIsle/CardIsle-Operations';
import { resetCardIsle } from '../CardIsle/CardIsle-Actions';
import { prepareRetreiveCart } from '../../../Checkout/Checkout-Operations';
import { resetGiftMessageForm } from '../GiftMessageForm/GiftMessageForm-Actions';
import { getCardIsleSelected } from '../CardIsle/CardIsle-Selector';
import isDesktopMobileTablet from '../../../../../../../app/helpers/DesktopMobile/isDesktopMobileTablet';
import { getBrand } from '../../../../../App/ducks/Brand/Brand-Selectors';

const {
    auth: {
        authOperations: {
            workers: {
                checkJWT,
            },
        },
    },
    addressBook: {
        addressBookActions,
    },
    common: {
        commonSelectors: {
            getProfileInfo,
            getIsAuthenticatedStatus,
            getGuestAddressBookEntries,
            getOrderId,
        },
    },
} = memberDucks;

const addRecipient = ({
    jwtToken, orderItemId, recipient, entryId, orderId,
}) => orderClient.addRecipient(
    {},
    jwtToken,
    orderItemId,
    recipient,
    entryId,
    orderId,
);

const getStateCityData = ({
    jwtToken, zipCode,
}) => orderClient.getCityStateFromZip(
    {},
    jwtToken,
    zipCode,
);

function* updateForm(action) {
    try {
        yield put(formActions.updateFormValues(action.data));
    } catch (ex) {
        console.log('EXCEPTION: ', ex);
    }
}

function* updateFormFromAddressBook(action) {
    try {
        yield put(formActions.updateFromAddressBook(action.data));
    } catch (ex) {
        console.log(ex);
    }
}

function* clearFormFields() {
    try {
        yield put(formActions.clearRecipientFormFields());
    } catch (ex) {
        console.log(ex);
    }
}

function* handleProductNotAvailableZipCode() {
    const loggedErrorKeysPayload = yield take(errorActions.loggedErrorKeys().type);
    if (loggedErrorKeysPayload.errorKeys.indexOf('_ERR_NOT_AVALIABLE_IN_ZIP') >= 0) {
        yield call(retrieveCart);
    }
}

const removeMaskForPhoneNumber = (number) => {
    let strNumber = number.toString().trim();
    strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9+]/g, '');
    if (strNumber.indexOf('+') === 0 && strNumber.indexOf('1') === 1) {
        strNumber = strNumber.substring(2, strNumber.length);
    } else if (strNumber.indexOf('+') >= 0) {
        strNumber = strNumber.replace(/\s/g, '').replace(/[^0-9]/g, '');
    }
    return strNumber;
};

function trimAndCompare(str1, str2) {
    const strVal1 = str1 ? str1.trim() : '';
    const strVal2 = str2 ? str2.trim() : '';
    return (strVal1 === strVal2);
}

function checkIfSavingInternationalProduct(recipientAddress) {
    if (recipientAddress.zipCode === '00000' && recipientAddress.state === 'OS') {
        return true;
    }
    return false;
}

function checkIfAddressPresentIntoGuestAddresslist(guestAddressList, recipientFormDetails) {
    const addressExist = guestAddressList.some((el) => {
        if (el.firstName === recipientFormDetails.firstName
            && el.lastName === recipientFormDetails.lastName
            && el.address1 === recipientFormDetails.address1
            && el.address2 === recipientFormDetails.address2
            && el.locationType === recipientFormDetails.locationType
            && el.state === recipientFormDetails.state
            && el.country === recipientFormDetails.country
            && el.city === recipientFormDetails.city
            && el.zipCode === recipientFormDetails.zipCode
            && el.phone === recipientFormDetails.phone) {
            return true;
        }
        return false;
    });
    return addressExist;
}

function* submitForm(action) {
    try {
        // Show loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'recipientForm',
            field: `${action.data.orderItemId}_productNotAvailable`,
        }));

        yield put(errorActions.clearErrors({
            errType: 'recipientFormClearAllFields',
            field: 'error',
        }));

        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));

        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: `${action.data.orderItemId}_missing_info`,
        }));

        yield put(errorActions.clearErrors({
            errType: 'validateBillingAddress',
        }));

        // Prepare Save Recipient
        const recipientFormDetails = action.data.recipient;
        if (recipientFormDetails.phone) {
            recipientFormDetails.phone = removeMaskForPhoneNumber(recipientFormDetails.phone);
        }

        if (action.data.useAddressForAll) {
            recipientFormDetails.useAddressForAll = 'Y';
        }

        if (action.data.isAddressVerified) recipientFormDetails.isAddressVerified = action.data.isAddressVerified;

        if (action.data.dpvIndicator) recipientFormDetails.dpvIndicator = action.data.dpvIndicator;

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);

        // Pass entry Id to update address book
        const reqObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            recipient: recipientFormDetails,
            entryId: action.data.entryId,
            orderId,
        };

        // Save Recipient
        yield call(addRecipient, reqObj);

        const isAuthenticated = yield select(getIsAuthenticatedStatus);
        const isInternational = yield call(checkIfSavingInternationalProduct, recipientFormDetails);

        // Registerd User - Load user addressbook
        if (isAuthenticated) {
            yield put(addressBookActions.loadAddressBook());
        }

        // Avoid saving recipient address to guest address book if product is international
        if (!isAuthenticated && !isInternational) {
            const getGuestAddressBook = yield select(getGuestAddressBookEntries);
            let saveGuestAddressBookAddress = true;
            if (getGuestAddressBook && getGuestAddressBook.length > 0) {
                saveGuestAddressBookAddress = false;
                getGuestAddressBook.forEach((addressObject) => {
                    const firstName = trimAndCompare(addressObject.firstName, recipientFormDetails.firstName);
                    const lastName = trimAndCompare(addressObject.lastName, recipientFormDetails.lastName);
                    const address1 = trimAndCompare(addressObject.address1, recipientFormDetails.address1);
                    const address2 = trimAndCompare(addressObject.address2, recipientFormDetails.address2);
                    const locationType = trimAndCompare(addressObject.locationType, recipientFormDetails.locationType);
                    const state = trimAndCompare(addressObject.state, recipientFormDetails.state);
                    const country = trimAndCompare(addressObject.country, recipientFormDetails.country);
                    const zipCode = trimAndCompare(addressObject.zipCode, recipientFormDetails.zipCode);
                    const phone = trimAndCompare(addressObject.phone, recipientFormDetails.phone);
                    const city = trimAndCompare(addressObject.city, recipientFormDetails.city);
                    if (checkIfAddressPresentIntoGuestAddresslist(getGuestAddressBook, recipientFormDetails)) {
                        saveGuestAddressBookAddress = false;
                    } else if (!firstName || !lastName || !address1 || !address2 || !locationType || !state || !country || !zipCode || !phone || !city) {
                        saveGuestAddressBookAddress = true;
                    }
                });
            }
            if (saveGuestAddressBookAddress) {
                yield put(addressBookActions.saveGuestAddressBook(recipientFormDetails));
            }
        }

        if (action.data.closeForm) yield call(action.data.closeForm);

        if (action.data.openGiftMessage) yield call(action.data.openGiftMessage);

        yield call(clearFormFields);

        yield call(prepareRetreiveCart, action.data.page);

        yield call(retrieveCart);

        if (action.data.checkSameAsDeliveryAddress) {
            const userProfile = yield select(getProfileInfo);
            if (isAuthenticated) {
                reqObj.recipient.email = userProfile.email;
                reqObj.recipient.confirmEmail = userProfile.email;
            }

            yield put(formActions.checkSameAsDeliveryAddressAddress(reqObj.recipient));
            yield put(updateBillingFormAutoFilled(true));
        }

        // Hide Loader
        yield call(shippingInfoActions.triggerShowShippingInfo);

        yield put(formActions.updatedRecipientAddressSuccessfully());
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        ex.orderItemId = action.data.orderItemId;
        yield fork(errorOperations.getError, ex, 'recipientForm');
        yield call(handleProductNotAvailableZipCode);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

/*
    Validate Address with QAS before saving address
*/

function* proceedToSaveRecipientAddress(action) {
    const proceedToSave = yield call(qasAddressSearchOperations.workers.validateAddressQAS, action);
    const { allowSave, addressVerifiedStatus } = proceedToSave;
    if (allowSave) {
        const updateVerification = { ...action };
        updateVerification.data.dpvIndicator = addressVerifiedStatus.dpvIndicator;
        updateVerification.data.isAddressVerified = addressVerifiedStatus.isAddressVerified;
        yield call(submitForm, updateVerification);
    }
}

function* extendAddressBookWorker() {
    yield put(showLoadingMessageActions.updateShowLoadingMessage());
    yield take(addressBookActions.proccessAddressBookCompleted().type);
    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* recipientFormChangeWatcher() {
    yield takeEvery(formActions.updateForm().type, updateForm);
}

function* recipientFormSubmitWatcher() {
    yield takeEvery(formActions.submitForm().type, submitForm);
}

function* addressBookChangeWatcher() {
    yield takeEvery(formActions.handleAddressBookChange().type, updateFormFromAddressBook);
}

function* clearFieldsWatcher() {
    yield takeEvery(formActions.triggerClearRecipientFormFields().type, clearFormFields);
}

// Extend Addressbook Watcher
function* extendAddressBookWatcher() {
    yield takeEvery(addressBookActions.loadAddressBook().type, extendAddressBookWorker);
}

function* proceedToSaveWatcher() {
    yield takeEvery(formActions.proceedToSave().type, proceedToSaveRecipientAddress);
}

const isSaveGuestAddressBookAddress = (getGuestAddressBook, recipientFormDetails) => {
    let saveGuestAddressBookAddress = true;
    if (getGuestAddressBook && getGuestAddressBook.length > 0) {
        saveGuestAddressBookAddress = false;
        getGuestAddressBook.forEach((addressObject) => {
            const firstName = trimAndCompare(addressObject.firstName, recipientFormDetails.firstName);
            const lastName = trimAndCompare(addressObject.lastName, recipientFormDetails.lastName);
            const address1 = trimAndCompare(addressObject.address1, recipientFormDetails.address1);
            const address2 = trimAndCompare(addressObject.address2, recipientFormDetails.address2);
            const locationType = trimAndCompare(addressObject.locationType, recipientFormDetails.locationType);
            const state = trimAndCompare(addressObject.state, recipientFormDetails.state);
            const country = trimAndCompare(addressObject.country, recipientFormDetails.country);
            const zipCode = trimAndCompare(addressObject.zipCode, recipientFormDetails.zipCode);
            const phone = trimAndCompare(addressObject.phone, recipientFormDetails.phone);
            const city = trimAndCompare(addressObject.city, recipientFormDetails.city);
            if (!firstName || !lastName || !address1 || !address2 || !locationType || !state || !country || !zipCode || !phone || !city) {
                saveGuestAddressBookAddress = true;
            }
        });
    }
    return saveGuestAddressBookAddress;
};

/* PWA Desktop Code Start */
function* pwaDesktopSubmitForm(action) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        // Clear Error
        yield put(errorActions.clearErrors({
            errType: 'recipientForm',
            field: `${action.data.orderItemId}_productNotAvailable`,
        }));
        yield put(errorActions.clearErrors({
            errType: 'recipientFormClearAllFields',
            field: 'error',
        }));
        yield put(errorActions.clearErrors({
            errType: 'payment',
            field: action.data.orderItemId,
        }));
        yield put(errorActions.clearErrors({
            errType: 'validateBillingAddress',
        }));

        // Recipient Form Data
        const recipientFormDetails = action.data.recipient;
        const entryId = action.data.recipient?.entryId || '';

        const isAuthenticated = yield select(getIsAuthenticatedStatus);
        const isInternational = yield call(checkIfSavingInternationalProduct, recipientFormDetails);
        const brand = yield select(getBrand);

        if (action.data.useAddressForAll) {
            recipientFormDetails.useAddressForAll = 'Y';
        }

        if (action.data.isAddressVerified) recipientFormDetails.isAddressVerified = action.data.isAddressVerified;
        if (action.data.dpvIndicator) recipientFormDetails.dpvIndicator = action.data.dpvIndicator;

        const jwtToken = yield call(checkJWT);
        const orderId = yield select(getOrderId);
        const reqObj = {
            wcEnv: {},
            jwtToken,
            orderItemId: action.data.orderItemId,
            recipient: recipientFormDetails,
            entryId,
            orderId,
        };
        // Save Recipient Address
        yield call(addRecipient, reqObj);

        // Avoid saving recipient address to guest address book if product is international
        if ((brand?.code === 'PLA' || !isAuthenticated) && !isInternational) {
            const getGuestAddressBook = yield select(getGuestAddressBookEntries);
            const saveGuestAddressBookAddress = isSaveGuestAddressBookAddress(getGuestAddressBook, recipientFormDetails);

            if (saveGuestAddressBookAddress) {
                yield put(addressBookActions.saveGuestAddressBook(recipientFormDetails));
            }
        }

        yield call(clearFormFields);

        if (action.data.checkSameAsDeliveryAddress) {
            const userProfile = yield select(getProfileInfo);

            if (isAuthenticated) {
                reqObj.recipient.email = userProfile.email;
                reqObj.recipient.confirmEmail = userProfile.email;
                reqObj.recipient.checkSameAsDeliveryAddress = true;
            }

            yield put(formActions.checkSameAsDeliveryAddressAddress(reqObj.recipient));
            yield put(updateBillingFormAutoFilled(true));
            yield put(updateCheckBillingAddressSameForm(true));
        }
        yield call(shippingInfoActions.triggerShowShippingInfo);
        yield put(formActions.updatedRecipientAddressSuccessfully());
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        ex.orderItemId = action.data.orderItemId;
        yield fork(errorOperations.getError, ex, 'recipientForm');
        yield call(handleProductNotAvailableZipCode);
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        throw ex;
    }
}

function prepareRecipientFormData(recipientFormData) {
    return {
        entryId: recipientFormData?.entryId || '',
        firstName: recipientFormData.firstName,
        lastName: recipientFormData.lastName,
        address1: recipientFormData.address1,
        address2: recipientFormData.address2,
        locationType: recipientFormData.locationType,
        nameOfLocation: recipientFormData.nameOfLocation,
        state: recipientFormData.state,
        country: recipientFormData.country,
        zipCode: recipientFormData.zipCode,
        phone: removeMaskForPhoneNumber(recipientFormData.phone),
        city: recipientFormData.city,
        email: recipientFormData.email,
        organizationName: recipientFormData.organizationName,
    };
}

const isCandaZip = (zipcode) => new RegExp(/^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}[- ]{0,1}[0-9]{1}[a-zA-Z]{1}[0-9]{1}/).test(zipcode);

const capitalizeCity = (city) => city?.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());

const getCity = (cityList, selectCity) => {
    if (selectCity && cityList?.find((city) => city.toLowerCase() === selectCity.toLowerCase())) {
        return capitalizeCity(cityList.find((city) => city.toLowerCase() === selectCity.toLowerCase()) || '');
    }
    return capitalizeCity(cityList?.[0] || selectCity || '');
};

function* triggerStateCityData(action) {
    try {
        let formZipCode = action.data.trim();
        let labelZip = 'Zip Code';
        let checkZip = false;

        if ((formZipCode.length === 6 || formZipCode.length === 7) && isCandaZip(formZipCode)) {
            checkZip = true;
            const zipSplit = formZipCode.replace(/\s/gi, '').toUpperCase().split('');
            zipSplit.splice(3, 0, ' ');
            formZipCode = zipSplit.join('');
            labelZip = 'Postal Code';
        } else if (formZipCode.length >= 5) {
            checkZip = true;
        }

        const jwtToken = yield call(checkJWT);
        const reqObj = {
            jwtToken,
            zipCode: formZipCode,
        };

        yield put(errorActions.loadError({ errType: 'recipientForm', errMsg: { zipCode: '' } }));

        if (reqObj && reqObj.zipCode && checkZip) {
            const resStateCityData = yield call(getStateCityData, reqObj);
            const cityStateDate = resStateCityData.data.esbSaltaServiceResponse;

            if (cityStateDate && cityStateDate.checkZipResponse
                && cityStateDate.checkZipResponse.checkZipResult
                && cityStateDate.checkZipResponse.checkZipResult.flwsErrors) {
                yield put(errorActions.loadError({ errType: 'recipientForm', errMsg: { zipCode: `${'Invalid '}${labelZip}` } }));
            }

            if (cityStateDate && cityStateDate.checkZipResponse
                && cityStateDate.checkZipResponse.checkZipResult
                && cityStateDate.checkZipResponse.checkZipResult.location
                && cityStateDate.checkZipResponse.checkZipResult.location.state) {
                const {
                    checkZipResponse: {
                        checkZipResult: {
                            location: {
                                state,
                                country,
                                cityList: {
                                    city,
                                },
                                zipCode,
                            },
                        },
                    },
                } = cityStateDate;
                const cityList = [
                    ...new Set(city),
                ];

                yield put(errorActions.loadError({ errType: 'recipientForm', errMsg: { zipCode: '' } }));

                const [editingRecipient] = yield select(commonSelectors.getCurrentEditingRecipient); // getting current saved recipient address
                const currentCity = editingRecipient?.recipientAddress?.city || null; // if user saved address then use previous city
                const recipientAddressForm = yield select(commonSelectors.getRecipientForm); // getting current form values
                const cityFromList = getCity(cityList, recipientAddressForm?.city || currentCity);

                if (country === 'CAN' || country === 'USA') {
                    yield put(formActions.updateRecipientForm({
                        country: country.slice(0, -1),
                        state,
                        cityArray: cityList,
                        city: cityFromList,
                        zipCode,
                    }));
                } else {
                    yield put(formActions.updateRecipientForm({
                        state,
                        cityArray: cityList,
                        city: cityFromList,
                        zipCode,
                    }));
                }
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            module: 'RecipientForm-Operations.js',
            function: 'triggerStateCityData',
            jsError: ex,
        });
    }
}

function* submitRecipientForm(action) {
    try {
        // Show Site Loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        const {
            orderItemId, history, dpvIndicator, isAddressVerified, checkSameAsDeliveryAddress,
        } = action.data;
        const objRecipientFormData = yield select(commonSelectors.getRecipientForm);
        const objPrepareRecipientFormData = yield call(prepareRecipientFormData, objRecipientFormData);
        const actionDataSaveRecipient = {
            data: {
                orderItemId,
                recipient: objPrepareRecipientFormData,
                checkSameAsDeliveryAddress,
                useAddressForAll: false,
                dpvIndicator,
                isAddressVerified,
                page: 'shipping',
                avoidClearForm: false,
            },
        };
        // Save Recipient Address Saga
        yield call(pwaDesktopSubmitForm, actionDataSaveRecipient);

        // Add Greeting Card
        // Review if the card is enabled and filled
        let typeGreeting = 'message';
        const flagState = yield select(commonSelectors.getFlags);
        const cardSelected = yield select(getCardIsleSelected);
        const cIEnabled = flagState['is-card-isle-enabled'] || false;

        if (cIEnabled && cardSelected?.pickupCode) {
            typeGreeting = 'card';
        }

        if (typeGreeting === 'message') {
            // Save gift message
            const giftMessageObj = yield select(giftMessageFormSelectors.getGiftMessageField);
            const messageTextPiped = giftMessageObj.message.replace(/\n/g, '|');
            const payloadGiftMessage = `${messageTextPiped} From: ${giftMessageObj.author}`;
            const occasionCode = giftMessageObj?.occasion || '';
            const actionDataSaveGiftMessage = {
                data: {
                    orderItemId,
                    giftMessage: payloadGiftMessage,
                    occasionCode,
                },
            };

            // Save Gift Message Saga
            yield call(pwaDesktopAddGiftMessage, actionDataSaveGiftMessage);
        } else {
            // Save Card isle
            yield call(addCardIsleToCart, orderItemId);
        }

        if (cIEnabled) {
            yield put(resetCardIsle());
        }
        yield put(resetGiftMessageForm());

        // Get Cart
        yield call(retrieveCart);

        const recipientEditingIndex = yield select(commonSelectors.getWorkingRecipient);
        const orderItemCount = yield select(commonSelectors.getNoOfOrderItems);
        const orderId = yield select(commonSelectors.getOrderId);

        // Clear if any payment errors due to address is not added
        yield put(errorActions.clearErrors({ errType: 'payment', field: 'orderItemError' }));
        yield put(errorActions.clearErrors({ errType: 'payment', field: orderItemId }));

        if (recipientEditingIndex < orderItemCount - 1) {
            yield put(updateWorkingRecipient());

            const [editingRecipient] = yield select(commonSelectors.getCurrentEditingRecipient);
            if (editingRecipient?.recipientAddress) {
                const recipientDetails = editingRecipient.recipientAddress;
                const [orderItems] = editingRecipient?.orderItems || [];
                const isInternational = yield call(checkIsInternational, orderItems);

                // load recipient address for for the next item
                const fillRecipientDetails = yield call(recipientFormFields, recipientDetails, orderItems);

                // load gift message for the next item
                if (editingRecipient?.orderItems?.length > 0 &&  editingRecipient.orderItems[0]?.field2) {
                    const giftMessageData = editingRecipient.orderItems[0].field2;
                    yield call(formatFullGifMessage, giftMessageData);
                }

                // set fields values for internation products
                if (isInternational) {
                    const countryField = yield call(getInternationalCountryField, orderItems);
                    fillRecipientDetails.country = countryField;
                    fillRecipientDetails.state = 'OS';
                    fillRecipientDetails.zipCode = '00000';
                } else {
                    fillRecipientDetails.country = yield call(getCountryField, recipientDetails?.country);
                }

                if (recipientDetails?.firstName) {
                    const { firstName, lastName, phoneNo } = recipientDetails;
                    if (firstName && lastName && phoneNo) {
                        fillRecipientDetails.isFormValid = true;
                    } else {
                        fillRecipientDetails.isFormValid = false;
                    }
                    yield put(formActions.updateFormAddressFromPreFill(fillRecipientDetails));
                } else {
                    fillRecipientDetails.isFormValid = false;
                    yield put(formActions.updateFormAddressFromPreFill(fillRecipientDetails));
                }

                // Load CityList and State from Zipcode
                if (recipientDetails?.zipCode && !isInternational) {
                    yield put(formActions.triggerStateCityData(recipientDetails.zipCode));
                }

                if (cIEnabled) {
                    // Review the product has card isle available.
                    yield call(loadCardIsleAvailable);
                }
            }

            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            if (typeof window !== 'undefined') {
                window.scrollTo(0, 0);
            }
        } else {
            yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
            const paypalPayment = yield select(commonSelectors.getPaypalPaymentDetails);
            const { payload } = paypalPayment;
            if (payload && payload.nonce && payload.pageType === 'cart') {
                const deviceType = isDesktopMobileTablet();
                if (deviceType === 'mobile') {
                    history.push(`/checkout/payment/${orderId}`);
                } else {
                    history.push(`/checkout/review-order/${orderId}`);
                }
            } else {
                history.push(`/checkout/${flagState['is-checkout-shipping-date-enabled'] ? 'shipping-date' : 'payment'}/${orderId}`);
            }
        }
    } catch (ex) {
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
        mbpLogger.logError({
            module: 'RecipientForm-Operations.js',
            function: 'submitRecipientForm',
            jsError: ex,
        });
    }
}

function* proceedSaveRecipientPwaDesktop(action) {
    const proceedToSave = yield call(qasAddressSearchOperations.workers.validateAddressQAS, action);
    const { allowSave, addressVerifiedStatus } = proceedToSave;

    if (allowSave) {
        const updateVerification = { ...action };
        updateVerification.data.dpvIndicator = addressVerifiedStatus.dpvIndicator;
        updateVerification.data.isAddressVerified = addressVerifiedStatus.isAddressVerified;
        yield call(submitRecipientForm, updateVerification);
    }
}

function* workerSaveRecipientPreCheckout() {
    try {
        const jwtToken = yield call(checkJWT);
        yield call(retrieveCart);
        const savedAddress = yield select(getSavedAddress);
        const recipient = yield call(convertAddressByAddressSource, savedAddress);
        const locationType = yield select(getLocationType);
        recipient.locationType = locationType;
        // Address Book Entry Id need to be passed to avoid
        const entryId = savedAddress?.addressComponents?.AddressBookEntryId || '';
        const lastAddedOrderItemDetails = yield select(commonSelectors.getLastAddedOrderItemDetails);
        const orderItemId = lastAddedOrderItemDetails?.orderItemsId || '';
        const orderId = yield select(getOrderId);
        if (orderId && orderItemId) {
            const reqObj = {
                wcEnv: {},
                jwtToken,
                orderItemId,
                recipient,
                entryId,
                orderId,
            };
            yield call(addRecipient, reqObj);
            yield call(retrieveCart);
        } else {
            mbpLogger.logError({
                orderId,
                orderItemId,
                module: 'RecipientForm-Operations.js',
                function: 'workerSaveRecipientPreCheckout',
                message: 'Missing OrderId or OrderItemId',
            });
        }
        yield put(formActions.saveRecipientAddressPreCheckoutCompleted());
    } catch (ex) {
        mbpLogger.logError({
            module: 'RecipientForm-Operations.js',
            function: 'workerSaveRecipientPreCheckout',
            jsError: ex,
        });
        yield put(formActions.saveRecipientAddressPreCheckoutCompleted());
    }
}

function* processSubmitRecipientPWADesktop(action) {
    yield call(submitRecipientForm, action);
}

function* watcherSubmitRecipientPWADesktop() {
    yield takeLatest(formActions.submitRecipientPwaDesktop().type, processSubmitRecipientPWADesktop);
}

function* watcherSaveRecipientPWADesktop() {
    yield takeLatest(formActions.proceedSaveRecipientPwaDesktop().type, proceedSaveRecipientPwaDesktop);
}

function* watcherTriggerStateCityData() {
    yield takeEvery(formActions.triggerStateCityData().type, triggerStateCityData);
}

function* watcherSaveRecipientPreCheckout() {
    yield takeLatest(formActions.saveRecipientAddressPreCheckout().type, workerSaveRecipientPreCheckout);
}

/* PWA Desktop Code End */

const watchers = [
    fork(recipientFormSubmitWatcher),
    fork(recipientFormChangeWatcher),
    fork(addressBookChangeWatcher),
    fork(clearFieldsWatcher),
    fork(extendAddressBookWatcher),
    fork(proceedToSaveWatcher),
    fork(watcherSubmitRecipientPWADesktop),
    fork(watcherSaveRecipientPWADesktop),
    fork(watcherTriggerStateCityData),
    fork(watcherSaveRecipientPreCheckout),
];

export { watchers, addRecipient };

export default {};
