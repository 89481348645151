/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './CreditCardBrand-ActionTypes';

export const getCreditCardBrand = (data) => ({
    type: actionTypes.GET_CARD_BRAND,
    data,
});

export const loadCreditCardBrand = (data) => ({
    type: actionTypes.LOAD_CARD_BRAND,
    data,
});
