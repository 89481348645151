/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export default {
    common: {
        showLoadingMessage: {
            flag: false,
            loaderId: 0,
        },
        error: {
            error: '',
            promotion: {
                promotionCode: '',
                error: '',
            },
            giftCard: '',
            cart: '',
            recipientForm: {
                zipCode: '',
                locationType: '',
                firstName: '',
                lastName: '',
                address1: '',
                city: '',
                error: '',
                state: '',
                email: '',
            },
            payment: {
                lastName: '',
                firstName: '',
                address1: '',
                email: '',
                confirmEmail: '',
                state: '',
                phone: '',
                zipCode: '',
                city: '',
                accountNumber: '',
                cvv: '',
                invalidYearAndMonth: '',
                error: '',
                month: '',
                year: '',
                nameOnCreditCard: '',
                validateBillingAddress: false,
                expirationMonthError: '',
                expirationYearError: '',
                detailedError: '',
            },
            calendar: '',
            giftMessage: '',
            dtw: '',
            passport: '',
            apiError: '',
        },
        properties: {},
        addressSearchQAS: {
            suggestion: {},
            formattedAddress: {},
            showValidatedAddressSuggestion: false,
            qasFailed: false,
        },
        dtw: {},
    },
    cart: {},
    order: {
        orderId: '',
        expressCheckout: false,
        atlasOrderId: '',
    },
    recipient: {
        recipients: [],
        orderItems: {},
        shippingInfo: false,
        workingRecipient: 0,
        recipientForm: {
            entryId: '',
            addressId: '',
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            email: '',
            locationType: '',
            nameOfLocation: '',
            state: '',
            country: '',
            zipCode: '',
            phone: '',
            city: '',
            giftMessageLine1: '',
            giftMessageLine2: '',
            giftMessageLine3: '',
            giftMessageLine4: '',
            giftMessageLine5: '',
            giftMessageLine6: '',
            giftMessageLine7: '',
            organizationName: '',
            isFormValid: false,
            cityArray: [],
            submitForm: false,
            checkSameAsDeliveryAddress: false,
            useAddressForAll: false,
        },
        calShipping: {
            calShippingChargesAttempt: 0,
            calShippingApplied: false,
        },
        cardIsle: {
            availability: {},
            selected: {},
        },
        giftMessage: {
            occasion: '',
            message: '',
            author: '',
            isFormValid: true,
            isOrderConfirmation: false,
            showGiftMessageContainer: true,
            showGiftMessageForm: true,
        },
        giftMessageExpressions: [],
    },
    payment: {
        creditCardBrand: '',
        paymentDetails: {},
        paymentForm: {
            accountNumber: '',
            amount: '',
            brand: '',
            cvv: '',
            expirationMonth: '',
            expirationYear: '',
            nameOnCreditCard: '',
            paymentMethod: {
                id: 'CreditCard',
                name: 'Credit/Debit Card',
            },
            isFormValid: false,
            lastFourDigits: '',
            isFromWallet: false,
        },
        rewardPoints: {
            balance: {},
            eligibility: 'F',
            selected: {
                points: '',
                amount: '',
            },
        },
        billingAddressForm: {
            firstName: '',
            lastName: '',
            address1: '',
            address2: '',
            address3: '',
            addressId: '',
            city: '',
            email: '',
            confirmEmail: '',
            country: '',
            locationType: '',
            organizationName: '',
            phone: '',
            mobilePhone: '',
            state: '',
            zipCode: '',
            cityArray: [],
            isFormValid: false,
            isFormAutoFilled: false,
            checkSameAsDeliveryAddress: false,
        },
        paymentMethods: {
            GooglePay: {
                id: 'GooglePay',
                name: 'Google Pay',
                active: 1,
                logo: ['//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/bltb2dea9d333c59ccd/5f4cebbe0ab106265a18730a/GooglePay_mark_800_gray.png'],
            },
            PayPal: {
                id: 'PayPal',
                name: 'PayPal',
                logo: ['//cdn2.1800flowers.com/wcsstore/Flowers/images/2018/mobile/Paypal-logo.png'],
                active: 1,
            },
            ApplePay: {
                id: 'ApplePay',
                name: 'Apple Pay',
                active: 0,
                logo: ['//cdn2.1800flowers.com/wcsstore/Flowers/images/btn_apple_pay_mark.png'],
            },
            ChasePay: {
                id: 'ChasePay',
                name: 'Chase Pay',
                active: 1,
                logo: ['//cdn2.1800flowers.com/wcsstore/Flowers/images/2018/mobile/chasepay_logo.png'],
            },
            VisaCheckout: {
                id: 'VisaCheckout',
                name: 'Click to Pay',
                active: 1,
                logo: ['//images.contentstack.io/v3/assets/bltdd99f24e8a94d536/blt5144bc0bcb0037ee/5f04ad87e54ba12bdf19b795/src-logo.png'],
            },
            CreditCard: {
                id: 'CreditCard',
                name: 'Credit/Debit Card',
                primary: 1,
                active: 1,
                logo: [
                    'https://cdn2.1800flowers.com/wcsstore/Flowers/images/2018/mobile/visa-icon.png',
                    'https://cdn2.1800flowers.com/wcsstore/Flowers/images/2018/mobile/amex-logo.png',
                    'https://cdn2.1800flowers.com/wcsstore/Flowers/images/2018/mobile/discover-logo.png',
                    'https://cdn2.1800flowers.com/wcsstore/Flowers/images/2018/mobile/mastercard-logo.png',
                ],
            },
        },
        subscription: {
            smsOptIn: false,
            emailOptIn: true,
        },
        account: {
            accountMessage: null,
        },
        paymentReadiness: {
            pageReady: false,
        },
        passport: {
            passportProcess: 0,
            showPassportConsentToRmGC: false,
            passportOrderItemId: '',
            tncContent: '',
            dynamicPassportCharge: '',
            passportData: {},
        },
        promotion: {
            promoCode: '',
            memberShipId: '',
        },
        paypal: {
            changeAccount: false,
            clientToken: '',
            payload: {},
            isBillingAddressMissingMandatoryFields: false,
        },
        googlePay: {
            clientToken: '',
        },
    },
};
