/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import track from 'react-tracking';

import {
    validateDataLayer,
    updateDataLayer,
    trackEvent,
    trackPageHandler,
    getProductListData,
} from '../utils/trackingUtils';

// Handle data layer injection
export const dispatchDataLayerChange = (data) => {
    if (typeof window !== 'undefined' && data) {
        validateDataLayer(data);
        updateDataLayer(data);
    }
};

// Track App
export const trackApp = (name) => track(
    { app: name },
    {
        dispatch: dispatchDataLayerChange,
    },
);

// Track Modules
export const trackModule = (name) => track({ module: name });

// Track Page
export const trackPage = (pageProps) => {
    const handler = trackPageHandler(pageProps);
    return track(handler);
};

// Track Category
export const trackCategoryData = (props = {}) => {
    const { categoryData } = props;

    if (categoryData && categoryData.category) {
        const { category } = categoryData;

        return {
            category,
            action: 'track_category_data',
        };
    }

    return null;
};

// Track Single Product
export const trackProductData = (props = {}) => {
    const { product } = props;
    if (product) {
        const products = {
            product,
            action: 'track_product_data',
        };
        if (product.brand === 'plants') trackEvent(products);
        else return products;
    }

    return null;
};

// Track Single Product
export const trackAddOns = (addOns) => {
    if (addOns) {
        const addOnsTrackingEvent = {
            addOns,
            action: 'track_addOns_data',
        };

        trackEvent(addOnsTrackingEvent);
    }
};

// Track Product List
export const trackProductListData = ({
    products,
    buildProductDataHandler,
    ...remainingProps
}) => {
    const productList = products || getProductListData({
        buildProductDataHandler,
        ...remainingProps,
    });

    trackEvent({
        products: productList,
        action: 'track_product_listing_data',
    });
};

export const trackData = (props = {}) => {
    const { action } = props;

    if (action) {
        trackEvent(props);
    }
};

const isTrackCommonPageSEO = (dataLayer) => {
    let seoData = false;
    dataLayer.forEach((entry) => {
        const { action } = entry;

        switch (action) {
            case 'track_seo_data':
                seoData = true;
                break;
            default:
                break;
        }
    });
    return seoData;
};

const isTrackCatPageSEO = (dataLayer) => {
    let categoryProducts = false;
    let seoData = false;

    dataLayer.forEach((entry) => {
        const { action } = entry;

        switch (action) {
            case 'track_seo_data':
                seoData = true;
                break;
            case 'track_product_listing_data':
                categoryProducts = true;
                break;
            default:
                break;
        }
    });

    return (seoData && categoryProducts);
};

const isTrackProductPageSEO = (dataLayer) => {
    let product = false;
    let seoData = false;

    dataLayer.forEach((entry) => {
        const { action } = entry;

        switch (action) {
            case 'track_seo_data':
                seoData = true;
                break;
            case 'track_product_data':
                product = true;
                break;
            default:
                break;
        }
    });

    return (seoData && product);
};

export const coreBrowsingPageViewSuccessTest = (props = {}) => {
    const { currentPage, dataLayer } = props;

    if (!currentPage || !dataLayer) {
        return false;
    }

    // Home Page, Search, Content
    if (currentPage.type === 'home' || currentPage.type === 'search' || currentPage.type === 'content' || currentPage.type === 'content-page') {
        return isTrackCommonPageSEO(dataLayer);
    }

    // Category
    if (currentPage.type === 'category') {
        return isTrackCatPageSEO(dataLayer);
    }

    // Product
    if (currentPage.type === 'product') {
        return isTrackProductPageSEO(dataLayer);
    }

    return false;
};

export const checkoutPageViewSuccessTest = (props = {}) => {
    let orderItems = false;
    const { dataLayer } = props;
    if (!dataLayer) {
        return false;
    }

    dataLayer.forEach((entry) => {
        const { action } = entry;

        switch (action) {
            case 'track_order_data':
                orderItems = true;
                break;
            default:
                break;
        }
    });

    return (orderItems);
};
