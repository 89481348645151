/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const SEND_ESHOP_CHECKOUT_DATA = 'SEND_ESHOP_CHECKOUT_DATA';
const ESHOP_SUCCESS = 'ESHOP_SUCCESS';

export default {
    SEND_ESHOP_CHECKOUT_DATA,
    ESHOP_SUCCESS,
};
