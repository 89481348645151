/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './GiftMessageForm-Actions';
import initialState from '../../../../initialState';

const giftMessageReducer = (state = initialState.recipient.giftMessage, action) => {
    switch (action.type) {
        case actions.updateGiftMessage().type:
            return { ...state, ...action.data };
        case actions.showGiftMessageForm().type:
            return { ...state, showGiftMessageForm: true };
        case actions.loadGiftMessageChange().type:
            return { ...state, message: action.data };
        case actions.loadAuthorChange().type:
            return { ...state, author: action.data };
        case actions.updateFormStatus().type:
            return { ...state, isFormValid: action.data };
        case actions.updateOccasion().type:
            return { ...state, occasion: action.data };
        case actions.skipGiftMessageForm().type:
            return {
                occasion: '',
                message: 'No Message',
                author: '',
                isFormValid: true,
                isOrderConfirmation: false,
                showGiftMessageContainer: true,
                showGiftMessageForm: false,
            };
        case actions.resetGiftMessageForm().type:
            return {
                occasion: '',
                message: '',
                author: '',
                isFormValid: true,
                isOrderConfirmation: false,
                showGiftMessageContainer: true,
                showGiftMessageForm: true,
            };
        default: {
            return state;
        }
    }
};

export default giftMessageReducer;
