/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    fork, take, call, put, select,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';

import { duck as tmDuck } from '../../../../../TagManager';
import * as actionTypes from './Error-ActionTypes';
import { loadError, loggedErrorKeys } from './Error-Actions';
import ErrCodeFieldMapping from './constant/ErrCodeMap';
import { ErrTextMapping, ErrMessageForErrorKeyMapping } from './constant/ErrTextMap';
import { getCustomerCare } from '../../Common-Selectors';
import { checkJWT } from '../../../../../Member/ducks/Auth/Auth-Operations';
import { logApi } from '../../../../../../../apis/checkout-apis/logApi';

export const arrErrorCodes = [401, 403, 404, 500, 501, 503, 504];

function* GenericErrorMessage() {
    const phoneNo = yield select(getCustomerCare);
    const contactLink = `<a style="color:#f44336" href="tel:'${phoneNo}'">${phoneNo}</a>`;
    return `We are sorry, there seems to be a technical issue, please try again later or call our customer service (${contactLink}) for help. We're here for you!`;
}

const getErrorCodeFieldMapping = (arrError, groupKey, prefixStr) => {
    const objError = {};
    let arrErrorKeys = [];
    arrError.forEach((err) => {
        let { errorKey } = err;
        let { errorMessage } = err;
        errorKey = errorKey.trim();
        errorMessage = errorMessage.trim();
        // Overwrite Error Message having error key as error text
        if (ErrTextMapping[errorMessage]) {
            errorMessage = ErrTextMapping[errorMessage];
        }
        // Overwite Error Message for a specif error key
        if (ErrMessageForErrorKeyMapping[groupKey]) {
            errorMessage = ErrMessageForErrorKeyMapping[groupKey];
        }
        const errGroupKey = groupKey.trim();
        arrErrorKeys = arrErrorKeys.concat(errorKey);
        if (ErrCodeFieldMapping[errGroupKey]) {
            const errField = ErrCodeFieldMapping[errGroupKey][errorKey];
            if (errField) {
                if (typeof errField === 'string') {
                    objError[errField] = errorMessage;
                } else {
                    errField.forEach((errFieldItem) => {
                        const errFieldName = (errFieldItem.indexOf('_') === 0) ? `${prefixStr}${errFieldItem}` : errFieldItem;
                        objError[errFieldName] = errorMessage;
                    });
                }
            } else if (Number.isInteger((parseInt(errorKey, 10)))) {
                objError[errorKey] = errorMessage;
            } else {
                objError.error = errorMessage;
            }
        } else {
            objError.error = errorMessage;
        }
    });
    return {
        objError,
        arrErrorKeys,
    };
};

const errorCodeParser = (errorMsgs) => {
    const arrErrorMsg = errorMsgs.replace(', ', ' ').replace('{', '').replace('}', '').split(',');
    const arrOfErrors = [];
    if (arrErrorMsg.length > 0) {
        arrErrorMsg.forEach((err) => {
            const objError = {};
            const tmpErr = err.split('=');
            if (tmpErr.length === 2) {
                objError.errorKey = tmpErr[0].trim();
                const errorMessage = tmpErr[1].trim();
                objError.errorMessage = errorMessage;
            } else {
                objError.errorKey = 'error';
                const errorMessage = err;
                objError.errorMessage = errorMessage;
            }
            arrOfErrors.push(objError);
        });
    }
    return arrOfErrors;
};

const getArrErrMsg = (detailedError) => {
    let arrErrMsg;
    if (detailedError?.validationErrors
        && Array.isArray(detailedError.validationErrors)
        && detailedError.validationErrors.length > 0) {
        arrErrMsg = detailedError.validationErrors;
    } else {
        arrErrMsg = errorCodeParser(detailedError.errorMessage);
    }
    return arrErrMsg;
};

const getPromotionErrorMsg = (detailedError) => {
    const errMessage = {};
    const promoMoreInfoList = [];
    const promoErrorMessage = detailedError?.errorMessage || '';
    const promoErrorList = detailedError?.validationErrors || [];

    if (promoErrorMessage) {
        errMessage.error = promoErrorMessage;
    }

    if (promoErrorList.length > 0) {
        promoErrorList.forEach((errorDetails) => {
            if (errorDetails.errorMessage) {
                promoMoreInfoList.push(errorDetails.errorMessage);
            }
        });
    }
    errMessage.errorMoreInfoList = promoMoreInfoList;
    return errMessage;
};

export function* getError(action, pageSection) {
    try {
        let arrErrMsg = [];
        let errCodeGroupKey = '';
        let actionData = action;
        if (Object.prototype.hasOwnProperty.call(action, 'response')) {
            actionData = action.response;
        }
        if ((Object.prototype.hasOwnProperty.call(actionData, 'status')) && (arrErrorCodes.indexOf(actionData.status) >= 0)) {
            const genricError = yield call(GenericErrorMessage);
            arrErrMsg[0] = {
                errorKey: 'error',
                errorMessage: genricError,
            };
        } else if (actionData?.data?.response === 'Error') {
            arrErrMsg = getArrErrMsg(actionData?.data?.detailedError);
            errCodeGroupKey = actionData.data.detailedError.errorKey;
            // LogAPI
            const token = yield call(checkJWT);
            try {
                yield call(logApi, {
                    token,
                    name: actionData?.config?.url,
                    request: actionData?.config,
                    response: actionData?.status,
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            arrErrMsg[0] = {
                errorKey: 'error',
                errorMessage: actionData?.data?.detailedError?.errorMessage || '',
            };
        }

        if (errCodeGroupKey === 'ERROR_CART_SUBMIT_REQUEST' && actionData?.data?.detailedError?.errorMessage) {
            arrErrMsg.push({ errorKey: 'detailedError', errorMessage: actionData?.data?.detailedError?.errorMessage });
        }

        const { orderItemId } = action;
        let errMessageObject = {};
        let errMessage;
        switch (pageSection) {
            case 'apiError':
                errMessage = { error: 'Sorry your order could not be completed, please try again.' };
                break;
            case 'recipientForm':
            case 'payment':
                errMessageObject = getErrorCodeFieldMapping(arrErrMsg, errCodeGroupKey, orderItemId);
                errMessage = errMessageObject.objError;
                if (errMessage?.error === '') {
                    errMessage = { error: 'Please review your credit card information.' };
                }
                break;
            case 'promotion':
                errMessage = getPromotionErrorMsg(actionData?.data?.detailedError);
                break;
            default:
                errMessageObject = getErrorCodeFieldMapping(arrErrMsg, errCodeGroupKey, orderItemId);
                errMessage = errMessageObject.objError.error;
                break;
        }

        // Track Error [Tealium]
        const errorEventObj = {
            eventCategory: 'Single Page Checkout',
            eventAction: 'Error',
            eventLabel: arrErrMsg[0].errorMessage,
        };
        yield put(tmDuck.actions.trackEvent(errorEventObj));

        yield put(loadError({
            errType: pageSection,
            errMsg: errMessage,
        }));

        yield put(loggedErrorKeys(errMessageObject.arrErrorKeys));

        mbpLogger.logError({
            pageSection,
            function: 'getError',
            module: 'mbp-checkout',
            message: errMessage,
        });
    } catch (ex) {
        const genricError = yield call(GenericErrorMessage);
        yield put(loadError({
            errType: 'error',
            errMsg: genricError,
        }));
    }
}

function* errorWatcherSaga() {
    let action = yield take(actionTypes.GET_ERROR);
    while (action !== END) {
        yield call(getError, action.data.errMsg, action.data.errType);
        action = yield take(actionTypes.GET_ERROR);
    }
}

const watchers = [
    fork(errorWatcherSaga),
];

export { watchers, GenericErrorMessage };

export default {};
