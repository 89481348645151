/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable */
import '@babel/polyfill';
import 'custom-event-polyfill';

// To debug IE 11 youneed ie11 and stable
// need install the package "react-app-polyfill"
// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";

import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
// import Loadable from 'react-loadable';
import { loadableReady } from '@loadable/component';
import { ApolloProvider } from 'react-apollo';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';

import ErrorBoundary from './app/components/ErrorBoundary/ErrorBoundary';
import PageErrorBoundaryUI from './app/components/ErrorBoundary/components/AppErrorBoundaryUI/AppErrorBoundaryUI';

import App from './app/App';
import rootSaga from './state/sagas';
import configureStore, { configureHistory } from './state/store';
import registerServiceWorker from './registerServiceWorker';
import buildGraphQLClient from './app/gql';
import LocaleProvider from './app/helpers/hooks/LocaleProvider';
import { AuthProvider } from './state/ducks/Member/ducks/Auth/Plugin/Auth';
import ScrollToTop from './app/components/GraphqlComponents/GraphqlCommonComponents/ScrollToTop';

const history = configureHistory();
const store = configureStore(window.__INITIAL_STATE__);
const persistor = persistStore(store);

// Delete initial state object
if (window.__INITIAL_STATE__) {
  delete window.__INITIAL_STATE__;
}

store.runSaga(rootSaga);

const apolloState = (typeof window !== 'undefined' && window.__APOLLO_STATE__) ? window.__APOLLO_STATE__ : null;

// Delete initial Apollo state object
if (typeof window !== 'undefined' && window.__APOLLO_STATE__) {
    delete window.__APOLLO_STATE__;
}

const { apolloClient, getSurrogateKeys } = buildGraphQLClient({
  initialState: apolloState,
});

// Create a new MUI class name generator.
const generateClassName = createGenerateClassName({
  productionPrefix: 'mbp',
});
const root = document.querySelector('#root');
const onBeforeLift = () => ({});

const RootComp = () => (
  <ApolloProvider injectFirst client={apolloClient}>
    <LocaleProvider history={history}>
      <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
              <BrowserRouter history={history}>
                <ErrorBoundary
                    boundaryName="App Page Error Boundary"
                    fallback={() => <AppErrorBoundaryUI />}
                >
                  <AuthProvider>
                    <StylesProvider generateClassName={generateClassName}>
                      <ScrollToTop>
                        <App />
                      </ScrollToTop>
                    </StylesProvider>
                  </AuthProvider>
                </ErrorBoundary>
              </BrowserRouter>
          </PersistGate>
      </ReduxProvider>
    </LocaleProvider>
  </ApolloProvider>
);

// Hydrate App in React w/ Redux
if (root.hasChildNodes() === true) {
    window.main = () => {
      loadableReady(() => {
        hydrate(<RootComp />, root);
        registerServiceWorker();
      });
    }
} else {
    render(<RootComp />, root);
}
