/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import mbpLogger from 'mbp-logger';

export const getBaseCode = (productCode, skuWithAlpha = false) => {
    try {
        let baseCode = productCode;
        if (productCode?.indexOf?.('_') !== -1) {
            const productCodeArr = productCode.split('_');
            if (productCodeArr.length > 2) {
                [, , baseCode] = productCodeArr;
            } else if (productCodeArr.length <= 2) {
                [, baseCode] = productCodeArr;
            }
        } else if (productCode?.indexOf?.('-') !== -1) {
            const productCodeArr = productCode.split('-');
            if (productCodeArr.length > 2) {
                // if product id is 1019-I-6X, it will return 6X
                [, , baseCode] = productCodeArr;
            } else if (productCodeArr.length <= 2) {
                // if product id is 1002-P153345, it will return P153345
                [, baseCode] = productCodeArr;
            }
        }
        if (baseCode) {
            // if string has numeric digit
            if (/\d/.test(baseCode) && baseCode.replace(/[A-Za-z]*$/, '') !== '' && !skuWithAlpha) {
                return baseCode.replace(/[A-Za-z]*$/, '');
            }
            // return SYFILETTRIM for 1018-P-SYFILETTRIM sku
            return baseCode;
        }
    } catch (ex) {
        mbpLogger.logError({
            productCode,
            function: 'getBaseCode',
            appName: process.env.npm_package_name,
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to get product base code.',
        });
    }

    return null;
};

export const getBrandId = (productCode) => {
    try {
        if (productCode) {
            if (productCode.substring(0, productCode.indexOf('-'))) {
                return productCode.substring(0, productCode.indexOf('-'));
            }

            return productCode.substring(0, productCode.indexOf('_'));
        }
    } catch (ex) {
        mbpLogger.logError({
            productCode,
            function: 'getBrand',
            appName: process.env.npm_package_name,
            module: 'tracking',
            jsError: ex,
            message: 'Error getting product brand.',
        });
    }

    return null;
};

export const getPrice = (product) => {
    let prices = null;

    if (product) {
        // Category/Search
        if (product.skuPriceRange) {
            prices = product.skuPriceRange;

            if (prices) {
                const { retail } = prices;

                // Return minimum retail price
                if (retail && retail.length) {
                    return `${retail[0].value}`;
                }
            }
        }

        // PDP
        if (product.productSkus) {
            prices =  (product.productSkus[0] && product.productSkus[0].prices) ? product.productSkus[0].prices : null;

            if (prices) {
                const [retail] = prices;

                // Return minimum retail price
                if (retail && retail.value) {
                    return `${retail.value}`;
                }
            }
        }

        // Add Ons
        if (product.prices && product.prices.length) {
            if (product.prices.length > 1) { // Both retail and sales fields present
                const [sale, retail] = product.prices;

                // Not on sale
                if (sale.value === retail.value) {
                    return `${retail.value}`;
                }

                // Sale
                if (sale.value !== retail.value) {
                    return `${sale.value}`;
                }
            }

            // Retail
            return `${product.prices[0].value}`;
        }

        // Checkout
        if (product.price) {
            return product.price;
        }
    }

    return null;
};

export const isValidProduct = (product) => {
    const REQUIRED_FIELDS = [
        'partNumber',
        'name',
    ];
    let valid = true;

    REQUIRED_FIELDS.forEach(
        (field) => {
            if (!product[field]) {
                valid = false;
            }
        },
    );

    return valid;
};

export const productIdWithBaseCode = (products) => {
    if (products?.length) {
        const allProducts = [];
        (products || []).forEach((item) => {
            const productItem = item;
            if (productItem.partNumber) {
                productItem.partNumber = getBaseCode(productItem.partNumber);
            }
            const pSkus = [];
            if (productItem?.sKUs?.length > 0) {
                (productItem.sKUs || []).forEach((skuItem) => {
                    const sku = skuItem;
                    if (sku.partNumber) {
                        sku.partNumber = getBaseCode(sku.partNumber);
                    }
                    pSkus.push(sku);
                });
                productItem.sKUs = pSkus;
            }
            allProducts.push(productItem);
        });
        return allProducts;
    }
    return [];
};

/**
 * Extracts the variant id with size.
 * @param {String} productCode Variant ID of selected product
*/
export const getPlantsLegacyVariantId = (productCode) => {
    try {
        if (productCode) {
            if (productCode.indexOf('-') !== -1) {
                const splittedId = productCode.split('-');
                return splittedId[1];
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            productCode,
            function: 'getPlantsLegacyVariantId',
            module: 'mbp-page',
            jsError: ex,
            message: 'Failed to get a variant id.',
        });
    }
    return null;
};
