/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import { GetCardType } from './Helper/Helper';

const prettyCard = (cardNum, cardLength, cardInfo) => {
    let cardNumber = cardNum;
    const card = cardInfo || null;
    if (card) {
        cardNumber = cardNumber.substring(0, cardLength);
        const offsets = [].concat(0, card.gaps, cardNumber.length);
        const components = [];
        for (let i = 0; offsets[i] < cardNumber.length; i += 1) {
            const start = offsets[i];
            const end = Math.min(offsets[i + 1], cardNumber.length);
            components.push(cardNumber.substring(start, end));
        }
        return components.join('-');
    }
    return cardNumber;
};

// format number 1000000 to 1,234,567
const formatNumber = (value) => {
    const formatAmount = value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formatAmount;
};

const MaskInput = {
    CreditCard: (val, props) => {
        const { min } = props;
        const cardNumber = val.split('-').join('');
        const cardType = GetCardType(cardNumber);

        // Look according to the credit card type the length of it.
        let cardLength = min;
        if (cardType && cardType.len) {
            cardLength = cardType.len;
        }

        const prettyCardNumber = prettyCard(cardNumber, cardLength, cardType);
        const unPrettyCard = prettyCardNumber.split('-').join('');
        let allow = false;
        if (cardNumber === unPrettyCard) {
            allow = true;
        }
        return {
            maskInputVal: prettyCardNumber,
            allow,
        };
    },
    Phone: (phone) => {
        const V1 = /^\((\d\d\d)\)\s$/; // the phone come with (201), dont need continue the process
        const V2 = /^\((\d\d\d)\)\s(\d\d\d)-$/; // the phone come with (201) 235-, dont need continue the process
        // start transform the code
        if (V1.test(phone)) {
            return {
                maskInputVal: String(phone).replace(V1, '$1'),
                allow: true,
            };
        }
        if (V2.test(phone)) {
            return {
                maskInputVal: String(phone).replace(V2, '($1) $2'),
                allow: true,
            };
        }

        let phoneNumber = String(phone).replace(/[^0-9]+/g, '');

        if (phoneNumber.length > 10) {
            // International numbers
            phoneNumber = phoneNumber.replace(/^(\d\d)(\d{3})(\d{3})(\d{0,4}).*/, '$1 $2 $3 $4');
        } else if (phoneNumber.length > 5) {
            // big to 5 digit do the form is it is not already there 201123435 -> (201) 123-435
            phoneNumber = phoneNumber.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, '($1) $2-$3');
        } else if (phoneNumber.length > 3) {
            // bit to 3 digit do the form is it is not already there 20123 -> (201) 23
            phoneNumber = phoneNumber.replace(/^(\d\d\d)(\d{0,4})/, '($1) $2');
        } else {
            // bit to 1 digit do the form is it is not already there 201 -> (201)
            phoneNumber = phoneNumber.replace(/^(\d*)/, '$1');
        }

        return {
            maskInputVal: phoneNumber,
            allow: true,
        };
    },
    Amount: (value) => {
        let amount = String(value);

        // don't validate empty input
        if (amount === '') {
            return {
                maskInputVal: amount,
                allow: true,
            };
        }

        // check for decimal
        if (amount.indexOf('.') >= 0) {
            // get position of first decimal
            // this prevents multiple decimals from
            // being entered
            const decimalPos = amount.indexOf('.');

            // split number by decimal point
            let leftSide = amount.substring(0, decimalPos);
            let rightSide = amount.substring(decimalPos);

            // add commas to left side of number
            leftSide = formatNumber(leftSide);

            // validate right side
            rightSide = formatNumber(rightSide);

            // Limit decimal to only 2 digits
            rightSide = rightSide.substring(0, 2);

            // join number by .
            amount = `${leftSide}.${rightSide}`;
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            amount = formatNumber(amount);
            amount = `${amount}`;
        }

        return {
            maskInputVal: amount,
            allow: true,
        };
    },
};

export default MaskInput;
