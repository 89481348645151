/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as orderActions from './Order-Actions';
import * as orderOperations from './Order-Operations';
import reducer from './Order-Reducer';

const orderWatcherSagas = [
    ...orderOperations.watchers,
];

export {
    orderOperations,
    orderActions,
    orderWatcherSagas,
};

export default reducer;
