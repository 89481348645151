/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
// import { dtwActions } from './index';
import { UPDATE_DTW_OPTIONS, UPDATE_SELECTED_DTW } from './DTW-ActionTypes';

const dtwInitalState = initialState.common.dtw;
const dtwReducer = (state = dtwInitalState, action) => {
    switch (action.type) {
        case UPDATE_DTW_OPTIONS:
            return {
                ...state,
                [action.data.orderItemId]: { dtwOptions: action.data },
            };
        case UPDATE_SELECTED_DTW:
            return {
                ...state,
                selectedDTW: action.data,
            };
        default:
            return state;
    }
};

export default dtwReducer;
