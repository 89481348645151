/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const CreditCardTypes = {
    VI: 'VISA',
    MC: 'MASTERCARD',
    DS: 'DISCOVER',
    AX: 'AMEX',
    PY: 'PAYPAL WALLET',
};

export default CreditCardTypes;
