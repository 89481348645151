/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import Months from '../state/constants/Months';

export const convertToYYYYMMDD = (date) => {
    if (date && /^\d{2}[-/]\d{2}[-/]\d{4}$/.test(date)) {
        return [
            date.slice(6, 10),
            '-',
            date.slice(0, 2),
            '-',
            date.slice(3, 5),
            'T00:00:00',
        ].join('');
    }
    return date;
};

export const dateMMMMDDYYYY = (
    dateStringOrObject,
    dataDictionary = {},
    nextYear = 0,
    nextMonth = 0,
    nextDay = 0,
) => {
    let date = dateStringOrObject;
    if (date && /^\d{2}[-/]\d{2}[-/]\d{4}$/.test(date)) {
        date = convertToYYYYMMDD(date);
    }
    // Accepting
    // 2020-05-04 00:00:00.0 / 2020-05-04T22:58:00.0
    // or
    // 2020-05-04 00:00:00 / 2020-05-04T22:58:00
    if (date && /^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(date)) {
        const dateObj = new Date(date.replace(/\s/, 'T'));
        const day = dateObj.getDate() + nextDay;
        const month = dateObj.getMonth() + nextMonth;
        const year = dateObj.getFullYear() + nextYear;
        const newDate = new Date(year, month, day);
        const newDay = newDate.getDate();
        const newMonth = dataDictionary?.[Months[newDate.getMonth()]] || Months[newDate.getMonth()];
        const newYear = newDate.getFullYear();
        return [newMonth, ' ', newDay, ', ', newYear].join('');
    }
    if (typeof date === 'object' && date.getTime()) {
        const day = date.getDate() + nextDay;
        const month = date.getMonth() + nextMonth;
        const year = date.getFullYear() + nextYear;
        const newDate = new Date(year, month, day);
        const newDay = newDate.getDate();
        const newMonth = dataDictionary?.[Months[newDate.getMonth()]] || Months[newDate.getMonth()];
        const newYear = newDate.getFullYear();
        return [newMonth, ' ', newDay, ', ', newYear].join('');
    }
    return date;
};

export const dateMMMMDD = (date) => {
    // Accepting
    // 2020-05-04 00:00:00.0 / 2020-05-04T22:58:00.0
    // or
    // 2020-05-04 00:00:00 / 2020-05-04T22:58:00
    if (date && /^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(date)) {
        const dateObj = new Date(date.replace(/\s/, 'T'));
        const day = dateObj.getDate();
        const month = Months[dateObj.getMonth()];
        return [month, ' ', day].join('');
    }
    return date;
};

export const dateMMDDYYYY = (date) => {
    // Accepting
    // 2020-05-04 00:00:00.0 / 2020-05-04T22:58:00.0
    // or
    // 2020-05-04 00:00:00 / 2020-05-04T22:58:00
    if (date && /^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(date)) {
        const currentDatetime = new Date(date.replace(/\s/, 'T'));
        const currentMonth = currentDatetime.getMonth() + 1;
        // Making month 2 digit format
        const getMonth = (currentMonth) < 10 ? `0${currentMonth}` : currentMonth;
        // Making date 2 digit format
        const getDate = currentDatetime.getDate() < 10 ? `0${currentDatetime.getDate().toString()}` : currentDatetime.getDate();
        const formattedDate = `${getMonth}-${getDate}-${currentDatetime.getFullYear()}`;
        return formattedDate;
    }
    return date;
};

export const MMDDYYYY = (date) => {
    if (date && /^\d{4}-\d{2}-\d{2}[\sT]\d{2}:\d{2}:\d{2}(\.0)?$/.test(date)) {
        const currentDatetime = new Date(date.replace(/\s/, 'T'));
        const currentMonth = currentDatetime.getMonth() + 1;
        // Making month 2 digit format
        const getMonth = (currentMonth) < 10 ? `0${currentMonth}` : currentMonth;
        // Making date 2 digit format
        const getDate = currentDatetime.getDate() < 10 ? `0${currentDatetime.getDate().toString()}` : currentDatetime.getDate();
        const formattedDate = `${getMonth}/${getDate}/${currentDatetime.getFullYear()}`;
        return formattedDate;
    }

    return date;
};

export const DMDD = (date, hideDay = false) => {
    const parsedDate = moment(date, ['MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'], true);
    if (!parsedDate.isValid()) {
        return 'Invalid Date';
    }
    const formattedDate = parsedDate.format(hideDay ? 'MMMM DD' : 'dddd, MMMM DD');
    return formattedDate;
};
