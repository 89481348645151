/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

export const placeCurrencyOrder = (data, jwt) => {
    const wcEnv = {
        rootUri: '/mbp-canada-checkout',
    };
    const resourcePath = '/payment/checkout';
    return restClient.postFacade(wcEnv, resourcePath, jwt, data, {})
        .then((response) => response, (error) => error);
};

export default {};
