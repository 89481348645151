/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const QUERY_QAS_ADDRESS_SEARCH = 'mbp-checkout/QUERY_QAS_ADDRESS_SEARCH';
export const LOAD_QAS_ADDRESS_SUGGESTION = 'mbp-checkout/LOAD_QAS_ADDRESS_SUGGESTION';
export const CLEAR_QAS_ADDRESS_SUGGESTION = 'mbp-checkout/CLEAR_QAS_ADDRESS_SUGGESTION';
export const GET_FORMAT_QAS_ADDRESS = 'mbp-checkout/GET_FORMAT_QAS_ADDRESS';
export const LOAD_FORATTED_QAS_ADDRESS = 'mbp-checkout/LOAD_FORATTED_QAS_ADDRESS';
export const CLEAR_FORATTED_QAS_ADDRESS = 'mbp-checkout/CLEAR_FORATTED_QAS_ADDRESS';
export const FLAG_ON_SHOW_VALIDATED_ADDRESS_SUGGESTION = 'mbp-checkout/FLAG_ON_SHOW_VALIDATED_ADDRESS_SUGGESTION';
export const FLAG_OFF_SHOW_VALIDATED_ADDRESS_SUGGESTION = 'mbp-checkout/FLAG_OFF_SHOW_VALIDATED_ADDRESS_SUGGESTION';
export const RESET_QAS_ADDRESS_STATE = 'mbp-checkout/RESET_QAS_ADDRESS_STATE';
export const KEEP_ADDRESS_QAS_VERIFICATION = 'mbp-checkout/KEEP_ADDRESS_QAS_VERIFICATION';
export const EDIT_ADDRESS_QAS_VERIFICATION = 'mbp-checkout/EDIT_ADDRESS_QAS_VERIFICATION';
export const SELECT_ADDRESS_QAS_VERIFICATION = 'mbp-checkout/SELECT_ADDRESS_QAS_VERIFICATION';
export const SAVE_BOOK_ADDRESS_PWA_DESKTOP = 'mbp-checkout/SAVE_BOOK_ADDRESS_PWA_DESKTOP';
export const SAVE_BOOK_ADDRESS_PWA_DESKTOP_COMPLETE = 'mbp-checkout/SAVE_BOOK_ADDRESS_PWA_DESKTOP_COMPLETE';
export const FLAG_ON_QAS_FAILED = 'mbp-checkout/FLAG_ON_QAS_FAILED';
