/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import axios from 'axios';
import mbpUtil from 'mbp-api-util';

const wcHost = mbpUtil.getEnv('APP_WC_HOST');
/* eslint-disable object-shorthand */
export const eshopSendOrder = (data, headers) => axios.post(`https://${wcHost}/r/api/mbp-0800-checkout/ESWCheckout/sendOrder`, { ...data }, { headers: headers }).then((res) => res);
export default {};
