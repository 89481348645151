/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    fork, take, call, put,
} from 'redux-saga/effects';

import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { completeChasePayCheckout } from './ChasePay-Actions';
import { errorActions } from '../../../Common/ducks/Error';
import { onPlaceOrder } from '../../Payment-Operations';

const invokeChasePayCheckout = (wcEnv, { jwtToken, orderId, params }) => orderClient.getChasePayCheckoutSession(
    wcEnv,
    jwtToken,
    orderId,
    params,
);

const processChasePayCheckoutOrder = (wcEnv, {
    jwtToken, orderId, sessionToken, params,
}) => orderClient.processChasePay(wcEnv, jwtToken, orderId, sessionToken, params);

function* onCompleteChasePayOrder(action) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.loadError({
            errType: 'error',
            errMsg: '',
        }));
        const { history } = action.data;
        const placeOrderConfig = {
            payload: {
                history,
                params: {},
                disablePaymentFields: false,
                source: 'PWA',
            },
        };
        yield call(onPlaceOrder, placeOrderConfig);
    } catch (ex) {
        yield put(errorActions.loadError({
            errType: 'error',
            errMsg: 'We are sorry, there seems to be a technical issue with ChasePay payment method, please try again later or use alternate payment method to complete order',
        }));
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            function: 'onCompleteChasePayOrder',
            jsError: ex,
            message: 'failed submitting chasepay checkout payment order',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* chasePayCompleteCheckoutWatcherSaga() {
    let action = yield take(completeChasePayCheckout().type);
    while (action !== END) {
        yield call(onCompleteChasePayOrder, action);
        action = yield take(completeChasePayCheckout().type);
    }
}

const watchers = [
    fork(chasePayCompleteCheckoutWatcherSaga),
];

export {
    watchers,

    invokeChasePayCheckout,
    processChasePayCheckoutOrder,
};
