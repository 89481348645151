/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './BillingAddressForm-Actions';
import initialState from '../../../../initialState';

const billingFormReducer = (state = initialState.payment.billingAddressForm, action) => {
    switch (action.type) {
        case actions.updateBillingForm().type:
            return { ...state, ...action.data };
        case actions.billingPrefillFormAddress().type: {
            const finaldata = {
                ...state,
                address1: action.data.address1 || '',
                address2: action.data.address2 || '',
                zipCode: action.data.zipCode || '',
                state: action.data.state || '',
                country: action.data.country || '',
                city: action.data.city || '',
                firstName: action.data.firstName || '',
                lastName: action.data.lastName || '',
                phone: action.data.phone || '',
                email: action.data.email || '',
                confirmEmail: action.data.confirmEmail || '',
                mobilePhone: action.data.mobilePhone || '',
            };
            return finaldata;
        }
        case actions.billingUpdateFormValue().type: {
            const formEvent = action.data;
            switch (formEvent.target.name) {
                case 'firstName':
                    return { ...state, firstName: formEvent.target.value };
                case 'lastName':
                    return { ...state, lastName: formEvent.target.value };
                case 'address1':
                    return { ...state, address1: formEvent.target.value };
                case 'address2':
                    return { ...state, address2: formEvent.target.value };
                case 'address3':
                    return { ...state, address3: formEvent.target.value };
                case 'city':
                    return { ...state, city: formEvent.target.value };
                case 'email':
                    return { ...state, email: formEvent.target.value };
                case 'confirmEmail':
                    return { ...state, confirmEmail: formEvent.target.value };
                case 'country':
                    return { ...state, country: formEvent.target.value };
                case 'organizationName':
                    return { ...state, organizationName: formEvent.target.value };
                case 'phone':
                    return { ...state, phone: formEvent.target.value };
                case 'state':
                    return { ...state, state: formEvent.target.value };
                case 'zipCode':
                    return { ...state, zipCode: formEvent.target.value };
                case 'mobilePhone':
                    return { ...state, mobilePhone: formEvent.target.value };
                case 'checkSameAsDeliveryAddress':
                    return { ...state, checkSameAsDeliveryAddress: formEvent.target.value };
                case 'cityArray':
                    return { ...state, cityArray: formEvent.target.value };
                /* case 'locationType':
                    return Object.assign({}, state, { locationType: formEvent.target.value });
                case 'addressId':
                    return Object.assign({}, state, { addressId: formEvent.target.value }); */
                default:
                    return state;
            }
        }
        case actions.billingClearUpdateFields().type:
            return {
                ...state,
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                address3: '',
                addressId: '',
                city: '',
                email: '',
                confirmEmail: '',
                country: '',
                locationType: '',
                organizationName: '',
                phone: '',
                state: '',
                zipCode: '',
                mobilePhone: '',
                isFormValid: false,
                isFormAutoFilled: false,
            };
        case actions.billingGetAddress().type:
            return state;
        case actions.updateBillingFormStatus().type:
            return { ...state, isFormValid: action.data };
        case actions.updateBillingFormAutoFilled().type:
            return { ...state, isFormAutoFilled: action.data };
        case actions.updateCheckBillingAddressSameForm().type:
            return { ...state, checkSameAsDeliveryAddress: action.data };
        default:
            return state;
    }
};

export default billingFormReducer;
