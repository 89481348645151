/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './CardIsle-ActionTypes';

export const resetCardIsle = () => ({
    type: actionTypes.CLEAR_CARD_ISLE_STATE,
});

export const loadCardIsle = (data) => ({
    type: actionTypes.LOAD_CARD_ISLE,
    data,
});

export const cardIsleAvailable = () => ({
    type: actionTypes.CARD_ISLE_AVAILABLE,
});

export const setCardIsleSelected = (data) => ({
    type: actionTypes.SET_CARD_ISLE_SELECTED,
    data,
});

export const addCardIsle = (orderItemId) => ({
    type: actionTypes.ADD_CARD_ISLE_SELECTED,
    orderItemId,
});

export const addCardIsleComplete = () => ({
    type: actionTypes.ADD_CARD_ISLE_SELECTED_COMPLETE,
});

export default {};
