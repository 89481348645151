/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    fork, call, select, take, put,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';
import * as actions from './VisaCheckout-Actions';
import * as commonSelector from '../../../Common/Common-Selectors';
import memberDucks from '../../../../../Member/ducks';
import { onPlaceOrder } from '../../Payment-Operations';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import { errorActions } from '../../../Common/ducks/Error';

const {
    auth: {
        authOperations,
    },
} = memberDucks;

const { checkJWT } = authOperations.workers;

const submitPaymentContext = ({
    wcEnv, jwtToken, orderId, visaCallId, encryptedKey, encryptedPaymentData, params,
}) => orderClient.processVisaCheckout(wcEnv, jwtToken, orderId, visaCallId, encryptedKey, encryptedPaymentData, params);

function* submitVisaCheckoutOrder(data) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.loadError({
            errType: 'error',
            errMsg: '',
        }));
        const { history } = data;
        const placeOrderConfig = {
            payload: {
                history,
                params: {},
                disablePaymentFields: false,
                source: 'PWA',
                visaCheckoutPayment: true,
            },
        };
        yield call(onPlaceOrder, placeOrderConfig);
    } catch (ex) {
        yield put(errorActions.loadError({
            errType: 'error',
            errMsg: 'We are sorry, there seems to be a technical issue with Visa Checkout payment method, please try again later or use alternate payment method to complete order',
        }));
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            function: 'submitVisaCheckoutOrder',
            jsError: ex,
            message: 'failed submitting visa checkout payment order',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* workerSubmitVisaCheckoutPaymentContext(action) {
    try {
        yield put(showLoadingMessageActions.updateShowLoadingMessage());
        yield put(errorActions.loadError({
            errType: 'error',
            errMsg: '',
        }));
        const { data } = action;
        const {
            callid: visaCallId,
            encKey: encryptedKey,
            encPaymentData: encryptedPaymentData,
        } = data.payment;
        const { history } = data;
        const orderId = yield select(commonSelector.getOrderId);
        const jwtToken = yield call(checkJWT);
        yield call(submitPaymentContext, {
            wcEnv: {},
            jwtToken,
            orderId,
            visaCallId,
            encryptedKey,
            encryptedPaymentData,
            params: {
                sourceApp: 'pwa',
            },
        });
        yield call(submitVisaCheckoutOrder, { history });
    } catch (ex) {
        yield put(errorActions.loadError({
            errType: 'error',
            errMsg: 'We are sorry, there seems to be a technical issue with Visa Checkout payment method, please try again later or use alternate payment method to complete order',
        }));
        mbpLogger.logError({
            module: 'mbp-pwa-ui',
            function: 'workerSubmitVisaCheckoutPaymentContext',
            jsError: ex,
            message: 'failed submitting visa checkout context',
        });
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* watcherSubmitVisaCheckoutPaymentContext() {
    let action = yield take(actions.submitVisaCheckoutPaymentContext().type);
    while (action !== END) {
        yield call(workerSubmitVisaCheckoutPaymentContext, action);
        action = yield take(actions.submitVisaCheckoutPaymentContext().type);
    }
}

const watchers = [
    fork(watcherSubmitVisaCheckoutPaymentContext),
];

export {
    watchers,
};

export default {};
