/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    takeEvery, call, fork, select, put,
} from 'redux-saga/effects';
import personClient from 'wc-api-person';
import mbpLogger from 'mbp-logger';
import mbpUtil from 'mbp-api-util';
import * as addressBookActions from './AddressBook-Actions';
import * as addressBookActionTypes from './AddressBook-ActionTypes';
import { commonSelectors } from '../Common';
import { authOperations } from '../Auth';

const { checkJWT } = authOperations.workers;
const TOTAL_ENTRIES_PER_PAGE = 200;

function* workerLoadAddressBook(action) {
    mbpLogger.logDebug({
        function: 'workerLoadAddressBook',
        module: 'mbp-member',
        message: 'Load Address Book',
    });
    try {
        let { pageNumber } = action.data;
        if (pageNumber === undefined)  {
            pageNumber = yield select(commonSelectors.getAddressBookPageNumber);
        }
        const contactId = yield select(commonSelectors.getContactId);
        if (contactId) {
            const accessToken = yield call(checkJWT);
            const wcEnv = {
                host: mbpUtil.getEnv('APP_WC_HOST'),
            };
            const response = yield call(personClient.getAddressBook, wcEnv, contactId, pageNumber, accessToken);
            if (response.data && response.data.Result) {
                const { AddressBookEntries, TotalEntries } = response.data.Result;
                const totalPages = Math.ceil(TotalEntries / TOTAL_ENTRIES_PER_PAGE);
                yield put(addressBookActions.saveAddressBook(AddressBookEntries, TotalEntries, pageNumber, totalPages));
            }
            yield put(addressBookActions.savedAddressBook());
        }
        yield put(addressBookActions.proccessAddressBookCompleted());
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerLoadAddressBook',
            module: 'mbp-member',
            jsError: ex,
            message: 'workerLoadAddressBook - Load Address Book Failed',
        });
        yield put(addressBookActions.proccessAddressBookCompleted());
    }
}

function* workerEditAddressBook(action) {
    mbpLogger.logDebug({
        function: 'workerEditAddressBook',
        module: 'mbp-member',
        message: 'Edit Address Book',
    });
    try {
        const { data } = action;
        const contactId = yield select(commonSelectors.getContactId);
        const accessToken = yield call(checkJWT);
        const wcEnv = {
            host: mbpUtil.getEnv('APP_WC_HOST'),
        };

        const response = yield call(personClient.editAddressBook, wcEnv, accessToken, contactId, data.addressBookEntry);

        if (response && response.data && response.data.StatusCode === 200) {
            const pageNumber = yield select(commonSelectors.getAddressBookPageNumber);
            const info = {
                data: {
                    pageNumber,
                },
            };
            yield call(workerLoadAddressBook, info);
        }
        yield put(addressBookActions.proccessAddressBookCompleted());
    } catch (ex) {
        mbpLogger.logError({
            action,
            function: 'workerLoadAddressBook',
            module: 'mbp-member',
            jsError: ex,
            message: 'workerEditAddressBook - Edit Address Book Failed',
        });
        yield put(addressBookActions.proccessAddressBookCompleted());
    }
}

function* workerAddAddressBook(action) {
    mbpLogger.logDebug({
        function: 'workerAddAddressBook',
        module: 'mbp-member',
        message: 'Add Address Book',
    });

    try {
        const { data } = action;
        const contactId = yield select(commonSelectors.getContactId);
        const accessToken = yield call(checkJWT);
        const wcEnv = {
            host: mbpUtil.getEnv('APP_WC_HOST'),
        };

        const response = yield call(personClient.registerNewAddressBook, wcEnv, accessToken, contactId, data.addressBookEntry);

        if (response && response.data && response.data.StatusCode === 200) {
            const pageNumber = yield select(commonSelectors.getAddressBookPageNumber);
            const info = {
                data: {
                    pageNumber,
                },
            };
            yield call(workerLoadAddressBook, info);
        }

        yield put(addressBookActions.proccessAddressBookCompleted());
    } catch (ex) {
        mbpLogger.logError({
            action,
            function: 'workerLoadAddressBook',
            module: 'mbp-member',
            jsError: ex,
            message: 'workerAddAddressBook - Edit Address Book Failed',
        });

        yield put(addressBookActions.proccessAddressBookCompleted());
    }
}

function* workerRemoveAddressBook(action) {
    mbpLogger.logDebug({
        function: 'workerRemoveAddressBook',
        module: 'mbp-member',
        message: 'Remove Address Book',
    });
    try {
        const { data } = action;
        const contactId = yield select(commonSelectors.getContactId);
        const accessToken = yield call(checkJWT);
        const wcEnv = {
            host: mbpUtil.getEnv('APP_WC_HOST'),
        };

        const response = yield call(personClient.removeAddressBook, wcEnv, contactId, data.addressBookEntryId, accessToken);

        if (response.status === 200) {
            const pageNumber = yield select(commonSelectors.getAddressBookPageNumber);
            const info = {
                data: {
                    pageNumber,
                },
            };
            yield call(workerLoadAddressBook, info);
        }
    } catch (ex) {
        mbpLogger.logError({
            action,
            function: 'workerLoadAddressBook',
            module: 'mbp-member',
            jsError: ex,
            message: 'workerRemoveAddressBook - Remove Address Book Failed',
        });
    }
}

function* workerLoadBillingAddress() {
    mbpLogger.logDebug({
        function: 'workerLoadBillingAddress',
        module: 'mbp-member',
        message: 'Load Billing Address',
    });
    try {
        const contactId = yield select(commonSelectors.getContactId);
        const accessToken = yield call(checkJWT);
        const wcEnv = {
            host: mbpUtil.getEnv('APP_WC_HOST'),
        };
        const response = yield call(personClient.getBillingAddress, wcEnv, contactId, accessToken);

        if (response.data && response.data.Result) {
            const { billingAddresses } = response.data.Result.billingAddress;
            yield put(addressBookActions.saveBillingAddress(billingAddresses));
        }
        yield put(addressBookActions.savedBillingAddress());
    } catch (ex) {
        mbpLogger.logError({
            function: 'workerLoadBillingAddress',
            module: 'mbp-member',
            jsError: ex,
            message: 'workerLoadBillingAddress - Load Billing Address Failed',
        });
    }
}

function* watcherLoadAddressBook() {
    yield takeEvery(addressBookActionTypes.LOAD_ADDRESS_BOOK, workerLoadAddressBook);
}

function* watcherAddAddressBook() {
    yield takeEvery(addressBookActions.addAddressBook().type, workerAddAddressBook);
}

function* watcherEditAddressBook() {
    yield takeEvery(addressBookActions.editAddressBook().type, workerEditAddressBook);
}

function* watcherRemoveAddressBook() {
    yield takeEvery(addressBookActionTypes.REMOVE_ADDRESS_BOOK, workerRemoveAddressBook);
}

function* watcherLoadBillingAddress() {
    yield takeEvery(addressBookActionTypes.LOAD_BILLING_ADDRESS, workerLoadBillingAddress);
}

const watchers = [
    fork(watcherLoadAddressBook),
    fork(watcherAddAddressBook),
    fork(watcherEditAddressBook),
    fork(watcherRemoveAddressBook),
    fork(watcherRemoveAddressBook),
    fork(watcherLoadBillingAddress),
];

const workers = {
    workerLoadBillingAddress,
    workerAddAddressBook,
    workerEditAddressBook,
    workerLoadAddressBook,
};

export default {
    watchers,
    workers,
};
