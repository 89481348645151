/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { createContext } from 'react';

const { Provider, Consumer } = createContext();
export { Provider, Consumer };
