/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export function isObject(item) {
    return (
        item
        && typeof item === 'object'
        && !Array.isArray(item)
        && item !== null
    );
}

export function isEmpty(item) {
    if (typeof item === 'undefined' || item === null) return true;
    // return Object.keys(item).length === 0 && item.constructor === Object;
    // return Object.keys(item).length === 0 && item.constructor === Array;
    return Object.keys(item).length === 0;
}

export function deepObjectExtend(target, source) {
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                deepObjectExtend(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        });
    }
    return target;
}

export const normalizeArrayToObject = (arr) => {
    const normalize = {};
    arr.forEach((item) => {
        normalize[[Object.keys(item)]] = item[[Object.keys(item)]];
    });
    return normalize;
};

export function orderBy(target, fieldName, order = 'asc') {
    target.sort((a, b) => {
        const x = a[fieldName];
        const y = b[fieldName];
        if (order === 'desc') {
            return x < y ? 1 : -1;
        }
        return x > y ? 1 : -1;
    });
    return target;
}
