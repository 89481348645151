/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import initialState from '../../../../initialState';
import * as actions from './Promotion-Action';

// eslint-disable-next-line consistent-return
const promotionReducer = (state = initialState.payment.promotion, action) => {
    switch (action.type) {
        case actions.updateFieldPromotionForm().type: {
            const formEvent = action.data;
            switch (formEvent.target.name) {
                case 'promotionCode':
                    return { ...state, promotionCode: formEvent.target.value };
                case 'membershipNumber':
                    return { ...state, membershipNumber: formEvent.target.value };
                default:
                    break;
            }
            break;
        }
        case actions.clearAllFieldPromotionForm().type:
            return initialState.payment.promotion;
        default:
            return state;
    }
    return null;
};

export default promotionReducer;
