/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actions from './Paypal-Actions';
import initialState from '../../../../initialState';

const paypalReducer = (state = initialState.payment.paypal, action) => {
    switch (action.type) {
        case actions.loadPaypalClientId().type:
            return {
                ...state,
                clientToken: action.data,
            };
        case actions.loadPaypalPayload().type:
            return {
                ...state,
                payload: action.data,
            };
        case actions.clearPaypalState().type:
            return {
                ...state,
                clientToken: '',
                payload: {},
                isBillingAddressMissingMandatoryFields: false,
            };
        case actions.updatePaypalBillingAddressMissingMandoryFields().type:
            return {
                ...state,
                isBillingAddressMissingMandatoryFields: action.data,
            };
        case actions.useAnotherPaypalAccount().type:
            return {
                ...state,
                changeAccount: action.data.change,
            };
        default:
            return state;
    }
};

export default paypalReducer;
