/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import mbpUtil from 'mbp-api-util';
import { removeMaskForPhoneNumber } from '../../../../Checkout-Helpers';

// Plugin Config
export const googlePayPluginConfig = {
    sdkURL: 'https://pay.google.com/gp/p/js/pay.js',
    environment: mbpUtil.getEnv('APP_GOOGLEPAY_ENV'),
    merchantId: mbpUtil.getEnv('APP_GOOGLEPAY_MERCHANT_ID'),
    merchantName: '1800flowersinc',
};

export function getPaymentClient(paymentClientConfig) {
    let paymentClient = null;
    if (typeof window !== 'undefined') {
        paymentClient = new window.google.payments.api.PaymentsClient(paymentClientConfig);
    }
    return paymentClient;
}

export const getIsGooglePaySupported = async () => {
    let isSupported = false;
    const paymentClient = getPaymentClient({ environment: googlePayPluginConfig.environment });
    if (paymentClient) {
        const response = await paymentClient.isReadyToPay({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                        allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
                    },
                },
            ],
        });
        if (response.result) {
            isSupported = true;
        }
    }
    return isSupported;
};

export function getGoogleTransactionInfo(orderSummary) {
    const { totalTax, orderTotalBeforeTaxes, orderTotalBalance } = orderSummary;
    return {
        displayItems: [
            {
                label: 'Total before tax',
                type: 'SUBTOTAL',
                price: `${orderTotalBeforeTaxes}`,
            },
            {
                label: 'Taxes',
                type: 'TAX',
                price: `${totalTax}`,
            },
        ],
        countryCode: 'US',
        currencyCode: 'USD',
        totalPriceStatus: 'FINAL',
        totalPrice: `${orderTotalBalance}`,
        totalPriceLabel: 'Order Total',
    };
}

/**
 * getfirstAndLastName function split the name by charater space
 */
function getfirstAndLastName(name) {
    const splitName = name.split(' ');
    const returnName = {
        firstName: splitName[0] || '.',
        lastName: '.',
    };
    if (splitName.length > 1) {
        splitName.shift();
        returnName.lastName = splitName.join(' ');
    }
    return returnName;
}

export const normalizeGooglePaymentBillingAddress = (paymentData) => {
    const address = paymentData.paymentMethodData.info.billingAddress;
    const emailId = paymentData.email;
    const phoneNumber = removeMaskForPhoneNumber(address.phoneNumber);
    const fullName = getfirstAndLastName(address.name);
    const firstName = fullName.firstName;
    const lastName = fullName.lastName;

    return {
        firstName,
        lastName,
        address1: address.address1,
        address2: address.address2,
        state: address.administrativeArea,
        city: address.locality,
        country: address.countryCode,
        email: emailId,
        confirmEmail: emailId,
        phone: phoneNumber,
        mobilePhone: phoneNumber,
        zipCode: address.postalCode,
    };
};

export default {};
