/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import commonReducers from './Common-Reducers';
import * as commonSelectors from './Common-Selectors';

// Ducks
import { errorActions, errorOperations } from './ducks/Error';
import { propertiesActions, propertiesOperations } from './ducks/Properties';
import { showLoadingMessageActions, showLoadingMessageOperations } from './ducks/ShowLoadingMessage';
import { qasAddressSearchActions, qasAddressSearchOperations } from './ducks/AddressSearchQAS';
import { dtwActions, dtwOperations } from './ducks/dtw';

const commonWatcherSagas = [
    ...errorOperations.watchers,
    // ...propertiesOperations.watchers,
    ...showLoadingMessageOperations.watchers,
    ...qasAddressSearchOperations.watchers,
    ...dtwOperations.watchers,
];

const commonDucks = {
    error: {
        errorActions,
        errorOperations,
    },
    properties: {
        propertiesActions,
        propertiesOperations,
    },
    showLoadingMessage: {
        showLoadingMessageActions,
        showLoadingMessageOperations,
    },
    qasAddressSearch: {
        qasAddressSearchActions,
        qasAddressSearchOperations,
    },
    dtw: {
        dtwActions,
        dtwOperations,
    },
};

export {
    commonWatcherSagas,
    commonSelectors,

    // Ducks
    commonDucks,
};

export default commonReducers;
