/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Passport-ActionTypes';

export const triggerPassportSubscription = (pageOn, passportSku) => ({
    type: actionTypes.TRIGGER_PASSPORT_SUBSCRIPTION,
    data: {
        pageOn,
        passportSku,
    },
});

export const triggerAddPassportByRemoveGiftCard = (pageOn) => ({
    type: actionTypes.TRIGGER_ADD_PASSPORT_BY_REMOVE_GIFTCARD,
    data: {
        pageOn,
    },
});

export const subscribePassport = (orderItemId) => ({
    type: actionTypes.SUBSCRIBE_PASSPORT,
    data: {
        orderItemId,
    },
});

export const setPassportData = (passportData) => ({
    type: actionTypes.SUBSCRIBE_PASSPORT_DATA,
    data: {
        passportData,
    },
});

export const unSubscribePassport = () => ({
    type: actionTypes.UNSUBSCRIBE_PASSPORT,
});

export const getPassportTncContent = () => ({
    type: actionTypes.GET_PASSPORT_TNC_CONTENT,
});

export const loadPassportTncContent = (tncContent) => ({
    type: actionTypes.LOAD_PASSPORT_TNC_CONTENT,
    data: {
        tncContent,
    },
});

export const showPassportModalToRemoveGC = () => ({
    type: actionTypes.SHOW_PASSPORT_MODAL_TO_REMOVE_GIFTCARD,
});

export const hidePassportModalToRemoveGC = () => ({
    type: actionTypes.HIDE_PASSPORT_MODAL_TO_REMOVE_GIFTCARD,
});

export const passportProcessStart = () => ({
    type: actionTypes.PASSPORT_PROCESS_START,
});

export const passportProcessEnd = () => ({
    type: actionTypes.PASSPORT_PROCESS_END,
});

export const getDynamicPassportCharge = () => ({
    type: actionTypes.GET_DYNAMIC_PASSPORT_CHARGE,
});

export const loadPassportDynamicCharge = (data) => ({
    type: actionTypes.LOAD_PASSPORT_DYNAMIC_CHARGE,
    data,
});

export const setPassportBundleData = (passportBundle) => ({
    type: actionTypes.PASSPORT_BUNDLE_DATA,
    data: {
        passportBundle,
    },
});
