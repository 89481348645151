/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    takeEvery, call, put, fork,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import personClient from 'wc-api-person';

import { setAccountMessage } from './Account-Actions';
import * as accountActionTypes from './Account-ActionTypes';
import { authOperations } from '../../../../../Member/ducks/Auth';

const { checkJWT } = authOperations.workers;

const createSignUp = ({
    JWT_TOKEN,
    accountObj,
}) => personClient.createAccountSignUp({}, JWT_TOKEN, accountObj);

function* accountSignUp(action) {
    try {
        const JWT_TOKEN = yield call(checkJWT);
        const { data } = action;
        const accountResult = yield call(createSignUp, {
            JWT_TOKEN,
            accountObj: data,
        });
        yield put(setAccountMessage(accountResult.data));
    } catch (ex) {
        mbpLogger.logError({
            function: 'accountRegister',
            module: 'mbp-pwa-ui',
            message: 'accountRegister - Error',
            jsError: ex,
        });
    }
}

function* watcherAccountRegister() {
    yield takeEvery(accountActionTypes.SET_ACCOUNT_REGISTER, accountSignUp);
}

const watchers = [
    fork(watcherAccountRegister),
];

export {
    watchers,
    accountSignUp,
};

export default {};
