/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/cart';
const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_ACTION = '/checkout';
/**
 * Place Order Service
 * Example input from Swagger
 *
 {
  "billingAddress": {
    "address1": "string",
    "address2": "string",
    "address3": "string",
    "addressId": "string",
    "city": "string",
    "confirmEmail": "string",
    "country": "string",
    "email": "string",
    "firstName": "string",
    "lastName": "string",
    "locationType": "string",
    "organizationName": "string",
    "phone": "string",
    "state": "string",
    "zipCode": "string"
  },
  "order": {
    "agentId": "string",
    "orderTotal": "string",
    "passportTermsAndConditionChecked": "string"
  },
  "payment": {
    "accountNumber": "string",
    "amount": "string",
    "cvv": "string",
    "expirationMonth": "string",
    "expirationYear": "string",
    "nameOnCreditCard": "string",
    "paymentMethod": "string",
    "savePaymentMethod": true
  },
  "user": {
    "confirmPassword": "string",
    "password": "string"
  }
 }
 *
 * @param {*} wcEnv
 * @param {*} jwtToken
 * @param {*} orderId
 * @param {*} billingAddress
 * @param {*} payment
 * @param {*} order
 * @param {*} user
 */

const placeOrder = (
    wcEnv, jwtToken, orderId, params, billingAddress,
    payment, order, user,
) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;

    const resourcePage = `${RESOURCE_PAGE_FACADE}/${orderId}${RESOURCE_PAGE_ACTION}`;

    const payload = {
        billingAddress,
        payment,
        order,
        user,
        ...params,
    };
    return restClient.postFacade(env, resourcePage, jwtToken, payload, {})
        .then((response) => response);
};

export default placeOrder;
