/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useTracking } from 'react-tracking';
import Cookies from 'universal-cookie';

export const handleDuplicateDataLayerEntry = (data) => {
    const dataLayer = (window.mbpDataLayer) ? window.mbpDataLayer : null;
    let duplicate = false;

    if (dataLayer) {
        dataLayer.forEach((entry) => {
            if (entry.action === data.action) { // Simple Comparison
                duplicate = true;
            }
        });
    }

    if (duplicate) {
        const tempDataLayer = window.mbpDataLayer.filter((entry) => (entry.action !== data.action));

        // Re-set data layer
        window.mbpDataLayer = tempDataLayer;
    }
};

export const validateDataLayer = (data) => {
    const { page } = data;
    let newPage = false;

    // Reset
    if (window.mbpDataLayer
        && (page && page.path)
    ) {
        window.mbpDataLayer.forEach((entry) => {
            if (entry.page && entry.page.path !== page.path) {
                newPage = true;
            }
        });

        if (newPage) {
            window.mbpDataLayer = [];
        }
    } else {
        // Init data layer object (GTM)
        window.dataLayer = window.dataLayer || [];
    }

    // Duplicates
    handleDuplicateDataLayerEntry(data);
};

export const updateDataLayer = (data) => {
    // Init data layer object (MBP)
    (window.mbpDataLayer = window.mbpDataLayer || []).push(data);
};

export const trackEvent = (data) => {
    const tracking = useTracking();

    tracking.trackEvent(data);
};

export const getPageDataFromLocation = (props) => {
    const { name, type } = props.pageProps;

    return {
        name,
        type,
        path: props.location?.pathname,
    };
};

export const getProductListData = (params) => {
    const {
        buildProductDataHandler,
    } = params;
    if (buildProductDataHandler) {
        const {
            data,
            path,
            giftBox,
            localFlorist,
        } = params;
        const { productData } = buildProductDataHandler({
            data,
            path,
            giftBox,
            localFlorist,
            icBannerBlock: {},
        });

        return productData;
    }

    return [];
};

// export const trackSearchProductListEventHandler = (props) => {
//     const {
//         products,
//     } = props;

//     return { products };
// };

export const trackPageHandler = (pageProps) => (props) => {
    const {
        page, location,
        categoryData, searchTerm,
    } = props;

    // Category
    if (categoryData
        && (
            (page && page.type === 'category') // TODO: popuplate page prop for category pages
            || (pageProps && pageProps.type === 'category') // Current - Manual
        )
    ) {
        const { category } = categoryData;

        return { category };
    }

    // Search
    if ((page && page.type === 'search') // TODO: popuplate page prop for category pages
        || (pageProps && pageProps.type === 'search') // Current - Manual
    ) {
        if (location) {
            return {
                searchTerm,
                page: getPageDataFromLocation({
                    location,
                    pageProps,
                }) || null,
            };
        }
    }

    // Default
    // No page prop, construct page object from location props and type param
    if (!page && (location && pageProps)) {
        return {
            page: getPageDataFromLocation({ location, pageProps }),
        };
    }

    return { page };
};

export const trackPassportData = (props = {}) => {
    const { bannerOnPage, passportData } = props;
    return {
        passportData: { page: bannerOnPage, ...passportData },
        action: 'track_passport_data',
    };
};

export const trackOrderDataHandler = (props = {}) => {
    const { order, cartDetails } = props;
    const cookies = new Cookies();
    const EVENT_ACTION = 'track_order_data';

    // Cart (Floral)
    if (cartDetails && Object.keys(cartDetails).length) {
        return {
            order: cartDetails?.orderDetails?.fdOrderVO,
            action: EVENT_ACTION,
        };
    }

    // The rest...
    if (order) {
        if (order?.orderIdWithSourceId && props.match?.url?.includes('/order-confirmation/')) {
            if (cookies.get('persisted_order_id') === order?.orderIdWithSourceId) return null;
            cookies.set('persisted_order_id', order?.orderIdWithSourceId, {
                path: '/',
                encode: String,
            });
        }
        return {
            order,
            action: EVENT_ACTION,
        };
    }

    return null;
};
