/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session'; // defaults to localStorage for web and AsyncStorage for react-native
import { combineReducers } from 'redux';
import * as paymentActions from './Payment-Actions';
import * as paymentOperations from './Payment-Operations';
import { paymentDetailsReducer, paymentMethodsReducer } from './Payment-Reducer';

import paymentFormReducer, { paymentFormActions } from './ducks/PaymentForm';
import billingAddressFormReducer, { billingAddressFormActions } from './ducks/BillingAddressForm';
import creditCardBrandReducer, { creditCardBrandOperations, creditCardBrandActions } from './ducks/CreditCardBrand';
import rewardPointsReducer, { rewardPointsOperations, rewardPointsActions } from './ducks/RewardPoints';
import subscriptionReducer, { subscriptionActions, subscriptionOperations } from './ducks/Subscription';
import accountReducer, { accountActions, accountOperations } from './ducks/Account';
import paymentReadinessReducer, { paymentReadinessActions } from './ducks/PaymentReadiness';
import promotionReducer, { promotionActions } from './ducks/Promotion';

// Passport
import passportReducer, { passportActions, passportOperations } from './ducks/Passport';

import * as payPalPlugin from './plugins/Paypal';
import * as chasePayPlugin from './plugins/ChasePay';
import * as visaCheckoutPlugin from './plugins/VisaCheckout';
import * as googlePayPlugin from './plugins/GooglePay';

// Watchers
const paymentWatcherSagas = [
    ...paymentOperations.watchers,

    // Payment Method Watchers
    ...creditCardBrandOperations.watchers,
    ...rewardPointsOperations.watchers,
    ...chasePayPlugin.chasePayOperations.watchers,
    ...payPalPlugin.paypalOperations.watchers,
    ...passportOperations.watchers,
    ...subscriptionOperations.watchers,
    ...accountOperations.watchers,
    ...visaCheckoutPlugin.visaCheckoutOperations.watchers,
    ...googlePayPlugin.googlePayOperations.watchers,
];

const paymentFormReducerPersistConfig = {
    key: 'paymentForm',
    storage: sessionStorage,
    whitelist: ['paymentMethod'],
};
const paymentFormPersistReducer = persistReducer(paymentFormReducerPersistConfig, paymentFormReducer);

// Reducer
const paymentReducers = combineReducers({
    // paymentForm: paymentFormReducer,
    paymentForm: paymentFormPersistReducer,
    paymentDetails: paymentDetailsReducer,
    paymentMethods: paymentMethodsReducer,
    billingAddressForm: billingAddressFormReducer,
    creditCardBrand: creditCardBrandReducer,
    rewardPoints: rewardPointsReducer,
    subscription: subscriptionReducer,
    account: accountReducer,
    paymentReadiness: paymentReadinessReducer,
    passport: passportReducer,
    promotion: promotionReducer,
    paypal: payPalPlugin.paypalReducer,
    googlePay: googlePayPlugin.googlePayReducer,
});

// Ducks
const paymentDucks = {
    paymentForm: {
        paymentFormActions,
    },
    billingAddressForm: {
        billingAddressFormActions,
    },
    creditCardBrand: {
        creditCardBrandActions,
        creditCardBrandOperations,
        creditCardBrandReducer,
    },
    rewardPoints: {
        rewardPointsActions,
        rewardPointsOperations,
        rewardPointsReducer,
    },
    subscription: {
        subscriptionActions,
        subscriptionOperations,
        subscriptionReducer,
    },
    account: {
        accountActions,
        accountOperations,
        accountReducer,
    },
    paymentReadiness: {
        paymentReadinessActions,
    },
    passport: {
        passportActions,
    },
    promotion: {
        promotionActions,
    },
};

// Plugins
const paymentPlugins = {
    PayPal: payPalPlugin,
    ChasePay: chasePayPlugin,
    VisaCheckout: visaCheckoutPlugin,
    GooglePay: googlePayPlugin,
};

export {
    paymentActions,
    paymentOperations,
    paymentWatcherSagas,
    paymentPlugins,
    paymentDucks,
};

export default paymentReducers;
