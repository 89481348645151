/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { END } from 'redux-saga';
import {
    put, fork, call, select, take,
} from 'redux-saga/effects';
import mbpLogger from 'mbp-logger';
import orderClient from '../../../../../../../apis/checkout-apis/orderClient';

import memberDucks from '../../../../../Member/ducks';
import { errorActions }  from '../../../Common/ducks/Error';
import { commonSelectors } from '../../../Common';
import {
    paypalGetClientId,
    logPaypalPaymentPayload,
    loadPaypalClientId,
    loadPaypalPayload,
    clearPaypalState,
    updatePaypalBillingAddressMissingMandoryFields,
} from './Paypal-Actions';
import {
    removeMaskForPhoneNumber,
    checkBillingAddressReturnedFromPaypal,
} from './Paypal-Helpers';
import { onPlaceOrder } from '../../Payment-Operations';
import { showLoadingMessageActions } from '../../../Common/ducks/ShowLoadingMessage';
import {
    billingPrefillFormAddress,
    updateBillingFormAutoFilled,
    updateBillingFormStatus,
} from '../../ducks/BillingAddressForm/BillingAddressForm-Actions';
import { getBrand } from '../../../../../App/ducks/Brand/Brand-Selectors';

const {
    auth: {
        authOperations: {
            workers: {
                checkJWT,
            },
        },
    },
} = memberDucks;

const getPaypalSessionClientToken = (config) => orderClient.getBraintreePayPalClientToken({}, config.jwtToken, config.orderId);
const getCustomPaypalSessionClientToken = (config) => orderClient.getPaymentClientToken({}, config.jwtToken, config.orderId);

const doUpdateBillingAddress = ({ JWT_TOKEN, orderId, billingAddress }) => orderClient.updateBillingAddress({}, JWT_TOKEN, orderId, billingAddress);

function* paypalGetClientIdWorker() {
    try {
        // show loader
        yield put(showLoadingMessageActions.updateShowLoadingMessage());

        // clear error
        yield put(errorActions.loadError({
            errType: 'paypal',
            errMsg: '',
        }));

        const jwtToken = yield call(checkJWT);
        const configObj = {
            jwtToken,
            orderId: yield select(commonSelectors.getOrderId),
        };
        const brand = yield select(getBrand);
        // Get Paypal Session Client Token
        let response = '';
        if (brand?.code === 'CAN') {
            response =  yield call(getPaypalSessionClientToken, configObj);
        } else {
            response =  yield call(getCustomPaypalSessionClientToken, configObj);
        }
        const { clientToken } = response.data;

        yield put(clearPaypalState());
        yield put(loadPaypalClientId(clientToken));

        // hide loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'paypalGetClientIdWorker',
            jsError: ex,
            message: 'Failed in getting paypal client id',
        });
        yield put(errorActions.loadError({
            errType: 'paypal',
            errMsg: 'We are sorry, there seems to be a technical issue with paypal payment method, please try again later or use alternate payment method to complete order',
        }));
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
    }
}

function* paypalGetClientIdWatcher() {
    let action = yield take(paypalGetClientId().type);
    while (action !== END) {
        yield call(paypalGetClientIdWorker, action);
        action = yield take(paypalGetClientId().type);
    }
}

function prepareBillingAddressFormData(payerInfo, billingForm) {
    const { billingAddress } = payerInfo;
    const phoneNo = payerInfo.phone ? removeMaskForPhoneNumber(payerInfo.phone) : '1234567891';
    const mobilePhoneNo = billingForm.mobilePhone ? removeMaskForPhoneNumber(billingForm.mobilePhone) : phoneNo;
    return {
        address1: billingAddress.line1 || '',
        address2: billingAddress.line2 || '',
        zipCode: billingAddress.postalCode || '',
        state: billingAddress.state || '',
        country: billingAddress.countryCode || '',
        city: billingAddress.city || '',
        firstName: payerInfo.firstName || '',
        lastName: payerInfo.lastName || '',
        phone: phoneNo,
        mobilePhone: mobilePhoneNo,
        email: payerInfo.email || '',
        confirmEmail: payerInfo.email || '',
    };
}

function* submitPaypal(action) {
    try {
        const { history, payload } = action.data;
        const { nonce } = payload;
        const placeOrderConfig = {
            payload: {
                history,
                params: {},
                disablePaymentFields: false,
                source: 'PWA',
                paypalPayment: {
                    nonce,
                },
            },
        };
        yield call(onPlaceOrder, placeOrderConfig);
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'submitPaypal',
            jsError: ex,
            message: 'Failed submitting paypal payment',
        });
    }
}

function* logPaypalPaymentPayloadWorker(action) {
    try {
        const { history, payload } = action.data;
        if (payload && payload.nonce) {
            const billingForm = yield select(commonSelectors.getBillingInfo);
            const isBillingAddressAvailable = yield call(checkBillingAddressReturnedFromPaypal, payload.details);
            const billingFormData = yield call(prepareBillingAddressFormData, payload.details, billingForm);
            // call cart-service for add billing address
            const orderId = yield select(commonSelectors.getOrderId);
            if (orderId) {
                const JWT_TOKEN = yield call(checkJWT);
                // save billing address
                const updateAddressResponse = yield call(doUpdateBillingAddress, {
                    JWT_TOKEN,
                    orderId,
                    billingAddress: billingFormData,
                });

                // success
                if (updateAddressResponse.data.successFlag) {
                    yield put(loadPaypalPayload(payload));
                    yield put(billingPrefillFormAddress(billingFormData));
                    yield put(updateBillingFormAutoFilled(true));

                    if (payload.pageType === 'cart') {
                        const logOrderId = yield select(commonSelectors.getOrderId);
                        history.push(`/checkout/shipping/${logOrderId}`);
                    } else if (isBillingAddressAvailable) {
                        yield put(updateBillingFormStatus(true));
                        yield put(updatePaypalBillingAddressMissingMandoryFields(false));
                        yield call(submitPaypal, action);
                    } else {
                        yield put(updateBillingFormStatus(false));
                        yield put(updatePaypalBillingAddressMissingMandoryFields(true));
                    }
                }
            }
        }
    } catch (ex) {
        mbpLogger.logError({
            module: 'mbp-checkout',
            function: 'logPaypalPaymentPayloadWorker',
            jsError: ex,
            message: 'Failed in loading paypal payment payload',
        });
    }
}

function* logPaypalPaymentPayloadWatcher() {
    let action = yield take(logPaypalPaymentPayload().type);
    while (action !== END) {
        yield call(logPaypalPaymentPayloadWorker, action);
        action = yield take(logPaypalPaymentPayload().type);
    }
}

const watchers = [
    fork(paypalGetClientIdWatcher),
    fork(logPaypalPaymentPayloadWatcher),
];

export {
    watchers,
};

export default {};
