/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import restClient from '../../app/helpers/restClient';

export const logApi = async ({
    token, name, request, response,
}) => {
    const payload = { name, request, response };
    const wcEnv = { rootUri: '/' };
    const resourcePage = 'mbp-canada-checkout/payment/logapi';
    const res = await restClient.postFacade(wcEnv, resourcePage, token, payload);
    delete res.headers;
    delete res.request;
    delete res.config;
    return {
        data: { res },
    };
};
export default {};
