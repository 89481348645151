/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export const UPDATE_GIFT_MESSAGE = 'UPDATE_GIFT_MESSAGE';
export const GIFT_MESSAGE_UPDATED_SUCCESSFULLY = 'GIFT_MESSAGE_UPDATED_SUCCESSFULLY';
export const TRIGGER_ADD_GIFT_MESSAGE = 'three-step-checkout/POST/ADD_GIFT_MESSAGE';
export const HANDLE_GIFT_MESSAGE_CHANGE = 'three-step-checkout/HANDLE_GIFT_MESSAGE_CHANGE';
export const LOAD_GIFT_MESSAGE_CHANGE = 'three-step-checkout/LOAD_GIFT_MESSAGE_CHANGE';
export const HANDLE_AUTHOR_CHANGE = 'three-step-checkout/HANDLE_AUTHOR_CHANGE';
export const TRIGGER_CHANGE_GIFT_MESSAGE = 'three-step-checkout/TRIGGER_CHANGE_GIFT_MESSAGE';
export const UPDATE_FORM_STATUS = 'three-step-checkout/UPDATE_FORM_STATUS';
export const UPDATE_OCCASSION = 'three-step-checkout/UPDATE_OCCASSION';
export const SHOW_GIFT_MESSAGE_FORM = 'three-step-checkout/SHOW_GIFT_MESSAGE_FORM';
export const TRIGGER_SKIP_MESSAGE = 'three-step-checkout/TRIGGER_SKIP_MESSAGE';
export const TRIGGER_RESET_MESSAGE = 'three-step-checkout/TRIGGER_RESET_MESSAGE';
export const TRIGGER_GCI_PERSONALIZATION_COMPLETE = 'three-step-checkout/TRIGGER_GCI_PERSONALIZATION_COMPLETE';
