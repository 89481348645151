/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import restClient from '../../app/helpers/restClient';

const RESOURCE_PAGE_FACADE = '/zipcode-validation';
const RESOURCE_ROOT_FACADE = '/checkout';
const RESOURCE_PAGE_ACTION = '/validate?zipCode=';

const getCityStateFromZip = (wcEnv, jwtToken, zipCode) => {
    const env = wcEnv;
    env.rootUri = RESOURCE_ROOT_FACADE;
    const resourcePage = RESOURCE_PAGE_FACADE + RESOURCE_PAGE_ACTION + zipCode;

    return restClient.getFacade(env, resourcePage, jwtToken)
        .then((response) => response);
};

export default getCityStateFromZip;
