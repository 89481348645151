/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const brandsConfig = {
    '18F': {
        id: '1001',
        code: '18F',
        domain: '1800flowers', // brand's web domain - string in the middle of  https://www.1800flowers.com
        storeId: 20051, // brand's unique store ID
        catalogId: 13302, // brands have many catalogs,but web has one unique catalog
        description: '1-800-Flowers', // simple description of brand used for alt text
        giftMessageConfig: {
            giftMessageMaxLines: 5,
            giftMessageMaxChars: 35,
            excludeFromTextLabel: false,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        typography: {
            fontFamily: [
                'Roboto',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},

        overrides: {},

        presentation_family: 'flower',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5f3c86',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
                gray: '#707070',
                lightGray: '#f0f0f0',
                darkGray: '#7B7B7B',
                lightPurple: '#F4F2F7',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
                countryCard: '#F4F2F8',
                buttonGreen: '#00C876',
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#65388b', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants
            colorNeutral91: '#F4F2F8',
            colorNeutral92: '#e8e8e8',
            colorNeutral93: '#e6e6e6',

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorAlert: '#f71548',
            contentColor: '#666666',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',
            categoryCarousalBorder: '#e2e2e2',

            black: '#1f1f1b',
            white: '#fff',
            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#f2f2f2',
            bgHeader: '#65388b',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            disableButton: '#d8d8d8',
            footerLinks: '#683D8E',
            gfBgColor: '#9872bb',
            OBHeadingColor: '#663399',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            select: '#646464',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productTitleColor: '#1f1f1b',
            productAvailibilityPDP: '#a1001a',
            sectionHeaderTextColor: '#212121',
            secondaryButton: '#324765',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                cardBorder: '#BFBFBF',
                fromValue: '#868484',
                radioButton: '#693388',
                selectedRadio: '#744C96',
                skuLabel: '#7C7C7C',
                textFieldColor: '#A5A5A5',
                skuLabelAmountSale: '#7DC383',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            mobileMenuColor: '#fff',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#65388b',
            modalBg: '#65388b',
            modalTile: '#65388b',

            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            preferenceOption: {
                color: '#4D4D4D',
            },
            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#7c7c7d',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#7c7c7d',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                borderColor: '#DBDBDB',
                itemDetailsColor: '#404040',
                payment: {
                    background: '#fff',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '50px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#fff',
            salePriceColor: '#7DC383',
            csh: {
                backgroundColor: '#F6F7FA',
            },
            // Obligatory items
            mandatoryColor: '#e80000',
        },
        implement_delivery_method: true,
    },
    '08F': {
        id: '1026',
        code: '08F',
        domain: '0800flowers', // brand's web domain - string in the middle of  https://www.0800flowers.com
        storeId: 24552, // brand's unique store ID
        catalogId: 21303, // brands have many catalogs,but web has one unique catalog
        description: '0-800-Flowers', // simple description of brand used for alt text
        giftMessageConfig: {
            giftMessageMaxLines: 5,
            giftMessageMaxChars: 35,
            excludeFromTextLabel: false,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        typography: {
            fontFamily: [
                'Roboto',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},

        overrides: {},

        presentation_family: 'growthbrand',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5f3c86',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
                gray: '#707070',
                lightGray: '#f0f0f0',
                darkGray: '#7B7B7B',
                lightPurple: '#F4F2F7',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
                countryCard: '#F4F2F8',
                buttonGreen: '#00C876',
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            colorPrimary: '#65388b', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants
            colorNeutral91: '#F4F2F8',
            colorNeutral92: '#e8e8e8',
            colorNeutral93: '#e6e6e6',

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorAlert: '#f71548',
            contentColor: '#666666',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',
            categoryCarousalBorder: '#e2e2e2',

            black: '#1f1f1b',
            white: '#fff',
            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#ffffff',
            bgHeader: '#65388b',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            disableButton: '#d8d8d8',
            footerLinks: '#65398E',
            gfBgColor: '#9872bb',
            OBHeadingColor: '#663399',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            select: '#646464',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productTitleColor: '#1f1f1b',
            productAvailibilityPDP: '#a1001a',
            sectionHeaderTextColor: '#65398E',
            secondaryButton: '#324765',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                cardBorder: '#BFBFBF',
                fromValue: '#868484',
                radioButton: '#693388',
                selectedRadio: '#744C96',
                skuLabel: '#7C7C7C',
                textFieldColor: '#A5A5A5',
                skuLabelAmountSale: '#7DC383',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            mobileMenuColor: '#fff',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            desktopBgCopyright: '#F4F2F7',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#F4F2F7',
            modalBg: '#65388b',
            modalTile: '#65388b',
            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            preferenceOption: {
                color: '#4D4D4D',
            },
            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#7c7c7d',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#7c7c7d',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                borderColor: '#DBDBDB',
                itemDetailsColor: '#404040',
                payment: {
                    background: '#fff',
                },
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '50px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
                description: '#707070',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#e06dff',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#111',
            salePriceColor: '#7DC383',
            csh: {
                backgroundColor: '#F6F7FA',
            },
            // Obligatory items
            mandatoryColor: '#e80000',
        },
        implement_delivery_method: true,
    },
    CAN: {
        id: '1031',
        code: 'CAN',
        domain: 'canada', // brand's web domain - string in the middle of  https://www.0800flowers.com
        storeId: 24559, // brand's unique store ID
        catalogId: 21309, // brands have many catalogs,but web has one unique catalog
        description: '1-800-Flowers', // simple description of brand used for alt text
        giftMessageConfig: {
            giftMessageMaxLines: 5,
            giftMessageMaxChars: 35,
            excludeFromTextLabel: false,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        typography: {
            fontFamily: [
                'Roboto',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {},

        overrides: {},

        presentation_family: 'growthbrand',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#5f3c86',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                red: '#d90059',
                gray: '#707070',
                lightGray: '#f0f0f0',
                darkGray: '#7B7B7B',
                lightPurple: '#F4F2F7',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
                countryCard: '#F4F2F8',
                buttonGreen: '#00C876',
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            break: '#eee',
            colorPrimary: '#65388b', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants
            colorNeutral91: '#F4F2F8',
            colorNeutral92: '#e8e8e8',
            colorNeutral93: '#e6e6e6',

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorAlert: '#f71548',
            colorCrossedPrice: '#e70538',
            colorSalesPrice: '#168748',
            contentColor: '#666666',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',
            categoryCarousalBorder: '#e2e2e2',

            black: '#1f1f1b',
            white: '#fff',
            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#ffffff',
            bgHeader: '#65388b',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgSelected: 'rgba(48,69,98,.1)',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            disableButton: '#d8d8d8',
            footerLinks: '#65398E',
            gfBgColor: '#9872bb',
            OBHeadingColor: '#663399',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            select: '#646464',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            rewardColor: '#00488a',
            primaryButton: '#1bc86d',
            successButton: '#1bc86d',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productTitleColor: '#1f1f1b',
            productAvailibilityPDP: '#a1001a',
            sectionHeaderTextColor: '#65398E',
            secondaryButton: '#324765',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                cardBorder: '#BFBFBF',
                fromValue: '#868484',
                radioButton: '#693388',
                selectedRadio: '#744C96',
                skuLabel: '#7C7C7C',
                textFieldColor: '#A5A5A5',
                skuLabelAmountSale: '#7DC383',
                priceFromValue: '#585858',
                paddingBottom: '109.100%',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#909090', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            mobileMenuColor: '#fff',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#65388b',
            desktopBgCopyright: '#F4F2F7',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#F4F2F7',
            modalBg: '#65388b',
            modalTile: '#65388b',
            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            preferenceOption: {
                color: '#4D4D4D',
            },
            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#7c7c7d',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#7c7c7d',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                borderColor: '#DBDBDB',
                itemDetailsColor: '#404040',
                payment: {
                    background: '#fff',
                },
                continueShoppingTxtColor: '#fff',
                continueShoppingBorderRadius: '0px',
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '50px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
                description: '#6f6f6f',
                paddingTop: '70px',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            footerDisclaimer: '#2e2c2c',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#1D8A4E',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#2ac670',
            // Apple Business Chat
            chatBgColor: '#65388b',
            chatTitle: '1-800-Flowers Assistant',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#65388b',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#111',
            salePriceColor: '#7DC383',
            csh: {
                backgroundColor: '#F6F7FA',
            },
            // Obligatory items
            mandatoryColor: '#e80000',
        },
        implement_delivery_method: true,
        best_sellers_category_id: '18F-Canada-400183038',

    },
    PLA: {
        id: '1028',
        code: 'PLA',
        domain: 'plants', // brand's web domain - string in the middle of  https://www.0800flowers.com
        storeId: 572, // brand's unique store ID
        catalogId: 9996, // brands have many catalogs,but web has one unique catalog
        description: 'plants', // simple description of brand used for alt text
        giftMessageConfig: {
            giftMessageMaxLines: 5,
            giftMessageMaxChars: 40,
            excludeFromTextLabel: false,
        },
        bloomreach: {
            account_id: null,
            domain_key: null,
            request_id: null,
            url: null,
            view_id: null,
        },
        typography: {
            // Its a default font family that can used throughout the app
            fontFamily: [
                'Hind',
                'sans-serif',
            ].join(','),
            useNextVariants: true,
            fontSize: 16,
            font_size_lg_bgs_title: '2.625rem',
            font_weight_regular: 500,
            font_weight_bold: 700,
            font_size_md: '1.125rem',
            secondaryFontFamily: [
                'BebasNeue',
                'sans-serif',
            ].join(','),
        },
        spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],        // eslint-disable-line arrow-parens
        breakpoints: {
            minWidth: {
                xxxs_screen: 280,
                xxs_screen: 320,
                xs_screen: 375,
                xs_screen_up: 376,
                xs_large_screen: 414,
                surface_duo: 540,
                surface_duo_up: 541,
                xs_large_screen_up: 568,
                small_screen_up: 767,
                small_screen_down: 768,
                small_medium_up: 811,
                small_medium_down: 991,
                medium_screen_up: 993,
                medium_screen_down: 1024,
                large_screen_up: 1290,
                ipad_pro_screen_down: 1279,
                big_screen_down: 1263,
                big_screen_up: 1027,
                xs_small_screen: 411,
                xxs_screen_up: 360,
                xs_large_screen_down: 640,
                xs_medium_screen_down: 653,
                tab_screen_up: 1112,
                xs_medium_screen_up: 736,
                big_screen_wiki_tiles: 1264,
                xs_large_screen_wiki_tiles: 567,
                screen_ipad_11: 834,
                screen_xxs_min: 348,
                xs_large_screen_surface_duo: 540,
                xs_iphone_pro_screen: 428,
                big_xl_screen_up: 1054,
                wiki_screen_size: 600,
                wiki_md_screen: 960,
            },
            maxWidth: {
                xxxs_screen: 281,
                xxs_screen: 321,
                xs_screen: 376,
                xs_screen_up: 377,
                xs_large_screen: 415,
                xs_large_screen_up: 569,
                small_screen_up: 768,
                small_screen_down: 769,
                small_medium_up: 812,
                small_medium_down: 992,
                medium_screen_up: 994,
                medium_screen_down: 1025,
                large_screen_up: 1291,
                ipad_pro_screen_down: 1280,
                big_screen_down: 1264,
                big_screen_up: 1028,
                xs_small_screen: 412,
                xxs_screen_up: 361,
                xs_large_screen_down: 641,
                xs_medium_screen_down: 654,
                tab_screen_up: 1113,
                xs_medium_screen_up: 737,
                big_screen_wiki_tiles: 1265,
                xs_large_screen_wiki_tiles: 568,
                screen_md: 1301,
                screen_ipad_11: 835,
                screen_ipad_pro: 428,
                xs_large_screen_surface_duo: 540,
                xs_iphone_pro_screen: 428,
                xs_large_screen_search_slider: 576,
            },
        },

        overrides: {},

        presentation_family: 'plants',

        palette: {
            // Brand Primary colors in 10% shades — dark to light
            primary: {
                main: '#b9e529',
                900: '#6f5092',
                800: '#7f639e',
                700: '#8f77aa',
                600: '#9f8ab6',
                500: '#af9ec3',
                400: '#bfb1cf',
                300: '#cfc5db',
                200: '#dfd8e7',
                100: '#efecf3',
                contrastText: '#fff',
            },
            // Brand Secondary colors in 10% shades — dark to light
            secondary: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#a3ce99',
                contrastText: '#fff',
            },

            tertiary: {},
            quadrinary: {},

            status: {
                main: '#E1B336',
                500: '#E1B336',
                contrastText: '#fff',
            },
            error: {
                main: '#ff0000',
                500: '#ff0000',
                contrastText: '#fff',
            },
            success: {
                main: '#00C876',
                900: '#5c9c4d',
                800: '#528a44',
                700: '#47793b',
                600: '#3d6833',
                500: '#66ad55',
                400: '#75b566',
                300: '#85bd77',
                200: '#94c688',
                100: '#d9f5dc',
                contrastText: '#fff',
            },
            common: {
                black: '#000',
                white: '#fff',
                grey: '#aeaeae',
                green: '#6E9930',
                plaGreen: '#B9E529',
                red: '#d90059',
                gray: '#707070',
                lightGray: '#f0f0f0',
                darkGray: '#7B7B7B',
                lightPurple: '#F4F2F7',
                gray_900: '#212324',
                gray_700: '#707070',
                gray_dark: '#9f9b9b',
            },
            background: {
                paper: '#fff',
                default: '#fff', // Used by css baseline to set body bg color
                countryCard: '#F4F2F8',
                buttonGreen: '#00C876',
            },
            text: {
                primary: 'rgba(0, 0, 0, 0.87)',
                secondary: 'rgba(0, 0, 0, 0.54)',
                disabled: 'rgba(0, 0, 0, 0.38)',
                hint: 'rgba(0, 0, 0, 0.38)',
            },
            contrastThreshold: 3,
            tonalOffset: 0.2,
            navHeight: '52px',

            // DEPRECATED - DO NOT USE/REFERENCE IN COMPONENTS ANY LONGER
            // All options below this line is officially deprecated
            // Notes: We shouldn't be writing component/feat specific css at a brand config level.

            break: '#458606',
            colorPrimary: '#87AA35', // replaces cta1
            colorPrimaryContrast: '#fff',
            colorSecondary: '#600', // replaces cta2
            colorSecondaryContrast: '#fff',
            colorTertiary: '#1f1f1b', // replaces cta3
            colorTertiaryContrast: '#fff',
            colorQuaternary: '#1f1f1b', // replaces cta4
            colorQuaternaryContrast: '#fff',
            colorQuinary: '#1f1f1b', // added for flexibility
            colorQuinaryContrast: '#fff',
            colorIcBanner10: '#e94e75',
            colorIcBanner20: '#7ca60b',
            colorNeutral: '#1f1f1b', // replaces gray*
            colorNeutral10: '#fafafa', // begin alpha variants
            colorNeutral20: '#f5f5f5',
            colorNeutral30: '#eee',
            colorNeutral40: '#e0e0e0',
            colorNeutral50: '#bdbdbd',
            colorNeutral60: '#9e9e9e',
            colorNeutral70: '#757575',
            colorNeutral80: '#373737',
            colorNeutral90: '#1b1b1b', // end alpha variants
            colorNeutral91: '#F4F2F8',
            colorNeutral92: '#e8e8e8',
            colorNeutral93: '#e6e6e6',

            colorInfo: '#1976d2',
            colorInfoContrast: '#fff',
            colorWarning: '#ffa000',
            colorWarningContrast: '#fff',
            clubsTypebackground: '#F2F0EC',
            colorAlert: '#f71548',
            contentColor: '#666666',
            colorAlertContrast: '#fff',
            colorSuccess: '#43a047',
            colorSuccessContrast: '#fff',
            colorDisabled: '#ccc',
            colorDisabledContrast: '#1f1f1b',
            colorValid: '#24b200',
            categoryCarousalBorder: '#e2e2e2',

            black: '#1f1f1b',
            white: '#fff',
            borderLeft: '#52287e',
            backgroundColor: '#f9f6f6',
            borderBottom: '1px dotted #7e7e7e',
            bgFooter: '#ffffff',
            bgHeader: '#f2f2f2',
            bgPhoneIcon: '#a1001a',
            bgPopup: 'rgba(0,0,0,.85)',
            bgs_grey: '#F0F4F5',
            bgSelected: 'rgba(48,69,98,.1)',
            bgs_radio_green: '#3FB030',
            bgTile1: '#a1001a',
            bgTile2: '#304562',
            bgTextOverlay1: 'rgba(0,0,0,0.6)',
            bigBoldLabel: '#1f1f1b',
            colorIconHeader: '#000',
            contentHeading: '#6e9930',
            cardPagebackground: '#ebeadd',
            colorHeadingSympathy: '#70548f',
            deliverySentence: '#52287e',
            buttonBackgroundColor: '#333',
            disableButton: '#d8d8d8',
            footerBanner: '#497E6F',
            footerLinks: '#65398E',
            gfBgColor: '#9872bb',
            OBHeadingColor: '#663399',
            gfMobileInputBorderBottom: '1px solid rgba(174,174,174,0.6)',
            gfMobileLabelTextColor: '#212121',
            gfMobileSelectOptionTextColor: '#606060',
            gfMobileZipInputLabelTextColor: '#a0a0a0',
            gfTabDesktopInputBorder: '1px solid #333',
            globalBannerBackgroundColor: '#1f1f1b',
            globalBannerTextColor: 'white',
            select: '#646464',
            globalBannerTextDecoration: 'underline',
            linkMenu: '#324765',
            orderConfirmationHeader: '#1f1f1b',
            orderConfirmationText: 'black',
            slideOutMiniCartMainColor: 'black',
            rewardColor: '#00488a',
            primaryButton: '#b9e529',
            primaryButtonHover: '#a4cf19',
            grayButton: '#c7c4c4',
            successButton: '#1bc86d',
            submit: '#33333',
            productLine: '#BDBBBB',
            modalBorder: '#ececec',
            productTitleColor: '#1f1f1b',
            productAvailibilityPDP: '#a1001a',
            linkColor: '#87AA35',
            calendarPrimary: '#87AA35',
            sectionHeaderTextColor: '#65398E',
            secondaryButton: '#324765',
            shape: {
                borderRadius: '4px',
            },
            tickCheck: '#00CC33',
            snipeText: '#660000',
            snipeTextPromo: '#a1001a',
            snipeTextBackground: 'rgba(48, 69, 98, .85)',
            snipeTextFontSize: '14px',
            snipeTextFontWeight: 500,
            guidedNav: {
                background: '#FFF',
            },
            promoGiftButtons: {
                background: '#e8eaec',
                color: '#2f2f2f',
            },
            globalBannerContainerBackground: '#ffffff',
            globalBannerContainerBorder: '#575757',
            globalBannerMainTextColor: '#2f2f2f',
            pdp: {
                subscription: {
                    checkboxColor: '#3D70B2',
                    rootContainerBackground: '#efdfb0',
                    boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
                    overlayContainerBackground: '#fff',
                    modalColor: '#fff',
                    subTable: '#f7eed7',
                    linkColor: '#304562',
                    goButton: '#a1001a',
                    goButtonColor: '#fff',
                },
                cardBorder: '#BFBFBF',
                fromValue: '#868484',
                radioButton: '#000',
                selectedRadio: '#000',
                skuLabel: '#7C7C7C',
                textFieldColor: '#A5A5A5',
                skuLabelAmountSale: '#7DC383',
                paddingBottom: '100%',
                salePrice: '#D1040C',
            },
            mobileMenuBG: {
                header: '#909090',
                main: '#fff',
                product: '#fff',
                alternate: '#909090',
                nonProduct: '#fff', // DEPRECATED- replaced by 'alternate'
                parent: '#909090',
                child: '#e8e8e8',
                leftMenuBG: '#a8a89a',
            },
            desktopHeaderMenu: {
                background: '#fff',
                text: '#333',
                border: '1px solid #f5f5f5',
                maxWidth: '1400px',
            },
            mobileMenuColor: '#fff',
            uiShadow: '0 8px 4px -4px rgba(59,33,81,0.21)',
            unCheckRadioBtn: '#65388b',
            checkRadioBtn: '#007bff',
            desktopBgCopyright: '#F2F2F2',
            copyright: '#606060',
            mobileFooterBottomLink: '#65388b',
            desktopBgFooter: '#F2F2F2',
            modalBg: '#65388b',
            modalTile: '#65388b',
            cta1: '#1f1f1b', // DEPRECATED
            cta2: '#47201D', // DEPRECATED
            cta3: '#660000', // DEPRECATED
            cta4: '#595959', // DEPRECATED
            pink1: '#8E1429', // DEPRECATED
            brown1: '#47201D', // DEPRECATED
            brown2: '#DBD0C2', // DEPRECATED
            brown3: '#DBD0C2', // DEPRECATED
            grey1: '#1f1f1b', // DEPRECATED
            grey2: '#3c3c35', // DEPRECATED
            grey3: '#5c5c52', // DEPRECATED
            grey4: '#7c7c70', // DEPRECATED
            grey5: '#a8a89a', // DEPRECATED
            grey6: '#d1d1c1', // DEPRECATED
            grey7: '#ebebde', // DEPRECATED
            grey8: '#cccccc', // DEPRECATED
            grey9: '#e8eaec', // DEPRECATED
            grey10: '#b6b5b5', // DEPRECATED

            preferenceOption: {
                color: '#4D4D4D',
            },
            flowerGift: {
                topLogoText: {
                    color: '#000000',
                },
                titleBg: {
                    background: 'rgba(0, 0, 0, 0.1)',
                },
            },
            sympathyPage: {
                buttonColor: '#50396E',
                stickyBackground: '#fff',
                borderColors: '#ccc',
                containerWidth: '1400px',
                headingColor: '#5d3a86',
                linkColor: '#639',
                subCopyBg: '#e6e6fa',
                subCopyBorderColor: '#d8bfd8',
            },
            myAccount: {
                contentGridBorder: '#7c7c7d',
                blockBorderRight: '#e2e2e2',
                blockMessageFadedInside: '#7c7c7d',
                blockHeaderbackground: '#ededea',
                blockActions: '#619ee6',
                caretSign: '#000',
                activeLink: '#000',
                divider: '#ccc',
                orderRow: '#f6f6f4',
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            checkoutGuestForgotLinkColor: '#3b73af',
            checkout: {
                borderColor: '#DBDBDB',
                itemDetailsColor: '#404040',
                payment: {
                    background: '#fff',
                },
                continueShoppingTxtColor: '#000',
                continueShoppingBorderRadius: '26px',
            },
            tiers: {
                card: {
                    backgroundColor: '#fff',
                },
                image: {
                    maxWidth: '100%',
                    height: 'auto',
                },
                title: {
                    lineHeight: '42px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    upSmall: {
                        textAlign: 'left',
                    },
                    downSmall: {
                        textAlign: 'center',
                    },
                },
                container: {
                    backgroundColor: 'transparent',
                    maxWidth: {
                        downSmall: '100%',
                        downMedium: '100%',
                        upMedium: '970px',
                        upLarge: '1170px',
                    },
                },
            },
            nup: {
                margin: '50px auto',
                header: {
                    textAlign: 'center',
                    width: '100%',
                    margin: '44px 0 20px 0',
                    letterSpacing: '.4px',
                    fontSize: '1.4em',
                    fontWeight: '300',

                },
                Standard: {
                    width: '240px',
                    height: 'auto',
                },
                Medium: {
                    width: '340px',
                    height: 'auto',
                },
                Small: {
                    width: '186px',
                },
            },
            genericBanner: {
                margin: '15px auto',
            },
            heroSingle: {
                margin: '15px auto',
            },
            footer: {
                marginTop: '10px',
                textAlign: 'left',
                description: '#707070',
                copyright: '#2E2C2C',
                paddingTop: '0px',
            },
            availableMessageColor: '#666',
            // Sympathy Helpful Resource
            ShrBordercolor: '#c0c0c0',
            sympathyViewAllBg: '#8c62ab',
            sympathyViewAllBorder: '#a3a3a3',
            sympathyAdvisorsTitle: '#6e9930',
            sympathyProductNameColor: '#666',
            sympathyAdvisorsBorder: '#b5b2d2',
            sympathyAdvisorsBg: '#f5f3f7',
            sympathyCallTitleColor: '#354219',
            passportDividerColor: '#6ecba6',
            passportBgColor: '#147a90',
            passportBorderColor: '#dcdcdc',
            passportSummaryBgColor: '#ececea',
            flowerClubColor: '#583C81',
            flowerClubBg: '#fff3ea',
            sympathyShowNowButton: '#0054a5',
            sympathyHoverColorButton: '#46a843',
            sympathyDiscountColor: '#d90059',
            footerDisclaimer: '#2e2c2c',
            // celebrations Rewards
            crBgColor: '#dddddd',
            crQusColor: '#F2A900',
            crDividerColor: '#cecece',
            crPassportTitle: '#007dba',
            crPassportSubTitle: '#999',
            sympathytitlecolor: '#70548f',
            // Promo codes Page
            imocTitleColor: '#f15a29',
            cardTitleColor: '#a4291f',
            // No Search found
            continueShoppingHoverBg: '#b9e529',
            backToHomeHoverBg: '#EBEBEB',
            continueShoppingBg: '#458606',
            // Apple Business Chat
            chatBgColor: '#86aa35',
            chatTitle: 'plants.com Assistant',
            giftBgColor: '#4d005f',
            applePayBgColor: '#1193ff',
            // Radio Listeners Search Page
            radioOfferBestSellerBgColor: '#cdeacb',
            radioOfferBestSellerTextColor: '#ac6ab5',
            radioOfferBgColor: '#932fbd',
            motherDayBg: '#faf6f5',
            motherDayShopBtn: '#fb8966',
            // Mother's day page
            motherDayColor: '#f9394b',
            motherDayBgColor: 'rgba(0, 0, 0, .6)',
            motherWhoArrowBg: '#9dc8d6',
            motherWhoLinkColor: '#4a8ed1',
            motherWhoDescColor: '#404251',
            motherSpecialDayBgColor: '#eb537d',
            imageCardBoxShadow: 'rgba(0, 0, 0, 0.2)',
            faqBgColor: '#ffeffb',
            // Smart Gift Page
            smartGiftBgColor: '#55b74d',
            smartGiftHoverBgColor: '#1e8b4f',
            giftGuideHoverColor: '#378a4e',
            giftGuideArticleBg: '#f9f9f9',
            communitionSectionBg: '#f1f1f1',
            // Customer Serivce Page
            csBgColor: '#F5F6F9',
            csContentColor: '#f3832f',
            checkoutHeaderBg: '#87AA35',
            // Birthday Flowers Page
            birthdayFlowerBgColor: '#9c7eb5',
            // Footer terms and privacy
            tpColor: '#111',
            salePriceColor: '#7DC383',
            csh: {
                backgroundColor: '#F6F7FA',
            },
        },
        implement_delivery_method: true,
        best_sellers_category_id: 'Best-Selling-Plants-022823',
    },
};

export default brandsConfig;
