/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import brandActionTypes from './Brand-ActionTypes';

export const loadBrand = (user, currentBrandId) => ({
    type: brandActionTypes.BRAND_LOAD,
    payload: {
        user,
        currentBrandId,
    },
});

export const brandLoaded = (id, brand) => ({
    type: brandActionTypes.BRAND_LOADED,
    id,
    payload: brand,
});

export const brandLoadFailed = (exception) => ({
    type: brandActionTypes.BRAND_LOAD_FAILED,
    payload: exception,
});

export const setCurrentBrandId = (brandId) => ({
    type: brandActionTypes.BRAND_SET_CURRENT_BRAND_ID,
    payload: brandId,
});
