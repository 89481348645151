/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import axios from 'axios';
import mbpUtil from 'mbp-api-util';

export const processCGCGreetingCardAPI = (data) => axios.post(`https://${mbpUtil.getEnv('APP_CELEBRATIONS_GREETING_DOMAIN')}/card-placed`, { data }).then((res) => res);

export default {};
