/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, takeEvery, call, put,
} from 'redux-saga/effects';

import * as checkoutProperties from './Properties-Actions';
import * as actionTypes from './Properties-ActionTypes';

function* getProperties() {
    try {
        const properties = yield call(checkoutProperties.retriveCheckoutProperties, []);
        yield put(checkoutProperties.loadCheckoutProperties, [properties]);
    } catch (e) {
        console.log('ERROR: ', e);
    }
}

function* getPropertiesWatcher() {
    yield takeEvery(actionTypes.GET_CHECKOUT_PROPERTIES, getProperties);
}

const watchers = [
    fork(getPropertiesWatcher),
];

export { watchers };

export default {};
