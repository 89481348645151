/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable import/prefer-default-export */
import React from 'react';

export const formatPhoneNumber = (phoneNumber) => {
    try {
        return phoneNumber
            .trim()
            .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
        // eslint-disable-next-line no-empty
    } catch (ex) {}
    return '';
};

export const phoneNumberLink = (phone) => (
    <a href={`tel:+${phone.replace(/\D/g, '')}`} className="link">
        {phone}
    </a>
);

export const getCreditCardType = (cardNumber) => {
    // start without knowing the credit card type
    let result = '';
    // first check for Mastercard
    if (/^5[1-5]/.test(cardNumber)) result = 'MC';
    // then check for Visa
    else if (/^4[0-9]/.test(cardNumber)) result = 'VI';
    // then check for American Express
    else if (/^3[47]/.test(cardNumber)) result = 'AX';
    // then Discover
    else if (/^6(?:011|5[0-9]{2})/.test(cardNumber)) result = 'DS';
    return result;
};

export const formatPrice = (price) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(Number(price))) {
        return Number(price).toFixed(2);
    }
    return price;
};
