/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    fork, put, call, select, takeEvery, take,
} from 'redux-saga/effects';
import orderClient from '../../../../../apis/checkout-apis/orderClient';

import memberDucks from '../../../Member/ducks';

import { errorActions, errorOperations }  from '../Common/ducks/Error';
import { showLoadingMessageActions } from '../Common/ducks/ShowLoadingMessage';

import {
    addCartRecipient,
    updateRecipients,
    updateWorkingRecipient,
    changeWorkingRecipient,
    callToTriggerValidateOrder,
    triggerValidateOrder,
    triggerValidateOrderCompleted,
} from './Recipient-Actions';

import { commonSelectors } from '../Common';

const {
    auth: {
        authOperations: {
            workers: {
                checkJWT,
            },
        },
    },
} = memberDucks;

const validateOrderAPI = (jwtToken, orderId) => orderClient.validateOrder({}, jwtToken, orderId);

function* loadRecipient(action) {
    const recipient = action.data;
    yield put(updateRecipients(recipient));
}

function* onChangeWorkingRecipient() {
    try {
        yield put(updateWorkingRecipient());
    } catch (e) {
        console.log(e);
    }
}

function validateOrderItemsForMissingDetails(recipients) {
    const errorMapping = {};
    if (recipients && recipients.length > 0) {
        recipients.forEach((orderItem) => {
            const { recipientAddress } = orderItem;
            orderItem.orderItems.forEach((item) => {
                if (recipientAddress && !recipientAddress.firstName) {
                    errorMapping[`${item.orderItemsId}`] = 'Please fill recipient address';
                } else if (!item.formattedPromisedAvailableTime) {
                    errorMapping[`${item.orderItemsId}`] = 'Please select a delivery date';
                } else if (!item.field2) {
                    errorMapping[`${item.orderItemsId}`] = 'Please fill gift message';
                }
            });
        });
    }
    return errorMapping;
}

function* workerValidateOrder(action) {
    const orderId = yield select(commonSelectors.getOrderId);
    const { history } = action;
    const { location: { pathname } } = history;

    try {
        const isPaymentErrors = yield select(commonSelectors.getIsPaymentHasError);

        if (!isPaymentErrors) {
            yield put(errorActions.clearErrors({
                errType: 'paymentClearAllFields',
                field: 'error',
            }));
        }

        const jwtToken = yield call(checkJWT);
        let redirect = false;

        // Validate all order items to have recipient address, delivery date and gift message.
        const continueToPayment = yield select(commonSelectors.continueToPayment);

        if (continueToPayment) {
            // proceed to do a server side validation - to check product availability
            yield call(validateOrderAPI, jwtToken, orderId);
            redirect = true;
        } else {
            // load client side error for missing address, delivery date and gift message
            const recipients = yield select(commonSelectors.getOrderItemExcludePassport);
            const validationError = yield call(validateOrderItemsForMissingDetails, recipients);
            yield put(errorActions.loadError({
                errType: 'payment',
                errMsg: validationError,
            }));
            throw new Error('clientError');
        }

        // proceed to payment page
        if (history && redirect && (pathname.indexOf('/cart') > 0 || pathname.indexOf('/shipping/') > 0)) {
            // redirect to payment page
            history.push(`/checkout/payment/${orderId}`);
        }

        yield put(triggerValidateOrderCompleted(true));
    } catch (ex) {
        // load error
        if (ex !== 'clientError') {
            yield call(errorOperations.getError, ex, 'payment');
        }

        // hide loader
        yield put(showLoadingMessageActions.flagOffShowLoadingMessage());

        // redirect to shipping page if validation fail
        const isPassportOnlyItem = yield select(commonSelectors.getIsPassportOnlyItemOnCart);
        if (history && !isPassportOnlyItem && (pathname.indexOf('/payment/') > 0 || pathname.indexOf('/cart/') > 0)) {
            history.push(`/checkout/shipping/${orderId}`);
        }

        yield put(triggerValidateOrderCompleted(false));
    }
}

function* workerCallToTriggerValidateOrder(action) {
    yield put(showLoadingMessageActions.updateShowLoadingMessage());
    yield put(triggerValidateOrder(action.history));
    yield take(callToTriggerValidateOrder().type);
    yield put(showLoadingMessageActions.flagOffShowLoadingMessage());
}

function* watchChangeWorkingRecipient() {
    yield takeEvery(changeWorkingRecipient().type, onChangeWorkingRecipient);
}

function* loadRecipientWatcher() {
    yield takeEvery(addCartRecipient().type, loadRecipient);
}

function* watchValidateOrder() {
    yield takeEvery(triggerValidateOrder().type, workerValidateOrder);
}

function* watchCallToTriggerValidateOrder() {
    yield takeEvery(callToTriggerValidateOrder().type, workerCallToTriggerValidateOrder);
}

const watchers = [
    fork(loadRecipientWatcher),
    fork(watchChangeWorkingRecipient),
    fork(watchValidateOrder),
    fork(watchCallToTriggerValidateOrder),
];

export {
    watchers,
    workerValidateOrder,
};

export default {};
